import React from "react";
import { connect } from "react-redux";
import { Modal as BModal } from "react-bootstrap";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  Modal,
  Input
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import CreateEditClient from "./create_edit_client";
import ViewClient from "./view_client";
import AddIcon from '../../assets/icons/add.png';
import uploadExcelDesktop from '../../assets/icons/upload-excel-desktop.png';
import ExcelFile from '../../assets/icons/excel-file.png';
import UploadFile from '../../assets/icons/upload.png';
import PrinterIcon from '../../assets/icons/printer.png';
import DeudaIcon from '../../assets/icons/ico_Deuda.png';

const HEADER_TABLE = [
  "Nombre/Razón social",
  "Email",
  "Cedula/RIF",
  "Teléfono",
  "Zona",
  "Categoría",
  "Estatus",
  "Acciones"
];
class Clients extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      last_page: 1,
      data: [],
      zones: [],
      client_categories: [],
      sellers: [],
      enterprises: [],
      retentions: [],
      user: null,
      view: false,
      selectedClient: null,
      list_status: [
        { value: '', label: "Todos" },
        { value: 1, label: "Activos" },
        { value: 0, label: "Inactivos" }
      ],
      list_person_type: [
        { value: "", label: "Todos" },
        { value: 1, label: "Naturales" },
        { value: 2, label: "Juridicas" }
      ],
      form: {
        status: null,
        person_type: "",
        zone: "",
        client_category_id: "",
        search: null,
        user_id: this.props.user.id,
        role: props.user.role,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
      },
      modalUploadExcel: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { page, form } = this.state;
    Globals.setLoading();
    axios
      .post("web/admin/clients?page=" + page, form)
      .then(res => {
        if (res.data.clients) {
          this.setState({
            last_page: res.data.clients.last_page,
            data: res.data.clients.data
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading()
        //this.getEnterprises();
        //this.getRetentions();
        this.getZones();
        this.getClientCategories();
        this.getSellers();
      });
  };

  close = () => {
    this.setState(
      {
        create: false,
        view: false,
        edit: null,
        user: null,
        modalUploadExcel: false,
      },
      () => this.load()
    );
  };

  delete = item => {
    const removeMsg = `¿Desea eliminar a: ${item.name}?`;
    const hasToPayOrders = (item?.to_pay_orders?.length ?? 0) > 0;
    const toPayMsg = hasToPayOrders ? '\n(este usuario tiene cuentas por cobrar)' : '';

    Globals.confirm(removeMsg + toPayMsg, () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`web/admin/clients/delete/${item.id}`)
        .then(res => {
          Globals.showSuccess('Cliente eliminado');
          this.load();
        })
        .catch(err => Globals.showError(err.response.data.msg))
        .then(() => Globals.quitLoading());
    });
  };

  suspend = item => {
    let message;
    message = item.status === 1 ? "¿Desea suspender a " : "¿Desea activar a ";
    Globals.confirm(message + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("web/admin/clients/setstatus", { client_id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => Globals.showError(err.response.data.msg))
        .then(() => Globals.quitLoading());
    });
  };

  print = () => {
    axios
      .post('web/admin/clients/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  }

  verify = item => {
    Globals.confirm("¿Desea verificar a: " + item.first_name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/clients/verify", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => Globals.showError(err.response.data.msg))
        .then(() => Globals.quitLoading());
    });
  };

  change = ({ target }) => {
    const { name, value } = target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  getZones = () => {
    axios
      .post("web/admin/zones", {user_id: this.props.user.id})
      .then(({ data }) => {
        data.zones.unshift({ id: "x", name: "Sin Zona" });
        data.zones.unshift({ id: "", name: "Todas" });

        this.setState({
          zones: data.zones
        });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getClientCategories = () => {
    axios
      .post("admin/clients/categories", {user_id: this.props.user.id, is_select: true})
      .then(({ data }) => {
        data.categories.unshift({ id: "x", name: "Sin Categoría" });
        data.categories.unshift({ id: "", name: "Todas" });

        this.setState({
          client_categories: data.categories
        });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getSellers = () => {
    axios
      .post("web/admin/sellers", { user_id: this.props.user.id, for_select: true })
      .then(({ data }) => {
        this.setState({ sellers: data.sellers.data });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  getEnterprises = () => {
    axios
      .get("admin/warehouses/enterprises")
      .then(res => {
        if (res.data.result) {
          this.setState({
            enterprises: res.data.users
          });
        }
      })
      .catch(() => Globals.showError());
  };

  getRetentions = () => {
    axios
      .get("admin/clients/retentions")
      .then(res => {
        this.setState({
          retentions: res.data
        });
      })
      .catch(() => Globals.showError());
  };

  downloadFormat = () => {
    Globals.setLoading();
    const { user } = this.props;

    let param = {
      user_id: user.id
    };

    axios
      .post("web/admin/clients/download-format", this.state.form)
      .then(res => {
        if(res.data.url_storage){
          const url = `${ENV.BasePublic}${res.data.url_storage}`;

          const element = document.createElement('a');
          element.href = url;
          element.download = 'Carga_Masiva_Clientes';
          element.click();

          Globals.quitLoading()
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
  };

  chooseFile = async (e) => {
    if (!e.target.files.length) return;
    const currentFile = URL.createObjectURL(e.target.files[0]);

    const data = {
      ...this.state.form,
      file: e.target.files[0],
      currentFile: currentFile,
    };

    e.target.value = "";

    Globals.confirm('¿Realmente desea subir el archivo?', async () => {
      await this.setState({ form: { ...data } });

      Globals.setLoading();
      axios
        .upload("web/admin/clients/import-excel", data)
        .then(async res => {
          if(res.data.result){
            Globals.showSuccess(res.data.msg);
            
            await this.setState({
              form: {
                ...this.state.form,
                file: {},
                currentFile: "",
              }
            });
            this.load();
          }
          else{
            if(res?.data?.error){
              Globals.showError(res.data.error);
            }
          }
        })
        .catch(err => {
          console.log(err);
          //Globals.showError('Existe un cliente con el código repetido. Intente de nuevo.');
          Globals.showError('El campo nombre es requerido.');
        })
        .then(() => Globals.quitLoading());
    });
  }

  render() {
    const {
      create,
      data,
      edit,
      modalUploadExcel,
      enterprises,
      last_page,
      list_person_type,
      retentions,
      page,
      person_type,
      search,
      status,
      list_status,
      user: client,
      view,
      zone,
      zones,
      client_categories,
      client_category_id,
      selectedClient,
      sellers,
    } = this.state;

    const { history, user } = this.props;

    const filtered_zones = zones.filter(Item => {
      return Item.id && Item.id > 0;
    });
    
    const filtered_clientCategories = client_categories.filter(Item => {
      return Item.id && Item.id > 0;
    });

    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2);
    const threeMonthsName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(threeMonthsAgo);
    const monthName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(currentDate);

    return (
      <Menu history={history}>
        {view && (
          <Modal title="Ver Cliente" onClose={this.close} visible>
            <ViewClient user={client} />
          </Modal>
        )}

        {create && (
          <Modal
            title={edit ? "Editar Cliente" : "Nuevo Cliente"}
            size="lg"
            onClose={this.close}
            visible
          >
            <CreateEditClient
              user={user}
              edit={edit}
              //enterprises={enterprises}
              zones={filtered_zones}
              client_categories={filtered_clientCategories}
              //retentions={retentions}
              onClose={this.close}
              sellers={sellers}
            />
          </Modal>
        )}

        <Modal
          // title={"Pedidos del último trimestre: " + threeMonthsName+'-'+monthName}
          title={"Cuentas por cobrar"}
          onClose={() => this.setState({ selectedClient: null })}
          visible={selectedClient}
        >
          <p>Cliente: <b>{ selectedClient?.name }</b></p>
          <table border={1} style={{ border: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th>Fecha</th>
                <th>Factura</th>
                <th>Vencimiento</th>
                {/* <th>Debe</th>
                <th>Haber/Pago</th> */}
                <th>Deuda</th>
                <th>Abono</th>
                <th>Por Cobrar</th>
              </tr>
            </thead>
            <tbody>
              {selectedClient?.to_pay_orders?.map((item, index) => (
                <tr key={index} style={{ textAlign: 'right' }}>
                  <td style={{ textAlign: 'center' }}>
                    { item?.created_es }
                  </td>
                  <td>{ item?.number_format }</td>
                  <td style={{ textAlign: 'center' }}>
                    { !!item?.payment_date ? moment(item?.payment_date).format('DD/MM/YYYY') : '' }
                  </td>
                  {/* <td>${ Globals.formatMiles(item?.to_pay) }</td>
                  <td>${ Globals.formatMiles(item?.partial_payment_total) }</td> */}
                  <td>${ Globals.formatMiles(item?.total) }</td>
                  <td>${ Globals.formatMiles(item?.partial_payment_total) }</td>
                  <td>${ Globals.formatMiles(item?.to_pay) }</td>
                </tr>
              ))}
              <tr style={{ textAlign: 'right' }}>
                <td colSpan={4}>
                  <b>${ Globals.formatMiles(selectedClient?.totalDeuda) }</b>
                </td>

                <td >
                  <b>${ Globals.formatMiles(selectedClient?.totalAbono) }</b>
                </td>

                <td >
                  <b>${ Globals.formatMiles(selectedClient?.totalToPay) }</b>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal>

        <BModal show={modalUploadExcel} onHide={() => this.close()} size="lg" id="modal-upload-excel">
          <BModal.Body>
            <UploadExcelFile
              download={() => this.downloadFormat()}
              onUpload={this.chooseFile}
              onClose={() => this.close()}
            />
          </BModal.Body>
        </BModal>

        <div id="home">
          <div className="row">
            <div className="col-sm-12 col-md">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={search}
                placeholder="Buscar por Nombre, Email, Cedula/RIF"
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="status"
                label="Estatus"
                className="filter"
                defaultname="Seleccione"
                onChange={this.change}
                value={status}
                options={list_status.map(({ value, label }) => ({
                  value: value,
                  label: label
                }))}
              />
            </div>
            {/* <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="person_type"
                label="Personalidad"
                className="filter"
                defaultname="Seleccione"
                onChange={this.change}
                value={person_type}
                options={list_person_type.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div> */}
            <div className="col-sm-12 col-md-2">
              <Select
                color="white"
                name="zone"
                label="Zonas"
                className="filter"
                defaultname="Seleccione"
                onChange={this.change}
                value={zone}
                options={this.state.zones.map(x => ({ value: x.id, label: x.name }))}
              />
            </div>
            <div className="col-sm-12 col-md-2">
              {this.state.client_categories.length > 2 && (
                  <Select
                    color="white"
                    name="client_category_id"
                    label="Categorías"
                    className="filter"
                    defaultname="Seleccione"
                    onChange={this.change}
                    value={client_category_id}
                    options={this.state.client_categories.map(x => ({ value: x.id, label: x.name }))}
                  />
              )}
            </div>
            <div className="col-sm-12 col-md">
              <Button 
                className="btn-align-bottom btn-filter" 
                color=" " 
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-12">
              <button
                className="btn btn-filter"
                style={{ marginTop: 10, marginBottom: 20 }}
                onClick={() => this.setState({ modalUploadExcel: true })}
              >
                <img src={AddIcon} style={{ width: 24, marginRight: '0.5rem' }} alt="Subir Excel" />
                <span>Subir masivamente clientes en excel</span>
              </button>
            </div>
          </div>
          <Table
            data={data.length}
            title="Clientes"
            header={HEADER_TABLE}
            right={
              <div>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print()}
                >
                  <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                </Button>
                <Button
                  title="Agregar Clientes"
                  small="true"
                  onClick={() => {
                    this.setState({
                      create: true
                    });
                  }}
                >
                  <img src={AddIcon} alt="Agregar Clientes" />
                </Button>
              </div>
            }
          >
            {data.map((i, index) => {
              const totalDeuda = i?.to_pay_orders?.reduce((a, b) => a + Number(b?.total), 0);
              const totalAbono = i?.to_pay_orders?.reduce((a, b) => a + b?.partial_payment_total, 0);
              const totalToPay = i?.to_pay_orders?.reduce((a, b) => a + b?.to_pay, 0);
              
              return (
                <tr key={index}>
                  <td>
                    {!!i?.to_pay_orders?.length ? (
                      <span
                        className="clickable-red"
                        onClick={() => this.setState({ selectedClient: { ...i, totalToPay, totalDeuda, totalAbono} })}
                      >
                        { i.name } <img src={DeudaIcon} />
                      </span>
                    ) : <span> { i.name } </span>}
                  </td>
                  <td>{i.email}</td>
                  <td align={""}>
                    {i.document ?? ''}
                  </td>
                  <td>{i.phone}</td>
                  <td>{i.zone?.name ?? 'Sin zona'}</td>
                  <td>{i.category?.name ?? 'Sin categoría'}</td>
                  <td style={{ color: Globals.getStatus(i.status).color }}>
                    {/* {Globals.getStatus(i.status).text} */}
                    {i.status === Constants.CLIENT.STATUS.INACTIVE ? 'Inactivo' : 'Activo'}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          user: i,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      small="true"
                      color=" "
                      style={{backgroundColor: '#0f54e0'}}
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: i,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {i.status === Constants.CLIENT.STATUS.INACTIVE && (
                      <Button
                        color="warning"
                        title="Activar"
                        small="true"
                        style={{backgroundColor: '#d39e00'}}
                        onClick={() => {
                          this.suspend(i, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {i.status === Constants.CLIENT.STATUS.ACTIVE && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.suspend(i, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={last_page}
            active={page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => {
                  this.load();
                }
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

const UploadExcelFile = ({ download, onUpload, onClose }) => (
  <>
    <div className="banner">
      <img src={uploadExcelDesktop} alt="Subir excel" />
    </div>

    <div className="content">
      <p>
        <b>Paso 1-</b> Descarga el archivo de excel
        <div>
          <button className="btn btn-primary p-2" onClick={download}>
            <img src={ExcelFile} alt="Descargar excel" />
            <span>Descargar Archivo Excel</span>
          </button>
        </div>
      </p>
      <p><b>Paso 2-</b> Carga la información de tus clientes, en el orden indicado en el excel</p>
      <p><b>Paso 3-</b> Sube tu archivo excel con la información de tus clientes</p>
      {/* <p><b>Los clientes con códigos repetidos no podrán ser cargados.</b></p> */}
    </div>

    <div className="actions">
      <button className="btn p-2" onClick={onClose}>
        <span>Cerrar</span>
      </button>

      <label className="btn p-2" htmlFor="excel-file">
        <img src={UploadFile} alt="Descargar excel" />
        <span>Subir Formato de Clientes</span>
      </label>
    </div>

    <input
      id="excel-file"
      type="file"
      accept="application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      onChange={onUpload}
      style={{ display: 'none' }}
    />
  </>
);

export default connect(state => {
  return {
    user: state.user
  };
})(Clients);
