const user = (state = false, action) => {
  switch (action.type) {
    case "SET_USER":
      if (action.payload.assigned_warehouses) {
        const assigned_warehouses = action.payload.assigned_warehouses.map(
          Item => {
            return {
              warehouse_id: Item.warehouse_id,
              branch_id: Item.branch_id
            };
          }
        );
        action.payload.assigned_warehouses = assigned_warehouses;
      }
      return action.payload;
    case "QUIT_USER":
      return false;
    default:
      return state;
  }
};

export default user;
