import React from "react";
import { Button, Input } from "../../components";
import { axios, Globals } from "../../utils";

class CreaEditSubcategory extends React.Component {
  state = {
    form: {},
    textButton: "Crear"
  };
  componentDidMount() {
    if (this.props.edit) this.edit();
  }
  submit = async () => {
    let param = { ...this.state.form };
    Globals.setLoading();
    axios
      .upload(
        this.props.edit
          ? "admin/subcategories/edit"
          : "admin/subcategories/create",
        param
      )
      .then(res => {
        if (res.data.result) {
          this.setState({
            form: {}
          });
          Globals.showSuccess(res.data.msg);
          this.props.onClose();
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    await this.setState({
      form: {
        category_id: this.props.edit.element.category_id,
        creator_id: this.props.edit.element.creator_id,
        code: this.props.edit.element.code,
        name: this.props.edit.element.name,
        id: this.props.edit.element.id
      },
      textButton: "Editar"
    });
  };

  change = e => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }
    this.setState({
      form: {
        creator_id: this.props.user.id,
        category_id: this.props.category_id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <div className="container-create-edit-user">
        <Input
          color=" "
          value={this.state.form.name}
          name="name"
          label="Nombre"
          labelClass="material"
          className="material"
          onChange={this.change}
        />
        <Input
          color=" "
          value={this.state.form.code}
          name="code"
          label="Código"
          labelClass="material"
          className="material"
          onChange={this.change}
        />
        {/* <File
                    placeholder={this.props.edit ? 'Cambiar imagen de perfil (Opcional) ' : "Agregar Imagen de perfil"}
                    placeholdersuccess="Imagen de perfil Agregada"
                    showcheck={ true.toString() }
                    onChange={ this.change }
                    name="image"
                    value={ this.state.form.image }
                    inputstyle={{
                        display: 'contents'
                    }}
                    className="btn-product" /> */}
        <div id="button" className="d-flex justify-content-center">
          <Button
            color=" "
            type="button"
            className="primary"
            onClick={() => this.submit()}
          >
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}

export default CreaEditSubcategory;
