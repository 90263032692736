import axios from '../../utils/axios';

class AccountToPayService {

	static async get(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/accounts-to-pay", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

	static async finish(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/accounts-to-pay/finish", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

	static async setPayment(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/accounts-to-pay/payment", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default AccountToPayService;