import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
	Table,
	Pagination,
	Button,
	Icon,
	Modal,
	Select,
	Input
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditWarehouse from "./create_edit_transfer_warehouse";
import AddIcon from '../../assets/icons/add.png';

class TransferWarehouses extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			header: [
				"Origen",
        "Destino",
        "Fecha/Hora",
        "Registrado por",
				"Acciones",
			],
			page: 1,
			last_page: 1,
			data: [],
			user: null,
			create: false,
			view: false,
			selectedTranfer: null,
			form: {
				status: null,
				search: null,
				user_id: this.props.user.id,
				role: this.props.user.role,
				admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
			},
			products: [],
		};
	}

	abortController = new AbortController()

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		this.abortController.abort()
	}

	load = () => {
		Globals.setLoading();
		let param = this.state.form;
		param.role = this.props.user.role;
		if (this.props.user.role === 3) {
			param.user_id = this.props.user.id;
		}
		if (this.props.user.role === 4) {
			param.user_id = this.props.user.enterprise_users.enterprise_id;
		}
		axios
			.post("web/admin/warehouses/transfers?page=" + this.state.page, param)
			.then(res => {
				if (res.data.transfers) {
					this.setState({
						last_page: res.data.transfers.last_page,
						data: res.data.transfers.data,
					});
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	};

	close = () => {
		this.setState({
			create: false,
			view: false,
			edit: null,
			user: null
		});

		this.load();
	};

	delete = (item, i) => {
		Globals.confirm("¿Desea eliminar el almacén: " + item.name + "?", () => {
			Globals.setLoading("Guardando...");
			axios
				.post(`web/admin/warehouses/delete`, { warehouse_id: item.id })
				.then(res => {
					Globals.showSuccess('Almacén eliminado');
					this.load();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	};

	handleChange = emitter => {
		const { name, value } = emitter.target;

		this.setState({
			form: {
				...this.state.form,
				[name]: value
			}
		});
	};

	render() {
		const { history, user } = this.props
		const { create, data, edit, header, search, products, selectedTranfer } = this.state
		const titleTable = 'Transferencias';
		const userTypeText = 'Transferencia';
		return (
			<Menu history={history}>

				{create && (
					<Modal
						title={edit ? `Editar ${userTypeText}` : `Nueva ${userTypeText}`}
						onClose={this.close}
						visible
					>
						<CreateEditWarehouse 
							edit={edit} 
							onClose={this.close}
						/>
					</Modal>
				)}

        <Modal
          title="Productos transferidos"
          onClose={() => this.setState({ selectedTranfer: null })}
          visible={selectedTranfer}
        >
          <p>Registrado por: <b>{ selectedTranfer?.registered_by?.name }</b></p>
          <table style={{ width: '100%' }}>
            <thead>
              <tr>
                <th>Producto</th>
                <th style={{ textAlign: 'right' }}>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {selectedTranfer?.details?.map((item, index) => (
                <tr key={index} style={{ borderBottom: '1px solid lightgray' }}>
                  <td>{ item?.product?.name }</td>
                  <td style={{ textAlign: 'right' }}>{ item?.quantity }</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>

				<div id="home">
					{/* <div className="row">
						<div className="col-md">
							<Input
								color=" "
								name="search"
								label="Búsqueda"
								className="filter"
								onChange={this.handleChange}
								value={search}
								placeholder="Buscar por Nombre"
							/>
						</div>
						<div className="col-md">
							<Button
								className="btn-align-bottom btn-filter"
								color=" "
								onClick={async () => {
									await this.setState({ page: 1 });
									this.load();
								}}
							>
								<span>Filtrar</span>
							</Button>
						</div>
					</div> */}
					<Table
						data={data.length}
						title={titleTable}
						header={header}
						right={
							<>
								{user.level_id === Constants.ROLE_ADMIN && (
									<Button
										title="Nueva Transferencia"
										small="true"
										onClick={() => {
											this.setState({
												create: true
											});
										}}
									>
										<img src={AddIcon} alt="Agregar Almacén" />
									</Button>
								)}
								{user.level_id === Constants.ROLE_MODERATOR && user.warehouse_id === null && (
									<Button
										title="Nueva Transferencia"
										small="true"
										onClick={() => {
											this.setState({
												create: true
											});
										}}
									>
										<img src={AddIcon} alt="Agregar Almacén" />
									</Button>
								)}
							</>
						}
					>
						{data.map((item, index) => {
							return (
								<tr key={index}>
									<td>{ item.origin_warehouse?.name }</td>
                  <td>{ item.destiny_warehouse?.name }</td>
                  <td>{ item.created_es }</td>
                  <td>{ item.registered_by?.name }</td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => this.setState({ selectedTranfer: item })}
                    >
                      <Icon name="eye" />
                    </Button>
                  </td>
								</tr>
							);
						})}
					</Table>

					<Pagination
						pages={this.state.last_page}
						active={this.state.page}
						onChange={page => {
							this.setState(
								{
									page: page
								},
								() => this.load()
							);
						}}
					/>
				</div>
			</Menu>
		);
	}
}

export default connect(state => {
	return {
		user: state.user
	};
})(TransferWarehouses);
