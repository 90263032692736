import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Globals, Constants } from "../../utils";
import { Button, Input, Modal, Table } from "../../components";
import { Order } from '../../services';
import CloseIcon from '../../assets/icons/close-black.png';
// Components
import Menu from "../menu";

const TABLE_HEADER = [
  'Código',
  'Descripción',
  'Cantidad',
  'Precio unitario',
  'Descuento %',
  'Neto',
  ''
];

class Returns extends React.Component {

  state = {
    showQuestions: false,
    showCreditNote: false,
    form: {
      user_id: this.props.user.id,
      registered_by: this.props.user.id,
      status: Constants.ORDER_STATUS.DISPATCHED,
      return_filter: true,
      order_id: '',
      motive: '',
    },
    order: {},
    details: [],
    questions: []
  }

  getOrder = () => {
    if (!this.state.form.order_id) return;
    Globals.setLoading();

    Order.getDispatch(this.state.form)
    .then(res => {
      if (!!res.error) throw res.error;
      if (!res.order) throw new Error('No se encontró la orden');

      const details = Globals.copy(res.order.details.map(x => ({
        ...x,
        has_conditions: false,
        original_qty: x.quantity,
      })));

      this.setState({
        order: {
          ...res.order,
          details,
        },
        details,
      });
    })
    .catch(Globals.showError)
    .finally(() => {
      Globals.quitLoading();
    });
  }

  onSearch = (e) => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });

    if (this.debounce) clearTimeout(this.debounce);
    this.debounce = setTimeout(() => {
      this.getOrder();
    }, 800);
  }

  changeProduct = (e, index) => {
    const details = Globals.copy(this.state.details);
    details[index][e.target.name] = e.target.value;

    if (e.target.name === 'quantity') {
      const subtotal = details[index].price * parseFloat(e.target.value || 0).toFixed(2);
      const discount = subtotal * (details[index].discount_percentage / 100);
      const total = parseFloat(subtotal - discount).toFixed(2);
      details[index].subtotal = parseFloat(subtotal).toFixed(2);
      details[index].total = total;
    }

    if (e.target.name === 'has_conditions') {
      details[index][e.target.name] = Boolean(+e.target.value);
    }

    this.setState({ details });
  }

  changeQuestion = (e, index) => {
    const questions = Globals.copy(this.state.questions);

    if (e.target.name === 'has_conditions') {
      questions[index][e.target.name] = Boolean(+e.target.value);
    }

    this.setState({ questions });
  }

  removeItem = (index) => {
    const details = Globals.copy(this.state.details).filter((_, idx) => idx !== index);
    this.setState({ details });
  }

  resetForm = () => {
    this.setState(state => ({
      showQuestions: false,
      showCreditNote: false,
      form: {
        ...state.form,
        order_id: '',
      },
      order: {},
      details: [],
      questions: [],
    }));
  }

  toggleModal = () => {
    this.setState(state => ({ showQuestions: !state.showQuestions }));
  }

  getTotals = () => {
    const { order, details } = this.state;

    const discount = order?.discount || 0;
    const discountPercentage = order?.discount_percentage || 0;
    let subtotal = order?.subtotal || 0;
    let total = order?.total || 0;

    if(order.details){
      let order_details_quantity = 0;
      order.details.forEach(element => {
        order_details_quantity += element.quantity;
      });

      let details_quantity = 0;
      details.forEach(element => {
        details_quantity += Number(element.quantity);
      });

      if(details_quantity !== order_details_quantity){
        total = 0
        subtotal = 0
        details.forEach(item => {
          const _subtotal = (item.price * item.quantity);
          const discount = _subtotal * (item.discount_percentage / 100);
          subtotal += (_subtotal - discount);
          total += (_subtotal - discount);
        });
        if(order.discount_percentage){
          const discount_general = total * (order.discount_percentage / 100);
          total = (total - discount_general);
        }
      }
    }

    return {
      discount: parseFloat(discount).toFixed(2),
      discountPercentaje: discountPercentage,
      subtotal: parseFloat(subtotal).toFixed(2),
      total: parseFloat(total).toFixed(2),
    }
  }

  getReturnedItems = () => {
    const { details, order } = this.state;
    const updated = details.filter(x => x.quantity < x.original_qty);
    const removed = order?.details
      ?.filter(original => !details.some(item => item.id === original.id))
      ?.map(x => ({ ...x, was_returned: true })) || [];

    return [
      ...updated,
      ...removed,
    ];
  }

  showDetails = async () => {
    if (!this.isValidForm('details')) return;
    await this.setState({
      questions: this.getReturnedItems().map(x => ({ id: x.id })),
    });
    this.toggleModal();
  }

  showCreditNote = () => {
    if (!this.isValidForm('questions')) return;
    this.setState({ showQuestions: false, showCreditNote: true });
  }

	isValidForm = (type) => {
		const onError = (message) => {
			Globals.showError(message);
			return false;
		}

		const { details, form, questions } = this.state;

    if (type === 'details') {
      if (!this.getReturnedItems().length)
        return onError('No hay productos para devolver');

      const someGreaterThanOriginal = details.some(x => x.quantity > x.original_qty);

      if (someGreaterThanOriginal)
        return onError('La cantidad de productos no puede ser mayor a la original');

      if (details.some(x => x.quantity < 1))
        return onError('Los productos en cero deben ser eliminados de la lista');
    }

    if (type === 'questions') {
      if (!form.motive)
        return onError('El motivo de devolución es obligatorio');

      if (!questions.every(x => typeof x?.has_conditions === "boolean"))
        return onError('Debe responder todas las preguntas de los productos');
    }

		return true;
	}

  submit = () => {
    Globals.setLoading();
    const { details, form, questions } = this.state;
    const { subtotal, total, discount, discountPercentaje } = this.getTotals();

    const updatedItems = this.getReturnedItems().map((item, index) => ({
      ...item,
      has_conditions: questions[index]?.has_conditions || false,
    }));

    const unupdateItems = details.filter(x => x.quantity === x.original_qty);

    const data = {
      ...form,
      date: moment().format('YYYY-MM-DD'),
      subtotal,
      total,
      discount,
      discount_percentage: discountPercentaje,
      credit_note: true,
      details: [
        ...updatedItems,
        ...unupdateItems
      ],
    };

    Order.createReturn(data)
      .then(res => {
        if (!!res.error || !res.result) throw res.error;
        Globals.showSuccess('La devolución se ha guardado con éxito en Reportes');
        this.resetForm();
      })
      .catch(Globals.showError)
      .finally(() => Globals.quitLoading());
  }

  render() {
    const { showCreditNote, showQuestions, details, order, form, questions } = this.state;
    const { discountPercentaje, subtotal, total } = this.getTotals();

    return (
      <Menu history={this.props.history}>
        <Modal
          className="modal-returns"
          onClose={this.toggleModal}
          visible={showQuestions}
        >
          <Input
            color=" "
            name="motive"
            label="Indique el motivo de la devolución (obligatorio)"
            placeholder="Indique el motivo"
            className="material"
            labelClass="material"
            value={form.motive}
            onChange={e => this.setState({ form: { ...form, motive: e.target.value }})}
          />

          {this.getReturnedItems().map((item, index) => (
            <div key={`question-${index}`}>
              <p className="question">
                ¿La mercancía devuelta ({ item.product.name }) se encuentra en condiciones para su reingreso a inventario?
              </p>
              <div className="resp-row">
                <div className="resp-row-item">
                  <input
                    id={`question${index}-yes`}
                    name="has_conditions"
                    type="checkbox"
                    checked={questions[index]?.has_conditions === true}
                    value="1"
                    onChange={e => this.changeQuestion(e,index)}
                  />
                  <label htmlFor={`question${index}-yes`}>Si</label>
                </div>

                <div className="resp-row-item">
                  <input
                    id={`question${index}-no`}
                    name="has_conditions"
                    type="checkbox"
                    checked={questions[index]?.has_conditions === false}
                    value="0"
                    onChange={e => this.changeQuestion(e,index)}
                  />
                  <label htmlFor={`question${index}-no`}>No</label>
                </div>
              </div>
            </div>
          ))}

          <footer>
            <Button
              className="btn-align-bottom btn-filter"
              color=" "
              onClick={this.toggleModal}
            >
              <span>CERRAR</span>
            </Button>

            <Button
              className="btn-align-bottom btn-filter"
              color=" "
              onClick={() => this.showCreditNote()}
            >
              <span>ACEPTAR</span>
            </Button>
          </footer>
        </Modal>

        {showCreditNote ? (
          <div id="returns-credit-note">
            <h5 className="title"><b>Verificar</b></h5>
            <main>
              <h6><b>Nota de entrega: { order?.number_format }</b></h6>
              <div className="header">
                <div>
                  <p>Cliente: { order?.client?.name }</p>
                  <p>Vendedor: { order?.seller?.name }</p>
                </div>
                <div>
                  <p>{ moment().format('D/M/YYYY') }</p>
                </div>
              </div>

              <table border={1}>
                <thead>
                  <tr>
                    <td>Código</td>
                    <td>Descripción</td>
                    <td>Cantidad</td>
                    <td>Precio unitario</td>
                    <td>Descuento %</td>
                    <td>Neto</td>
                  </tr>
                </thead>
                <tbody>
                  {details.map(item => (
                    <tr>
                    <td>{ item.product.code }</td>
                    <td>{ item.product.name }</td>
                    <td>{ item.quantity }</td>
                    <td>{ Globals.formatMiles(item.price) }</td>
                    <td>{ item.discount_percentage }%</td>
                    <td>{ Globals.formatMiles(item.total) }</td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="totals">
                <div>
                  <span>Subtotal</span>
                  <span>${ subtotal }</span>
                  <span>% Descuento</span>
                  <span>{ discountPercentaje }%</span>
                  <span>Total</span>
                  <span>${ total }</span>
                </div>
              </div>
            </main>

            <footer>
              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                onClick={() => this.setState({ showCreditNote: false })}
              >
                <span>REGRESAR</span>
              </Button>

              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                onClick={this.submit}
              >
                <span>ACEPTAR</span>
              </Button>
            </footer>
          </div>
        ) : (
          <div id="returns">
            <div className="header">
              <div className="filters">
                <div className="col-md-4 px-0">
                  <Input
                    color=" "
                    name="order_id"
                    placeholder="--"
                    label="N° de entrega asociada"
                    className="filter"
                    value={form.order_id}
                    onKeyPress={Globals.soloNumeros}
                    onChange={this.onSearch}
                  />
                </div>
                <div className="col-md-3 pr-0">
                  <Input
                    color=" "
                    name="clientName"
                    placeholder="--"
                    label="Cliente"
                    className="filter"
                    value={order?.client?.name || ''}
                    onChange={() => {}}
                  />
                </div>
                <div className="col-md-3 pr-0">
                  <Input
                    color=" "
                    name="sellerName"
                    placeholder="--"
                    label="Vendedor"
                    className="filter"
                    value={order?.seller?.name || ''}
                    onChange={() => {}}
                  />
                </div>
              </div>

              <div className="actions">
                <Button
                  className="btn-align-bottom btn-filter"
                  color="green"
                  onClick={this.showDetails}
                  disabled={!order?.id}
                >
                  <span>Aceptar</span>
                </Button>

                <Button
                  className="btn-align-bottom btn-filter"
                  color="red"
                  onClick={this.resetForm}
                  disabled={!order?.id}
                >
                  <span>Limpiar</span>
                </Button>
              </div>

            </div>

            <main>
              <Table
                data={details.length}
                title="Nota de entrega"
                header={TABLE_HEADER}
              >
                {details.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>{ item.product.code }</td>
                      <td>{ item.product.name }</td>
                      <td>
                        <input
                          name="quantity"
                          value={item.quantity}
                          onKeyUp={Globals.soloNumeros}
                          onChange={e => {
                            if (Number(e.target.value) > -1 && Number(e.target.value) <= item.original_qty)
                              this.changeProduct(e, index);
                          }}
                        />
                      </td>
                      <td>{ Globals.formatMiles(item.price) }</td>
                      <td>{ item.discount_percentage }%</td>
                      <td>{ Globals.formatMiles(item.total) }</td>
                      <td>
                        <button
                          className="table-row-action"
                          title="Remover"
                          onClick={() => this.removeItem(index)}
                        >
                          <img src={CloseIcon} alt="Remover" />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </Table>

              <div className="totals">
                <div className="title">Monto Total</div>
                <div className="item">
                  <div>Subtotal</div>
                  <div>${ Globals.formatMiles(subtotal) }</div>
                </div>
                <div className="item">
                  <div>% Descuento</div>
                  <div>{ Globals.formatMiles(discountPercentaje )}%</div>
                </div>
                <div className="item">
                  <div><b>Total</b></div>
                  <div><b>${ Globals.formatMiles(total) }</b></div>
                </div>
              </div>
            </main>
          </div>
        )}
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
  };
})(Returns);