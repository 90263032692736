import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Globals, Constants } from "../../utils";

import { Pagination, Button, Modal, Table, Icon } from "../../components";
import {
  ExpensesIngress as ExpensesIngressService,
  Provider,
  Bank
} from "../../services";

// Components
import Menu from "../menu";
import View from "./view";
import Filter from "./filterForm";
import CreateEditExpensesIngress from "./create_edit_expenses-ingress";

const TABLE_HEADER = [
  "Nro. de Factura",
  "Nro. de Control",
  "Proveedor",
  "Fecha Emision",
  "Tipo de Pago",
  "Estatus",
  "Total",
  "Acciones"
];

class ExpensesIngress extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        data: [],
        page: 1,
        Item: "",
        current_page: 1,
        last_page: 1,
        visibleCreate: false,
        visiblePurchase: false,
        showLg: true,
        providers: [],
        banks: []
      };
    }
  
    componentDidMount() {
        this.load();
    }

    load = () => {
        
        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        Promise.all([
            Provider.getProviders({ Id: enterpriseId }),
            ExpensesIngressService.getExpensesIngress(page, {
              enterprise_id: enterpriseId
            }),
            Bank.getBanks({enterprise_id: enterpriseId})
        ])
        .then(response => {
            const { current_page, data, last_page } = response[1];

            this.setState({
                providers: response[0],
                banks: response[2],
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    loadMore = () => {

        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        ExpensesIngressService.getExpensesIngress(page, {
            enterprise_id: enterpriseId,
        })
        .then(response => {
            const { current_page, data, last_page } = response;

            this.setState({
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    filterMap = form => {

        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    
        ExpensesIngressService.getExpensesIngress(page, {
            enterprise_id: enterpriseId,
            ...form
        })
        .then(response => {
            const { current_page, data, last_page } = response;

            this.setState({
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    close = () => {
        this.setState({
            visibleCreate: false,
            visiblePurchase: false
        });
    };

    deployPurchase = Item => {
        this.setState({ Item: Item, visiblePurchase: true });
    };

    rawProvider = Provider => {
        this.setState(state => ({
            showLg: true,
            providers: [...state.providers, Provider]
        }));
    };
    
    removeAttribute = () => {
        let showLg = !this.state.showLg;
        this.setState({ showLg: showLg });
    };

    render() {
        const {
            data,
            Item,
            showLg,
            providers,
            banks,
            visibleCreate,
            visiblePurchase
        } = this.state;
    
        const { user, history, sidebar } = this.props;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
        const providersMapped = providers
        .filter(Item => Item.status === Constants.STATUS_ACTIVE)
        .map((Item, key) => {
          return {
            value: Item.id,
            label: Item.name,
            time_limit: Item.configuration_provider ? Item.configuration_provider.days_deadline : ''
          };
        })

        const banksMapped = banks
        .filter(Item => Item.status === Constants.STATUS_ACTIVE)
        .map((Item, key) => {
          return {
            value: Item.id,
            label: Item.name,
            type: Item.type
          };
        })
    
        return (
          <Menu history={history}>
            {visibleCreate && (
              <Modal
                className={showLg ? "modal-order" : ""}
                title="Crear Ingreso de Factura"
                onClose={this.close}
                visible
              >
                <CreateEditExpensesIngress
                    removeAttribute={this.removeAttribute}
                    newProvider={Provider => this.rawProvider(Provider)}
                    onClose={this.close}
                    havePermission={
                    sidebar.find(Item => Item.name === "Proveedores") ? true : false
                    }
                    enterpriseId={enterpriseId}
                    Item={null}
                    providers={providersMapped}
                    banks={banksMapped}
                    user={user}
                />
              </Modal>
            )}
    
            {visiblePurchase && (
              <Modal
                className="modal-order"
                title="Visualizar Factura"
                onClose={this.close}
                visible
              >
                <View Item={Item} />
              </Modal>
            )}
    
            <div id="home">
              <div className="container-fluid">
                <Filter
                  submitted={form => this.filterMap(form)}
                  enterpriseId={enterpriseId}
                  providers={providersMapped}
                />
    
                <div className="row">
                  <div className="col-md">
                    <Table
                        data={data.length}
                        title="Facturas"
                        header={TABLE_HEADER}
                        right={
                            <Button
                                title="Agregar Factura"
                                outline="true"
                                small="true"
                                onClick={() => {
                                    this.setState({
                                        visibleCreate: true,
                                        showLg: true
                                    });
                                }}
                            >
                                <Icon name="plus" />
                            </Button>
                        }
                    >
                      {data.map((Item, index) => {
                        return (
                            <tr key={index}>
                                <td className="text-center">
                                    {(Item || {}).bill_number || "-"}
                                </td>
                                <td className="text-center">
                                    {(Item || {}).control_number || "-"}
                                </td>
                                <td>{Item.provider.name}</td>
                                <td className="text-center">
                                    {moment(Item.date_emission).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                    {Item.payment_type === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH
                                    ? "Contado"
                                    : "Credito"}
                                </td>
                                <td className={Item.status ? "text-success" : "text-danger"}>
                                    {Item.status ? "PAGADA" : "SIN PAGAR"}
                                </td>
                                <td>{Globals.formatMiles(Item.total)}</td>
                                <td>
                                    <Button
                                        color="primary"
                                        title="Ver mas"
                                        small="true"
                                        onClick={() => this.deployPurchase(Item)}
                                    >
                                        <Icon name="eye" />
                                    </Button>
                                </td>
                            </tr>
                        );
                      })}
                    </Table>
                  </div>
                </div>
    
                <div className="row my-3">
                  <div className="col-md">
                    <Pagination
                      pages={this.state.last_page}
                      active={this.state.page}
                      onChange={page => {
                        this.setState(
                          {
                            page: page
                          },
                          () => {
                            this.loadMore();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        );
    }
}

export default connect(state => {
    return {
      user: state.user,
      sidebar: state.sidebar
    };
  })(ExpensesIngress);