import React from "react";
import moment from "moment";

import { connect } from "react-redux";

import { Pagination, Button, Modal, Table, Icon } from "../../components";
import { Globals, Constants, Format, Colors } from "../../utils";
import { Product, Currency, Request, Branch, Seller } from "../../services";

// Components
import View from "./view";
import Menu from "../menu";
import Filter from "./filterForm";
import CreateEditRequest from "./create_edit_request";
import AddIcon from '../../assets/icons/add.png';

const TABLE_HEADER = [
  "Nro. Pedido",
  "Divisa",
  "Cliente",
  "Usuario",
  "Fecha de emisión",
  "Status",
  "Total",
  "Acciones"
];

const TYPE_LIST = {
  PENDIENTE: 0,
  PROCESADO: 1,
  ANULADO: 2
};

class Requests extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      form: {
        since: null,//new Date(new Date().setHours(0,0,0,0)),
        until: null,//new Date(new Date().setHours(24,0,0,0))
      },
      current_page: 1,
      last_page: 1,
      currencies: [],
      customers: [],
      branches: [],
      sellers: [],
      visibleCreate: false,
      visibleEdit: false,
      visibleProcessing: false,
      visibleRequest: false,
      showLg: true,
      products: []
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { user } = this.props;
    const { page, form } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const zonesId = Globals.getZones(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);

    const getCustomers =
      user.role === Constants.ROLE_SELLER
        ? Request.getCustomersBySeller({
          zones_id: zonesId,
          enterprise_id: enterpriseId
        })
        : Request.getCustomersByEnterprise({ Id: enterpriseId });

    Globals.setLoading();

    Promise.all([
      Product.getAllProductsByEnterprise({
        user_id: enterpriseId
      }),
      Request.getRequests(page, {
        user_id: user.id,
        Id: enterpriseId,
        warehouse_id: warehouse_id,
        role: user.role,
        ...form
      }),
      getCustomers,
      Currency.getCurrenciesFromPurchase(),
      Seller.getSellersHaveRequests({ user_id: enterpriseId }),
    ])
      .then(response => {
        const productsMap = response[0].map(Item => {
          let current_stock = 0;

          if (Item.last_movement) {
            current_stock =
              Item.last_movement.type === 1
                ? Item.last_movement.current_stock + Item.last_movement.amount
                : Item.last_movement.current_stock - Item.last_movement.amount;
          }

          let prices = [];
          if (user.role === Constants.ROLE_SELLER) {
            prices = [
              {
                value: 1,
                label: "Precio Min."
              }
            ]
            if (user.configuration_seller.price_max === 1)
              prices.push({ value: 2, label: "Precio Max." })
            if (user.configuration_seller.price_offer === 1)
              prices.push({ value: 3, label: "Precio Oferta" })

          } else {
            prices = [
              {
                value: 1,
                label: "Precio Min."
              },
              {
                value: 2,
                label: "Precio Max."
              },
              {
                value: 3,
                label: "Precio Oferta"
              }
            ]
          }

          return {
            id: Item.product.id,
            name: Item.product.name,
            code: Item.product.code,
            last_movement: current_stock,
            warehouse_id: Item.warehouse_id,
            serialization: Item.product.serialization,
            prices: prices,
            stock_min: Item.stock_min,
            quantity: "",
            rate: 1,
            price: Item.price,
            price_base: Item.price,
            price_offer: Item.price_offer,
            price_max: Item.price_max,
            exempt: Item.product.exempt,
            subtotal: 0,
            vat: 0,
            total: 0,
            stock: 0
          };
        });

        const { current_page, data, last_page } = response[1];

        const customersMap = response[2];

        const currenciesMap = response[3]
          .filter(Item => Item.status === Constants.STATUS_ACTIVE)
          .map(Item => {
            return {
              value: Item.id,
              label: Item.name
            };
          });

        const sellersMap = Format.rawSeller(response[4]);

        this.setState({
          products: productsMap,
          customers: customersMap,
          currencies: currenciesMap,
          sellers: sellersMap,
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  componentDidUpdate(_, prevState) {
    if (
      prevState.visibleCreate !== this.state.visibleCreate &&
      this.state.visibleCreate
    ) {
      this.maybeLoad();
    }
  }

  maybeLoad() {
    const { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Branch.getBranchesByEnterprise({ Id: enterpriseId })
      .then(response => {
        let branches_filtered = response.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        this.setState({
          branches: branchesMap
        });
      })
      .catch(() => Globals.showError());
  }

  loadMore = () => {
    const { user } = this.props;
    const { page, form } = this.state;

    const Id = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Request.getRequests(page, {
      Id: Id,
      warehouse_id: warehouse_id,
      role: user.role,
      user_id: user.id,
      ...form
    })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;
    const { page } = this.state;
    const Id = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Globals.setLoading();

    if(form.status >= 0){
      Request.getRequests(1, {
        Id: Id,
        warehouse_id: warehouse_id,
        role: user.role,
        ...form
      })
        .then(response => {
          const { current_page, data, last_page } = response;
  
          this.setState({
            data: data,
            form: { ...form },
            current_page: current_page,
            last_page: last_page
          });
        })
        .catch(() => Globals.showError())
        .finally(() => Globals.quitLoading());
    }
    else{
      Request.getRequests(page, {
        Id: Id,
        warehouse_id: warehouse_id,
        role: user.role,
        ...form
      })
        .then(response => {
          const { current_page, data, last_page } = response;
  
          this.setState({
            data: data,
            form: { ...form },
            current_page: current_page,
            last_page: last_page
          });
        })
        .catch(() => Globals.showError())
        .finally(() => Globals.quitLoading());
    }
    
  };

  rawCustomer = Customer => {
    this.setState(state => ({
      showLg: true,
      customers: [...state.customers, Customer]
    }));
  };

  deployProcessing = Item => {
    this.setState({ Item: Item, visibleProcessing: true });
  };

  deployEdit = Item => {
    this.setState({ Item: Item, visibleCreate: true, visibleEdit: true });
  };

  deployRequest = Item => {
    this.setState({ Item: Item, visibleRequest: true });
  };

  rejectRequest = Item => {
    Globals.confirm(`¿Desea anular el pedido con el Nro. ${Item.code}?`, () => {
      Request.rejectRequest({ Id: Item.id })
        .then(() =>
          Globals.showSuccess("¡Se ha anulado el pedido exitosamente!")
        )
        .catch(() => Globals.showError())
        .finally(() => this.load());
    });
  };

  close = () => {
    this.setState(
      {
        visibleCreate: false,
        visibleEdit: false,
        visibleRequest: false,
        visibleProcessing: false
      },
      () => this.load()
    );
  };

  removeAttribute = () => {
    let showLg = !this.state.showLg;
    this.setState({ showLg: showLg });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case TYPE_LIST.PENDIENTE:
        respuesta = {
          text: "Pendiente",
          color: Colors.orange
        };
        break;
      case TYPE_LIST.PROCESADO:
        respuesta = {
          text: "Procesado",
          color: Colors.green
        };
        break;
      case TYPE_LIST.ANULADO:
        respuesta = {
          text: "Anulado",
          color: Colors.red
        };
        break;
    }
    return respuesta;
  };

  render() {
    const {
      branches,
      sellers,
      customers,
      currencies,
      data,
      Item,
      last_page,
      page,
      products,
      showLg,
      visibleCreate,
      visibleEdit,
      visibleRequest,
      visibleProcessing
    } = this.state;

    const { user, history, sidebar } = this.props;
    const Id = (user.enterprise_users || {}).enterprise_id || user.id;

    return (
      <Menu history={history}>
        {(visibleCreate || visibleEdit) && (
          <Modal
            className={showLg ? "modal-delivery" : ""}
            title={`${visibleEdit ? 'Editar':'Nuevo'} Pedido`}
            onClose={this.close}
            visible
          >
            <CreateEditRequest
              removeAttribute={this.removeAttribute}
              newCustomer={Customer => this.rawCustomer(Customer)}
              onClose={this.close}
              havePermission={
                sidebar.find(Item => Item.name === "Clientes") ? true : false
              }
              Id={Id}
              Item={visibleEdit ? Item : null}
              products={products}
              currencies={currencies}
              customers={customers.filter(
                Item => (Item || {}).status === Constants.STATUS_ACTIVE
              )}
              branches={branches}
              user={user}
            />
          </Modal>
        )}

        {visibleProcessing && (
          <Modal
            className="modal-delivery"
            title="Procesar Pedido"
            onClose={this.close}
            visible
          >
            <CreateEditRequest
              onClose={this.close}
              havePermission={false}
              Id={Id}
              Item={Item}
              customers={customers}
              currencies={currencies}
              branches={branches}
              user={user}
            />
          </Modal>
        )}

        {visibleRequest && (
          <Modal
            className="modal-delivery"
            title="Detalle de pedido"
            onClose={this.close}
            visible
          >
            <View
              status
              Item={Item}
              Id={Id}
              user={this.props.user}
              onClose={this.close}
            />
          </Modal>
        )}

        <div id="delivery">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              user={user}
              since={new Date(new Date().setHours(0,0,0,0))}
              until={new Date(new Date().setHours(24,0,0,0))}
              Id={Id}
              sellers={sellers}
              status
              customers={customers.map((Item, key) => {
                return {
                  value: Item.id,
                  label:
                    Item.person.first_name
                };
              })}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Pedidos"
                  header={TABLE_HEADER}
                  right={
                    <Button
                      title="Agregar Pedidos"
                      small="true"
                      onClick={() => {
                        this.setState({
                          visibleCreate: true,
                          showLg: true
                        });
                      }}
                    >
                      <img src={AddIcon} alt="Agregar Pedidos" />
                    </Button>
                  }
                >
                  {data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>{Item.code}</td>
                        <td>{(Item.currency || {}).name || "-"}</td>
                        <td>
                          {(Item.client.person || {}).first_name ||
                            (Item.client || {}).name}
                        </td>
                        <td>
                          {(Item.creator.person || {}).first_name ||
                            (Item.creator || {}).name}
                        </td>
                        <td className="text-center">
                          {Item.date_emission
                            ? moment(Item.date_emission).format("DD/MM/YYYY")
                            : "-"}
                        </td>
                        <td
                          style={{
                            color: this.getStatus(Item.status).color
                          }}
                        >
                          {this.getStatus(Item.status).text}
                        </td>
                        <td>{Globals.formatMiles(Item.total)}</td>
                        <td>
                          <Button
                            color=" "
                            title="Ver más"
                            small="true"
                            style={{backgroundColor: '#5c068c'}}
                            onClick={() => this.deployRequest(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                          {!Item.status && (
                            <React.Fragment>
                              <Button
                                color="info"
                                title="Editar"
                                small="true"
                                style={{backgroundColor: '#0f54e0'}}
                                onClick={() => this.deployEdit(Item)}
                              >
                                <Icon name="edit" />
                              </Button>
                              <Button
                                color="red"
                                title="Anular"
                                small="true"
                                onClick={() => this.rejectRequest(Item)}
                              >
                                <Icon name="ban" />
                              </Button>
                            </React.Fragment>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={last_page}
                  active={page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => {
                        this.loadMore();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(Requests);
