import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Globals, Constants } from "../../../utils";
import { Pagination, Button, Modal, Table, Icon } from "../../../components";
import {
  BankingTransactions as BankingTransactionsService,
  Bank
} from "../../../services";


// Components
import Menu from "../../menu";
import View from "./view";
import Filter from "./filterForm";

import NumberFormat from "react-number-format";

const TABLE_HEADER = [
  "Fecha",
  "Bancos",
  "Cuenta Bancaria",
  "Concepto",
  "Ingreso",
  "Egreso",
  "Saldo",
  "Acciones"
];

class BankingTransactions extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      Item: "",
      form: {
        movement_type: 0,
        bank_id: 0,
        bank_account_id: 0,
        until: 0,
      },
      current_page: 1,
      last_page: 1,
      visiblePurchase: false,
      visibleForm: false,
      showLg: true,
      banks: []
    };
  }


  load = () => {

    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Promise.all([
      BankingTransactionsService.getBankingTransactions(page, {
        enterprise_id: enterpriseId
      }),
      Bank.getBanks({ enterprise_id: enterpriseId })
    ])
      .then(response => {
        const { current_page, data, last_page } = response[0];
        this.setState({
          banks: response[1],
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(error => {
        Globals.showError();
      });
  };


  componentDidMount() {
    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Bank.getBanks({ enterprise_id: enterpriseId })
      .then(response => {
        this.setState({
          banks: response
        });
      })
      .catch(error => {
        Globals.showError();
      });
  }

  loadMore = () => {

    const { user } = this.props;
    const { page, form } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    BankingTransactionsService.getBankingTransactions(page, {
      enterprise_id: enterpriseId,
      ...form
    })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  filterMap = form => {

    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    BankingTransactionsService.getBankingTransactions(page, {
      enterprise_id: enterpriseId,
      ...form
    })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          data: data,
          current_page: current_page,
          last_page: last_page,
          form: {
            ...form
          }
        });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  close = () => {
    this.setState({
      visiblePurchase: false,
      visibleForm: false
    }, () => {
      this.loadMore();
    });
  };

  deployReport = Item => {
    this.setState({ Item: Item, visiblePurchase: true });
  };

  deployForm = Item => {
    this.setState({ Item: Item, visibleForm: true });
  }; data



  removeAttribute = () => {
    let showLg = !this.state.showLg;
    this.setState({ showLg: showLg });
  };

  render() {

    const {
      data,
      Item,
      banks,
      visiblePurchase,
      visibleForm,
    } = this.state;
    const { user, history } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    const banksMapped = banks
      .filter(Item => Item.status === Constants.STATUS_ACTIVE)
      .map((Item, key) => {
        return {
          value: Item.id,
          label: Item.name,
          type: Item.type
        };
      })

    return (
      <Menu history={history}>

        {visiblePurchase && (
          <Modal
            className="modal-order"
            title="Visualizar Reporte"
            onClose={this.close}
            visible>
            <View Item={Item}
              bank={banks.find(x => x.id === Item.bank_id)} />
          </Modal>
        )}

        <div id="home">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              enterpriseId={enterpriseId}
              user={user}
              banks={banks}
              banksMapped={banksMapped}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Reporte Movimientos bancarios"
                  header={TABLE_HEADER}
                  emptyMessage="Seleccione el banco y la cuenta bancaria para ver los movimientos"
                >
                  {data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {moment(Item.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {Item.bank.name}
                        </td>
                        <td>
                          {(Item.bank_account || {}).account_number}
                        </td>
                        <td>
                          {Item.description}
                        </td>
                        <td>
                          {Item.type === Constants.TYPE_ENTRY ?
                            <NumberFormat
                              value={Item.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            /> : '---'}
                        </td>
                        <td>
                          {Item.type === Constants.TYPE_OUTPUT ?
                            <NumberFormat
                              value={Item.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            /> : '---'}
                        </td>
                        <td>
                          {Item.type === Constants.TYPE_OUTPUT ?
                            <NumberFormat
                              value={Item.current_amount - Item.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              fixedDecimalScale={true}
                              decimalScale={2}
                            /> :
                            <NumberFormat
                              value={Item.current_amount + Item.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                            />
                          }
                        </td>
                        <td>
                          <Button
                            color="primary"
                            title="Ver mas"
                            small="true"
                            onClick={() => this.deployReport(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={this.state.last_page}
                  active={this.state.page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => {
                        this.loadMore();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }

}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(BankingTransactions);