import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals, Colors } from "../../utils";
import CreaEditSubcategory from "./create_edit_subcategory";
import ViewSubcategory from "./view_subcategory";
import $ from "jquery";
import AddIcon from '../../assets/icons/add.png';

class Subcategory extends React.Component {
  state = {
    header: ["Nombre", "Código", "Categoría", "Status", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    category: null,
    view: false,
    list_status: [
      { value: "", label: "Todos" },
      { value: 0, label: "Suspendidas" },
      { value: 1, label: "Activas" }
    ],
    form: {
      status: "",
      search: "",
      category_id: ""
    }
  };
  componentDidMount() {
    this.setCategory(this.props.match.params.category_id);
    setTimeout(async () => {
      await $(".tooltip").tooltip("hide");
    }, 100);
  }
  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let param = this.props.location.pathname.split("/");
      param = param[param.length - 1];
      this.setCategory(param);
    }
    setTimeout(async () => {
      await $(".tooltip").tooltip("hide");
    }, 100);
  }
  setCategory = async param => {
    await this.setState({
      form: {
        ...this.state.form,
        category_id: param
      }
    });
    this.load();
  };
  load = () => {
    Globals.setLoading();
    axios
      .post("admin/subcategories/get?page=" + this.state.page, this.state.form)
      .then(async res => {
        if (res.data.result) {
          if (this.state.page > 1 && res.data.subcategories.data.length === 0) {
            await this.setState({
              page: 1
            });
            this.load();
          } else {
            await this.setState({
              last_page: res.data.subcategories.last_page,
              data: res.data.subcategories.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };
  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Suspendida",
          color: Colors.orange
        };
        break;

      case 1:
        respuesta = {
          text: "Activa",
          color: Colors.green
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      category: null
    });
    await this.load();
  };

  suspend = (item, i) => {
    let text = "¿Desea suspender la subcategoría ";
    if (item.status !== 1) {
      text = "¿Desea activar la subcategoría ";
    }
    Globals.confirm(text + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/subcategories/suspend", { id: item.id })
        .then(res => {
          if (res.data.result) {
            Globals.showSuccess(res.data.msg);
            this.load();
          } else {
            Globals.showError();
          }
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm(
      "¿Desea eliminar la subcategoría: " + item.name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/subcategories/delete", { id: item.id })
          .then(res => {
            Globals.showSuccess(res.data.msg);
            this.load();
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };
  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  render() {
    return (
      <Menu history={this.props.history}>
        <Modal
          title="Ver Subcategoría"
          onClose={this.close}
          visible={this.state.view}
        >
          <ViewSubcategory category={this.state.category} />
        </Modal>
        <Modal
          title={this.state.edit ? "Editar Subcategoría" : "Nueva Subcategoría"}
          onClose={this.close}
          visible={this.state.create}
        >
          <CreaEditSubcategory
            category_id={this.state.form.category_id}
            user={this.props.user}
            edit={this.state.edit}
            onClose={this.close}
          />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-md-2">
              <Button color=" " className="btn-align-bottom btn-filter" onClick={ this.goBack }>
                <span>Volver</span>
              </Button>
            </div>
            <div className="col-md-4">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre o Código"
              />
            </div>
            <div className="col-md-4">
              <Select
                color=" "
                name="status"
                label="Estatus"
                defaultname="Seleccione"
                className="filter"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div>
            <div className="col-md-2">
              <Button color=" " className="btn-align-bottom btn-filter" onClick={ this.load }>
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Subcategorías"
            header={this.state.header}
            right={
              <Button
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.name}</td>
                  <td>{i.code}</td>
                  <td>{i.category.name}</td>
                  <td style={{ color: this.getStatus(i.status).color }}>
                    {this.getStatus(i.status).text}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() => {
                        this.setState({
                          category: i,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      color="primary"
                      small="true"
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: i,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {i.status === 0 && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.suspend(i, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {i.status === 1 && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.suspend(i, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Subcategory);
