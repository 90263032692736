import axios from "../../utils/axios";
import Globals from "../../utils/globals";

class inventory {
  static async createMovement(form) {
    return new Promise((resolve, reject) => {
      axios.post(parseInt(form.type) === 3 ? "admin/inventory/translate" : "admin/inventory/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      ).catch(err => {
        if (err.response.status === 422) {
          Globals.sure(err.response.data.msg)
        }
      });
    });
  }
  static async checkExistence(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/inventory/check-existence", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async check_serials(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sales/check_serials", form).then(
        ({ data }) => {
          // http success
          console.log(data);
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

}

export default inventory;