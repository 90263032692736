import React from 'react';
import { connect } from 'react-redux';
import { Constants, Globals, axios } from '../../utils';
import { Input, Select, Modal } from '../../components';
import { PublicStoreService, QuoteService } from '../../services';
import TrashIcon from '../../assets/icons/trash.png';
// import SelectDates from './select-dates';

class ConvertToOrder extends React.Component {

	state = {
		methods: [],
		form: {
			method_id: '',
			file: '',
			initial_payment: '',
			status: Constants.PAYMENT_TYPES.PAID,
			show_taxes: Constants.QUOTES.SHOW_TAXES.NO,
			discount: this.props?.item?.discount || '',
			credit_days: '',
			information: this.props?.item?.comments || '',
			iva: null,
			iva_bs: null,
		},
		dates: [],
		visible_dates: false,
		order: this.props.item,
		config: null,
		height: 0,
		methods_selected: [],
		disabled_button: false,
		quote: this.props.quote ?? false,
		exchangeRate: 0,
	}

	componentDidMount() {
		const details = this.props?.item?.details?.map(x => {
			const product = Globals.copy(x.product);
			product.quantity = x.quantity;
			product.discount = x.discount_percentage;
			product.size_id = x.size_id;
			product.color_id = x.color_id;
			product.selected_price = x.price ?? x.sale_price;
			x.selected_price = product.selected_price 
			return { ...x, product };
		});

		const show_taxes = this.props?.item?.show_taxes || Constants.QUOTES.SHOW_TAXES.NO;

		this.setState({
			form: {
				...this.state.form,
				show_taxes,
			},
			order: {
				...this.state.order,
				show_taxes,
				details,
			}
		})
		this.load();
		this.getExchangeRate();
	}

	load = async () => {
		this.getConfig();
	}

	getExchangeRate = async () => {
		try {
			const filter = { user_id: this.props.user.id };

			const res = await axios.post('admin/conversions', filter);
			if (!res.data?.conversion) throw Error('Debe establecer una tasa de cambio para continuar');

			this.setState({ exchangeRate: res.data?.conversion?.amount || 0 });

		} catch (error) {
			Globals.showError(error?.message);
			console.log('NewRequest -> getExchangeRate -> catch:', error)
		}
	};

	getConfig = () => {
		axios
			.post("sync/config", { user_id: this.props.user.id })
			.then(({ data }) => {
					this.setState({ config: data.config });
			})
			.catch(() => Globals.showError())
			.finally(() => Globals.quitLoading());
	};

	setPaymentType = (status) => {
		this.setState({
			form: {
				...this.state.form,
				status
			}
		});
	}

	change = (value,target) => {
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
	}

  checkedChange = async (newValue) => {
    await this.setState(prevState => ({
      form: {
        ...prevState.form,
        show_taxes: newValue == true ? Constants.QUOTES.SHOW_TAXES.YES : Constants.QUOTES.SHOW_TAXES.NO
      }
    }));

	this.getTotal();
  }

	submit = async () => {
		if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY && this.state.form.credit_days <= 0) {
			Globals.showError("Debe ingresar los días de crédito");
			this.enableSubmit();
			return false;
		}

		if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY && this.state.form.initial_payment >= this.getTotal()) {
			Globals.showError("El pago inicial no puede ser igual o mayor al monto total. Este es un pedido por cobrar");
			this.enableSubmit();
			return false;
		}

		if (this.state.form.discount != '' && this.state.form.discount > 100) {
			Globals.showError("El porcentaje de descuento general no puede ser mayor a 100%");
			this.enableSubmit();
			return false;
		}

		if (this.state.config?.negative_stock != Constants.CONFIG.YES) {
			const detail = this.state.order.details.find((i) => {
				if (i.product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
					const size_color = i.product?.size_colors?.find((_i) => {
						if (i.size_id && i.color_id) {
							return _i.size_id == i.size_id && _i.color_id == i.color_id;
						}
						else if (i.size_id) {
							return _i.size_id == i.size_id && _i.color_id == null;
						}
						else if (i.color_id) {
							return _i.color_id == i.color_id && _i.size_id == null;
						}
					});

					return size_color?.stock < i.quantity;
				}
				else {
					return i.product.stock < i.quantity;
				}
			});

			if (detail && detail?.product) {
				const size_color = detail?.product?.size_colors?.find((_i) => {
					if (detail.size_id && detail.color_id) {
						return _i.size_id == detail.size_id && _i.color_id == detail.color_id;
					}
					else if (detail.size_id) {
						return _i.size_id == detail.size_id && _i.color_id == null;
					}
					else if (detail.color_id) {
						return _i.color_id == detail.color_id && _i.size_id == null;
					}
				});
				if (detail.product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
					Globals.showError("Lo sentimos, el producto " + detail.product.name + ` (${ [detail.product?.size?.name,detail.product?.color?.name].filter(i => i != null).join(',') }) no tiene suficiente stock (` + size_color?.stock + ")");
				}
				else {
					Globals.showError("Lo sentimos, el producto " + detail.product.name + " no tiene suficiente stock (" + detail.product.stock + ")");
				}
				this.enableSubmit();
				return false;
			}
		}

		if (this.state.form.status != Constants.PAYMENT_TYPES.TO_PAY || this.state.form.initial_payment != '') {
			if (this.state.methods_selected.length == 0) {
				Globals.showError("Debe seleccionar al menos 1 método de pago");
				this.enableSubmit();
				return false;
			}

			if (this.state.methods_selected.filter(i => !i.amount).length > 0) {
				Globals.showError("Debe ingresar el monto de cada método de pago");
				this.enableSubmit();
				return false;
			}

			let total = this.getTotal();
			let message = "La suma de los métodos de pago deben ser igual al total";

			if (this.state.form.status == Constants.PAYMENT_TYPES.TO_PAY) {
				total = this.state.form.initial_payment;
				message = "La suma de los métodos de pago deben ser igual al pago inicial";
			}

			if (this.state.methods_selected.map(i => i.amount).reduce((a, b) => parseFloat(a) + parseFloat(b),0) != Globals.roundMiles(total)) {
				Globals.showError(message);
				this.enableSubmit();
				return false;
			}
		}
		let client = this.props.client?.id;

		Globals.setLoading();
		try {
			const details = this.state.order.details.map(x => ({
				...x,
				discount: x.discount || 0,
			}));

			//Si es una cotizacion
			if(this.state.quote === true){
				const res = await QuoteService.convertToOrder({
					registered_by: this.props.user?.id,
					seller_id: this.props.user?.id,
					client_id: client,
					admin_id: this.props.user?.level_id == Constants.ROLE_ADMIN ? this.props.user?.id : this.props.user?.admin_id,
					quote_id: this.state.order?.id,
					currency: this.props.currency,
					...this.state.form,
					discount: this.state.form.discount || 0,
					hasFile: true,
					details: JSON.stringify(details),
					methods_selected: JSON.stringify(this.state.methods_selected),
					onError: () => {
						this.enableSubmit();
					}
				});
				
				//Mensaje de error
				if(res?.result == false){
					Globals.showError(res?.error ?? 'El cliente ya ha alcanzado su limite de crédito permitido');
					this.props.onClose(true);
					Globals.quitLoading();
					return
				}
			}
			else{ //si es pedido de ws
				const res = await PublicStoreService.convertToOrder({
					seller_id: this.props.user?.id,
					client_id: client,
					admin_id: this.props.user?.level_id == Constants.ROLE_ADMIN ? this.props.user?.id : this.props.user?.admin_id,
					order_id: this.state.order?.id,
					currency: this.props.currency,
					...this.state.form,
					discount: this.state.form.discount || 0,
					hasFile: true,
					details: JSON.stringify(details),
					methods_selected: JSON.stringify(this.state.methods_selected),
					onError: () => {
						this.enableSubmit();
					}
				});

				//Mensaje de error
				if(res?.result == false){
					Globals.showError(res?.error ?? 'El cliente ya ha alcanzado su limite de crédito permitido');
					this.props.onClose(true);
					Globals.quitLoading();
					return
				}
			}
			Globals.showSuccess("Se ha enviado el pedido");
			this.props.onClose(true);

			// TODO: Habilitar el socket en el front para notificar el cambio
			// res.notifications?.forEach(item => {
			// 	Socket.emit(SocketEvents.STOCK.ALERT,{
			// 		message: item,
			// 		seller_id: this.state.order?.seller_id
			// 	});
			// });

			// res.notifications_zero?.forEach(item => {
			// 	Socket.emit(SocketEvents.STOCK.ZERO,{
			// 		message: item,
			// 		seller_id: this.state.order?.seller_id
			// 	});
			// });

		} catch (error) {
			console.log(error);
			Globals.showError('Ocurrió un error al guardar el pedido');
		}

		Globals.quitLoading();
	}

	enableSubmit = () => {
		return this.setState({
			disabled_button: false
		});
	}

	openDates = () => {
		this.setState({
			visible_dates: true
		});
	}

	onCloseDates = (dates = null) => {
		this.setState({
			visible_dates: false,
			dates: dates || this.state.dates
		});
	}

	/*
	getTotal = () => {
		// let total = this.state.order?.details?.map((i) => {
		// 	let total = (i.sale_price || 0) * i.quantity;

		// 	if (i.discount != null) {
		// 		total = total - ((total * i.discount) / 100);
		// 	}

		// 	return total;
		// }).reduce((a,b) => a + b,0);

		let total = this.state.order?.details?.map((i) => {
			let price = i.selected_price ? i.selected_price : i.sale_price;
			let total = price * i.quantity;
		  
			if (i.discount != null) {
			  total = total - ((total * i.discount) / 100);
			}
		  
			return total;
		}).reduce((a,b) => a + b,0);

		if (this.state.form.discount != '') {
			total = total - ((total * this.state.form.discount) / 100);
		}

		if(this.state.quote === true){
			if (this.state.form.show_taxes == Constants.QUOTES.SHOW_TAXES.YES) {
				const iva = this.state.order.iva
				total += Number(iva);
			}
		}

		return total;
	}
	*/

	/*
	getTotal = () => {
		const { order, form, quote, exchangeRate } = this.state; // Asegúrate de incluir exchangeRate si es necesario

		let total = 0;
		let iva = 0;
		let iva_bs = 0;

		if (order?.details) {
			order.details.forEach(i => {
				let price = i.selected_price ? i.selected_price : i.sale_price;
				let totalProduct = price * i.quantity;

				if (i.discount != null) {
					totalProduct = totalProduct - ((totalProduct * i.discount) / 100);
				}

				total += totalProduct;

				// Calculo del IVA
				if (form.show_taxes && !i.exento) { // Asume que tienes una propiedad exento para determinar si un producto está exento de IVA
					const subtotalWithDiscounts = totalProduct;
					const calculatedIva = (subtotalWithDiscounts * Constants.TAXES.IVA) / 100;
					iva += calculatedIva;
					iva_bs += calculatedIva * exchangeRate; // Multiplicamos por exchangeRate para obtener el valor en Bs
				}
			});
		}

		if (form.discount != '') {
			total = total - ((total * form.discount) / 100);
		}

		if (quote === true) {
			if (form.show_taxes == Constants.QUOTES.SHOW_TAXES.YES) {
				total += Number(iva); // Sumamos el IVA calculado al total
			}
		}

		if (form.show_taxes == Constants.QUOTES.SHOW_TAXES.YES){
			form.iva = iva;
			form.iva_bs = iva_bs;
		}

		return total;
	};
	*/
	getTotal = () => {
		const { order, form, quote, exchangeRate } = this.state;
	
		let total = 0;
		let iva = 0;
		let iva_bs = 0;
	
		if (order?.details) {
			order.details.forEach(i => {
				let price = i.selected_price ? i.selected_price : i.sale_price;
				let totalProduct = price * i.quantity;
	
				// Aplicar descuento específico del producto
				if (i.discount != null) {
					totalProduct -= (totalProduct * i.discount) / 100;
				}
	
				// Calcular IVA después de aplicar descuentos específicos pero antes del descuento general
				if (form.show_taxes && !i.exento) {
					const calculatedIva = (totalProduct * Constants.TAXES.IVA) / 100;
					iva += calculatedIva;
					iva_bs += calculatedIva * exchangeRate;
					totalProduct += calculatedIva; // Sumar el IVA al total del producto
				}
	
				total += totalProduct;
			});
	
			// Aplicar descuento general después de sumar todos los productos
			if (form.discount != '') {
				total = total - ((total * form.discount) / 100);
			}
		}
	
		// No es necesario sumar el IVA al total aquí ya que se suma individualmente a cada producto
	
		if (form.show_taxes == Constants.QUOTES.SHOW_TAXES.YES){
			form.iva = iva;
			form.iva_bs = iva_bs;
		}
	
		return total;
	};	

	changeValue = (value,target,index) => {
		let details = this.state.order.details;
		details[index][target] = value;

		this.setState({
			order: {
				...this.state.order,
				details
			}
		});
	}

  getPrices = (product) => {
    let size_color = null;
    let prices = product.prices;

    if (product?.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
      size_color = product?.size_colors?.find((i) => {
        if (product.size_id && product.color_id) {
          return i.size_id === product.size_id && i.color_id === product.color_id;
        }
        else if (product.size_id) {
          return i.size_id === product.size_id && !i.color_id;
        }
        else if (product.color_id) {
          return i.color_id === product.color_id && !i.size_id;
        }

        return null;
      });

      prices = size_color?.prices || [];
      product.price = (product?.size_colors.length > 0 ? product?.size_colors[0].sale_price : 0);

    } else {
      product.price = product.sale_price;
    }

    return [
      {
        value: size_color?.sale_price || product.price,
        label: `$${Globals.formatMiles(size_color?.sale_price || product.price,true)}`,
      },
      ...prices.map(i => ({
        value: i.price,
        label: `$${Globals.formatMiles(i.price,true)}`,
      }))
    ]
  }

	render() {
		const { visible_dates } = this.state;

		return (
			<React.Fragment>
				{visible_dates && (
					<Modal
						title="Seleccione fechas"
						onClose={ () => this.onCloseDates() }
						visible
					>
						{/* <SelectDates
							dates={ this.state.dates }
							onClose={ this.onCloseDates }
						/> */}
					</Modal>
				)}
				<div className="whatsapp-convert-to-order">
					<div className="product-list">
						{this.state.order && this.state.order.details.map((item,index) => {
							//precio por defecto
							const product = item.product;
							let label_total = item.quantity + ' x ' + Globals.formatMiles(item.selected_price || 0,true);
							let total = item.selected_price * item.quantity;

							if (item.discount != null && item.discount != '') {
								total = total - ((total * item.discount) / 100);
								label_total += ' - ' + Globals.formatMiles(item.discount,true,'') + '%';
							}

							if (product)
								return (
									<div className="product-item" key={ index }>
										<div className="product-image">
											<img src={ Globals.fromPhotos(product?.photo?.file) } alt="Foto del producto" />
										</div>
										<div className="product-info">
											<div className="product-name">{ product?.name }</div>
											<div className="product-price">{ Globals.formatMiles(total,true) } ({ label_total })</div>
											<Select
												label="Precio"
												placeholder="Seleccionar"
												className="material"
												labelClass="material"
												value={item.selected_price}
												options={this.getPrices(product)}
												onChange={ e => {
													if (Globals.validateDouble(e.target.value) || e.target.value === '') {
														this.changeValue(e.target.value,'selected_price',index);
													}
												} }
											/>
											<Input
												type="number"
												label="Cantidad"
												className="material"
												value={ item.quantity || 0 }
												onChange={ e => {
													if (Globals.validateDouble(e.target.value) || e.target.value === '') {
														this.changeValue(e.target.value,'quantity',index);
													}
												} }
											/>
											<Input
												type="number"
												label="Descuento (%)"
												placeholder="Opcional"
												className="material"
												value={ item.discount?.toString() || '' }
												onChange={ e => {
													if (Globals.validateDouble(e.target.value) || e.target.value === '') {
														this.changeValue(e.target.value,'discount',index);
													}
												} }
											/>

											{ item.discount != null && item.discount > 100 && (
												<div className="discount-error">El descuento no puede ser mayor a 100%</div>
											)}

											{product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && (
												<div className="product-details">
													{item.color_id && (
														<div className="product-color" style={{ backgroundColor: item.color?.hex }} />
													)}
													{item.size_id && (
														<div className="product-size">{ item.size?.name }</div>
													)}
												</div>
											)}
										</div>
									</div>
								)
							else
								return null;
						})}
					</div>

					<div className="total-amount">
						<span>Total:</span>
						<span>{ Globals.formatMiles(this.getTotal(),true) }</span>
					</div>

					<div className="form">
						<div className="tab-container">
							<div
								className={`tab ${this.state.form.status === Constants.PAYMENT_TYPES.PAID ? 'selected':''}`}
								onClick={ () => this.setPaymentType(Constants.PAYMENT_TYPES.PAID) }
							>
								Pagado
							</div>
							<div
								className={`tab ${this.state.form.status === Constants.PAYMENT_TYPES.TO_PAY ? 'selected':''}`}
								onClick={ () => this.setPaymentType(Constants.PAYMENT_TYPES.TO_PAY) }
							>
								Por Cobrar
							</div>
						</div>

						{this.state.form.status === Constants.PAYMENT_TYPES.TO_PAY && (
							<>
								<div className="input-container">
									<Input
										type="number"
										label="Pago Inicial (Opcional)"
										className="material"
										labelClass="material"
										value={ this.state.form.initial_payment }
										onChange={ e => {
											if (Globals.validateDouble(e.target.value) || e.target.value === '') {
												this.change(e.target.value,'initial_payment');
											}
										} }
									/>
								</div>

								<div className="input-container">
									{/* <Select
										onValueChange={ value => {
											this.setState({
												form: {
													...this.state.form,
													credit_days: value
												}
											});
										} }
										items={ Constants.DAYS_PAY }
										value={ this.state.form.credit_days }
										style={ styles.input }
										placeholder="Seleccionar"
									/> */}
									<Input
										type="number"
										label="Días de Crédito"
										className="material"
										labelClass="material"
										value={ this.state.form.credit_days }
										onChange={ e => {
											if (Globals.validateInteger(e.target.value) || e.target.value === '') {
												this.change(e.target.value,'credit_days');
											}
										} }
									/>
								</div>
							</>
						)}

						<div className="input-container">
							<Select
								label="Método de Pago"
								placeholder="Seleccionar"
								className="material"
								labelClass="material"
								value={ this.state.form.method_id }
								options={ this.props.methods.filter(i => this.state.methods_selected.map(i => i.id).indexOf(i.id) == -1).map(i => {
									return {
										value: i.id,
										label: i.name
									}
								}) }
								onChange={ e => {
									if (e.target.value) {
										let methods_selected = [...this.state.methods_selected];
										methods_selected.push({
											id: e.target.value
										});
										this.setState({
											methods_selected,
											form: {
												...this.state.form,
												method_id: ''
											}
										});
									}
								} }
							/>
						</div>

						{this.state.methods_selected.length > 0 && (
							<div className="container-payments">
								{this.state.methods_selected.map((i,index) => {
									const method = this.props.methods.find(item => item.id == i.id);

									return (
										<div className="payment-method">
											<div className="payment-method-desc">
												{ method?.name }
											</div>
											<div className="payment-method-desc">
												<Input
													type="number"
													placeholder="Monto"
													labelClass="material"
													className="material"
													value={ i.amount }
													onChange={ e => {
														if (Globals.validateDouble(e.target.value) || e.target.value == '') {
															let methods_selected = [...this.state.methods_selected];
															methods_selected[index].amount = e.target.value;
															this.setState({
																methods_selected
															});
														}
													} }
												/>
											</div>
											<div className="payment-method-delete">
												<button
													onClick={ () => {
														let methods_selected = [...this.state.methods_selected];
														methods_selected.splice(index,1);
														this.setState({ methods_selected });
													} }
												>
													<img src={ TrashIcon } style={{ width: 20, height: 20 }} alt="Eliminar" />
												</button>
											</div>
										</div>
									)
								})}
							</div>
						)}

						<div className="input-container">
							<Input
								type="number"
								label="Descuento General (%)"
								placeholder="Opcional"
								className="material"
								labelClass="material"
								value={ this.state.form.discount?.toString() || '' }
								onChange={ e => {
									if (Globals.validateDouble(e.target.value) || e.target.value == '') {
										this.change(e.target.value,'discount');
									}
								} }
							/>
						</div>

						{ this.state.form.discount != null && this.state.form.discount > 100 && (
							<div className="discount-error">El descuento no puede ser mayor a 100%</div>
						)}

						<div className="export-option" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '10rem', margin: '1.5rem 0 1rem' }}>
							<input
								name="show_taxes"
								id="exento-check"
								type="checkbox"
								checked={this.state.form.show_taxes === Constants.QUOTES.SHOW_TAXES.YES}
								style={{ marginRight: '0.5rem' }}
								onChange={() => this.checkedChange(!this.state.form.show_taxes)}
							/>
							<label className="" htmlFor="exento-check" style={{ marginBottom: 0 }}>
								<span style={{ fontSize: 16 }}>Incluir Impuesto</span>
							</label>
						</div>

						<div className="input-container">
							<Input
								label="Comprobante de Pago (Opcional)"
								labelClass="material"
								className="material"
								placeholder="Opcional"
								value={ this.state.form.file?.name || '' }
								onChange={ () => {} }
								onClick={() => document.getElementById('upload-voucher').click()}
								readOnly
							/>
							<input
								id="upload-voucher"
								type="file"
								style={{ display: 'none' }}
								onChange={e => this.change(e.target.files[0], 'file')}
							/>
						</div>

						<div className="input-container">
							<Input
								label="Información adicional"
								className="material"
								labelClass="material"
								style={ { height: Math.max(60, this.state.height) } }
								onContentSizeChange={(event) => {
									this.setState({ height: event.nativeEvent.contentSize.height })
								}}
								value={this.state.form.information}
								multiline={ true }
								onChange={ e => this.change(e.target.value,'information') }
							/>
						</div>

						<div className="container-buttons">
							<button
								onClick={ async () => {
									await this.setState({ disabled_button: true });
									this.submit();
								} }
								disabled={ this.state.disabled_button }
							>
								<span>Finalizar Compra</span>
							</button>

							<button className="danger" onClick={ () => this.props.onClose() }>
								<span>Borrar compra</span>
							</button>
						</div>
					</div>
				</div>
			</React.Fragment>
		)
	}
}

export default connect(state => {
	return {
		user: state.user,
    currency: state.currency,
	}
})(ConvertToOrder);