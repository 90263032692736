import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Modal,
  DatePicker,
  Icon,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import AddIcon from '../../assets/icons/add.png';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import ModalCreatePayment from "./modal-create-payment";
import PrinterIcon from '../../assets/icons/printer.png';

const tableHeader = [
  'Fecha', 
  'N° Pedidos Pagados', 
  'Vendedor', 
  'Comisión Pagada',
  'Método de Pago', 
  'Gerente', 
  'Comisión Pagada',
  'Método de Pago',
  'Monto Total Pagado',
  ''
];

class CommisionsPayments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAddPayment: false,
      data: [],
      sellers: [],
      managers: [],
      form: {
        web: true,
        seller_id: "",
        since: "",
        until: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        user_id: this.props.user.id,
        payment_method_id: ''
      },
      pagination: {
        page: 1,
        last_page: 1,
      },
      totals: {
        monto_total: 0,
        total_comisiones: 0,
        total_cobrar: 0,
      },
      modal: {
        paid_amount: '',
        commission_to_pay: '',
      },
      payment_methods: [],
      order_id: this.props.history.location.state?.order_id,
      withoutFilters: this.props.history.location.state?.withoutFilters,
      item: null
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getSellers();
    this.getManagers();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (page = 1) => {
    const {form} = this.state
    form.page = page;
    form.order_id = this.state.order_id;
    axios
      .post('web/admin/commission-payments', form)
      .then(res => {
        this.setState({
          page,
          data: res.data.payments.data,
          last_page: res.data.payments.last_page,
        });
      })
      .catch(err => {
        console.log('Commissions -> load -> catch: ', err);
      });
  };

  getSellers = async () => {
    await axios
      .post('web/admin/orders/sellers', {
        ...this.state.form,
        is_select: true,
        not_admin: true,
      })
      .then(res => {
        this.setState({
          sellers: res.data.sellers,
          payment_methods: res.data.payment_methods || []
        });
      })
      .catch(err => {
        console.log('Commissions -> getSellers -> catch: ', err);
      });
  };

  getManagers = async () => {
    await axios
      .post('web/admin/orders/managers', {
        ...this.state.form,
      })
      .then(res => {
        this.setState({
          managers: res.data.managers
        });
      })
      .catch(err => {
        console.log('Commissions -> getManagers -> catch: ', err);
      });
  };

  delete = async (item) => {
    Globals.confirm("¿Desea eliminar el pago?", () => {
      Globals.setLoading();
      axios
        .post(`web/admin/commission-payments/delete`, { commission_payment_id: item.id })
        .then(res => {
          Globals.showSuccess('Se ha eliminado el pago correctamente');
          this.load();
        })
        .catch(err => {
          Globals.showError();
          console.log(err)
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  change = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      form: {
        web: true,
        seller_id: "",
        since: "",
        until: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        user_id: this.props.user.id,
        payment_method_id: ''
      },
    }));
    await this.load();
  };

  togglePaymentModal = () => {
    this.setState(state => ({ visibleAddPayment: !state.visibleAddPayment }));
    this.load()
  };

  goBack = () => {
    this.props.history.replace('/commissions');
  };

  print = () => {
    axios
      .post('web/admin/commission-payments/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  }

  closeInfo = () => {
    this.setState({
      visible_info: false
    });
  }

  viewInfo = (item) => {
    this.setState({
      item,
      visible_info: true
    });
  }

  render() {

    const { data, form, visibleAddPayment, visible_info, item } = this.state;

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={() => this.goBack()} />}
      >
        <Modal
          title="Ver Pago"
          onClose={() => this.closeInfo()}
          visible={visible_info}
        >
          <div className="modal-view-payment-individual">
            {
              (item?.comments || item?.voucher) && (
                <div>
                  <h3>{ item.user?.name }</h3>
                  {
                    item?.payment_method && (
                      <p>{ item?.payment_method?.name }</p>
                    )
                  }
                  {
                    (item?.comments) && (
                      <>
                        <p style={{ marginBottom: '0px' }}>Información adicional</p>
                        <p>{ item?.comments }</p>
                      </>
                    )
                  }
                  {
                    (item?.voucher) && (
                      <a href={ ENV.BaseStorage + (item?.voucher || item?.voucher_manager) } target="_blank">
                        <Button
                          title="Ver"
                        >
                          Ver comprobante de pago
                        </Button>
                      </a>
                    )
                  }
                </div>
              )
            }
            {
              (item?.comments_manager || item?.voucher_manager) && (
                <div style={{ marginTop: (item?.comments || item?.voucher) ? '15px' : '0px' }}>
                  <h3>{ item.user?.managers[0]?.name }</h3>
                  {
                    item?.payment_method_manager && (
                      <p>{ item?.payment_method_manager?.name }</p>
                    )
                  }
                  {
                    (item?.comments_manager) && (
                      <>
                        <p style={{ marginBottom: '0px' }}>Información adicional</p>
                        <p>{ item?.comments_manager }</p>
                      </>
                    )
                  }
                  {
                    (item?.voucher_manager) && (
                      <a href={ ENV.BaseStorage + (item?.voucher_manager) } target="_blank">
                        <Button
                          title="Ver"
                        >
                          Ver comprobante de pago
                        </Button>
                      </a>
                    )
                  }
                </div>
              )
            }
          </div>
        </Modal>

        <Modal
          title="Agregar Pago de Comisión"
          onClose={() => this.togglePaymentModal()}
          visible={visibleAddPayment}
        >
          <ModalCreatePayment
            sellers={this.state.sellers}
            onClose={() => this.togglePaymentModal()}
          />
        </Modal>

        <div id="commissions-payments" className="new-reports">
          {
            !this.state.withoutFilters && (
              <div className="row">
                <div className="col-md-2">
                  <Select
                    color="white"
                    name="seller_id"
                    label="Vendedor"
                    className="filter"
                    defaultname="Todos"
                    disabledFirst="false"
                    onChange={this.change}
                    value={form.seller_id}
                    options={
                      [{
                        label: 'Vendedores',
                        optgroup: true,
                        options: this.state.sellers.map((Item) => {
                          return {
                            value: Item.id,
                            label: Item.name
                          }
                        })
                      },
                      {
                        label: 'Gerentes',
                        optgroup: true,
                        options: this.state.managers.map((Item) => {
                          return {
                            value: Item.id,
                            label: Item.name
                          }
                        })
                      }]
                    }
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    color="white"
                    label="Desde"
                    className="filter"
                    value={form.since}
                    onChange={value => {
                      this.setState(state => ({
                        form: {
                          ...state.form,
                          since: value
                        }
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <DatePicker
                    color="white"
                    label="Hasta"
                    className="filter"
                    value={form.until}
                    onChange={value => {
                      this.setState(state => ({
                        form: {
                          ...state.form,
                          until: value
                        }
                      }));
                    }}
                  />
                </div>
                <div className="col-md-2">
                  <Select
                      color="white"
                      name="payment_method_id"
                      label="Método de pago"
                      className="filter"
                      style={{ textTransform: "capitalize" }}
                      onChange={emitter => this.change(emitter)}
                      value={form.payment_method_id}
                      options={this.state.payment_methods.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        }
                      })}
                  />
                </div>
                <div className="col-md-2">
                  <div className="container-buttons">
                    <Button className="btn-align-bottom btn-filter blue" color=" " onClick={() => this.load()}>
                      <span>Filtrar</span>
                    </Button>
                    <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                      <span>Limpiar</span>
                    </Button>
                  </div>
                </div>
              </div>
            )
          }

          {/* {!!data.length && (
            <div className="row monthly-earnings mb-3 d-flex">
              <div className="col-4 col-lg-3 total-amount">
                <div className="totals">
                  <span>Total:</span>
                  <span>$ {Globals.formatMiles(this.state.totals.monto_total)} </span>
                  <span>Pagado:</span>
                  <span>$ {Globals.formatMiles(this.state.totals.monto_total)}</span>
                </div>
              </div>
            </div>
          )} */}

          <Table
            data={this.state.data.length}
            title="Pago de Comisiones"
            header={tableHeader}
            right={
              <>
                {
                  !this.state.withoutFilters && (
                    <div>
                      <Button
                        title="Imprimir"
                        small="true"
                        onClick={() => this.print()}
                      >
                        <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                      </Button>
                    </div>
                  )
                }
              </>
            }
          >
            {this.state.data.map((i, index) => {
              let amount_seller = i.amount_seller !== null ? i.amount_seller
                :
                i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission) : i.orders[0]?.commission;

              let amount_manager = i.amount_manager !== null ? i.amount_manager
                :
                i.orders[0]?.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((i.orders[0]?.total - i.orders[0]?.to_pay) / i.orders[0]?.total) * i.orders[0]?.commission_manager) : i.orders[0]?.commission_manager;

              let total_pagado = amount_seller + amount_manager;

              let is_manager = false;

              if (i.user?.level_id == Constants.ROLE_MANAGER && i.orders.length > 0 && i.orders[0].seller_id != i.user?.id) {
                is_manager = true;
              }

              return (
                <tr key={index}>
                  <td>{ moment(i.date).format('DD-MM-YYYY') }</td>
                  <td>
                    { 
                      i.orders.map(order => order.number_format).join(', ')
                    }
                  </td>
                  <td>{ !is_manager ? i.user?.name : '-' }</td>                  
                  <td> 
                    {
                      Globals.formatMiles(amount_seller, true)
                    }
                  </td>
                  <td>{ i?.payment_method?.name || '-' }</td>
                  <td>{ is_manager ? i.user?.name : i.user?.managers[0]?.name }</td>
                  <td> 
                    {
                      Globals.formatMiles(amount_manager, true)
                    }
                  </td>
                  <td>{ i.payment_method_manager?.name || '-' }</td>
                  <td>
                    { 
                      Globals.formatMiles(total_pagado, true)
                    }
                  </td>
                  <td>
                    {
                      (i.comments || i.comments_manager || i.voucher || i.voucher_manager) && (
                        <Button
                          title="Ver información adicional"
                          small="true"
                          color="primary"
                          onClick={() => {
                            this.viewInfo(i);
                          }}
                        >
                          <Icon name="info" />
                        </Button>
                      )
                    }
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.pagination.last_page}
            active={this.state.pagination.page}
            onChange={async page => {
              this.load(page);
            }}
          />

        </div>
      </Menu>
    );
  }
}

const BackButton = ({ onClick }) => (
  <>
    <div className="back-button">
      <img src={LeftArrowIcon} onClick={() => onClick()} alt="Regresar" />
    </div>
    <div className="content-breadcumbs">
      <div className="title">Pago de Comisiones</div>
    </div>
  </>
)

export default connect(state => ({
  user: state.user
}))(CommisionsPayments);
