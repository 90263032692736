import React from "react";

import { InputFormat, Button, CheckBox, Input  } from "../../components";
import { Constants, Globals } from '../../utils';


const TYPE_PAYMENT = [ ...Constants.CASH_COUNT_METHOD_PAYMENTS ];
const WITHOUT_REFERENCE = [
  Constants.PAYMENT_METHODS.CASH_BS,
  Constants.PAYMENT_METHODS.CASH_FE,
  Constants.PAYMENT_METHODS.POS,
];

class Payment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      total: props.form.total,
      discount_percentage: props.form.discount_percentage,
      errors: []
    };
  }

  componentWillReceiveProps(props) {
    const { form } = props
    const { total, discount_percentage } = form
    this.setState({ ...this.state, total: total, discount_percentage: discount_percentage })
  }

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  checkDiscountPercentage(values, name) {
    const { floatValue: value } = values
    const { user } = this.props;
    const discountRate = Globals.getRate(user);

    if (name === "discount_percentage" && !!discountRate) {
      if (parseInt(value) > discountRate.percentage) {
        Globals.showWarning(
          `¡El porcentaje de descuento no puede exceder el ${discountRate.percentage}%!`
        );
        this.setState({ ...this.state, discount_percentage: '' })
        return;
      }
    }
    this.props.handleDiscount(value, 'discount_percentage')
  }

  render() {
    const { form, showDiscountSale } = this.props;

    const _total = form.type_payments.map(i => i.amount).filter(i => i != '' && i != null).reduce((a, b) => a + b, 0);
    const _rest = this.state.total - _total;

    return (
      <div className="modal-payment-types">
        <div className="row">
          <div className="col col-md mx-4">
            <div className="form-group">
              {TYPE_PAYMENT.map((Payment, key) => {
                return (
                  <React.Fragment key={key}>
                    <CheckBox
                      label={Payment.label}
                      name={Payment.name}
                      value={Payment.value}
                      defaultChecked={
                        form.type_payments.find(
                          Item => parseInt(Item.id) === Payment.value
                        )
                          ? "checked"
                          : null
                      }
                      onClick={this.props.handleChange}
                    />
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        {form.type_payments.length > 0
          ? form.type_payments.map((Item, key) => {
            return (
              <div className="row" key={key}>
                <div className="col col-md-6">
                  <InputFormat
                    color="gray"
                    name="amount"
                    thousandSeparator={true}
                    allowNegative={false}
                    isNumericString={true}
                    decimalScale="2"
                    label={Item.label}
                    value={Item.amount}
                    onValueChange={values => {
                      this.props.handlePayment(values, key);
                    }}
                  />
                </div>

                {!WITHOUT_REFERENCE.includes(Number(Item.id)) ?
                  <div className="col col-md-6">
                    <Input
                      color="gray"
                      type="text"
                      label="Número de referencia"
                      value={Item.reference_number}
                      onChange={ values => {
                        this.props.handleReferenceNumber(values.target.value, key);
                      } }
                    />
                  </div>
                : null}
              </div>
            );
          })
          : null}
        {showDiscountSale ?
          <div className="row">
            <div className="col col-md">
              <InputFormat
                color="gray"
                name="discount_percentage"
                thousandSeparator={true}
                allowNegative={false}
                isNumericString={true}
                decimalScale="0"
                label="Porcentaje de Descuento"
                value={this.state.discount_percentage}
                onValueChange={values => {
                  this.checkDiscountPercentage(values, 'discount_percentage')
                }}
              />
            </div>
          </div>
          : null}
        <div className="row">
          <div className="col col-md">
            <InputFormat
              color="gray"
              name="total"
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              label="Total"
              value={this.state.total}
              readOnly
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-md">
            <InputFormat
              color="gray"
              name="total"
              thousandSeparator={true}
              allowNegative={true}
              isNumericString={true}
              decimalScale="2"
              label="Resta por pagar"
              value={_rest}
              readOnly
              customClass={!this.props.isCash && _rest < 0 ? 'rest-negative' : ''}
            />
          </div>
        </div>
        { _rest < 0 && this.props.isCash() && (<p className="_total_cash">Debe entregar cambio de { Globals.formatMiles(_rest * -1)} en efectivo</p>)}
        <div className="row">
          <div className="col col-md text-center">
            <Button
              submitted={
                this.props.submitted ? this.props.submitted : undefined
              }
              block="true"
              type="button"
              disabled={this.props.form.type_payments.length === 0}
              onClick={emitter => {
                this.props.onClick(emitter);
              }}
            >
              Procesar
            </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default Payment;
