import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { axios, Constants, Globals } from '../../utils';
import { Button, DatePicker, Modal, Select } from '../../components';
import CloseIcon from '../../assets/icons/close-black.png';
import LocationPinIcon from '../../assets/icons/location-pin.png';
import ModalViewRoute from './modal-route';

const INITIAL_FORM = {
  seller_id: '',
  zone_id: '',
  warehouse_id: '',
  client_id: '',
  date: new Date(),
  date_until: new Date(),
  visit_type_id: '',
}

export class ModalAutosaleCreatePlanned extends Component {

  state = {
    form: { ...INITIAL_FORM },
    isEditing: !!this.props?.item,
    selectedClients: [],
    clients: [],
    sellers: [],
    visit_types: [],
    zones: [],
    warehouses: [],
    visible_route: false,
    item: null,
  }

  componentDidMount() {
    this.setState({
      sellers: this.props.sellers,
      visit_types: this.props.visit_types,
      warehouses: this.props.warehouses,
    });

    if (this.state.isEditing) this.fillForm();
  }

  fillForm = async () => {
    const item = this.props.item;
    console.log(item)
    const selectedClients = item.details.map(client => {
      const warehouseName = this.props.warehouses.find(x => x.id === client.warehouse_id)?.name;
      const zoneName = this.props.zones.find(x => x.id === client.zone_id)?.name;
      return {
        id: client.id,
        warehouse_id: client.warehouse_id,
        warehouseName,
        zone_id: client.zone_id,
        zoneName,
        client_id: client.client_id,
        date: moment(client.date),
        date_until: moment(client.date_until),
        visit_type_id: client.visit_type_id,
        visitTypeName: client.visit_type?.name,
        client: client.client,
      };
    });

    await this.setState({
      selectedClients,
      form: {
        ...this.state.form,
        seller_id: item.seller_id,
        date: moment(item.date).toDate(),
        date_until: moment(item.date_until).toDate(),
        warehouse_id: item.details[0]?.warehouse_id || null,
      }
    });

    const seller = item.seller;
    const sellerZones = seller?.zones?.map(x => x.id);
    const zones = this.props.zones.filter(x => sellerZones?.includes(x.id));
    const clients = this.props.clients.filter(x => x.seller_id === item.seller_id);
    await this.setState({
      zones,
      clients: clients,
    });
  }

  change = async (value, target) => {
    if (target === 'seller_id') {
      const seller = this.state.sellers.find(x => x.id === +value);
      const sellerZones = seller?.zones?.map(x => x.id);
      const zones = this.props.zones.filter(x => sellerZones?.includes(x.id));
      const sameZoneClients = this.state.selectedClients.filter(x => sellerZones.includes(x.zone_id));
      await this.setState({
        zones,
        selectedClients: sameZoneClients,
        clients: sellerZones.includes(Number(this.state.form.zone_id)) ? this.state.clients : [],
      });

      if (!!this.state.selectedClients.length) {
        Globals.showInformation(`Los clientes previamente cargados serán asignados a ${seller?.name}`);
      }
    }

    if (target === 'zone_id') {
      const clients = this.props.clients.filter(x => x.zone_id === +value);
      await this.setState({ clients });
    }

    this.setState({ form: { ...this.state.form, [target]: value } });
  }

  isValidPush = (showMsg = true) => {
    const onError = (msg) => {
      if (showMsg) Globals.showError(msg);
      return false;
    }

    const { form, selectedClients } = this.state;

    if (!form.seller_id)
      return onError('Debe seleccionar el vendedor');

    if (!form.zone_id)
      return onError('Debe seleccionar la zona');

    if (!form.date)
      return onError('Debe seleccionar la fecha de inicio');

    if (!form.date_until)
      return onError('Debe seleccionar la fecha límite');

    if (!form.warehouse_id)
      return onError('Debe seleccionar el almacén');

    if (!form.client_id)
      return onError('Debe seleccionar el cliente');

    if (!form.visit_type_id)
      return onError('Debe seleccionar el tipo de visita');

    // Validar que no haya 2 almacenes distintos en la misma fecha
    const exists = selectedClients.some(x => {
      const sameDate = moment(x.date).format('YYYY-MM-DD') === moment(form.date).format('YYYY-MM-DD');
      const anotherWarehouse = x.warehouse_id !== form.warehouse_id;
      return sameDate && anotherWarehouse;
    });

    if (exists)
      return onError('No es posible asignar dos almacenes el mismo día');

    // Validar que no haya un mismo cliente más de una vez en un mismo día
    const exist_client = selectedClients.some(x => {
      const sameDate = moment(x.date).format('YYYY-MM-DD') === moment(form.date).format('YYYY-MM-DD');
      const sameClient = x.client_id === form.client_id; // Asumiendo que tienes un campo client_id para identificar al cliente
      return sameDate && sameClient;
     });
     
     if (exist_client)
      return onError('No es posible asignar el mismo cliente el mismo día');     

    return true;
  }

  isValidForm = () => {
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    const { selectedClients } = this.state;

    if (!selectedClients.length)
      return onError('Debe seleccionar al menos un cliente');

    return true;
  }

  push = async () => {
    if (!this.isValidPush()) return;

    const { clients, form, selectedClients, sellers, visit_types, warehouses, zones } = this.state;
    const client = clients.find(x => x.id === +form.client_id);
    const sellerName = sellers.find(x => x.id === +form.seller_id)?.name;
    const warehouseName = warehouses.find(x => x.id === +form.warehouse_id)?.name;
    const zoneName = zones.find(x => x.id === +form.zone_id)?.name;
    const visitTypeName = visit_types.find(x => x.id === +form.visit_type_id)?.name;
    const newSeller = { ...form, sellerName, warehouseName, zoneName, visitTypeName, client };

    await this.setState({ selectedClients: [ ...selectedClients, newSeller ] });
    this.resetForm();
  }

  remove = (index) => {
    const others = this.state.selectedClients.filter((_, idx) => idx !== index);
    this.setState({ selectedClients: others });
  }

  submit = () => {
    if (!this.isValidForm()) return;
    Globals.setLoading();

    const minDate = moment.min(this.state.selectedClients.map(x => moment(x.date))).format('YYYY-MM-DD');
    const maxDate = moment.max(this.state.selectedClients.map(x => moment(x.date_until))).format('YYYY-MM-DD');

    const data = {
      id: this.state.isEditing ? this.props.item.id : null,
      user_id: this.props.user.id,
      type: Constants.AUTOSALE_TYPE.PLANNED,
      seller_id: this.state.form.seller_id,
      date: minDate, //fecha mas baja
      date_until: maxDate, //fecha mas alta
      items: this.state.selectedClients.map(x => ({
        id: x.id ?? undefined,
        client_id: x.client_id,
        visit_type_id: x.visit_type_id,
        warehouse_id: x.warehouse_id,
        zone_id: x.zone_id,
        date: moment(x.date).format('YYYY-MM-DD'),
        date_until: moment(x.date_until).format('YYYY-MM-DD'),
      })),
    };

    axios
      .post("admin/auto-sales/create", data)
      .then(({ data }) => {
        if (!data.result) throw data;
        Globals.showSuccess(`Autoventa ${this.state.isEditing ? 'editada': 'asignada'}  con éxito`)
        this.props.onClose(true);
      })
      .catch(error => {
        if (error.response) {
          // El servidor devolvió una respuesta con un código de estado fuera del rango 2xx
          Globals.showError(error.response.data.error); // Accede al mensaje de error en error.response.data.error
        } else if (error.request) {
          // La solicitud se realizó pero no se recibió ninguna respuesta
          console.error('Error de red:', error.request);
        } else {
          // Algo sucedió al configurar la solicitud que provocó un error
          console.error('Error:', error.message);
        }
     })
      .finally(() => Globals.quitLoading());
  }

  resetForm = () => {
    this.setState(
      {
        form: {
          ...INITIAL_FORM,
          seller_id: this.state.form.seller_id,
          date: this.state.form.date,
          date_until: this.state.form.date_until,
          warehouse_id: this.state.form.warehouse_id,
        }
      }
    );
  }

  viewRoute = (item) => {
    this.setState({
      visible_route: true,
      item
    });
  }

  closeRoute = () => {
    this.setState({ visible_route: false, item: null });
  }

  render() {

    const { form, isEditing, selectedClients, visible_route } = this.state;
    const isValidPush = this.isValidPush(false);

    return (
      <div>
        {
          visible_route && (
            <Modal
              className="modal-planner-view-route"
              title=""
              onClose={this.closeRoute}
              visible
            >
              <ModalViewRoute
                item={{ details: [this.state.item] }}
                onClose={this.closeRoute}
              />
            </Modal>
          )
        }

        <div className="form form-col-3">
          <Select
            label="Vendedor"
            className="material"
            labelClass="material"
            options={this.state.sellers}
            value={form.seller_id}
            onChange={e => this.change(e.target.value, 'seller_id')}
            disabled={isEditing ? true : false}
          />
          <Select
            label="Zona"
            disabledFirst
            className="material"
            labelClass="material"
            options={this.state.zones}
            value={form.zone_id}
            onChange={e => this.change(e.target.value, 'zone_id')}
          />
          <Select
            label="Cliente"
            className="material"
            labelClass="material"
            options={this.state.clients}
            value={form.client_id}
            onChange={e => this.change(e.target.value, 'client_id')}
          />
          <DatePicker
            label="Fecha Desde"
            className="material"
            value={form.date}
            onChange={v => this.change(v, 'date')}
            //defaultToday={isEditing ? false : true}
            //disabled={true}
          />
          <DatePicker
            label="Fecha Hasta"
            className="material"
            value={form.date_until}
            onChange={v => this.change(v, 'date_until')}
            //defaultToday={isEditing ? false : true}
            //disabled={true}
          />
          <Select
            label="Almacén"
            className="material"
            labelClass="material"
            options={this.state.warehouses}
            value={form.warehouse_id}
            onChange={e => this.change(e.target.value, 'warehouse_id')}
            disabled={isEditing ? true : false}
          />
          <Select
            label="Tipo de visita"
            className="material"
            labelClass="material"
            options={this.state.visit_types}
            value={form.visit_type_id}
            onChange={e => this.change(e.target.value, 'visit_type_id')}
          />
        </div>

        <div className="d-flex justify-content-center">
          <button className={`push-btn ${isValidPush ? 'active':''}`} onClick={this.push}>CARGAR</button>
        </div>

        <table>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Fecha Desde</th>
              <th>Fecha Hasta</th>
              <th>Zona</th>
              <th>Almacén</th>
              <th>Ubicación</th>
              <th>Tipo de Visita</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {selectedClients.map((item, index) => (
              <tr key={index}>
                <td>{ item.client?.name }</td>
                <td>{ moment(item.date).format('DD-MM-YYYY') }</td>
                <td>{ moment(item.date_until).format('DD-MM-YYYY') }</td>
                <td>{ item.zoneName }</td>
                <td>{ item.warehouseName }</td>
                <td>
                  <img
                    src={LocationPinIcon}
                    className="location-pin action"
                    alt="Ubicación"
                    onClick={() => this.viewRoute(item)}
                  />
                </td>
                <td>{ item.visitTypeName }</td>
                <td>
                  <img
                    className="action"
                    src={CloseIcon}
                    alt="Eliminar de la lista"
                    onClick={() => this.remove(index)}
                  />
                </td>
              </tr>
            ))}

            {!selectedClients.length && (
              <tr>
                <td colSpan={8} className="no-items">No ha seleccionado clientes</td>
              </tr>
            )}
          </tbody>
        </table>

        <div className="d-flex justify-content-center my-4">
          <Button className="btn-filter" onClick={this.submit}>
            { isEditing ? 'GUARDAR' : 'CREAR' }
          </Button>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalAutosaleCreatePlanned)