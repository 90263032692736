import React from "react";
import NumberFormat from "react-number-format";

const InputFormat = props => (
  <div className="form-group">
    {props.label && <label htmlFor={props.name} className={props?.labelClass}>{props.label}</label>}
    <NumberFormat
      {...props}
      className={`form-control ${
        props.color ? "input-" + props.color : "input-white"
      } ${props.error ? props.error : ""} ${props.customClass ? props.customClass : ""}`}
      name={props.name}
      disabled={props.disabled ? props.disabled : false}
    />
    {props.invalidfeedback}
  </div>
);

export default InputFormat;
