import React from 'react';
import Menu from '../menu';
import { Table, Pagination, Button, Icon, Modal, Select, Input } from '../../components';
import { axios, Globals, Colors } from '../../utils';
import CreaEditUser from './create_edit_user';
import ViewUser from './view_user';

class Home extends React.Component {
	state={
		header: [
			'Nombre',
			'Email',
			'Cedula',
			'Status',
			'Acciones'
		],
		page: 200,
		last_page: 1,
		data: [],
		user: null,
		view: false,
		list_status: [
			{ value: '', label: 'Todos' },
			{ value: 0, label: 'Nuevos' },
			{ value: 1, label: 'Activos' },
			{ value: 2, label: 'Suspendidos' },
		],
		form:{
			status: '',
			search: ''
		}
	}

	abortController = new AbortController()

	componentDidMount(){
		this.load()
	}

	componentWillUnmount() {
		this.abortController.abort()		
	}

	load = () => {
		Globals.setLoading();
		axios.post('admin/seller/get?page=' + this.state.page, this.state.form)
			.then(async res => {
				if (res.data.result) {
					if(this.state.page > 1 && res.data.users.data.length === 0){
						await this.setState({
							page: 1
						});
						this.load()
					}else{
						await this.setState({
							last_page: res.data.users.last_page,
							data: res.data.users.data
						});
					}
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

	getStatus = (status) => {
		let respuesta = "";
		switch(status) {
			case 0:
				respuesta = {
					text: "Nuevo",
					color: Colors.red
				}
				break;
				
			case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;

			case 2:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
				break;
		}
		return respuesta;
	}

	close = async () => {
		await this.setState({
			create: false,
			view: false,
			edit: null,
			user: null
		});
		await this.load()
	}

	suspend = (item, i) =>{
		Globals.confirm('¿Desea suspender a ' + item.person.name + ' ' + item.person.lastname + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/seller/suspend',{ id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	activate = (item, i) => {
		Globals.confirm('¿Desea activar a: ' + item.person.name + ' ' + item.person.lastname + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/seller/active',{ id: item.id })
				.then(res => {
					if(res.data.result){
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	verify = (item, i) => {
		Globals.confirm('¿Desea verificar a: ' + item.person.name + ' ' + item.person.lastname + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/seller/verify',{ id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	delete = (item, i) => {
		Globals.confirm('¿Desea eliminar a: ' + item.person.name + ' ' + item.person.lastname + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/seller/delete',{ id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}
	change = e => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
    }
	render() {
		return (
			<Menu history={ this.props.history }>
				<Modal
					title="Ver Usuario" 
					onClose={ this.close }
					visible={ this.state.view }>
					<ViewUser user={ this.state.user } />
				</Modal>
				<Modal
					title={this.state.edit ? 'Editar Cuenta' : "Nueva Cuenta"}
					onClose={ this.close } 
					visible={ this.state.create }>
					<CreaEditUser
						edit={this.state.edit}
						onClose={ this.close } />
				</Modal>
				<div id="home">
					<div className="row">
						<div className="col-md-5">
							<Input
								color="white" 
								name="search" 
								label="Búsqueda"
								onChange={ this.change } 
								value={ this.state.search }
								placeholder="Buscar por Nombre, Email o Cédula" />
						</div>
						<div className="col-md-5">
							<Select
								color="white"
								name="status"
								label="Estatus"
								defaultname="Todos"
								onChange={ this.change }
								value={ this.state.status }
								options={ this.state.list_status.map(i => {
									return {
										value: i.value,
										label: i.label
									}
								}) } />
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom" onClick={ this.load }>
								Filtrar
							</Button>
						</div>
					</div>
					<Table data={ this.state.data.length } title="Vendedores" header={ this.state.header } right={
								<Button outline="true" small="true" onClick={ () => {
									this.setState({
										create: true
									});
								}}>
									<Icon name="plus" />
								</Button>
							}>
							{ this.state.data.map((i,index) => {
								return (
									<tr key={ index }>
										<td>{ i.person.name +' '+i.person.lastname }</td>
										<td>{ i.email }</td>
										<td>{ i.person.document }</td>
										<td style={ {color: this.getStatus(i.status).color }}>
											{ this.getStatus(i.status).text }
										</td>
										<td>
											<Button title="Ver" small="true" onClick={ () => {
												this.setState({
													user: i,
													view: true
												});
											} }>
												<Icon name="eye" />												
											</Button>
											<Button title="Editar" color="primary" small="true" onClick={ () => 
												this.setState({
													create: true,
													edit: {
														element: i,
														index: index,
													}
												}) }>
												<Icon name="edit" />												
											</Button>
											{ i.status == 2 && <Button color="info" title="Activar" small="true" onClick={ () => {
												this.activate(i,index);
											} }>
												<Icon name="check" />
											</Button> }
											{ i.status == 0 && <Button color="success" title="Verificar" small="true" onClick={ () => {
												this.verify(i,index);
											} }>
												<Icon name="user-plus" />
											</Button> }
											{ i.status == 1 && (
												<Button color="info" title="Suspender" small="true" onClick={ () => this.suspend(i,index) }>
													<Icon name="close" />												
												</Button>
											) }
											<Button color="red" title="Eliminar" small="true" onClick={ () => this.delete(i,index) }>
												<Icon name="trash" />												
											</Button>
										</td>
									</tr>
								)
							}) }
						</Table>

						<Pagination 
							pages={ this.state.last_page } 
							active={ this.state.page }
							onChange={ async page => {
								await this.setState({
									page: page
								});
								this.load();
						} } />
				</div>
			</Menu>
		)
	}
}

export default Home;