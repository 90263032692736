export default Object.freeze([
	{
		// Level types start at one. This is just a hole so we can index
		// them directly.
	},
	{
		type: 'SuperAdministrador',
		value: 1
	},
	{
		type: 'Administrador',
		value: 2
	},
	{
		type: 'Usuario',
		value: 3
	},
	{
		type: 'SubUsuario',
		value: 4
	},
	{
		type: 'Cliente',
		value: 5
	},
	{
		type: 'Proveedor',
		value: 6
	}
]);
