import Constants from "./constants";
// import Globals from "./globals";
import Payments from "./payments";
import moment from "moment";

const IVA = 0.16;

class Format {
  rawProducts = (configuration, Items) => {
    return Items.map(Item => {
      let current_stock = 0;

      if ((Item.product_warehouse[0] || {}).last_movement) {
        current_stock =
          Item.product_warehouse[0].last_movement.type === 1
            ? Item.product_warehouse[0].last_movement.current_stock +
            Item.product_warehouse[0].last_movement.amount
            : Item.product_warehouse[0].last_movement.current_stock -
            Item.product_warehouse[0].last_movement.amount;
      }

      const prices = [
        {
          value: 1,
          label: "Precio Min.",
          status: 1
        },
        {
          value: 2,
          label: "Precio Max.",
          status: !!configuration ? configuration.price_max : 1
        },
        {
          value: 3,
          label: "Precio Oferta",
          status: !!configuration ? configuration.price_offer : 1
        }
      ].filter(({ status }) => status === Constants.STATUS_ACTIVE);

      return {
        ...Item,
        product_id: Item.id,
        hidden: false,
        last_movement: current_stock,
        prices: prices,
        stock_min: (Item.product_warehouse[0] || {}).stock_min,
        price: (Item.product_warehouse[0] || {}).price
          ? (Item.product_warehouse[0] || {}).price
          : 0,
        percentage_earning: (Item.product_warehouse[0] || {}).percentage_earning
          ? (Item.product_warehouse[0] || {}).percentage_earning / 100
          : 0,
        percentage_max: (Item.product_warehouse[0] || {}).percentage_max
          ? (Item.product_warehouse[0] || {}).percentage_max / 100
          : 0,
        percentage_offer: (Item.product_warehouse[0] || {}).percentage_offer
          ? (Item.product_warehouse[0] || {}).percentage_offer / 100
          : 0,
        earning: (Item.product_warehouse[0] || {}).price || 0,
        earning_max: (Item.product_warehouse[0] || {}).price_max || 0,
        earning_offer: (Item.product_warehouse[0] || {}).price_offer || 0,
        quantity: 1,
        rate: 1,
        subtotal: (Item.product_warehouse[0] || {}).price || 0,
        vat: Item.exempt ? 0 : (Item.product_warehouse[0] || {}).price * IVA,
        total: Item.exempt
          ? (Item.product_warehouse[0] || {}).price
          : (Item.product_warehouse[0] || {}).price * IVA +
          (Item.product_warehouse[0] || {}).price,
        stock: 0
      };
    });
  };

  rawMovement = Items => {
    return Items.map(Item => {
      let stockBase =
        Item.type === Constants.TYPE_ENTRY
          ? Item.current_stock + Item.amount
          : Item.current_stock - Item.amount;

      return {
        ...Item,
        action_class: actionable(Item.action_class),
        stock: stockBase
      };
    });
  };

  rawInventoryAmount = Items => {
    return Items.map(Item => {
      let cash = 0,
        credit = 0,
        debit = 0,
        transfer = 0,
        onCredit = 0;

      if ((Item.method_payment || {}).length > 0) {

        //si es una devolucion resto
        if (Item.type === 3) {
          var cashMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[1].value
          );

          var creditMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[2].value
          );

          var debitMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[3].value
          );

          var transferMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[4].value
          );

          var onCreditMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[5].value
          );

          cash = cashMethod ? cashMethod.amount : 0;
          credit = creditMethod ? creditMethod.amount : 0;
          debit = debitMethod ? debitMethod.amount : 0;
          transfer = transferMethod ? transferMethod.amount : 0;
          onCredit = onCreditMethod ? onCreditMethod.amount : 0;
        }
        else {
          var cashMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[1].value
          );

          var creditMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[2].value
          );

          var debitMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[3].value
          );

          var transferMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[4].value
          );

          var onCreditMethod = Item.method_payment.find(
            Payment => Payment.type === Payments[5].value
          );

          cash = cashMethod ? cashMethod.amount : 0;
          credit = creditMethod ? creditMethod.amount : 0;
          debit = debitMethod ? debitMethod.amount : 0;
          transfer = transferMethod ? transferMethod.amount : 0;
          onCredit = onCreditMethod ? onCreditMethod.amount : 0;
        }

      }

      let type = '';
      if (Item.type === Constants.TYPE_DELIVERY) {
        type = 'NE';
      }
      else if (Item.type === 2 || Item.type === 0) {
        type = 'FACT';
      }
      else if (Item.type === 3) { //devolucion
        type = 'DEV';
      }
      return {
        ...Item,
        type: type,//Item.type === Constants.TYPE_DELIVERY ? "NE" : "FACT",
        cash: cash,
        credit: credit,
        debit: debit,
        transfer: transfer,
        onCredit: onCredit
      };
    });
  };

  amountMap = (Items, name) => {
    return Items.reduce((value, data) => {
      if (data.type == 'DEV' || data.type == 3) { //devolucion
        return value - parseFloat(data[name]);
      }
      else {
        return value + parseFloat(data[name]);
      }

    }, 0);
  };

  rawSeller = Items => {
    return Items.map(Item => {
      const { name } = Item
      const { first_name } = Item.person || {};
      const {id} = Item

      return {
        value: id,
        label: first_name
      };
    });
  };

  rawPayments = Items => {
    return Items.map((Item, key) => {
      let debts = [];
      let payments = [];
      let amountAvailable = 0;
      let amountOwed = 0;
      let documentIdentity = "";
      let expiredDebts = 0;
      let configuration_client = [];
      const creditDeadline = (Item.configuration_client || {}).days_deadline;
      const currentDate = moment()

      // Datos personales
      if ((Item || {}).person) {
        documentIdentity =
          (Item.person || {}).identity_document ||
          (Item.person || {}).fiscal_identification;
      }

      if ((Item || {}).user_payments) {
        payments = Item.user_payments.map(Payment => {

          return {
            id: Payment.id,
            user_id: Payment.user_id,
            current_amount: Payment.current_amount,
            status: Payment.status,
            creator: (Payment.creator.person || {}).first_name ||
              (Payment.creator || {}).name
          };
        });

        amountAvailable = payments.reduce(function (amount, debt) {
          return amount + debt.current_amount;
        }, 0);
      }

      // Verificación de deudas de usuario
      if ((Item || {}).request) {
        debts = Item.request.map(Request => {
          let expensesMade = Request.total;

          if ((Request.method_payment || []).length > 0) {
            expensesMade = Request.method_payment.reduce(function (
              amount,
              Payment
            ) {
              return amount + Payment.amount;
            },
              0);

            expensesMade = Request.total - expensesMade;
          }

          const dateEmission = moment(Request.date_emission);
          const timeDiff = currentDate.diff(dateEmission, 'days')
          const expiredCharge = timeDiff > creditDeadline ? true : false;
          expiredDebts += expiredCharge ? 1 : 0;

          return {
            id: Request.id,
            action_id: Request.id,
            type: Request.type === Constants.TYPE_DELIVERY ? "NE" : "FC",
            expensesMade: expensesMade,
            code: Request.code,
            date: dateEmission.format("DD/MM/YYYY"),
            expired: expiredCharge,
            seller: (Request.seller.person || {}).first_name ||
              (Request.seller || {}).name
          };
        });

        amountOwed = debts.reduce(function (amount, debt) {
          return amount + debt.expensesMade;
        }, 0);
      }
      return {
        id: Item.id,
        name: Item.name,
        status: Item.status,
        level: Item.level,
        person: Item.person,
        request: Item.request,
        amount_available: amountAvailable,
        amount_owed: amountOwed,
        documentIdentity: documentIdentity,
        debts: debts,
        payments: payments,
        expiredDebts: expiredDebts,
        configuration_client: Item.configuration_client
      };
    });
  };

  rawWarehouse = (Items, userWarehouses, userRole) => {
    return userRole === Constants.ROLE_MANAGER ||
      userRole === Constants.ROLE_SELLER
      ? Items.filter(Item => {
        return userWarehouses.some(query => {
          return parseInt(query.warehouse_id) === parseInt(Item.id);
        });
      })
      : Items;
  };

  rawBranches = (Items, userBranches, userRole) => {
    return userRole === Constants.ROLE_MANAGER ||
      userRole === Constants.ROLE_SELLER
      ? Items.filter(Item => {
        return userBranches.some(query => {
          return parseInt(query.branch_id) === parseInt(Item.id);
        });
      })
      : Items;
  };

  rawUser = user => {
    return user
    const {
      zones,
      person,
      roles,
      assigned_warehouses,
      enterprise_users,
      configuration_seller,
      ROLE_SELLER_subuser
    } = user;

    const rawZones = zones.map(({ pivot }) => ({
      zoneId: pivot.zone_id,
      userId: pivot.user_id
    }));

    return {
      id: user.id,
      name: user.name,
      email: user.email,
      status: user.status,
      level: user.level,
      role: user.role,
      assigned_warehouses: assigned_warehouses,
      enterprise_users: enterprise_users,
      ROLE_SELLER_subuser: ROLE_SELLER_subuser,
      configuration_seller: configuration_seller,
      zones: rawZones,
      person: person,
      roles: roles
    };
  };

  rawMethodPayments = Items => {
    return Items.map(Item => {
      return Item.method_payment.map(payment => {
        return payment
      })
    }).flat();
  }

  rawCashCounts = Items => {
    let cashArr = {
      label: 'Efectivo',
      method_type: 0,
      amount: 0,
      current_amount: 0,
      payments: [],
      bank_payments: []
    };
    let creditArr = {
      label: 'T. Credito',
      method_type: 0,
      amount: 0,
      current_amount: 0,
      payments: [],
      bank_payments: []
    };
    let debitArr = {
      label: 'Debito',
      method_type: 0,
      amount: 0,
      current_amount: 0,
      payments: [],
      bank_payments: []
    };
    let transferArr = {
      label: 'Transferencia',
      method_type: 0,
      amount: 0,
      current_amount: 0,
      payments: [],
      bank_payments: []
    };
    const cashCountsReduced = Items.reduce((prev, current) => {
      const { label } = Constants.CASH_COUNT_METHOD_PAYMENTS.find(method => parseInt(method.value) === parseInt(current.type));
      switch (current.type) {
        case Constants.SALE_PAYMENT_TYPES.CASH:
          cashArr = {
            ...cashArr,
            label: label,
            method_type: current.type,
            bank_type: Constants.TYPE_BANKS_LIST.CASH_ONLY,
            amount: parseFloat((cashArr.amount + current.amount).toFixed(2)),
            current_amount: parseFloat((cashArr.amount + current.amount).toFixed(2)),
            payments: [...cashArr.payments, current]
          }
          break;
        case Constants.SALE_PAYMENT_TYPES.CCARD:
          creditArr = {
            ...creditArr,
            label: label,
            method_type: current.type,
            bank_type: Constants.TYPE_BANKS_LIST.REGULAR,
            amount: parseFloat((creditArr.amount + current.amount).toFixed(2)),
            current_amount: parseFloat((creditArr.amount + current.amount).toFixed(2)),
            payments: [...creditArr.payments, current]
          }
          break;
        case Constants.SALE_PAYMENT_TYPES.DEBIT:
          debitArr = {
            ...debitArr,
            label: label,
            method_type: current.type,
            bank_type: Constants.TYPE_BANKS_LIST.REGULAR,
            amount: parseFloat((debitArr.amount + current.amount).toFixed(2)),
            current_amount: parseFloat((debitArr.amount + current.amount).toFixed(2)),
            payments: [...debitArr.payments, current]
          }
          break;
        case Constants.SALE_PAYMENT_TYPES.TRANSFER:
          transferArr = {
            ...transferArr,
            label: label,
            method_type: current.type,
            bank_type: Constants.TYPE_BANKS_LIST.REGULAR,
            amount: parseFloat((transferArr.amount + current.amount).toFixed(2)),
            current_amount: parseFloat((transferArr.amount + current.amount).toFixed(2)),
            payments: [...transferArr.payments, current]
          }
          break;
        default:
          break;
      }
      let totalArr = [cashArr, creditArr, debitArr, transferArr]
      return totalArr
    }, 0)
    return cashCountsReduced;
  }
}

function actionable(actionId) {
  switch (actionId) {
    case 1:
      return "Ajuste de inventario";
    case 2:
      return "Orden de compra";
    case 3:
      return "Nota de entrega";
    case 4:
      return "Traslado entre almacenes";
    case 5:
      return "Venta de producto";
    case 6:
      return "Operación por Producto Compuesto";
    case 7:
      return "Eliminación por Producto Compuesto";
    case 8:
     return "Devolución";
    default:
      return "Sin descripción";
  }
}

export default new Format();
