import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Input, Select, DatePicker, SelectActions, Modal } from "../../components";
import { Constants, Globals, axios } from "../../utils";

import { Branch, Warehouse } from "../../services";
import CreateeditCategory from "./create_edit_category";

class CreateEditIncomeExpensesExtra extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: "",
      modalCategory: false,
      editCategory: null,
      form: {
        id: "",
        income_id: "",
        description: "",
        date: "",
        method_id: "",
        category_id: "",
        type: "",
        total: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      },
      errors: [],
      categories: [],
      methods: [],
      list_status: [
        {
          id: Constants.PAYMENT_TYPES.PAID,
          name: "Pagado"
        },
        {
          id: Constants.PAYMENT_TYPES.TO_PAY,
          name: "Por pagar"
        },
      ],
      types: [
        {
          id: 1,
          name: "Ingresos"
        },
        {
          id: 2,
          name: "Gastos"
        },
      ],
      edit: false,
      textButton: "Crear",
      paid: false
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount() {
    const { edit } = this.props;

    this.load();

    if (!edit) {
      return;
    }

    this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    const { user } = this.props;
    const userId = user.id;

    this.setState(state => ({
      ...state,
      categories: this.props.categories,
      methods: this.props.methods,
    }));
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    let param = { ...this.state.form };
    param.user_id = this.props.user.id;
    param.web = true;
    let edit_element = false
    if(this.props.edit != undefined) edit_element = true

    Globals.setLoading();
    axios
      .post(
        edit_element == true ? "admin/income/edit" : "admin/income/create",
        param
      )
      .then(res => {
        this.setState(this.defaultState);
        let msg = ''
        edit_element == true ? msg = "Operación editada" : msg = "Operación creada"
        Globals.showSuccess(msg);
        this.props.onClose();
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.error);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    const { element } = this.props.edit;
    console.log(element)

    await this.setState({
      form: {
        id: element.id,
        income_id: element.id,
        description: element.description,
        date: moment(element.date).isValid() ? moment(element.date).toDate() : '',
        method_id: element.method_id,
        category_id: element.category_id,
        type: element.type,
        total: element.total,
        status: element.status,
      },
      edit: true,
      textButton: "Editar"
    });
  };

  handleChange = emitter => {
    const { name, value, checked } = emitter.target;
  
    if (name === "paid") {
      this.setState({
        paid: checked
      });
    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      });
    }
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  };

  createZone = () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ modalCategory: true, editCategory: null });
  };

  editCategory = (item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      modalCategory: true,
      editCategory: {
        element: item,
        index: index,
      }
    })
  };

  deleteZone = (item) => {
    Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
      Globals.setLoading("Eliminando...");
      axios.post('web/admin/zones/delete', { zone_id: item.id })
        .then(res => {
          Globals.showSuccess('zona eliminada con exito');
          this.getCategories();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  close = async (reloading = false) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
    await this.setState({ modalCategory: false, editCategory: null });
    if (reloading) await this.getCategories();
  }

  createCategory = () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({ modalCategory: true, editCategory: null });
  };

  editCategory = (item, index) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
    this.setState({
      modalCategory: true,
      editCategory: {
        element: item,
        index: index,
      }
    })
  };

  deleteCategory = (item) => {
    Globals.confirm('¿Desea eliminar la categoría: ' + item.name + '?', () => {
      Globals.setLoading("Eliminando...");
      axios.post('admin/income-categories/delete', { category_id: item.id })
        .then(res => {
          Globals.showSuccess('categoría eliminada con exito');
          this.getCategories();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  getCategories = () => {
    axios
      .post("admin/income-categories", { user_id: this.props.user.id, update_select: true, web: true })
      .then(({ data }) => {
        console.log('data get categories: ', data)
        this.setState({ categories: data.categories });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  render() {
    const { submitted } = this.state;
    const textNameField = this.props.user.role === 1 ? "Nombre/Razón Social" : "Nombre y apellido";
    const sameUser = this.state.form.id === this.props.user.id

    return (
      <form onSubmit={this.handleSubmit}>
        <Modal
          title={this.state.edit ? 'Editar categoría' : "Nueva categoría"}
          onClose={() => this.close()}
          visible={this.state.modalCategory}
        >
          <CreateeditCategory
            user={this.props.user}
            edit={this.state.editCategory}
            onClose={() => this.close(true)}
          />
        </Modal>

        {this.state.modalCategory && (
          <div
            className="backdrop"
            onClick={() => this.close()}
          />
        )}
        <div className="container-create-edit-user">
          <div className="row">
            <div className="col-md-12">
              <SelectActions
                label="Tipo"
                placeholder="Seleccione"
                actionOptions={[]}
                options={this.state.types.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                }))}
                value={this.state.form.type}
                onChange={v => this.handleChange({ target: { value: v, name: 'type' } })}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {/* <SelectActions
                label="Categoría"
                placeholder="Seleccione"
                actionOptions={[
                ]}
                options={this.state.categories.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                }))}
                value={this.state.form.category_id}
                onChange={v => this.handleChange({ target: { value: v, name: 'category_id' } })}
              /> */}
              <SelectActions
                label="Categoría"
                placeholder="Seleccione"
                actionOptions={[
                  {
                    onClick: () => this.createCategory(),
                    content: (
                      <div>
                        <span>Añadir</span>
                        <i className="fa fa-regular fa-plus" style={{ marginLeft: '0.75rem' }} />
                      </div>
                    ),
                  }
                ]}
                options={this.state.categories.map((i, idx) => ({
                  value: i.id,
                  label: i.label ?? i.name,
                  actions: [
                    <i
                      key={`zone-${i.value}-edit`}
                      className="fa fa-regular fa-edit"
                      title="Editar"
                      onClick={() => this.editCategory(i, idx)}
                    />,
                    <i
                      key={`zone-${i.value}-close`}
                      className="fa fa-regular fa-close"
                      title="Eliminar"
                      onClick={() => this.deleteCategory(i, idx)}
                    />,
                  ],
                }))}
                value={this.state.form.category_id}
                onChange={v => this.handleChange({ target: { value: v, name: 'category_id' } })}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <Input
                color=" "
                type="text"
                value={this.state.form.description}
                name="description"
                label="Descripción"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("description") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("description")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <DatePicker
                color="white"
                label="Fecha"
                labelClass="material"
                className="material"
                value={this.state.form.date}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      date: value
                    }
                  }));
                }}
              />
            </div>

            <div className="col-md-6">
              <Input
                color=" "
                type="number"
                value={this.state.form.total}
                name="total"
                label="Total ($)"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("total") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("total")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <SelectActions
                label="Estatus"
                placeholder="Seleccione"
                actionOptions={[
                ]}
                options={this.state.list_status.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                }))}
                value={this.state.form.status}
                onChange={v => this.handleChange({ target: { value: v, name: 'status' } })}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">
              <SelectActions
                label="Forma de pago"
                placeholder="Seleccione"
                actionOptions={[
                ]}
                options={this.state.methods.map((i, idx) => ({
                  value: i.id,
                  label: i.name,
                }))}
                value={this.state.form.method_id}
                onChange={v => this.handleChange({ target: { value: v, name: 'method_id' } })}
              />
            </div>
          </div>

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button color=" " type="submit" className="primary" disabled={submitted}>
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

CreateEditIncomeExpensesExtra.defaultState = {
  form: {
    id: "",
    income_id: "",
    description: "",
    date: "",
    method_id: "",
    category_id: "",
    type: "",
    total: "",
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditIncomeExpensesExtra);
