import React from "react";

import { Select, DatePicker, Button, Input } from "../../components";
import { Constants, Globals } from "../../utils";

class FilterReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        client: 0,
        status: '',
        user_id: Globals.getUserId(props.user)
      },
      dates: {
        minDate: "",
        maxDate: ""
      }
    };
  }

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    if(name === 'branch_id' || name === 'warehouse_id') {
      value = [parseInt(value)]
    }

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  render() {
    
    const { form, dates } = this.state;
    const { customers } = this.props;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              minDate={dates.minDate}
              value={form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              maxDate={dates.maxDate}
              value={form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    until: value
                  }
                }));
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Select
              color="white"
              name="client"
              label="Clientes"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.client}
              options={[...customers, { value: 0, label: "Todos" }].reverse()}
            />
          </div>
          <div className="col-md">
            <Select
              color="white"
              name="status"
              label="Estatus"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.status}
              options={[...Constants.TYPE_REPORT_CHARGES_STATUS, {value: '', label: 'Todos'}].reverse()}
            />
          </div>
          <div className="col-md">
            <div className="form-group">
              <Button
                className="btn-block btn-align-bottom"
                onClick={this.load}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default FilterReport;
