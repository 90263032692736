import React from "react";

import { Button, Input, InputFormat } from "../../components";

import { axios, Globals, Constants } from "../../utils";

class CreateEditClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      utility: false,
      form: {
        cost: 0,
        price: 0,
        price_max: 0,
        price_offer: 0,
        current_price: 0,
        current_price_max: 0,
        current_price_offer: 0,
        percentage_earning: 0,
        percentage_max: 0,
        percentage_offer: 0,
        creator_id: props.user.id
      },
      textButton: "Crear"
    };
  }

  componentDidMount() {
    if (this.props.edit) this.edit();
  }

  submit = async () => {
    Globals.setLoading();
    axios
      .post(
        this.props.edit ? "admin/prices/edit" : "admin/prices/create",
        this.state.form
      )
      .then(res => {
        this.setState({
          form: {
            cost: 0,
            price: 0,
            price_max: 0,
            price_offer: 0,
            percentage_earning: 0,
            percentage_max: 0,
            percentage_offer: 0
          }
        });
        Globals.showSuccess(res.data.msg);
        this.props.onClose();
      })
      .catch(err => {
        let message = "Disculpe, ha ocurrido un error";
        if (err.response.status === 422) {
          message = err.response.data.msg;
        }
        Globals.showError(message);
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    await this.setState({
      form: {
        warehouse_id: this.props.edit.element.warehouse_id,
        product_id: this.props.edit.element.product_id,
        cost: this.props.edit.element.cost,
        price: this.props.edit.element.price,
        price_max: this.props.edit.element.price_max,
        price_offer: this.props.edit.element.price_offer,
        current_price: this.props.edit.element.price,
        current_price_max: this.props.edit.element.price_max,
        current_price_offer: this.props.edit.element.price_offer,
        percentage_earning: this.props.edit.element.percentage_earning,
        percentage_max: this.props.edit.element.percentage_max,
        percentage_offer: this.props.edit.element.percentage_offer,
        creator_id: this.props.user.id,
        id: this.props.edit.element.id
      },
      textButton: "Actualizar Precios"
    });
  };

  change = e => {
    this.setState({
      form: {
        creator_id: this.props.user.id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCost = (values, name) => {
    const { form } = this.state;
    const { floatValue: value } = values;

    let newValue = !value ? 0 : value;

    let price =
      newValue > 0
        ? parseFloat(
            (newValue * (form.percentage_earning / 100) + newValue).toFixed(2)
          )
        : 0;

    let price_max =
      newValue > 0
        ? parseFloat(
            (newValue * (form.percentage_max / 100) + newValue).toFixed(2)
          )
        : 0;

    let price_offer =
      newValue > 0
        ? parseFloat(
            (newValue * (form.percentage_offer / 100) + newValue).toFixed(2)
          )
        : 0;

    this.setState(state => ({
      utility: newValue === Constants.VALUE_ZERO,
      form: {
        ...state.form,
        price: price,
        price_max: price_max,
        price_offer: price_offer,
        [name]: newValue
      }
    }));
  };

  handleFormat = (values, name) => {
    const { form, utility } = this.state;
    const { floatValue: value } = values;

    let newValue = !value ? 0 : value;

    switch (name) {
      case "price":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: newValue,
            percentage_earning:
              form.cost === 0 && utility
                ? form.percentage_earning
                : form.cost > 0
                ? Math.round(parseFloat(((newValue / form.cost - 1) * 100).toFixed(2)))
                : 0
          }
        }));
        break;

      case "price_max":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: newValue,
            percentage_max:
              form.cost === 0 && utility
                ? form.percentage_max
                : form.cost > 0
                ? Math.round(parseFloat(((newValue / form.cost - 1) * 100).toFixed(2)))
                : 0
          }
        }));
        break;
      case "price_offer":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: newValue,
            percentage_offer:
              form.cost === 0 && utility
                ? form.percentage_offer
                : form.cost > 0
                ? Math.round(parseFloat(((newValue / form.cost - 1) * 100).toFixed(2)))
                : 0
          }
        }));
        break;
    }
  };

  handlePercentage = (emitter, key) => {
    const { name, value } = emitter.target;
    const { form } = this.state;
    let total;
    if (name === "percentage_earning") {
      total = parseFloat((form.cost * (value / 100) + form.cost).toFixed(2));
    } else if (name === "percentage_max") {
      total = parseFloat((form.cost * (value / 100) + form.cost).toFixed(2));
    } else if (name === "percentage_offer") {
      total = parseFloat((form.cost * (value / 100) + form.cost).toFixed(2));
    }
    this.setState({
      form: {
        creator_id: this.props.user.id,
        ...this.state.form,
        [name]: value,
        [key]: total
      }
    });
  };

  render() {
    const { form, textButton } = this.state;
    return (
      <div className="text-center container-create-edit-user">
        <div className="row">
          <div className="col-md-12">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              color="gray"
              value={form.cost}
              name="cost"
              label="Costo"
              maxLength={20}
              onValueChange={values => this.handleCost(values, "cost")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">Precios Actuales</div>
          </div>
          <div className="col-md-4">
            <div className="form-group">Procentajes Utilidad</div>
          </div>
          <div className="col-md-4">
            <div className="form-group">Precios Sugeridos</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              color="gray"
              value={form.current_price}
              name="current_price"
              label="Precio Min. Actual"
              maxLength={20}
              disabled={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              color="gray"
              value={form.percentage_earning}
              name="percentage_earning"
              label="% Utilidad Min."
              type="text"
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              maxLength={4}
              onChange={value => this.handlePercentage(value, "price")}
            />
          </div>
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              customClass={
                form.price == form.current_price
                  ? "text-color"
                  : form.price > form.current_price
                  ? "text-color__green"
                  : "text-color__danger"
              }
              color="gray"
              value={form.price}
              name="price"
              label="Precio Min."
              maxLength={20}
              onValueChange={value => this.handleFormat(value, "price")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              color="gray"
              value={form.current_price_max}
              name="current_price_max"
              label="Precio Max. Actual"
              maxLength={20}
              disabled={true}
            />
          </div>
          <div className="col-md-4">
            <InputFormat
              color="gray"
              value={form.percentage_max}
              name="percentage_max"
              label="% Utilidad Max."
              type="text"
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              maxLength={4}
              onChange={value => this.handlePercentage(value, "price_max")}
            />
          </div>
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              customClass={
                form.price_max == form.current_price_max
                  ? "text-color"
                  : form.price_max > form.current_price_max
                  ? "text-color__green"
                  : "text-color__danger"
              }
              color="gray"
              value={form.price_max}
              name="price_max"
              label="Precio Max."
              maxLength={20}
              onValueChange={value => this.handleFormat(value, "price_max")}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              color="gray"
              value={form.current_price_offer}
              name="current_price_offer"
              label="Precio Oferta Actual"
              maxLength={20}
              disabled={true}
            />
          </div>
          <div className="col-md-4">
            <Input
              color="gray"
              value={form.percentage_offer}
              name="percentage_offer"
              label="% Utilidad Oferta"
              type="text"
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              maxLength={4}
              onChange={value => this.handlePercentage(value, "price_offer")}
            />
          </div>
          <div className="col-md-4">
            <InputFormat
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              customClass={
                form.price_offer == form.current_price_offer
                  ? "text-color"
                  : form.price_offer > form.current_price_offer
                  ? "text-color__green"
                  : "text-color__danger"
              }
              color="gray"
              value={form.price_offer}
              name="price_offer"
              label="Precio Oferta"
              maxLength={20}
              onValueChange={value => this.handleFormat(value, "price_offer")}
            />
          </div>
        </div>
        <div id="button">
          <Button block="true" type="button" onClick={() => this.submit()}>
            {textButton}
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateEditClient;
