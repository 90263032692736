import React from "react";
import Title from "./title";

const Table = props => {
  const { emptyMessage = 'No hay registros' } = props;

  // let content = (
  //   <table className="table table-component table-sm">
  //     <thead>
  //       <tr>
  //         {props.header.map((Item, key) => {
  //           return <th key={key}>{Item}</th>;
  //         })}
  //       </tr>
  //     </thead>
  //     <tbody>{props.children}</tbody>
  //   </table>
  // );
  
  let content = (
    <table className="table table-component table-sm">
      <thead>
        <tr>
          {props.header.map((item, key) => {
            const lines = item.includes('\n') ? item.split('\n') : [item];
            return (
              <th key={key}>
                {lines.map((line, index) => (
                  <div key={index}>{line}</div>
                ))}
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>{props.children}</tbody>
    </table>
  );

  if (!props.data || props.data === 0) {
    content = <h2 className="no-table-data">{emptyMessage}</h2>;
  }

  return (
    <div className={`table-container ${props?.className || ''}`}>
      <Title right={props.right} name={props.title} />
      <div className="table-responsive">{content}</div>
    </div>
  );
};

export default Table;
