import axios from "../../utils/axios";

class Zones {
  static async getZones(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/zones/get`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Zones;
