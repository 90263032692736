import React from "react";

import { Select, DatePicker, Button, Input } from "../../components";
import { Globals, Constants, Format } from "../../utils";
import { Warehouse } from "../../services";
import SearchIcon from '../../assets/icons/search.png';

const TYPE_LIST = [
  { value: -1, label: "Todos" },
  { value: 0, label: "Pendiente" },
  { value: 1, label: "Procesado" },
  { value: 2, label: "Anulado" }
];

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        status: -1,
        year: "",
        month: "",
        // code: "",
        since: props.since ? props.since : "",
        until: props.until ? props.until : "",
        branch_id: [],
        warehouse_id: Globals.getAssignedWarehouses(props.user),
        zone_id: 0,
        client: 0,
        seller: 0,
        user_id: Globals.getUserId(props.user)
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      months: [],
      years: [],
      warehouses: []
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }
  }

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;
    const warehouse_id = Globals.getAssignedWarehouses(user)

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: userId,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: warehouse_id
        },
        warehouses: warehousesMap
      }));
    });
  };

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    if(name === 'branch_id' || name === 'warehouse_id') {
      value = [parseInt(value)]
    }

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  render() {
    const { customers, sellers, branches, zones, status, report } = this.props;
    const { form, dates, warehouses } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              className="filter"
              minDate={dates.minDate}
              value={form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              className="filter"
              maxDate={dates.maxDate}
              value={form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    until: value
                  }
                }));
              }}
            />
          </div>
          
          <div className="col-md">
            <Select
              color="white"
              name="client"
              label="Clientes"
              className="filter"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.client}
              options={[...customers, { value: 0, label: "Todos" }].reverse()}
            />
          </div>

          {sellers && sellers.length > 0 && this.props.user.role !== Constants.ROLE_SELLER && (
            <div className="col-md">
              <Select
                color="white"
                name="seller"
                label="Vendedores"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.handleChange(emitter, null)}
                value={form.seller}
                options={[...sellers, { value: 0, label: "Todos" }].reverse()}
              />
            </div>
          )}

          {status && (
            <div className="col-md">
              <Select
                color="white"
                name="status"
                label="Estatus"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.handleChange(emitter, null)}
                value={form.status}
                options={TYPE_LIST}
              />
            </div>
          )}

          {!report && (
            <div className="col-md">
              <div className="form-group">
                <Button
                  color=" "
                  className="btn-block btn-align-bottom btn-filter">
                  <span>Filtrar</span>
                </Button>
              </div>
            </div>
          )}
        </div>

        {report && (
          <React.Fragment>
            <div className="row">
              <div className="col-md">
                <Select
                  color="white"
                  name="branch_id"
                  label="Sucursal"
                  className="filter"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={form.branch_id}
                  options={branches}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="warehouse_id"
                  label="Almacenes"
                  className="filter"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={form.warehouse_id}
                  options={[
                    ...warehouses,
                    { value: 0, label: "Todos" }
                  ].reverse()}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="zone_id"
                  label="Zona"
                  className="filter"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={form.zone_id}
                  options={zones}
                />
              </div>
              <div className="col-md">
                <Button
                  color=" "
                  className="btn-align-bottom btn-filter">
                  <span>Filtrar</span>
                </Button>
              </div>
            </div>
          </React.Fragment>
        )}
      </form>
    );
  }
}

export default Filter;
