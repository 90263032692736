import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  Modal,
  Input
} from "../../components";
import { axios, Globals, Colors } from "../../utils";
import { Enterprise, User, Sidebar } from "../../services";
import CreateEditEnterprise from "./create_edit_enterprise";
import ViewEnterprise from "./view_enterprise";

const HEADER_TABLE = [
  "Nombre de Usuario",
  "Nombre",
  "Código",
  "E-mail",
  "Cédula",
  "RIF",
  "Teléfono",
  "Estatus",
  "Multiempresa",
  "Acciones"
];
class Enterprises extends React.Component {
  state = {
    page: 1,
    last_page: 1,
    data: [],
    user: null,
    view: false,
    list_status: [
      { value: 1, label: "Activos" },
      { value: 2, label: "Suspendidos" }
    ],
    form: {
      status: "",
      search: "",
      user_id: this.props.user.id,
      role: this.props.user.role,
      administrator_id: ""
    },
    administrators: []
  };

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { page, form } = this.state;

    Globals.setLoading();

    Enterprise.getEnterprises(page, form)
      .then(({ data, last_page }) => {
        const rawEnterprise = data.map(item => {
          return {
            ...item,
            role: (item.roles[0] || {}).id || 3
          };
        });

        this.setState({
          last_page: last_page,
          data: rawEnterprise
        });
      })
      .catch(error => {
        Globals.showError(error);
        Globals.quitLoading();
      });

    User.getAdministrators(form)
      .then(response => {
        this.setState({
          administrators: response
        });
      })
      .catch(error => {
        Globals.showError(error);
        Globals.quitLoading();
      })
      .finally(() => {
        Globals.quitLoading();
      });
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });
    await this.load();
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar a: " + item.first_name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/enterprises/delete", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  suspend = (item, i) => {
    Globals.confirm("¿Desea suspender a " + item.first_name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/enterprises/suspend", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  verify = (item, i) => {
    Globals.confirm("¿Desea verificar a: " + item.first_name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/enterprises/verify", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar a la empresa: " + item.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/enterprises/get-enterprise-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.enterprise
            });
            let Menus = [];
            Sidebar.getModuleByUser(res.data.enterprise.id).then(result => {
              const { enterprise_modules } = result;

              res.data.modules.forEach((module, key) => {
                enterprise_modules.forEach(Item => {
                  if (Item.id === module.id) {
                    Menus.push(module);
                  }
                });
              });

              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: Menus
              });
            });
            this.props.history.push("/users");
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  getStatus = ({ status }) => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nuevo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  render() {
    const { user } = this.props;
    const admin = user.role === 1 ? true : false;
    let selectAdministrator;
    if (admin) {
      selectAdministrator = (
        <Select
          color="white"
          name="administrator_id"
          label="Administrador"
          defaultname="Todos"
          disabledFirst="false"
          onChange={this.change}
          value={this.state.form.administrator_id}
          options={this.state.administrators.map(i => {
            return {
              value: i.id,
              label: i.name
            };
          })}
        />
      );
    }
    return (
      <Menu history={this.props.history}>
        {this.state.view && (
          <Modal title="Ver Empresa" onClose={this.close} visible>
            <ViewEnterprise user={this.state.user} />
          </Modal>
        )}

        {this.state.create && (
          <Modal
            title={this.state.edit ? "Editar Empresa" : "Nuevo Empresa"}
            onClose={this.close}
            visible
          >
            <CreateEditEnterprise
              user={this.props.user}
              edit={this.state.edit}
              onClose={this.close}
            />
          </Modal>
        )}

        <div id="home">
          <div className="row">
            <div className="col-md">
              <Input
                color="white"
                name="search"
                label="Búsqueda"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre, E-mail, Cédula, RIF o Teléfono"
              />
            </div>
            <div className="col-md">
              <Select
                color="white"
                name="status"
                label="Estatus"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div>
            <div className="col-md">{selectAdministrator}</div>
            <div className="col-md">
              <Button className="btn-align-bottom" onClick={this.load}>
                Filtrar
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Empresas"
            header={HEADER_TABLE}
            right={
              <Button
                title="Agregar Empresa"
                outline="true"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <Icon name="plus" />
              </Button>
            }
          >
            {this.state.data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>{Item.name}</td>
                  <td>{Item.first_name}</td>
                  <td>{Item.code}</td>
                  <td>{Item.email}</td>
                  <td align={Item.identity_document ? "" : "center"}>
                    {Item.identity_document ? Item.identity_document : "-"}
                  </td>
                  <td>{Item.fiscal_identification}</td>
                  <td>{Item.phone}</td>
                  <td style={{ color: this.getStatus(Item).color }}>
                    {this.getStatus(Item).text}
                  </td>
                  <td>
                    {Item.enterprise_administrator
                      ? Item.enterprise_administrator.administrator.person
                          .first_name
                      : ""}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() => {
                        this.setState({
                          user: Item,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>

                    <Button
                      title="Editar"
                      color="primary"
                      small="true"
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: Item,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>

                    {Item.status === 2 && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.suspend(Item, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}

                    {Item.status === 0 && (
                      <Button
                        color="success"
                        title="Verificar"
                        small="true"
                        onClick={() => {
                          this.verify(Item, index);
                        }}
                      >
                        <Icon name="user-plus" />
                      </Button>
                    )}

                    {Item.status === 1 && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.suspend(Item, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}

                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(Item, index)}
                    >
                      <Icon name="trash" />
                    </Button>

                    {(this.props.user.role === 1 ||
                      this.props.user.role === 2) && (
                      <Button
                        color="blue"
                        title="Tomar Datos"
                        small="true"
                        onClick={() => this.set(Item, index)}
                      >
                        <Icon name="user" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    admin: state.admin
  };
})(Enterprises);
