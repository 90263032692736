import InventoryIcon from '../assets/icons/inventory.png';
import ProductsIcon from '../assets/icons/products.png';
import SalesIcon from '../assets/icons/sales.png';
import ExchangeIcon from '../assets/icons/exchange.png';
import ProvidersIcon from '../assets/icons/providers.png';
import UsersIcon from '../assets/icons/users.png';
import ReportsIcon from '../assets/icons/reports.png';
import PrintIcon from '../assets/icons/printer.png';
import AjustesGlobales from '../assets/icons/ajustesGlobales.png';
import Managers from '../assets/icons/gerente.png';
import Moderators from '../assets/icons/moderadores.png';
import PedidosWS from '../assets/icons/pedidos_whatsapp.png';
import Pedidos from '../assets/icons/pedidos.png';
import PlanificadorVisita from '../assets/icons/planificador_visita.png';
import Proveedores from '../assets/icons/proveedores.png';
import IngresosGastosExtras from '../assets/icons/ingre-gastos-extras.png';
import HomeIcon from '../assets/icons/home.png';
import BusinessIcon from '../assets/icons/business.png';
import CommissionsIcon from '../assets/icons/commissions.png';
import QuotesIcon from '../assets/icons/quotes.png';
import AccountsIcon from '../assets/icons/accounts.png';

export default Object.freeze([
  {
    // Level types start at one. This is just a hole so we can index
    // them directly.
  },
  {
    logo: InventoryIcon,
    id: 1,
    name: "Inventario",
    modules: [15, 13],
  },
  {
    logo: ProductsIcon,
    id: 2,
    name: "Productos",
    modules:[36]
  },
  {
    logo: SalesIcon,
    id: 3,
    name: "Clientes",
  },
  {
    logo: ExchangeIcon,
    id: 4,
    name: "Moneda",
  },
  {
    logo: Proveedores,
    id: 5,
    name: "Proceso de Compra",
  },
  {
    logo: UsersIcon,
    id: 6,
    name: "Usuarios",
    permissions:[1,2]
  },
  {
    logo: ReportsIcon,
    id: 7,
    name: "Reportes",
  },
  // {
  //   logo: ProvidersIcon,
  //   id: 8,
  //   name: "Vendedores",
  // },
  // {
  //   logo: Moderators,
  //   id: 9,
  //   name: "Moderadores",
  // },
  // {
  //   logo: Managers,
  //   id: 10,
  //   name: "Gerentes",
  // },
  {
    logo: Pedidos,
    id: 11,
    name: "Pedidos",
    modules: [37,44,69,53],
  },
  {
    logo: PedidosWS,
    id: 12,
    name: "Pedidos de Whatsapp",
  },
  {
    logo: PlanificadorVisita,
    id: 13,
    name: "Jornada de trabajo",
    modules: [42,71,74],
  },
  {
    logo: AjustesGlobales,
    id: 14,
    name: "Globales",
    modules: [45,47,48, 52, 61],
  },
  {
    logo: IngresosGastosExtras,
    id: 15,
    name: "Ingresos/Gastos Extras",
  },
  {
    logo: HomeIcon,
    id: 16,
    name: "Inicio",
  },
  {
    logo: BusinessIcon,
    id: 17,
    name: "Mi Cuenta / Mi Negocio",
  },
  {
    logo: CommissionsIcon,
    id: 18,
    name: "Comisiones",
  },
  {
    logo: CommissionsIcon,
    id: 19,
    name: "Almacenes",
  },
  {
    logo: Moderators,
    id: 20,
    name: "Personal",
    modules: [28, 29, 30] //Vendedores, gerentes, moderadores, 
  },
  {
    logo: QuotesIcon,
    id: 21,
    name: "Cotizaciones"
  },
  {
    logo: AccountsIcon,
    id: 22,
    name: "Cuentas",
    modules: [27, 64],
  },
]);
