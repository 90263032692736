import React from "react";
import moment from "moment";

import { Textarea, ViewSerial, Button, Select } from "../../components";
import { Globals } from "../../utils";
import {
    ReturnsDeliveryOrBill
} from "../../services";

const type = [
  { value: 1, label: "Nota de entrega" },
  { value: 2, label: "Factura de Venta" }
];

class CreateReturnFrom extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            view_serials: false,
            item_serials: null,
            serials: null,
            form: {
                observations: "",
            },
            submitted: false,
            errors: [],
            textButton: "Crear",
        };
    }

    handleSubmit = emitter => {
        emitter.preventDefault();

        const { submitted, create } = this.state;

        if (submitted) {
            return;
        }

        // Vaciando los campos de errores al enviar solicitud
        this.setState({ submitted: true, errors: [] });

        const { Item } = this.props;
        const { user } = this.props;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        let request = {
            'Id': enterpriseId,
            'user_id': user.id,
            'role': user.role,
            'code': Item.code,
            'warehouse_id': Item.warehouse_id,
            'observations': this.state.form.observations,
        }

        ReturnsDeliveryOrBill.createReturnFrom(request)
            .then(async response => {
                if(response.error){
                    Globals.showError(response.error);
                    this.props.onClose();
                    return;
                }
                const { control_number } = response;
                if(response.error){
                    Globals.showError(response.error);
                }else{
                    Globals.showSuccess(`¡Devolución Nº ${control_number} creada exitosamente!`);
                }
                await this.setState({
                    observations: "",
                });
                this.props.onClose();
            })
            .catch(() => Globals.showError());

        // switch (create) {
        //     case true:
        //         Delivery.createDelivery(form)
        //             .then(async response => {
        //                 const { code } = response;

        //                 this.setState(this.defaultState);
        //                 Globals.showSuccess(`¡Pedido Nº ${code} creada exitosamente!`);
        //                 await this.setState({
        //                     visiblePayment: false,
        //                     form: {
        //                         id: "",
        //                         forthright: false,
        //                         warehouse_id: "",
        //                         branch_id: "",
        //                         client_id: "",
        //                         code: "",
        //                         currency_id: "",
        //                         observations: "",
        //                         subtotal: 0.0,
        //                         taxable: 0.0,
        //                         exempt: 0.0,
        //                         vat: 0.0,
        //                         total: 0.0,
        //                         creator_id: this.props.user.id,
        //                         enterprise_id: this.props.Id,
        //                         products: [],
        //                         type_payments: []
        //                     }
        //                 });
        //                 this.props.onClose();
        //             })
        //             .catch(error => {
        //                 if ((error || {}).message) {
        //                     Globals.showError(error.message);
        //                 }

        //                 if (error.hasOwnProperty("msg")) {
        //                     Globals.showError(error.msg);
        //                 }

        //                 this.setState({ submitted: false, errors: error, visiblePayment: false, });
        //             });
        //         break;
        //     default:
        //         break;
        // }
    };

    handleChange = emitter => {
        const { name, value } = emitter.target;
        const { form } = this.state;

        switch (name) {
            case "observations":
                this.setState(state => ({
                    form: {
                        ...state.form,
                        observations: value,
                    }
                }));
                break;
            default:
                this.setState(state => ({
                    form: {
                        ...state.form,
                        type_payments: [],
                        [name]: value
                    }
                }));
                break;
        }
    };

    hasErrorFor(field) {
        return !!this.state.errors[field];
    }

    renderErrorFor(field) {
        if (this.hasErrorFor(field)) {
            return (
                <span className="invalid-feedback my-2 text-left">
                    <strong>{this.state.errors[field][0]}</strong>
                </span>
            );
        }
    }

    render() {
        const { Item } = this.props;
        const { view_serials, item_serials, serials, form, submitted, textButton } = this.state;
        const _Item = Item;

        return (
            <React.Fragment>
                {view_serials && (
                    <ViewSerial
                        onBack={() => {
                            this.setState({
                                item_serials: null,
                                view_serials: false,
                                serials: null
                            });
                        }}
                        product={item_serials}
                        serials={serials}
                    />
                )}

                {!view_serials && (
                    <React.Fragment>
                        <div className="row">
                            <div className="col-md">
                                <div className="form-group">
                                    <div className="table-responsive">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Nro.</th>
                                                    <td>{Item.code}</td>
                                                    <th>Fecha:</th>
                                                    <td>{parseFormat(Item.date_emission)}</td>
                                                </tr>
                                                {(Item || {}).request_order && (
                                                    <tr style={{ whiteSpace: "nowrap" }}>
                                                        <th>Nro. de Pedido:</th>
                                                        <td>{Item.request_order.code}</td>
                                                        <th colSpan="2"></th>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <th>Cliente:</th>
                                                    <td>
                                                        {(Item.client.person || {}).first_name ||
                                                            (Item.client || {}).name}
                                                    </td>
                                                    <th>Hora:</th>
                                                    <td>{timeFormat(Item.date_emission)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Rif Cliente:</th>
                                                    <td>
                                                        {(Item.client.person || {}).fiscal_identification ||
                                                            (Item.client.person || {}).identity_document}
                                                    </td>
                                                    <th>Tipo:</th>
                                                    <td>
                                                      {Item.type == 1 && (
                                                        "Nota de Entrega"
                                                      ) || (
                                                        "Factura de Venta"
                                                      )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th>Vendedor:</th>
                                                    <td>
                                                        {(Item.seller.person || {}).first_name ||
                                                            (Item.seller || {}).name}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md">
                                <div className="form-group">
                                    <div className="table-responsive">
                                        <table className="table table-bordered view-delivery">
                                            <thead>
                                                <tr>
                                                    <th colSpan="6" className="text-center">
                                                        PRODUCTOS DE LA NOTA DE ENTREGA
                                                    </th>
                                                </tr>
                                                <tr style={{ whiteSpace: "nowrap" }}>
                                                    <th>Código</th>
                                                    <th>Nombre</th>
                                                    <th>Descripción</th>
                                                    <th>Precio</th>
                                                    <th>Cantidad</th>
                                                    <th>Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {_Item.details.map((Item, key) => {
                                                    const serials = _Item.operation.filter(
                                                        i => i.product_warehouse.product_id == Item.id
                                                    );

                                                    return (
                                                        <tr key={key}>
                                                            <td>
                                                                <p>{Item.code}</p>
                                                                {serials.length > 0 && (
                                                                    <p
                                                                        className="view-serials"
                                                                        onClick={() => {
                                                                            this.setState({
                                                                                view_serials: true,
                                                                                item_serials: Item,
                                                                                serials: serials
                                                                            });
                                                                        }}
                                                                    >
                                                                        Ver Seriales
                                                                    </p>
                                                                )}
                                                            </td>
                                                            <td>{Item.name}</td>
                                                            <td>{Item.description ? Item.description : 'Sin descripción'}</td>
                                                            <td>{Globals.formatMiles(Amount(Item))}</td>
                                                            <td>{Item.pivot.quantity}</td>
                                                            <td>
                                                                {Globals.formatMiles(Item.pivot.subtotal)}
                                                            </td>
                                                        </tr>
                                                    );
                                                })}
                                                <tr>
                                                    <td colSpan="3"></td>
                                                    <th>Subtotal/Cantidad</th>
                                                    <td>{getQuantity(Item)}</td>
                                                    <td>{Globals.formatMiles(getAmount(Item))}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <th>Descuento</th>
                                                    <td>
                                                        {Globals.formatMiles(Item.discount_percentage)}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <th>Exento</th>
                                                    <td>{Globals.formatMiles(Item.exempt)}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <th>Base Imponible</th>
                                                    <td>{Globals.formatMiles(Item.taxable)}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <th>IVA</th>
                                                    <td>{Globals.formatMiles(Item.vat)}</td>
                                                </tr>
                                                <tr>
                                                    <td colSpan="4"></td>
                                                    <th>Total</th>
                                                    <td>{Globals.formatMiles(Item.total)}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="row text-center">
                            <div className="col-md">
                                <div className="form-group">
                                    <Textarea
                                        color="gray"
                                        value={this.state.form.observations}
                                        name="observations"
                                        label="Observaciones"
                                        onChange={this.handleChange}
                                        error={`${this.hasErrorFor("observations") ? "is-invalid" : ""
                                            }`}
                                        invalidfeedback={this.renderErrorFor("observations")}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md">
                                {submitted ? (
                                    <div
                                        className="spinner-border text-primary"
                                        role="status"
                                    >
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                ) : (
                                        <div id="button">
                                            <Button
                                                block="true"
                                                type="button"
                                                disabled={submitted || form.observations == ''}
                                                onClick={emitter => {
                                                    Globals.confirm(
                                                        `¿Seguro de devolver la nota de entrega número: ${Item.code}?`,
                                                        () => {
                                                            this.handleSubmit(emitter);
                                                        }
                                                    );
                                                }}
                                            >
                                                {this.state.textButton}
                                            </Button>
                                        </div>
                                    )}
                            </div>
                        </div>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    }
}

function getQuantity({ details: Products }) {
    return Products.reduce(function (Quantity, Item) {
        return parseInt(Item.pivot.quantity + Quantity);
    }, 0);
}

function getAmount({ details: Products }) {
    return Products.reduce(function (Amount, Item) {
        return parseFloat(Item.pivot.subtotal + Amount);
    }, 0);
}

function Amount({ pivot: Amount }) {
    return parseFloat(Amount.subtotal / Amount.quantity);
}

function parseFormat(Date) {
    return moment(Date).format("DD/MM/YYYY");
}

function timeFormat(Time) {
    return moment(Time).format("HH:mm:ss");
}

export default CreateReturnFrom;