import React from "react";
import { Button, Input, Select } from "../../components";
import { axios, Globals } from "../../utils";

class CreateEditBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        id: "",
        name: "",
        code: "",
        enterprise_id: props.user.id,
        creator_id: props.user.id
      },
      enterprises: props.enterprises,
      textButton: "Crear"
    };
  }

  componentDidMount() {
    if (this.props.edit) this.edit();
  }

  submit = () => {
    const { form } = this.state;

    axios
      .upload(
        this.props.edit ? "admin/branches/edit" : "admin/branches/create",
        form
      )
      .then(res => {
        Globals.showSuccess(res.data.msg);
        this.props.onClose();
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.msg);
          return;
        }
        Globals.showError();
      });
  };

  edit = () => {
    this.setState({
      form: {
        creator_id: this.props.edit.element.creator_id,
        code: this.props.edit.element.code,
        name: this.props.edit.element.name,
        enterprise_id: this.props.edit.element.enterprise_id,
        id: this.props.edit.element.id
      },
      textButton: "Editar"
    });
  };

  change = e => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }

    this.setState({
      form: {
        enterprise_id: this.props.user.id,
        creator_id: this.props.user.id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    const admin =
      this.props.user.role === 1 || this.props.user.role === 2 ? true : false;
    let selectEnterprise;
    // if (admin) {
    //   selectEnterprise = (
    //     <Select
    //       color="gray"
    //       name="enteprise_id"
    //       label="Empresa"
    //       defaultname="Seleccione"
    //       disabledFirst={false}
    //       onChange={this.change}
    //       value={this.state.form.enteprise_id}
    //       options={this.state.users.map(i => {
    //         return {
    //           value: i.id,
    //           label: i.name
    //         };
    //       })}
    //     />
    //   );
    // }
    return (
      <div className="container-create-edit-user">
        <div className="row">
          <div className="col-md-12">
            <Input
              color=" "
              value={this.state.form.name}
              name="name"
              className="material"
              label="Nombre"
              labelClass="material"
              onChange={this.change}
            />
          </div>
          <div className="col-md-12">
            <Input
              color=" "
              value={this.state.form.code}
              name="code"
              className="material"
              label="Código"
              labelClass="material"
              onChange={this.change}
            />
          </div>
          <div className="col-md-12">{selectEnterprise}</div>
        </div>
        <div id="button" className="d-flex justify-content-center">
          <Button
            type="button"
            color=" "
            className="primary"
            onClick={() => this.submit()}
          >
            <span>{this.state.textButton}</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateEditBrand;
