import React from "react";
import { connect } from "react-redux";

import { Globals, Constants, Format, axios } from "../../utils";
import { Warehouse, Inventory, Provider } from "../../services";

import {
  Button,
  Select,
  Input,
  InputFormat,
  Textarea,
  Icon,
  CheckBox,
  ModalScan,
  InputGroup,
  Modal,
  DatePicker
} from "../../components";

import NumberFormat from "react-number-format";
import moment from "moment";

class CreateEditInventoryAdjustment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      search: '',
      form: {
        branch_id: '',
        warehouse_id: '',
        type: '',
        description: '',
        products: [],
        provider_id: '',
        provider_doc_type: 'J',
        provider_doc: '',
        number_code: '',
        date: moment().format('YYYY-MM-DD'),
        warehouse_destination_id: '',
        branch_destination_id: '',
        enterprise_id: props.Id,
        creator_id: props.user.id,
        final_cost: 0,
        showTranslate: false
      },
      branches: props.branches,
      warehouses: [],
      branches_destination: props.branches,
      warehouses_destination: [],
      suggestions: [],
      providers: [],
      errors: [],
      create: true,
      textButton: "Crear",
      edit_serial: false,
      item_serial: null,
      showSavedText: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    };
  }

  async componentDidMount() {
    // Retoma los datos del formulario anterior
    // const { inventory } = this.props;
    // if (inventory) {
    //   await this.setState({
    //     form: {
    //       ...inventory
    //     }
    //   });
    // }

    this.maybeLoadProviders();
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }
    if (
      prevState.form.branch_destination_id !==
      this.state.form.branch_destination_id &&
      this.state.form.branch_destination_id
    ) {
      this.maybeLoadWarehousesDestination(this.state.form.warehouse_id);
    }
  }

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { form, submitted, create } = this.state;
    let fireConfirm = false;

    if (submitted) {
      return;
    }

    // Filtrando productos para valores nulos
    const findValuesNull = form.products.filter(
      Item => Item.quantity <= 0 || Item.quantity === ""
    );

    // Filtrando productos sin costo cargado
    const findCostNull = form.products.filter(
      Item => Item.cost <= 0 || Item.cost === ""
    );

    // Filtrando productos sin precio de venta cargado
    const findPriceNull = form.products.filter(
      Item => Item.price <= 0 || Item.price === "" || Item.price === undefined
    );

    // Verificando si se seleccione actualizar los precios de los productos
    const findUpdatePrice = form.products.filter(Item => !Item.change_price);

    if (form.products.length === 0) {
      Globals.showError("¡La operación debe tener productos agregados!");
      return;
    } else if (findValuesNull.length > 0) {
      Globals.showError("¡Los productos deben tener un valor mayor a 0!");
      return;
    } else if (findCostNull.length > 0) {
      Globals.showError("¡Los productos deben tener un costo mayor a 0!");
      return;
    } else if (findPriceNull.length > 0 && parseInt(form.type) === 1) {
      Globals.showError("¡Los productos deben tener un precio de venta mayor a 0!");
      return;
    } else if (
      findUpdatePrice.length > 0 &&
      ((parseInt(form.type) === 3 &&
        parseInt(form.branch_destination_id) === parseInt(form.branch_id)) ||
        parseInt(form.type) === 1)
    ) {
      fireConfirm = true;
    }

    // Validando si hay productos por serializar
    const cant = form.products.filter(
      i =>
        parseInt(i.serialization) === Constants.SERIALIZATION.ACTIVE && i.serials == null
    ).length;
    if (cant > 0) {
      Globals.showError("¡Hay productos que necesitan serializarse!");
      return;
    }

    // Ingreso
    if (Number(form.type) === Constants.TYPE_ADJUSTMENT[0].value) {
      // Validando que el ingreso tenga un proveedor
      if (!form.provider_id) {
        Globals.showError("¡Debe seleccionar un proveedor!");
        return;
      }
      // Validando que el ingreso tenga una factura
      if (!form.number_code) {
        Globals.showError("¡Debe ingresar el número de factura!");
        return;
      }
    }

    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });

    switch (create) {
      case true:
        if (!fireConfirm) {
          Inventory.createMovement(form)
            .then(async response => {
              await this.setState({
                form: {
                  branch_id: "",
                  warehouse_id: "",
                  type: 1,
                  description: "",
                  products: [],
                  warehouse_destination_id: "",
                  branch_destination_id: "",
                  enterprise_id: this.props.Id,
                  creator_id: this.props.user.id,
                  final_cost: 0,
                  showTranslate: false
                }
              });
              Globals.showSuccess("¡Movimiento creado exitosamente!");
              this.props.onClose();
            })
            .catch(error => {
              if (error.hasOwnProperty("msg")) {
                Globals.showError(error.msg);
              }
              this.setState({
                submitted: false,
                errors: error
              });
            });
        } else {
          Globals.confirm(
            "¡Uno o mas productos no tienen seleccionada la opción de actualizar los precios!",
            () => {
              Inventory.createMovement(form)
                .then(async response => {
                  await this.setState({
                    form: {
                      branch_id: "",
                      warehouse_id: "",
                      type: 1,
                      description: "",
                      products: [],
                      warehouse_destination_id: "",
                      branch_destination_id: "",
                      enterprise_id: this.props.Id,
                      creator_id: this.props.user.id,
                      final_cost: 0,
                      showTranslate: false
                    }
                  });
                  Globals.showSuccess("¡Movimiento creado exitosamente!");
                  this.props.onClose();
                })
                .catch(error => {
                  if (error.hasOwnProperty("msg")) {
                    Globals.showError(error.msg);
                  }
                  this.setState({
                    submitted: false,
                    errors: error
                  });
                });
            },
            "¿Seguro que desea continuar?"
          );
          this.setState({ submitted: false, errors: [] });
        }
        break;
      case false:
        break;
      default:
        break;
    }
  };

  AutoComplete = ({ search }) => {
    const { products } = this.props;
    const { form } = this.state;
    console.log(products)

    let finalProducts = [];

    let isnum = /^[0-9]+$/;

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    if (!search.match(isnum)) { //Si la busqueda es por nombre
      let searchLowerCase = search.toLowerCase();

      const filterProducts = products.filter(Item => {
        let nameLowerCase = Item.name.toLowerCase();

        switch (parseInt(form.type)) {
          case 1:
            return (
              nameLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id
            );
          case 2:
            return (
              nameLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id &&
              Item.last_movement > 0
            );
          case 3:
            return (
              nameLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id &&
              Item.last_movement > 0
            );
          default:
            return Item;
        }
      });

      const notChosenProducts = filterProducts.filter(el => {
        return form.products.every(f => {
          return parseInt(f.id) !== parseInt(el.id);
        });
      });

      if (form.products.length > 0) {
        finalProducts = notChosenProducts;
      } else {
        finalProducts = filterProducts;
      }
      
      this.setState({ suggestions: finalProducts });

    }
    else if (search.match(isnum)) { //Si la busqueda es por codigo

      let searchLowerCase = search.toLowerCase();

      const filterProducts = products.filter(Item => {
        let codeLowerCase = Item.code.toLowerCase();

        switch (parseInt(form.type)) {
          case 1:
            return (
              codeLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id
            );
          case 2:
            return (
              codeLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id &&
              Item.last_movement > 0
            );
          case 3:
            return (
              codeLowerCase.indexOf(searchLowerCase) !== -1 &&
              parseInt(this.state.form.warehouse_id) === Item.warehouse_id &&
              Item.last_movement > 0
            );
          default:
            return Item;
        }
      });

      const notChosenProducts = filterProducts.filter(el => {
        return form.products.every(f => {
          return parseInt(f.id) !== parseInt(el.id);
        });
      });

      if (form.products.length > 0) {
        finalProducts = notChosenProducts;
      } else {
        finalProducts = filterProducts;
      }

      this.setState({ suggestions: finalProducts });

    }



  };

  handleChange = emitter => {
    const { name, value } = emitter.target;
    console.log(this.state.form.products)
    switch (name) {
      case "search":
        this.setState({ [name]: value }, () => {
          this.AutoComplete(this.state);
        });
        break;
      case "type":
        this.setState(state => {
          state.form = {
            ...state.form,
            showTranslate: parseInt(value) === 3,
            [name]: value
          };
          return state;
        });
        break;
      case "warehouse_id":
        if (this.state.form.products.length > 0) {
          Globals.sure(
            "¡Perderá su información de productos al cambiar de almacén! ¿Desea cambiar de almacén?",
            confirm => {
              if (confirm.value) {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    products: []
                  },
                  suggestions: [],
                  search: ''
                }));
              }
            }
          );
        } else {
          this.setState(state => ({
            form: {
              ...state.form,
              [name]: value
            },
            suggestions: [],
            search: ''
          }));
        }
        break;
      case "branch_id":
        if (this.state.form.products.length > 0) {
          Globals.sure(
            "¡Perderá su información de productos al cambiar de sucursal! ¿Desea cambiar de sucursal?",
            confirm => {
              if (confirm.value) {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    products: []
                  },
                  suggestions: [],
                  search: ''
                }));
              }
            }
          );
        } else {
          this.setState(state => ({
            form: {
              ...state.form,
              [name]: value
            },
            suggestions: [],
            search: ''
          }));
        }
        break;
      case "provider_id":
        const provider = this.state.providers.find(x => Number(x.value) === Number(value));
        const rif = String(provider.person?.fiscal_identification).split('-');
        
        let doc = '';
        let type = '';
        if (rif.length === 2) {
          type = rif[0];
          doc = rif[1];
        }

        this.setState(state => ({
          form: {
            ...state.form,
            provider_id: provider.value,
            provider_doc_type: type,
            provider_doc: doc,
          }
        }));
        break;
      default:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          }
        }));
        break;
    }
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;

    const Id = user.id;

    Warehouse.getWarehouseByBranch({
      user_id: Id,
      branch_id: form.branch_id
    }).then(response => {

      const warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );


      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: this.props.inventory ? this.props.inventory.warehouse_id : ""
        },
        warehouses: warehousesMap
      }));
    });
  };

  maybeLoadWarehousesDestination = origin_warehouse_id => {
    const { form } = this.state;
    const { user, inventory } = this.props;

    const Id = user.id;

    Warehouse.getWarehouseByBranch({
      user_id: Id,
      branch_id: form.branch_destination_id
    }).then(response => {
      let warehouses_destination = response.filter(
        Item => Item.id !== parseInt(origin_warehouse_id)
      );
      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_destination_id: inventory ? inventory.warehouse_destination_id : ""
        },
        warehouses_destination: warehouses_destination
      }));
    });
  };

  maybeLoadProviders = () => {
    const { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Provider.getProviders({ Id: enterpriseId })
      .then(response => {
        const providers = response
          .filter(Item => Item.status === Constants.STATUS_ACTIVE)
          .map((Item, key) => {
            return {
              ...Item,
              value: Item.id,
              label: Item.name,
              time_limit: Item.configuration_provider ? Item.configuration_provider.days_deadline : ''
            };
          });
        this.setState({ providers });
      })
      .catch(error => {
        Globals.showError();
      });
};

  handleNum = (emitter, key) => {
    const { name, value, type, checked } = emitter.target;
    const { form } = this.state;
    const isPurchase = parseInt(form.type) === Constants.TYPE_ADJUSTMENT[0].value;

    let newValue = value ? parseInt(value) : value;
    let rawProducts = form.products;
    let quantityValue;
    let cost_total = 0;
    let text_Type = "descargar";
    if (parseInt(form.type) === 3) {
      text_Type = "trasladar";
    }
    switch (name) {
      case "quantity":
        quantityValue =
          newValue > 0 && newValue !== ""
            ? parseInt(form.type) === Constants.TYPE_ADJUSTMENT[0].value
              ? rawProducts[key].last_movement + newValue
              : rawProducts[key].last_movement - newValue
            : 0;
        if(isPurchase){
          cost_total = newValue * rawProducts[key]["cost"];
        }
        else{
          cost_total = newValue * rawProducts[key]["price"];
        }
        

        if (Math.sign(quantityValue) === -1) {
          Globals.showError(
            // `La cantidad a descargar supera el stock actual del producto: ${rawProducts[key].name}`
            `La cantidad a ${text_Type} de este producto supera su stock actual`
          );
          return;
        }

        rawProducts[key] = {
          ...rawProducts[key],
          quantity: newValue,
          stock: quantityValue,
          cost_total: cost_total
        };
        break;
      case "cost":
        if (Math.sign(quantityValue) === -1) {
          Globals.showError(
            `El costo del producto debe ser mayor a cero para el producto: ${rawProducts[key].name}`
          );
          return;
        }

        cost_total = newValue * rawProducts[key]["quantity"];

        rawProducts[key] = {
          ...rawProducts[key],
          cost: newValue,
          cost_total: cost_total
        };
        break;
      case "change_price":
        const set = type === "checkbox" ? checked : value;
        rawProducts[key] = {
          ...rawProducts[key],
          change_price: set
        };
        break;
      default:
        break;
    }

    this.setState(state => ({
      form: {
        ...state.form,
        products: rawProducts
      }
    }));
  };

  getTotalCost = () => {
    const { form } = this.state;

    const cost = form.products.reduce((count, product) => {
      return count + product.cost_total;
    }, 0);

    this.setState(state => ({
      form: {
        ...state.form,
        final_cost: cost
      }
    }));
  };

  handleValueChange = (values, key) => {
    const { floatValue } = values;
    const { form } = this.state;
    const isPurchase = parseInt(form.type) === Constants.TYPE_ADJUSTMENT[0].value;

    let rawProducts = form.products;
    let cost_total = 0;

    rawProducts[key] = {
      ...rawProducts[key],
      cost: floatValue
    };

    if(isPurchase){
      cost_total = parseFloat(rawProducts[key]["cost"] * rawProducts[key]["quantity"]).toFixed(2);
    }
    else{
      cost_total = floatValue * rawProducts[key]["quantity"];
    }

    rawProducts[key] = {
      ...rawProducts[key],
      cost_total: cost_total
    };

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.getTotalCost()
    );
  };

  handleValueChangePrice = (values, key) => {
    const { floatValue } = values;
    const { form } = this.state;

    let rawProducts = form.products;

    rawProducts[key] = {
      ...rawProducts[key],
      price: floatValue
    };

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      })
    );
  };

  view = item => {
    this.setState({
      view_serials: true,
      item_serials: item.product,
      serials: item.serials
    });
  };

  takeProduct = Product => {
    console.log(Product)
    const { form } = this.state;
    const exists = this.state.form.products.find(Item => {
      return Item.id === Product.id;
    });

    if (Product.quantity == "") {
      Product.quantity = 0;
    }

    if (!exists) {
      if (
        Product.stock_min > Product.last_movement &&
        (parseInt(form.type) === 2 || parseInt(form.type) === 3)
      ) {
        Globals.showInformation(
          "El producto seleccionado se encuentra debajo de su stock minimo"
        );
      }
      this.setState(state => ({
        form: {
          ...state.form,
          products: [...state.form.products, Product]
        },
        suggestions: [],
        search: ""
      }));
    }

    this.saveInventory();
  };

  handleClick = Product => {
    const { form } = this.state;

    const filterMap = form.products.filter(Item => Item.id !== Product.id);

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: filterMap
        }
      }),
      () => {
        Globals.showSuccess("¡Producto eliminado de la orden!");
      }
    );
  };

  showAlertInfo = (warehouse_id, branch_id) => {
    Globals.confirm(
      "¡Perderá su información de productos al cambiar de almacén!",
      "¿Desea cambiar de almacén?",
      () => {
        this.setState(state => ({
          form: {
            ...state.form,
            products: []
          }
        }));
      }
    );
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  clearDestiny = () => {
    this.setState(state => ({
      form: {
        ...state.form,
        branch_destination_id: "",
        warehouse_id: "",
        warehouse_destination_id: ""
      },
      warehouses: [],
      warehouses_destination: []
    }));
  };

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };

  showSerials = item => {
    const { form } = this.state;
    let creator_id = this.props.user.id;
    let product_id = item.id;
    let request = {
      creator_id: creator_id,
      warehouse_id: form.warehouse_id,
      product_id: product_id,
    };

    //request.push({ creator_id: creator_id }, { warehouse_id: form.warehouse_id }, { product_id: product_id });

    axios
      .post("admin/deliver-notes/getserialsavailable", request)
      .then(res => {
        if (res.data.serialsAvailable) {
          if (res.data.serialsCompromised > 0) {
            if (res.data.serialsCompromised == 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidad comprometida en un PEDIDO.'
              });
            }
            else if (res.data.serialsCompromised > 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidades comprometidas en un PEDIDO.'
              });
            }
          }
          this.setState({
            show_serials: true,
            item_serial: item,
            serials_available: res.data.serialsAvailable,
          });
        }
        else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError(err);
      });


  }

  onCloseShowSerials = () => {
    this.setState({
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    });
  }

  onSuccess = async item => {
    let products = [...this.state.form.products];
    const index = products.findIndex(i => parseInt(i.id) === parseInt(this.state.item_serial.id));
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      form: {
        ...this.state.form,
        products: products
      }
    });
    await this.handleNum(
      {
        target: {
          value: item.length,
          name: "quantity"
        }
      },
      index
    );
    await this.getTotalCost();
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_INVENTORY',
      payload: {
        ...this.state.form
      }
    });
  }

  saveInventory = () => {
    this.setState({
      showSavedText: true
    }, () => {
      setTimeout(() => {
        this.setState({
          showSavedText: false
        })
      }, 5000)
    });
    this.props.dispatch({
      type: 'SET_INVENTORY',
      payload: {
        ...this.state.form
      }
    });
  }

  totalQuantity(form) {
    let quantity = 0;
    form.products.map((Item, key) => {
      quantity = Item.quantity + quantity;
    })
    return quantity;
  }

  getAdjustmentTypeAction = () => {
    switch (Number(this.state.form.type)) {
      case Constants.TYPE_ADJUSTMENT[0].value:
        return 'a Ingresar';

      case Constants.TYPE_ADJUSTMENT[1].value:
        return 'a Egresar';

      case Constants.TYPE_ADJUSTMENT[2].value:
        return 'a Trasladar';

      default:
        return ''
    }
  }

  getAdjustmentTypeTitle = () => {
    switch (Number(this.state.form.type)) {
      case Constants.TYPE_ADJUSTMENT[0].value:
        return 'INGRESO DE PRODUCTOS';

      case Constants.TYPE_ADJUSTMENT[1].value:
        return 'SALIDA DE PRODUCTOS';

      case Constants.TYPE_ADJUSTMENT[2].value:
        return 'TRASLADO DE PRODUCTOS';

      default:
        return ''
    }
  }

  getProductName = (Item) => {
    return (
      Item.name +
      (Item?.product_brand?.name ? `, ${Item?.product_brand?.name}` : '') +
      (Item?.product_model?.name ? `, ${Item?.product_model?.name}` : '')
    )
  }

  render() {
    const {
      branches,
      warehouses,
      branches_destination,
      warehouses_destination,
      suggestions,
      providers,
      form,
      submitted,
      item_serial,
      showSavedText,
      show_serials,
      serials_available,
      serialsCompromised,
    } = this.state;
    const { user } = this.props;

    // const multipleWarehouses = user.role === Constants.ROLE_SELLER ? true :
    //   Globals.getAssignedWarehouses(user).length > 1 ? true : false;

    const isPurchase = parseInt(form.type) === Constants.TYPE_ADJUSTMENT[0].value;
    const isSale = parseInt(form.type) === Constants.TYPE_ADJUSTMENT[1].value;
    const isTransfer = parseInt(form.type) === Constants.TYPE_ADJUSTMENT[2].value;

    return (
      <React.Fragment>
        {this.state.edit_serial && (
          <React.Fragment>
            {/*<EditSerial
              onSuccess={ this.onSuccess }
              onCancel={ this.onCancel }
              product={ this.state.item_serial } />*/}

            <ModalScan
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
              serials={item_serial.serials}
            />
          </React.Fragment>
        )}

        {!this.state.edit_serial && (
          <form onSubmit={this.handleSubmit}>
            <div className="container-create-edit-user">
              <div className="row">
                <div className="col-md-6">
                  <Select
                    color=" "
                    name="branch_id"
                    label="Sucursal"
                    labelClass="material"
                    className="material"
                    onChange={async e => {
                      await this.handleChange(e);
                      await this.clearDestiny();
                    }}
                    disabled={
                      this.state.form.products.length > 0 ? true : false
                    }
                    value={this.state.form.branch_id}
                    options={branches}
                    error={`${this.hasErrorFor("branch_id") ? "is-invalid" : ""
                      }`}
                    invalidfeedback={this.renderErrorFor("branch_id")}
                />
                </div>

                <div className="col-md-6">
                  <Select
                    color=" "
                    name="warehouse_id"
                    label="Almacen"
                    labelClass="material"
                    className="material"
                    onChange={async e => {
                      await this.handleChange(e);
                      await this.maybeLoadWarehousesDestination(
                        this.state.form.warehouse_id
                      );
                    }}
                    disabled={
                      this.state.form.products.length > 0 ? true : false
                    }
                    value={this.state.form.warehouse_id}
                    options={warehouses}
                    error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""
                      }`}
                    invalidfeedback={this.renderErrorFor("warehouse_id")}
                  />
                </div>

                <div className="col-md-6">
                  <Select
                    color=" "
                    name="type"
                    label="Tipo de Operación"
                    labelClass="material"
                    className="material"
                    onChange={this.handleChange}
                    disabled={
                      this.state.form.products.length > 0 ? true : false
                    }
                    value={this.state.form.type}
                    options={Constants.TYPE_ADJUSTMENT}
                    error={`${this.hasErrorFor("type") ? "is-invalid" : ""}`}
                    invalidfeedback={this.renderErrorFor("type")}
                  />
                </div>

                <div className="col-md-6">
                  <Input
                    color=" "
                    name="search"
                    placeholder="Buscar por nombre o código"
                    label="Buscar Productos"
                    labelClass="material"
                    className="material"
                    value={this.state.search}
                    onChange={this.handleChange}
                    error={`${this.hasErrorFor("products") ? "is-invalid" : ""}`}
                    invalidfeedback={this.renderErrorFor("products")}
                />
                  <div id="suggestion">
                    {suggestions.length > 0
                      ? suggestions.map((Item, key) => {
                        return (
                          <div
                            key={key}
                            onClick={() => {
                              this.takeProduct(Item);
                            }}
                          >
                            {Item.name}
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>

                {/* Ingreso */}
                {isPurchase && (
                  <>
                    <div className="col-md-6">
                      <Select
                        color=" "
                        name="provider_id"
                        label="Proveedor"
                        labelClass="material"
                        className="material"
                        onChange={this.handleChange}
                        value={this.state.form.provider_id}
                        options={providers}
                        error={`${this.hasErrorFor("provider_id") ? "is-invalid" : ""}`}
                        invalidfeedback={this.renderErrorFor("provider_id")}
                        icon={
                          <Button
                            color="primary"
                            type="button"
                            title="Agregar otro"
                            small="true"
                            onClick={() => {
                              this.props.createProvider();
                            }}
                          >
                            <Icon name="plus" />
                          </Button>
                        }
                      />
                    </div>

                    <div className="col-md-6" style={{ display: 'flex' }}>
                      <Select
                        color=" "
                        name="provider_doc_type"
                        label="RIF"
                        placeholder=" "
                        labelClass="material"
                        className="material"
                        options={Constants.TYPE_DOCUMENTS}
                        disabled
                        value={this.state.form.provider_doc_type}
                      />
                      <div style={{ flex: 1, paddingTop: '8px' }}>
                        <Input
                          color=" "
                          name="provider_doc"
                          label=" "
                          labelClass="material"
                          className="material"
                          disabled
                          value={this.state.form.provider_doc}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        color=" "
                        name="number_code"
                        label="Número de factura"
                        labelClass="material"
                        className="material"
                        onKeyPress={Globals.soloNumeros}
                        value={this.state.form.number_code}
                        onChange={this.handleChange}
                        error={`${this.hasErrorFor("number_code") ? "is-invalid" : ""}`}
                        invalidfeedback={this.renderErrorFor("number_code")}
                      />
                    </div>

                    <div className="col-md-6">
                      <DatePicker
                        color="white"
                        label="Fecha de factura"
                        labelClass="material"
                        className="material"
                        value={moment(this.state.form.date).toDate()}
                        onChange={value => {
                          this.setState(state => ({
                            form: {
                              ...state.form,
                              date: moment(value).format('YYYY-MM-DD')
                            }
                          }));
                        }}
                      />
                    </div>
                  </>
                )}

                {/* Traslado */}
                {isTransfer && (
                  <>
                    <div className="col-md-6">
                      <Select
                        color=" "
                        name="branch_destination_id"
                        label="Sucursal destino"
                        labelClass="material"
                        className="material"
                        onChange={this.handleChange}
                        value={this.state.form.branch_destination_id}
                        options={branches_destination}
                        error={`${this.hasErrorFor("branch_destination_id")
                          ? "is-invalid"
                          : ""
                          }`}
                        invalidfeedback={this.renderErrorFor(
                          "branch_destination_id"
                        )}
                      />
                    </div>
                    <div className="col-md-6">
                      <Select
                        color=" "
                        name="warehouse_destination_id"
                        label="Almacen destino"
                        labelClass="material"
                        className="material"
                        onChange={this.handleChange}
                        value={this.state.form.warehouse_destination_id}
                        options={warehouses_destination.map(Item => {
                          return {
                            value: Item.id,
                            label: Item.name
                          };
                        })}
                        error={`${this.hasErrorFor("warehouse_destination_id")
                          ? "is-invalid"
                          : ""
                          }`}
                        invalidfeedback={this.renderErrorFor(
                          "warehouse_destination_id"
                        )}
                      />
                    </div>
                  </>
                )}
              </div>

              {form.products.length > 0 && (
                <div className="row">
                  <div className="col-sm-12">
                    <div className="form-group">
                      <div className="table-responsive">
                        <table className="table table-sm table-bordered">
                          <thead>
                            <tr>
                              <td
                                colSpan={isSale ? '9' : (isTransfer ? '7' : '10')}
                                align="center"
                                className="table-title"
                              >
                                {this.getAdjustmentTypeTitle()}
                              </td>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                              <th>Codigo</th>
                              <th>Producto</th>
                              <th>Stock Actual</th>
                              <th>Stock Mínimo</th>
                              <th>Cantidad {this.getAdjustmentTypeAction()}</th>
                              {isPurchase && <th>Precio de Compra</th>}
                              {!isTransfer && <th>Precio de Venta</th>}
                              {!isTransfer && <th>Stock Final</th>}
                              {isPurchase && <th>Costo Total Compra</th>}
                              {isSale && <th>Costo Total Venta</th>}
                              <th>Acción</th>
                            </tr>
                          </thead>
                          <tbody className="tbody">
                            {form.products.map((Item, key) => {
                              return (
                                <tr key={key}>
                                  <td>{Item.code}</td>
                                  <td className="name1" title={this.getProductName(Item)}>
                                    {this.getProductName(Item)}
                                  </td>
                                  <td>{Item.last_movement}</td>
                                  <td>{Item.stock_min}</td>
                                  <th>
                                    <Input
                                      type="text"
                                      name="quantity"
                                      defaultname="quantity"
                                      color="gray"
                                      value={Item.quantity}
                                      disabled={
                                        Item.serialization ==
                                        Constants.SERIALIZATION.ACTIVE
                                      }
                                      maxLength={20}
                                      onKeyPress={e => {
                                        Globals.soloNumeros(e);
                                      }}
                                      onChange={async emitter => {
                                        await this.handleNum(emitter, key);
                                        await this.getTotalCost();
                                      }}
                                    />
                                  </th>
                                  {isPurchase && (
                                    <th>
                                      {isPurchase ? (
                                        <InputFormat
                                          color="gray"
                                          name="cost"
                                          thousandSeparator={true}
                                          allowNegative={false}
                                          isNumericString={true}
                                          decimalScale="2"
                                          maxLength={15}
                                          onValueChange={values =>
                                            this.handleValueChange(values, key)
                                          }
                                          value={Item.cost}
                                        />
                                      ) : isTransfer &&
                                        parseInt(form.branch_id) ===
                                        parseInt(
                                          form.branch_destination_id
                                        ) ? (
                                        <InputFormat
                                          color="gray"
                                          name="cost"
                                          thousandSeparator={true}
                                          allowNegative={false}
                                          isNumericString={true}
                                          decimalScale="2"
                                          maxLength={15}
                                          onValueChange={values =>
                                            this.handleValueChange(values, key)
                                          }
                                          value={Item.cost}
                                        />
                                      ) : (
                                        <NumberFormat
                                          value={Item.cost}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      )}
                                    </th>
                                  )}
                                  {!isTransfer && (
                                    <>
                                      <th>
                                        {isPurchase ? (
                                          <InputFormat
                                            color="gray"
                                            name="price"
                                            thousandSeparator={true}
                                            allowNegative={false}
                                            isNumericString={true}
                                            decimalScale="2"
                                            maxLength={15}
                                            onValueChange={values =>
                                              this.handleValueChangePrice(values, key)
                                            }
                                            value={Item.price}
                                          />
                                        ) : (
                                          <NumberFormat
                                            value={Item.price}
                                            displayType={"text"}
                                            thousandSeparator={true}
                                          />
                                        )}
                                      </th>
                                      <td>{Item.stock}</td>
                                      <td>
                                        <NumberFormat
                                          value={Item.cost_total}
                                          displayType={"text"}
                                          thousandSeparator={true}
                                        />
                                      </td>
                                    </>
                                  )}

                                  <td>
                                    <div className="col-md-12 .col-md-pull-3 p-0">
                                      <div className="container-btn-actions">
                                        <Button
                                          style={{}}
                                          color="red"
                                          small="true"
                                          title="Remover"
                                          type="button"
                                          onClick={() => {
                                            this.handleClick(Item);
                                          }}
                                        >
                                          <Icon name="minus" />
                                        </Button>

                                        {/*
                                              Boton de serialización, se muestra solo si la cantidad es mayor a 0 y se puede serializar el producto
                                            */}
                                        {Item.serialization ===
                                          Constants.SERIALIZATION.ACTIVE && (
                                            <Button
                                              color={
                                                !Item.serials ? "yellow" : "green"
                                              }
                                              small="true"
                                              title={
                                                !Item.serials
                                                  ? "Serializar"
                                                  : "Serializado"
                                              }
                                              type="button"
                                              className="btn-actions-orders"
                                              onClick={() => {
                                                this.handleSerial(Item);
                                              }}
                                            >
                                              <Icon name="list" />
                                            </Button>
                                          )}
                                        {/* {Item.serialization == Constants.SERIALIZATION.ACTIVE && (
                                            <Button
                                              color="primary"
                                              title="Ver Seriales"
                                              small="true"
                                              onClick={() => this.view(Item)}
                                            >
                                              <Icon name="eye" />
                                            </Button>
                                            )} */}
                                        {
                                          /*Boton de mostrar seriales disponibles, se muestra solo si se puede serializar el producto*/
                                        }
                                        {Item.serialization ==
                                          Constants.SERIALIZATION.ACTIVE && Item.stock > 0 && (
                                            <Button
                                              color={"blue"}
                                              small="true"
                                              title={"Ver seriales disponibles"}
                                              type="button"
                                              className="btn-actions-orders"
                                              onClick={() => {
                                                this.showSerials(Item);
                                              }}
                                            >
                                              <Icon name="eye" />
                                            </Button>
                                          )}

                                        {
                                          show_serials && serials_available &&
                                          <div className="row">
                                            <div className="col-md-12">
                                              <Modal
                                                className={"modal-serials-inputs"}
                                                title="Seriales disponibles"
                                                //onSuccess={this.props.onSuccess}
                                                onClose={this.onCloseShowSerials}
                                                visible
                                              >
                                                {serialsCompromised != '' &&
                                                  <span className="text-danger">
                                                    <strong> <small> {serialsCompromised} </small>  </strong>
                                                  </span>
                                                }
                                                <div className="modal-serials-inputs">
                                                  {serials_available.map((item, index) => (
                                                    <div className="col-md-12" key={index}>
                                                      <InputGroup
                                                        label="Hola"
                                                        color="gray"
                                                        type="text"
                                                        value={item.serial}
                                                        disabled={true}
                                                      />
                                                    </div>
                                                  ))}
                                                </div>

                                              </Modal>
                                            </div>
                                          </div>
                                        }

                                      </div>
                                      {parseInt(this.state.form.type) === 1 ? (
                                        <label className="mr-2">
                                          Actualizar Precio:
                                          <CheckBox
                                            style={{
                                              marginLeft: -3,
                                              marginTop: -3
                                            }}
                                            name="change_price"
                                            checked={Item.change_price}
                                            onChange={emitter => {
                                              this.handleNum(emitter, key);
                                            }}
                                          />
                                        </label>
                                      ) : parseInt(this.state.form.type) ===
                                        3 &&
                                        parseInt(this.state.form.branch_id) ===
                                        parseInt(
                                          this.state.form
                                            .branch_destination_id
                                        ) ? (
                                        <label>
                                          Actualizar Precio:
                                          <CheckBox
                                            name="change_price"
                                            checked={Item.change_price}
                                            onChange={emitter => {
                                              this.handleNum(emitter, key);
                                            }}
                                          />
                                        </label>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <th colSpan="4">Total de unidades modificadas: </th>
                              <th>
                                {this.totalQuantity(form)}
                              </th>
                              {!isTransfer && (
                                <>
                                  <th colSpan={isSale ? '2' : '3'}>
                                    Costo total del movimiento: 
                                  </th>
                                  <th>
                                    <NumberFormat
                                      value={this.state.form.final_cost}
                                      displayType={"text"}
                                      thousandSeparator={true}
                                    />
                                  </th>
                                </>
                              )}
                              <th></th>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row">
                <div className="col-md-12">
                  <Textarea
                    color=" "
                    value={this.state.form.description}
                    name="description"
                    label="Descripción de la Operación"
                    labelClass="material"
                    className="material"
                    onChange={this.handleChange}
                    error={`${this.hasErrorFor("description") ? "is-invalid" : ""
                      }`}
                    invalidfeedback={this.renderErrorFor("description")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md">
                  {submitted ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    <div id="button" style={{display: 'flex', justifyContent: 'center'}}>
                      <Button type="submit" color=" " className="primary" disabled={submitted}>
                        <span>{this.state.textButton}</span>
                      </Button>
                    </div>
                  )}
                </div>
                {/* <div className="col-md">
                    {!submitted && (
                      <div id="button">
                        <Button block="true" type="button" onClick={() => this.saveInventory()} disabled={submitted}>
                          Almacenar en memoria
                      </Button>
                      </div>
                    )}
                  </div> */}
              </div>
              {showSavedText && (
                <div className="row mt-3">
                  <div className="col-md">
                    <label>
                      Se han almacenado en caché los datos de la operación
                    </label>
                  </div>
                </div>
              )}
            </div>
          </form>
        )}
      </React.Fragment>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    inventory: state.inventory
  };
})(CreateEditInventoryAdjustment);
