import React from "react";

import { Button, Icon, ModalScan, Modal } from "../../components";
import { Globals, Constants } from "../../utils";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        products: []
      },
      edit_serial: false,
      item_serial: null
    };
  }
  

  onSuccess = async item => {
    let products = [...this.props.form.products];
    const index = products.findIndex(i => i.id == this.state.item_serial.id);
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      form: {
        ...this.state.form,
        products: products
      }
    });
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };
  close = () => {
    this.setState(
      {
        edit_serial: false,
      }
    );
  };
  render() {
    const { form } = this.props;
    const {
      edit_serial,
      item_serial
    } = this.state;
    return (
      <React.Fragment>
        {edit_serial ? (
          <React.Fragment>
            <Modal
              title="Serializar"
              onClose={this.close}
              visible
            >
              <ModalScan
                onSuccess={this.onSuccess}
                onCancel={this.onCancel}
                serials={item_serial.serials}
              />
            </Modal>
          </React.Fragment>
        ) :
          (< div className="row">
            <div className="col col-md">
              <div className="form-group">
                <div className="table-responsive table-products">
                  <table className="table table-bordered table-sm">
                    <thead>
                      <tr>
                        <td colSpan="7">PRODUCTOS</td>
                      </tr>
                      <tr style={{ whiteSpace: "nowrap" }}>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Descripción</th>
                        <th>Cantidad</th>
                        <th>Acción</th>
                      </tr>
                    </thead>
                    <tbody>
                      {form.products.map((Item, key) => {
                        return (
                          <tr key={key}>
                            <td>{Item.code}</td>
                            <td>{Item.name}</td>
                            <td>{Item.description}</td>
                            <td>

                              <input
                                type="text"
                                name="quanty"
                                className="form-control-sm"
                                value={Item.quanty}
                                maxLength="10"
                                disabled={
                                  Item.serialization ==
                                  Constants.SERIALIZATION.ACTIVE
                                }
                                onKeyPress={emitter => {
                                  Globals.soloNumeros(emitter);
                                }}
                                onChange={emitter => {
                                  this.props.onChange(emitter, key);
                                }}
                              />
                            </td>
                            <td>
                              {Item.serialization ==
                                Constants.SERIALIZATION.ACTIVE && (
                                  <Button
                                    color={
                                      !Item.serials
                                        ? "yellow"
                                        : "green"
                                    }
                                    small="true"
                                    title={
                                      !Item.serials
                                        ? "Serializar"
                                        : "Serializado"
                                    }
                                    type="button"
                                    className="btn-actions-orders"
                                    onClick={() => {
                                      this.handleSerial(Item);
                                    }}
                                  >
                                    <Icon name="list" />
                                  </Button>
                                )}
                              <Button
                                color="red"
                                small="true"
                                title="Remover"
                                type="button"
                                onClick={() => {
                                  this.props.onClick(Item);
                                }}
                              >
                                <Icon name="minus" />
                              </Button>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>)
        }
      </React.Fragment>
    );
  }
}

export default Products;
