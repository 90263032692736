import React from "react";
import { List } from "../../components";
import { Colors } from "../../utils";
// import { Avatar, Icon, List } from '../../components';
// import { ENV, Colors } from '../../utils';
// import User from '../../assets/img/user.jpg';

class ViewUser extends React.Component {
  state = {
    user: this.props.user
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nuevo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  render() {
    return (
      <div className="text-center container-view-user">
        <List.Container>
          <List.Item label="Nombre">{this.state.user.name}</List.Item>
          {/* <List.Item label="Código">{this.state.user.code}</List.Item> */}
          <List.Item label="Email">{this.state.user.email}</List.Item>
          <List.Item label="RIF">
            {this.state.user.document}
          </List.Item>
          <List.Item label="Teléfono">{this.state.user.phone}</List.Item>
          <List.Item label="Dirección">
            {this.state.user.address
              ? this.state.user.address
              : "La dirección no ha sido ingresada."}
          </List.Item>
          {/* <List.Item label="Personalidad Jurídica">
            {this.state.user.person_type
              ? this.state.user.person_type === 1
                ? "Natural"
                : "Juridica"
              : "Ninguna"}
          </List.Item> */}
          {/* <List.Item label="Tipo de Retencion">
            {this.state.user.user_retention_types
              ? this.state.user.user_retention_types.retention.percentage + "%"
              : "No posee tipo de retencion"}
          </List.Item> */}
          {/* <List.Item label="Status">
            <span
              style={{
                color: this.getStatus(this.state.user.status).color
              }}
            >
              {this.getStatus(this.state.user.status).text}
            </span>
          </List.Item> */}
        </List.Container>
      </div>
    );
  }
}

export default ViewUser;
