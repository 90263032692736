import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import moment from "moment";
import { Button, Input, Select, DatePicker, SelectActions, Modal } from "../../components";
import { Constants, Globals, axios } from "../../utils";

import { Branch, Warehouse } from "../../services";
import CreateEditZone from "../zones/create_edit_zone";

const SHOW_PRICES = {
	YES: 1,
	NO: 0
}

class paymentMethodAdmin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: "",
            form: {
                methods: [],
            },
            methods: [],
            textButton: "Guardar",
			user: this.props.user,
        };

        this.handleChange = this.handleChange.bind(this);
    }

    abortController = new AbortController();

    componentDidMount() {
        this.load();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    load = () => {
        const { user } = this.props;

        axios
            .post("admin/payment-methods/admin", { user_id: this.props.user.id, all: true })
            .then(({ data }) => {
				console.log(data)
                this.setState({ methods: data.all });
				this.setState({
					form: {
						...this.state.form,
						methods: data.methods.map(x => x.id) || [],
					},
					methods: data.all
				});
            })
            .catch(() => Globals.showError())
            .then(() => Globals.quitLoading());
    };

    handleSubmit = emitter => {
        emitter.preventDefault();

        let param = { ...this.state.form };
        param.user_id = this.props.user.id;
        param.web = true;
        if(param.methods.length == 0){
            return Globals.showError('Debes seleccionar al menos un método de pago');
        }

        Globals.setLoading();
        axios
            .post(
                "admin/payment-methods/admin/edit",
                param
            )
            .then(res => {
                let msg = 'Métodos de pago registrados exitosamente'
                Globals.showSuccess(msg);
            })
            .catch(err => {
                if (err.response.status === 422) {
                    Globals.showError(err.response.data.error);
                    return;
                }
                Globals.showError();
            })
            .then(() => {
                Globals.quitLoading();
            });
    };

    handleChange = emitter => {
        const { name, value } = emitter.target;

        this.setState({
            form: {
                ...this.state.form,
                [name]: value
            }
        });
    };

    toggleMethods= (id) => {
        const { form } = this.state;
        const exists = form.methods.includes(id);
        if (exists) {
            const others = form.methods.filter(x => x !== id);
            this.setState({ form: { ...form, methods: others } });
        } else {
            const methods = [ ...form.methods, id ];
            this.setState({ form: { ...form, methods } });
        }
    };

    hasErrorFor(field) {
        return !!this.state.errors[field];
    }

    renderErrorFor(field) {
        if (this.hasErrorFor(field)) {
            return (
                <span className="invalid-feedback my-2 text-left">
                    <strong>{this.state.errors[field][0]}</strong>
                </span>
            );
        }
    };

    createZone = () => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
        this.setState({ modalZones: true, editZone: null });
    };

    editZone = (item, index) => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
        this.setState({
            modalZones: true,
            editZone: {
                element: item,
                index: index,
            }
        })
    };

    deleteZone = (item) => {
        Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
            Globals.setLoading("Eliminando...");
            axios.post('web/admin/zones/delete', { zone_id: item.id })
                .then(res => {
                    Globals.showSuccess('zona eliminada con exito');
                    this.getZones();
                })
                .catch(err => {
                    Globals.showError(err.response.data.msg);
                })
                .then(() => {
                    Globals.quitLoading();
                });
        });
    };

    close = async (reloading = false) => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
        await this.setState({ modalZones: false, editZone: null });
        if (reloading) await this.getZones();
    }

    onChange = async (value, target) => {
		await this.setState(state => ({
			form: {
				...state.form,
				[target]: !!value?.target ? value?.target?.value : value,
			}
		}));
	}

    render() {
		const { history } = this.props;
        const { submitted, permissions, methods } = this.state;
        const textNameField = this.props.user.role === 1 ? "Nombre/Razón Social" : "Nombre y apellido";

        return (
			<Menu history={history}>
                <div className='container' style={{ backgroundColor: 'white' }}>
                    <form onSubmit={this.handleSubmit}>
                        <div className="container-create-edit-user">

                            <div><b>Métodos de Pago</b></div>
                            <ul style={{ paddingLeft: 0 }}>
                                {methods.map(x => (
                                    <MethodItem
                                        label={x.name}
                                        isSelected={this.state.form.methods.includes(x.id)}
                                        onPress={() => this.toggleMethods(x.id)}
                                    />
                                ))}
                            </ul>

                            {submitted ? (
                                <div className="spinner-border text-primary" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                            ) : (
                                <div id="button" className="d-flex justify-content-center">
                                    <Button color=" " type="submit" className="primary" disabled={submitted}>
                                        <span>{this.state.textButton}</span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </form>
                </div>
			</Menu>
        );
    }
}

const MethodItem = ({ label, isSelected, onPress }) => (
    <li onClick={() => onPress()} className={`permission-item ${isSelected ? 'selected' : ''}`}>
        { label }
        <div className="checkbox" />
    </li>
);

paymentMethodAdmin.defaultState = {
    form: {
        id: "",
        name: "",
        phone: "",
        email: "",
        document_type: "V",
        document: "",
        level: "",
        role: "",
        email: "",
        password: "",
        password_confirmation: "",
        image: "",
        commission: "",
        goal_amount: "",
        goal_date: "",
    }
};

export default connect(state => {
    return {
        user: state.user
    };
})(paymentMethodAdmin);
