import React from "react";
import { connect } from "react-redux";
import { Button, Select, Input, InputFormat } from "../../components";
import { axios, Globals } from "../../utils";

class CreateEditProductProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        provider_id: "",
        price: "",
        product_id: "",
        creator_id: ""
      },
      users: [],
      textButton: "Asignar"
    };
  }

  componentDidMount() {
    this.setState((state, props) => {
      state.users = props.providers.filter(el => {
        if (!el.product_provider) {
          return el;
        }
        return;
      });
      state.form.product_id = props.product_id;
      state.form.creator_id = props.user.id;
      return state;
    });

    if (this.props.edit) {
      this.edit();
    }
  }

  submit = async () => {
    let param = { ...this.state.form };
    Globals.setLoading();
    axios
      .upload(
        this.props.edit
          ? "admin/products/providers/change"
          : "admin/products/providers/assign",
        param
      )
      .then(res => {
        if (res.data.result) {
          this.setState({
            form: {}
          });
          Globals.showSuccess(res.data.msg);
          this.props.onClose();
        }
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.msg);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    var prov = this.props.providers.filter(el => {
      if (
        el.product_provider === null ||
        el.id === this.props.edit.element.provider_id
      ) {
        return el;
      }
    });
    await this.setState({
      users: prov,
      form: {
        ...this.state.form,
        price: this.props.edit.element.price,
        provider_id: this.props.edit.element.provider_id,
        product_id: this.props.product_id,
        id: this.props.edit.element.id
      },
      textButton: "Cambiar"
    });
  };

  handleValueChange = (values, name) => {
    const { floatValue } = values;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: floatValue
      }
    }));
  };

  change = e => {
    let user_id = "";
    if (this.props.user.role === 1 || this.props.user.role === 2) {
      user_id = this.props.user.id;
    } else {
      user_id =
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id;
    }
    this.setState({
      form: {
        provider_id: user_id,
        creator_id: this.props.user.id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <div className="container-create-edit-user">
        <Select
          color="white"
          name="provider_id"
          label="Proveedores"
          labelClass="material"
          className="material"
          defaultname="Seleccione"
          disabledfirst={false}
          onChange={this.change}
          value={this.state.form.provider_id}
          options={this.state.users.map(i => {
            return {
              value: i.id,
              label: i.name
            };
          })}
        />
        <InputFormat
          color=" "
          name="price"
          thousandSeparator={true}
          allowNegative={false}
          isNumericString={true}
          decimalScale="2"
          label="Costo del Proveedor"
          labelClass="material"
          customClass="material"
          maxLength={10}
          onValueChange={values => this.handleValueChange(values, "price")}
          value={this.state.form.price}
        />
        <div id="button" className="d-flex justify-content-center">
          <Button className="primary" color=" " type="button" onClick={() => this.submit()}>
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditProductProvider);
