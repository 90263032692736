import React from 'react'
import Select from 'react-select';

const customStyles = {
  option: (provided, state) => ({
  	...provided,
    fontSize: 12
  })  
}

const SelectSearch = (props) => (
  <div className={ `form-group container-select-search ${ props.searchRight && 'search-right' }` }>
	{ props.label && <label>{ props.label }</label> }
	<Select 
		{ ...props }
		noOptionsMessage={ () => "No hay resultados" }
		styles={ customStyles }
		className={ `form-control ${ props.color ? props.color : '' }` }
		placeholder={ props.placeholder || '' }
		options={ props.options } />
  </div>
)

export default SelectSearch;