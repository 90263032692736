import axios from "../../utils/axios";

class Module {
  static async getModulesEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/enterprise", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getModulesSeller(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/seller", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getModules() {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/get", {}).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        error => {
          // http failed
          reject(error);
        }
      );
    });
  }

  static async updateEnterpriseModules(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/modules/edit", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Module;
