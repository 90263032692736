import React from "react";
import { connect } from "react-redux";
import { Button, Icon, Modal, Pagination, Table } from "../../../components";
import { Globals, Constants } from "../../../utils";
import { Bank } from "../../../services";

// Components
import Menu from "../../menu";
import Filter from "./filterForm";
import CreateEditBankAccount from "./create_edit_bank_account";

const TABLE_HEADER = [
  "Banco",
  "Nº de Cuenta",
  "Tipo",
  "Estado"
];

class BankAccounts extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        create: false,
        data: [],
        page: 1,
        current_page: 1,
        last_page: 1,
        bank_account: null,
        form: {
                bank_id: "",
                status: "",
                search: ""
            }
        };
    }

    componentDidMount() {
        this.setBank(this.props.match.params.bank_id);
    }

    componentDidUpdate(nextProps) {
        if (nextProps.location.pathname !== this.props.location.pathname) {
            let param = this.props.location.pathname.split("/");
            param = param[param.length - 1];
            this.setBank(param);
        }
    }

    setBank = async id => {
        await this.setState( state => ({
            form: {
                ...state.form,
                bank_id: id
            }
            }));
        this.load();
    }

    load = () => {
        const { page, form } = this.state;
        const bankId = form.bank_id;

        Bank.getBankAccountsPaginated(page, { bank_id: bankId })
        .then(response => {
            const { current_page, data, last_page } = response;
            this.setState({
            data: data,
            current_page: current_page,
            last_page: last_page
            });
        })
        .catch(() => Globals.showError());

    };

    loadMore = () => {
        const { page, form } = this.state;
        const bankId = form.bank_id;

        Bank.getBankAccountsPaginated(page, { bank_id: bankId })
            .then(response => {
            const { current_page, data, last_page } = response;

            this.setState({
                data: data,
                current_page: current_page,
                last_page: last_page
            });
            })
            .catch(() => Globals.showError());
    };

    filterMap = form => {
        const { page } = this.state;

        Bank.getBankAccountsPaginated(page, { ...form })
            .then(response => {
            const { current_page, data, last_page } = response;
            this.setState({
                page: 1,
                data: data,
                current_page: current_page,
                last_page: last_page,
                form: {
                ...form
                }
            });
            })
            .catch(() => Globals.showError());
    };

    active = bank => {
        const {id, account_number, status} = bank;
        const message = status === 2 ?  `¿Desea activar la cuenta Nº ${account_number}?` : `¿Desea suspender la cuenta Nº ${account_number}?`;
        Globals.confirm(message, () => {
            Globals.setLoading("Procesando...");
            Bank.activateBankAccount(id)
            .then(response => {
                const { account_number, status:bankStatus } = response;
                const statusText = bankStatus === 2 ? 'Suspendido' : 'Activado';
                Globals.showSuccess(`Cuenta Nº ${account_number} ${statusText} con exito!`);
                this.loadMore();
                })
            .catch(err => {
            if (err.msg) Globals.showError(err.msg);
            })
            .finally(() =>{
            Globals.quitLoading();
            })
        })
    }

    delete = bank => {
        const {id, account_number} = bank;
        const message =`¿Desea eliminar la cuenta Nº ${account_number}?`;
        Globals.confirm(message, () => {
            Globals.setLoading("Procesando...");
            Bank.deleteBankAccount(id)
            .then(response => {
                Globals.showSuccess(`Cuenta Nº ${account_number} eliminada con exito!`);
                this.loadMore();
                })
            .catch(err => {
            if (err.msg) Globals.showError(err.msg);
            })
            .finally(() =>{
            Globals.quitLoading();
            })
        })
    }

    goBack = () => {
        this.props.history.goBack();
    };

    close = () => {
        this.setState(
            {
                create: false,
                bank: null
            },
            () => this.loadMore()
        );
    };

    getAccountType = (type) => {
        return Constants.TYPE_BANK_ACCOUNTS.find(account => account.value === type)
    }

    render() {
        const { bank, create, data, form } = this.state;
        const { user } = this.props;
        const bankId = form.bank_id;

        return (
            <Menu history={this.props.history}>
            {create && (
                <Modal
                title={bank ? "Editar Cuenta Bancaria" : "Nueva Cuenta Bancaria"}
                onClose={this.close}
                visible
                >
                <CreateEditBankAccount
                    bankId={bankId}
                    user={user}
                    bank={bank}
                    onClose={this.close}
                />
                </Modal>
            )}
            <div id="home">
                <div className="container-fluid">
                <Filter
                    submitted={form => this.filterMap(form)}
                    user={user}
                    bankId={bankId}
                    back={this.goBack}
                />

                <div className="row">
                    <div className="col-md">
                    <Table
                        data={this.state.data.length}
                        title="Cuentas Bancarias"
                        header={TABLE_HEADER}
                        right={
                        <Button
                            title="Agregar Cuentas"
                            outline="true"
                            small="true"
                            onClick={() => {
                            this.setState({
                                create: true
                            });
                            }}
                        >
                            <Icon name="plus" />
                        </Button>
                        }
                    >
                        {data.map((Item, key) => {
                        return (
                            <tr key={key}>
                            <td>
                                {Item.bank.name}
                            </td>
                            <td>
                                {Item.account_number}
                            </td>
                            <td>
                                {this.getAccountType(Item.type).label}
                            </td>
                            <td style={{ color: Globals.getStatus(Item.status).color }}>
                                {Globals.getStatus(Item.status).text}
                            </td>
                            <td>
                                <Button
                                title="Editar"
                                color="primary"
                                small="true"
                                onClick={() =>
                                    this.setState({
                                    create: true,
                                    bank: Item
                                    })
                                }
                                >
                                <Icon name="edit" />
                                </Button>
                                {Item.status === 2 && (
                                <Button
                                    color="info"
                                    title="Activar"
                                    small="true"
                                    onClick={() => {
                                    this.active(Item);
                                    }}
                                >
                                    <Icon name="check" />
                                </Button>
                                )}
                                {Item.status === 1 && (
                                <Button
                                    color="info"
                                    title="Suspender"
                                    small="true"
                                    onClick={() => this.active(Item)}
                                >
                                    <Icon name="close" />
                                </Button>
                                )}
                                <Button
                                color="red"
                                title="Eliminar"
                                small="true"
                                onClick={() => this.delete(Item)}
                                >
                                <Icon name="trash" />
                                </Button>
                            </td>
                            </tr>
                        );
                        })}
                    </Table>
                    </div>
                </div>

                <div className="row my-3">
                    <div className="col-md">
                    <Pagination
                        pages={this.state.last_page}
                        active={this.state.page}
                        onChange={page => {
                        this.setState(
                            {
                            page: page
                            },
                            () => this.loadMore()
                        );
                        }}
                    />
                    </div>
                </div>
                </div>
            </div>
            </Menu>
        );
    }
}
    
export default connect(state => {
    return {
    user: state.user
    };
})(BankAccounts);