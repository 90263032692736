import moment from 'moment';
import React, { Component } from 'react'
import { Globals, axios } from '../../../utils';
import { connect } from 'react-redux';

class ModalCreateReturn extends Component {

  state = {
    isReadOnly: false,
    returnedItem: this.props.returnedItem,
    form: {
      bill_number: '',
      comments: '',
    },
  }

  componentDidMount() {
    if (!!this.props?.isReadOnly) this.edit();
  }

  edit = () => {
    this.setState({
      isReadOnly: true,
      form: {
        bill_number: this.props.returnedItem?.order_bill?.bill_number,
        comments: this.props.returnedItem?.comments,
      }
    });
  }

  submit = async () => {
    if (!this.state.form.bill_number)
      return Globals.showError('Debe indicar el número de la factura');

    Globals.setLoading();

    const data = {
      ...this.state.form,
      user_id: this.props.user.id,
      order_id: this.state.returnedItem?.id,
    };

    try {
      const res = await axios.post('admin/orders/fiscal-bill/creditnote', data);
      if (!res.data.result) return;
      Globals.showSuccess('Nota de crédito creada con éxito');
      this.props.onSuccess();

    } catch (error) {
      Globals.showError(error?.response?.data?.error ?? 'Ocurrió un error inesperado');
    }

    Globals.quitLoading();
  }

  change = async (value, target) => {
    await this.setState(s => ({ form: { ...s.form, [target]: value } }));
  }

  convert = (value) => {
    return value * (this.state.returnedItem?.conversion || 1);
  }

  render() {

    const { form, isReadOnly, returnedItem } = this.state;

    return (
      <>
        <div className="modal-top">
          <div>
            <p>Cliente: { returnedItem?.client?.name }</p>
            <p>Cédula: { returnedItem?.client?.document }</p>
            <p className="conversion">Tasa de cambio: Bs. { Globals.formatMiles(returnedItem?.conversion || 0) }</p>
          </div>
          <div>
            <p className="date">Fecha de emisión: { moment(returnedItem?.created_at).format('DD/MM/YYYY') }</p>
            <p>Forma de pago: { returnedItem?.payment_methods_text }</p>
          </div>
        </div>

        <table border={1}>
          <thead>
            <tr>
              <td>Código</td>
              <td>Descripción</td>
              <td>Cantidad</td>
              <td>Precio</td>
              <td>Desc %</td>
              <td>Neto</td>
            </tr>
          </thead>
          <tbody>
            {returnedItem?.details?.map((item, index) => (
              <tr key={index}>
                <td>{ item.product?.code }</td>
                <td>{ item.product?.name }</td>
                <td>{ item.quantity }</td>
                <td>Bs. { Globals.formatMiles(this.convert(item.price)) }</td>
                <td>{ item.discount_percentage }</td>
                <td>Bs. { Globals.formatMiles(this.convert(item.total)) }</td>
              </tr>
            ))}
          </tbody>
        </table>

        <div className="modal-bottom">
          <div className="form">
            <div className="bill-number">
              <label>Número de Factura</label>
              <input 
                value={form.bill_number}
                onChange={v => this.change(v.target.value,'bill_number')}
              />
            </div>
            <label>Motivo de nota de crédito (Opcional)</label>
            <textarea
              className="reason"
              value={form.comments}
              onChange={v => this.change(v.target.value,'comments')}
            />
          </div>

          <div className="totals">
            <div>
              <span>Sub-total</span>
              <span>Bs. { Globals.formatMiles(this.convert(returnedItem?.subtotal || 0)) }</span>
            </div>
            <div>
              <span>% Desc</span>
              <span>
                <span>({ Globals.formatMiles(returnedItem?.discount_percentage ) }%) </span>
                <span>-Bs. { Globals.formatMiles(this.convert(returnedItem?.discount || 0)) }</span>
              </span>
            </div>
            <div>
              <span>IVA</span>
              <span>Bs. { Globals.formatMiles(returnedItem?.iva_bs || 0) }</span>
            </div>
            {returnedItem?.igtf_bs > 0 && (
              <div>
                <span>IGTF</span>
                <span>Bs. { Globals.formatMiles(returnedItem?.igtf_bs || 0) }</span>
              </div>
            )}
            <div>
              <span>Total</span>
              <span>Bs. { Globals.formatMiles(returnedItem?.total_bs || 0) }</span>
            </div>
          </div>
        </div>

        {!isReadOnly && (
          <div className="modal-actions">
            <button onClick={() => this.props.onClose()}>
              Cancelar
            </button>
            <button onClick={this.submit}>
              Procesar
            </button>
          </div>
        )}
      </>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalCreateReturn);