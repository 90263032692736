import React from 'react';
import { on } from 'jetemit';
import { Constants, Globals } from '../utils';
import { Input, Button, Icon, InputGroup, Modal } from '../components';

class ModalScan extends React.Component {

	constructor(props) {
		super(props);

		let serials = [];

		if (this.props.serials) {
			serials = this.props.serials;
		}

		const unsubscriber = on(Constants.SCANNER, this.add);

		this.state = {
			serials: serials,
			manualSerial: '',
			unsubscriber: unsubscriber,
			showSerials: false,
		}
	}

	componentWillUnmount() {
		if (this.state.unsubscriber) {
			this.state.unsubscriber();
		}
	}

	add = code => {
		let serials = [...this.state.serials];
		if (!code || code == '') {
			Globals.showError("Lo sentimos, el serial no puede estar vacio");
		}
		else if (serials.indexOf(code) == -1) {
			serials.push(code);
			this.setState({
				serials: serials,
				manualSerial: ''
			});
		}
		else {
			Globals.showError("Lo sentimos, el serial ya se encuentra agregado");
		}
	}

	onCloseShowSerials = () => {
		this.setState({
			showSerials: false
		});
	}

	render() {
		const { serials, manualSerial, showSerials } = this.state;

		return (
			<div className="modal-scan">
				<div className="row">
					<div className="col-md-6">
						{this.props._quantity && <p className="quantity-order"><span>Cantidad de la Orden:</span> {this.props._quantity}</p>}
						<p className="quantity"><span>Cantidad de Seriales:</span> {serials.length}</p>
						<p className="note-serialize">Use el lector de códigos de barra o ingrese manualmente</p>
					</div>
					<div className="col-md-6">
						<form className="container-serial-manual" onSubmit={event => {
							event.preventDefault();
							this.add(manualSerial);
						}}>
							<div style={{
								flex: .7
							}}>
								<Input
									color="gray"
									type="text"
									value={manualSerial}
									maxlength={25}
									onChange={emitter => {
										const { value } = emitter.target;
										this.setState({
											manualSerial: value
										});
									}}
								/>
							</div>
							<div style={{
								flex: .3
							}}>
								<Button className="btn-green">
									Agregar
			                    </Button>
							</div>
						</form>
					</div>
				</div>
				<div className="row">
					<div className="col-md-6">
						{
							// serials.map((item,index) => (
							// 	<div className="col-md-4" key={ index }>
							// 		<InputGroup
							// 			label="Hola"
							// 			color="gray"
							//             type="text"
							//             value={ item }
							//             disabled={ true }
							//             itemRight={
							// 				<Button onClick={ () => {
							// 					this.setState({
							// 						serials: serials.filter(i => i != item)
							// 					});
							// 				} }>
							// 					<Icon name="remove" />
							// 				</Button>
							//             }
							//         />
							// 	</div>
							// ))
							serials.length > 0 &&
							<Button
								onClick={() => {
									this.setState({
										showSerials: true
									});
								}}
							>
								Ver seriales <Icon name="eye" />
							</Button>
						}
					</div>

					{
						showSerials &&
						<div className="row">
							<div className="col-md-12">
								<Modal
									className={"modal-serials-inputs"}
									title="Seriales registrados"
									onSuccess={this.props.onSuccess}
									onClose={this.onCloseShowSerials}
									visible
								>
									<div className="modal-serials-inputs">
										{serials.map((item, index) => (
											<div className="col-md-12" key={index}>
												<InputGroup
													label="Hola"
													color="gray"
													type="text"
													value={item}
													disabled={true}
													itemRight={
														<Button onClick={() => {
															this.setState({
																serials: serials.filter(i => i != item)
															});
														}}>
															<Icon name="remove" />
														</Button>
													}
												/>
											</div>
										))}
									</div>

								</Modal>
							</div>
						</div>
					}
				</div>
				<div className="row-flex flex-right">
					<div className="row-flex row-flex-buttons">
						<div className="col-flex text-center">
							<Button className="btn-red" onClick={this.props.onCancel}>
								Cancelar
		                    </Button>
						</div>
						<div className="col-flex text-center">
							<Button
								disabled={serials.length == 0}
								className="btn-green"
								onClick={() => this.props.onSuccess(serials)}>
								Guardar
		                    </Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

export default ModalScan;