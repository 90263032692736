import React from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, SelectActions, Select } from "../../components";
import { Constants, Globals, axios } from "../../utils";
import ENV from "../../utils/env";

class CreateEditWarehouse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: "",
      form: {
        id: "",
        warehouse_id: "",
        name: "",
        user_id: this.props.user.id,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        products: [],
        search: "",
        type: "",
      },
      errors: [],
      edit: false,
      textButton: "Crear",
      products: [],
      selectedProduct: null,
      types: [
        {
          value: Constants.WAREHOUSES.TYPE.FIJO,
          label: 'Fijo'
        },
        {
          value: Constants.WAREHOUSES.TYPE.MOVIL,
          label: 'Móvil'
        },     
      ],
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount() {
    const { edit } = this.props;
    if (edit) this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  isValidForm = () => {
    const { form } = this.state;
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    if (!form.name)
      return onError('Debe ingresar el nombre');

    // if (!!form.products.length && form.products.some(item => !item.quantity))
    //   return onError('Debe ingresar la cantidad del producto');

    return true;
  }

  handleSubmit = (emitter) => {
    emitter.preventDefault();
    if (!this.isValidForm()) return;

    let param = { ...this.state.form };
    param.products = param.products.map(item => ({ ...item, quantity: parseInt(item.quantity) }));
    param.user_id = this.props.user.id;

    Globals.setLoading();
    axios
      .post(
        this.props.edit ? "web/admin/warehouses/edit" : "web/admin/warehouses/create",
        param
      )
      .then(res => {
        Globals.showSuccess(!!this.props.edit ? "Almacen editado" : "Almacen creado");
        this.props.onClose();
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err?.response?.data?.error);
          return;
        }
        Globals.showError(err?.response?.data?.error);
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    const { element } = this.props.edit;

    // const products = element.warehouse_products.map(x => {
    //   const product = element.products.find(item => item.product.id == x.product_id)?.product;
    //   return {
    //     id: product?.id,
    //     name: product?.name,
    //     code: product?.code,
    //     description: product?.description,
    //     quantity: x.quantity,
    //     type: product?.type,
    //     size_colors: product?.size_colors || [],
    //     size_color_id: x.size_color_id,
    //   };
    // }).map(item => ({
    //   ...item,
    //   name: this.getProductName(item),
    // }));

    const products = element.warehouse_products.map(x => {
      const product = element.products.find(item => item.id == x.product_id);
      return {
        id: product?.id,
        name: product?.name,
        code: product?.code,
        description: product?.description,
        quantity: x.quantity,
        type: product?.type,
        size_colors: product?.size_colors || [],
        size_color_id: x.size_color_id,
      };
    }).filter(i => i.id != null).map(item => ({
      ...item,
      name: this.getProductName(item),
    }));

    await this.setState({
      form: {
        ...element,
        warehouse_id: element.id,
        products,
      },
      edit: true,
      textButton: "Editar"
    });
  };

  handleChange = (emitter, index) => {
    const { name, value } = emitter.target;

    if (index !== undefined) {
      const form = Globals.copy(this.state.form);
      form.products[index][name] = value;
      this.setState({ form });

    } else {
      this.setState({
        form: {
          ...this.state.form,
          [name]: value
        }
      });
    }
  };

  handleDelete = (index) => {
    const form = Globals.copy(this.state.form);
    const products = form.products.filter((_, i) => i !== index);
    this.setState({ form: { ...form, products } });
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  };

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  };

	getProductName = (product) => {
    if (product.type === Constants.PRODUCTS_TYPE.NORMAL) return product.name;

    const size_color = product.size_colors.find(sc => sc.id === product.size_color_id);
    let altName = product.name;
    if (!!size_color?.color) altName += `, ${ size_color.color.name }`;
    if (!!size_color?.size) altName += `, ${ size_color.size.name }`;
    return altName;
	};

  onSelectProduct = (productId) => {
    const exists = this.state.form.products.find(item => item.id === productId);
    if (exists) return;

    const product = this.state.products.find(item => item.id === productId);
    this.setState((state) => ({
      form: {
        ...state.form,
        products: [
          ...state.form.products,
          {
            id: product.id,
            name: product.name,
            quantity: '',
          },
        ],
      },
      selectedProduct: productId
    }));
  };

  getFilteredProducts = () => {
    const { products, search } = this.state.form;
    if (!search) return products;

    return products.filter(item => {
      return [item.name, item.code, item.description]
        .filter(x => !!x)
        .some(x => String(x).toLowerCase().includes(String(search).toLowerCase()));
    });
  }

  render() {
    const { form, submitted } = this.state;
    const isMainWarehouse = form?.id == this.props.user.warehouse_principal;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container-create-edit-user">
          <div className="row">

            <div className="col-md-12">
              {/* OCULTO PARA PROD */}
              {/* {ENV.mode !== 3 ? (
                <Select
                  color=" "
                  label="Tipo"
                  name="type"
                  labelClass="material"
                  className="material"
                  options={this.state.types}
                  value={this.state.form.type}
                  error={`${this.hasErrorFor("type") ? "is-invalid" : ""}`}
                  invalidfeedback={this.renderErrorFor("type")}
                  onChange={this.handleChange}
                />
              ) : null} */}
              <Select
                color=" "
                label="Tipo"
                name="type"
                labelClass="material"
                className="material"
                options={this.state.types}
                value={this.state.form.type}
                error={`${this.hasErrorFor("type") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("type")}
                onChange={this.handleChange}
              />
            </div>


            <div className="col-md-12">
              <Input
                type="text"
                color=" "
                value={this.state.form.name}
                name="name"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                label="Nombre"
                className="material"
                labelClass="material"
                onChange={this.handleChange}
              />
            </div>

            {!!this.props.edit && (
              <div className="col-md-12">
                <Input
                  color=" "
                  name="search"
                  label="Nombre o Código del Producto"
                  className="material"
                  labelClass="material"
                  value={form.search}
                  onChange={this.handleChange}
                />
              </div>
            )}
          </div>

          <div className="my-2">
            {!!this.props.edit && this.getFilteredProducts().map((product, index) => (
              <div key={`product-${index}`} className="row" style={{ 'alignItems': 'center' }}>
                <p className="col-8">
                  <div>{ product.name }</div>
                  <small>Cantidad: { product.quantity }</small>
                </p>
              </div>
            ))}
          </div>

          {submitted && (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          )}

          {(!submitted && !isMainWarehouse) && (
            <div id="button" className="d-flex justify-content-center">
              <Button color=" " type="submit" className="primary" disabled={submitted}>
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

CreateEditWarehouse.defaultState = {
  form: {
    id: "",
    warehouse_id: "",
    name: "",
    products: [],
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditWarehouse);
