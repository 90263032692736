import { combineReducers } from "redux";
import user from "./user";
import support from "./support";
import sidebar from "./sidebar";
import admin from "./admin";
// import token from "./token";
import sales from './sales';
import delivery from './delivery';
import request from './request';
import serials_request from './serials_request';
import inventory from './inventory';
import currency from './currency';
import printer from './printer';

export default combineReducers({
  user: user,
  sidebar: sidebar,
  admin: admin,
  support: support,
  // token: token,
  sales,
  delivery,
  request,
  serials_request,
  inventory,
  currency,
  printer,
});
