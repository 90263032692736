import axios from "../../utils/axios";

class Bank {
  static async getBanksPaginated(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/banks/index?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getBanks(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/banks/get`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });

  }
  static async showBank(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/banks/show`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getBankAccountsPaginated(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/bank-accounts/index?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getBankAccounts(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/bank-accounts/get`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getHasAccounts(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/bank-accounts/has-accounts`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async newBank(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/banks`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async newBankAccount(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/bank-accounts`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async updateBank(id, form) {
    return new Promise((resolve, reject) => {
      axios.put(`admin/banks/${id}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async updateBankAccount(id, form) {
    return new Promise((resolve, reject) => {
      axios.put(`admin/bank-accounts/${id}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async activateBank(id) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/banks/activate/${id}`).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async activateBankAccount(id) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/bank-accounts/activate/${id}`).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async deleteBank(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`admin/banks/${id}`).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async deleteBankAccount(id) {
    return new Promise((resolve, reject) => {
      axios.delete(`admin/bank-accounts/${id}`).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Bank;