import axios from "../../utils/axios";

class currency {
  static async getCurrencies() {
    return new Promise((resolve, reject) => {
      axios.post("admin/currencies/get", {}).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCurrenciesFromPurchase() {
    return new Promise((resolve, reject) => {
      axios.post("admin/currencies/purchase", {}).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default currency;
