import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  DatePicker,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import PrinterIcon from '../../assets/icons/printer.png';

class ReportCommisions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      header: [
        'Fecha',
        'ID',
        'Total\nVenta',
        'Vendedor',
        'Gerente',
        'Total\nAbonado',
        'Comisión pagada\nVendedor',
        'Por pagar\nVendedor',
        'Comisión pagada\nGerente',
        'Por pagar\nGerente',
      ],
      page: 1,
      last_page: 1,
      data: [],
      sellers: [],
      managers: [],
      form: {
        search: "",
        since: "",
        until: "",
        seller_id: "",
        manager_id: "",
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      monto_total: 0,
      //total_comisiones: 0,
      //total_cobrar: 0,
      total_comision_paid_seller: 0,
      total_comision_toPay_seller: 0,
      total_comision_paid_manager: 0,
      total_comision_toPay_manager: 0,
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (page = 1) => {
    axios
      .post('admin/reports/orders', {
        web: true,
        user_id: this.props.user.id,
        until: this.state.form.until && moment(this.state.form.until,'DD-MM-YYYY').format('YYYY-MM-DD'),
        since: this.state.form.since && moment(this.state.form.since,'DD-MM-YYYY').format('YYYY-MM-DD'),
        currency: this.props.currency,
        page,
        status: [
          Constants.ORDER_STATUS.PENDING,
          Constants.ORDER_STATUS.PROCESSED,
        ],
        seller_id: this.state.form.seller_id,
        manager_id: this.state.form.manager_id,
        only_processed: true,
        not_admin: true,
      })
      .then(res => {
        this.setState({
          page,
          data: res.data.orders.data,
          sellers: res.data.sellers,
          managers: res.data.managers,
          last_page: res.data.orders.last_page,
          monto_total: res.data?.totales?.total ?? 0,
          //total_comisiones: res.data?.totales?.total_comision ?? 0,
          //total_cobrar: res.data?.totales?.total_cobrar ?? 0,
          total_comision_paid_seller: res.data?.totales?.total_comision_paid_seller ?? 0,
          total_comision_toPay_seller: res.data?.totales?.total_comision_toPay_seller ?? 0,
          total_comision_paid_manager: res.data?.totales?.total_comision_paid_manager ?? 0,
          total_comision_toPay_manager: res.data?.totales?.total_comision_toPay_manager ?? 0,
        });
      })
      .catch(err => {
        console.log('Commissions -> load -> catch: ', err);
      });
  };

  change = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        search: "",
        user_id: "",
        seller_id: "",
        manager_id: "",
      },
      thead: [],
      trows: [],
    }));
    await this.load();
  };

  print = () => {
		axios
      .post('admin/reports/commissions/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        until: this.state.form.until && moment(this.state.form.until,'DD-MM-YYYY').format('YYYY-MM-DD'),
        since: this.state.form.since && moment(this.state.form.since,'DD-MM-YYYY').format('YYYY-MM-DD'),
        currency: this.props.currency,
        seller_id: this.state.form.seller_id,
        web: true,
        only_processed: true,
        not_admin: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Commissions -> download -> catch: ', err);
      });
  };

  render() {
    return (
      <Menu history={this.props.history}>
        <div id="products" className="report-commisions new-reports">
          <div className="row">
            <div className="col-md-2 pr-0">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2 pr-0">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2 pr-0">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.seller_id}
                options={this.state.sellers.map(Item => ({
                  value: Item.id,
                  label: Item.name
                }))}
              />
            </div>
            <div className="col-md-2 pr-0">
              <Select
                color="white"
                name="manager_id"
                label="Gerente"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.manager_id}
                options={this.state.managers.map(Item => ({
                  value: Item.id,
                  label: Item.name
                }))}
              />
            </div>

            <div className="col-md-4">
              <div className="container-buttons">
                <Button className="btn-align-bottom btn-filter blue" color=" " onClick={() => this.load()}>
                  <span>Filtrar</span>
                </Button>
                <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="monthly-earnings flex">
            <div className="total-amount">
              <div className="container-total blue">
                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>TOTAL VENTAS</span>
                <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '16px' }}>{Globals.formatMiles(this.state.monto_total)} $</div>
              </div>
            </div>
            <div className="total-amount">
              <div className="container-total cyan">
                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>PAGADO VENDEDORES</span>
                <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '16px' }}>{Globals.formatMiles(this.state.total_comision_paid_seller)} $</div>
              </div>
            </div>
            <div className="total-amount">
              <div className="container-total">
                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>POR PAGAR VENDEDORES</span>
                <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '16px' }}>{Globals.formatMiles(this.state.total_comision_toPay_seller)} $</div>
              </div>
            </div>
            <div className="total-amount">
              <div className="container-total">
                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>PAGADO GERENTES</span>
                <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '16px' }}>{Globals.formatMiles(this.state.total_comision_paid_manager)} $</div>
              </div>
            </div>
            <div className="total-amount">
              <div className="container-total">
                <span className="" style={{ fontWeight: 'bold', fontSize: '16px' }}>POR PAGAR GERENTES</span>
                <div style={{ color: 'rgb(58, 127, 194)', fontWeight: 'bold', fontSize: '16px' }}>{Globals.formatMiles(this.state.total_comision_toPay_manager)} $</div>
              </div>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Reporte de comisiones"
            header={this.state.header}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print()}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td style={{ maxWidth: 'none' }} > {i.created_es} </td>
                  <td style={{ maxWidth: 'none' }} >{ i.number_format }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.total, true) }</td>
                  <td style={{ maxWidth: 'none' }}>{ i?.seller?.name }</td>
                  <td style={{ maxWidth: 'none' }} >{ i?.seller?.managers[0]?.name }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.partial_payment_total, true) }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.total_commission_paid_seller, true) }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.total_commission_to_pay['seller'], true) }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.total_commission_paid_manager, true) }</td>
                  <td style={{ maxWidth: 'none' }} >{ Globals.formatMiles(i.total_commission_to_pay['manager'], true) }</td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              this.load(page);
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(ReportCommisions);
