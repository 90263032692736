import io from 'socket.io-client';
import ENV from './env';

const Socket = io(ENV.Socket,{
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 5000,
      reconnectionAttempts: Infinity,
      forceNew: false
});

export default Socket;
