import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Icon, Modal, Pagination, Table } from "../../components";
import { Globals } from "../../utils";
import { Bank } from "../../services";

// Components
import Menu from "../menu";
import Filter from "./filterForm";
import CreateEditBank from "./create_edit_bank";

const TABLE_HEADER = [
  "Nombre",
  "Estado",
  "Acciones"
];

class Banks extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      create: false,
      data: [],
      page: 1,
      current_page: 1,
      last_page: 1,
      bank: null,
      form: {
        status: "",
        search: ""
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    let { user } = this.props;
    const { page } = this.state;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Bank.getBanksPaginated(page, { enterprise_id: EnterpriseId })
    .then(response => {
      const { current_page, data, last_page } = response;
      this.setState({
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());

  };

  loadMore = () => {
    const { user } = this.props;
    const { page } = this.state;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Bank.getBanksPaginated(page, { enterprise_id: EnterpriseId })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;
    const { page } = this.state;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Bank.getBanksPaginated(page, { enterprise_id: EnterpriseId, ...form })
      .then(response => {
        const { current_page, data, last_page } = response;
        this.setState({
          page: 1,
          data: data,
          current_page: current_page,
          last_page: last_page,
          form: {
            ...form
          }
        });
      })
      .catch(() => Globals.showError());
  };

  active = bank => {
    const {id, name, status} = bank;
    const message = status === 2 ?  `¿Desea activar al banco ${name}?` : `¿Desea suspender al banco ${name}?`;
    Globals.confirm(message, () => {
      Globals.setLoading("Procesando...");
      Bank.activateBank(id)
      .then(response => {
            const { name:bankName, status:bankStatus } = response;
            const statusText = bankStatus === 2 ? 'Suspendido' : 'Activado';
            Globals.showSuccess(`Banco ${bankName} ${statusText} con exito!`);
            this.loadMore();
          })
      .catch(err => {
        if (err.msg) Globals.showError(err.msg);
      })
      .finally(() =>{
        Globals.quitLoading();
      })
    })
  }

  delete = bank => {
    const {id, name} = bank;
    const message =`¿Desea eliminar al banco ${name}?`;
    Globals.confirm(message, () => {
      Globals.setLoading("Procesando...");
      Bank.deleteBank(id)
      .then(response => {
            Globals.showSuccess(`Banco ${name} eliminado con exito!`);
            this.loadMore();
          })
      .catch(err => {
        if (err.msg) Globals.showError(err.msg);
      })
      .finally(() =>{
        Globals.quitLoading();
      })
    })
  }

  close = () => {
    this.setState(
      {
        create: false,
        bank: null
      },
      () => this.loadMore()
    );
  };

  render() {
    const { bank, create, data } = this.state;
    const { user } = this.props;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    return (
      <Menu history={this.props.history}>
        {create && (
          <Modal
            title={bank ? "Editar Banco" : "Nuevo Banco"}
            onClose={this.close}
            visible
          >
            <CreateEditBank
              EnterpriseId={EnterpriseId}
              user={user}
              bank={bank}
              onClose={this.close}
            />
          </Modal>
        )}
        <div id="home">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              user={user}
              Id={EnterpriseId}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={this.state.data.length}
                  title="Bancos"
                  header={TABLE_HEADER}
                  right={
                    <Button
                      title="Agregar Bancos"
                      outline="true"
                      small="true"
                      onClick={() => {
                        this.setState({
                          create: true
                        });
                      }}
                    >
                      <Icon name="plus" />
                    </Button>
                  }
                >
                  {data.map((Item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {Item.name}
                        </td>
                        <td style={{ color: Globals.getStatus(Item.status).color }}>
                          {Globals.getStatus(Item.status).text}
                        </td>
                        <td>
                          <Button
                            title="Editar"
                            color="primary"
                            small="true"
                            onClick={() =>
                              this.setState({
                                create: true,
                                bank: Item
                              })
                            }
                          >
                          <Icon name="edit" />
                          </Button>
                          {Item.status === 2 && (
                            <Button
                              color="info"
                              title="Activar"
                              small="true"
                              onClick={() => {
                                this.active(Item);
                              }}
                            >
                              <Icon name="check" />
                            </Button>
                          )}
                          {Item.status === 1 && (
                            <Button
                              color="info"
                              title="Suspender"
                              small="true"
                              onClick={() => this.active(Item)}
                            >
                              <Icon name="close" />
                            </Button>
                          )}
                          <Button
                            color="red"
                            title="Eliminar"
                            small="true"
                            onClick={() => this.delete(Item)}
                          >
                            <Icon name="trash" />
                          </Button>
                          <Link to={`bank-accounts/${Item.id}`}>
                            <Button color="orange" title="Cuentas" small="true">
                              <Icon name="list" />
                            </Button>
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={this.state.last_page}
                  active={this.state.page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => this.loadMore()
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Banks);
