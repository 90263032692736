import React from 'react';

const Avatar = props => (
	<img className="img-avatar" style={
		{
			width: props.size,
			height: props.size,
			borderRadius: `calc(${ props.size } * 2)`
		}
	} src={ props.source } alt="" />
)

export default Avatar;