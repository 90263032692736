import React from "react";
import { connect } from "react-redux";

import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Input,
  Select
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";

// Components
import Menu from "../menu";
import ViewProvider from "./view_provider";
import CreateEditProvider from "./create_edit_provider";
import AddIcon from '../../assets/icons/add.png';

import moment from "moment";

const TABLE_HEADER = [
  "Nombre/Empresa",
  "Email",
  "RIF",
  "Teléfono",
  "Persona de contacto",
  //"Estatus",
  "Acciones"
];

const STATUS = [
  { value: "", label: "Todos" },
  { value: 1, label: "Activos" },
  { value: 2, label: "Suspendidos" }
];
class Providers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      last_page: 1,
      data: [],
      retentions: [],
      enterprises: [],
      user: null,
      view: false,
      form: {
        search: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
      },
      selectedProvider: null,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.role = this.props.user.role;
    if (this.props.user.role === 3) {
      param.user_id = this.props.user.id;
    }
    if (this.props.user.role === 4) {
      param.user_id = this.props.user.enterprise_users.enterprise_id;
    }
    axios
      .post("admin/providers?page=" + this.state.page, param)
      .then(res => {
        if (res.data.providers) {
          this.setState({
            last_page: res.data.providers.last_page,
            data: res.data.providers.data,
            //retentions: res.data.retentions,
            //enterprises: res.data.enterprises
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });
    await this.load();
  };

  suspend = (item, i) => {
    Globals.confirm("¿Desea suspender a " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/providers/suspend", { id: item.id })
        .then(res => {
          if (res.data.result) {
            Globals.showSuccess(res.data.msg);
            this.load();
          } else {
            Globals.showError();
          }
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  activate = (item, i) => {
    Globals.confirm("¿Desea activar a: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/providers/active", { id: item.id })
        .then(res => {
          if (res.data.result) {
            Globals.showSuccess(res.data.msg);
            this.load();
          } else {
            Globals.showError();
          }
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar a: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/providers/delete", { provider_id: item.id })
        .then(res => {
          Globals.showSuccess('Proveedor eliminado');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nuevo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  render() {
    const { view, create, selectedProvider, } = this.state;

    const currentDate = new Date();
    const threeMonthsAgo = new Date(currentDate.getFullYear(), currentDate.getMonth() - 2);
    const threeMonthsName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(threeMonthsAgo);
    const monthName = new Intl.DateTimeFormat("es-ES", { month: "long" }).format(currentDate);

    return (
      <Menu history={this.props.history}>
        {view && (
          <Modal title="Ver Proveedor" onClose={this.close} visible>
            <ViewProvider user={this.state.user} />
          </Modal>
        )}

        {create && (
          <Modal
            title={this.state.edit ? "Editar Proveedor" : "Nuevo Proveedor"}
            onClose={this.close}
            visible
          >
            <CreateEditProvider
              edit={this.state.edit}
              //retentions={this.state.retentions}
              //enterprises={this.state.enterprises}
              onClose={this.close}
            />
          </Modal>
        )}

        <Modal
          title={"Compras del último trimestre: " + threeMonthsName + '-' + monthName}
          onClose={() => this.setState({ selectedProvider: null })}
          visible={selectedProvider}
        >
          <p>Proveedor: <b>{selectedProvider?.name}</b></p>
          <table border={1} style={{ border: 'collapse', width: '100%' }}>
            <thead>
              <tr style={{ textAlign: 'center' }}>
                <th>Fecha</th>
                <th>Factura</th>
                <th>Vencimiento</th>
                <th>Debe</th>
                <th>Haber/Pago</th>
              </tr>
            </thead>
            <tbody>
              {selectedProvider?.last_trimester_purchases?.map((item, index) => (
                <tr key={index} style={{ textAlign: 'right' }}>
                  <td style={{ textAlign: 'center' }}>
                    {item?.created_es}
                  </td>
                  <td>{item?.number_format}</td>
                  <td style={{ textAlign: 'center' }}>
                    {!!item?.payment_date ? moment(item?.payment_date).format('DD/MM/YYYY') : ''}
                  </td>
                  <td>${Globals.formatMiles(item?.to_pay)}</td>
                  <td>${Globals.formatMiles(item?.partial_payment_total)}</td>
                </tr>
              ))}
              <tr style={{ textAlign: 'right' }}>
                <td colSpan={4}>
                  <b>Total CxP</b>
                </td>
                <td>
                  <b>$ {Globals.formatMiles(selectedProvider?.totalCxC)}</b>
                </td>
              </tr>
            </tbody>
          </table>
        </Modal>

        <div id="home">
          <div className="row">
            <div className="col-sm-12 col-md-5">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre, RIF, o Email"
              />
            </div>
            {/* <div className="col-sm-12 col-md-5">
              <Select
                color="white"
                name="status"
                label="Status"
                defaultname="Seleccione"
                className="filter"
                onChange={this.change}
                value={this.state.status}
                options={STATUS.map(Item => {
                  return {
                    value: Item.value,
                    label: Item.label
                  };
                })}
              />
            </div> */}
            <div className="col-sm-12 col-md-2">
              <Button 
                color=" " 
                className="btn-align-bottom btn-filter" 
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
          </div>

          <Table
            data={this.state.data.length}
            title="Proveedores"
            header={TABLE_HEADER}
            right={
              <Button
                title="Agregar Proveedores"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Agregar Proveedores" />
              </Button>
            }
          >
            {this.state.data.map((Item, key) => {
              const totalCxC = Item?.last_trimester_purchases?.reduce((a, b) => a + b?.to_pay, 0);
              return (
                <tr key={key}>
                  <td>
                    {!!Item?.last_trimester_purchases?.length ? (
                      <span
                        className="clickable"
                        onClick={() => this.setState({ selectedProvider: { ...Item, totalCxC} })}
                      >
                        { Item.name }
                      </span>
                    ) : ( Item.name )}
                  </td>
                  {/* <td>{Item.company}</td> */}
                  <td>{Item.email}</td>
                  <td>{Item.document}</td>
                  <td>{Item.phone}</td>
                  <td>{Item.contact_person}</td>
                  {/* <td style={{ color: this.getStatus(Item.status).color }}>
                    {this.getStatus(Item.status).text}
                  </td> */}
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      style={{backgroundColor: '#5c068c'}}
                      onClick={() => {
                        this.setState({
                          user: Item,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      small="true"
                      color=" "
                      style={{backgroundColor: '#0f54e0'}}
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: Item,
                            index: key
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {/* {Item.status === 2 && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.activate(Item, key);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {Item.status === 1 && (
                      <Button
                        color="warning"
                        title="Suspender"
                        small="true"
                        style={{backgroundColor: '#d39e00'}}
                        onClick={() => this.suspend(Item, key)}
                      >
                        <Icon name="close" />
                      </Button>
                    )} */}
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      style={{backgroundColor: '#cf3f10'}}
                      onClick={() => this.delete(Item, key)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => {
                  this.load();
                }
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}
export default connect(state => {
  return {
    user: state.user
  };
})(Providers);
