import React from "react";
import { connect } from "react-redux";

import { Globals, Format } from "../../utils";
import { Button, Modal, Table, Icon } from "../../components";
import { CashCount as CC } from "../../services";
import AddIcon from '../../assets/icons/add.png';

// Components
import Menu from "../menu";
import Filter from "./filterForm";
import ManageCashCount from "./manage-cash-count";

const TABLE_HEADER = [
  "Caja",
  "Fecha",
  "Hora",
  "Saldo inicial",
  "Saldo final",
  "Acción"
];

class CashCount extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: null,
      cashCountModalTitle: 'Cierre de caja',
      visible: false,
      visibleCashCount: false,
      visibleResume: false,
      itemDetails: null,
      data: [],
      cashRegisters: [],
    };
  }

  componentDidMount() {
    this.load();
  }

  load = (form = {}) => {
    const { user } = this.props;
    const Id = user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Promise.all([
      CC.getNotProcessedRequests({
        ...form,
        enterprise_id: enterpriseId,
        user_id: Id,
        warehouse_id: warehouse_id,
        role: user.role
      }),
      CC.getCajas(),
    ])
      .then(res => {
        console.log(res);
        const cashRegisters = res[1]?.cajas?.map(x => ({ ...x, value: x.id, label: x.name + ' '+x.person.code }));
        this.setState({ data: res[0], cashRegisters });
      })
      .catch((err) => {
        console.log(err, 'err')
        Globals.showError()
      });
  };

  loadMore = () => {
    const { user } = this.props;
    const { form } = this.state;

    const Id = user.id;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    CC.getNotProcessedRequests({
      enterprise_id: enterpriseId,
      user_id: Id,
      warehouse_id: warehouse_id,
      role: user.role,
      ...form
    })
      .then(response => {
        const data = response;
        let total = 0;
        let pending = 0;

        const dataMap = Format.rawMethodPayments(data);
        const dataFormatted = Format.rawCashCounts(dataMap);
        if(dataFormatted.length > 0) {
          total = dataFormatted.reduce((prev, next) => prev + parseFloat((next.amount).toFixed(2)), 0);
          pending = dataFormatted.reduce((prev, next) => prev + parseFloat((next.current_amount).toFixed(2)), 0);
        }

        this.setState({
          data: dataFormatted,
          total: total,
          pending: pending
        });
      })
      .catch(() => Globals.showError());
  };

  cashCount = async () => {
    await this.setState({ visible: false, visibleCashCount: true });
  };

  deployManage = Item => {
    this.setState({ Item: Item, visible: true });
  };

  processingPayment = (newPayment) => {

    const { Item, data } = this.state;
    const key = data.findIndex(({ id }) => id === Item.id);
    const newData = [...data];

    newData[key] = {
      ...newData[key],
      amount_available: newData[key].amount_available + parseFloat(newPayment.amount)
    };

    newPayment.amount = parseFloat(newPayment.amount);
    newPayment.current_amount = parseFloat(newPayment.current_amount);
    newPayment.type = parseInt(newPayment.type);
    newPayment.status = 0;

    this.setState(state => ({
      visibleCreate: false,
      visibleProcessing: true,
      Item: {
        ...state.Item,
        amount_available: newData[key].amount_available,
        payments: [...state.Item.payments, newPayment]
      },
      data: newData
    }));
  };

  close = async (success = false) => {
    await this.viewResume(false);
    await this.setState({ visible: false, visibleCashCount: false, itemDetails: null });
    if (success) this.load();
  };

  viewResume = async (view) => {
    const title = view ? 'Resumen de cierre de caja' : 'Cierre de caja';
    await this.setState({ visibleResume: view, cashCountModalTitle: title });
  }

  viewDetails = async (item) => {
    await this.setState({ itemDetails: item });
    this.viewResume(true);
    this.cashCount();
  }

  render() {
    const { data, cashRegisters, visibleResume, visibleCashCount, cashCountModalTitle, itemDetails } = this.state;
    const { user, history } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    return (
      <Menu history={history}>
        {visibleCashCount && (
          <Modal
            className="modal-cash-count"
            title={cashCountModalTitle}
            onClose={this.close}
            visible
          >
            <ManageCashCount
              enterpriseId={enterpriseId}
              cashRegisters={cashRegisters}
              onClose={this.close}
              viewResume={this.viewResume}
              visibleResume={visibleResume}
              readonly={!!itemDetails}
              item={itemDetails}
            />
          </Modal>
        )}

        <div id="delivery">
          <div className="container-fluid">
            <Filter
              submitted={form => this.load(form)}
              user={user}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Cierre de Caja"
                  header={TABLE_HEADER}
                  right={
                    <Button
                      small="true"
                      title="Agregar cierre de caja"
                      onClick={() => this.cashCount()}
                    >
                      <img src={AddIcon} alt="Agregar cierre de caja" />
                    </Button>
                  }
                >
                  {data.length > 0 && data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>{Item.caja.person.code ? Item.caja.person.code : ''} {Item.caja.person.first_name ? Item.caja.person.first_name : ''}</td>
                        <td>{Item.date}</td>
                        <td>{Item.hour}</td>
                        <td>{Item.entry_amount}</td>
                        <td>{Item.current_amount}</td>
                        <td>
                          <Button
                            color=" "
                            title="Ver mas"
                            small="true"
                            style={{backgroundColor: '#5c068c'}}
                            onClick={() => this.viewDetails(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CashCount);
