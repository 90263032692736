import axios from "../../utils/axios";

class DebtsToPay {

  static async getDebtsToPay(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/debts-to-pay/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async payDebt(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/debts-to-pay/pay", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

}



export default DebtsToPay;