import axios from "../../utils/axios";

class auth {
  static async authenticate(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/login", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async refresh(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/refresh", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async logoutWeb(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/logout-web", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async checkSession(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/check-session", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async checkPlanUser(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/check-planuser", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async register(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/register", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async recover(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/recover", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async checkPermissions(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/checkpermissions", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCountries(form) {
    return new Promise((resolve, reject) => {
      axios.post("auth/countries", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

}

export default auth;
