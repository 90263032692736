import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Input,
} from "../../components";
import { axios, Globals, ENV } from "../../utils";
import PrinterIcon from '../../assets/icons/printer.png';

const tableHeader = [
  'N° devolución', 'N° nota crédito', 'Fecha', 'Cliente', 'Vendedor', 'Monto disponible', 'Estatus','Productos defectuosos','Acciones',
];

class ReportReturns extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      last_page: 1,
      data: [],
      sellers: [],
      clients: [],
      form: {
        return_id: "",
        credit_note_id: "",
        client_id: "",
        seller_id: "",
      },
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (page = 1) => {
    Globals.setLoading();

    axios
      .post('admin/returns/get', {
        user_id: this.props.user.id,
        return_id: this.state.form.return_id,
        credit_note_id: this.state.form.credit_note_id,
        seller_id: this.state.form.seller_id,
        client_id: this.state.form.client_id,
        page,
      })
      .then(res => {
        this.setState({
          page,
          data: res.data.returns.data,
          sellers: res.data.sellers,
          clients: res.data.clients,
          last_page: res.data.returns.last_page,
        });
      })
      .catch(err => {
        console.log('Commissions -> load -> catch: ', err);
      })
      .finally(() => Globals.quitLoading());
  };

  change = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  print = () => {
    axios
      .post('admin/returns/download-all', {
        ...this.state.form,
        user_id: this.props.user.id,
        currency: this.props.currency,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Reporte de devoluciones -> download -> catch: ', err);
      });
  }

  printReturn = (item) => {
    Globals.setLoading();

		axios
      .post('admin/returns/download', {
        user_id: this.props.user.id,
        return_id: item.id,
      })
      .then(res => {
        if (!res.data.url) throw res.data.error;
        const win = window.open(res.data.url, "_blank");
        win.focus();
      })
      .catch(err => {
        Globals.showError(err);
        console.log('Commissions -> download -> catch: ', err);
      })
      .finally(() => Globals.quitLoading());
  };

  printCreditNote = (item) => {
    Globals.setLoading();

		axios
      .post('admin/returns/credit-note/download', {
        user_id: this.props.user.id,
        return_id: item.id,
      })
      .then(res => {
        if (!res.data.url) throw res.data.error;
        const win = window.open(res.data.url, "_blank");
        win.focus();
      })
      .catch(err => {
        Globals.showError(err);
        console.log('Commissions -> download -> catch: ', err);
      })
      .finally(() => Globals.quitLoading());
  };

  render() {
    return (
      <Menu history={this.props.history}>
        <div id="report-returns">
          <div className="row">
            <div className="col-md-3 pr-0">
              <Input
                color=" "
                name="return_id"
                label="Número Devolución"
                className="filter"
                onChange={this.change}
                value={this.state.form.return_id}
                placeholder="Número Devolución"
              />
            </div>
            <div className="col-md-3 pr-0">
              <Input
                color=" "
                name="credit_note_id"
                label="Número Nota de Crédito"
                className="filter"
                onChange={this.change}
                value={this.state.form.credit_note_id}
                placeholder="Número Nota de Crédito"
              />
            </div>
            <div className="col-md-2 pr-0">
              <Select
                color="white"
                name="client_id"
                label="Cliente"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.client_id}
                options={this.state.clients.map(item => ({
                  value: item.id,
                  label: item.name
                }))}
              />
            </div>
            <div className="col-md-2 pr-0">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form.seller_id}
                options={this.state.sellers.map(item => ({
                  value: item.id,
                  label: item.name
                }))}
              />
            </div>

            <div className="col-md-2 pr-0 d-flex" style={{ gap: '1rem' }}>
              <Button className="btn-align-bottom btn-filter" color=" " onClick={() => this.load()}>
                <span>Filtrar</span>
              </Button>
            </div>
          </div>

          <Table
            data={this.state.data.length}
            title="Reportes de devolución"
            header={tableHeader}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print()}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{ i.number_format }</td>
                  <td>{ i.credit_note?.number_format ?? '' }</td>
                  <td>{ moment(i.date).format('DD/MM/YYYY') }</td>
                  <td>{ i.order?.client?.name }</td>
                  <td>{ i.order?.seller?.name }</td>
                  <td>{ i.total_return }</td>
                  <td>{ i.status_text }</td>
                  <td>
                    {
                      i.details
                        .filter(detail => detail.has_conditions == 0)
                        .map((detail, index) => 
                          <React.Fragment key={index}>
                            {detail.product.name} {detail.quantity}<br />
                          </React.Fragment>
                        )
                    }
                  </td>
                  <td>
                    {(i.credit_note !== null) && (
                      <span
                        className="print-badge"
                        title="Imprimir Nota de Crédito"
                        onClick={() => this.printCreditNote(i)}
                      >
                        <span>Imprimir NC</span>
                      </span>
                    )}
                    {/* {(i.credit_note !== null) && (
                      <button
                        className="edit-circle"
                        title="Editar Nota de Crédito"
                        onClick={() => {}}
                      >
                        <Icon name="pencil" />
                      </button>
                    )} */}

                    <span
                      className="print-badge"
                      title="Imprimir Devolución"
                      onClick={() => this.printReturn(i)}
                    >
                      <span>Imprimir D</span>
                    </span>
                    {/* <button
                      className="edit-circle"
                      title="Editar Devolución"
                      onClick={() => {}}
                    >
                      <Icon name="pencil" />
                    </button> */}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              this.load(page);
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(ReportReturns);
