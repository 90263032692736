import axios from "../../utils/axios";

class MonthlyEarnings {
  static async getEarnings(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/monthly-earnings/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default MonthlyEarnings;
