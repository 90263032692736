import axios from "../../utils/axios";

class Seller {
  static async getSellers(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/sellers/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getSellersByEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sellers/enterprise", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getSellersHaveDeliveryNotes(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sellers/delivery-notes", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getSellersHaveRequests(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sellers/requests", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async create(form) {
    return new Promise((resolve, reject) => {
      axios.upload("admin/sellers/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async update(form) {
    return new Promise((resolve, reject) => {
      axios.upload("admin/sellers/update", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Seller;
