import React from "react";

import { Globals, Constants } from "../../utils";
import { Client } from "../../services";
import { Select, Input, Button, Textarea } from "../../components";

class FormClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      form: {
        enterprise_id: props.Id,
        first_name: "",
        document_type: "V",
        identity_document: "",
        fiscal_document_type: "V",
        fiscal_identification: "",
        email: "",
        phone: "",
        zone_id: "",
        retention_id: 1,
        person_type: "",
        direction: "",
        creator_id: props.Id
      },
      zones: [],
      errors: [],
      textButton: "Crear",
      buttonBack: "Ir atras"
    };
  }

  componentDidMount() {
    this.maybeLoadZones();
  }

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { form, submitted } = this.state;

    if (submitted) {
      return;
    }

    this.setState({ submitted: true, errors: [] });

    Client.ClientFromRequest(form)
      .then(response => {
        this.props.customer(response);
        this.setState({ submitted: false, errors: [] });
      })
      .catch(error => {
        if ((error || {}).message) {
          Globals.showError(error.message);
        }

        this.setState({ submitted: false, errors: error });
      });
  };

  maybeLoadZones = () => {
    Client.getZones()
      .then(response => {
        const zonesMap = response.map(Item => {
          return {
            value: Item.id,
            label: Item.name
          };
        });

        this.setState({ zones: zonesMap });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    switch (name) {
      default:
        this.setState({
          form: {
            ...this.state.form,
            [name]: value
          }
        });
        break;
    }
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { submitted, zones } = this.state;

    return (
      <div className="container-create-edit-order">
        <div className="row">
          <div className="col col-sm">
            <Input
              color=" "
              value={this.state.form.first_name}
              name="first_name"
              label="Nombre"
              labelClass="material"
              className="material"
              error={`${this.hasErrorFor("first_name") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("first_name")}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-sm">
            <div style={{ display: "flex" }}>
              <Select
                color=" "
                label="Tipo"
                name="document_type"
                labelClass="material"
                className="material"
                value={this.state.form.document_type}
                options={Constants.TYPE_IDENTITY}
                onChange={this.handleChange}
              />
              <Input
                color=" "
                value={this.state.form.identity_document}
                name="identity_document"
                label="Cédula"
                labelClass="material"
                className="material"
                maxLength={this.state.form.document_type === "J" ? 9 : 8}
                error={`${this.hasErrorFor("identity_document") ? "is-invalid" : ""
                  }`}
                invalidfeedback={this.renderErrorFor("identity_document")}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col col-sm">
            <div style={{ display: "flex" }}>
              <Select
                color=" "
                label="Tipo"
                name="fiscal_document_type"
                labelClass="material"
                className="material"
                value={this.state.form.fiscal_document_type}
                options={Constants.TYPE_DOCUMENTS}
                onChange={this.handleChange}
              />
              <Input
                color=" "
                value={this.state.form.fiscal_identification}
                name="fiscal_identification"
                label="RIF"
                labelClass="material"
                className="material"
                //maxLength={this.state.form.document_type === "J" ? 9 : 8}
                maxLength={this.state.form.document_type === "J" ? 12 : 12}
                error={`${this.hasErrorFor("fiscal_identification") ? "is-invalid" : ""
                  }`}
                invalidfeedback={this.renderErrorFor("fiscal_identification")}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col col-sm">
            <Input
              color=" "
              value={this.state.form.email}
              name="email"
              type="email"
              label="E-Mail"
              labelClass="material"
              className="material"
              error={`${this.hasErrorFor("email") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("email")}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-sm">
            <Input
              color=" "
              value={this.state.form.phone}
              name="phone"
              label="Teléfono"
              labelClass="material"
              className="material"
              maxLength={11}
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              error={`${this.hasErrorFor("phone") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("phone")}
              onChange={this.handleChange}
            />
          </div>
          <div className="col col-sm">
            <Select
              color=" "
              name="zone_id"
              label="Zona"
              labelClass="material"
              className="material"
              onChange={this.handleChange}
              value={this.state.form.zone_id}
              error={`${this.hasErrorFor("zone_id") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("zone_id")}
              options={zones}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-sm">
            <Textarea
              label="Dirección"
              name="direction"
              value={this.state.form.direction}
              error={`${this.hasErrorFor("direction") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("direction")}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {submitted ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
                <div id="button" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                  <Button
                    type="button"
                    color="white"
                    onClick={this.props.removeAttribute()}
                    disabled={submitted}
                  >
                    <span>{this.state.buttonBack}</span>
                  </Button>
                  <Button
                    type="button"
                    color=" "
                    className="primary"
                    onClick={this.handleSubmit}
                    disabled={submitted}
                  >
                    <span>{this.state.textButton}</span>
                  </Button>
                </div>
              )}
          </div>
        </div>
      </div>
    );
  }
}

export default FormClient;
