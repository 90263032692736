import React from "react";
import moment from "moment";

import { connect } from "react-redux";

import { Pagination, Button, Icon, Title } from "../../components";
import { Globals, ENV } from "../../utils";
import { MonthlyEarning } from "../../services";

// Components
import Menu from "../menu";
import Filter from "./filterForm";

import PrinterIcon from '../../assets/icons/printer.png';

class MonthlyEarnings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      form: {
        since: moment().format('YYYY-MM-DD'),
        until: moment().format('YYYY-MM-DD'),
      },
      total: 0,
      current_page: 1,
      last_page: 1,
      visibleProcessing: false,
      visibleRequest: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { page, form } = this.state;

    MonthlyEarning.getEarnings(page, form)
      .then(res => {
        const { current_page, data, last_page } = res.sales;
        this.setState({ data, total: res?.total, current_page, last_page });
      })
      .catch(err => Globals.showError());
  };

  filterMap = async (form) => {
    await this.setState({ form });
    this.load();
  };

  showItem = (Item) => {
    // this.setState({ Item: Item, visibleRequest: true });
  }

  printItem = (Item) => {

  }

  close = () => {
    this.setState(
      {
        visibleRequest: false,
        visibleProcessing: false
      },
      () => this.load()
    );
  };

  printReport = (data) => {
    const { since, until } = this.state.form;
    const since_format = moment(since).format("YYYY-MM-DD")
    const until_format = moment(until).format("YYYY-MM-DD")

    if(data.length > 0){
      let parameters = ''

      if (since != null && since != '') parameters += `${since_format}/`
      else parameters += `${0}/`

      if (until != null && until != '') parameters += `${until_format}`
      else parameters += `${0}`

      const url = `${ENV.BaseURI}admin/monthly-earnings/print/${parameters}`;
      const win = window.open(url, "_blank");
      win.focus();
    }
    else{
      Globals.showError('Debe de traer datos la consulta para poder imprimir el reporte');
    }
    
  };

  render() {
    const {
      data,
      form,
      last_page,
      page,
      total
    } = this.state;

    const { history } = this.props;

    return (
      <Menu history={history}>
        <div id="delivery">
          <div className="container-fluid monthly-earnings">
            <Filter
              submitted={form => this.filterMap(form)}
              since={moment(form.since).toDate()}
              until={moment(form.until).toDate()}
            />

            <div className="row">
              <div className="col-md">
                <div className="table-container" style={{ width: '100%' }}>
                  <Title 
                    name="Ganancias mensuales" 
                    right={
                      <Button
                        title="Imprimir"
                        small="true"
                        onClick={() => this.printReport(this.state.data)}
                      >
                        <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                      </Button>
                    }
                  />
                  <div className="table-responsive">
                    <table className="table table-component">
                      <thead style={{ whiteSpace: 'pre' }}>
                        <th>Productos</th>
                        <th>Marca</th>
                        <th>Modelo</th>
                        <th>Cantidad{'\n'}comprada</th>
                        <th>Cantidad{'\n'}vendida</th>
                        <th>Precio{'\n'}unitario</th>
                        <th>Precio{'\n'}venta</th>
                        <th>Importe total{'\n'}de la compra</th>
                        <th>Importe total{'\n'}de ventas</th>
                        <th>Ganancia{'\n'}bruta</th>
                        <th>% Ganancia{'\n'}por producto</th>
                        {/* <th>Acción</th> */}
                      </thead>
                      <tbody>
                        {data.map((Item, index) => (
                          <tr key={index}>
                            <td>{Item?.name}</td>
                            <td>{Item?.product_brand?.brand?.name}</td>
                            <td>{Item?.product_model?.model?.name}</td>
                            <td>{Item?.cant_comprada}</td>
                            <td>{Item?.cant_vendida}</td>
                            <td>{Globals.formatMiles(Item?.product_warehouse[0]?.cost)}</td>
                            <td>{Globals.formatMiles(Item?.product_warehouse[0]?.price)}</td>
                            <td>{Globals.formatMiles(Item?.importe_compra)}</td>
                            <td>{Globals.formatMiles(Item?.importe_venta)}</td>
                            <td className="text-center">{Globals.formatMiles(Item?.ganancia_bruta)}</td>
                            <td>{Globals.formatMiles(Item?.porc_ganancia) + ' %'}</td>
                            {/* <td>
                              <Button
                                color=" "
                                title="Ver más"
                                small="true"
                                style={{backgroundColor: '#5c068c'}}
                                onClick={() => this.showItem(Item)}
                              >
                                <Icon name="eye" />
                              </Button>
                              <Button
                                title="Imprimir"
                                small="true"
                                color=" "
                                style={{backgroundColor: '#0f54e0'}}
                                onClick={() => this.printItem(Item)}
                              >
                                <Icon name="print" />
                              </Button>
                            </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row flex-row-reverse">
              <div className="col-sm-6 total-earnings">
                <div>Total Ganancia Bruta</div>
                <div>{Globals.formatMiles(total)}</div>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={last_page}
                  active={page}
                  onChange={page => this.setState({ page }, () => this.load())}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(MonthlyEarnings);
