import React from "react";
import { connect } from "react-redux";

import {
  Button,
  Select,
  Input,
  Textarea,
  CheckBox,
  Icon
} from "../../components";
import File from "../../components/file";
import { axios, Globals, Constants } from "../../utils";
import { Product } from "../../services";

// Componentes
import Products from "./products";
import Category from "./category";
import Brand from "./brand";
import Subcategory from "./subcategory";
import Model from "./model";
import Warehouses from "./warehouses";

class CreateEditProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledName: true,
      showCateg: false,
      showBrand: false,
      showSubCateg: false,
      showModel: false,
      form: {
        name: "",
        replaceName: false,
        percentage_earning: 0,
        percentage_commission: 0,
        description: "",
        branchId: "",
        warehouseId: "",
        brand: "",
        brandName: "",
        model: "",
        modelName: "",
        category: "",
        categoryName: "",
        subcategory: "",
        subcategoryName: "",
        warehouses: [],
        user_id: props.Id,
        role: props.user.role,
        exempt: 0,
        image: "",
        serialization: 0,
        search: "",
        products: []
      },
      suggestions: [],
      categories: [],
      subcategories: [],
      models: [],
      brands: [],
      companies: [],
      warehouses: [],
      edit: false,
      textButton: "Crear"
    };
  }

  componentDidMount() {
    const { edit } = this.props;

    if (edit) {
      const { element: components } = edit;
      const productsDetails = components.product_details.map(Item => {
        return {
          ...Item,
          id: Item.product_id,
          name: (Item.product || {}).name,
          description: (Item.product || {}).description,
          code: (Item.product || {}).code,
          serialization: (Item.product || {}).serialization,
          quanty: Item.amount
        };
      });

      this.setState((state, props) => {
        state.form.products = productsDetails;
        state.form.model = props.edit.element.product_model
          ? props.edit.element.product_model.model_id
          : "";
        state.form.brand = props.edit.element.product_brand
          ? props.edit.element.product_brand.brand_id
          : "";
        return state;
      });

      this.load();

      return;
    }

    this.load();
    this.brands();
    this.categories();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.form.branchId !== this.state.form.branchId &&
      this.state.form.branchId
    ) {
      this.maybeLoadWarehouses();
    }

    if (prevProps.branches !== this.props.branches && this.props.edit) {
      this.maybeLoadBranch();
    }
  }

  load = () => {
    const { user, Id } = this.props;

    let param = {
      role: user.role,
      user_id: Id
    };

    axios
      .post("admin/products/categories", param)
      .then(res => {
        if (res.data.result) {
          let categories = [];
          res.data.categories.forEach((element, i) => {
            categories.push({
              label: element.name,
              value: element.id,
              subcategories: element.subcategories
            });
          });
          this.setState({
            categories: categories
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(() => Globals.showError())
      .then(() => this.brands());
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { warehouses, edit: components } = this.props;

    const warehouseMap =
      parseInt(form.branchId) > 0
        ? warehouses.filter(Item => {
          return (
            Item.value === 0 || Item.branchId === parseInt(form.branchId)
          );
        })
        : [...warehouses];

    const warehouseForm = warehouseMap
      .filter(Item => Item.value !== 0)
      .map(({ value, label }) => {
        let itemProduct;

        if (components) {
          const { element } = components;

          itemProduct = element.product_warehouse.find(
            Item => Item.warehouse_id === value
          );
        }

        return {
          id: value,
          name: label,
          stock_min: (itemProduct || {}).stock_min || "",
          stock_max: (itemProduct || {}).stock_max || "",
          price: (itemProduct || {}).price || "",
          location: (itemProduct || {}).location || "",
          percentage_earning: (itemProduct || {}).percentage_earning || "",
          percentage_max: (itemProduct || {}).percentage_max || "",
          percentage_offer: (itemProduct || {}).percentage_offer || ""
        };
      });

    this.setState(
      state => ({
        form: {
          ...state.form,
          warehouseId: "",
          warehouses: warehouseForm
        },
        warehouses: warehouseMap
      }),
      () => {
        if (components) {
          this.maybeLoadWarehouse();
        }
      }
    );
  };

  maybeLoadBranch = () => {
    const { edit } = this.props;
    const { element: components } = edit;

    const branchesMap = [
      ...new Set(
        components.product_warehouse.map(({ warehouse }) => warehouse.branch_id)
      )
    ];

    const branchId = branchesMap.length > 1 ? 0 : branchesMap[0];

    this.setState(
      state => ({
        form: {
          ...state.form,
          branchId: branchId
        }
      }),
      () => {
        if (components) {
          this.maybeLoadWarehouses();
        }
      }
    );
  };

  maybeLoadWarehouse = () => {
    const { edit } = this.props;
    const { element: components } = edit;

    const warehousesMap = [
      ...new Set(
        components.product_warehouse.map(({ warehouse_id }) => warehouse_id)
      )
    ];

    const warehouseId = warehousesMap.length > 1 ? 0 : warehousesMap[0];

    this.setState(state => ({
      form: {
        ...state.form,
        warehouseId: warehouseId
      }
    }));
  };

  brands = () => {
    const { user, Id } = this.props;

    let param = {
      role: user.role,
      user_id: Id
    };

    axios
      .post("admin/products-compound/brands", param)
      .then(({ data }) => {
        if (data.result) {
          const brandsMap = data.brands.map(({ name, id, models }) => ({
            label: name,
            value: id,
            models: models
          }));

          this.setState({
            brands: brandsMap
          });
        } else {
          Globals.showError(data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .finally(() => {
        if (this.props.edit) {
          this.edit();
          return;
        }
      });
  };

  handleClick = Product => {
    const { form } = this.state;

    Globals.confirm(`¿Desea remover el producto: ${Product.name}?`, () => {
      const filterMap = form.products.filter(Item => Item.id !== Product.id);

      this.setState(state => ({
        form: {
          ...state.form,
          products: filterMap
        }
      }));
    });
  };

  submit = () => {
    const { form } = this.state;

    let param = form;

    if (param.description == null) {
      param.description = "";
    }

    const warehouseMap = form.warehouses.filter(
      Item =>
        Item.percentage_earning !== "" ||
        Item.percentage_max !== "" ||
        Item.percentage_offer !== ""
    );

    const product = this.state.form.products.find(Item => Item.quanty <= 0 && !Item.serialization);

    // Validando si hay productos por serializar
    const cant = this.state.form.products.filter(
      i =>
        i.serialization == Constants.SERIALIZATION.ACTIVE && i.serials == null
    ).length;
    if (cant > 0) {
      Globals.showError("¡Hay productos que necesitan serializarse!");
      return;
    }

    param.ware = JSON.stringify(warehouseMap);
    param.prods = JSON.stringify(form.products);

    const serializedProducts = this.state.form.products.find(Item => Item.serialization > 0);

    if(!form.serialization && serializedProducts) {
      Globals.showWarning(
        `¡Debe activar la opcion de Serializacion, si al menos uno de los productos es serializado!`
      );
      return;
    }

    if(form.serialization && !serializedProducts) {
      Globals.showWarning(
        `¡Debe desactivar la opcion de Serializacion, si no posee ningun producto serializado!`
      );
      return;
    }

    if (product) {
      Globals.showWarning(
        `¡No le ha asignado una cantidad al producto ${product.name}!`
      );

      return;
    } else if (warehouseMap.length === 0) {
      Globals.showWarning(
        `¡Debe cargar al menos 1 porcentaje en alguno de los almacenes!`
      );

      return;
    }

    axios
      .upload(
        this.props.edit
          ? "admin/products-compound/edit"
          : "admin/products-compound/create",
        param
      )
      .then(res => {
        if (res.data.result) {
          Globals.showSuccess(res.data.msg);
          this.props.onClose();
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.msg);
          return;
        }

        Globals.showError();
      });
  };

  addCategory = Category => {
    this.setState(state => ({
      showCateg: !state.showCateg,
      categories: [
        ...state.categories,
        {
          label: Category.name,
          value: Category.id,
          subcategories: Category.subcategories || [] || []
        }
      ]
    }));
  };

  addSubcategory = Subcategory => {
    const { categories } = this.state;

    const key = categories.findIndex(
      Item => parseInt(Item.value) === parseInt(Subcategory.category_id)
    );

    const rawCategories = categories;

    rawCategories[key] = {
      ...rawCategories[key],
      subcategories: [...rawCategories[key].subcategories, Subcategory]
    };

    this.setState(state => ({
      showSubCateg: !state.showSubCateg,
      categories: rawCategories,
      subcategories: [
        ...state.subcategories,
        { label: Subcategory.name, value: Subcategory.id }
      ]
    }));
  };

  addBrand = Brand => {
    this.setState(state => ({
      showBrand: !state.showBrand,
      brands: [
        ...state.brands,
        { label: Brand.name, value: Brand.id, models: Brand.models || [] || [] }
      ]
    }));
  };

  addModel = Model => {
    const { brands } = this.state;

    const key = brands.findIndex(
      Item => parseInt(Item.value) === parseInt(Model.brand_id)
    );

    const rawBrands = brands;

    rawBrands[key] = {
      ...rawBrands[key],
      models: [...rawBrands[key].models, Model]
    };

    this.setState(state => ({
      showModel: !state.showModel,
      brands: rawBrands,
      models: [...state.models, { label: Model.name, value: Model.id }]
    }));
  };

  getName = () => {
    if (!this.state.form.replaceName && !this.props.edit) {
      this.setState(state => {
        state.form.name = "";
        state.form.name += state.form.categoryName + " ";
        state.form.name += state.form.subcategoryName + " ";
        state.form.name += state.form.brandName + " ";
        state.form.name += state.form.modelName + " ";
        return state;
      });
    }
  };

  edit = () => {
    this.setState({
      form: {
        ...this.state.form,
        id: this.props.edit.element.id,
        name: this.props.edit.element.name,
        description: this.props.edit.element.description,
        category: this.props.edit.element.product_category
          ? this.props.edit.element.product_category.category_id
          : "",
        subcategory: this.props.edit.element.product_subcategory
          ? this.props.edit.element.product_subcategory.subcategory_id
          : "",
        brand: this.props.edit.element.product_brand
          ? this.props.edit.element.product_brand.brand_id
          : "",
        model: this.props.edit.element.product_model
          ? this.props.edit.element.product_model.model_id
          : "",
        percentage_commission: this.props.edit.element.percentage_commission,
        percentage_earning: this.props.edit.element.percentage_earning,
        exempt: this.props.edit.element.exempt,
        serialization: this.props.edit.element.serialization
      },
      edit: true,
      textButton: "Editar"
    });

    this.models();
    this.categories();
  };

  categories = (name = null) => {
    const { form, categories } = this.state;

    const categoryMap = categories.find(
      Item => Item.value === parseInt(form.category)
    );

    if (categoryMap && categoryMap.subcategories) {
      const subcategoriesMap = categoryMap.subcategories.map(
        ({ name, id }) => ({
          label: name,
          value: id
        })
      );

      this.setState({
        subcategories: subcategoriesMap
      });
    }

    if (name === "category") {
      this.setState(
        state => {
          state.form = {
            ...state.form,
            subcategory: ""
          };
          return state;
        },
        () => {
          this.getName();
        }
      );
    }

    if (name === "subcategory") {
      this.getName();
    }
  };

  models(name = null) {
    const { form, brands } = this.state;

    const brandMap = brands.find(Item => Item.value === parseInt(form.brand));

    if (brandMap && brandMap.models) {
      const modelsMap = brandMap.models.map(({ name, id }) => ({
        label: name,
        value: id
      }));

      this.setState({ models: modelsMap });
    }

    if (name === "brand") {
      this.setState(
        state => {
          state.form = {
            ...state.form,
            model: ""
          };
          return state;
        },
        () => {
          this.getName();
        }
      );
    }

    if (name === "model") {
      this.getName();
    }
  }

  autocomplete = () => {
    const { Id } = this.props;
    const { form } = this.state;

    if (!form.search) {
      this.setState({ suggestions: [] });
      return;
    }

    const warehouseId =
      parseInt(form.warehouseId) === 0
        ? form.warehouses.map(({ id }) => id)
        : form.warehouses
          .filter(Item => Item.id === parseInt(form.warehouseId))
          .map(({ id }) => id);

    Product.getProductsFromCompound({
      user_id: Id,
      search: form.search,
      warehouses_id: warehouseId
    }).then(response => {
      const warehouseMap = FilterByWarehouse(form);

      let productMap = response
        .filter(Item => {
          return form.products.every(query => {
            return parseInt(query.id) !== parseInt(Item.id);
          });
        })
        .filter(Item => {
          const founds = warehouseMap.filter(warehouse => {
            return Item.product_warehouse.some(
              ({ warehouse_id }) => warehouse.id === warehouse_id
            );
          });

          return founds.length === warehouseMap.length;
        })
        .map(Item => {
          return {
            ...Item,
            quanty: ""
          };
        });

      this.setState({ suggestions: productMap });
    });
  };

  takeProduct = Product => {

    this.setState(state => ({
      form: {
        ...state.form,
        search: "",
        products: [...state.form.products, Product]
      },
      suggestions: []
    }));
  };

  change = async (e, key) => {
    const { value } = e.target;
    const { form } = this.state;

    var nam = e.target.name;

    if (e.target.name === "exempt" || e.target.name === "serialization") {
      let target = e.target;
      let val = target.checked ? 1 : 0;
      this.setState({
        form: {
          ...this.state.form,
          [e.target.name]: val
        }
      });
    } else if (
      e.target.name === "stock_min" ||
      e.target.name === "stock_max" ||
      e.target.name === "location"
    ) {
      const rawWarehouses = form.warehouses;

      rawWarehouses[key] = {
        ...rawWarehouses[key],
        [e.target.name]: e.target.value
      };

      this.setState({
        form: {
          ...this.state.form,
          warehouses: rawWarehouses
        }
      });
    } else if (e.target.name === "quanty") {
      let newValue = value ? parseInt(value) : value;
      let rawProducts = form.products;

      rawProducts[key] = {
        ...rawProducts[key],
        quanty: newValue
      };

      this.setState({
        showAutocomplete: true,
        form: {
          ...this.state.form,
          products: rawProducts
        }
      });
    } else if (e.target.name === "search") {
      this.setState(
        {
          form: {
            ...this.state.form,
            [e.target.name]: e.target.value
          }
        },
        () => {
          this.autocomplete();
        }
      );
    } else if (
      e.target.name === "branchId" ||
      e.target.name === "warehouseId"
    ) {
      this.setState(state => ({
        form: {
          ...state.form,
          [nam]: value
        }
      }));
    } else {
      var name = nam,
        text = null;

      var valid =
        name === "subcategory" ||
        name === "category" ||
        name === "brand" ||
        name === "model";

      if (valid) {
        for (let i = 0; i < e.target.options.length; i++) {
          if (e.target.options[i].value === e.target.value && e.target.value) {
            text = e.target.options[i].text;
          }
        }
      }

      var val = e.target.value;

      this.setState(
        state => {
          state.form[nam] = val;
          if (valid) {
            state.form[name + "Name"] = text;
          }
          return state;
        },
        () => {
          this.models(name);
          this.categories(name);
        }
      );
    }
  };

  handleValueChange = (values, name, key) => {
    const { floatValue } = values;
    const { form } = this.state;

    const rawWarehouses = form.warehouses;

    rawWarehouses[key] = {
      ...rawWarehouses[key],
      [name]: floatValue
    };

    this.setState(state => ({
      form: {
        ...state.form,
        warehouses: rawWarehouses
      }
    }));
  };

  render() {
    const {
      form,
      edit: components,
      showCateg,
      showBrand,
      showSubCateg,
      showModel,
      disabledName,
      warehouses
    } = this.state;
    const { Id, branches } = this.props;

    const warehouseMap =
      parseInt(form.warehouseId) > 0
        ? form.warehouses.filter(Item => Item.id === parseInt(form.warehouseId))
        : form.warehouses;

    return (
      <div className="container">
        <div className="text-center container-create-edit-user">
          <p className="text-center text-danger">
            <strong>Importante</strong>: Selecciona la sucursal y el almacén de
            donde se extraen los artículos que conformaran el producto
            compuesto.
          </p>
          {this.props.user.role === 1 ? (
            <div className="row">
              <div className="col col-md">
                <Select
                  label="Empresa"
                  name="company"
                  defaultname="category"
                  value={this.state.form.company ? this.state.form.company : ""}
                  options={this.state.companies}
                  onChange={this.change}
                />
              </div>
            </div>
          ) : (
              ""
            )}

          <div className="row">
            <div className="col-md-6">
              <Input
                color="gray"
                value={this.state.form.name}
                name="name"
                label="Nombre"
                onChange={this.change}
                disabled={disabledName}
              />
            </div>

            <div className="col-md-2 mt-4">
              <CheckBox
                classdiv="form-group"
                label="Editar Nombre"
                name="replaceName"
                checked={this.state.form.replaceName}
                value={this.state.form.replaceName}
                onChange={() => {
                  let replaceName = !form.replaceName;

                  this.setState(state => ({
                    disabledName: !replaceName,
                    form: {
                      ...state.form,
                      replaceName: replaceName
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2 mt-4">
              <CheckBox
                classdiv="form-group"
                label="Exento"
                name="exempt"
                checked={this.state.form.exempt}
                value={this.state.form.exempt ? 1 : 0}
                onChange={this.change}
              />
            </div>
            {/* <div className="col-md-2 mt-4">
              <CheckBox
                classdiv="form-group"
                label="Serialización"
                name="serialization"
                checked={this.state.form.serialization}
                value={this.state.form.serialization ? 1 : 0}
                onChange={this.change}
              />
            </div> */}
          </div>
          <div className="row">
            <div className="col col-md">
              <div className="row">
                <div className="col col-md">
                  <Select
                    label="Sucursales"
                    name="branchId"
                    value={this.state.form.branchId}
                    options={branches}
                    onChange={this.change}
                    disabled={components}
                  />
                </div>
                <div className="col col-md">
                  <Select
                    label="Almacenes"
                    name="warehouseId"
                    value={this.state.form.warehouseId}
                    options={warehouses}
                    onChange={this.change}
                    disabled={components}
                  />
                </div>
              </div>
            </div>
            <div className="col col-md">
              <Input
                color="gray"
                value={this.state.form.percentage_commission}
                name="percentage_commission"
                label="Porcentaje de comisión"
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                onChange={this.change}
              />
            </div>
          </div>
          <div className="row">
            <div className="col col-md">
              <Textarea
                label="Descripción"
                name="description"
                value={this.state.form.description}
                onChange={this.change}
                rows={3}
              />
            </div>
            <div className="col col-md">
              <File
                placeholder={
                  this.state.edit ? "Cambiar imagen" : "Agregar Imagen"
                }
                placeholdersuccess="Imagen Agregada"
                showcheck={true.toString()}
                onChange={this.change}
                name="image"
                label="Imagen"
                value={this.state.form.image}
                inputstyle={{
                  display: "contents"
                }}
                className="btn-product"
              />
            </div>
          </div>

          {form.warehouseId || components ? (
            <div className="row">
              <div className="col col-md-6">
                <Input
                  color="gray"
                  value={this.state.form.search}
                  name="search"
                  label="Buscar Productos"
                  onChange={this.change}
                  className="mb-0"
                />
                <div id="suggestion">
                  {this.state.suggestions.length > 0 && this.state.form.search
                    ? this.state.suggestions.map(Item => {
                      return (
                        <div
                          key={Item.id}
                          onClick={() => {
                            this.takeProduct(Item);
                          }}
                        >
                          {Item.code + "-" + Item.name}
                        </div>
                      );
                    })
                    : null}
                </div>
              </div>
            </div>
          ) : null}

          {form.products.length > 0 && (
            <Products
              form={form}
              onClick={Product => {
                this.handleClick(Product);
              }}
              onChange={(emitter, key) => {
                this.change(emitter, key);
              }}
            />
          )}

          {/* Select Category, Subcategory, Brand, Model */}

          <div className="row">
            <div className="col-md-3">
              <Select
                label="Categoría"
                name="category"
                value={this.state.form.category}
                options={this.state.categories}
                onChange={this.change}
                icon={
                  this.props.sidebar.find(
                    ({ name }) => name === "Categorías"
                  ) ? (
                      <Button
                        color="blue"
                        title="Agregar otra"
                        small="true"
                        onClick={() =>
                          this.setState(state => ({
                            showCateg: !state.showCateg
                          }))
                        }
                      >
                        <Icon name={this.state.showCateg ? "minus" : "plus"} />
                      </Button>
                    ) : null
                }
              />
            </div>
            <div className="col-md-3">
              <Select
                label="Subcategoría"
                name="subcategory"
                value={this.state.form.subcategory}
                options={this.state.subcategories}
                onChange={this.change}
                icon={
                  this.state.form.category &&
                    this.props.sidebar.find(
                      ({ name }) => name === "Categorías"
                    ) ? (
                      <Button
                        color="blue"
                        title="Agregar otra"
                        small="true"
                        onClick={() =>
                          this.setState(state => ({
                            showSubCateg: !state.showSubCateg
                          }))
                        }
                      >
                        <Icon name={this.state.showSubCateg ? "minus" : "plus"} />
                      </Button>
                    ) : null
                }
              />
            </div>
            <div className="col-md-3">
              <Select
                label="Marca"
                name="brand"
                value={this.state.form.brand}
                options={this.state.brands}
                onChange={this.change}
                icon={
                  this.props.sidebar.find(({ name }) => name === "Marcas") ? (
                    <Button
                      color="blue"
                      title="Agregar otra"
                      small="true"
                      onClick={() =>
                        this.setState(state => ({
                          showBrand: !state.showBrand
                        }))
                      }
                    >
                      <Icon name={this.state.showBrand ? "minus" : "plus"} />
                    </Button>
                  ) : null
                }
              />
            </div>
            <div className="col-md-3">
              <Select
                label="Modelo"
                name="model"
                value={this.state.form.model}
                options={this.state.models}
                onChange={this.change}
                icon={
                  this.state.form.brand &&
                    this.props.sidebar.find(({ name }) => name === "Marcas") ? (
                      <Button
                        color="blue"
                        title="Agregar otra"
                        small="true"
                        onClick={() =>
                          this.setState(state => ({
                            showModel: !state.showModel
                          }))
                        }
                      >
                        <Icon name={this.state.showModel ? "minus" : "plus"} />
                      </Button>
                    ) : null
                }
              />
            </div>
          </div>

          {/* Add news elements */}

          {showCateg || showBrand || showSubCateg || showModel ? (
            <div className="row">
              <div className="col col-md-3">
                {showCateg && (
                  <Category
                    category={Category => {
                      this.addCategory(Category);
                    }}
                    Id={Id}
                  />
                )}
              </div>
              <div className="col col-md-3">
                {showSubCateg && (
                  <Subcategory
                    subcategory={Subcategory => {
                      this.addSubcategory(Subcategory);
                    }}
                    Id={Id}
                    categoryId={form.category}
                  />
                )}
              </div>
              <div className="col col-md-3">
                {showBrand && (
                  <Brand
                    brand={Brand => {
                      this.addBrand(Brand);
                    }}
                    Id={Id}
                  />
                )}
              </div>
              <div className="col col-md-3">
                {showModel && (
                  <Model
                    model={Model => {
                      this.addModel(Model);
                    }}
                    Id={Id}
                    brandId={form.brand}
                  />
                )}
              </div>
            </div>
          ) : null}

          {form.warehouses.length > 0 && parseInt(form.warehouseId) >= 0 && (
            <Warehouses
              warehouses={warehouseMap}
              onChange={(emitter, key) => this.change(emitter, key)}
              onValueChange={this.handleValueChange}
            />
          )}

          <div className="row text-center">
            <div className="col col-md" id="button">
              <Button type="button" onClick={() => this.submit()}>
                {this.state.textButton}
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function FilterByWarehouse(form) {
  return parseInt(form.warehouseId) > 0
    ? form.warehouses.filter(Item => Item.id === parseInt(form.warehouseId))
    : form.warehouses;
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(CreateEditProduct);
