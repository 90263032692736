import React from 'react';
import { Button, Input } from './';
import { Globals } from '../utils';

class EditSerial extends React.Component {	

	constructor(props) {
		super(props);

		let product = { ...this.props.product };
		if (!product.serials) {
			product.serials = [];
			for (var i = 0; i < product.quantity; i++) {
				product.serials[i] = '';
			}
		}		

		this.state = {
			product: product
		}
	}

	hasDuplicates = array => {
	    return (new Set(array)).size !== array.length;
	}

	close = () => {
		if (this.hasDuplicates(this.state.product.serials)) {
			Globals.showError("Lo sentimos, existen uno o más seriales repetidos");
		}
		else {
			this.props.onSuccess(this.state.product);
		}		
	}
	
	render() {
		return (
			<div className={ `modal-serial ${ this.props.className }` }>
				<p className="product_name">{ this.state.product.name }</p>
					
				<div className="row">
					{
						this.state.product.serials.map((item,index) => (
							<div className="col-md-4" key={ index }>
								<Input
									color="gray"
				                    type="text"
				                    value={ item }
				                    placeholder="Serial de Producto"
				                    onChange={ emitter => {
				                    	let product = this.state.product;
				                    	const { name, value } = emitter.target;
				                    	product.serials[index] = value;
										this.setState({
											product: product
										});
				                    } }
				                  />
							</div>
						))
					}
				</div>

				<div className="row-flex flex-right">
					<div className="row-flex row-flex-buttons">
						<div className="col-flex text-center">
							<Button className="btn-red" onClick={ this.props.onCancel }>
		                      Cancelar
		                    </Button>					
						</div>
						<div className="col-flex text-center">
							<Button
							   disabled={ this.state.product.serials.filter(i => i == '').length > 0 } 
							   className="btn-green"
							   onClick={ this.close }>
		                      Guardar
		                    </Button>
						</div>					
					</div>
				</div>
			</div>
		)
	}
}

export default EditSerial;