import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditSeller from "./create_edit_seller";
import ViewSeller from "./view_seller";
import AddIcon from '../../assets/icons/add.png';
import globals from "../../utils/globals";
// import socket from '../../utils/socket';

class Sellers extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        "Nombre/Razón social",
        "Correo electrónico",
        "CI/RIF",
        "Teléfono",
        "Comisión",
        //"Rol",
        //"Estatus",
        "Acciones"
      ],
      page: 1,
      last_page: 1,
      data: [],
      user: null,
      view: false,
      list_status: [
        { value: "", label: "Todos" },
        // { value: 0, label: "Nuevos" },
        { value: 1, label: "Activos" },
        { value: 0, label: "Inactivos" }
      ],
      form: {
        status: null,
        search: null,
        user_id: this.props.user.id,
        role: this.props.user.role,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
      },
      permissions: [],
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
  
  load = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.role = this.props.user.role;
    if (this.props.user.role === 3) {
      param.user_id = this.props.user.id;
    }
    if (this.props.user.role === 4) {
      param.user_id = this.props.user.enterprise_users.enterprise_id;
    }
    param.web = true
    param.user_id = this.props.user.id
    axios
      .post("web/admin/sellers?page=" + this.state.page, param)
      .then(res => {
        if (res.data.sellers) {
          this.setState({
            last_page: res.data.sellers.last_page,
            data: res.data.sellers.data,
            //retentions: res.data.retentions,
            //enterprises: res.data.enterprises
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });

    this.getPermissions()
    
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Inactivo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };
  
  getPermissions = () => {
		axios
			.post("admin/sellers/permissions", {})
			.then(res => {
				this.setState({
					permissions: res.data,
				});
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

  close = () => {
    this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });

    this.load();
  };

  inactiveUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea inactivar a: ${name}?`, () => {
      Users.setStatusInactive({  id: Item.id })
        .then(response => {
          // socket.emit('disable-user',{id: item.id});
          this.load();
          Globals.showSuccess("¡Vendedor inactivado exitosamente!");
        })
        .catch(error => {
          Globals.showError(error);
        });
    });
  };

  activateUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea activar a: ${name}?`, () => {
      Users.setStatusActive({ Id: Item.id })
        .then(response => {
          //socket.emit('activate-user', { id: item.id });
          this.load();
          Globals.showSuccess("¡Vendedor activado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  verifyUser = Item => {
    const { person } = Item;
    const name = person.first_name;

    Globals.confirm(`¿Desea verificar a: ${name}?`, () => {
      Users.setStatusVerify({ Id: Item.id })
        .then(response => {
          this.load();
          Globals.showSuccess("¡Vendedor verificado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar a: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`web/admin/sellers/delete/${item.id}`, { id: item.id })
        .then(res => {
          Globals.showSuccess('Vendedor eliminado');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar al Vendedor multiempresa: " + item.person.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/users/get-user-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.user
            });
            Sidebar.getModules(res.data.user.role).then(response => {
              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: response
              });
              this.props.history.push("/enterprises");
            });
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  render() {
    const {history} = this.props
    const { create, data, edit, header, list_status, search, status, user, permissions, view } = this.state
    // const titleTable = this.props.user.role === 1 ? 'Multiempresas' : 'Vendedores';
    // const userTypeText = this.props.user.role === 1 ? 'Multiempresa' : 'Vendedor';
    const titleTable = 'Vendedores';
    const userTypeText = 'Vendedor';
    return (
      <Menu history={history}>
        {view && (
          <Modal title={`Ver ${userTypeText}`} onClose={this.close} visible>
            <ViewSeller user={user} />
          </Modal>
        )}

        {create && (
          <Modal
            title={edit ? `Editar ${userTypeText}` : `Nuevo ${userTypeText}`}
            onClose={this.close} 
            visible
          >
            <CreateEditSeller edit={edit} onClose={this.close} permissions={permissions} />
          </Modal>
        )}

        <div id="home">
          <div className="row">
            <div className="col-md">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.handleChange}
                value={search}
                placeholder="Buscar por Nombre, Email o Cédula"
              />
            </div>
            {/* <div className="col-md">
              <Select
                color="white"
                name="status"
                label="Status"
                className="filter"
                defaultname="Seleccione"
                onChange={this.handleChange}
                value={status}
                options={list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div> */}
            <div className="col-md">
              <Button 
                className="btn-align-bottom btn-filter" 
                color=" " 
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <Table
            data={data.length}
            title={titleTable}
            header={header}
            right={
              <Button
                title="Agregar Vendedor"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Agregar Vendedor" />
              </Button>
            }
          >
            {data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>{Item.name}</td>
                  <td>{Item.email}</td>
                  <td>{Item.document}</td>
                  <td>{Item.phone}</td>
                  <td>{Item.commission}</td>
                  {/* <td>
                    {Globals.getLevel(Item.level_id)}
                  </td> */}
                  {/* <td style={{ color: this.getStatus(Item.status).color }}>
                    {this.getStatus(Item.status).text}
                  </td> */}
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => {
                        this.setState({
                          user: Item,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      small="true"
                      color=" "
                      style={{backgroundColor: '#0f54e0'}}
                      onClick={() =>
                        this.setState(
                          {
                            edit: {
                              element: Item,
                              index: index
                            }
                          },
                          () => {
                            this.setState({ create: true });
                          }
                        )
                      }
                    >
                      <Icon name="edit" />
                    </Button>

                    {Item.status === Constants.STATUS_INACTIVE && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.activateUser(Item);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}

                    {/* {Item.status === Constants.STATUS_INACTIVE && (
                      <Button
                        color="success"
                        title="Verificar"
                        small="true"
                        onClick={() => {
                          this.verifyUser(Item);
                        }}
                      >
                        <Icon name="user-plus" />
                      </Button>
                    )} */}

                    {/* {Item.status === Constants.STATUS_ACTIVE && (
                      <Button
                        color="warning"
                        title="Inactivar"
                        small="true"
                        style={{backgroundColor: '#d39e00'}}
                        onClick={() => this.inactiveUser(Item, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )} */}

                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(Item, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                    {this.props.user.role === 1 && (
                      <Button
                        color="blue"
                        title="Tomar Datos"
                        small="true"
                        onClick={() => this.set(Item, index)}
                      >
                        <Icon name="user" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.load()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Sellers);
