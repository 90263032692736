import React from "react";

import { axios, Globals, Constants } from "../../utils";
import { Button, Select, Input, Textarea } from "../../components";

// Components
import SelectCustom from "../../components/select";
import { connect } from "react-redux";

class CreateEditProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      form: {},
      textButton: "Crear",
      retention_types: [],
      enterprises: []
    };
  }

  fiscal_docs = {
    document_type: "V",
    document: ""
  };

  componentDidMount() {
    this.getRetentions();
  }

  getRetentions = () => {
    Globals.setLoading();
    const { user } = this.props;
    let param = {};
    param.role = user.role;
    if (user.role === 3) {
      param.user_id = user.id;
    }
    if (user.role === 4) {
      param.user_id = user.enterprise_users.enterprise_id;
    }

    axios
      .post("admin/providers/get?page=1", param)
      .then(res => {
        if (res.data.result) {
          this.setState({
            retention_types: res.data.retentions,
            enterprises: res.data.enterprises,
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  submit = async () => {
    const { user } = this.props;
    let param = { ...this.state.form };
    param.user_id = user.id;
    if (user.role === 3) {
      param.enterprise_id = user.id;
    }
    if (user.role === 4) {
      param.enterprise_id = user.enterprise_users.enterprise_id;
    }
    this.setState({ submitted: true });
    Globals.setLoading();
    axios
      .post("admin/providers/create", param)
      .then(res => {
        if (res.data.result) {
          this.setState({ form: {} });
          this.props.onClose(true);
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        if (err.response?.status === 422) {
          Globals.showError(err.response.data.msg);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        this.setState({ submitted: false });
        Globals.quitLoading();
      });
  };

  change = e => {
    this.setState({
      form: {
        fiscal_document_type: this.fiscal_docs.document_type,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  showSelect = () => {
    var select = null;
    if (this.props.user.role === 1 || this.props.user.role === 2) {
      select = (
        <SelectCustom
          color="white"
          label="Empresa"
          name="enterprise_id"
          defaultname="Seleccione"
          className="material"
          labelClass="material"
          onChange={this.change}
          value={
            this.state.form.enterprise_id ? this.state.form.enterprise_id : ""
          }
          options={this.state.enterprises.map(el => {
            return {
              value: el.id,
              label: el.name
            };
          })}
        />
      );
    }

    return select;
  };

  setSelectedOption = async (value, key) => {
    this.setState({
      form: {
        ...this.state.form,
        [key]: value
      }
    });
    if (key === "fiscal_document_type") this.fiscal_docs.document_type = value;
  };

  render() {
    return (
      <div className="container-create-edit-user">
        {this.showSelect()}
        <div className="row">
          <div className="col-md-12">
            <Input
              color=" "
              value={this.state.form.name}
              name="name"
              label="Nombre"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color=" "
              value={this.state.form.code}
              name="code"
              label="Código"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <Select
                color="white"
                label="Tipo"
                name="fiscal_document_type"
                defaultname="Seleccione"
                className="material"
                labelClass="material"
                onChange={e =>
                  this.setSelectedOption(e.target.value, e.target.name)
                }
                value={
                  this.state.form.fiscal_document_type
                    ? this.state.form.fiscal_document_type
                    : "V"
                }
                options={[
                  {
                    label: "V",
                    value: "V"
                  },
                  {
                    label: "J",
                    value: "J"
                  },
                  {
                    label: "E",
                    value: "E"
                  }
                ]}
              />
              <Input
                color=" "
                value={this.state.form.fiscal_identification}
                name="fiscal_identification"
                label="RIF"
                className="material"
                labelClass="material"
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                maxLength={this.state.form.document_type === "J" ? 11 : 10}
                onChange={this.change}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Select
              color="white"
              label="Tipo de personalidad"
              name="person_type"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              onChange={e =>
                this.setSelectedOption(e.target.value, e.target.name)
              }
              value={
                this.state.form.person_type ? this.state.form.person_type : ""
              }
              options={Constants.TYPE_PERSON}
            />
          </div>
          <div className="col-md-6">
            <Select
              color=" "
              label="Tipo de Retención IVA"
              name="retention_type_id"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              onChange={e =>
                this.setSelectedOption(e.target.value, e.target.name)
              }
              value={
                this.state.form.retention_type_id
                  ? this.state.form.retention_type_id
                  : ""
              }
              options={
                this.state.retention_types
                  ? this.state.retention_types.map(i => {
                      return {
                        value: i.id,
                        label: i.percentage
                      };
                    })
                  : ""
              }
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-md">
            <Textarea
              label="Direccion"
              name="direction"
              value={this.state.form.direction}
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color=" "
              value={this.state.form.phone}
              name="phone"
              label="Teléfono"
              className="material"
              labelClass="material"
              maxLength={11}
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <Input
              color=" "
              value={this.state.form.email}
              name="email"
              type="email"
              label="E-Mail"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Input
              type="number"
              color=" "
              value={this.state.form.time_limit}
              name="time_limit"
              label="Plazo de Credito"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-sm">
            {this.state.submitted ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            ) : (
              <div id="button" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Button
                  type="button"
                  color="white"
                  onClick={() => this.props.onClose(false)}
                >
                  <span>Ir atrás</span>
                </Button>
                <Button
                  type="button"
                  color=" "
                  className="primary"
                  onClick={this.submit}
                >
                  <span>{this.state.textButton}</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditProvider);
