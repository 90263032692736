import React from "react";

const Input = props => (
  <div className="form-group">
    {props.label && <label htmlFor={props.name} className={props?.labelClass}>{props.label} {props.icon}</label>}
    <input
      {...props}
      type={props.type ? props.type : "text"}
      className={`form-control${props.size === "small" ? "-sm" : ""} ${
        props.color ? "input-" + props.color : "input-white"
      } ${props.error ? props.error : ""} ${props.className ? props.className : ""}`}
      name={props.name}
      autoComplete="off"
    />
    {props.rightIcon}
    {props.invalidfeedback}
  </div>
);

export default Input;
