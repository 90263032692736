import React, { Component } from 'react';
import Menu from '../menu';

class EmptyHome extends Component {
  render() {
    return (
			<Menu history={ this.props.history } />
    )
  }
}

export default EmptyHome;