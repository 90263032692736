import React from "react";
import { connect } from "react-redux";
import { Globals } from "../../utils";
import { Button, Input, InputFormat, Select } from "../../components";
import { CashCount as CC } from "../../services";

const INITIAL_FORM = {
  creator_id: '',
  caja_id: '',
  withdraw: true,
  observations: '',
  leave_input: '',
  out_amount: '',
  balance: 0,
  current_amount: 0,
};

const CASH_COUNT_TYPE = {
  CLOSSING: 1,
  WITHDRAWAL: 2,
};

class ManageCashCount extends React.Component {
  constructor(props) {
    super(props);

    INITIAL_FORM.creator_id = this.props.user.id;

    this.state = {
      submitted: false,
      viewResume: this.props.visibleResume || false,
      readonly: this.props.readonly || false,
      code: '0001',
      form: { ...INITIAL_FORM },
      data: {
        entradas: 0,
        orders: [],
        last_clossing: null,
        payments: {
          efectivo: 0,
          debito: 0,
          transferencia: 0,
          divisas: 0,
          pago_movil: 0
        }
      },
      cashRegisters: this.props.cashRegisters || [],
    };
  }

  componentDidMount() {
    if (!!this.props?.item?.caja_id){
      this.getItemDetails(this.props.item.caja_id, this.props.item.id);
    }
    else{
      this.getDataByCR(this.props.user.id);
    }
  }

  getItemDetails = (caja_id, cierre_id) => {
    CC.getDetails({ caja_id }, cierre_id)
      .then(async (res) => {
        await this.setState({ data: res });
      })
      .catch((err) => {
        console.log(err, 'err')
        Globals.showError()
      });
  }

  getDataByCR = (caja_id) => {
    CC.getDataByCaja({ caja_id })
      .then(async (res) => {
        const code = String(Number(res.last_clossing?.code || 0) + 1).padStart(4, '0');

        await this.setState(s => ({
          data: res,
          code,
          form: {
            ...INITIAL_FORM,
            caja_id,
            entry_amount: res.entradas,
          }
        }));
        this.calculate(true);
      })
      .catch((err) => {
        console.log(err, 'err')
        Globals.showError()
      });
  }

  calculate = async (initial = false) => {
    const { data, form } = this.state;
    const lastBalance = parseFloat(data?.last_clossing?.current_amount || 0);
    const fullBalance = parseFloat((data?.entradas || 0) + lastBalance);
    const outAmount = parseFloat(form.out_amount || 0);

    let balance = parseFloat(fullBalance - outAmount);

    if (initial) {
      // En primera instancia la salida = saldo total (withdraw = true = Retirar todo)
      await this.setState({ form: { ...form, out_amount: balance } });
      this.calculate();
    } else {
      this.setState({
        form: {
          ...form,
          balance: Globals.formatMiles(balance),
          current_amount: balance,
        }
      });
    }
  }

  handleChange = async (emitter) => {
    if (this.state.readonly) return;
    const { name, value } = emitter.target;

    switch (name) {
      case 'caja_id':
        this.setState(state => ({ form: { ...state.form, [name]: value } }));
        this.getDataByCR(value);
        break;

      case 'out_amount':
        await this.setState(state => ({ form: { ...state.form, [name]: value } }));
        this.calculate();
        break;

      case 'withdraw_all':
        await this.setState(state => ({ form: { ...state.form, withdraw: true } }));
        this.calculate(true);
        break;

      case 'withdraw_partial':
        await this.setState(state => ({ form: { ...state.form, withdraw: false, out_amount: '' } }));
        this.calculate();
        break;

      default:
        await this.setState(state => ({ form: { ...state.form, [name]: value } }));
        break;
    }
  };

  movementResume = (view) => {
    this.props.viewResume(view);
    this.setState({ viewResume: view })
  }

  submit = (type = CASH_COUNT_TYPE.CLOSSING) => {
    const { form } = this.state;
    if (!form.caja_id) return Globals.showError('Debe seleccionar una caja');

    this.setState({ submitted: true });

    const data = {
      ...form,
      observations: form.observations || null,
    };

    CC.createCashCount(data, type)
      .then(res => {
        console.log(res);
        if (!res?.id) throw res;
        Globals.showSuccess("Cierre creado exitosamente!");
        this.props.onClose(true);
      })
      .catch((err) => {
        console.log(err, 'err')
        Globals.showError(err.error ? err.error : 'Error')
      })
      .finally(() => this.setState({ submitted: false }));
  }

  render() {

    const { cashRegisters, code, data, form, readonly, submitted, viewResume } = this.state;

    return (
      viewResume ? (
        <div className="manage-cash-count">
          <div className="title mb-3">Total ventas: {Globals.formatMiles(data.entradas)}</div>
          {!!this.props?.item && (
            <div className="title mb-3">
              Total salida: {Globals.formatMiles(this.props?.item?.out_amount)}
            </div>
          )}
          <div className="resume-item">
            <div>Efectivo</div>
            <div>{Globals.formatMiles(data.payments.efectivo)}</div>
          </div>
          <div className="resume-item">
            <div>Tarjeta débito</div>
            <div>{Globals.formatMiles(data.payments.debito)}</div>
          </div>
          <div className="resume-item">
            <div>Transferencia</div>
            <div>{Globals.formatMiles(data.payments.transferencia)}</div>
          </div>
          <div className="resume-item">
            <div>Divisas</div>
            <div>{Globals.formatMiles(data.payments.divisas)}</div>
          </div>
          <div className="resume-item no-border">
            <div>Pago móvil</div>
            <div>{Globals.formatMiles(data.payments.pago_movil)}</div>
          </div>

          <hr />

          {/* <div className="title mb-3">Total en caja: 0,00</div>
          <div className="resume-item">
            <div>Ventas</div>
            <div className="border">0,00</div>
          </div>
          <div className="resume-item">
            <div>Entrega de cambio</div>
            <div className="border">0,00</div>
          </div>
          <div className="resume-item">
            <div>Gastos de caja</div>
            <div className="border">0,00</div>
          </div>
          <div className="resume-item no-border">
            <div>Retiros de caja</div>
            <div className="border">0,00</div>
          </div>

          <hr />

          <div className="resume-item">
            <div className="font-weight-bold">Total pendiente</div>
            <div>0,00</div>
          </div> */}

          <div className="row mt-2">
            <div className="col-md">
              <Input
                color=" "
                name="observations"
                label="Observaciones"
                labelClass="material"
                className="material"
                value={readonly ? data.last_clossing?.observations : form.observations}
                onChange={this.handleChange}
              />
            </div>
          </div>

          <div className="row d-flex justify-content-center mt-4">
            {!readonly && (
              <div id="button" style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
                <Button
                  type="button"
                  color=" "
                  className="primary"
                  onClick={() => this.movementResume(false)}
                >
                  <span>Aceptar</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="manage-cash-count">
          <div className="subtitle">N° de cierre {code}</div>

          <div className="row">
            <div className="col-md">
              {/* <Select
                color="white"
                label="N° de Caja"
                name="caja_id"
                labelClass="material"
                className="material"
                options={cashRegisters}
                value={form.caja_id}
                onChange={this.handleChange}
              /> */}
              <div className="title">
                {`${this.props.user.person.first_name || ''} ${this.props.user.person.last_name || ''}`}
              </div>
            </div>
          </div>

          <div className="last-cash-count">
            Último cierre: {Globals.formatMiles(data.last_clossing?.current_amount || 0)}
          </div>

          <hr />

          <div className="row">
            <div className="col-4">
              <div className="title">Entradas</div>
              <div className="cash-in">{data.entradas}</div>
            </div>
            <div className="col-4 text-center">
              <div className="title">Salida</div>
              <div className={`cash-out ${(!form.out_amount && 'zero')}`}>
                {form.out_amount || '00'}
              </div>
            </div>
            <div className="col-4 text-right">
              <div className="title">Saldo</div>
              <div className="cash-balance">{form.balance}</div>
            </div>
          </div>

          <hr />

          <div className="title mb-1">Retirar antes de cerrar</div>
          <div className="withdraw-before">
            <input
              id="withdraw_all"
              type="checkbox"
              name="withdraw_all"
              checked={form.withdraw}
              onChange={this.handleChange}
            />
            <label htmlFor="withdraw_all">Retirar todo</label>
          </div>

          <div className="withdraw-before">
            <input
              id="withdraw_partial"
              type="checkbox"
              name="withdraw_partial"
              checked={!form.withdraw}
              onChange={this.handleChange}
            />
            <label htmlFor="withdraw_partial">Retiro parcial</label>
          </div>

          <div className="withdraw mt-2">
            <InputFormat
              color=" "
              name="out_amount"
              thousandSeparator={true}
              allowNegative={false}
              isNumericString={true}
              decimalScale="2"
              placeholder="Monto"
              label={form.withdraw ? 'Retirar todo' : 'Retiro parcial'}
              labelClass="material"
              customClass="material"
              disabled={form.withdraw}
              value={form.out_amount}
              onChange={this.handleChange}
            />
          </div>

          {/* <div className="row mx-1">
            <div className="mr-2">Dejar</div>
            <div className="d-flex">
              <InputFormat
                color=" "
                name="leave_input"
                thousandSeparator={true}
                allowNegative={false}
                isNumericString={true}
                decimalScale="2"
                placeholder="00"
                value={form.leave_input}
                disabled={form.withdraw}
                onChange={this.handleChange}
              />
              <div className="input-suffix">Bs/USD</div>
            </div>
          </div> */}

          <div className="mb-4">
            <span className="link" onClick={() => this.movementResume(true)}>
              Ver resumen de movimientos
            </span>
          </div>

          <div className="row d-flex justify-content-center">
            {submitted ? (
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div id="button" style={{ display: 'flex', flex: 1, justifyContent: 'space-evenly' }}>
                <Button
                  color=" "
                  type="submit"
                  className="primary"
                  onClick={() => this.submit()}
                >
                  <span>Cerrar caja</span>
                </Button>

                <Button
                  color=" "
                  type="submit"
                  className="secondary"
                  onClick={() => this.submit(CASH_COUNT_TYPE.WITHDRAWAL)}
                >
                  <span>Continuar</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ManageCashCount);
