import React from "react";
import { Button, Input, Select } from "../../../components";
import { Globals, Constants } from "../../../utils";
import { Bank } from "../../../services";

class CreateEditBankAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        id: "",
        account_number: "",
        type: "",
        amount: "",
        bank_id: ""
      },
      textButton: "Crear",
      errors: [],
      created_at: "",
      updated_at: "",
      bankType: ""
    };
  }
  componentDidMount() {
    const { bank, bankId } = this.props
    Bank.showBank({ bankId: bankId })
      .then(({type}) => {
        this.setState({
          bankType: type
        })
      })
    if (bank) {
      this.loadData(bank)
    }
  }

  change = emitter => {
    const { name, value } = emitter.target;
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  loadData = async (bank) => {
    await this.setState({
      form: {
        ...bank
      },
      textButton: "Editar"
    });
  }

  submit = () => {

    this.setState({ submitted: true, errors: [] });

    const { bank, bankId, user } = this.props;
    const { form } = this.state;
    const bankFunction = bank ? Bank.updateBankAccount(form.id, { ...form, bank_id: bankId, user_id: user.id }) :
      Bank.newBankAccount({ ...form, bank_id: bankId, user_id: user.id });
    bankFunction
      .then(response => {
        const { account_number } = response
        const messageSuccess = bank ? `¡Cuenta bancaria ${account_number} modificado exitosamente!` :
          `¡Cuenta bancaria ${account_number} creado exitosamente!`
        Globals.showSuccess(messageSuccess);
        this.props.onClose();
      })
      .catch(error => {
        if ((error || {}).message) {
          Globals.showError(error.message);
        }

        if (error.hasOwnProperty("msg")) {
          Globals.showError(error.msg);
        }

        this.setState({ submitted: false, errors: error });
      });
  }

  hasErrorFor(field) {
    const { errors } = this.state
    return !!errors[field];
  }

  renderErrorFor(field) {
    const { errors } = this.state
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { form, submitted } = this.state;
    const { created_at, updated_at } = this.props.bank || {}
    console.log(this.state.bankType)
    return (
      <div className="text-center container-create-edit-user">
        <div className="row">
          <div className="col-md-12">
            <Input
              color="gray"
              value={form.account_number}
              name="account_number"
              label="Nombre/Nº de Cuenta"
              onChange={this.change}
              error={`${
                this.hasErrorFor("account_number") ? "is-invalid" : ""
                }`}
              invalidfeedback={this.renderErrorFor("account_number")}
            />
          </div>
          <div className="col-md-12">
            <Select
              color="gray"
              name="type"
              label="Tipo de Cuenta"
              defaultname="Seleccione"
              disabledFirst="false"
              onChange={this.change}
              value={form.type}
              options={this.state.bankType == 1 ? [Constants.TYPE_BANK_ACCOUNTS[0],Constants.TYPE_BANK_ACCOUNTS[1]] : [Constants.TYPE_BANK_ACCOUNTS[2]]}
              error={`${
                this.hasErrorFor("type") ? "is-invalid" : ""
                }`}
              invalidfeedback={this.renderErrorFor("type")}
            />
          </div>
          {
            (((!form.id) || (form.type == 3 && created_at === updated_at)) &&
              <div className="col-md-12">
                <Input
                  color="gray"
                  value={form.amount}
                  name="amount"
                  label="Capital Inicial"
                  onChange={this.change}
                  onKeyPress={e => {
                    Globals.soloNumeros(e);
                  }}
                  error={`${
                    this.hasErrorFor("amount") ? "is-invalid" : ""
                    }`}
                  invalidfeedback={this.renderErrorFor("amount")}
                  maxLength={20}
                />
              </div>)
          }
        </div>
        <div id="button">
          <Button
            submitted={
              submitted ? submitted : undefined
            }
            block="true"
            type="button"
            onClick={() => this.submit()}>
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}
export default CreateEditBankAccount;