import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  DatePicker,
  Input,
  Modal,
  Icon
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import PrinterIcon from '../../assets/icons/printer.png';
import ModalCreatePayment from "./modal-create-payment";
import AddIcon from '../../assets/icons/add.png';

class Commisions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleAddPayment: false,
      total: 0,
      header: [
        'Fecha de Pedido', 
        'N° Pedido', 
        'Vendedor', 
        'Gerente', 
        'Cliente', 
        'Monto Total Venta', 
        'Monto Abonado Venta',
        'Comisión por Pagar (Vendedor)', 
        'Comisión Pagada (Vendedor)', 
        'Comisión por Pagar (Gerente)',
        'Comisión Pagada (Gerente)',
        ''
      ],
      page: 1,
      last_page: 1,
      data: [],
      sellers: [],
      managers: [],
      form: {
        client_search: "",
        seller_id: "",
        manager_id: "",
        search: "",
        since: "",
        until: "",
        user_id: this.props.user.id,
        _seller_id: '',
        order_search: ''
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      comissions_managers: 0,
      commissions_sellers: 0,
      pagar_managers: 0,
      pagar_sellers: 0,
      modal: {
        paid_amount: '',
        commission_to_pay: '',
      },
      conversion: 0
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();

    this.getSellers()
    this.getManagers()
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (page = 1) => {
    axios
      .post('web/admin/comissions', {
        web: true,
        user_id: this.props.user.id,
        until: this.state.form.until && moment(this.state.form.until,'DD-MM-YYYY').format('YYYY-MM-DD'),
        since: this.state.form.since && moment(this.state.form.since,'DD-MM-YYYY').format('YYYY-MM-DD'),
        currency: this.props.currency,
        page,
        status: [
          Constants.ORDER_STATUS.PENDING,
          Constants.ORDER_STATUS.PROCESSED,
        ],
        seller_id: this.state.form.seller_id,
        _seller_id: this.state.form._seller_id,
        manager_id: this.state.form.manager_id,
        client_search: this.state.form.client_search,
        order_search: this.state.form.order_search
      })
      .then(res => {
        console.log(res)
        this.setState({
          page,
          data: res.data.orders.data,
          //sellers: res.data.sellers,
          //managers: res.data.managers,
          last_page: res.data.orders.last_page,
          comissions_managers: res.data.totales?.managers?.total_commission || 0,
          commissions_sellers: res.data.totales?.sellers?.total_commission || 0,
          pagar_managers: res.data.totales?.managers?.total_pagar || 0,
          pagar_sellers: res.data.totales?.sellers?.total_pagar || 0,
        });
      })
      .catch(err => {
        console.log('Commissions -> load -> catch: ', err);
      });
  };

  getSellers = async () => {
    await axios
      .post('web/admin/orders/sellers', {
        ...this.state.form,
        is_select: true,
        not_admin: true,
      })
      .then(res => {
        this.setState({
          sellers: res.data.sellers,
          conversion: res.data.conversion?.amount || 0
        });
      })
      .catch(err => {
        console.log('Commissions -> getSellers -> catch: ', err);
      });
  };

  getManagers = async () => {
    await axios
      .post('web/admin/orders/managers', {
        ...this.state.form,
      })
      .then(res => {
        this.setState({
          managers: res.data.managers
        });
      })
      .catch(err => {
        console.log('Commissions -> getManagers -> catch: ', err);
      });
  };

  change = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        client_search: "",
        search: "",
        user_id: "",
        seller_id: "",
        manager_id: "",
        _seller_id: '',
        order_search: ''
      },
      thead: [],
      trows: [],
    }));
    await this.load();
  };

  print = () => {
    axios
      .post('web/admin/comissions/download', {
        ...this.state.form,
        user_id: this.props.user.id,
        web: true,
      })
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  }

  togglePaymentModal = () => {
    this.setState(state => ({ visibleAddPayment: !state.visibleAddPayment }));
    this.load()
  };

  render() {
    const { visibleAddPayment } = this.state;

    return (
      <Menu history={this.props.history}>
        <Modal
          title="Agregar Pago de Comisión"
          onClose={() => this.togglePaymentModal()}
          visible={visibleAddPayment}
        >
          <ModalCreatePayment
            managers={this.state.managers}
            sellers={this.state.sellers}
            onClose={() => this.togglePaymentModal()}
            conversion={ this.state.conversion }
          />
        </Modal>

        <div id="commissions" className="new-reports">
          <div className="row">
            <div className="col-md-2">
              <Input
                color=" "
                name="client_search"
                label="Cliente"
                className="filter"
                onChange={this.change}
                value={this.state.form.client_search}
                placeholder="Buscar por cliente"
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="_seller_id"
                label="Vendedor/Gerente"
                className="filter"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={this.state.form._seller_id}
                options={
                  [{
                    label: 'Vendedores',
                    optgroup: true,
                    options: this.state.sellers.map((Item) => {
                      return {
                        value: Item.id,
                        label: Item.name
                      }
                    })
                  },
                  {
                    label: 'Gerentes',
                    optgroup: true,
                    options: this.state.managers.map((Item) => {
                      return {
                        value: Item.id,
                        label: Item.name
                      }
                    })
                  }]
                }
              />
            </div>
            <div className="col-md-2">
              <Input
                color=" "
                name="order_search"
                label="ID de pedido"
                className="filter"
                onChange={this.change}
                value={this.state.form.order_search}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <Button
                color="blue"
                className="btn-align-bottom btn-primary btn-filter"
                style={{ minWidth: 'max-content' }}
                onClick={() => this.props.history.push('/commissions/payments')}
              >
                <span style={{ color: "white" }}>Ver Pagos</span>
              </Button>
            </div>
            <div className="col-md-3">
              <div className="container-buttons">
                <Button className="btn-filter blue" color=" " onClick={() => this.load()}>
                  <span>Filtrar</span>
                </Button>
                <Button className="btn-filter" color=" " onClick={this.returnState}>
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>
          <div className="row" style={{ maxWidth: '600px', margin: '0 auto', marginBottom: '20px', marginTop: '20px' }}>
            <div className="col-md-6 total-amount">
              <div className="container-total blue">
                <span className="label">Gerente</span>
                <div className="totals">
                  <span>Total:</span>
                  <span style={{ color: '#fff' }}>$ {Globals.formatMiles(this.state.comissions_managers)} </span>
                  <span>Por Pagar:</span>
                  <span style={{ color: '#fff' }}>$ {Globals.formatMiles(this.state.pagar_managers)}</span>
                </div>
              </div>
            </div>
            <div className="col-md-6 total-amount">
              <div className="container-total cyan">
                <span className="label">Vendedor</span>
                <div className="totals">
                  <span>Total:</span>
                  <span>$ {Globals.formatMiles(this.state.commissions_sellers)}</span>
                  <span>Por Pagar:</span>
                  <span>$ {Globals.formatMiles(this.state.pagar_sellers)}</span>
                </div>
              </div>
            </div>
          </div>

          <Table
            data={this.state.data.length}
            title="Comisiones"
            header={this.state.header}
            // right={
            //   <div>
            //     <Button
            //       title="Imprimir"
            //       small="true"
            //       onClick={() => this.print()}
            //     >
            //       <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
            //     </Button>
            //   </div>
            // }
            right={
              <div>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print()}
                >
                  <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                </Button>
                <Button
                  title="Agregar pago"
                  small="true"
                  onClick={() => this.togglePaymentModal()}
                  disabled={!this.state.sellers.length}
                >
                  <img src={AddIcon} style={{ width: 24 }} alt="Agregar pago" />
                </Button>
              </div>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{ moment(i.created_at).format('DD-MM-YYYY') }</td>
                  <td>{ String(i.correlative_number).padStart(4,'0') }</td>
                  <td>{ i?.seller?.name }</td>
                  <td>{ i.seller?.managers[0]?.name }</td>
                  <td>{ i?.client?.name }</td>
                  <td>{ Globals.formatMiles(i.total, true) }</td>
                  <td> 
                    { Globals.formatMiles(i.partial_payment_total, true) } 
                  </td>
                  <td>{ Globals.formatMiles(i.total_commission_to_pay.seller, true) }</td>
                  <td>{ Globals.formatMiles(i.total_commission_paid_seller) }</td>
                  <td>{ Globals.formatMiles(i.total_commission_to_pay.manager, true) }</td>
                  <td>{ Globals.formatMiles(i.total_commission_paid_manager) }</td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => {
                        this.props.history.push({
                          pathname: '/commissions/payments',
                          state: {
                            order_id: i.id,
                            withoutFilters: true
                          }
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              this.load(page);
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(Commisions);
