/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component, useEffect, useState } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import LocationPinIcon from '../../assets/icons/location-pin.png';
import Menu from '../menu';
import { Button, DatePicker, Modal, Pagination, Select } from '../../components';
import { axios, Constants, ENV, Globals } from '../../utils';

const perPage = 10;

const TAB = {
  ROUTE: 1,
  WITHOUT_ZONES: 2,
  VISIT_REPORT: 3,
}

const INITIAL_FORM = {
  date: '',
  zone: '',
  status: '',
}

// @ts-ignore
const google = window.google;

class VisitUnplannedDetails extends Component {

  state = {
    tab: TAB.ROUTE,
    visibleZoneModal: false,
    selectedItem: null,
    form: { ...INITIAL_FORM },
    zones: [],
  }

  componentDidMount() {
    const zones = this.props.zones.map(x => ({ label: x.name, value: x.id }));
    this.setState({ zones });
  }

  changeTab = (tab) => {
    this.setState({ tab });
  }

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  }

  toggleZoneModal = (item = null) => {
    const selectedItem = !!item ? { ...item, zone_id: null } : null;
    this.setState({ visibleZoneModal: !!item, selectedItem });
  }

  saveClientZone = () => {
    if (!this.state.selectedItem?.zone_id)
      return Globals.showError('Debe seleccionar una zona');

    const form = {
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      ...this.state.selectedItem,
    }

    Globals.setLoading();
    axios
      .post(`web/admin/clients/edit/${form.id}`, form)
      .then(res => {
        if (res.data.result) {
          Globals.showSuccess(res.data.msg);
          this.toggleZoneModal();
          this.props.onRefresh();
        }
      })
      .catch(err => {
        let message = "Disculpe, ha ocurrido un error";
        if (err.response.status === 422) {
          message = err.response.data.error;
        }
        Globals.showError(message);
      })
      .then(() => {
        Globals.quitLoading();
      });
  }

  resetForm = () => {
    this.setState({
      form: {
        date: '',
        zone: '',
        status: '',
      }
    });
  }

  isFormDirty = () => {
    const initial = JSON.stringify(INITIAL_FORM);
    const form = JSON.stringify(this.state.form);
    return initial !== form;
  }

  render() {

    const { tab, visibleZoneModal } = this.state;
    const { item } = this.props;

    return (
      <Menu
        history={this.props.history}
        leftSlot={<BackButton onClick={() => this.props.goBack()} />}
      >
        {!!visibleZoneModal && (
          <Modal
            className="modal-planner-zone"
            title="Asignar zona al cliente"
            onClose={() => this.toggleZoneModal()}
            visible
          >
            <div style={{ marginBottom: '2rem' }}>
              <Select
                color="white"
                label="Zona"
                className="material"
                labelClass="material"
                disabledFirst={false}
                value={this.state.selectedItem?.zone_id ?? ''}
                onChange={e => this.setState({ selectedItem: { ...this.state.selectedItem, zone_id: e.target.value } })}
                options={this.state.zones.slice(1)}
              />
            </div>

            <Button
              type="submit"
              color=" "
              className="primary"
              block
              onClick={this.saveClientZone}
            >
              <span>Guardar</span>
            </Button>
          </Modal>
        )}

        <div id="visit-unplanned-details">
          <h5 className="pb-2">{ item?.seller?.name }</h5>
          <section className="actions">
            <div className="filters" style={{ alignItems: 'flex-end' }}>
              {tab === TAB.VISIT_REPORT && (
                <Select
                  color="white"
                  label="Zona"
                  className="filter"
                  labelClass="filter"
                  value={this.state.form.zone}
                  onChange={e => this.change(e.target.value, 'zone')}
                  options={[
                    { value: 'x', label: 'Sin Zona' },
                    ...this.state.zones,
                  ]}
                />
              )}
              {/* <DatePicker
                color="white"
                label="Fecha"
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              /> */}
              {this.isFormDirty() && (
                <small
                  style={{ padding: '0.75rem 0', cursor: 'pointer' }}
                  onClick={this.resetForm}
                >
                  Limpiar filtros
                </small>
              )}
            </div>
            <div className="views">
              <Tab
                label="Ruta"
                isSelected={tab === TAB.ROUTE}
                onClick={() => this.changeTab(TAB.ROUTE)}
              />
              <Tab
                label="Clientes sin zonas"
                isSelected={tab === TAB.WITHOUT_ZONES}
                onClick={() => this.changeTab(TAB.WITHOUT_ZONES)}
              />
              <Tab
                label="Informes de visita"
                isSelected={tab === TAB.VISIT_REPORT}
                onClick={() => this.changeTab(TAB.VISIT_REPORT)}
              />
            </div>
          </section>

          <section className="tab-content">
            {tab === TAB.ROUTE && (
              <TabRoute seller={item.seller} details={item.details} />
            )}
            {tab === TAB.WITHOUT_ZONES && (
              <TabWithoutZones
                clients={item?.clients_without_zone ?? []}
                form={this.state.form}
                openZoneModal={this.toggleZoneModal}
              />
            )}
            {tab === TAB.VISIT_REPORT && (
              <TabVisitReport form={this.state.form} details={item?.details} />
            )}
          </section>
        </div>
      </Menu>
    )
  }
}

const TabRoute = ({ seller, details }) => {
  const [selectedItem, setSelectedItem] = useState();
  const [points, setPoints] = useState([]);

  const setMap = async () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { 
        strokeColor: "#07a043",
        strokeWeight: 5
      }
    });

    if (points?.length > 0) {
      const map = new google.maps.Map(document.getElementById('map-planner-view'), {
        zoom: 13,
        center: new google.maps.LatLng(points[0]?.latitude,points[0]?.longitude),
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false
      });

      var waypoints = [];

      for (let i = 0; i < points.length; i++) {
        if (i !== 0 && i !== (points.length - 1)) {
          waypoints.push({
            location: new google.maps.LatLng(points[i].latitude,points[i].longitude),
            stopover: true
          });
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(points[i].latitude,points[i].longitude),
          map,
          title: '',
          icon: ENV.BasePublic + 'img/marker.png',
          animation: google.maps.Animation.DROP,
          draggable: false
        });

        google.maps.event.addListener(marker, 'click', () => {
          setSelectedItem(points[i])
        });
      }

      if (points.length > 1) {
        const request = {
          origin: new google.maps.LatLng(points[0].latitude,points[0].longitude),
          destination: new google.maps.LatLng(points[points.length - 1].latitude,points[points.length - 1].longitude),
          waypoints,
          optimizeWaypoints: true,
          travelMode: google.maps.DirectionsTravelMode.DRIVING
        };

        directionsDisplay.setMap(map);
        directionsService.route(request, (response, status) => {
          if (status === google.maps.DirectionsStatus.OK) {
            directionsDisplay.setDirections(response);
          }
        });
      }

      if (!!points.length) setSelectedItem(points[0]);
    }
  }

  useEffect(() => {
    const _points = details
      ?.filter(item => {
        const hasLat = item?.client?.latitude != null;
        const hasLng = item?.client?.longitude != null;
        return hasLat && hasLng;
      })
      .map((item) => ({
        ...item,
        latitude: parseFloat(item?.client?.latitude),
        longitude: parseFloat(item?.client?.longitude),
      }));

    setPoints(_points);
  }, []);

  useEffect(() => {
    setMap();
  }, [points]);

  const keys = ['id'];
  const zones = [...(seller?.zones ?? []),seller?.zone]
    .filter(i => !!i)
    .filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    );

  return (
    <div className="tab-route">
      <div className="map">
        <div id="map-planner-view" />
      </div>
      <div className="modal-planer-view">
        <p className="title">Vendedor</p>
        <h5 className="seller">{ seller?.name }</h5>
        {selectedItem != null && (
          <p className="name">{ moment(selectedItem?.created_at).format('DD-MM-YYYY') }</p>
        )}
        <p className="zones">{ zones.map(i => i.name).filter(i => i != null).join(', ') }</p>

        {selectedItem != null && (
          <React.Fragment>
            <p className="gray">Cliente</p>
            <div className="row">
              <div className="col-md-6">
                <p className="name">{ selectedItem?.client?.name }</p>
                <p className="location">
                  <img src={LocationPinIcon} alt="Ubicación" />
                  { selectedItem?.client?.address }
                </p>
              </div>
              <div className="col-md-6">
                <p>{ selectedItem?.client?.phone }</p>
                <p>{ selectedItem?.client?.email }</p>
              </div>
            </div>

            {!!selectedItem?.visit && (
              <>
                <p className="blue">Última visita</p>
                <div className="row">
                  <div className="col-md-3">
                    <p className="gray">Fecha</p>
                    <p>{ selectedItem?.init_date ? moment(selectedItem?.init_date).format('DD-MM-YYYY') : '-' }</p>
                  </div>
                  <div className="col-md-3">
                    <p className="gray">Hora de Inicio</p>
                    <p>{ selectedItem?.init_hour ? moment(selectedItem?.init_hour,'HH:mm:ss').format('HH:mm a') : '-' }</p>
                  </div>
                  <div className="col-md-3">
                    <p className="gray">Hora de fin</p>
                    <p>{ selectedItem?.finish_hour ? moment(selectedItem?.finish_hour,'HH:mm:ss').format('HH:mm a') : '-' }</p>
                  </div>
                  <div className="col-md-3">
                    <p className="gray">Resultado de la visita</p>
                    {
                      selectedItem?.visit?.results?.map((item) => {
                        return (
                          <p className="result-visit">{ item.name }</p>
                        )
                      })
                    }
                  </div>
                </div>
                <p className="gray">Observación</p>
                <p className="comments">{ selectedItem?.visit?.comments || '-' }</p>
                <p className="blue">Próxima visita</p>
                <p className="date">{ moment(selectedItem?.visit?.next_date).format('DD-MM-YYYY') }</p>
                <p className="container-check">
                  <div className={ `check ${ selectedItem?.visit?.remember ? 'active' : '' }` }></div>
                  Recordatorio
                </p>
              </>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  )
}

const TabWithoutZones = ({ clients, form, openZoneModal }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 0,
  })

  const paginate = (page) => {
    setPagination(s => ({ ...s, page }));
  }

  const getItems = () => {
    let _clients = Globals.copy(clients);

    if (form.since) {
      _clients = _clients.filter(x => {
        return moment(x.created_at).format('YYYY-MM-DD') === moment(form.since).format('YYYY-MM-DD')
      });
    }

    if (isNaN(form.zone)) {
      _clients = _clients.filter(x => !x.client?.zone_id);

    } else if (form.zone > 0) {
      _clients = _clients.filter(x => x.client?.zone_id === Number(form.zone));
    }

    const first = perPage * (pagination.page - 1);
    const last = perPage * pagination.page;
    return _clients?.slice(first, last) || [];
  }

  useEffect(() => {
    setPagination({
      page: 1,
      lastPage: Math.ceil((clients?.length ?? 0) / perPage),
    });
  }, [clients]);

  return (
    <div className="tab-new-visited table-wrapper">
      <table className="table">
        <thead className="header">
          <tr>
            <td>Cliente</td>
            <td>Fecha</td>
            <td>Dirección</td>
            <td>Teléfono</td>
            <td>Correo</td>
            <td>Zona</td>
          </tr>
        </thead>
        <tbody className="body">
          {getItems()?.map((client, index) => (
            <tr key={index}>
              <td>{ client?.name }</td>
              <td>{ moment(client?.created_at).format('DD-MM-YYYY') }</td>
              <td><div>{ client?.address }</div></td>
              <td>{ client?.phone }</td>
              <td>{ client?.email }</td>
              <td>
                <span style={{ color: 'red' }}>Sin zona</span>
                <img
                  src={LocationPinIcon}
                  onClick={() => openZoneModal(client)}
                  className="location-pin ml-2"
                  alt="Dirección"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr />

      <Pagination
        pages={pagination.lastPage}
        active={pagination.page}
        onChange={paginate}
      />
    </div>
  )
}

const TabVisitReport = ({ details, form }) => {
  const [pagination, setPagination] = useState({
    page: 1,
    lastPage: 0,
  })

  const paginate = (page) => {
    setPagination(s => ({ ...s, page }));
  }

  const getItems = () => {
    let _clients = Globals.copy(details);

    if (form.since) {
      _clients = _clients.filter(x => {
        return moment(x.created_at).format('YYYY-MM-DD') === moment(form.since).format('YYYY-MM-DD')
      });
    }

    if (isNaN(form.zone)) {
      _clients = _clients.filter(x => !x.client?.zone_id);

    } else if (form.zone > 0) {
      _clients = _clients.filter(x => x.client?.zone_id === Number(form.zone));
    }

    const first = perPage * (pagination.page - 1);
    const last = perPage * pagination.page;
    return _clients?.slice(first, last) || [];
  }

  useEffect(() => {
    setPagination({
      page: 1,
      lastPage: Math.ceil((details?.length ?? 0) / perPage),
    });
  }, [details]);

  return (
    <div className="tab-visit-report table-wrapper">
      <table className="table">
        <thead className="header">
          <tr>
            <td>Cliente</td>
            <td>Dirección</td>
            <td>Inicio</td>
            <td>Fin</td>
            <td>Duración (Minutos)</td>
            <td>Resultado de visitas</td>
            <td>Observación</td>
            <td>Próxima visita</td>
          </tr>
        </thead>
        <tbody className="body">
          {getItems()?.map((visit, index) => (
            <tr key={index}>
              <td>{ visit?.client?.name }</td>
              <td>{ visit?.client?.address }</td>
              <td>{ visit?.init_hour ? moment(visit?.init_hour,'HH:mm:ss').format('HH:mm a') : '-' }</td>
              <td>{ visit?.finish_hour ? moment(visit?.finish_hour,'HH:mm:ss').format('HH:mm a') : '-' }</td>
              <td>
                {(visit?.init_hour && visit?.finish_hour)
                  ? moment(visit?.finish_hour,'HH:mm:00').diff(moment(visit?.init_hour,'HH:mm:00'), 'minutes')
                  : '-'
                }
              </td>
              <td>{ visit?.visit?.results?.map((item) => item.name).join(', ') }</td>
              <td>{ visit?.visit?.comments ?? '-' }</td>
              <td>{ (visit?.visit?.next_date ? moment(visit?.next_date).format('DD/MM/YYYY') : '-') }</td>
            </tr>
          ))}
        </tbody>
      </table>

      <hr />

      <Pagination
        pages={pagination.lastPage}
        active={pagination.page}
        onChange={paginate}
      />
    </div>
  )
}

const Tab = ({ label, onClick, isSelected }) => (
  <button
    className={isSelected ? 'selected':''}
    onClick={onClick}
  >
    { label }
  </button>
)

const BackButton = ({ onClick }) => (
  <div className="back-button">
    <img src={LeftArrowIcon} onClick={onClick} alt="Regresar" />
  </div>
)

export default connect(state => ({
  user: state.user,
}))(VisitUnplannedDetails);