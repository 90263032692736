import React from "react";

import { Button, Select, Input, Textarea, File } from "../../components";
import { axios, Globals, Constants } from "../../utils";

class CreateEditClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        first_name: "",
        document_type: "V",
        identity_document: "",
        fiscal_document_type: "V",
        fiscal_identification: "",
        administrator_id: props.user.role === 2 ? props.user.id : "",
        serialization: null,
        sale_type: "",
        email: "",
        phone: "",
        image: "",
        direction: ""
      },
      administrators: [],
      textButton: "Crear"
    };
  }

  ident_docs = {
    document_type: "V",
    document: ""
  };

  fiscal_docs = {
    document_type: "V",
    document: ""
  };

  sale_type = [
    {
      name: "Nota de Entrega",
      value: 1
    },
    {
      name: "Factura",
      value: 2
    }
  ]

  componentDidMount() {
    if (this.props.edit) {
      this.edit();
      this.getAdministrators();
    } else {
      this.getAdministrators();
    }
  }

  submit = () => {
    if (!this.state.form.document_type) {
      this.setState({
        ...this.state.form,
        document_type: "V",
        document: "V-" + this.state.form.document
      });
    } else if (
      !this.state.form.document_type.indexOf("-") ||
      this.state.form.document_type
    ) {
      this.setState({
        ...this.state.form,
        document: this.state.form.document_type + "-" + this.state.form.document
      });
    }

    axios
      .upload(
        this.props.edit ? "admin/enterprises/edit" : "admin/enterprises/create",
        this.state.form
      )
      .then(res => {
        if (res.data.result) {
          Globals.showSuccess(res.data.msg);
          this.props.onClose();
        }
      })
      .catch(err => {
        let message = "Disculpe, ha ocurrido un error";
        if (err.response.status === 422) {
          message = err.response.data.msg;
        }
        Globals.showError(message);
      });
  };

  edit = () => {
    const { edit, user } = this.props;
    const { element } = edit;
    console.log(element)

    if ((element || {}).identity_document) {
      if (
        !element.identity_document.indexOf("-") ||
        parseInt(element.identity_document)
      ) {
        this.ident_docs = {
          document_type: "V",
          document: element.identity_document
        };
      } else {
        let division = element.identity_document.split("-");
        this.ident_docs = {
          document_type: division[0],
          document: division[1]
        };
      }
    }

    if (element.name) {
      if (element.name.indexOf("-") <= -1) {
        element.name = element.name;
      } else {
        let division = element.name.split("-");
        element.name = division[1];
      }
    }

    if ((element || {}).fiscal_identification) {
      if (
        !element.fiscal_identification.indexOf("-") ||
        parseInt(element.fiscal_identification)
      ) {
        this.fiscal_docs = {
          document_type: "V",
          document: element.fiscal_identification
        };
      } else {
        let division = element.fiscal_identification.split("-");
        this.fiscal_docs = {
          document_type: division[0],
          document: division[1]
        };
      }
    }

    this.setState(state => ({
      form: {
        ...state.form,
        id: element.id,
        name: element.name,
        first_name: element.first_name,
        email: element.email,
        phone: element.phone,
        direction: element.direction,
        person_type: element.person_type,
        sale_type: element.configuration_enterprise ? element.configuration_enterprise.sale_type : '',
        fiscal_document_type: this.fiscal_docs.document_type,
        fiscal_identification: this.fiscal_docs.document,
        identity_document: this.ident_docs.document,
        document_type: this.ident_docs.document_type,
        image: element.avatar,
        administrator_id: element.enterprise_administrator.administrator_id,
        creator_id: user.id,
        serialization: element.serialization
      },
      textButton: "Editar"
    }));
  };

  handleChange = emitter => {
    const { user } = this.props;
    console.log(emitter.target)
    const { name, value } = emitter.target;

    this.setState({
      form: {
        administrator_id: user.role === 1 ? "" : this.props.user.id,
        creator_id: this.props.user.id,
        fiscal_document_type: this.fiscal_docs.document_type,
        document_type: this.ident_docs.document_type,
        ...this.state.form,
        [name]: value
      }
    });
  };

  getAdministrators = () => {
    Globals.setLoading();
    axios
      .get("admin/enterprises/administrators")
      .then(res => {
        this.setState({
          administrators: res.data
        });
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  setSelectedOption = async (value, key) => {
    this.setState({
      form: {
        ...this.state.form,
        [key]: value
      }
    });
    if (key === "document_type") this.ident_docs.document_type = value;
    if (key === "fiscal_document_type") this.fiscal_docs.document_type = value;
  };

  render() {
    const admin = this.props.user.role === 1 ? true : false;
    console.log(this.state)
    let selectAdministrator;
    if (admin) {
      selectAdministrator = (
        <div className="col-md-12">
          <Select
            color="gray"
            name="administrator_id"
            label="Multiempresa"
            defaultname="Seleccione"
            onChange={this.handleChange}
            value={this.state.form.administrator_id}
            options={this.state.administrators.map(i => {
              return {
                value: i.id,
                label: i.name
              };
            })}
          />
        </div>
      );
    }
    return (
      <div className="text-center container-create-edit-user">
        <div className="row">
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.name}
              name="name"
              label="Nombre de Usuario"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.first_name}
              name="first_name"
              label="Nombre de la Empresa"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <Select
                color="gray"
                label="Tipo"
                name="document_type"
                defaultname="Seleccione"
                onChange={e =>
                  this.setSelectedOption(e.target.value, e.target.name)
                }
                value={this.state.form.document_type}
                options={Constants.TYPE_DOCUMENTS}
              />
              <Input
                color="gray"
                value={this.state.form.identity_document}
                name="identity_document"
                label="Cédula"
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                maxLength={this.state.form.document_type === "J" ? 9 : 8}
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <Select
                color="gray"
                label="Tipo"
                name="fiscal_document_type"
                defaultname="Seleccione"
                onChange={e =>
                  this.setSelectedOption(e.target.value, e.target.name)
                }
                value={this.state.form.fiscal_document_type}
                options={Constants.TYPE_DOCUMENTS}
              />
              <Input
                color="gray"
                value={this.state.form.fiscal_identification}
                name="fiscal_identification"
                label="RIF"
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                maxLength={ 12 }
                onChange={this.handleChange}
              />
            </div>
          </div>
        </div>
        <div className="row">{selectAdministrator}</div>
        <div className="row">
          <div className="col-md-12">
            <Select
              color="gray"
              name="sale_type"
              label="Tipo de Venta"
              defaultname="Seleccione"
              onChange={this.handleChange}
              value={this.state.form.sale_type}
              options={this.sale_type.map(i => {
                return {
                  value: i.value,
                  label: i.name
                };
              })}
            />
          </div>
        </div>
        <div className="row text-center">
          <div className="col-md-12">
            <label>
              Serialización
            </label>
          </div>
        </div>
        <div className="row mb-3">
          <div className="col-md-6">
            <input
              type="radio"
              name="serialization"
              defaultname="Seleccione"
              onChange={this.handleChange}
              defaultChecked={
                this.state.form.serialization === 1
                  ? "checked"
                  : null
              }
              value={1}
            />
            <label className="form-check-label">
              Si
            </label>
          </div>
          <div className="col-md-6">
            <input
              type="radio"
              name="serialization"
              defaultname="Seleccione"
              onChange={this.handleChange}
              defaultChecked={
                this.state.form.serialization === 0
                  ? "checked"
                  : null
              }
              value={0}
            />
            <label className="form-check-label">
              No
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.email}
              name="email"
              type="email"
              label="E-Mail"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.phone}
              name="phone"
              label="Teléfono"
              maxLength={11}
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <Textarea
              label="Direccion"
              name="direction"
              value={this.state.form.direction}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.password}
              name="password"
              type="password"
              autoComplete="off"
              label="Contraseña"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.password_confirmation}
              name="password_confirmation"
              type="password"
              autoComplete="off"
              label="Confirmar Contraseña"
              onChange={this.handleChange}
            />
          </div>
        </div>

        <File
            placeholder={
              this.state.edit
                ? "Cambiar Logo de Empresa (opcional)"
                : "Agregar Logo de Empresa (opcional)"
            }
            placeholdersuccess={
              typeof this.state.form.image == "string"
                ? "Cambiar Logo (Opcional)"
                : "Logo Agregada"
            }
            showcheck={true.toString()}
            onChange={this.handleChange}
            name="image"
            value={this.state.form.image}
            inputstyle={{
              display: "contents"
            }}
            className="btn-product"
          />

        <div id="button">
          <Button block="true" type="button" onClick={() => this.submit()}>
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}
export default CreateEditClient;
