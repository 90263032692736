/* eslint-disable react-hooks/exhaustive-deps */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import NumberFormat from 'react-number-format';
import { axios, Constants, Globals } from '../../../utils';
import { Select, Button, SelectActions, Input } from '../../../components';
import { useState } from 'react';
import { useEffect } from 'react';

export class ModalProducts extends Component {

  state = {
    page: 1,
    last_page: 1,
    form: {
      search: '',
      user_id: this.props.user.id,
      quantity: '1',
      discount: '',
      warehouse_id: "",
    },
    data: [],
    prices: [],
    selectedProduct: null,
    selectedPrice: '',
    warehouses: this.props.warehouses,
    formatWarehouses: this.props.formatWarehouses,
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    /*
      Si es ADMIN, o no tiene almacen asignado le cargo por defecto los productos del almacen principal
      SI no es ADMIN, y tiene almacen asignado, le cargo por defecto los productos del almacen asignado
    */
    if(this.props.user.level_id == Constants.ROLE_ADMIN){
      this.onSelectWarehouse(this.props.user.warehouse_principal);
    }
    else{
      if(this.props.user.warehouse_id == null){
        this.onSelectWarehouse(this.props.user.warehouse_principal);
      }
      else{
        this.onSelectWarehouse(this.props.user.warehouse_id)
      }
    }
  }

  getproducts = async () => {
    Globals.setLoading();

    const form = {
      search: this.state.form.search,
      user_id: this.state.form.user_id,
    };

    axios.post("web/admin/orders/products", form)
      .then(res => {
        if (!res.data) throw Error('No se encontraron los productos');
        this.setState({ data: res.data.products });
      })
      .catch(() => {
        Globals.showError();
      })
      .finally(() => Globals.quitLoading());
  }

  resetForm = async () => {
    await this.setState({
      form: {
        search: '',
        user_id: this.props.user.id,
        quantity: 1,
        discount: '',
        warehouse_id: this.state.form.warehouse_id,
      },
      prices: [],
      selectedProduct: null,
      selectedPrice: '',
    });
  }

  change = async (value, target) => {
    await this.setState(state => ({ form: { ...state.form, [target]: value } }));
  }

  onChangeProductSizeColor = async (value, target) => {
    await this.setState(state => ({
      selectedProduct: {
        ...state.selectedProduct,
        [target]: value,
      }
    }));

    this.setPrices();
    this.formatProduct();
  }

  formatProduct = () => {
    const { form, selectedPrice, selectedProduct } = this.state;
    if (!selectedProduct) return;

    const quantity = Number(form.quantity);
    const discount = Number(form.discount || 0);
    const warehouse_id = Number(form.warehouse_id)
    let stock = selectedProduct.stock;

    if (selectedProduct.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
      const size_color = selectedProduct.size_colors.find(sc => sc.size_id === selectedProduct.size_id && sc.color_id === selectedProduct.color_id);

      stock = size_color?.stock || 0;
      selectedProduct.altName = selectedProduct.name;
      if (!!size_color?.color) selectedProduct.altName += `, ${ size_color.color.name }`;
      if (!!size_color?.size) selectedProduct.altName += `, ${ size_color.size.name }`;
    }

    const subtotal = quantity * selectedPrice;

    this.setState({
      selectedProduct: {
        ...selectedProduct,
        product_id: selectedProduct.id,
        quantity,
        stock,
        subtotal,
        discount,
        warehouse_id,
      }
    });

    //Limpio el almacen
    // this.setState(state => ({
    //   form: {
    //     ...state.form,
    //     warehouse_id: '',
    //   }
    // }));
  }

  getPrices = (product) => {
    let size_color = null;
    let prices = product.prices;

    if (product?.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
      size_color = product?.size_colors?.find((i) => {
        if (product.size_id && product.color_id) {
          return i.size_id === product.size_id && i.color_id === product.color_id;
        }
        else if (product.size_id) {
          return i.size_id === product.size_id && !i.color_id;
        }
        else if (product.color_id) {
          return i.color_id === product.color_id && !i.size_id;
        }

        return null;
      });

      prices = size_color?.prices || [];
      product.price = (product?.size_colors.length > 0 ? product?.size_colors[0].sale_price : 0);

    } else {
      product.price = product.sale_price;
    }

    return [
      {
        value: size_color?.sale_price || product.price,
        label: `$${Globals.formatMiles(size_color?.sale_price || product.price,true)}`,
      },
      ...prices.map(i => ({
        value: i.price,
        label: `$${Globals.formatMiles(i.price,true)}`,
      }))
    ]
  }

  setPrices = async () => {
    const product = Globals.copy(this.state.selectedProduct);
    this.setState({
      prices: this.getPrices(product),
      selectedPrice: '',
    });
  }

  onSelectProduct = async (id) => {
    await this.resetForm();
    const selectedProduct = Globals.copy(this.state.data.find(p => p.id === id));
    let prices = this.getPrices(selectedProduct);
    selectedProduct.size_id = null;
    selectedProduct.color_id = null;

    await this.setState(state => ({
      selectedProduct,
      selectedPrice: '',
      prices,
      form: {
        ...state.form,
        quantity: 1,
        discount: '',
      }
    }));

    this.formatProduct();
  }

  onSelectPrice = async (amount) => {
    await this.setState({ selectedPrice: parseFloat(parseFloat(amount).toFixed(2)) });
  }

  onSelectWarehouse = async (warehouse_id) => {
    this.setState(state => ({
      form: {
        ...state.form,
        warehouse_id: warehouse_id,
      }
    }));

    const selectedWarehouse = this.state.warehouses.find(warehouse => warehouse.id == warehouse_id);
    if (selectedWarehouse) {
      const products = selectedWarehouse.products.map(i => {
        const total_quantity = i.total_quantity;
        if (total_quantity <= 0) {
          return null; 
        }
        return {
          ...i.product,
          total_quantity: total_quantity
        };
      }).filter(product => product !== null);
      this.setState({ data: products });
    }
  }

  onChangeQuantity = async (value) => {
    await this.change(value, 'quantity');
    this.formatProduct();
  }

  submit = () => {
    if (!this.isValidForm()) return;

    const product = Globals.copy(this.state.selectedProduct);
    product.name = product?.altName || product.name;
    product.price = this.state.selectedPrice;
    product.sale_price = this.state.selectedPrice;
    product.selected_price = this.state.selectedPrice;

    this.props.onSelectProduct(product, this.state.form.warehouse_id);
    this.resetForm();
  }

  isValidForm = () => {
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    const { selectedPrice, selectedProduct } = this.state;

    console.log('selectedProduct: ', selectedProduct)
    console.log('user: ', this.props.user)

    if (selectedProduct.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
      let colors = selectedProduct.size_colors.filter(i => i.color_id).reduce((prev, curr) => prev.set(curr.color_id, curr.color), new Map()).values();
      let sizes = selectedProduct.size_colors.filter(i => i.size_id).reduce((prev, curr) => prev.set(curr.size_id, curr.size), new Map()).values();
      colors = [ ...colors ];
      sizes = [ ...sizes ];

      if (!!colors.length && !selectedProduct.color_id)
        return onError('Debe seleccionar un color');

      if (!!sizes.length && !selectedProduct.size_id)
        return onError('Debe seleccionar una talla');
    }

    if (!selectedProduct)
      return onError('Debe selecciona el producto');

    if (typeof selectedPrice !== 'number')
      return onError('Debe seleccionar el precio');

    if (selectedProduct.discount !== '' && selectedProduct.discount < 0)
      return onError('El porcentaje de descuento no puede ser negativo');

    if (selectedProduct.discount !== '' && selectedProduct.discount > 100)
      return onError('El porcentaje de descuento no puede ser mayor a 100%');

    if (selectedProduct.quantity < 1)
      return onError('Debe seleccionar al menos 1');

    // Si es el almacen principal el stock esta en stock, si es otro almacen esta en total_quantity
    if(selectedProduct.warehouse_id === this.props.user.warehouse_principal){
      if (selectedProduct.quantity > selectedProduct.stock){
        return onError(`No hay suficiente stock (${ selectedProduct.stock })`);
      } 
    }
    else{
      if (selectedProduct.quantity > selectedProduct.total_quantity){
        return onError(`No hay suficiente stock (${ selectedProduct.total_quantity })`);
      } 
    }
    

    return true;
  }

  render() {

    const { form, data, prices, selectedProduct, selectedPrice } = this.state;

    return (
      <div className="mt-2">
        {/* comentado para prod */}
        {(
          (
            this.state.warehouses.length > 1 && (
              this.props.user.level_id == Constants.ROLE_ADMIN || 
              (this.props.user.level_id == Constants.ROLE_MODERATOR && this.props.user.warehouse_id === null)
            )
          ) ?
            <div className="col-12 select-product">
              <Select
                label="Almacén"
                labelClass="material"
                disabledFirst
                value={form.warehouse_id}
                options={this.state.formatWarehouses}
                onChange={e => this.onSelectWarehouse(e.target.value)}
              />
            </div>
          : ''
        )}
        
        <div className="col-12 select-product">
          <SelectActions
            label="Nombre o Código"
            isSearchable
            options={data.map(item => ({
              ...item,
              value: item.id,
              label: `${String(item.code).toLowerCase().toUpperCase()} - ${item.name}`,
              actions: [],
            }))}
            value={selectedProduct?.id || null}
            onChange={this.onSelectProduct}
          />
        </div>

        {selectedProduct?.type === Constants.PRODUCTS_TYPE.SIZE_COLOR && (
          <div className="col-12">
            <SizeColorForm
              product={selectedProduct}
              onChange={(value, target) => this.onChangeProductSizeColor(value, target)}
            />
          </div>
        )}

        <div className="col-12 price-discount">
          <div className="select-price">
            <Select
              label="Precio"
              labelClass="material"
              disabledFirst
              value={selectedPrice}
              options={prices}
              onChange={e => this.onSelectPrice(e.target.value)}
            />
          </div>

          <div className="select-discount">
            <Input
              label="Descuento (%)"
              placeholder="Opcional"
              type="number"
              color=" "
              className="material"
              labelClass="material"
              step="0.01"
              min="0"
              max="100"
              value={form.discount}
              onChange={async (e) => {
                await this.change(e.target.value, 'discount');
                if (!!selectedProduct) this.formatProduct();
              }}
            />
          </div>
        </div>

        <div className="container-data">
          <span>Cantidad</span>
          <NumberFormat
            className="quantity-input"
            value={form.quantity}
            allowEmptyFormatting={true}
            thousandSeparator={true}
            decimalScale={0}
            allowNegative={false}
            onValueChange={({ value }) => this.onChangeQuantity(value)}
          />
        </div>

        <div className="actions">
          <Button onClick={() => this.submit()}>
            CARGAR
          </Button>

          <Button onClick={() => this.props.onClose()}>
            FINALIZAR
          </Button>
        </div>
      </div>
    )
  }
}

const SizeColorForm = ({ product, onChange }) => {

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  function getUniqueItems() {
    const _colors = product.size_colors.filter(i => i.color_id).reduce((prev, curr) => prev.set(curr.color_id, curr.color), new Map());
    const _sizes = product.size_colors.filter(i => i.size_id).reduce((prev, curr) => prev.set(curr.size_id, curr.size), new Map());

    setColors([ ..._colors.values() ]);
    setSizes([ ..._sizes.values() ]);
  }

  useEffect(() => {
    getUniqueItems();
  }, []);

  return (
    <div className="size-colors-form">
      {!!sizes.length && (
        <div className="sizes">
          <Select
            label="Talla"
            labelClass="material"
            className="material"
            disabledFirst
            options={ sizes.map(item => ({ value: item.id, label: item.name })) }
            value={ product.size_id }
            onChange={e => onChange(Number(e.target.value), 'size_id')}
          />
        </div>
      )}

      {!!colors.length && (
        <div className="colors">
          <SelectActions
            label="Color"
            options={colors.map(item => ({
              value: item.id,
              label: (<div className="circle" style={{ backgroundColor: item.hex }} />),
              actions: [],
            }))}
            value={product.color_id}
            onChange={id => onChange(Number(id), 'color_id')}
          />
        </div>
      )}
    </div>
  );
}

export default connect(state => ({
  user: state.user,
}))(ModalProducts)