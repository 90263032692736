import React, { Component } from 'react';
import { connect } from 'react-redux';
import { axios, Constants, Globals } from "../../utils";
import {
  Pagination,
  Input
} from "../../components";

const INITIAL_FORM = {
  search: ''
}

export class ModalSellers extends Component {

  timer: any = undefined;

  state = {
    page: 1,
    last_page: 1,
    data: [],
    form: INITIAL_FORM,
  }

  componentDidMount() {
    this.load();
  }

  load = async (page = 1) => {
    let { form } = this.state;

    form.user_id = this.props.user.id;

    axios
      .post("admin/visit-planners/get-sellers?page=" + page, form)
      .then(res => {
        this.setState({
          page,
          last_page: res.data.sellers.last_page,
          data: res.data.sellers.data,
        });
      })
      .catch(() => {
        Globals.showError();
      });
  }

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  render() {
    return (
      <div className="modal-planer-sellers">
        <div className="container-search">
          <Input
            color=" "
            placeholder="Buscar por nombre"
            className="filter"
            labelClass="filter"
            value={this.state.form.search}
            onChange={ e => {
              clearTimeout(this.timer);
              this.timer = setTimeout(async () => {
                await this.setState({
                  page: 1
                });
                this.load(this.state.page);
              },1000);
              this.change(e.target.value, 'search');
            }}
          />
        </div>

        <div className="row item-seller">
          <div className="col-6 th-header">
            Nombre
          </div>
          <div className="col-6 th-header text-right">
            Estatus
          </div>
        </div>

        <div className="container-data">
          {
            this.state.data.map((item) => {
              return (
                <div className="row item-seller">
                  <div className="col-6">
                    { item.name }
                  </div>
                  <div className="col-6 text-right">
                    <span className={ `${ item.status_seller == Constants.STATUS_SELLER.ACTIVE ? 'status-green' : 'status-red' }` }>
                       { item.status_text }
                    </span>
                  </div>
                </div>
              )
            })
          }
        </div>

        <Pagination
          pages={this.state.last_page}
          active={this.state.page}
          onChange={page => this.load(page)}
        />
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalSellers)