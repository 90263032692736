import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Modal
} from "../../components";
import { axios, Constants, Globals } from "../../utils";
import LocationPinIcon from '../../assets/icons/location-pin.png';
import moment from 'moment';
import ModalView from './modal-view';
import ModalViewRoute from './modal-route';
import VisitUnplannedDetails from "./visit-unplanned-details";
import CreateEditSeller from "../sellers/create_edit_seller";

const INITIAL_FORM = {
  since: "",
  until: "",
  search: "",
  status: "",
  zone: "",
}

const SCREEN = {
  MAIN: 1,
  DETAILS: 2,
}

class VisitUnplanned extends React.Component {

  state = {
    screen: SCREEN.MAIN,
    selectedItem: null,
    header: ['Vendedor','Fecha','Zonas asignadas','Estatus','Ruta','Acciones'],
    page: 1,
    last_page: 1,
    data: [],
    form: INITIAL_FORM,
    user_id: this.props.user.level_id === Constants.ROLE_ADMIN
      ? this.props.user.id
      : this.props.user.admin_id,
    visible: false,
    item: null,
    visible_route: false,
    editSeller: false,
    itemSeller: null,
    permissions: [],
    zones: [],
  };

  componentDidMount() {
    this.fetchData();
  };

  fetchData = (page = 1) => {
    let { form } = this.state;

    form.user_id = this.props.user.id;    

    axios
      .post("admin/visit-planners/get-unplanned?page=" + page, {
        ...form,
        since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
        until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      })
      .then(res => {
        this.setState({
          page,
          last_page: res.data.visit_planners.last_page,
          data: res.data.visit_planners.data
        });

        if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) == -1) {
          this.props.history.replace('/');
        }
      })
      .catch(() => {
        Globals.showError();
      });

    this.getPermissions()
    this.getZones()
  };

  getPermissions = () => {
		axios
			.post("admin/sellers/permissions", {})
			.then(res => {
				this.setState({
					permissions: res.data,
				});
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

  getZones = () => {
    axios
      .post("web/admin/zones", { user_id: this.props.user.id })
      .then(({ data }) => {
        data.zones.unshift({ id: "x", name: "Sin Zona" });
        data.zones.unshift({ id: "", name: "Todas" });

        this.setState({
          zones: data.zones
        });
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  cleanFilters = async () => {
    await this.setState({ form: { ...INITIAL_FORM } });
    this.fetchData();
  }

  view = (item) => {
    this.setState({
      visible: true,
      item
    });
  }

  viewRoute = (item) => {
    this.setState({
      visible_route: true,
      item
    });
  }

  close = () => {
    this.setState({
      visible: false,
      item: null
    });
  }

  closeRoute = () => {
    this.setState({
      visible_route: false,
      item: null
    });
  }

  closeEditSeller = () => {
    this.setState({
      editSeller: false,
      itemSeller: null,
    });
    this.fetchData(this.state.page);
  };

  changeScreen = (screen, selectedItem = null) => {
    this.setState({ screen, selectedItem });
  }

  render() {
    const { search, status, since, until, zone } = this.state.form;
    const { screen, selectedItem, visible, visible_route, editSeller, permissions, edit } = this.state;

    if (screen === SCREEN.DETAILS) return (
      <VisitUnplannedDetails
        history={this.props.history}
        item={selectedItem}
        zones={this.state.zones}
        onRefresh={() => {
          this.fetchData(this.state.page);
          this.changeScreen(SCREEN.MAIN);
        }}
        goBack={() => this.changeScreen(SCREEN.MAIN)}
      />
    )

    return (
      <Menu history={this.props.history}>
        {
          visible && (
            <Modal
              className="modal-planner-view"
              title=""
              onClose={this.close}
              visible
            >
              <ModalView
                item={ this.state.item }
                onClose={this.close}
              />
            </Modal>
          )
        }

        {
          visible_route && (
            <Modal
              className="modal-planner-view-route"
              title=""
              onClose={this.closeRoute}
              visible
            >
              <ModalViewRoute
                item={ this.state.item }
                onClose={this.closeRoute}
              />
            </Modal>
          )
        }

        {/* Modal editar seller */}
        {editSeller && (
          <Modal
            title={'Editar Vendedor'}
            onClose={this.closeEditSeller} 
            visible
          >
            <CreateEditSeller edit={edit} onClose={this.closeEditSeller} permissions={permissions} />
          </Modal>
        )}

        <div id="visit-planner">
          <div className="row">
            <div className="col-2">
              <DatePicker
                color="white"
                label="Desde"
                maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              />
            </div>
            <div className="col-2">
              <DatePicker
                color="white"
                label="Hasta"
                minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
                className="filter"
                value={this.state.form.until}
                onChange={value => this.change(value, 'until')}
              />
            </div>
            <div className="col-2">
              <Input
                color=" "
                label="Vendedor"
                placeholder="Escribir"
                className="filter"
                labelClass="filter"
                value={this.state.form.search }
                onChange={e => this.change(e.target.value, 'search')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Estatus"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={[
                  { value: Constants.VISIT_PLANNER_STATUS.PENDING, label: 'Pendiente' },
                  { value: Constants.VISIT_PLANNER_STATUS.FINISH, label: 'Finalizados' },
                  { value: Constants.VISIT_PLANNER_STATUS.FAILED, label: 'No Finalizadas' }
                ]}
                value={this.state.form.status}
                onChange={e => this.change(e.target.value, 'status')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                name="zone"
                label="Zonas"
                className="filter"
                defaultname="Seleccione"
                onChange={e => this.change(e.target.value, 'zone')}
                value={this.state.form.zone}
                options={this.state.zones.map(x => ({ value: x.id, label: x.name }))}
              />
            </div>

            <div className="col-2">
              <Button
                className="btn-align-bottom btn-filter mr-3"
                color=" "
                onClick={() => this.fetchData()}
              >
                <span>Filtrar</span>
              </Button>
            </div>

            {
              (since != '' || until != '' || search != '' || status != '' || zone != '') && (
                <div className="col-2">
                  <Button
                    className="btn-filter mb-3"
                    color=" "
                    onClick={this.cleanFilters}
                  >
                    <span>Limpiar</span>
                  </Button>
                </div>
              )
            }
          </div>

          <Table
            title="Rutas Registradas"
            data={this.state.data.length}
            header={this.state.header}
          >
            {this.state.data.map((i, index) => {
              const keys = ['id'];
              const zones = [...i.seller?.zones,i.seller?.zone].filter(i => i !== null).filter((value, index, self) =>
                self.findIndex(v => keys.every(k => v[k] === value[k])) === index
              );

              return (
                <tr key={`routes-${index}`}>
                  <td>
                    <span className="link" onClick={() => this.changeScreen(SCREEN.DETAILS, i)}>
                      { i.seller?.name }
                    </span>
                  </td>
                  <td>{ moment(i.date).format('DD-MM-YYYY') }</td>
                  <td>{ zones.map(i => i.name).filter(i => i != null).join(', ') || 'Sin zona' }</td>
                  <td>{ i.status_text }</td>
                  <td>
                      <img
                        src={LocationPinIcon}
                        className="location-pin"
                        alt="Ubicación"
                        onClick={() => this.viewRoute(i)}
                      />
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => this.view(i)}
                    >
                      <Icon name="eye" />
                    </Button>

                    <Button
                      title="Editar Vendedor"
                      small="true"
                      color="primary"
                      onClick={() =>
                        this.setState(
                          {
                            edit: {
                              element: i.seller,
                              index: index
                            }
                          },
                          () => {
                            this.setState({ editSeller: true });
                          }
                        )
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => this.fetchData(page)}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(VisitUnplanned);
