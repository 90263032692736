import axios from "../../utils/axios";

class enterprise {

  static async getEnterprises(page, param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/enterprises/get?page=${page}`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async setStatusActive(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/active", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async setStatusInactive(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/suspend", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async removeRol(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/delete", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default enterprise;
