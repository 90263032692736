import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import CreateEditProduct from '../products/create_edit_product';
import {
  Button,
  Select,
  Input,
  InputFormat,
  DatePicker,
  Textarea,
  Icon,
  EditSerial,
  ModalScan
} from "../../components";
import { axios, Globals, Constants, Format } from "../../utils";
import { Order, Product, Warehouse, Bank } from "../../services";
// Components
import FormProvider from "./formProvider";

var timer = null;
//
const IVA = 0.16;

const METHOD_TYPE_LIST = [
  { value: 1, label: "Efectivo" },
  { value: 3, label: "Debito" },
  { value: 4, label: "Transferencia" }
];


const PAYMENT_TYPE_LIST = [
  { value: 1, label: "Contado" },
  { value: 2, label: "Credito" }
];

class CreateEditOrder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitted: false,
      create: props.Item ? false : true,
      search: "",
      showProvider: false,
      showProduct: false,
      edit_serial: false,
      item_serial: null,
      warehouses_products: [],
      companies_products: [],
      form: {
        id: "",
        forthright: false,
        provider_id: "",
        branch_id: "",
        warehouse_id: "",
        code: "",
        bill_number: "",
        control_number: "",
        type: "",
        currency_id: "",
        date_purchase: new Date(),
        date_delivery: new Date(),
        date_delivery_real: "",
        bill_date_reception: new Date(),
        bill_date_emission: new Date(),
        observations: "",
        subtotal: 0.0,
        taxable: 0.0,
        exempt: 0.0,
        subtotal_real: 0.0,
        vat: 0.0,
        vat_real: 0.0,
        total: 0.0,
        total_real: 0.0,
        creator_id: props.user.id,
        enterprise_id: props.Id,
        products: [],
        payment_type: "",
        method_type: "",
        bank_id: "",
        bank_account_id: "",
        reference_number: ""
      },
      dates: {
        minRequestDate: moment.now(),
        minDeliveryDate: moment.now()
      },
      warehouses: [],
      errors: [],
      products: [],
      suggestions: [],
      textButton: "Crear",
      banks: [],
      bank_accounts: [],
    };
  }

  maybeLoadBankAccounts = () => {
    const { form } = this.state

    Bank.getBankAccounts({ bank_id: form.bank_id })
      .then(response => {
        let bankAccountsMapped = [];

        if (form.method_type && parseInt(form.method_type) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .filter(Item => Item.type === Constants.TYPE_BANK_ACCOUNTS_LIST.CASH_ONLY)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? Item.last_balance.total : 0
              };
            })
        } else {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? Item.last_balance.total : 0
              };
            })
        }

        this.setState(state => ({
          form: {
            ...state.form,
            bank_account_id: ""
          },
          bank_accounts: bankAccountsMapped
        }));

        console.log('bank_accounts: ', form.bank_accounts);

      })
      .catch(() => Globals.showError());
  }

  componentDidMount() {
    const { Id, user } = this.props;

    this.setState(state => ({
      form: {
        ...state.form,
        enterprise_id: Id,
        creator_id: user.id
      }
    }));

    if (this.props.Item && !this.state.create) {
      console.log(this.props.Item)
      const { Item } = this.props;
      this.maybeLoadData(Item);
    } else {
      this.maybeLoadId(Id);
    }

    const enterprise_id = Globals.getEnterpriseId(user);
    this.warehouses(enterprise_id);
    this.companies();

    this.loadFormState();
  }

  companies = () => {
    const { user } = this.props;
    let param = {
      role: user.role,
      user_id: user.id
    };
    axios
      .post("admin/products/companies", param)
      .then(res => {
        if (res.data.result) {
          let companies = [];
          res.data.companies.forEach((element, i) => {
            companies.push({
              label: element.name,
              value: element.id,
              models: element.models
            });
          });
          this.setState({
            companies_products: companies
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(() => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  warehouses = enterprise_id => {
    const { user } = this.props;

    const Id = enterprise_id;

    axios
      .post("admin/products/warehouses", {
        role: user.role,
        user_id: Id
      })
      .then(({ data }) => {
        if (data.result) {
          const warehouseMap = Format.rawWarehouse(
            data.warehouses,
            user.assigned_warehouses,
            user.role
          ).map(Item => {
            return {
              ...Item,
              id: Item.id,
              name: Item.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: "",
              percentage_earning: 0,
              percentage_max: 0,
              percentage_offer: 0
            };
          });

          this.setState({
            warehouses_products: warehouseMap
          });
        } else {
          Globals.showError(data.msg);
        }
      })
      .catch(() => Globals.showError());
  };

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }
  }

  maybeLoadId = Id => {
    Order.getId({ Id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));
      })
      .catch(error => {
        Globals.showError();
      });
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { Id, Item, user } = this.props;

    Warehouse.getWarehouseByBranch({
      user_id: Id,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = [];
      if (user.role === 4) {
        warehouses_filtered = response.filter(el => {
          return user.assigned_warehouses.some(f => {
            return parseInt(f.warehouse_id) === parseInt(el.id);
          });
        });
      } else {
        warehouses_filtered = response;
      }
      const warehousesMap = warehouses_filtered.filter(
        Item => Item.status === Constants.STATUS_ACTIVE
      );

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: Item ? state.form.warehouse_id : ""
        },
        warehouses: warehousesMap
      }), () => {
        // this.props.dispatch({
        //   type: 'SET_ORDER',
        //   payload: null
        // });
      });

    });

  };

  takeProduct = Product => {
    const exists = this.state.form.products.find(Item => {
      return Item.id === Product.id;
    });

    if (!exists) {
      this.setState(state => ({
        search: "",
        form: {
          ...state.form,
          products: [...state.form.products, Product]
        }
      }));
    }
    console.log(this.state.suggestions);
    this.setState({ suggestions: [] });

    this.saveOrder();
  };

  saveOrder() {
    // this.props.dispatch({
    //   type: 'SET_ORDER',
    //   payload: {
    //     ...this.state.form
    //   }
    // });

    const { form } = this.state;

    try {

      console.log('saveExpressIngress >> state.form: ', form);

      const serializedData = {
        id: form.id,
        forthright: form.forthright,
        provider_id: form.provider_id,
        branch_id: form.branch_id,
        warehouse_id: form.warehouse_id,
        code: form.products,
        bill_number: form.bill_number,
        control_number: form.control_number,
        type: form.type,
        currency_id: form.currency_id,
        date_purchase: form.date_purchase,
        date_delivery: form.date_delivery,
        date_delivery_real: form.date_delivery_real,
        bill_date_reception: form.bill_date_reception,
        bill_date_emission: form.bill_date_emission,
        observations: form.observations,
        subtotal: form.subtotal,
        taxable: form.taxable,
        exempt: form.exempt,
        subtotal_real: form.subtotal_real,
        vat: form.vat,
        vat_real: form.vat_real,
        total: form.total,
        total_real: form.total_real,
        creator_id: form.creator_id,
        enterprise_id: form.enterprise_id,
        products: form.products,
        payment_type: form.payment_type,
        method_type: form.method_type,
        bank_id: form.bank_id,
        bank_account_id: form.bank_account_id,
        reference_number: form.reference_number
      };


      console.log('saveOrder >> serializedData: ', serializedData);
      console.log('serializedData parse to json: ', JSON.stringify(serializedData));
      localStorage.setItem('OrderState', JSON.stringify(serializedData));
    } catch (error) {
      console.log(error);
    }

  }

  loadFormState = () => {
    let { form } = this.state;
    try {
      let serializedData = localStorage.getItem('OrderState')
      if (serializedData === null) {
        return undefined // Si no existe el state en el local storage devolvemos undefined para que cargue el state inicial que hayamos definido
      }

      serializedData = JSON.parse(serializedData);
      console.log("storage.form.state: ", serializedData) // Si encontramos con exito nuestro storage lo devolvemos.
      // Convertimos las fechas de string a Date
      serializedData.date_purchase = new Date(serializedData.date_purchase);
      serializedData.date_delivery = new Date(serializedData.date_delivery);
      serializedData.date_delivery_real = new Date(serializedData.date_delivery_real);
      serializedData.bill_date_reception = new Date(serializedData.bill_date_reception);
      serializedData.bill_date_emission = new Date(serializedData.bill_date_emission);

      this.setState({ form: serializedData });

      form.forthright = serializedData.forthright;
      form.provider_id = serializedData.provider_id;
      form.branch_id = serializedData.branch_id;
      form.warehouse_id = serializedData.warehouse_id;
      form.code = serializedData.code;
      form.type = serializedData.type;
      form.currency_id = serializedData.currency_id;
      form.products = serializedData.products;
      form.observations = serializedData.observations;
      form.subtotal = serializedData.subtotal
      form.taxable = serializedData.taxable
      form.exempt = serializedData.exempt
      form.subtotal_real = serializedData.subtotal_real
      form.vat = serializedData.vat
      form.vat_real = serializedData.vat_real
      form.total = serializedData.total
      form.total_real = serializedData.total_real
      form.payment_type = serializedData.payment_type
      form.method_type = serializedData.method_type
      form.bank_id = serializedData.bank_id
      form.bank_account_id = serializedData.bank_account_id
      form.reference_number = serializedData.bank_account_id

      return console.log("form.state: ", form);

    } catch (error) {
      return undefined // Si ocurre algun error, devuelvo undefined para cargar el state inicial.
    }
  }

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { form, submitted, create } = this.state;

    if (submitted) {
      return;
    }

    // Filtrando productos para valores nulos
    const findValuesNull = form.products.filter(
      Item => Item.quantity <= 0 || Item.quantity === ""
    );
    // Filtrando productos para costos nulos
    const findCostNull = form.products.filter(
      Item => parseInt(Item.total) <= 0 || parseInt(Item.total) === ""
    );
    // Filtrando costo de ganancias para valores nulos
    // const findEarningNull = form.products.filter(
    //   Item => Item.earning <= 0 || Item.earning === ""
    // );

    if (findValuesNull.length > 0) {
      Globals.showError("¡Los productos deben tener un valor mayor a 0!");
      return;
      // } else if (findEarningNull.length > 0) {
      //   Globals.showError(
      //     "¡El % de ganancia de los productos debe tener un valor mayor a 0!"
      //   );
      //   return;
    } else if (findCostNull.length > 0) {
      Globals.showError("¡Los productos deben tener un costo mayor a 0!");
      return;
    } else if (form.products.length === 0) {
      Globals.showError("¡Debe agregar productos a la orden!");
      return;
    } else if (form.total <= 0) {
      Globals.showError("¡La orden debe tener un valor total mayor a 0!");
      return;
    }

    // Validando si hay productos por serializar
    if (!create || form.forthright) {
      const cant = form.products.filter(i => i.serialization == Constants.SERIALIZATION.ACTIVE && i.serials == null).length;
      if (cant > 0) {
        Globals.showError("¡Hay productos que necesitan serializarse!");
        return;
      }
    }

    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });

    switch (create) {
      case true:
        // Registrar orden
        Order.createOrder(form)
          .then(response => {
            console.log(response);
            Globals.showSuccess(
              `¡${form.forthright ? "Factura " : "Orden "} creada exitosamente!`
            );
            localStorage.removeItem('OrderState'); //dejo el state del storage vacío
            this.props.onClose();

          })
          .catch(error => {

            if ("bill_number" in error) {
              Globals.showError(error.bill_number[0]);
            }
            if ("control_number" in error) {
              Globals.showError(error.control_number[0]);
            }
            if ("msg" in error) {
              Globals.showError(error.msg)
            }
            this.setState({ submitted: false, errors: error });
          });
        break;
      case false:
        // Actualizar orden
        Order.updateOrder(form)
          .then(response => {
            const { code } = response;

            Globals.showSuccess(`¡Orden Nº ${code} procesada exitosamente!`);
            localStorage.removeItem('OrderState'); //dejo el state del storage vacío
            this.props.onClose();

          })
          .catch(error => {
            console.log(error)
            if ("msg" in error) {
              Globals.showError(error.msg)
            }
            this.setState({ submitted: false, errors: error });
          });
        break;
      default:
        break;
    }
  };

  timerAutoComplete = ({ search }) => {
    if (timer) {
      clearTimeout(timer);
    }

    timer = setTimeout(() => {
      this.AutoComplete(search);
    }, 1000);
  };

  AutoComplete = search => {
    const { Id } = this.props;
    const { form } = this.state;

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    console.log(search+ '    '+ this.state.suggestions);

    Product.getProductsByEnterprise({
      user_id: Id,
      search: search,
      warehouse_id: form.warehouse_id
    })
      .then(response => {
        const productMap = response
          .filter(Item => {
            return form.products.every(query => {
              return parseInt(query.id) !== parseInt(Item.id);
            });
          })
          .map((Item, key) => {
            return {
              ...Item,
              product_id: Item.id,
              earning: 0,
              earning_max: 0,
              earning_offer: 0,
              hidden: false,
              price: 0,
              unit_price: (Item.product_provider || {}).price || 0,
              percentage_earning: (Item.product_warehouse[0] || {})
                .percentage_earning
                ? (Item.product_warehouse[0] || {}).percentage_earning / 100
                : 0,
              percentage_max: (Item.product_warehouse[0] || {}).percentage_max
                ? (Item.product_warehouse[0] || {}).percentage_max / 100
                : 0,
              percentage_offer: (Item.product_warehouse[0] || {})
                .percentage_offer
                ? (Item.product_warehouse[0] || {}).percentage_offer / 100
                : 0,
              quantity: 0,
              subtotal: 0,
              vat: 0
            };
          });

        this.setState({ suggestions: productMap });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;
    const { banks } = this.props;
    const { bank_accounts } = this.state

    switch (name) {
      case "search":
        this.setState({ [name]: value }, () => {
          this.timerAutoComplete(this.state);
        });
        break;
      case "payment_type":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_id: "",
            bank_account_id: "",
            balance: 0
          },
          banks: banks,
          bank_accounts: []
        }));
        break;
      case "method_type":
        let banksMapped = []
        if (value && parseInt(value) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          banksMapped = banks.filter(Item => Item.type === Constants.TYPE_BANKS_LIST.CASH_ONLY)
        } else {
          banksMapped = banks.filter(Item => Item.type !== Constants.TYPE_BANKS_LIST.CASH_ONLY)
        }
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_id: "",
            bank_account_id: "",
            balance: 0
          },
          banks: banksMapped,
          bank_accounts: []
        }));
        break;
      case "bank_id":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_account_id: "",
            balance: 0
          },
        }), () => this.maybeLoadBankAccounts());
        break;
      case "reference_number":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          },
        }))
        break;
      case "bank_account_id":
        const bankAccount = bank_accounts.find(Item => Item.value === parseInt(value));
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            balance: parseFloat(bankAccount.balance)
          },
        }));
        break;
      default:
        this.setState({
          form: {
            ...this.state.form,
            [name]: value
          }
        });
        break;
    }
  };

  handleNum = (emitter, key) => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    let newValue = value ? parseInt(value) : value;
    let rawProducts = [...form.products];

    // Formula US$14,50 × (0,30 + 1)

    switch (name) {
      case "quantity":
        let quantityValue =
          newValue > 0 && newValue !== ""
            ? newValue * rawProducts[key].unit_price
            : 0;

        rawProducts[key] = {
          ...rawProducts[key],
          quantity: newValue,
          earning:
            rawProducts[key].percentage_earning > 0
              ? FloatNum(
                rawProducts[key].unit_price +
                rawProducts[key].percentage_earning *
                rawProducts[key].unit_price
              )
              : 0,
          earning_max:
            rawProducts[key].percentage_max > 0
              ? FloatNum(
                rawProducts[key].unit_price +
                rawProducts[key].percentage_max *
                rawProducts[key].unit_price
              )
              : 0,
          earning_offer:
            rawProducts[key].percentage_offer > 0
              ? FloatNum(
                rawProducts[key].unit_price +
                rawProducts[key].percentage_offer *
                rawProducts[key].unit_price
              )
              : 0,
          subtotal: quantityValue,
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue
              : quantityValue * IVA + quantityValue
          )
        };
        break;
      case "unit_price":
        let unitValue =
          newValue > 0 && newValue !== ""
            ? rawProducts[key].quantity * newValue
            : 0;

        rawProducts[key] = {
          ...rawProducts[key],
          unit_price: newValue,
          earning:
            rawProducts[key].percentage_earning > 0 && newValue > 0
              ? FloatNum(
                newValue + rawProducts[key].percentage_earning * newValue
              )
              : 0,
          earning_max:
            rawProducts[key].percentage_max > 0 && newValue > 0
              ? FloatNum(newValue + rawProducts[key].percentage_max * newValue)
              : 0,
          earning_offer:
            rawProducts[key].percentage_offer > 0 && newValue > 0
              ? FloatNum(
                newValue + rawProducts[key].percentage_offer * newValue
              )
              : 0,
          subtotal: unitValue,
          vat: FloatNum(rawProducts[key].exempt ? 0 : unitValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt ? unitValue : unitValue * IVA + unitValue
          )
        };
        break;
      default:
        return;
    }

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.calcAmounts(rawProducts)
    );
  };

  handlePrice = (floatValue, key, name) => {
    const { form } = this.state;
    let rawProducts = [...form.products];

    if (name == "unit_price") {
      let unitValue =
        floatValue > 0 && floatValue !== ""
          ? rawProducts[key].quantity * floatValue
          : 0;

      rawProducts[key] = {
        ...rawProducts[key],
        unit_price: floatValue,
        earning:
          rawProducts[key].percentage_earning > 0 && floatValue > 0
            ? FloatNum(
              floatValue + rawProducts[key].percentage_earning * floatValue
            )
            : 0,
        earning_max:
          rawProducts[key].percentage_max > 0 && floatValue > 0
            ? FloatNum(floatValue + rawProducts[key].percentage_max * floatValue)
            : 0,
        earning_offer:
          rawProducts[key].percentage_offer > 0 && floatValue > 0
            ? FloatNum(
              floatValue + rawProducts[key].percentage_offer * floatValue
            )
            : 0,
        subtotal: unitValue,
        vat: (rawProducts[key].exempt ? 0 : unitValue * IVA),
        total: (
          rawProducts[key].exempt ? unitValue : unitValue * IVA + unitValue
        )
      };

      console.log(rawProducts)
    }
    else {
      rawProducts[key] = {
        ...rawProducts[key],
        [name]: floatValue
      };
    }

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.calcAmounts(rawProducts)
    );
  };

  calcAmounts = rawProducts => {
    this.setState(state => ({
      form: {
        ...state.form,
        subtotal: FloatNum(reduceMap(rawProducts, "subtotal")),
        taxable: FloatNum(taxableMap(rawProducts)),
        exempt: FloatNum(exemptMap(rawProducts)),
        vat: FloatNum(reduceMap(rawProducts, "vat")),
        total: FloatNum(reduceMap(rawProducts, "total"))
      }
    }));
  };

  maybeLoadData = Item => {
    const { details } = Item;

    const rawProducts = arrayTransform(details, Item.warehouse_id);

    rawProducts.forEach(i => {
      i._quantity = i.quantity;
    });

    this.setState(state => ({
      form: {
        ...state.form,
        id: Item.id,
        forthright: false,
        provider_id: Item.provider_id,
        branch_id: Item.warehouse.branch_id,
        warehouse_id: Item.warehouse_id,
        code: Item.code,
        currency_id: Item.currency_id,
        date_purchase: Item.date_purchase,
        date_delivery: Item.date_delivery,
        date_delivery_real: new Date(Item.date_delivery),
        observations: Item.observations,
        subtotal: Item.subtotal,
        taxable: Item.taxable,
        exempt: Item.exempt,
        subtotal_real: Item.subtotal,
        status: Item.status,
        vat: Item.vat,
        vat_real: Item.vat,
        total: Item.total,
        total_real: Item.total,
        products: rawProducts
      },
      textButton: "Procesar"
    }));
  };

  handleClick = Product => {
    const { form } = this.state;

    Globals.confirm(
      `¿Desea remover de la orden el producto: ${Product.name}?`,
      () => {
        const filterMap = form.products.filter(Item => Item.id !== Product.id);

        this.setState(state => ({
          form: {
            ...state.form,
            products: filterMap,
            subtotal: FloatNum(reduceMap(filterMap, "subtotal")),
            taxable: FloatNum(taxableMap(filterMap)),
            exempt: FloatNum(exemptMap(filterMap)),
            vat: FloatNum(reduceMap(filterMap, "vat")),
            total: FloatNum(reduceMap(filterMap, "total"))
          }
        }));

        this.saveOrder(); //Actualizo el state
      }
    );
  };

  removeAttribute = () => {
    this.setState({ showProvider: false }, () => this.props.removeAttribute());
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  componentWillUnmount() {
    if (timer) {
      clearTimeout(timer);
    }
    //this.saveOrder();
  }

  handleSerial = item => {
    this.setState({
      item_serial: item,
      edit_serial: true
    });
  }

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  }

  onSuccess = async item => {
    let products = [...this.state.form.products];
    const index = products.findIndex(i => i.id == this.state.item_serial.id);
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      form: {
        ...this.state.form,
        products: products
      }
    });
    this.handleNum({
      target: {
        value: item.length,
        name: 'quantity'
      }
    }, index);
  }

  render() {
    const { providers, currencies, branches, Id, havePermission, havePermissionProducts } = this.props;
    const { submitted, form, create, showProvider, warehouses, banks } = this.state;



    return (
      <React.Fragment>
        {this.state.edit_serial ? (
          <React.Fragment>
            {/*<EditSerial
              onSuccess={ this.onSuccess }
              onCancel={ this.onCancel }
              product={ this.state.item_serial } />  width: 700px, height: 237px*/}

            <ModalScan
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
              _quantity={this.state.item_serial._quantity}
              serials={this.state.item_serial.serials} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            {showProvider ? (
              <FormProvider
                Id={Id}
                provider={Provider =>
                  this.setState({ showProvider: false }, () => {
                    this.props.newProvider(Provider);
                  })
                }
                removeAttribute={() => this.removeAttribute}
              />
            ) : this.state.showProduct ? (
              <React.Fragment>
                <Button style={{ marginBottom: '10px' }} onClick={() => this.setState({
                  showProduct: false
                })}>
                  <Icon name="arrow-back" />
                  Volver
                </Button>
                <CreateEditProduct
                  companies={this.state.companies_products}
                  warehouses={this.state.warehouses_products}
                  edit={false}
                  onClose={() => this.setState({
                    showProduct: false
                  })}
                />
              </React.Fragment>
            ) : (
              <form onSubmit={this.handleSubmit}>
                <div className="text-center container-create-edit-order">
                  {create && (
                    <div className="row text-left">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="form-check">
                            <input
                              id="forthright"
                              type="checkbox"
                              name="forthright"
                              checked={this.state.form.forthright}
                              value={this.state.form.forthright}
                              onClick={async () => {
                                let boolean = this.state.form.forthright;
                                let products = [...form.products];
                                let change = [];
                                products = products.map((i, index) => {
                                  if (i.serialization == Constants.SERIALIZATION.ACTIVE && i.quantity > 0) {
                                    i.quantity = 0;
                                    i.serials = null;
                                    change.push({
                                      _index: index,
                                      quantity: i.quantity
                                    })
                                  }
                                  return i;
                                });

                                await this.setState(state => ({
                                  form: {
                                    ...state.form,
                                    forthright: !boolean,
                                    bill_date_emission: new Date(),
                                    products: products
                                  }
                                }));
                                change.forEach(i => {
                                  this.handleNum({
                                    target: {
                                      value: i.quantity,
                                      name: 'quantity'
                                    }
                                  }, i._index);
                                })
                              }}
                              className="form-check-input"
                            />
                            <label className="form-check-label" htmlFor="forthright">
                              Factura de compra
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md">
                      <Select
                        color="gray"
                        label="Proveedor"
                        name="provider_id"
                        options={providers}
                        disabled={!create}
                        value={this.state.form.provider_id}
                        error={`${this.hasErrorFor("provider_id") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("provider_id")}
                        onChange={this.handleChange}
                        icon={
                          create && havePermission ? (
                            <Button
                              color="blue"
                              type="button"
                              title="Agregar otro"
                              small="true"
                              onClick={() => {
                                let showProvider = !this.state.showProvider;
                                this.setState({ showProvider: showProvider }, () => {
                                  this.props.removeAttribute();
                                });
                              }}
                            >
                              <Icon
                                name={this.state.showProvider ? "minus" : "plus"}
                              />
                            </Button>
                          ) : null
                        }
                      />
                    </div>
                    {!form.forthright ? (
                      <div className="col-md">
                        <Input
                          type="text"
                          color="gray"
                          value={this.state.form.code}
                          placeholder="Código alfanumérico"
                          name="code"
                          maxLength="10"
                          error={`${this.hasErrorFor("code") ? "is-invalid" : ""}`}
                          invalidfeedback={this.renderErrorFor("code")}
                          disabled
                          label="Número de orden"
                          onChange={this.handleChange}
                        />
                      </div>
                    ) : (
                      <React.Fragment>
                        <div className="col-md">
                          <Input
                            type="text"
                            color="gray"
                            value={this.state.form.bill_number}
                            placeholder="Código alfanumérico"
                            name="bill_number"
                            maxLength="10"
                            error={`${this.hasErrorFor("bill_number") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("bill_number")}
                            label="Número de factura"
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md">
                          <Input
                            type="text"
                            color="gray"
                            value={this.state.form.control_number}
                            placeholder="Código alfanumérico"
                            name="control_number"
                            maxLength="10"
                            error={`${this.hasErrorFor("control_number") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("control_number")}
                            label="Número de control"
                            onChange={this.handleChange}
                          />
                        </div>
                      </React.Fragment>)}
                    {!create &&
                      (
                        <React.Fragment>
                          <div className="col-md">
                            <Input
                              type="text"
                              color="gray"
                              value={this.state.form.bill_number}
                              placeholder="Código alfanumérico"
                              name="bill_number"
                              maxLength="10"
                              error={`${this.hasErrorFor("bill_number") ? "is-invalid" : ""
                                }`}
                              invalidfeedback={this.renderErrorFor("bill_number")}
                              label="Número de factura"
                              onChange={this.handleChange}
                            />
                          </div>
                          <div className="col-md">
                            <Input
                              type="text"
                              color="gray"
                              value={this.state.form.control_number}
                              placeholder="Código alfanumérico"
                              name="control_number"
                              maxLength="10"
                              error={`${this.hasErrorFor("control_number") ? "is-invalid" : ""
                                }`}
                              invalidfeedback={this.renderErrorFor("control_number")}
                              label="Número de control"
                              onChange={this.handleChange}
                            />
                          </div>
                        </React.Fragment>)
                    }
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <Select
                        color="gray"
                        label="Divisa"
                        name="currency_id"
                        options={currencies}
                        value={this.state.form.currency_id}
                        disabled={
                          this.state.form.status === Constants.STATUS_INACTIVE
                        }
                        error={`${this.hasErrorFor("currency_id") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("currency_id")}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col col-md">
                      <Select
                        color="gray"
                        name="branch_id"
                        label="Sucursal"
                        value={this.state.form.branch_id}
                        disabled={
                          this.state.form.status === Constants.STATUS_INACTIVE
                        }
                        error={`${this.hasErrorFor("branch_id") ? "is-invalid" : ""}`}
                        invalidfeedback={this.renderErrorFor("branch_id")}
                        options={branches}
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md">
                      <Select
                        color="gray"
                        label="Almacen"
                        name="warehouse_id"
                        value={this.state.form.warehouse_id}
                        disabled={
                          this.state.form.status === Constants.STATUS_INACTIVE
                        }
                        error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("warehouse_id")}
                        options={warehouses.map(Item => {
                          return {
                            value: Item.id,
                            label: Item.name
                          };
                          console.log(this.state.form.warehouse_id)
                        })}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {create ? (
                    <div className="row">
                      <div className="col-md">
                        <DatePicker
                          color="gray"
                          label={
                            form.forthright ? "Fecha de Recepción" : "Fecha de Pedido"
                          }
                          minDate={this.state.dates.minRequestDate}
                          value={
                            form.forthright
                              ? this.state.form.bill_date_reception
                              : this.state.form.date_purchase
                          }
                          selected={
                            form.forthright
                              ? this.state.form.bill_date_reception
                              : this.state.form.date_purchase
                          }
                          disabled={form.forthright}
                          onChange={value => {
                            if (form.forthright) {
                              return;
                            }

                            this.setState(state => ({
                              form: {
                                ...state.form,
                                date_purchase: value,
                                date_delivery: ""
                              },
                              dates: {
                                ...state.dates,
                                minDeliveryDate: value
                              }
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md">
                        <DatePicker
                          color="gray"
                          label={
                            form.forthright ? "Fecha de Emisión" : "Fecha de Entrega"
                          }
                          minDate={
                            form.forthright ? "" : this.state.dates.minDeliveryDate
                          }
                          maxDate={
                            form.forthright ? this.state.form.bill_date_reception : ""
                          }
                          value={
                            form.forthright
                              ? this.state.form.bill_date_emission
                              : this.state.form.date_delivery
                          }
                          selected={
                            form.forthright
                              ? this.state.form.bill_date_emission
                              : this.state.form.date_delivery
                          }
                          onChange={value => {
                            const name = form.forthright
                              ? "bill_date_emission"
                              : "date_delivery";

                            this.setState(state => ({
                              form: {
                                ...this.state.form,
                                [name]: value
                              }
                            }));
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <div className="col-md">
                        <DatePicker
                          color="gray"
                          label="Fecha de Entrega"
                          value={this.state.form.date_delivery_real}
                          onChange={value => {
                            this.setState(state => ({
                              form: {
                                ...this.state.form,
                                date_delivery_real: value
                              }
                            }));
                          }}
                        />
                      </div>
                      <div className="col-md"></div>
                    </div>
                  )}
                  {
                    (form.forthright || !create) &&
                    (
                      <div className="row">
                        <div className="col-md-4">
                          <Select
                            color="gray"
                            label="Tipo de Pago"
                            name="payment_type"
                            options={PAYMENT_TYPE_LIST}
                            value={this.state.form.payment_type}
                            error={`${this.hasErrorFor("payment_type") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("payment_type")}
                            onChange={this.handleChange}
                          />
                        </div>

                      </div>
                    )
                  }
                  {
                    (form.forthright && parseInt(this.state.form.payment_type) === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH || (!create && parseInt(this.state.form.payment_type) === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH)) && (
                      <div className="row">
                        <div className="col-md-4">
                          <Select
                            color="gray"
                            label="Método de Pago"
                            name="method_type"
                            disabledFirst={false}
                            defaultname="Seleccione"
                            defaultValue=""
                            options={METHOD_TYPE_LIST}
                            value={this.state.form.method_type}
                            onClick={this.handleChange}
                            error={`${this.hasErrorFor("method_type") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("method_type")}
                            onChange={this.handleChange}
                          />
                        </div>

                        {(form.forthright && parseInt(this.state.form.method_type)
                          ===
                          Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.TRANSFER
                          ||
                          (!create
                            &&
                            parseInt(this.state.form.method_type)
                            ===
                            Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.TRANSFER)) &&
                          <div className="col-md-4">
                            <Input
                              label="Numero de referencia"
                              name="reference_number"
                              value={this.state.form.reference_number}
                              color="gray"
                              onKeyPress={emitter =>
                                Globals.soloNumeros(emitter)
                              }
                              onChange={this.handleChange}
                            />
                          </div>
                        }

                      </div>

                    )
                  }
                  <div>
                    {
                      this.state.form.method_type &&
                      (
                        <div className="row">
                          <div className="col-md-4">
                            <Select
                              color="gray"
                              label="Banco"
                              name="bank_id"
                              value={this.state.form.bank_id}
                              disabledFirst={false}
                              error={`${this.hasErrorFor("bank_id") ? "is-invalid" : ""
                                }`}
                              invalidfeedback={this.renderErrorFor("bank_id")}
                              options={banks}
                              // options={warehouses.map(Item => {
                              //   return {
                              //     value: Item.id,
                              //     label: Item.name
                              //   };
                              //   console.log(this.state.form.warehouse_id)
                              // })}
                              //defaultname="Seleccione"
                              //defaultValue=""                                  
                              onChange={this.handleChange}

                            // color="gray"
                            // label="Almacen"
                            // name="warehouse_id"
                            // value={this.state.form.warehouse_id}
                            // disabled={
                            //   this.state.form.status === Constants.STATUS_INACTIVE
                            // }
                            // error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""
                            //   }`}
                            // invalidfeedback={this.renderErrorFor("warehouse_id")}
                            // options={warehouses.map(Item => {
                            //   return {
                            //     value: Item.id,
                            //     label: Item.name
                            //   };
                            //   console.log(this.state.form.warehouse_id)
                            // })}
                            // onChange={this.handleChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <Select
                              color="gray"
                              label="Cuenta Bancaria"
                              name="bank_account_id"
                              value={this.state.form.bank_account_id}
                              disabledFirst={false}
                              error={`${this.hasErrorFor("bank_account_id") ? "is-invalid" : ""
                                }`}
                              invalidfeedback={this.renderErrorFor("bank_account_id")}
                              options={this.state.bank_accounts}
                              // options={warehouses.map(Item => {
                              //   return {
                              //     value: Item.id,
                              //     label: Item.name
                              //   };
                              //   console.log(this.state.form.warehouse_id)
                              // })}
                              onChange={this.handleChange}
                            //defaultname="Seleccione"
                            //defaultValue=""






                            // color="gray"
                            // label="Almacen"
                            // name="warehouse_id"
                            // value={this.state.form.warehouse_id}
                            // disabled={
                            //   this.state.form.status === Constants.STATUS_INACTIVE
                            // }
                            // error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""
                            //   }`}
                            // invalidfeedback={this.renderErrorFor("warehouse_id")}
                            // options={warehouses.map(Item => {
                            //   return {
                            //     value: Item.id,
                            //     label: Item.name
                            //   };
                            //   console.log(this.state.form.warehouse_id)
                            // })}
                            // onChange={this.handleChange}
                            />
                          </div>
                          <div className="col-md-4">
                            <InputFormat
                              color="gray"
                              disabled={true}
                              name="balance"
                              thousandSeparator={true}
                              allowNegative={false}
                              isNumericString={true}
                              decimalScale="2"
                              label="Saldo"
                              value={this.state.form.balance}
                              error={`${this.hasErrorFor("amount") ? "is-invalid" : ""}`}
                              invalidfeedback={this.renderErrorFor("amount")}
                            />

                          </div>

                        </div>
                      )
                    }
                  </div>

                  {create && form.warehouse_id && (
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          color="gray"
                          name="search"
                          label="Buscar Productos"
                          value={this.state.search}
                          onChange={this.handleChange}
                          icon={
                            havePermissionProducts && (
                              <Button
                                color="blue"
                                type="button"
                                title="Agregar otro"
                                small="true"
                                onClick={() => {
                                  let showProduct = !this.state.showProduct;
                                  this.setState({ showProduct });
                                }}
                              >
                                <Icon
                                  name={this.state.showProduct ? "minus" : "plus"}
                                />
                              </Button>
                            )
                          }
                        />
                        <div id="suggestion">
                          {this.state.suggestions.length > 0
                            ? this.state.suggestions.map((Item, key) => {
                              return (
                                <div
                                  key={key}
                                  onClick={() => this.takeProduct(Item)}
                                >
                                  {Item.name}
                                </div>
                              );
                            })
                            : null}
                        </div>
                      </div>
                    </div>
                  )}

                  {form.products.length > 0 && (
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <div className="table-responsive table-orders">
                            <table className="table table-bordered table-products table-sm">
                              <thead>
                                <tr>
                                  <td
                                    colSpan={!create || form.forthright ? "11" : "10"}
                                  >
                                    PRODUCTOS DE LA ORDEN / FACTURA
                                  </td>
                                </tr>
                                <tr style={{ whiteSpace: "nowrap" }}>
                                  <th>Código</th>
                                  <th>Nombre</th>
                                  <th>Descripción</th>
                                  <th>Cant.</th>
                                  <th>Cost x Ud.</th>
                                  {!create || form.forthright ? (
                                    <React.Fragment>
                                      <th>Precio Min.</th>
                                      <th>Precio Max.</th>
                                      <th>Precio Oferta</th>
                                    </React.Fragment>
                                  ) : null}
                                  <th>Total</th>
                                  <th>Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {form.products.map((Item, key) => {
                                  return (
                                    <tr key={key}>
                                      <td>{Item.code}</td>
                                      <td style={{ whiteSpace: "nowrap" }}>
                                        {Item.name}
                                      </td>
                                      <td>{Item.description}</td>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          type="text"
                                          name="quantity"
                                          disabled={Item.serialization == Constants.SERIALIZATION.ACTIVE && (!create || form.forthright)}
                                          className="form-control-sm"
                                          value={Item.quantity}
                                          maxLength={8}
                                          onKeyPress={emitter =>
                                            Globals.soloNumeros(emitter)
                                          }
                                          onChange={async emitter => {
                                            let products = [...this.state.form.products];
                                            products[key].serials = null;
                                            this.setState({
                                              edit_serial: false,
                                              form: {
                                                ...this.state.form,
                                                products: products
                                              }
                                            });
                                            this.handleNum(emitter, key)
                                          }}
                                        />
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        <InputFormat
                                          color="gray"
                                          name="unit_price"
                                          className="form-control-sm"
                                          thousandSeparator={true}
                                          allowNegative={false}
                                          isNumericString={true}
                                          decimalScale="2"
                                          maxLength={15}
                                          onKeyPress={emitter => {
                                            // Globals.soloNumeros(emitter)
                                          }}
                                          onValueChange={({ floatValue }) =>
                                            this.handlePrice(
                                              floatValue,
                                              key,
                                              "unit_price"
                                            )
                                          }
                                          // onChange={async emitter => {
                                          //   let products = [...this.state.form.products];
                                          //   products[key].serials = null;
                                          //   this.setState({
                                          //     edit_serial: false,
                                          //     form: {
                                          //       ...this.state.form,
                                          //       products: products
                                          //     }
                                          //   });
                                          //   this.handleNum(emitter, key)
                                          // }}
                                          value={Item.unit_price}
                                        />
                                      </th>

                                      {!create || form.forthright ? (
                                        <React.Fragment>
                                          <th style={{ whiteSpace: "nowrap" }}>
                                            <InputFormat
                                              color="gray"
                                              name="earning"
                                              className="form-control-sm"
                                              thousandSeparator={true}
                                              allowNegative={false}
                                              isNumericString={true}
                                              decimalScale="2"
                                              maxLength={15}
                                              onKeyPress={emitter => {
                                                // Globals.soloNumeros(emitter)
                                              }}
                                              onValueChange={({ floatValue }) =>
                                                this.handlePrice(
                                                  floatValue,
                                                  key,
                                                  "earning"
                                                )
                                              }
                                              value={Item.earning}
                                            />
                                          </th>
                                          <th style={{ whiteSpace: "nowrap" }}>
                                            <InputFormat
                                              color="gray"
                                              name="earning_max"
                                              className="form-control-sm"
                                              thousandSeparator={true}
                                              allowNegative={false}
                                              isNumericString={true}
                                              decimalScale="2"
                                              maxLength={15}
                                              onKeyPress={emitter => {
                                                // Globals.soloNumeros(emitter)
                                              }}
                                              onValueChange={({ floatValue }) =>
                                                this.handlePrice(
                                                  floatValue,
                                                  key,
                                                  "earning_max"
                                                )
                                              }
                                              value={Item.earning_max}
                                            />
                                          </th>
                                          <th style={{ whiteSpace: "nowrap" }}>
                                            <InputFormat
                                              color="gray"
                                              name="earning_offer"
                                              className="form-control-sm"
                                              thousandSeparator={true}
                                              allowNegative={false}
                                              isNumericString={true}
                                              decimalScale="2"
                                              maxLength={15}
                                              onKeyPress={emitter => {
                                                // Globals.soloNumeros(emitter)
                                              }}
                                              onValueChange={({ floatValue }) =>
                                                this.handlePrice(
                                                  floatValue,
                                                  key,
                                                  "earning_offer"
                                                )
                                              }
                                              value={Item.earning_offer}
                                            />
                                          </th>
                                        </React.Fragment>
                                      ) : null}

                                      <th>{Globals.formatMiles(Item.subtotal)}</th>

                                      <th>
                                        <div className="container-btn-actions">
                                          <Button
                                            color="red"
                                            small="true"
                                            title="Remover"
                                            type="button"
                                            onClick={() => {
                                              this.handleClick(Item);
                                            }}
                                          >
                                            <Icon name="minus" />
                                          </Button>

                                          {/*
                                            Boton de serialización, se muestra solo si es factura de compra y se puede serializar el producto
                                          */}

                                          {(!create || form.forthright == 1)
                                            && Item.serialization == Constants.SERIALIZATION.ACTIVE
                                            && <Button
                                              color={!Item.serials ? 'yellow' : 'green'}
                                              small="true"
                                              title={!Item.serials ? 'Serializar' : "Serializado"}
                                              type="button"
                                              className="btn-actions-orders"
                                              onClick={() => {
                                                this.handleSerial(Item);
                                              }}
                                            >
                                              <Icon name="list" />
                                            </Button>}
                                        </div>
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md">
                      <Textarea
                        label="Observaciones"
                        name="observations"
                        value={this.state.form.observations}
                        error={`${this.hasErrorFor("observations") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("observations")}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {form.products.length > 0 && (
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th style={{ width: "25%" }}>SUBTOTAL:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.subtotal)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>EXENTO:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.exempt)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>BASE IMPONIBLE:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.taxable)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>IVA:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.vat)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>TOTAL:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.total)}
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md">
                      {submitted ? (
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                        <div id="button">
                          <Button block="true" type="submit" disabled={submitted}>
                            {this.state.textButton}
                          </Button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </form>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }

}

function arrayTransform(Items, warehouse_id) {
  return Items.map((Product, key) => {
    let percentages = Product.product_warehouse.filter(
      Item => Item.warehouse_id === warehouse_id
    );

    let rate = Product.pivot.subtotal / Product.pivot.quantity;
    let percentage_earning = (percentages[0] || {}).percentage_earning
      ? (percentages[0] || {}).percentage_earning / 100
      : 0;
    let percentage_max = (percentages[0] || {}).percentage_max
      ? (percentages[0] || {}).percentage_max / 100
      : 0;
    let percentage_offer = (percentages[0] || {}).percentage_offer
      ? (percentages[0] || {}).percentage_offer / 100
      : 0;

    let earning = rate * percentage_earning;
    let earning_max = rate * percentage_max;
    let earning_offer = rate * percentage_offer;

    return {
      id: Product.id,
      Id: Product.pivot.id,
      product_id: Product.id,
      code: Product.code,
      name: Product.name,
      exempt: Product.exempt,
      percentage_earning: percentage_earning,
      percentage_max: percentage_max,
      percentage_offer: percentage_offer,
      earning: rate + earning,
      earning_max: rate + earning_max,
      earning_offer: rate + earning_offer,
      price: rate,
      unit_price: rate,
      description: Product.description,
      quantity: Product.pivot.quantity,
      quantity_real: Product.pivot.quantity,
      subtotal: Product.pivot.subtotal,
      subtotal_real: Product.pivot.subtotal,
      vat: Product.pivot.vat,
      vat_real: Product.pivot.vat,
      total: Product.pivot.total,
      total_real: Product.pivot.total,
      serialization: Product.serialization,
    };
  });
}

function taxableMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_ACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function exemptMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_INACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function reduceMap(Items, name) {
  return Items.reduce((value, product) => {
    return value + parseFloat(product[name]);
  }, 0);
}

function FloatNum(number) {
  return number.toFixed(2);
}

function FloatNum4Decimal(number) {
  return number.toFixed(4);
}

export default CreateEditOrder;
