import React from "react";
import $ from "jquery";

class Button extends React.Component {
  componentDidMount() {
    $('[data-toggle="tooltip"]').tooltip({ trigger: "hover" }); // Por defecto tenia  hide
  }

  componentDidUpdate() {
    $('[data-toggle="tooltip"]').tooltip("hide");
  }

  componentWillUnmount() {
    $('[data-toggle="tooltip"]').tooltip("hide");
  }

  render() {
    const { submitted } = this.props;

    return (
      <React.Fragment>
        {!submitted ? (
          <button
            {...this.props}
            title={this.props.title}
            data-toggle="tooltip"
            data-placement="bottom"
            className={`btn btn-primary ${this.props.className}
              ${this.props.outline ? "btn-outline" : ""}
              ${this.props.clear ? "btn-clear" : ""}
              ${this.props.color ? "btn-" + this.props.color : "btn-blue"}
              ${this.props.small ? "btn-small" : ""}
              ${this.props.block ? "btn-block" : ""}`}
          >
            {this.props.children}
          </button>
        ) : (
          <div className="spinner-border text-primary" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Button;
