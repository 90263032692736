import React from "react";
import { connect } from "react-redux";

import { axios, Globals, Constants } from "../../utils";
import { Button, Select, Input, Textarea } from "../../components";

// Components
import SelectCustom from "../../components/select";

class CreateEditProvider extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        company: "",
        contact_person: "",
      },
      textButton: "Crear",
      retention_types: [],
      enterprises: []
    };
  }

  fiscal_docs = {
    document_type: "V",
    document: ""
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      //retention_types: this.props.retentions,
      //enterprises: this.props.enterprises
    });
    if (this.props.edit) {
      this.edit();
    }
  }

  submit = async () => {
    let param = { ...this.state.form };
    param.user_id = this.props.user.id;
    if (this.props.user.role === 3) {
      param.enterprise_id = this.props.user.id;
    }
    if (this.props.user.role === 4) {
      param.enterprise_id = this.props.user.enterprise_users.enterprise_id;
    }
    Globals.setLoading();
    axios
      .post(
        this.props.edit ? "admin/providers/edit" : "admin/providers/create",
        param
      )
      .then(res => {
        this.setState({
          form: {}
        });
        let msg = ''
        this.props.edit ? msg = "Proveedor editado" : msg = "Proveedor creado"
        Globals.showSuccess(msg);
        this.props.onClose();
      })
      .catch(err => {
        if (err.response.status === 422) {
          Globals.showError(err.response.data.error);
          return;
        }
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    console.log(this.props.edit)
    if (this.props.edit.element.document) {
      let division = this.props.edit.element.document.split("-");
      this.ident_docs = {
        document_type: division[0],
        document: division[1]
      };
    }

    await this.setState({
      form: {
        id: this.props.edit.element.id,
        provider_id: this.props.edit.element.id,
        name: this.props.edit.element.name,
        company: this.props.edit.element.company,
        email: this.props.edit.element.email,
        phone: this.props.edit.element.phone,
        address: this.props.edit.element.address,
        //fiscal_document_type: this.ident_docs.document_type,
        //document: this.ident_docs.document,
        document: this.props.edit.element.document,
        contact_person: this.props.edit.element.contact_person,
        comments: this.props.edit.element.comments,
      },
      textButton: "Editar"
    });
  };

  change = e => {
    this.setState({
      form: {
        //fiscal_document_type: this.fiscal_docs.document_type,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  showSelect = () => {
    var select = null;
    if (this.props.user.role === 1 || this.props.user.role === 2) {
      select = (
        <SelectCustom
          color="white"
          label="Caja"
          name="enterprise_id"
          defaultname="Seleccione"
          className="material"
          labelClass="material"
          onChange={this.change}
          value={
            this.state.form.enterprise_id ? this.state.form.enterprise_id : ""
          }
          options={this.state.enterprises.map(el => {
            return {
              value: el.id,
              label: el.name
            };
          })}
        />
      );
    }

    return select;
  };

  setSelectedOption = async (value, key) => {
    this.setState({
      form: {
        ...this.state.form,
        [key]: value
      }
    });
    //if (key === "fiscal_document_type") this.fiscal_docs.document_type = value;
  };

  render() {
    return (
      <div className="container-create-edit-user">
        {/* {this.showSelect()} */}
        <div className="row">
          {/* <div className="col-md-12">
            <Input
              color=" "
              value={this.state.form.name}
              name="name"
              label="Nombre/Empresa"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6">
            {/* <Input
              color=" "
              value={this.state.form.company}
              name="company"
              label="Empresa"
              className="material"
              labelClass="material"
              onChange={this.change}
            /> */}
            <Input
              color=" "
              value={this.state.form.name}
              name="name"
              label="Nombre o Empresa"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <div style={{ display: "flex" }}>
              <Input
                color=" "
                value={this.state.form.document}
                name="document"
                label="RIF"
                className="material"
                labelClass="material"
                onKeyPress={e => {
                  Globals.soloRifDocument(e);
                }}
                maxLength={14}
                onChange={this.change}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-6">
            <Select
              color="white"
              label="Tipo de personalidad"
              name="person_type"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              onChange={e =>
                this.setSelectedOption(e.target.value, e.target.name)
              }
              value={
                this.state.form.person_type ? this.state.form.person_type : ""
              }
              options={Constants.TYPE_PERSON}
            />
          </div> */}
          {/* <div className="col-md-6">
            <Select
              color=" "
              label="Tipo de Retención IVA"
              name="retention_type_id"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              onChange={e =>
                this.setSelectedOption(e.target.value, e.target.name)
              }
              value={
                this.state.form.retention_type_id
                  ? this.state.form.retention_type_id
                  : ""
              }
              options={
                this.state.retention_types
                  ? this.state.retention_types.map(i => {
                      return {
                        value: i.id,
                        label: i.percentage
                      };
                    })
                  : ""
              }
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color=" "
              value={this.state.form.phone}
              name="phone"
              label="Teléfono"
              className="material"
              labelClass="material"
              maxLength={11}
              onKeyPress={e => {
                Globals.soloNumeros(e);
              }}
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <Input
              color=" "
              value={this.state.form.email}
              name="email"
              type="email"
              label="E-Mail"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col col-md">
            <Textarea
              label="Direccion"
              name="address"
              value={this.state.form.address}
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Input
              color=" "
              value={this.state.form.contact_person}
              name="contact_person"
              type="text"
              label="Persona de Contacto"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Input
              color=" "
              value={this.state.form.comments}
              name="comments"
              type="text"
              label="Información Adicional"
              className="material"
              labelClass="material"
              onChange={this.change}
            />
          </div>
        </div>
        <div id="button" className="d-flex justify-content-center">
          <Button color=" " className="primary" type="button" onClick={() => this.submit()}>
            <span>{this.state.textButton}</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditProvider);
