/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Icon, Input, Select, SelectActions } from "../../components";
import { axios, Constants, Globals } from "../../utils";

class CreateEditTransferWarehouse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      create: !!props.edit ? false : true,
      form: {
        search: '',
        user_id: props.user.id,
        origin_warehouse_id: "",
        destiny_warehouse_id: "",
        products: []
      },
      warehouses: [],
      products: [],
      textButton: "Procesar",
      viewSerials: false,
      itemSerials: null
    }
  }

  componentDidMount() {
    this.getWarehouses();
    if (!!this.props?.edit) this.edit();
  }

	getWarehouses = () => {
		axios
			.post("web/admin/warehouses", 
        { 
          user_id: this.props.user.id, 
          is_select: true, 
          all_types: true,
          without_autosale: true, 
        }
      )
			.then(res => {
				if (res.data.warehouses) {
					const formatWarehouses = res.data.warehouses.map(item => ({
            ...item,
            value: item.id,
            label: item.name,
          }));

					const mainIdx = formatWarehouses.findIndex(x => x.id === this.props.user.warehouse_principal);
					if (mainIdx >= 0) {
						const main = formatWarehouses[mainIdx];
						formatWarehouses.splice(mainIdx, 1);
						formatWarehouses.unshift(main);
					}

					this.setState({
						warehouses: formatWarehouses,
					});
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

  handleChange = async (emitter, index) => {
    const { name, value } = emitter.target;
    const form = Globals.copy(this.state.form);

    if (name === 'origin_warehouse_id') {
      if (!!value) {
        await this.setState({
          products: this.state.warehouses
            .find(item => item.id === parseInt(value)).products
            .map(item => ({
              id: item.product_id,
              name: item.product?.name ?? '',
              stock: item.total_quantity,
              code: item.product?.code,
              type: item.product?.type,
              size_colors: item.product?.size_colors ?? [],
              quantity: '',
            })),
        });

      } else {
        await this.setState({ products: [] });
      }
      form.products = [];
    }

    if (index !== undefined) {
      form.products[index][name] = value;

      if (['size_id','color_id'].includes(name)) {
        const sizeColor = form.products[index]?.size_colors?.find(i => {
          return i.size_id == form.products[index].size_id && i.color_id == form.products[index].color_id;
        });

        form.products[index].stock = sizeColor?.stock || 0;
      }

    } else {
      form[name] = value;
    }

    await this.setState({ form });
  }

  handleDelete = (index) => {
    const form = Globals.copy(this.state.form);
    const products = form.products.filter((_, i) => i !== index);
    this.setState({ form: { ...form, products } });
  }

  onSelectProduct = (productId) => {
    const product = this.state.products.find(item => item.id === productId);

    this.setState((state) => ({
      form: {
        ...state.form,
        search: '',
        products: [
          ...state.form.products,
          Globals.copy({
            ...product,
            stock: product.type === Constants.PRODUCTS_TYPE.NORMAL ? product.stock : 0,
          }),
        ],
      },
    }));
  }

  isValidForm = () => {
    const { form } = this.state;
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    if (!form.origin_warehouse_id)
      return onError('Seleccione un almacén de origen');

    if (!form.destiny_warehouse_id)
      return onError('Seleccione un almacén de destino');

    if (!form.products.length)
      return onError('Seleccione al menos un producto');

    if (form.products.some(item => !Number(item.quantity) || isNaN(Number(item.quantity))))
      return onError('Debe ingresar la cantidad a transferir de al menos un producto');

    if (form.products.some(item => item.quantity > item.stock))
      return onError('La cantidad a transferir no puede ser mayor al stock del producto');

    if (form.products.some(item => item.type === Constants.PRODUCTS_TYPE.SIZE_COLOR && !item.size_id && !item.color_id))
      return onError('Debe seleccionar una talla/color para los productos de tipo talla/color');

    const byNormal = form.products.filter(item => item.type === Constants.PRODUCTS_TYPE.NORMAL);
    const bySizeColor = form.products.filter(item => item.type === Constants.PRODUCTS_TYPE.SIZE_COLOR);

    let duplicated = byNormal.some(item => {
      return form.products.filter(i => i.id === item.id).length > 1;
    });

    if (duplicated)
      return onError('Hay productos duplicados en el listado');

    duplicated = bySizeColor.some(item => {
      return form.products.filter(i => i.id === item.id && i.size_id === item.size_id && i.color_id === item.color_id).length > 1;
    });

    if (duplicated)
      return onError('No puede seleccionar el mismo producto con la misma talla y color');

    return true;
  }

  handleSubmit = (e) => {
    e.preventDefault();
    if (!this.isValidForm()) return;
    this.setState({ submitted: true });
    Globals.setLoading();

    axios
      .post('web/admin/warehouses/transfers/save', this.state.form)
      .then(res => {
        Globals.showSuccess('Transferencia realizada');
        this.props.onClose();
      })
      .catch(err => {
        Globals.showError(err?.response?.data?.error);
      })
      .finally(() => {
        this.setState({ submitted: false });
        Globals.quitLoading();
      });
  }

  render() {

    const { submitted, form, warehouses, products } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="row">
          <div className="col-6">
            <Select
              label="Almacen de Origen"
              name="origin_warehouse_id"
              className="material"
              labelClass="material"
              options={warehouses.filter(x => x.id !== parseInt(form.destiny_warehouse_id))}
              value={form.origin_warehouse_id}
              onChange={this.handleChange}
            />
          </div>

          <div className="col-6">
            <Select
              label="Almacen de Destino"
              name="destiny_warehouse_id"
              className="material"
              labelClass="material"
              options={warehouses.filter(x => x.id !== parseInt(form.origin_warehouse_id))}
              value={form.destiny_warehouse_id}
              onChange={this.handleChange}
            />
          </div>

          <div className="col-12">
            <SelectActions
              label="Producto"
              placeholder="Nombre o Código del Producto"
              isSearchable
              options={products.map(item => ({
                ...item,
                value: item.id,
                label: item.name,
                actions: [],
              }))}
              value={form.search}
              onChange={this.onSelectProduct}
            />
          </div>
        </div>

        <div className="pt-3">
          <p className="text-left">Productos a transferir</p>
          <hr className="mt-0" />

          {form.products.map((item, index) => (
            <>
              <div className="row" style={{ alignItems: 'flex-start' }}>
                <div className="col-7">
                  <div>{ item.name }</div>
                  <div style={{ fontSize: 12 }}>Cantidad: {item.stock}</div>
                </div>
                <div className="col-4">
                  <Input
                    type="number"
                    color=" "
                    name="quantity"
                    label="Cantidad a transferir"
                    className="material"
                    min="1"
                    step="1"
                    value={item.quantity}
                    onChange={e => this.handleChange(e, index)}
                  />
                </div>
                <div className="col-1" style={{ 'padding': 0, height: 87, display: 'flex', alignItems: 'center' }}>
                  <Button
                    type="button"
                    color="red"
                    title="Eliminar de la lista"
                    small="true"
                    onClick={() => this.handleDelete(index)}
                  >
                    <Icon name="trash" />
                  </Button>
                </div>
                {item?.type === Constants.PRODUCTS_TYPE.SIZE_COLOR && (
                  <div className="col-12">
                    <SizeColorForm
                      product={item}
                      onChange={(value, target) => this.handleChange({target: { name: target, value }}, index)}
                    />
                  </div>
                )}
              </div>
              <hr className="mt-1" />
            </>
          ))}

          {!form.products.length && (
            <p className="text-center">No hay productos seleccionados</p>
          )}

          <div className="row mt-5">
            <div className="col-md">
              {submitted ? (
                <div className="spinner-border text-primary" role="status">
                  <span className="sr-only">Procesando...</span>
                </div>
              ) : (
                <div id="button">
                  <Button block="true" type="submit" disabled={submitted}>
                  { this.state.textButton }
                  </Button>
                </div>
              )}
            </div>
          </div>
        </div>
      </form>
    );
    }
}

const SizeColorForm = ({ product, onChange }) => {

  const [colors, setColors] = useState([]);
  const [sizes, setSizes] = useState([]);

  function getUniqueItems() {
    const _colors = product.size_colors.filter(i => i.color_id).reduce((prev, curr) => prev.set(curr.color_id, curr.color), new Map());
    const _sizes = product.size_colors.filter(i => i.size_id).reduce((prev, curr) => prev.set(curr.size_id, curr.size), new Map());

    setColors([ ..._colors.values() ]);
    setSizes([ ..._sizes.values() ]);
  }

  useEffect(() => {
    getUniqueItems();
  }, []);

  return (
    <div className="size-colors-form">
      {!!sizes.length && (
        <div className="sizes">
          <Select
            label="Talla"
            labelClass="material"
            className="material"
            disabledFirst
            options={ sizes.map(item => ({ value: item.id, label: item.name })) }
            value={ product.size_id }
            onChange={e => onChange(Number(e.target.value), 'size_id')}
          />
        </div>
      )}

      {!!colors.length && (
        <div className="colors">
          <SelectActions
            label="Color"
            options={colors.map(item => ({
              value: item.id,
              label: (<div className="circle" style={{ backgroundColor: item.hex }} />),
              actions: [],
            }))}
            value={product.color_id}
            onChange={id => onChange(Number(id), 'color_id')}
          />
        </div>
      )}
    </div>
  );
}

export default connect(state => ({
  user: state.user,
}))(CreateEditTransferWarehouse);