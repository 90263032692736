import React from 'react';
import moment from 'moment';
import { Button } from '../../components';
import { Globals, Constants } from '../../utils';
import DownloadIcon from '../../assets/icons/download.png';
import PdfIcon from '../../assets/icons/pdf.png';

class ViewPayments extends React.Component {

	state = {
		order: this.props.order
	}

	isVoucherPDF = (file) => {
		return !!file ? String(file).endsWith('.pdf') : false;
	}

  downloadVoucher = (photo) => {
    let link = document.createElement('a');
    link.href = Globals.fromPhotos(photo);
    link.download = 'Comprobante';
    link.target = '_blank';
    link.click();
    link.remove();
  }

	render() {
		const { order } = this.state;

		const is_to_pay = order.payments?.length > 0 || order.status_payment == Constants.PAYMENT_TYPES.TO_PAY;

		return (
			<div id="modal-view-payments">
				<div>
					{
						order.payment_methods?.length > 0 && (
							<div className="order__payment">
								{
									is_to_pay ? (
										<div className="title">Pagos iniciales:</div>
									) : (
										<div className="title">Pagos al contado:</div>
									)
								}

								{
									order.payment_methods?.map((item,index) => {
										const method = this.props.methods.find(i => i.value == item.method_id);

										return (
											<div className="item order__payment">
												<div>
													<span className="title">Pago:</span>
													<span>{ Globals.formatMiles(item.amount) }</span>
												</div>
												{method && (
													<div>
														<span className="title">Método:</span>
														<span>{ method?.label }</span>
													</div>
												)}
												<div>{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }</div>

												{!!order?.file && (
													<div className="voucher" onClick={() => this.downloadVoucher(order?.file)}>
														<div className="voucher-content">
															<img
																src={this.isVoucherPDF(order?.file) ? PdfIcon : Globals.fromPhotos(order?.file)}
																className="voucher-image"
																alt="Comprobante.pdf"
															/>
															<span>Comprobante</span>
														</div>
														<div className="voucher-icon">
															<img src={DownloadIcon} alt="Descargar comprobante" />
														</div>
													</div>
												)}
											</div>
										)
									})
								}
							</div>
						)
					}

					{
						is_to_pay && (
							<div className="order__payment">
								<div className="title">Pagos por cuotas:</div>

								{
									order.payments?.map((item,index) => {
										return (
											<div className="item">
												<div>
													<span className="title">Pago:</span>
													<span>{ Globals.formatMiles(item.amount) }</span>
												</div>
												{item.method && (
													<div>
														<span className="title">Método:</span>
														<span>{ item.method?.name }</span>
													</div>
												)}
												<div>{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }</div>

												{!!item?.photo && (
													<div className="voucher" onClick={() => this.downloadVoucher(item?.photo)}>
														<div className="voucher-content">
															<img
																src={this.isVoucherPDF(item?.photo) ? PdfIcon : Globals.fromPhotos(item?.photo)}
																className="voucher-image"
																alt="Comprobante.pdf"
															/>
															<span>Comprobante</span>
														</div>
														<div className="voucher-icon">
															<img src={DownloadIcon} alt="Descargar comprobante" />
														</div>
													</div>
												)}
											</div>
										)
									})
								}

								{!order.payments?.length && (
									<div className="no-items">No se han registrado pagos de cuotas</div>
								)}
							</div>
						)
					}
				</div>
				<Button color="gray" type="button" block onClick={ () => this.props.onClose() }>
					<span>CERRAR</span>
				</Button>
			</div>
		)
	}
}

export default ViewPayments;