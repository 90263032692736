/* eslint-disable default-case */
import React from "react";
import { connect } from "react-redux";
import { on } from "jetemit";
import {
  Select,
  Input,
  Button,
  Modal,
  Icon,
  EditSerial,
  ModalScan,
  InputGroup,
} from "../../components"; 
import {
  Globals,
  Format,
  ReduceMap,
  Constants,
  Payments,
  ENV,
  axios
} from "../../utils";

import { Branch, Request, Warehouse, Product, Sale, Client } from "../../services";

// Components
import Menu from "../menu";
import FormClient from "../requests/formClient";
import Filter from "./filterForm";
import Payment from "./payment";

const IVA = 0.16;

class Sales extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      submitted: false,
      visible: false,
      uglyModal: false,
      visiblePayment: false,
      showCustomer: false,
      search: "",
      searchClient: "",
      filteredClients: false,
      Item: {},
      form: {
        code: "",
        client_id: "",
        branch_id: "",
        warehouse_id: "",
        type_payments: [],
        discount_percentage: 0,
        subtotal: 0,
        discount: 0,
        taxable: 0,
        exempt: 0,
        vat: 0,
        total: 0,
        products: [],
        creator_id: props.user.id,
        search: "",
      },
      errors: [],
      customers: [],
      branches: [],
      suggestions: [],
      clientSuggestions: [],
      textButton: "Entrar",
      warehouses: [],
      edit_serial: false,
      item_serial: null,
      showModalScan: false,
      itemSelected: null,
      unsubscriber: null,
      showSavedText: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      client_selected: '',
      suggestions_client: [],
    };
  }

  componentDidMount() {
    const { sales } = this.props;
    this.load(sales);

    const unsubscriber = on(Constants.SCANNER, code => {
      const {
        showModalScan,
        step,
        showCustomer,
        visiblePayment,
        visible,
        uglyModal
      } = this.state;

      if (
        !visiblePayment &&
        !showCustomer &&
        !showModalScan &&
        !visible &&
        !uglyModal &&
        step === Constants.STEP_FORM
      ) {
        this.getOneProductByCode(code);
      }
    });

    this.setState({
      unsubscriber: unsubscriber
    });

    document.addEventListener("keydown", this.onKeyDown, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyDown, false);

    if (this.state.unsubscriber) {
      this.state.unsubscriber();
    }

    this.props.dispatch({
      type: 'SET_SALES',
      payload: { ...this.state.form }
    });
  }

  onKeyDown = event => {
    const {
      showModalScan,
      step,
      showCustomer,
      visiblePayment,
      visible,
      uglyModal,
      itemSelected,
      form
    } = this.state;

    if (
      !visiblePayment &&
      !showCustomer &&
      !showModalScan &&
      !visible &&
      !uglyModal &&
      step === Constants.STEP_FORM
    ) {
      switch (event.key) {
        case "F8":
          event.preventDefault();
          if (itemSelected) {
            const product = form.products.find(i => i.id == itemSelected);
            if (product.serialization == Constants.SERIALIZATION.ACTIVE)
              this.setState({
                showModalScan: true
              });
          }
          break;

        case "ArrowUp":
          event.preventDefault();
          if (itemSelected && form.products.length > 0) {
            const index = form.products.findIndex(i => i.id == itemSelected);

            if (index > 0) {
              this.setState({
                itemSelected: form.products[index - 1].id
              });
            }
          }
          break;

        case "ArrowDown":
          event.preventDefault();
          if (itemSelected && form.products.length > 0) {
            const index = form.products.findIndex(i => i.id == itemSelected);

            if (index < form.products.length - 1) {
              this.setState({
                itemSelected: form.products[index + 1].id
              });
            }
          }
          break;
      }
    }
  };

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }

    if (prevState.step !== this.state.step) {
      //this.maybeLoadCustomers();
    }

    if (prevState.uglyModal !== this.state.uglyModal && this.state.uglyModal) {
      this.generateId();
    }
  }

  load = (sales = null) => {
    const { user } = this.props;
    const id = (user.enterprise_users || {}).enterprise_id || user.id;
    const userId = Globals.getUserId(user);
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const zonesId = Globals.getZones(user);

    const getCustomers =
      user.role === Constants.ROLE_SELLER
        ? Request.getCustomersBySeller({
          zones_id: zonesId,
          enterprise_id: enterpriseId
        })
        : Request.getCustomersByEnterprise({ Id: enterpriseId });

    Sale.getSaleType({ Id: id })
      .then(result => (result ? result : 2))
      .then(({ sale_type }) => {
        Promise.all([
          Branch.getBranchesByEnterprise({ Id: id }),
          Sale.getId({ Id: userId, sale_type: sale_type }),
          //getCustomers,
        ])
          .then(response => {
            let branches_filtered = response[0].filter(
              ({ deleted_at }) => !deleted_at
            );

            branches_filtered = Format.rawBranches(
              branches_filtered,
              user.assigned_warehouses,
              user.role
            );

            const branchesMap = branches_filtered
              .filter(({ status }) => status === Constants.STATUS_ACTIVE)
              .map(({ id, name }) => ({
                value: id,
                label: name
              }));

            //const customersMap = response[2];
            //console.log('customersMap: ', customersMap)

            const formValues = sales ? { ...sales } : this.state.form;
            this.props.dispatch({
              type: 'SET_SALES',
              payload: formValues
            });
            this.setState(state => ({
              branches: branchesMap,
              customers: [],//customersMap,
              form: {
                ...formValues,
                sale_type: sale_type,
                code: response[1]
              }
            }));

          })
          .catch(() => Globals.showError());
      })
      .catch(() => Globals.showError());


  };

  getOneProductByCode = code => {
    const { user } = this.props;
    const { form } = this.state;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Product.getOneProductByCode({
      user_id: userId,
      code: code,
      warehouse_id: form.warehouse_id
    })
      .then(response => {
        const configuration = Globals.getRate(user);
        const parseMap = Format.rawProducts(configuration, [response]);
        let product = parseMap[0];
        if (product.serialization == Constants.SERIALIZATION.ACTIVE) {
          product.quantity = 0;
          product.subtotal = 0;
          product.total = 0;
          product.vat = 0;
        }
        this.takeProduct(product);
      })
      .catch(err => {
        if (err.msg) Globals.showError(err.msg);
      });
  };

  generateId = () => {
    const { user } = this.props;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Sale.getSaleType({ Id: userId })
      .then(({ sale_type }) => sale_type)
      .then(sale_type => {
        Sale.getId({ Id: userId, sale_type: sale_type }).then(response => {
          this.setState(state => ({
            form: {
              ...state.form,
              code: response
            }
          }));
        });
      })
      .catch(() => Globals.showError());
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;

    const Id = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: Id,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: this.props.sales ? this.props.sales.warehouse_id : ""
        },
        warehouses: warehousesMap
      }));
    });
  };

  maybeLoadCustomers = () => {
    const { user } = this.props;

    const enterprise_id = Globals.getUserId(user);
    const zonesId = Globals.getZones(user);

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    // const getCustomers =
    //   user.role === Constants.ROLE_SELLER
    //     ? Request.getCustomersBySeller({
    //       zones_id: zonesId,
    //       enterprise_id: userId
    //     })
    //     : Request.getCustomersByEnterprise({ Id: enterprise_id });

    // getCustomers
    //   .then(response => {
    //     const customersMap = response
    //       .filter(
    //         Item => (Item || {}).status === Constants.STATUS_ACTIVE
    //       )
    //       .map(Item => {
    //         return {
    //           value: Item.id,
    //           label:
    //             (Item || {}).first_name ||
    //             (Item || {}).name
    //         };
    //       });

    //     this.setState({ customers: customersMap });
    //   })
    //   .catch(() => Globals.showError());
  };

  searchCustomers = ({ searchClient }) => {
    const { user } = this.props;
    console.log(user.role)
    console.log(Constants.ROLE_SELLER)

    const enterprise_id = Globals.getUserId(user);
    const zonesId = Globals.getZones(user);

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    // const getCustomers =
    //   user.role === Constants.ROLE_SELLER
    //     ? Request.getCustomersBySellerIdentification({
    //       zones_id: zonesId,
    //       enterprise_id: userId,
    //       search: searchClient
    //     })
    //     : Request.getCustomersByEnterpriseIdentification({ Id: enterprise_id, search: searchClient });

    // getCustomers
    //   .then(response => {
    //     console.log(response);
    //     const customersMap = response
    //       .filter(
    //         Item => ((Item || {}).status === Constants.STATUS_ACTIVE) && Item
    //       );
    //     this.setState({ clientSuggestions: customersMap });
    //   })
    //   .catch(() => Globals.showError());

  };

  setClientSuggestions = (item) => {
    const { client } = item;

    console.log('client');
    console.log(client);
    if (client) {
      this.setState({
        searchClient: "",
        clientSuggestions: [],
        form: {
          ...this.state.form,
          client_id: client.id
        }
      });
    } else {
      Globals.showError(
        `¡Este cliente fue eliminado!`
      );
      return;
    }
  }

  firstElement = emitter => {
    emitter.preventDefault();
    const { suggestions: Products } = this.state;

    if (Products.length === 0 || (Products[0] || {}).last_movement === 0) {
      if ((Products[0] || {}).last_movement === 0) {
        Globals.showWarning(
          `¡El producto ${Products[0].name} no posee stock en almacén!`
        );
      }
      return;
    }

    this.takeProduct(Products[0]);
  };

  firstElementClient = emitter => {
    emitter.preventDefault();
    const { clientSuggestions } = this.state;
    this.setClientSuggestions(clientSuggestions[0]);
  };

  AutoComplete = ({ search }) => {
    // ---- Se limito la cantidad de consultas por milisegudo para evitar multiples consultas en el escaneo de código de barra

    if (window.preventDuplicateKeyPresses) return;

    window.preventDuplicateKeyPresses = true;
    window.setTimeout(function () {
      window.preventDuplicateKeyPresses = false;
    }, 150);

    // ----

    const { user } = this.props;
    const { form } = this.state;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;
    const configuration = Globals.getRate(user);

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    Product.getProductsByCode({
      user_id: userId,
      search: search,
      warehouse_id: form.warehouse_id
    })
      .then(response => {
        const parseMap = Format.rawProducts(configuration, response);

        this.setState({ suggestions: parseMap });
      })
      .catch(err => {
        console.log(err);
      });
  };

  takeProduct = Product => {
    const { form } = this.state;

    if ((Product || {}).last_movement === 0) {
      Globals.showWarning(
        `¡El producto ${Product.name} no posee stock en almacén!`
      );
      return;
    }

    const exists = this.state.form.products.find(
      Item => Item.id === Product.id
    );

    if (Product.serialization == Constants.SERIALIZATION.ACTIVE) {
      Product.quantity = 0;
      Product.subtotal = 0;
      Product.total = 0;
      Product.vat = 0;
    }

    let rawProducts;

    if (!exists) {
      const rawProduct = {
        ...Product,
        discount_percentage: 0,
        amount_discount: 0
      };

      rawProducts = [...this.state.form.products, rawProduct];
    } else {
      rawProducts = form.products;

      if (Product.serialization != Constants.SERIALIZATION.ACTIVE) {
        const arrayKey = this.state.form.products.findIndex(
          Item => Item.id === Product.id
        );
        // let rate = rateValue(rawProducts[arrayKey]);
        // let newValue = rawProducts[arrayKey].quantity + 1;
        // let quantityValue = newValue * rate;

        // rawProducts[arrayKey] = {
        //   ...rawProducts[arrayKey],
        //   quantity: newValue,
        //   subtotal: parseFloat(quantityValue),
        //   vat: FloatNum(rawProducts[arrayKey].exempt ? 0 : quantityValue * IVA),
        //   total: FloatNum(
        //     rawProducts[arrayKey].exempt
        //       ? quantityValue
        //       : quantityValue * IVA + quantityValue
        //   )
        // };

        // NOTA: Se cambio la función por errores en el calculo del total

        this.handleNum(
          {
            target: {
              value: rawProducts[arrayKey].quantity + 1,
              name: "quantity"
            }
          },
          arrayKey
        );

        this.setState(state => ({
          search: "",
          suggestions: [],
          itemSelected: !this.state.itemSelected
            ? Product.id
            : this.state.itemSelected
        }));

        return;
      }
    }

    this.setState(state => ({
      search: "",
      form: {
        ...state.form,
        products: rawProducts,
        subtotal: FloatNum(ReduceMap.reduceMap(rawProducts, "subtotal")),
        taxable: FloatNum(ReduceMap.taxableMap(rawProducts)),
        exempt: FloatNum(ReduceMap.exemptMap(rawProducts)),
        vat: FloatNum(ReduceMap.reduceMap(rawProducts, "vat")),
        total: FloatNum(ReduceMap.reduceMap(rawProducts, "total"))
      },
      suggestions: [],
      itemSelected: !this.state.itemSelected
        ? Product.id
        : this.state.itemSelected
    }));

    this.saveSale();
  };

  // saveSale = () => {
  //   this.setState({
  //     search: ""
  //   }, () => {
  //     setTimeout(() => {
  //       this.setState({
  //         search: ""
  //       })
  //     }, 5000)
  //   });
  //   this.props.dispatch({
  //     type: 'SET_SALE',
  //     payload: {
  //       ...this.state.form
  //     }
  //   });
  // }

  handleNumPayment = (values, key) => {
    const { floatValue } = values;
    const { form } = this.state;

    const paymentsMap = [...form.type_payments];

    paymentsMap[key] = {
      ...paymentsMap[key],
      amount: floatValue
    };

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: paymentsMap
      }
    }));
  };

  handleReferenceNumber = (value, key) => {
    const { form } = this.state;

    const paymentsMap = [...form.type_payments];

    paymentsMap[key] = {
      ...paymentsMap[key],
      reference_number: value
    };

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: paymentsMap
      }
    }));
  }

  /**
   * Establece en el estado el valor para realizar los calculos.
   * @param {object} => emitter recibe el valor establecido en el Input
   * @param {int} => key recibe el valor de la posición en el array.
   */

  handleNum = (emitter, key) => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    const { user } = this.props;
    const discountRate = Globals.getRate(user);

    if (name === "discount_percentage" && !!discountRate) {
      if (parseInt(value) > discountRate.percentage) {
        Globals.showWarning(
          `¡El porcentaje de descuento no puede exceder el ${discountRate.percentage}%!`
        );
        return;
      }
    }

    /**
     * Definición de variables.
     * @param {object} => newValue establece el valor emitido en el Input
     * @param {array} => rawProducts establece una copia en memoria del array.
     * @param {float} => amount almacena algún calculo dentro de los casos.
     */
    let newValue = value ? parseInt(value) : value;
    let rawProducts = [...form.products];
    let amount = 0;
    let subtotalAmount = 0;
    let discount_percentage = 0;
    let discount = 0;
    let rate;
    let quantityValue;

    discount_percentage =
      rawProducts[key].discount_percentage > 0
        ? rawProducts[key].discount_percentage / 100
        : 0;

    switch (name) {
      case "quantity":
        rate = rateValue(rawProducts[key]);
        quantityValue = newValue > 0 && newValue !== "" ? newValue * rate : 0;
        // if (
        //   rawProducts[key].stock_min >=
        //   rawProducts[key].last_movement - newValue
        // ) {
        //   Globals.showWarning(
        //     `¡El producto ${rawProducts[key].name} estara por debajo del stock mínimo!`
        //   );
        // }
        // Calculo de descuento (Precio * Descuento)
        discount =
          rawProducts[key].discount_percentage > 0
            ? quantityValue * discount_percentage
            : 0;

        rawProducts[key] = {
          ...rawProducts[key],
          quantity: newValue,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue - discount
              : quantityValue * IVA + quantityValue - discount
          ),
          stock: rawProducts[key].last_movement - newValue
        };
        break;
      case "rate":
        // Precio establecido en la Tasa de Cobro
        rate =
          parseInt(value) === 1
            ? rawProducts[key].earning
            : parseInt(value) === 2
              ? rawProducts[key].earning_max
              : rawProducts[key].earning_offer;
        // Cantidad x Precio establecido
        quantityValue = rawProducts[key].quantity * rate;
        // Calculo de descuento (Precio * Descuento)
        discount =
          rawProducts[key].discount_percentage > 0
            ? quantityValue * discount_percentage
            : 0;

        rawProducts[key] = {
          ...rawProducts[key],
          [name]: value,
          price: rate,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue - discount
              : quantityValue * IVA + quantityValue - discount
          )
        };
        break;
      case "discount_percentage":
        // Precio establecido en la Tasa de Cobro
        rate =
          parseInt(rawProducts[key].rate) === 1
            ? rawProducts[key].earning
            : parseInt(rawProducts[key].rate) === 2
              ? rawProducts[key].earning_max
              : rawProducts[key].earning_offer;
        // Porcentaje en Decimales (40 / 100) = 0,4
        quantityValue = newValue / 100;
        // Cantidad x Precio establecido (5 * 5,00) = 25
        amount = rawProducts[key].quantity * rate;
        // Monto del Descuento (0,4 * 25) = 10
        discount = quantityValue * amount;
        // 100 - 100 * 0,5 (Calculo para el total)
        subtotalAmount =
          rawProducts[key].subtotal > 0
            ? amount - amount * quantityValue
            : rawProducts[key].subtotal;

        rawProducts[key] = {
          ...rawProducts[key],
          discount_percentage: newValue,
          vat: FloatNum(rawProducts[key].exempt ? 0 : subtotalAmount * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? subtotalAmount
              : subtotalAmount * IVA + subtotalAmount
          )
        };
        break;
    }

    discount = this.calculateDiscount(rawProducts);

    this.setState(state => ({
      form: {
        ...state.form,
        products: rawProducts,
        subtotal: FloatNum(ReduceMap.reduceMap(rawProducts, "subtotal")),
        discount: FloatNum(discount),
        taxable: FloatNum(ReduceMap.taxableMap(rawProducts)),
        exempt: FloatNum(ReduceMap.exemptMap(rawProducts)),
        vat: FloatNum(ReduceMap.reduceMap(rawProducts, "vat")),
        total: FloatNum(ReduceMap.reduceMap(rawProducts, "total"))
      }
    }));
  };

  calculateDiscount = rawProducts => {
    // Calculo de descuento global
    let discount = 0;
    let discount_percentage = 0;
    rawProducts.forEach(item => {
      discount_percentage =
      item.discount_percentage > 0 ? item.discount_percentage / 100 : 0;
      discount +=
        item.discount_percentage > 0
        ? item.price * item.quantity * discount_percentage
        : 0;
    });
    return discount;
  };

  handleChange = (emitter, string) => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    switch (name) {
      case "cash":
      case "credit":
      case "debit":
      case "transfer":
      case "financed":
        const method = form.type_payments.find(Item => Item.id === value);
        const processed = parseInt(value) === Constants.SALE_PAYMENT_TYPES.CREDIT ?
          Constants.SALE_PAYMENT_PROCESSED_LIST.PROCESSED : Constants.SALE_PAYMENT_PROCESSED_LIST.NOT_PROCESSED

        let parseMap;
        if (!!method) {
          parseMap = form.type_payments.filter(Item => Item.id !== value);
        } else {
          parseMap = [
            ...form.type_payments,
            { id: value, label: Payments[value].type, amount: "", reference_number: "", processed: processed }
          ];
        }

        this.setState(state => ({
          form: {
            ...state.form,
            type_payments: parseMap
          }
        }));

        return;
      case "searchClient":
        this.setState({ [name]: value }, () => {
          this.searchCustomers(this.state);
        });
        return;
      case "search_client":
        this.setState(state => ({
          form: {
            ...state.form,
            client_id: ""
          },
          client_selected: "",
        }));
        this.setState({ [name]: value }, () => {
          const filter = this.filterClients(this.state)
          console.log(filter)
          if (filter === true || filter === undefined){
            this.AutoCompleteClients(this.state);
          }
          
          
        });
        break;
      case "search":
        this.setState({ [name]: value }, () => {
          this.AutoComplete(this.state);
        });
        return;
      default:
        this.setState({
          form: {
            ...this.state.form,
            [name]: value
          }
        });
        return;
    }
  };

  ClearClient = () => {
    const { form } = this.state;
    this.setState(state => ({
      form: {
        ...state.form,
        client_id: ""
      },
      client_selected: "",
      search_client: "",
      filteredClients: false,
      suggestions_client: [],
    }));
  }

  filterClients = ({ search_client }) => {
    const { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    if (!search_client) {
      this.setState({ suggestions_client: [] });
      return false;
    }

    let stringCount = search_client.replace(/\s/g, "");
    let count = stringCount.length;

    if(count < 4){
      this.setState({ filteredClients: false });
      return false
    }

    if(count>=4 && this.state.filteredClients === false){
      
      Request.getCustomersByEnterpriseSearch({ Id: enterpriseId, search: search_client })
        .then(response => {
          this.setState({ customers: response, filteredClients: true });
          this.setState({ suggestions_client: response });
          return true
        })
        .catch(() => Globals.showError());
    }

    if(count>=4 && this.state.filteredClients === true){
      return true
    }

  }

  AutoCompleteClients = ({ search_client }) => {
    const { customers } = this.state;

    let isnum = /^[0-9]+$/;

    if (!search_client) {
      this.setState({ suggestions_client: [] });
      return;
    }

    if (!search_client.match(isnum)) { //Si la busqueda es por nombre
      let searchLowerCase = search_client.toLowerCase();

      const filterCustomers = customers.filter(Item => {
        //console.log(Item)
        let nameLowerCase = Item.person ? Item.person.first_name.toLowerCase() : Item.label.toLowerCase();
        return (
          nameLowerCase.indexOf(searchLowerCase) !== -1 //&&
          // form.client_id.every(query => {
          //   return parseInt(query.id) !== parseInt(Item.id);
          // })
        );
      });
      this.setState({ suggestions_client: filterCustomers });
    }
    else if (search_client.match(isnum)) { //Si la busqueda es por cedula o rif
      let searchLowerCase = search_client;

      const filterCustomers = customers.filter(Item => {
        if(Item.person){
          if (Item.person.identity_document != null) { //Si es por cedula
            let identityLowerCase = Item.person.identity_document;
            return (
              identityLowerCase.indexOf(searchLowerCase) !== -1
            );
          }
          else if (Item.person.fiscal_identification != null 
              && Item.person.identity_document != null) 
          { //Si es por rif
            let fiscalLowerCase = Item.person.identity_document;
            return (
              fiscalLowerCase.indexOf(searchLowerCase) !== -1
            );
          }
        }
        else if(Item.dni){
          let identityLowerCase = Item.dni;
          return (
            identityLowerCase.indexOf(searchLowerCase) !== -1
          );
        }
        

      });

      this.setState({ suggestions_client: filterCustomers });


      //this.setState({ suggestions_client: filterCustomers });
    }

  };

  takeClient = Client => {
    console.log('client: ', Client);

    let client_selected = '';
    let client_id = '';

    if (Client.person) {
      client_id = Client.id;
      if (Client.person.identity_document) {
        client_selected = Client.person.first_name + ' ' + Client.person.identity_document;
      }
      else if (Client.person.fiscal_identification !== null) {
        client_selected = Client.person.first_name + ' ' + Client.person.fiscal_identification;
      }
    }
    else {
      client_id = Client.value;
      client_selected = Client.label + ' ' + Client.dni;
    }


    this.setState(state => ({
      search_client: "",
      form: {
        ...state.form,
        client_id: client_id
      },
      suggestions_client: [],
      client_selected: client_selected,
    }));
    this.state.form.client_id = client_id;

    //this.saveDelivery();
  };

  handleDiscount = (value, name) => {
    const { form } = this.state
    const { user } = this.props;
    const rawProducts = [...form.products];
    const discountRate = Globals.getRate(user);

    if (name === "discount_percentage" && !!discountRate) {
      if (parseInt(value) > discountRate.percentage) {
        Globals.showWarning(
          `¡El porcentaje de descuento no puede exceder el ${discountRate.percentage}%!`
        );
        return;
      }
    }

    const parseMap = form.type_payments.map((item) => {
      return {
        ...item,
        amount: "",
        reference_number: "",
      }
    })

    const mapProducts = rawProducts.map((product, key) => {
      return {
        ...product,
        discount_percentage: value,
        vat: FloatNum(rawProducts[key].exempt ? 0 : rawProducts[key].subtotal * IVA),
        total: FloatNum(
          rawProducts[key].exempt
            ? rawProducts[key].subtotal
            : rawProducts[key].subtotal * IVA + rawProducts[key].subtotal
        )
      }
    })

    const discount = this.calculateDiscount(mapProducts);

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: parseMap,
        discount_percentage: value,
        subtotal: FloatNum(ReduceMap.reduceMap(mapProducts, "subtotal")),
        discount: FloatNum(discount),
        taxable: FloatNum(ReduceMap.taxableMap(mapProducts)),
        exempt: FloatNum(ReduceMap.exemptMap(mapProducts)),
        vat: FloatNum(ReduceMap.reduceMap(mapProducts, "vat")),
        total: FloatNum(ReduceMap.reduceMap(mapProducts, "total") - FloatNum(discount))
      }
    }));
  }

  handleClick = Product => {
    const { form } = this.state;

    Globals.confirm(
      `¿Desea remover de la venta el producto: ${Product.name}?`,
      () => {
        const filterMap = form.products.filter(Item => Item.id !== Product.id);

        const discount = this.calculateDiscount(filterMap);

        this.setState(state => ({
          form: {
            ...state.form,
            products: filterMap,
            subtotal: FloatNum(ReduceMap.reduceMap(filterMap, "subtotal")),
            taxable: FloatNum(ReduceMap.taxableMap(filterMap)),
            exempt: FloatNum(ReduceMap.exemptMap(filterMap)),
            vat: FloatNum(ReduceMap.reduceMap(filterMap, "vat")),
            total: FloatNum(ReduceMap.reduceMap(filterMap, "total")),
            discount: FloatNum(discount)
          }
        }));
      }
    );
  };

  isCash = () => {
    return (
      this.state.form.type_payments.map(i => parseInt(i.id)).indexOf(1) != -1
    );
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { form, submitted } = this.state;

    if (submitted) {
      return;
    }

    if (this.isCash()) {
      const amountNoCash = FloatNum(
        ReduceMap.reduceMap(
          form.type_payments.filter(i => parseInt(i.id) != 1),
          "amount"
        )
      );

      if (amountNoCash > parseFloat(form.total)) {
        Globals.showError(
          "¡El monto a pagar sin efectivo es mayor al total de la venta!"
        );
        return;
      } else {
        form.type_payments[
          form.type_payments.findIndex(i => parseInt(i.id) == 1)
        ].amount = parseFloat(FloatNum(parseFloat(form.total) - amountNoCash));
      }
    }

    // Almacenando el valor de por pago de la venta
    const amountMap = FloatNum(
      ReduceMap.reduceMap(form.type_payments, "amount")
    );
    // Filtrando productos para valores nulos
    const findValuesNull = form.products.filter(
      Item => Item.quantity <= 0 || Item.quantity === ""
    );
    // Filtrando productos con precios en 0
    const findNotValues = form.products.filter(Item => Item.total <= 0);

    if (form.products.length === 0) {
      Globals.showError("¡El pedido debe tener productos agregados!");
      return;
    } else if (findValuesNull.length > 0) {
      Globals.showError("¡Los productos deben tener un valor mayor a 0!");
      return;
    } else if (findNotValues.length > 0) {
      Globals.showError("¡Los productos deben tener un precio mayor a 0!");
      return;
    } else if (parseFloat(amountMap) > parseFloat(form.total)) {
      Globals.showError("¡El monto a pagar es mayor al total de la venta!");
      return;
    } else if (parseFloat(amountMap) < parseFloat(form.total)) {
      Globals.showError("¡El monto a pagar es menor al total de la venta!");
      return;
    } else if (isNaN(parseFloat(amountMap))) {
      Globals.showError("¡Debe ingresar el monto en los métodos de pago!");
      return;
    }

    // Validando si hay productos por serializar
    const cant = form.products.filter(
      i =>
        i.serialization == Constants.SERIALIZATION.ACTIVE && i.serials == null
    ).length;
    if (cant > 0) {
      Globals.showError("¡Hay productos que necesitan serializarse!");
      return;
    }

    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });

    const { user } = this.props;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    form.userId = userId;

    form.creator_id = user.id;

    Sale.createSale(form)
      .then(response => {
        this.setState(state => ({
          submitted: false,
          visiblePayment: false,
          uglyModal: true,
          Item: response,
          form: {
            ...state.form,
            code: "",
            client_id: "",
            type_payments: [],
            discount_percentage: 0,
            discount: 0,
            subtotal: 0,
            discount: 0,
            taxable: 0,
            exempt: 0,
            vat: 0,
            total: 0,
            products: []
          }
        }), () => {
          this.props.dispatch({
            type: 'SET_SALES',
            payload: null
          });
        });
      })
      .catch(error => {
        if ((error || {}).message) {
          Globals.showError(error.message);
        }

        if (error.hasOwnProperty("msg")) {
          Globals.showError(error.msg);
        }

        this.setState({
          submitted: false,
          visiblePayment: false,
          errors: error
        });
      });
  };

  rawCustomer = Customer => {
    console.log(Customer)
    this.setState(state => ({
      form: {
        ...state.form,
        client_id: Customer.client_id
      },
      client_selected: (Customer.client.person || {}).first_name + ' ' + (Customer.client.person || {}).identity_document || (Customer.client.person || {}).fiscal_identification,
      customers: [
        ...state.customers,
        {
          value: Customer.client_id,
          label:
            (Customer.client.person || {}).first_name ||
            (Customer.client.person || {}).name,
          dni: (Customer.client.person || {}).identity_document || (Customer.client.person || {}).fiscal_identification
        }
      ]
    }));
  };

  close = () => {
    this.setState({
      visible: false,
      uglyModal: false,
      visiblePayment: false,
      showCustomer: false
    });
  };

  confirmPrint = () => {
    const { user } = this.props;
    const enterprise_id = Globals.getEnterpriseId(user);
    const { Item } = this.state;
    const process_id = Item.id;
    const url = `${ENV.BasePublic}sales/print-sale/${enterprise_id}/${process_id}`;
    const win = window.open(url, "_blank");
    win.focus();
    this.close();
  };

  clean = () => {
    this.setState(state => ({
      errors: [],
      search: "",
      form: {
        ...state.form,
        products: [],
        discount_percentage: 0,
        discount: 0,
        subtotal: 0,
        taxable: 0,
        exempt: 0,
        vat: 0,
        total: 0
      },
      suggestions: []
    }));
  };

  setStep = Number => {
    this.setState({ step: Number });
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };

  onSuccess = async item => {
    let products = [...this.state.form.products];
    const index = products.findIndex(i => i.id == this.state.itemSelected);
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      showModalScan: false,
      form: {
        ...this.state.form,
        products: products
      }
    });
    this.handleNum(
      {
        target: {
          value: item.length,
          name: "quantity"
        }
      },
      index
    );
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  saveSale = () => {
    this.setState({
      showSavedText: true
    }, () => {
      setTimeout(() => {
        this.setState({
          showSavedText: false
        })
      }, 5000)
    });
    this.props.dispatch({
      type: 'SET_SALES',
      payload: { ...this.state.form }
    });
  }

  restoreState = () => {
    this.props.dispatch({
      type: 'SET_SALES',
      payload: {
        code: "",
        client_id: "",
        branch_id: "",
        warehouse_id: "",
        type_payments: [],
        discount_percentage: 0,
        subtotal: 0,
        discount: 0,
        taxable: 0,
        exempt: 0,
        vat: 0,
        total: 0,
        products: [],
      }
    });
  }

  showSerials = item => {
    const { form } = this.state;
    let creator_id = this.props.user.id;
    let product_id = item.id;
    let request = {
      creator_id: creator_id,
      warehouse_id: form.warehouse_id,
      product_id: product_id,
    };

    //request.push({ creator_id: creator_id }, { warehouse_id: form.warehouse_id }, { product_id: product_id });

    axios
      .post("admin/deliver-notes/getserialsavailable", request)
      .then(res => {
        if (res.data.serialsAvailable.length > 0) {
          if (res.data.serialsCompromised > 0) {
            if (res.data.serialsCompromised == 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidad comprometida en un PEDIDO.'
              });
            }
            else if (res.data.serialsCompromised > 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidades comprometidas en un PEDIDO.'
              });
            }
          }
          this.setState({
            show_serials: true,
            item_serial: item,
            serials_available: res.data.serialsAvailable,
          });
        }
        else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      });


  }

  onCloseShowSerials = () => {
    this.setState({
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    });
  }

  getItemsTotal() {
    let total = 0;
    this.state.form.products.forEach(product => {
      total = total + product.quantity;
    });
    return total;
  }

  render() {
    const {
      step,
      submitted,
      Item,
      form,
      visible,
      uglyModal,
      visiblePayment,
      showCustomer,
      customers,
      branches,
      warehouses,
      edit_serial,
      item_serial,
      showModalScan,
      itemSelected,
      showSavedText,
      show_serials,
      serials_available,
      serialsCompromised,
      client_selected,
      suggestions_client,
    } = this.state;

    const { user } = this.props;
    const Id = (user.enterprise_users || {}).enterprise_id || user.id;
    const __index = form.products.findIndex(i => i.id == itemSelected);

    const showDiscountProduct = user.role === Constants.ROLE_SELLER &&
      user.configuration_seller.type_percentage === Constants.DISCOUNT_TYPES.bySale ? false : true;

    const showDiscountSale = user.role === Constants.ROLE_SELLER &&
      user.configuration_seller.type_percentage === Constants.DISCOUNT_TYPES.bySale ? true : false;

    const havePermission = this.props.sidebar.find(
      Item => Item.name === "Clientes"
    )
      ? true
      : false;

    const discountRate = (user || {}).configuration_seller
      ? !!user.configuration_seller.percentage
      : true;

    return (
      <Menu history={this.props.history}>
        {showCustomer && (
          <Modal title="Crear cliente" onClose={this.close} visible>
            <FormClient
              Id={Id}
              back={false}
              customer={Customer =>
                this.setState({ showCustomer: false }, () => {
                  this.rawCustomer(Customer);
                })
              }
            />
          </Modal>
        )}

        {showModalScan && (
          <Modal
            className="modal-product"
            title="Ingresar seriales"
            onClose={() => {
              this.setState({
                showModalScan: false
              });
            }}
            visible
          >
            <ModalScan
              serials={form.products[__index].serials}
              onSuccess={this.onSuccess}
              onCancel={() => {
                this.setState({
                  showModalScan: false
                });
              }}
            />
          </Modal>
        )}

        <div id="home">
          <div className="container-fluid">
            {visible && (
              <Modal title="Busqueda avanzada" onClose={this.close} visible>
                <Filter
                  onClose={this.close}
                  onClick={Product => {
                    this.takeProduct(Product);
                  }}
                  user={user}
                  Id={Id}
                  form={form}
                />
              </Modal>
            )}

            {visiblePayment && (
              <Modal title="Método de pago" onClose={this.close} visible>
                <Payment
                  isCash={this.isCash}
                  onClose={this.close}
                  submitted={submitted}
                  form={form}
                  user={user}
                  showDiscountSale={showDiscountSale}
                  handlePayment={(emitter, key) => {
                    this.handleNumPayment(emitter, key);
                  }}
                  handleChange={emitter => {
                    this.handleChange(emitter);
                  }}
                  handleDiscount={(value, name) => {
                    this.handleDiscount(value, name)
                  }}
                  handleReferenceNumber={(value, key) => {
                    this.handleReferenceNumber(value, key)
                  }}
                  onClick={emitter => {
                    this.handleSubmit(emitter);
                  }}
                />
              </Modal>
            )}

            {uglyModal && (
              <Modal className="modal-green" onClose={this.close} visible>
                <div className="row">
                  <div className="col col-md text-center minh">
                    <strong className="checkmark">
                      <span role="img" aria-label="sheep">
                        ✔️
                      </span>
                    </strong>
                    <h1 className="font-weight-bold text-white ">
                      VENTA N° {Item.code} PROCESADA EXITOSAMENTE
                    </h1>
                    <Button
                      type="button"
                      onClick={() => this.confirmPrint()}
                      className="mt-6 f-big"
                    >
                      IMPRIMIR
                    </Button>
                    <Button
                      type="button"
                      onClick={() => this.close()}
                      className="mt-6 f-big"
                    >
                      CERRAR
                    </Button>
                  </div>
                </div>
              </Modal>
            )}

            {step === Constants.STEP_SELECTION && (
              <div className="row justify-content-center align-items-center minh">
                <div className="col col-md-8">
                  <div className="card">
                    <div className="card-header font-weight-bold bg-blue">
                      PUNTO DE VENTA
                    </div>
                    <div className="card-body text-center">
                      <div className="row">
                        {branches.length > 0 ? (
                          <React.Fragment>
                            <div className="col col-md">
                              <Select
                                color="gray"
                                name="branch_id"
                                defaultname="Seleccione"
                                label="Sucursal"
                                value={this.state.form.branch_id}
                                error={`${this.hasErrorFor("branch_id")
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                invalidfeedback={this.renderErrorFor(
                                  "branch_id"
                                )}
                                options={branches}
                                onChange={e => {
                                  this.restoreState();
                                  this.setState({
                                    form: {
                                      ...this.state.form,
                                      client_id: "",
                                      type_payments: [],
                                      discount_percentage: 0,
                                      subtotal: 0,
                                      discount: 0,
                                      taxable: 0,
                                      exempt: 0,
                                      vat: 0,
                                      total: 0,
                                      products: [],
                                      [e.target.name]: e.target.value
                                    }
                                  });
                                }}
                              />
                            </div>
                            <div className="col-md">
                              <Select
                                color="gray"
                                label="Almacen"
                                name="warehouse_id"
                                defaultname="Seleccione"
                                value={this.state.form.warehouse_id}
                                error={`${this.hasErrorFor("warehouse_id")
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                invalidfeedback={this.renderErrorFor(
                                  "warehouse_id"
                                )}
                                options={warehouses}
                                onChange={e => {
                                  this.restoreState();
                                  this.setState({
                                    form: {
                                      ...this.state.form,
                                      client_id: "",
                                      type_payments: [],
                                      discount_percentage: 0,
                                      subtotal: 0,
                                      discount: 0,
                                      taxable: 0,
                                      exempt: 0,
                                      vat: 0,
                                      total: 0,
                                      products: [],
                                      [e.target.name]: e.target.value
                                    }
                                  });
                                }}
                              />
                            </div>
                          </React.Fragment>
                        ) : (
                          <div className="col col-md">
                            <div
                              className="spinner-border text-primary"
                              role="status"
                            >
                              <span className="sr-only">Loading...</span>
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row mt-3">
                        <div className="col col-md">
                          <Button
                            type="button"
                            disabled={!form.branch_id || !form.warehouse_id}
                            onClick={() => {
                              this.setStep(2);
                            }}
                          >
                            Entrar
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === Constants.STEP_FORM && (
              <React.Fragment>
                {edit_serial ? (
                  <EditSerial
                    onSuccess={this.onSuccess}
                    onCancel={this.onCancel}
                    product={this.state.item_serial}
                    inputColor="white"
                    className="white"
                  />
                ) : (
                  <React.Fragment>
                    <div className="row">
                      <div className="col-md-2 my-1">
                        <Button
                          type="button"
                          onClick={() => {
                            this.setState(
                              state => ({
                                step: 1,
                                form: {
                                  ...state.form,
                                  client_id: "",
                                  branch_id: "",
                                  warehouse_id: "",
                                  type_payments: []
                                }
                              }),
                              () => {
                                this.clean();
                              }
                            );
                          }}
                        >
                          Cerrar
                        </Button>
                      </div>
                      <div className="col-md-7">
                        <table className="table table-responsive">
                          <thead>
                            <tr>
                              <td>
                                <small>
                                  <strong>Sucursal:</strong>
                                </small>
                              </td>
                              <td>
                                <small>{loadBranch(branches, form)}</small>
                              </td>
                              <td>
                                <small>
                                  <strong>Almacen:</strong>
                                </small>
                              </td>
                              <td>
                                <small>{loadWarehouse(warehouses, form)}</small>
                              </td>
                              <td>
                                <small>
                                  <strong>
                                    {form.sale_type === 1
                                      ? "Nota de Entrega:"
                                      : "Factura:"}
                                  </strong>
                                </small>
                              </td>
                              <td>
                                <small>{form.code}</small>
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                    {showSavedText && (
                      <div className="row mt-3">
                        <label>
                          Se han almacenado en caché los datos de la venta
                        </label>
                      </div>
                    )}
                    <div className="row mt-3">
                      <div className="col-md">
                        <div className="card">
                          <div className="card-body p-2">
                            <div className="row">
                              <div className="col col-md-6">
                                {/* <form onSubmit={this.firstElementClient}>
                                    <Input
                                      color="gray"
                                      name="searchClient"
                                      label="Ingrese el nombre, cedula o rif del cliente"
                                      value={this.state.searchClient}
                                      onChange={this.handleChange}
                                    />
                                    <div id="suggestion">
                                      {this.stateSuggestions.length > 0
                                        ? this.stateSuggestions.map((Item, key) => {
                                          return (
                                            <div
                                              key={key}
                                              onClick={() => this.setClientSuggestions(Item)}
                                            >
                                              {(Item || {}).first_name} {(Item || {}).last_name}
                                              - {(Item || {}).identity_document || (Item || {}).fiscal_identification}
                                            </div>
                                          );
                                        })
                                        : null}
                                    </div>
                                  </form> */}
                                <Input
                                  color="gray"
                                  id="search_client"
                                  autoComplete="off"
                                  disabled={client_selected !== ''}
                                  name="search_client"
                                  placeholder="Buscar por nombre, cédula o rif"
                                  label="Buscar cliente"
                                  value={
                                    client_selected !== '' && this.state.search_client === ''
                                      ?
                                      client_selected
                                      :
                                      this.state.form.client_id !== '' && client_selected !== ''
                                      ? 
                                      client_selected
                                      :
                                      this.state.form.client_id !== ''
                                      ?
                                      this.state.form.client_id
                                      : 
                                      this.state.search_client
                                  }
                                  onChange={this.handleChange}
                                  error={`${this.hasErrorFor("client_id") ? "is-invalid" : ""
                                    }`}
                                  invalidfeedback={this.renderErrorFor("client_id")}
                                  icon={
                                    havePermission ? (
                                      <Button
                                        color="blue"
                                        type="button"
                                        title="Agregar otro"
                                        small="true"

                                        onClick={() => {
                                          let showCustomer = !this.state
                                            .showCustomer;
                                          this.setState({
                                            showCustomer: showCustomer
                                          });
                                        }}
                                      >
                                        <Icon
                                          name={
                                            this.state.showCustomer
                                              ? "minus"
                                              : "plus"
                                          }
                                        />
                                      </Button>
                                    ) : null
                                  }
                                />

                                <div id="suggestion">
                                  {suggestions_client.length > 0
                                    ? suggestions_client.map((Item, key) => {
                                      return (
                                        <div
                                          key={key}
                                          onClick={() => {
                                            this.takeClient(Item);
                                          }}
                                        >
                                          {Item.person ?
                                            Item.person.identity_document
                                              ?
                                              Item.person.first_name + ' ' + Item.person.identity_document
                                              :
                                              Item.person.first_name + ' ' + Item.person.fiscal_identification
                                            :
                                            Item.label + ' ' + Item.dni
                                          }
                                        </div>
                                      );
                                    })
                                    : null}
                                </div>
                              </div>
                              <div className="col col-md-6">
                                <Button
                                  className="clearClient"
                                  color="red"
                                  type="button"
                                  title="Limpiar cliente seleccionado"
                                  small="true"
                                  onClick={() => {
                                    this.ClearClient();
                                  }}
                                >X
                                </Button>
                              </div>
                            </div>
                            {/* <div className="col- col-md-6">
                              <Select
                                color="gray"
                                label="Cliente"
                                name="client_id"
                                defaultname="Seleccione"
                                value={this.state.form.client_id}
                                error={`${this.hasErrorFor("client_id")
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                invalidfeedback={this.renderErrorFor(
                                  "client_id"
                                )}
                                options={customers}
                                onChange={this.handleChange}
                                icon={
                                  havePermission ? (
                                    <Button
                                      color="blue"
                                      type="button"
                                      title="Agregar otro"
                                      small="true"
                                      onClick={() => {
                                        let showCustomer = !this.state
                                          .showCustomer;
                                        this.setState({
                                          showCustomer: showCustomer
                                        });
                                      }}
                                    >
                                      <Icon
                                        name={
                                          this.state.showCustomer
                                            ? "minus"
                                            : "plus"
                                        }
                                      />
                                    </Button>
                                  ) : null
                                }
                              />
                              <div></div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col col-md-10">
                        <form onSubmit={this.firstElement}>
                          <Input
                            color="white"
                            name="search"
                            placeholder="Ingrese el código del producto"
                            value={this.state.search}
                            onChange={this.handleChange}
                          />
                          <div id="suggestion">
                            {this.state.suggestions.length > 0
                              ? this.state.suggestions.map((Item, key) => {
                                return (
                                  <div
                                    key={key}
                                    onClick={() => {
                                      this.takeProduct(Item);
                                    }}
                                  >
                                    {Item.code + " - " + Item.name}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </form>
                      </div>
                      <div className="col col-md-2">
                        <Button
                          type="button"
                          disabled={!form.branch_id || !form.warehouse_id}
                          onClick={() => {
                            this.setState({
                              visible: true,
                              suggestions: [],
                              search: ""
                            });
                          }}
                        >
                          Buscar
                        </Button>
                      </div>
                    </div>
                    {form.products.length > 0 && (
                      <p className="note-serialize">
                        Presione F8 para escanear seriales | Use las flechas del
                        teclado para moverse entre productos
                      </p>
                    )}
                    <div
                      className="row mb-3"
                      style={{ maxHeight: "350px", overflow: "auto" }}
                    >
                      <div className="col-md">
                        <div className="card">
                          <div className="card-body">
                            <div className="table-responsive table-sales">
                              <table className="table table-bordered table-products table-sm">
                                <thead>
                                  <tr style={{ whiteSpace: "nowrap" }}>
                                    <th>Código</th>
                                    <th>Descripción</th>
                                    <th>Cantidad</th>
                                    <th>Tasa de cobro</th>
                                    <th>Precio</th>
                                    {showDiscountProduct && discountRate && <th>% Descuento</th>}
                                    <th>Total</th>
                                    <th>Acción</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {form.products.length > 0 ? (
                                    form.products.map((Item, key) => {
                                      const isSelected =
                                        itemSelected && itemSelected == Item.id;

                                      return (
                                        <tr
                                          key={key}
                                          className={
                                            isSelected ? "item-selected" : ""
                                          }
                                        >
                                          <td>{Item.code}</td>
                                          <td>{Item.name}</td>
                                          <td>
                                            <input
                                              type="text"
                                              name="quantity"
                                              className={`form-control-sm ${isSelected ? "input-white" : ""
                                                }`}
                                              value={Item.quantity}
                                              maxLength="10"
                                              disabled={
                                                Item.serialization ==
                                                Constants.SERIALIZATION.ACTIVE
                                              }
                                              onKeyPress={emitter => {
                                                Globals.soloNumeros(emitter);
                                              }}
                                              onChange={async emitter => {
                                                let products = [
                                                  ...this.state.form.products
                                                ];
                                                products[key].serials = null;
                                                this.setState({
                                                  edit_serial: false,
                                                  form: {
                                                    ...this.state.form,
                                                    products: products
                                                  }
                                                });
                                                this.handleNum(emitter, key);
                                              }}
                                            />
                                          </td>
                                          <td>
                                            <Select
                                              size="small"
                                              color={
                                                isSelected ? "white" : "gray"
                                              }
                                              name="rate"
                                              onChange={emitter => {
                                                this.handleNum(emitter, key);
                                              }}
                                              value={Item.rate}
                                              options={Item.prices}
                                            />
                                          </td>
                                          <td>
                                            {Globals.formatMiles(Item.price)}
                                          </td>
                                          {showDiscountProduct && discountRate && (
                                            <td>
                                              <input
                                                type="text"
                                                name="discount_percentage"
                                                className={`form-control-sm ${isSelected
                                                  ? "input-white"
                                                  : ""
                                                  }`}
                                                value={Item.discount_percentage}
                                                maxLength="2"
                                                onKeyPress={emitter => {
                                                  Globals.soloNumeros(emitter);
                                                }}
                                                onChange={emitter => {
                                                  this.handleNum(emitter, key);
                                                }}
                                              />
                                            </td>
                                          )}
                                          <td>
                                            {Globals.formatMiles(Item.subtotal)}
                                          </td>
                                          <td className="text-center">
                                            <div className="container-btn-actions container-btn-actions-sales">
                                              <Button
                                                color="red"
                                                small="true"
                                                title="Remover"
                                                type="button"
                                                onClick={() => {
                                                  this.handleClick(Item);
                                                }}
                                              >
                                                <Icon name="minus" />
                                              </Button>

                                              {/*
                                                    Boton de serialización, se muestra solo si la cantidad es mayor a 0 y se puede serializar el producto
                                                  */}

                                              {/* Item.quantity > 0 && Item.serialization == Constants.SERIALIZATION.ACTIVE && (
                                                  <Button
                                                    color={ !Item.serials ? 'yellow' : 'green' }
                                                    small="true"
                                                    title={ !Item.serials ? 'Serializar' : "Serializado" }
                                                    type="button"
                                                    className="btn-actions-orders"
                                                    onClick={() => {
                                                      this.handleSerial(Item);
                                                    }}
                                                  >
                                                    <Icon name="list" />
                                                  </Button>
                                                ) */}

                                              {/*
                                                    Boton para el escaneo de seriales
                                                  */}

                                              {Item.serialization ==
                                                Constants.SERIALIZATION
                                                  .ACTIVE && (
                                                  <Button
                                                    color={Item.quantity > 0 ? "green" : "yellow"}
                                                    small="true"
                                                    title={"Escanear"}
                                                    type="button"
                                                    className="btn-actions-orders"
                                                    onClick={() => {
                                                      this.setState({
                                                        showModalScan: true,
                                                        itemSelected: Item.id
                                                      });
                                                    }}
                                                  >
                                                    <Icon name="list" />
                                                  </Button>
                                                )}

                                              {
                                                /*Boton de mostrar seriales disponibles, se muestra solo si se puede serializar el producto*/
                                              }
                                              {Item.serialization ==
                                                Constants.SERIALIZATION.ACTIVE && (
                                                  <Button
                                                    color={"blue"}
                                                    small="true"
                                                    title={"Ver seriales disponibles"}
                                                    type="button"
                                                    className="btn-actions-orders"
                                                    onClick={() => {
                                                      this.showSerials(Item);
                                                    }}
                                                  >
                                                    <Icon name="eye" />
                                                  </Button>
                                                )}

                                            </div>
                                          </td>
                                        </tr>
                                      );
                                    })
                                  ) : (
                                    <tr>
                                      <td colSpan="8">
                                        No hay productos agregados
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {
                      show_serials && serials_available &&
                      <div className="row">
                        <div className="col-md-12">
                          <Modal
                            className={"modal-serials-inputs"}
                            title="Seriales disponibles"
                            //onSuccess={this.props.onSuccess}
                            onClose={this.onCloseShowSerials}
                            visible
                          >
                            {serialsCompromised != '' &&
                              <span className="text-danger">
                                <strong> <small> {serialsCompromised} </small>  </strong>
                              </span>
                            }
                            <div className="modal-serials-inputs">
                              {serials_available.map((item, index) => (
                                <div className="col-md-12" key={index}>
                                  <InputGroup
                                    label="Hola"
                                    color="gray"
                                    type="text"
                                    value={item.serial}
                                    disabled={true}
                                  />
                                </div>
                              ))}
                            </div>

                          </Modal>
                        </div>
                      </div>
                    }

                    <div className="row">
                      <div className="col-md text-center">
                        <div className="d-inline-flex my-2">
                          <div id="button" className="mr-2 d-flex">
                            <Button
                              color="danger"
                              type="button"
                              disabled={submitted}
                              onClick={() => {
                                this.clean();
                              }}
                            >
                              Limpiar
                            </Button>
                          </div>
                          <div id="button" className="ml-2">
                            <Button
                              color="success"
                              type="button"
                              disabled={submitted || form.products.length === 0}
                              onClick={() => {
                                if (!form.client_id) {
                                  Globals.showWarning(
                                    `¡Debe seleccionar el cliente antes de procesar la venta!`
                                  );
                                  return;
                                }

                                this.setState({ visiblePayment: true });
                              }}
                            >
                              Totalizar
                            </Button>
                          </div>
                        </div>
                      </div>
                      <div className="col col-md my-2">
                        <div className="card">
                          <div className="card-body">
                            <p className="text-left m-0 font-weight-bold">
                              Total de piezas
                              <span className="float-right">
                                {this.getItemsTotal()}
                              </span>
                            </p>
                            <p className="text-left m-0">
                              Subtotal
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.subtotal)}
                              </span>
                            </p>
                            <p className="text-left m-0">
                              DESCUENTO
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.discount)}
                              </span>
                            </p>
                            <p className="text-left m-0">
                              EXENTO
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.exempt)}
                              </span>
                            </p>
                            <p className="text-left m-0">
                              BASE IMP.
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.taxable)}
                              </span>
                            </p>
                            <p className="text-left m-0">
                              IVA
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.vat)}
                              </span>
                            </p>
                            <b className="text-left m-0 total d-flex align-items-center justify-content-between">
                              TOTAL
                              <span className="float-right">
                                {Globals.formatMiles(this.state.form.total)}
                              </span>
                            </b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
        </div>
      </Menu>
    );
  }
}

function rateValue(raw) {
  return parseInt(raw.rate) === 1
    ? raw.earning
    : parseInt(raw.rate) === 2
      ? raw.earning_max
      : raw.earning_offer;
}

function loadBranch(branches, { branch_id: Id }) {
  return branches.find(({ value }) => value === parseInt(Id)).label;
}

function loadWarehouse(warehouses, { warehouse_id: Id }) {
  return warehouses.find(({ value }) => value === parseInt(Id)).label;
}

function FloatNum(number) {
  return number.toFixed(2);
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar,
    sales: state.sales
  };
})(Sales);
