const support = (state = 0, action) => {
  switch (action.type) {
    case "SET_SUPPORT":
      return action.payload;
    case "QUIT_SUPPORT":
      return 0;
    default:
      return state;
  }
};

export default support;
