/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

const SelectActions = ({
  label = '',
  placeholder = '',
  options = [],
  actionOptions = [],
  bottomActionOptions = [],
  value,
  isSearchable = false,
  onChange = () => {},
  onInputChange,
}) => {

  const [show, setShow] = useState(false);
  const [selected, setSelected] = useState();
  const [inputValue, setInputValue] = useState();
  const [filteredOptions, setFilteredOptions] = useState(options);

  const selectOption = (item) => {
    setSelected(item);
    setShow(false);
    if (item.value !== selected?.value)
      onChange(item.value);
  }

  const onSearch = (newValue) => {
    setSelected(undefined);
    setInputValue(newValue);

    if (onInputChange) return onInputChange(newValue);
    if (!newValue) return setFilteredOptions(options);

    const filtered = options.filter(i => {
      return Object.entries(i)
        .filter(val => ['string','number'].includes(typeof val[1]))
        .some(val => String(val[1]).toLowerCase().includes(String(newValue).toLowerCase()));
    });
    setFilteredOptions(filtered);
  }

  const updateValue = () => {
    const exists = options.find(x => x.value === value);
    if (exists) setSelected(exists);
    else setSelected(undefined);
  }

  useEffect(() => {
    updateValue();
  }, [value, options]);

  useEffect(() => {
    setFilteredOptions(options);
  }, [options]);

  return (
    <>
      {show && (
        <div className="backdrop" onClick={() => setShow(false)} />
      )}

      <div className="custom-actions-select-wrapper">
        <div className="form-group custom-actions-select" onClick={() => setShow(true)}>
          <label className="material">{ label }</label>
          <div className="input-wrapper">
              {isSearchable ? (
                <input
                  className="form-control material"
                  value={ selected?.label || inputValue || '' }
                  placeholder={placeholder}
                  onChange={e =>  onSearch(e.target.value)}
                />
              ) : (
                <div className="form-control material">
                  <span>{ selected?.label || placeholder || '' }</span>
                </div>
              )}
            <div className="dropdown-tip">
              <i className="fa fa-regular fa-chevron-down"></i>
            </div>
          </div>
        </div>

        {show && (
          <ul className="custom-actions-options">
            {actionOptions.map((option, index) => (
              <li
                key={`${label || placeholder}-action-as-option-${index}`}
                className={`action-as-option green ${ option?.className }`}
                onClick={() => option.onClick()}
              >
                { option.content }
              </li>
            ))}

            {filteredOptions.map((option, index) => (
              <li
                key={`${label || placeholder}-option-${index}`}
                className={option?.disabled ? 'disabled' : ''}
              >
                <span
                  className="label"
                  title={ typeof option.label === 'string' ? option.label : undefined }
                  onClick={() => option?.disabled ? {} : selectOption(option)}
                >
                  { option.label }
                </span>
                <div className="actions">{ option?.actions }</div>
              </li>
            ))}

            {bottomActionOptions.map((option, index) => (
              <li
                key={`${label || placeholder}-action-as-option-${index}`}
                className={`bottom-action-as-option blue ${ option?.className }`}
                onClick={() => option.onClick()}
              >
                { option.content }
              </li>
            ))}
          </ul>
        )}
      </div>
    </>
  )
}

export default SelectActions;