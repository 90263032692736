import React from "react";
import moment from "moment";

import { Textarea, ViewSerial } from "../../components";
import { Globals, Constants } from "../../utils";

class View extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view_serials: false,
      item_serials: null,
      serials: null
    };
  }

  render() {
    const { Item } = this.props;

    return (
      <React.Fragment>

        { this.state.view_serials && (
          <ViewSerial onBack={ () => {
            this.setState({
              item_serials: null,
              view_serials: false,
              serials: null
            });
          } } 
          product={ this.state.item_serials } 
          serials={ this.state.serials } />
        ) }

        { !this.state.view_serials && (
          <React.Fragment>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER ? (
                            <React.Fragment>
                              <th>Nro. de Pedido:</th>
                              <td>{Item.code}</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <th>Nro. de Factura:</th>
                              <td>{Item.bill_number}</td>
                            </React.Fragment>
                          )}
                          {parseInt(Item.type) === Constants.PURCHASE_BILL && (
                            <React.Fragment>
                              <th>Nro. de Control:</th>
                              <td>{Item.control_number}</td>
                            </React.Fragment>
                          )}
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER ? (
                            <React.Fragment>
                              <th>Fecha de Compra:</th>
                              <td>{parseFormat(Item.date_purchase)}</td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <th>Fecha de Recepción:</th>
                              <td>{parseFormat(Item.bill_date_reception)}</td>
                            </React.Fragment>
                          )}
                        </tr>
                        <tr>
                          <th>Proveedor:</th>
                          <td>{Item.provider.name}</td>
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER ? (
                            <React.Fragment>
                              <th>Fecha de Entrega:</th>
                              <td>
                                {parseFormat(
                                  Item.status
                                    ? Item.date_delivery_real
                                    : Item.date_delivery
                                )}
                              </td>
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <th></th>
                              <th></th>
                              <th>Fecha de Emisión:</th>
                              <td>{parseFormat(Item.bill_date_emission)}</td>
                            </React.Fragment>
                          )}
                        </tr>
                        <tr>
                          <th>Tipo:</th>
                          <td>
                            {parseInt(Item.type) === Constants.PURCHASE_ORDER
                              ? "Orden de Compra"
                              : "Factura de compra"}
                          </td>
                          <th>Sucursal</th>
                          <td>{Item.warehouse.branch.name}</td>
                        </tr>
                        <tr>
                          <th>Divisa:</th>
                          <td>{Item.currency.name}</td>
                          <th>Almacén</th>
                          <td>{Item.warehouse.name}</td>
                        </tr>
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row view-order">
              <div className="col-md">
                <div className="form-group">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <td colSpan="6" className="text-center">
                            PRODUCTOS DE LA ORDEN / FACTURA
                          </td>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th>Descripción</th>
                          <th>Precio</th>
                          <th>Cantidad</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Item.details.map((Product, key) => {  
                          const serials = Item.operation.filter(i => i.product_warehouse.product_id == Product.id);

                          return (
                            <tr key={key}>
                              <td>
                                <p>{Product.code}</p>
                                { serials.length > 0 && <p className="view-serials" onClick={ () => {
                                  this.setState({
                                    view_serials: true,
                                    item_serials: Product,
                                    serials: serials
                                  });
                                } }>
                                  Ver Seriales
                                </p> }
                              </td>
                              <td>{Product.name}</td>
                              <td>{Product.description}</td>
                              <td>
                                {Globals.formatMiles(
                                  Item.status
                                    ? Product.pivot.subtotal_real /
                                        Product.pivot.quantity_real
                                    : Product.pivot.subtotal /
                                        Product.pivot.quantity
                                )}
                              </td>
                              <td>
                                {Item.status
                                  ? Product.pivot.quantity_real
                                  : Product.pivot.quantity}
                              </td>
                              <td>
                                {Globals.formatMiles(
                                  Item.status
                                    ? Product.pivot.subtotal_real
                                    : Product.pivot.subtotal
                                )}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="3"></td>
                          <th colSpan="2">Subtotal</th>
                          <td>
                            {Globals.formatMiles(
                              Item.status ? Item.subtotal_real : Item.subtotal
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3"></td>
                          <th colSpan="2">Exento</th>
                          <td>{Globals.formatMiles(Item.exempt)}</td>
                        </tr>
                        <tr>
                          <td colSpan="3"></td>
                          <th colSpan="2">Base Imponible</th>
                          <td>{Globals.formatMiles(Item.taxable)}</td>
                        </tr>
                        <tr>
                          <td colSpan="3"></td>
                          <th colSpan="2">IVA</th>
                          <td>
                            {Globals.formatMiles(
                              Item.status ? Item.vat_real : Item.vat
                            )}
                          </td>
                        </tr>
                        <tr>
                          <td colSpan="3"></td>
                          <th colSpan="2">Total</th>
                          <td>
                            {Globals.formatMiles(
                              Item.status ? Item.total_real : Item.total
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row text-center">
              <div className="col-md">
                <div className="form-group">
                  <Textarea
                    color="gray"
                    value={Item.observations}
                    name="observations"
                    label="OBSERVACIONES"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </React.Fragment> ) }

      </React.Fragment>
    );
  }
}

function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

export default View;
