import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, FacebookIcon, WhatsappIcon } from 'react-share';
import TwitterIcon from '../assets/icons/twitter.png';

const Share = ({ url }) => {
  const title = 'Comparte en redes sociales';

  return (
    <div className="share-buttons">
      <FacebookShareButton url={url} quote={title}>
        <FacebookIcon size={32} round={true} />
      </FacebookShareButton>

      <TwitterShareButton url={url} title={title}>
        <img src={TwitterIcon} alt="X | Twitter" style={{ width: 32, height: 32 }} />
      </TwitterShareButton>

      <WhatsappShareButton url={url} title={title}>
        <WhatsappIcon size={32} round={true} />
      </WhatsappShareButton>
    </div>
  );
};

export default Share;