import React, { createRef } from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
    Table,
    Pagination,
    Button,
    Input,
    Select,
    CheckBox,
    Icon,
    DatePicker,
    Modal,
    SelectActions,
} from "../../components";
import { axios, Constants, Globals, ENV, Colors } from "../../utils";
import { Warehouse, Category, Brand, Payment } from "../../services";
import NumberFormat from "react-number-format";
import PrinterIcon from '../../assets/icons/printer.png';
import CloseBlackIcon from '../../assets/icons/close-black.png';
import moment from "moment";
import CreatePayment from "./create-payment";

const TAB = {
    CONSOLIDATED: 1,
    INDIVIDUAL: 2,
}

class Payments extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: TAB.CONSOLIDATED,
            thead: [],
            trows: [],
            total: 0,
            header: [
                {
                    value: "id",
                    label: "ID",
                    type: 1,
                    section: "required",
                    order: 0
                },
                {
                    value: "created_at",
                    label: "Fecha Emisión",
                    type: 1,
                    section: "required",
                    order: 0
                },
                {
                    value: "dispatch_date",
                    label: "Fecha Despacho",
                    type: 1,
                    section: "required",
                    order: 0
                },
                {
                    value: "seller",
                    label: "Vendedor",
                    type: 1,
                    section: "required",
                    order: 1
                },
                {
                    value: "client",
                    label: "Cliente",
                    type: 1,
                    section: "required",
                    order: 2
                },
                {
                    value: "status",
                    label: "Estatus\nPago",
                    type: 1,
                    section: "required",
                    order: 3
                },
                {
                    value: "amount",
                    label: "Total\nDeuda",
                    type: 1,
                    section: "required",
                    order: 3
                },
                {
                    value: "to_pay",
                    label: "Total\nPor Cobrar",
                    type: 1,
                    section: "required",
                    order: 4
                },
                // {
                //   value: "branch",
                //   label: "Sucursal",
                //   type: 1,
                //   section: "required",
                //   order: 5
                // },
                // {
                //   value: "warehouse",
                //   label: "Almacen",
                //   type: 1,
                //   section: "required",
                //   order: 6
                // },
                // {
                //   value: "payment_method",
                //   label: "Método de pago",
                //   type: 1,
                //   section: "required",
                //   order: 6
                // },
                // {
                //   value: "",
                //   label: "Acciones",
                //   type: 1,
                //   section: "required",
                //   order: 7
                // },
            ],
            optionals: [
                { value: "code", label: "Código", show: true },
                { value: "name", label: "Nombre", show: true },
                { value: "branch", label: "Almacen", show: true },
                { value: "warehouse", label: "Almacen", show: true },
                { value: "current_stock", label: "Existencia", show: true },
                //{ value: "committed", label: "Comprometido", show: false },
                { value: "available", label: "Disponible", show: true },
                { value: "total", label: "Total", show: true },
                //{ value: "cost", label: "Costo", show: false },
                //{ value: "price", label: "Precio Min.", show: false },
                //{ value: "price_max", label: "Precio Max.", show: false },
                //{ value: "price_offer", label: "Precio Oferta", show: false }
            ],
            page: 1,
            last_page: 1,
            data: [],
            branches: [],
            warehouses: [],
            user: null,
            providers: {},
            form: {
                status: "",
                search: "",
                branch_id: "",
                warehouse_id: "",
                category_id: "",
                subcategory_id: "",
                brand_id: "",
                model_id: "",
                user_id: this.props.user.id,
                code: true,
                order_id: "",
                name: true,
                current_stock: true,
                total: false,
                price: false,
                price_max: false,
                price_offer: false,
                cost: false,
                committed: false,
                available: false,
                since: "", //moment().toDate(),
                until: "", //moment().toDate(),
                payment_method_id: "",
                client_id: "",
                seller_id: "",
                zone_id: "",
                currency: Constants.CURRENCIES.DOLARES,
                is_expired: "",
            },
            categories: [],
            subcategories: [],
            brands: [],
            models: [],
            payment_methods: [],
            clients: [],
            sellers: [],
            paymentMethods: [],
            zones: [],
            countPrice: 0,
            user_id:
                this.props.user.role === 4
                    ? this.props.user.enterprise_users.enterprise_id
                    : this.props.user.id,
            role: this.props.user.role,
            showProductsFilters: true,
            dates: {
                minDate: "",
                maxDate: ""
            },
            total_pedidos: 0,
            total_abonado: 0,
            total_cobrar: 0,
            modalPaymentDetails: false,
            modalAddPayment: false,
            modalAddPaymentMultiple: false,
            selectedItem: null,
            dataClients: [],
            showPhoto: null,
            status_payments: [
                {label: 'Vencida', value: 1},
                {label: 'Próximo a vencer', value: 2}
            ]
        };
    }

    abortController = new AbortController();
    debounce = createRef();

    componentDidMount() {
        this.load();
        this.getThead();
        this.getClients();
        this.getPaymentMethods();
        this.getSellers();
        this.getZones();
    }
    // componentDidUpdate(_, prevState) {
    //   if (prevState.form.branch_id !== this.state.form.branch_id && this.state.form.branch_id) {
    //     this.getWarehouses();
    //     this.load();
    //   }
    // }
    componentWillUnmount() {
        this.abortController.abort();
    }

    load = async (page = 1) => {
        this.state.tab === TAB.CONSOLIDATED
            ? this.getConsolidated(page)
            : this.getIndividual(page);
    };

    getIndividual = (resetPage = null) => {
        let { page, form } = this.state;

        if (typeof resetPage === "number") {
            page = resetPage;
        }

        form.page = page;
        form.user_id = this.props.user.id;
        form.web = true
        form.withoutUsers = true

        axios
            .post("admin/reports/accounts-receivable?page=" + page, form)
            .then(res => {
                this.setState({
                    page,
                    last_page: res.data.orders.last_page,
                    data: res.data.orders.data,
                    total_pedidos: res.data.total,
                    total_abonado: res.data.total_paid,
                    total_cobrar: res.data.total_to_pay,
                });
            })
            .catch(err => {
                Globals.showError();
            });
    };

    getConsolidated = (resetPage = null) => {
        let { page, form } = this.state;

        if (typeof resetPage === "number") {
            page = resetPage;
        }

        form.page = page;
        form.user_id = this.props.user.id;
        form.web = true;

        axios
            .post("admin/accounts-receivable/consolidated?page=" + page, form)
            .then(res => {

                const dataClients = new Set();
                res.data.clients.data.map(x => dataClients.add(x.id));

                this.setState({
                    page,
                    last_page: res.data.clients.last_page,
                    data: res.data.clients.data,
                    total_pedidos: res.data.total,
                    total_abonado: res.data.total_paid,
                    total_cobrar: res.data.total_to_pay,
                    dataClients: [...dataClients],
                });
            })
            .catch(err => {
                Globals.showError();
            });
    };

    getPaymentMethods = async () => {
        const { form } = this.state;
        try {
            const res = await Payment.getPaymentMethods(form);
            if (!res?.methods?.data?.length) throw new Error();

            this.setState({ paymentMethods: res.methods.data.map(x => ({ value: x.id, label: x.name })) });

        } catch (error) {
            Globals.showError('Ocurrió un error al obtener los métodos de pago');
            console.log('getPaymentMethods -> catch: ', error)
        }
    }

    getClients = async () => {
        Globals.setLoading();

        try {
            const filter = {
                user_id: this.state.form.user_id,
                search: this.state.form.search,
            };

            const res = await axios.post('web/admin/orders/clients', filter);
            if (!res.data?.clients) throw Error('No se encontraron los clientes');

            const clients = res.data.clients.data.map(c => ({
                ...c,
                value: c.id,
                label: !!c.document ? `${c.name} - Rif ${c.document}` : c.name,
            }));

            this.setState({ clients });

        } catch (error) {
            Globals.showError(error?.message);
            console.log('Cuentas por Pagar -> getClients -> catch:', error)
        }

        Globals.quitLoading();
    }

    getSellers = async () => {
        Globals.setLoading();

        try {
            const filter = {
                user_id: this.state.form.user_id,
            };

            const res = await axios.post('web/admin/orders/sellers', filter);
            if (!res.data?.sellers) throw Error('No se encontraron los vendedores');

            const sellers = res.data.sellers.data.map(c => ({
                ...c,
                value: c.id,
                label: !!c.document ? `${c.name} - Rif ${c.document}` : c.name,
            }));

            this.setState({ sellers });

        } catch (error) {
            Globals.showError(error?.message);
            console.log('Cuentas por Pagar -> getSellers -> catch:', error)
        }

        Globals.quitLoading();
    }

    getZones = async () => {
        Globals.setLoading();

        axios
            .post("web/admin/zones", { user_id: this.props.user.id })
            .then(({ data }) => {
                data.zones.unshift({ id: "", name: "Todas" });
                this.setState({ zones: data.zones.map(item => ({ value: item.id, label: item.name })) });
            })
            .catch(() => Globals.showError())
            .then(() => Globals.quitLoading());
    }

    warehouses = () => {
        let param = {
            role: this.props.user.role,
            user_id: this.props.user.id
        };
        axios
            .post("admin/products/warehouses", param)
            .then(res => {
                if (res.data.result) {
                    let form = [];
                    res.data.warehouses.forEach((el, i) => {
                        form.push({
                            id: el.id,
                            name: el.name,
                            stock_min: "",
                            stock_max: "",
                            price: "",
                            location: ""
                        });
                    });
                    this.setState({
                        warehouses: form
                    });
                } else {
                    Globals.showError(res.data.msg);
                }
            })
            .catch(err => {
                Globals.showError();
            })
            .then(() => {
                Globals.quitLoading();
            });
    };

    getWarehouses = id => {
        if (!id) {
            let warehouses_filtered = [];
            this.setState(state => ({
                ...state,
                warehouses: warehouses_filtered,
                form: {
                    ...state.form,
                    warehouse_id: ""
                }
            }));
            return;
        }

        let { user } = this.props;

        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        Warehouse.getWarehouses({
            role: user.role,
            user_id: enterpriseId,
            branch_id: id
        }).then(response => {
            if (response.result) {
                let warehouses_filtered = [];
                if (user.role === 4) {
                    warehouses_filtered = response.warehouses.data.filter(el => {
                        return user.assigned_warehouses.some(f => {
                            return parseInt(f.warehouse_id) === parseInt(el.id);
                        });
                    });
                } else {
                    warehouses_filtered = response.warehouses.data;
                }

                this.setState(state => ({
                    warehouses: warehouses_filtered,
                    form: {
                        ...state.form,
                        warehouse_id: ""
                    }
                }));
            }
        });
    };

    getSubcategories = categoryId => {
        let param = {
            category_id: categoryId,
            select: true
        };
        Category.getSubcategories(param).then(response => {
            if (response.result) {
                this.setState(state => ({
                    ...state,
                    subcategories: response.subcategories,
                    form: {
                        ...state.form,
                        subcategory_id: ""
                    }
                }));
            }
        });
    };

    getModels = brandId => {
        let param = {
            brand_id: brandId,
            select: true,
            user_id: this.props.user.id
        };
        const { form } = this.state;
        Brand.getModels(param).then(response => {
            if (response.result) {
                this.setState({
                    models: response.models,
                    form: {
                        ...form,
                        model_id: ""
                    }
                });
            }
        });
    };

    openProductsDetails = e => {
        this.change(e);
        if (e.target.value === "")
            this.setState({
                showProductsFilters: false
            });
        else
            this.setState({
                showProductsFilters: true
            });
    };

    change = async (e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
    };

    debouncingSearchClient = async (search) => {
        if (this.debounce.current) clearTimeout(this.debounce.current);

        await this.change({ target: { value: undefined, name: 'client_id' } });
        await this.change({ target: { value: search, name: 'search' } });
        this.debounce.current = setTimeout(async () => {
            this.getClients();
        }, 1000);
    }

    changeTab = async (tab) => {
        await this.setState({ tab, data: [], dataClients: [], page: 1 });

        this.state.tab === TAB.CONSOLIDATED
            ? this.getConsolidated()
            : this.getIndividual();
    }

    handleCheck = async e => {
        var { name, checked } = e.target;
        let { form, header, trows } = this.state;

        let sectionPrices = header
            .filter(Item => {
                return Item.section === "price";
            })
            .map(Item => {
                return Item.value;
            });

        let sectionStock = header
            .filter(Item => {
                return Item.section === "stock";
            })
            .map(Item => {
                return Item.value;
            });

        if (trows.length === 0) {
            trows = header.filter(Item => {
                return Item.type === 1;
            });
        } else {
            trows = trows.filter(Item => {
                return Item.type;
            });
        }

        let find = header.find(Item => Item.value === name);

        if (sectionPrices.includes(name)) {
            if (!trows.find(Item => Item.value === find.value)) {
                let countSecPrice = trows.filter(Item => {
                    return Item.section === "price";
                });

                trows.push(find);

                if (countSecPrice.length === 0) {
                    let totalRow = header.find(Item => Item.value === "total");
                    totalRow.multiplier = name;
                    trows.push(totalRow);
                } else {
                    let indexRow = trows.findIndex(Item => Item.value === "total");
                    if (indexRow > -1) {
                        trows.splice(indexRow, 1);
                    }
                }
            } else {
                let index2 = trows.findIndex(Item => Item.value === name);
                trows.splice(index2, 1);

                let countSecPrice = trows.filter(Item => {
                    return Item.section === "price";
                });

                if (countSecPrice.length === 1) {
                    let totalRow = header.find(Item => Item.value === "total");
                    totalRow.multiplier = countSecPrice[0].value;
                    trows.push(totalRow);
                } else {
                    let indexRow = trows.findIndex(Item => Item.value === "total");
                    if (indexRow > -1) {
                        trows.splice(indexRow, 1);
                    }
                }
            }
        }

        if (sectionStock.includes(name)) {
            if (!trows.find(Item => Item.value === find.value)) {
                let countSecStock = trows.filter(Item => {
                    return Item.section === "stock";
                });

                trows.push(find);

                if (countSecStock.length === 0) {
                    let totalAvailable = header.find(Item => Item.value === "available");
                    trows.push(totalAvailable);
                } else {
                    let indexRow = trows.findIndex(Item => Item.value === "available");
                    if (indexRow > -1) {
                        trows.splice(indexRow, 1);
                    }
                }
            } else {
                let index2 = trows.findIndex(Item => Item.value === name);
                trows.splice(index2, 1);

                let countSecStock = trows.filter(Item => {
                    return Item.section === "stock";
                });

                if (countSecStock.length === 1) {
                    let totalAvailable = header.find(Item => Item.value === "available");
                    trows.push(totalAvailable);
                } else {
                    let indexRow = trows.findIndex(Item => Item.value === "available");
                    if (indexRow > -1) {
                        trows.splice(indexRow, 1);
                    }
                }
            }
        }

        trows.sort((a, b) => parseInt(a.order) - parseInt(b.order));

        let thead = trows.map(Item => {
            return Item.label;
        });

        this.setState({
            form: {
                ...form,
                [name]: checked
            }
        });

        this.getThead(thead, trows);
        return;
    };

    getThead = async (arr = [], arr2 = []) => {
        const { header } = this.state;

        let newHeader = arr;

        if (newHeader.length === 0) {
            newHeader = header
                .filter(Item => {
                    return Item.type === 1;
                })
                .map(Item => {
                    return Item.label;
                });
        }

        await this.setState(state => {
            state.thead = newHeader;
            state.trows = arr2;
        });
    };

    returnState = async () => {
        await this.setState(state => ({
            ...state,
            page: 1,
            search: '',
            form: {
                status: "",
                search: "",
                branch_id: "",
                warehouse_id: "",
                category_id: "",
                subcategory_id: "",
                brand_id: "",
                model_id: "",
                user_id: "",
                order_id: "",
                payment_method_id: "",
                since: "",
                until: "",
                client_id: "",
                seller_id: "",
                zone_id: "",
                is_expired: "",
            },
            thead: [],
            trows: [],
            optionals: [
                { value: "code", label: "Código", show: true },
                { value: "name", label: "Nombre", show: true },
                { value: "branch", label: "Almacen", show: true },
                { value: "warehouse", label: "Almacen", show: true },
                { value: "current_stock", label: "Existencia", show: true },
                //{ value: "committed", label: "Comprometido", show: false },
                { value: "available", label: "Disponible", show: true },
                { value: "total", label: "Total", show: true },
                //{ value: "cost", label: "Costo", show: false },
                //{ value: "price", label: "Precio Min.", show: false },
                //{ value: "price_max", label: "Precio Max.", show: false },
                //{ value: "price_offer", label: "Precio Oferta", show: false }
            ],
        }));
        await this.getThead();
        await this.load();
    };

    print = () => {
        let { form } = this.state;
        form.currency = Constants.CURRENCIES.DOLARES
        Globals.typeDownloadOptions(
            `Selecciona en que formato deseas que se exporte el reporte`,
            (type_download) => {
                this.setState(state => ({
                    form: {
                        ...state.form,
                        type_download: type_download
                    }
                }));
                axios
                    .post("admin/reports/accounts-receivable/download", this.state.form)
                    .then(res => {
                        if (res.data.url_storage) {
                            //const url = res.data.url;
                            const url = `${ENV.BasePublic}${res.data.url_storage}`;
                            const win = window.open(url, "_blank");
                            win.focus();
                        }
                        else {
                            Globals.showError('Ha ocurrido un error');
                        }
                    })
                    .catch(err => {
                        Globals.showError();
                    });
            }
        );

    };

    printConsolidated = () => {
        let { form } = this.state;
        form.currency = Constants.CURRENCIES.DOLARES
        // Globals.typeDownloadOptions(
        //     `Selecciona en que formato deseas que se exporte el reporte`,
        //     (type_download) => {
        //         this.setState(state => ({
        //             form: {
        //                 ...state.form,
        //                 type_download: type_download
        //             }
        //         }));
        //         axios
        //             .post("admin/accounts-receivable/consolidated-download", this.state.form)
        //             .then(res => {
        //                 if (res.data.url_storage) {
        //                     const url = `${ENV.BasePublic}${res.data.url_storage}`;
        //                     const win = window.open(url, "_blank");
        //                     win.focus();
        //                 }
        //                 else {
        //                     Globals.showError('Ha ocurrido un error');
        //                 }
        //             })
        //             .catch(err => {
        //                 Globals.showError();
        //             });
        //     }
        // );

        this.setState(state => ({
            form: {
                ...state.form,
                type_download: 'pdf'
            }
        }));
        axios
            .post("admin/accounts-receivable/consolidated-download", this.state.form)
            .then(res => {
                if (res.data.url_storage) {
                    const url = `${ENV.BasePublic}${res.data.url_storage}`;
                    const win = window.open(url, "_blank");
                    win.focus();
                }
                else {
                    Globals.showError('Ha ocurrido un error');
                }
            })
            .catch(err => {
                Globals.showError();
            });
    };

    close = (reload = false) => {
        this.setState({ modalPaymentDetails: false, modalAddPayment: false, selectedItem: null, modalAddPaymentMultiple: false });
        if (reload) this.load();
    }

    viewPayments = (item) => {
        console.log(item)
        this.setState({ modalPaymentDetails: true, selectedItem: item });
    }

    addPayment = (item) => {
        this.setState({ modalAddPayment: true, selectedItem: item });
    }

    addPaymentMultiple = (item) => {
        console.log(item)

        // Verificar si existe al menos un pago con admin_confirmation igual a 0
        const hasUnconfirmedPayment = item.orders.some(order => 
            order.payments.some(payment => payment.admin_confirmation === 0)
        );
        if (hasUnconfirmedPayment) {
            Globals.showError("No se puede registrar un abono múltiple a esta cuenta, debido a que tiene abonos pendientes por aprobar.");
            return;
        }

        this.setState({ modalAddPaymentMultiple: true, selectedItem: item });
    }

    approve = (item) => {
        const data = {
            order_id: item.order_id,
            payment_id: item.id,
        };

        Globals.confirm('¿Realmente desea aprobar este pago?', async () => {
            try {
                const res = await axios.post('admin/accounts-receivable/approve', data);
                if (!res.data?.result) throw new Error('Ocurrió un error inesperado');
                Globals.showSuccess('Pago aprobado con éxito');
                this.close(true);

            } catch (error) {
                Globals.showError(error?.message);
                console.log('Payments -> approve -> catch: ', error);
            }
        });
    }

    reject = (item) => {
        const data = {
            order_id: item.order_id,
            payment_id: item.id,
        };

        Globals.confirm('¿Realmente desea rechazar este pago?', async () => {
            try {
                const res = await axios.post('admin/accounts-receivable/reject', data);
                if (!res.data?.result) throw new Error('Ocurrió un error inesperado');
                Globals.showSuccess('Pago rechazado con éxito');
                this.close(true);

            } catch (error) {
                Globals.showError(error?.message);
                console.log('Payments -> reject -> catch: ', error);
            }
        });
    }

    delete = async (item) => {
        console.log(item)
        Globals.setLoading();

        try {
            const data = {
                web: true,
                user_id: this.props.user.id,
                payment_id: item.id,
            };

            await axios.post('admin/accounts-receivable/delete', data);
            Globals.showSuccess("Se ha eliminado el pago correctamente");
            this.close(true)

        } catch (error) {
            Globals.showError();
            console.log('Commissions -> load -> catch: ', error);
        }

        Globals.quitLoading();
    }

    getStatusName = (status) => {
        switch (status) {
            case Constants.PAYMENT_ADMIN_CONFIRM.PENDING:
                return 'Pendiente por aprobación';
            case Constants.PAYMENT_ADMIN_CONFIRM.REJECTED:
                return 'Rechazado';
            case Constants.PAYMENT_ADMIN_CONFIRM.APPROVED:
            default:
                return 'Aprobado';
        }
    }

    getStatusColor = (status) => {
        switch (status) {
            case Constants.PAYMENT_ADMIN_CONFIRM.PENDING:
                return '#3A7FC2';
            case Constants.PAYMENT_ADMIN_CONFIRM.REJECTED:
                return '#DD4B4B';
            case Constants.PAYMENT_ADMIN_CONFIRM.APPROVED:
            default:
                return '#38CE6A';
        }
    }

    render() {
        const { tab, trows } = this.state;
        const showTotal = trows.find(i => i.value === 'total') && trows.find(i => i.value === 'cost');
        const {
            total_pedidos,
            total_abonado,
            total_cobrar,
            modalPaymentDetails,
            modalAddPayment,
            modalAddPaymentMultiple,
            selectedItem,
            paymentMethods,
            showPhoto,
        } = this.state;

        return (
            <Menu history={this.props.history}>

                {!!showPhoto && (
                    <div className="modal-planner-view-photo" style={{ zIndex: 2050 }}>
                        <div className="close-photo" onClick={() => this.setState({ showPhoto: null })}>
                            <img src={CloseBlackIcon} alt="Cerrar" />
                        </div>
                        <div className="photo-wrapper">
                            <img src={Globals.fromPhotos(showPhoto)} alt="" />
                        </div>
                    </div>
                )}

                {modalPaymentDetails && (
                    <Modal title="Pagos Realizados" onClose={() => this.close()} visible>
                        {
                            selectedItem?.payments?.map((item) => (
                                <div style={{ marginBottom: 20, paddingBottom: 10, borderBottom: '1px solid lightgray' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <span style={{ marginRight: 5 }}><b>Pago:</b> </span>
                                        <span>{Globals.formatMiles(item.amount, true)}</span>
                                    </div>
                                    {
                                        item?.method && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <span style={{ marginRight: 5 }}><b>Método:</b> </span>
                                                <span>{item.method?.name}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        item?.comments && (
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                <span style={{ marginRight: 5 }}><b>Comentarios:</b> </span>
                                                <span>{item.comments}</span>
                                            </div>
                                        )
                                    }
                                    {
                                        item.registered_by && (
                                            <span style={{ flexDirection: 'row', alignItems: 'center' }}>
                                                <span style={{ marginRight: 5 }}><b>Vendedor/Cobrador: </b></span>
                                                <span>{item?.author?.name}</span>
                                            </span>
                                        )
                                    }
                                    <div style={{ flexDirection: 'row', alignItems: 'center' }}>
                                        <span style={{ marginRight: 5 }}><b>Estatus: </b></span>
                                        <span style={{ color: this.getStatusColor(item.admin_confirmation) }}>
                                            {this.getStatusName(item.admin_confirmation)}
                                        </span>
                                    </div>
                                    {!!item?.photo && (
                                        <div onClick={() => this.setState({ showPhoto: item.photo })}>
                                            <img src={Globals.fromPhotos(item.photo)} style={{ width: 100, height: 100, objectFit: 'contain', cursor: 'pointer' }} alt="" />
                                        </div>
                                    )}
                                    <span>{moment(item.created_at).format('DD/MM/YYYY HH:mm')}</span>
                                    {this.props.user.level_id === Constants.ROLE_ADMIN && (
                                        <div style={{ display: 'grid', gridAutoFlow: 'column', direction: 'rtl', gridTemplateColumns: 'repeat(3, 1fr)', flexDirection: 'row-reverse', marginTop: 8, justifyContent: 'space-around', textAlign: 'center' }}>
                                            <div
                                                style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.REJECTED) }}
                                                onClick={() => this.delete(item)}
                                            >
                                                ELIMINAR
                                            </div>
                                            {item.admin_confirmation === Constants.PAYMENT_ADMIN_CONFIRM.PENDING && (
                                                <>
                                                    <div
                                                        style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.REJECTED) }}
                                                        onClick={() => this.reject(item)}
                                                    >
                                                        RECHAZAR
                                                    </div>
                                                    <div
                                                        style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.APPROVED) }}
                                                        onClick={() => this.approve(item)}
                                                    >
                                                        APROBAR
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    )}
                                    
                                    {this.props.user.level_id === Constants.ROLE_MODERATOR && this.props.user.permissions.length > 0 &&
                                        this.props.user.permissions.some(permission =>
                                            permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_ACCEPT_PAYMENT ||
                                            permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_DELETE_PAYMENT ||
                                            permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_REJECT_PAYMENT
                                        ) && (
                                            <div style={{
                                                display: 'grid',
                                                gridAutoFlow: 'column',
                                                direction: 'rtl',
                                                gridTemplateColumns: 'repeat(3, 1fr)',
                                                flexDirection: 'row-reverse',
                                                marginTop: 8,
                                                justifyContent: 'space-around',
                                                textAlign: 'center'
                                            }}>
                                                {this.props.user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_DELETE_PAYMENT) && (
                                                    <div
                                                        style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.REJECTED) }}
                                                        onClick={() => this.delete(item)}
                                                    >
                                                        ELIMINAR
                                                    </div>
                                                )}

                                                {item.admin_confirmation === Constants.PAYMENT_ADMIN_CONFIRM.PENDING && (
                                                    <>
                                                        {this.props.user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_REJECT_PAYMENT) && (
                                                            <div
                                                                style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.REJECTED) }}
                                                                onClick={() => this.reject(item)}
                                                            >
                                                                RECHAZAR
                                                            </div>
                                                        )}
                                                        {this.props.user.permissions.some(permission => permission.code === Constants.PERMISSIONS.MODERATOR.ORDERS_ACCEPT_PAYMENT) && (
                                                            <div
                                                                style={{ flex: 1, cursor: 'pointer', color: this.getStatusColor(Constants.PAYMENT_ADMIN_CONFIRM.APPROVED) }}
                                                                onClick={() => this.approve(item)}
                                                            >
                                                                APROBAR
                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            </div>
                                        )
                                    }

                                </div>
                            ))
                        }

                        {
                            !selectedItem?.payments?.length && (
                                <p style={{ padding: 10, textAlign: 'center' }}>No se han registrado pagos</p>
                            )
                        }

                        <Button block secondary onClick={() => this.close()}>
                            <span>CERRAR</span>
                        </Button>
                    </Modal>
                )}

                {modalAddPayment && (
                    <Modal title="Agregar Pago" onClose={() => this.close()} visible>
                        <CreatePayment
                            order={selectedItem}
                            client={null}
                            type={Constants.PAYMENT_INDIVIDUAL}
                            paymentMethods={paymentMethods}
                            onClose={this.close}
                        />
                    </Modal>
                )}

                {modalAddPaymentMultiple && (
                    <Modal title="Agregar Pago Múltiple" onClose={() => this.close()} visible>
                        <CreatePayment
                            order={selectedItem}
                            client={selectedItem}
                            type={Constants.PAYMENT_MULTIPLE}
                            paymentMethods={paymentMethods}
                            onClose={this.close}
                        />
                    </Modal>
                )}

                <div id="products">
                    <div className="row">
                        <div className="col-md-2">
                            <Input
                                color=" "
                                name="order_id"
                                label="ID"
                                className="filter"
                                value={this.state.form.order_id}
                                onChange={emitter => this.change(emitter)}
                            />
                        </div>
                        <div className="col-md-2 select-action-as-filter">
                            <SelectActions
                                label="Cliente"
                                placeholder="Nombre del Cliente o Rif"
                                className="filter"
                                isSearchable
                                options={this.state.clients.map(i => ({
                                    value: i.id,
                                    label: i.name,
                                    actions: [],
                                }))}
                                value={this.state.form.client_id}
                                onChange={value => this.change({ target: { value, name: 'client_id' } })}
                                onInputChange={this.debouncingSearchClient}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select
                                color="white"
                                name="seller_id"
                                label="Vendedor"
                                className="filter"
                                style={{ textTransform: "capitalize" }}
                                onChange={emitter => this.change(emitter)}
                                value={this.state.form.seller_id}
                                options={this.state.sellers}
                            />
                        </div>
                        <div className="col-md-2" />
                        <div className="col-md-2" />
                        <div className="col-md-2" />
                        {/* <div className="col-md-2">
              <Select
                color="white"
                name="zone_id"
                label="Zona"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.zone_id}
                options={this.state.zones}
              />
            </div> */}
                        <div className="col-md-2">
                            <DatePicker
                                color="white"
                                label="Desde"
                                className="filter"
                                minDate={this.state.dates.minDate}
                                value={this.state.form.since}
                                onChange={value => {
                                    this.setState(state => ({
                                        form: {
                                            ...state.form,
                                            since: value
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <DatePicker
                                color="white"
                                label="Hasta"
                                className="filter"
                                maxDate={this.state.dates.maxDate}
                                value={this.state.form.until}
                                onChange={value => {
                                    this.setState(state => ({
                                        form: {
                                            ...state.form,
                                            until: value
                                        }
                                    }));
                                }}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select
                                color="white"
                                name="payment_method_id"
                                label="Método pago"
                                className="filter"
                                style={{ textTransform: "capitalize" }}
                                onChange={emitter => this.change(emitter)}
                                value={this.state.form.payment_method_id}
                                options={this.state.paymentMethods}
                            />
                        </div>
                        <div className="col-md-2">
                            <Select
                                color="white"
                                name="is_expired"
                                label="Estatus Pago"
                                className="filter"
                                style={{ textTransform: "capitalize" }}
                                onChange={emitter => this.change(emitter)}
                                value={this.state.form.is_expired}
                                options={this.state.status_payments}
                            />
                        </div>
                        <div className="col-md-4" style={{ display: 'flex', gap: '2rem' }}>
                            <Button className="btn-align-bottom btn-filter" color=" " onClick={() => this.load(1)}>
                                <span>Filtrar</span>
                            </Button>
                            <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                                <span>Limpiar</span>
                            </Button>
                        </div>
                    </div>

                    {(!!this.state.optionals.length && this.state.optionals.some(x => !x.show)) && (
                        <div className="row" id="row1">
                            <div className="col col-md d-flex flex-wrap justify-content-between align-items-center check">
                                {this.state.optionals.map(el => {
                                    if (!el.show) {
                                        return (
                                            <div className="px-3 py-3 check1">
                                                <CheckBox
                                                    label={el.label}
                                                    name={el.value}
                                                    checked={this.state.form[el.value] ? true : false}
                                                    value={this.state.form[el.value]}
                                                    onChange={this.handleCheck}
                                                />
                                            </div>
                                        );
                                    }
                                    return <></>
                                })}
                            </div>
                        </div>
                    )}

                    <div className="row monthly-earnings mb-3">
                        <div className="col-sm-2" style={{ backgroundColor: '#BAB3B1', padding: '10px', margin: '5px' }}>
                            <span className="" style={{ fontWeight: 'bold' }}>TOTAL PEDIDOS</span>
                            <div style={{ fontWeight: 'bold' }}>{Globals.formatMiles(total_pedidos)} $</div>
                        </div>
                        <div className="col-sm-2" style={{ backgroundColor: '#BAB3B1', padding: '10px', margin: '5px' }}>
                            <span className="" style={{ fontWeight: 'bold' }}>TOTAL ABONADOS</span>
                            <div style={{ fontWeight: 'bold' }}>{Globals.formatMiles(total_abonado)} $</div>
                        </div>
                        <div className="col-sm-2" style={{ backgroundColor: '#BAB3B1', padding: '10px', margin: '5px' }}>
                            <span className="" style={{ fontWeight: 'bold' }}>TOTAL POR COBRAR</span>
                            <div style={{ fontWeight: 'bold' }}>{Globals.formatMiles(total_cobrar)} $</div>
                        </div>
                    </div>


                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', marginBottom: '1rem' }}>
                        <Button
                            className="btn-filter"
                            color={tab === TAB.CONSOLIDATED ? 'blue' : ' '}
                            onClick={() => this.changeTab(TAB.CONSOLIDATED)}
                        >
                            <span className={tab === TAB.CONSOLIDATED ? 'text-white' : ''}>Consolidado</span>
                        </Button>
                        <Button
                            className="btn-filter"
                            color={tab === TAB.INDIVIDUAL ? 'blue' : ' '}
                            onClick={() => this.changeTab(TAB.INDIVIDUAL)}
                        >
                            <span className={tab === TAB.INDIVIDUAL ? 'text-white' : ''}>Individual</span>
                        </Button>
                    </div>

                    {tab === TAB.CONSOLIDATED && (
                        <Table
                            data={this.state.data.length}
                            title="Cuentas por cobrar"
                            header={['ID', 'Fecha de emisión', 'Fecha de vencimiento', 'Días de vencimiento', 'Estatus pago', 'Saldo']}
                            right={
                                <Button title="Imprimir" small="true" onClick={this.printConsolidated}>
                                    <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                                </Button>
                            }
                        >
                            {this.state.dataClients.map(clientId => {
                                const client = this.state.data.find(x => x.id === clientId);
                                const total = client?.orders_filter?.length > 0 ? client.orders_filter.map(x => x.to_pay).reduce((acc, curr) => acc + curr, 0) : client?.orders?.map(x => x.to_pay).reduce((acc, curr) => acc + curr, 0);

                                return client?.orders_filter?.length > 0 ? client.orders_filter.map((i, index) => {
                                    const expireDate = moment(i.created_at).add(i.credit_days || 0, 'days');
                                    const isExpired = moment() >= expireDate;
                                    const isFirst = !index;

                                    return (
                                        <React.Fragment key={index}>
                                            {isFirst && (
                                                <tr>
                                                    <td colSpan={5} style={{ color: '#3a7fc2', fontWeight: 'bold' }}>
                                                        {client?.name}
                                                    </td>
                                                    <td align="right" style={{ color: '#3a7fc2', fontWeight: 'bold' }}>
                                                        $ {Globals.formatMiles(total, true)}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="green"
                                                            title="Agregar pago múltiple"
                                                            small="true"
                                                            onClick={() => this.addPaymentMultiple(client)}
                                                        >
                                                            <Icon name="upload" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>{i.correlative_number}</td>
                                                <td>{moment(i.created_at).format('DD/MM/YYYY')}</td>
                                                <td>{moment(i.created_at).add(i.credit_days || 0, 'days').format('DD/MM/YYYY')}</td>
                                                <td>{i.credit_days || '-'}</td>
                                                <td style={{ color: isExpired ? Colors.red : Colors.green }}>
                                                    {isExpired ? 'Vencida' : 'Próxima a vencer'}
                                                </td>
                                                <td>{parseFloat(Number(i.to_pay)).toFixed(2)}</td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                }) : client?.orders?.map((i, index) => {
                                    const expireDate = moment(i.created_at).add(i.credit_days || 0, 'days');
                                    const isExpired = moment() >= expireDate;
                                    const isFirst = !index;

                                    return (
                                        <React.Fragment key={index}>
                                            {isFirst && (
                                                <tr>
                                                    <td colSpan={5} style={{ color: '#3a7fc2', fontWeight: 'bold' }}>
                                                        {client?.name}
                                                    </td>
                                                    <td align="right" style={{ color: '#3a7fc2', fontWeight: 'bold' }}>
                                                        $ {Globals.formatMiles(total, true)}
                                                    </td>
                                                    <td>
                                                        <Button
                                                            color="green"
                                                            title="Agregar pago múltiple"
                                                            small="true"
                                                            onClick={() => this.addPaymentMultiple(client)}
                                                        >
                                                            <Icon name="upload" />
                                                        </Button>
                                                    </td>
                                                </tr>
                                            )}
                                            <tr>
                                                <td>{i.correlative_number}</td>
                                                <td>{moment(i.created_at).format('DD/MM/YYYY')}</td>
                                                <td>{moment(i.created_at).add(i.credit_days || 0, 'days').format('DD/MM/YYYY')}</td>
                                                <td>{i.credit_days || '-'}</td>
                                                <td style={{ color: isExpired ? Colors.red : Colors.green }}>
                                                    {isExpired ? 'Vencida' : 'Próxima a vencer'}
                                                </td>
                                                <td>{parseFloat(Number(i.to_pay)).toFixed(2)}</td>
                                            </tr>
                                        </React.Fragment>
                                    );
                                });

                            })}
                        </Table>
                    )}

                    {tab === TAB.INDIVIDUAL && (
                        <Table
                            data={this.state.data.length}
                            title="Cuentas por cobrar"
                            header={this.state.thead}
                            right={
                                <Button title="Imprimir" small="true" onClick={this.print}>
                                    <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
                                </Button>
                            }
                        >
                            {this.state.data.map((i, index) => {
                                const expireDate = moment(i.created_at).add(i.credit_days || 0, 'days');
                                const isExpired = moment() >= expireDate;
                                    
                                return (
                                    <tr key={index}>
                                        <td>
                                            {i.correlative_number}
                                        </td>
                                        <td>{i.created_es}</td>
                                        <td>{i.dispatch_date != null ? moment(i.dispatch_date).format('DD/MM/YYYY') : ''}</td>
                                        <td>
                                            {i.seller?.name ?? ''}
                                        </td>
                                        <td>
                                            {i.client?.name ?? ''}
                                        </td>
                                        <td style={{ color: isExpired ? Colors.red : Colors.green }}>
                                            {isExpired ? 'Vencida' : 'Próxima a vencer'}
                                        </td>
                                        <td>
                                            {parseFloat(Number(i.total)).toFixed(2)}
                                        </td>
                                        <td>
                                            {parseFloat(Number(i.to_pay)).toFixed(2)}
                                        </td>
                                        <td>
                                            <Button
                                                color="primary"
                                                title="Ver pagos"
                                                small="true"
                                                onClick={() => this.viewPayments(i)}
                                            >
                                                <Icon name="eye" />
                                            </Button>

                                            <Button
                                                color="green"
                                                title="Agregar pago"
                                                small="true"
                                                onClick={() => this.addPayment(i)}
                                            >
                                                <Icon name="upload" />
                                            </Button>
                                        </td>
                                        {/* <td>{
                      i.payment_methods_text ? i.payment_methods_text
                      :
                      i.method ? i.method?.name
                      :
                      ''
                    }
                    </td>
                    <td>{i.status_text}</td> */}
                                        {/* {trows.map(row => {
                      if (
                        row.value === "committed" ||
                        row.value === "available"
                      ) {
                        return (
                          <td>
                            {row.value === "committed"
                              ? disponible(i)
                              : stock(i) - disponible(i) >= 0
                                ? stock(i) - disponible(i)
                                : 0}
                          </td>
                        );
                      } else if (
                        row.type === 2 ||
                        (row.type === 3 && row.value === "total")
                      ) {
                        return (
                          <td>
                            <NumberFormat
                              value={
                                row.value === "total"
                                  ? parseFloat(
                                    (i[row.multiplier] * stock(i)).toFixed(2)
                                  )
                                  : parseFloat(i[row.value].toFixed(2))
                              }
                              displayType={"text"}
                              thousandSeparator={true}
                            />
                          </td>
                        );
                      }
                    })} */}
                                    </tr>
                                );
                            })}
                        </Table>
                    )}

                    {
                        showTotal && (
                            <p style={{
                                fontSize: '16px',
                                fontWeight: 'normal',
                                fontFamily: 'Roboto Light',
                                textAlign: 'right',
                                marginTop: '10px',
                                marginRight: '10px'
                            }}>Costo Total: <NumberFormat
                                    value={parseFloat(this.state.total.toFixed(2))}
                                    displayType={"text"}
                                    thousandSeparator={true}
                                />
                            </p>
                        )
                    }

                    <Pagination
                        pages={this.state.last_page}
                        active={this.state.page}
                        onChange={page => this.load(page)}
                    />

                </div>
            </Menu>
        );
    }
}

export default connect(state => {
    return {
        user: state.user
    };
})(Payments);
