import React from "react";
import { Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";

const RouteAuth = props => {
  let data;

  if (!props.user) {
    data = <Redirect to="/login" />;
  } else {
    data = <Route {...props} />;
    // if (props.user.level === 2) {
    // 	let pathC = props.user.permissions.find(i => i.path === props.path);
    // 	if (!pathC) data = <Redirect to={props.user.permissions[0].path} />;
    // }
  }

  return data;
};

export default connect(state => {
  return {
    user: state.user
  };
})(RouteAuth);
