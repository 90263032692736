import axios from "../../utils/axios";

class Request {
  static async getRequests(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/requests/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getRequestsAllStatus(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/requests/getAll?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersByEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/enterprise", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersByEnterpriseSearch(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/enterprise/search", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersBySeller(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/seller", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersByEnterpriseIdentification(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/enterprise/by-identification", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersBySellerIdentification(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/seller/by-identification", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersByEnterpriseHasPendingRequests(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/enterprise/pending-requests", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersBySellerHasPendingRequests(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/seller/pending-requests", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getId(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/generateId", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async generateId(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/getId", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async generateCodeRequest(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/generateCodeRequest", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createRequest(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async updateRequest(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/update", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async rejectRequest(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/requests/reject", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async exportExcel(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/report/sales", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Request;
