import React from "react";

const CheckBox = props => (
  <div className={"checkbox " + props.classdiv}>
    <label htmlFor={props.name}>
      <input
        {...props}
        type="checkbox"
        className="form-check-input"
        checked={props.checked}
        name={props.name}
        id= {props.name}
      />
      {props.label}
    </label>
  </div>
);

export default CheckBox;
