/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { Button, Input, Select, DatePicker, Modal, Icon } from "../../components";
import { Constants, Globals, axios } from "../../utils";
import AddIcon from '../../assets/icons/add.png';
import TrashIcon from '../../assets/icons/trash.png';

import { QuoteService } from "../../services";

const INITIAL_STATE = {
	client_id: '',
	comments: '',
	expiration_date: '',
  products: []
}

class CreateEditQuote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: INITIAL_STATE,
      client: null,
      modalProducts: false,
      disabled_button: false,
      errors: [],
      products: [],
      data: {
        products: [],
        clients: [],
      },
      edit: false,
      textButton: "Crear",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount() {
    this.getClients();
    if (this.props.edit) this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  getClients = () => {
    axios
      .post("web/admin/quotes/clients", {
        web: true,
        admin_id: this.props.user.admin_id || this.props.user.id
      })
      .then(res => {
        if(res.data.clients){
          this.setState({
            data: {
              ...this.state.data,
              clients: res.data.clients.data,
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    let documents = {};

    const { element } = this.props.edit;
    console.log(element)

    // if(element.person.document){
    //   if (
    //     !element.person.document.indexOf("-") ||
    //     parseInt(element.person.document)
    //   ) {
    //     documents = {
    //       document_type: "V",
    //       document: element.person.document
    //     };
    //   } else {
    //     let division = element.person.document.split("-");
    //     documents = {
    //       document_type: division[0],
    //       document: division[1]
    //     };
    //   }
    // }


    // if (element.name) {
    //   if (element.name.indexOf("-") <= -1) {
    //     element.name = element.name;
    //   } else {
    //     let division = element.name.split("-");
    //     element.name = division[1];
    //   }
    // }

    // let warehouse_id,
    //   branch_id = 0;

    // if (element.assigned_warehouses.length > 0) {
    //   if (element.assigned_warehouses.length === 1) {
    //     branch_id = element.assigned_warehouses[0].branch_id;
    //     warehouse_id = element.assigned_warehouses[0].warehouse_id;
    //   } else {
    //     let count = element.assigned_warehouses.reduce((obj, v) => {
    //       obj[v.branch_id] = (obj[v.branch_id] || 0) + 1;
    //       return obj;
    //     }, {});
    //     if (Object.keys(count).length === 1) {
    //       branch_id = element.assigned_warehouses[0].branch_id;
    //     }
    //   }
    // }

    // if (branch_id) {
    //   await this.getWarehouses(branch_id);
    // }

    await this.setState({
      form: {
        id: element.id,
        seller_id: element.id,
        zone_id: element.zone_id,
        name: element.name,
        email: element.email,
        phone: element.phone,
        //document_type: documents.document_type,
        document: element.document,
        level: element.level_id,
        role: element.level_id,
        password: "",
        password_confirmation: "",
        commission: element.commission,
        goal_amount: element.goal?.amount,
        goal_date: "",
      },
      edit: true,
      textButton: "Editar"
    });

    // if (Types[user.role].value === Types[3].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else if (Types[user.role].value === Types[4].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else {
    //   this.setState({
    //     ...this.state.form,
    //     level: element.roles[0].id
    //   });
    // }
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

	getTotal = () => {
		return this.state.products.map(i => i.selected_price * i.quantity).reduce((a,b) => a + b,0);
	};


	delete = (index) => {
		Globals.confirm('¿Desea eliminar el producto de la cotización?',() => {
			let products = [ ...this.state.products ];
			products.splice(index,1);
			this.setState({ products });
		});
	}

	changeManual = (index, quantity) => {
    let products = [...this.state.products];
		products[index].quantity = quantity;
		this.setState({ products });
	}

	changeValue = (value,target,index,callback = () => {}) => {
		let products = this.state.products;
		products[index][target] = value;

		this.setState({
			products
		},() => callback());
	}

	addOne = (index) => {
		let products = [ ...this.state.products ];
		products[index].quantity++;
		this.setState({ products });
	}

	removeOne = (index) => {
		let products = [ ...this.state.products ];
		if (products[index].quantity > 1) {
			products[index].quantity--;
			this.setState({ products });
		}
	}

  choseProducts = (products = []) => {
    const { products: oldProducts } = this.state;

    const nonAdded = products
      .filter(np => !oldProducts.some(op => op.id === np.id))
      .map(p => ({ ...p, quantity: 1, selected_price: p.sale_price }));

    this.setState({
      products: [ ...oldProducts, ...nonAdded ],
    });
  }

	enableSubmit = () => {
		return this.setState({
			disabled_button: false
		});
	}

	submit = async () => {
		if (this.state.products.filter(i => {
			const product = this.state.products.find(_i => _i.id == i.id);
			return product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && !i.size_id && !i.color_id;
		}).length > 0) {
			Globals.showWarning("Por favor, ingrese el color/talla de los productos que lo requieran");
			this.enableSubmit();
			return false;
		}

		for (let index = 0; index < this.state.products.length; index++) {
			const i = this.state.products[index];
			const product = this.state.products.find(_i => _i.id == i.id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const item_find = this.state.products.find((item,_index) => {
					if (index != _index) {
						if (item.id == i.id && item.color_id == i.color_id && item.size_id == i.size_id) {
							return true;
						}
					}

					return false;
				});

				if (item_find) {
					const color = this.props.colors.find(_i => _i.id == i.color_id);
					const size = this.props.sizes.find(_i => _i.id == i.size_id);
					const product = this.state.products.find(_i => _i.id == i.id);
					Globals.showWarning(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) se encuentra más de una vez en la cotización`);
					this.enableSubmit();
					return false;
				}
			}
		}

		for (let i = 0; i < this.state.products.length; i++) {
			const item = this.state.products[i];
			const product = this.state.products.find(_i => _i.id == item.id);
			if (product.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
				const size_color = product?.size_colors?.find((i) => {
					if (item.size_id && item.color_id) {
						return i.size_id == item.size_id && i.color_id == item.color_id;
					}
					else if (item.size_id) {
						return i.size_id == item.size_id && i.color_id == null;
					}
					else if (item.color_id) {
						return i.color_id == item.color_id && i.size_id == null;
					}
				});
				if (!size_color) {
					const color = this.props.colors.find(_i => _i.id == item.color_id);
					const size = this.props.sizes.find(_i => _i.id == item.size_id);
					Globals.showWarning(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) no se encuentra en inventario`);
					this.enableSubmit();
					return false;
				}
			}
		}

		if (this.state.products.filter(i => !i.quantity || i.quantity == 0).length > 0) {
			Globals.showWarning("Debe seleccionar la cantidad de todos los productos");
			this.enableSubmit();
			return false;
		}

    if (!this.state.form.client_id) {
      Globals.showWarning("El campo Cliente es requerido");
      this.enableSubmit();
      return false;
    }

    if (!this.state.form.expiration_date) {
      Globals.showWarning("El campo Fecha de Vencimiento es requerido");
      this.enableSubmit();
      return false;
    }

    Globals.setLoading();

    try {
      await axios.post('admin/quotes/create', {
        seller_id: this.props.user.id,
        ...this.state.form,
        details: JSON.stringify(this.state.products),
        currency: this.props.currency,
        admin_id: this.props.user.admin_id || this.props.user.id,
        expiration_date: this.state.form.expiration_date && moment(this.state.form.expiration_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
      });
      Globals.showSuccess("Se ha realizado su cotización correctamente");
      this.props.onClose();

    } catch (error) {
      Globals.showError("Ocurrió un error inesperado");
      this.enableSubmit();

    } finally {
      Globals.quitLoading();
    }

	}

	close = async () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalProducts: false });
	}

  render() {
    const { submitted, products, data, disabled_button } = this.state;

    return (
      <form onSubmit={this.submit}>
        <div className="container-create-edit-quote">
          <Modal
            title="Seleccionar producto"
            onClose={() => this.close()}
            visible={this.state.modalProducts}
          >
            <SelectProduct
              onSelect={this.choseProducts}
              onClose={() => this.close()}
            />
          </Modal>

          {this.state.modalProducts && (
            <div
              className="backdrop"
              onClick={() => this.close()}
            />
          )}

          <div className="d-flex justify-content-end">
            <button
              className="add"
              type="button"
              title="Agregar producto"
              onClick={() => {
                document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
                this.setState({ modalProducts: true })
              }}
            >
              <img src={AddIcon} alt="Agregar producto" />
            </button>
          </div>

          {products.length === 0 && (
            <div className="no-items">No hay productos en la cotización</div>
          )}

          {products.map((product, index) => {
            let size_color = null;
            let prices = product.prices;

            if (product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR) {
              size_color = product?.size_colors?.find((i) => {
                if (product.size_id && product.color_id) {
                  return i.size_id == product.size_id && i.color_id == product.color_id;
                }
                else if (product.size_id) {
                  return i.size_id == product.size_id && i.color_id == null;
                }
                else if (product.color_id) {
                  return i.color_id == product.color_id && i.size_id == null;
                }
              });

              prices = size_color?.prices || [];
            }

            return (
              <div key={`product-${index}`} className="product-item">
                <div className="product-image">
                  <img src={ Globals.fromPhotos(product?.photo?.file) } alt="Foto del producto" />
                </div>

                <div className="product-details">
                  <div className="product-name" title={ product.name }>{ product.name }</div>

                  <Select
                    color="gray"
                    defaultname="Seleccione"
                    className="material"
                    labelClass="material"
                    disabledFirst={false}
                    onChange={value => this.changeValue(value, 'selected_price', index)}
                    value={product.selected_price}
                    options={[
                      {
                        value: size_color?.sale_price || product.sale_price,
                        label: '$' + Globals.formatMiles(size_color?.sale_price || product.sale_price, true)
                      },
                      ...prices.map(i => {
                        return {
                          value: i.price,
                          label: Globals.formatMiles(i.price, true)
                        }
                      })
                    ]}
                  />

                  <div className="product-price">
                    <span>$ { Globals.formatMiles(product.selected_price * product.quantity, true) } </span>
                    <span>({ product.quantity } x { Globals.formatMiles(product.selected_price, true) })</span>
                  </div>

                  <div className="product-qty-section">
                    <div className="product-qty-button" onClick={() => this.removeOne(index)}>
                      <span>-</span>
                    </div>
                    <input
                      className="product-qty"
                      value={product.quantity}
                      onChange={e => this.changeManual(index, e.target.value)}
                    />
                    <div className="product-qty-button" onClick={() => this.addOne(index)}>
                      <span>+</span>
                    </div>
                  </div>

                  {
                    product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR && (
                      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                        {
                          [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].length > 0 && (
                            <div style={{ flex: 1 }}>
                              <div>Color:</div>
                                <div className="colors">
                                  {
                                    [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].map((_item) => {
                                      return (
                                        <div
                                          className={`color ${product.color_id == _item ? 'selected' : ''}`}
                                          style={{ backgroundColor: product?.size_colors?.find(x => x.color_id === _item)?.color?.hex }}
                                          onClick={ () => {
                                            this.changeValue(_item,'color_id',index,() => {
                                              const size_color = product?.size_colors?.find((i) => {
                                                if (product.size_id && product.color_id) {
                                                  return i.size_id == product.size_id && i.color_id == product.color_id;
                                                }
                                                else if (product.size_id) {
                                                  return i.size_id == product.size_id && i.color_id == null;
                                                }
                                                else if (product.color_id) {
                                                  return i.color_id == product.color_id && i.size_id == null;
                                                }
                                              });
                                              if (size_color)
                                                this.changeValue(size_color?.sale_price,'price',index,() => {
                                                  this.changeValue(size_color?.sale_price,'selected_price',index);
                                                });
                                            });
                                        } } />
                                      )
                                    })
                                  }
                              </div>
                            </div>
                          )
                        }
                        {
                          [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].length > 0 && (
                            <div style={{ flex: 1 }}>
                              <div>Talla:</div>
                              <Select
                                disabledFirst={false}
                                onChange={ e => {
                                  this.changeValue(e.target.value,'size_id',index,() => {
                                    const size_color = product?.size_colors?.find((i) => {
                                      if (product.size_id && product.color_id) {
                                        return i.size_id == product.size_id && i.color_id == product.color_id;
                                      }
                                      else if (product.size_id) {
                                        return i.size_id == product.size_id && i.color_id == null;
                                      }
                                      else if (product.color_id) {
                                        return i.color_id == product.color_id && i.size_id == null;
                                      }
                                    });
                                    if (size_color)
                                      this.changeValue(size_color?.sale_price,'price',index,() => {
                                        this.changeValue(size_color?.sale_price,'selected_price',index);
                                      });
                                  });
                                } }
                                options={ [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].map(_item => {
                                  const size = product.size_colors?.find(i => i.size_id === _item);
                                  return {
                                    value: size?.size?.id,
                                    label: size?.size?.name,
                                  }
                                }) }
                                value={ product.size_id }
                                className="material"
                                labelClass="material"
                                placeholder="Todos"
                              />
                            </div>
                          )
                        }
                      </div>
                    )
                  }
                </div>

                <div className="product-trash">
                  <img
                    src={TrashIcon}
                    title="Eliminar de la lista"
                    alt="Eliminar"
                    onClick={() => this.delete(index)}
                  />
                </div>
              </div>
            )
          })}

          <div className="total-price">
            <div>TOTAL:</div>
            <div>$ { this.getTotal() }</div>
          </div>

          <div className="row">
            <DatePicker
              color="white"
              label="Fecha de Expiración"
              // minDate={ moment().add(1,'day').format('DD-MM-YYYY') }
              className="material"
              value={this.state.form.expiration_date}
              onChange={value => this.handleChange({ target: {value, name: 'expiration_date'}})}
            />

            <Select
              color="gray"
              name="client_id"
              label="Cliente"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              disabledFirst={false}
              onChange={this.handleChange}
              value={this.state.form.client_id}
              options={data.clients.map(i => ({ value: i.id, label: i.name }))}
            />
          </div>

          <Input
            color=" "
            value={this.state.form.commission}
            name="description"
            label="Agregar información adicional (Opcional)"
            className="material"
            labelClass="material"
            onChange={this.handleChange}
          />

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button
                color=" "
                type="button"
                className="primary"
                disabled={disabled_button}
                onClick={() => this.submit()}
              >
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const SelectProduct = ({ onSelect, onClose }) => {
  const user = useSelector(state => state.user);
  const adminId = user.level_id === Constants.ROLE_ADMIN ? user.id : user.admin_id;

  const { products, search, fetchMore, isLoading, pagination } = useFetch(adminId);
  const [selected, setSelected] = useState([]);

  const select = (item) => {
    if (selected.includes(item.id)) {
      const others = selected.filter(x => x !== item.id);
      setSelected(others);
    } else {
      setSelected([ ...selected, item.id ]);
    }
  }

  const submit = () => {
    onSelect(products.filter(x => selected.includes(x.id)));
    onClose();
  }

  return (
    <div id="container-create-edit-quote-select-product">
      <Input
        color=" "
        placeholder="Buscar por nombre"
        className="material"
        labelClass="material"
        onChange={e => search(e.target.value)}
      />

      {!!selected.length && (
        <div className="product-totals">
          <span>Seleccionados: { selected.length }</span>
          <button
            type="button"
            className="done"
            title="Agregar a la cotización"
            onClick={() => submit()}
          >
            <Icon name="check" />
          </button>
        </div>
      )}

      {products.map((item, index) => {
        const isSelected = selected.includes(item.id);
        return (
          <div
            key={`product-item-${index}`}
            className={`product-item ${isSelected ? 'selected':''}`}
            onClick={() => select(item)}
          >
            <div className="product-image">
              <img src={ Globals.fromPhotos(item?.photo?.file) } alt="Producto" />
            </div>
            <div className="product-details">
              <span className="product-name" title={item?.name}>{ item?.name }</span>
            </div>
            <div>${ item?.sale_price }</div>
          </div>
        )
      })}

      <div className="d-flex justify-content-center mt-4">
        {isLoading && <span>Cargando...</span>}
        {(!isLoading && pagination.page < pagination.last_page) && (
          <Button onClick={() => fetchMore()}>
            <span>Cargar más</span>
          </Button>
        )}
      </div>
    </div>
  );
}

const useFetch = (admin_id) => {
  const initialForm = {
    web: true,
    admin_id,
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res = await axios.post('web/admin/quotes/products', pagination);

      if (!res?.data?.products)
        return Globals.showError('Ocurrió un error inesperado');

      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.products?.total,
        last_page: res.data?.products.last_page,
      });

      const newData = pagination.page > 1
        ? [ ...data, ...res.data?.products?.data ]
        : res.data?.products?.data;

      setData(newData);

    } catch (error) {
      Globals.showError('Ocurrió un error inesperado');
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const fetchMore = (page = pagination.page + 1) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    products: data,
    pagination,
    fetchMore,
    reload,
    isLoading: canFetch,
    search,
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditQuote);
