import React from "react";
import moment from "moment";

import {
  Button,
  Input,
  Textarea,
  ModalScan,
  ViewSerial,
  Select,
} from "../../components";
import { Globals, Constants, Format, axios, Payments, ReduceMap, } from "../../utils";
import { Delivery, Warehouse, ReturnsDeliveryOrBill } from "../../services";
import { connect } from 'react-redux';

// Components
import FormClient from "../delivery-notes/formClient";

const IVA = 0.16;

const type = [
  { value: 1, label: "Nota de entrega" },
  { value: 2, label: "Factura de Venta" }
];

class CreateEditReturns extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      create: props.Item ? false : true,
      search: "",
      search_client: "",
      showCustomer: false,
      form: {
        id: "",
        forthright: false,
        warehouse_id: "",
        branch_id: "",
        client_id: "",
        type: "",
        control_code: "",
        order_code: "",
        currency_id: "",
        observations: "",
        subtotal: 0.0,
        taxable: 0.0,
        exempt: 0.0,
        vat: 0.0,
        total: 0.0,
        creator_id: props.user.id,
        enterprise_id: props.Id,
        products: [],
        type_payments: [],
      },
      errors: [],
      products: [],
      suggestions: [],
      suggestions_code: [],
      warehouses: [],
      textButton: "Crear",
      edit_serial: false,
      item_serial: null,
      showSavedText: false,
      order_selected: '',
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      visiblePayment: false,
      showOrder: false,
    };
  }

  async componentDidMount() {
    const { Id, user } = this.props;

    if (!this.props.Item && this.props.delivery) {
      // console.log('!this.props.Item && this.props.delivery');
      await this.setState({
        form: {
          ...this.props.delivery
        }
      });
    }

    this.setState(state => ({
      form: {
        ...state.form,
        enterprise_id: Id,
        creator_id: user.id
      }
    }));

    if (this.props.Item && !this.state.create) {
      const { Item } = this.props;

      this.maybeLoadData(Item, user);
      this.maybeLoadGenerateId();
    } else {
      this.maybeLoadId(Id);
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }

    if (
      prevState.form.forthright !== this.state.form.forthright &&
      this.state.form.forthright
    ) {
      this.maybeLoadGenerateId();
    } else if (
      prevState.form.forthright !== this.state.form.forthright &&
      !this.state.form.forthright
    ) {
      this.maybeLoadId();
    }
  }

  componentWillUnmount() {
    this.props.dispatch({
      type: 'SET_DELIVERY',
      payload: {
        ...this.state.form
      }
    });
  }

  saveDelivery() {
    this.setState({
      showSavedText: true
    }, () => {
      setTimeout(() => {
        this.setState({
          showSavedText: false
        })
      }, 5000)
    });
    this.props.dispatch({
      type: 'SET_DELIVERY',
      payload: {
        ...this.state.form
      }
    });
  }

  maybeLoadGenerateId = () => {
    document.getElementById("search_code").style.display = "none";
    const { Id } = this.props;

    Delivery.generateId({ Id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));
        // console.log(this.state.form.code+ "CODE");

        document.getElementById("search_code").style.display = "true";
      })
      .catch(() => Globals.showError());
  };

  maybeLoadData = (Item, user) => {
    const { details } = Item;

    const rawProducts = arrayTransform(details, Item.warehouse_id, user);

    this.setState(state => ({
      form: {
        ...state.form,
        id: Item.id,
        warehouse_id: Item.warehouse_id,
        branch_id: "",
        client_id: Item.client_id,
        type: "",
        code: "",
        currency_id: Item.currency_id,
        observations: Item.observations,
        taxable: Item.taxable,
        exempt: Item.exempt,
        subtotal: Item.subtotal,
        vat: Item.vat,
        total: Item.total,
        products: rawProducts
      },
      textButton: "Procesar"
    }));
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: userId,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: this.props.delivery ? this.props.delivery.warehouse_id : ""
        },
        warehouses: warehousesMap
      }), () => {
        this.props.dispatch({
          type: 'SET_DELIVERY',
          payload: null
        });
      });
    });
  };

  AutoComplete = ({ search }) => {
    const { products } = this.props;
    const { form } = this.state;

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    let searchLowerCase = search.toLowerCase();

    const filterProducts = products.filter(Item => {
      let nameLowerCase = Item.name.toLowerCase();

      return (
        nameLowerCase.indexOf(searchLowerCase) !== -1 &&
        parseInt(form.warehouse_id) === Item.warehouse_id &&
        Item.last_movement > 0 &&
        form.products.every(query => {
          return parseInt(query.id) !== parseInt(Item.id);
        })
      );
    });

    this.setState({ suggestions: filterProducts });
  };

  AutoCompleteOrders = ({ search_code }) => {
    const { orders } = this.props;
    const { form } = this.state;

    let isnum = /^[0-9]+$/;

    if (!search_code) {
      this.setState({ suggestions_order: [] });
      return;
    }

    // console.log('search_code '+search_code);
    // console.log(orders);
    // console.log(form);
    // console.log(search_code);
    // console.log(this.state.search_client);

    let searchLowerCase = search_code.toLowerCase();
    const filterOrders = orders.filter(Item => {
      if (Item.code != '') {
        let codeLowerCase = Item.code.toLowerCase();
        return (
          codeLowerCase.indexOf(searchLowerCase) !== -1
        );
      }
    });


    this.setState({ suggestions_code: filterOrders });

  };

  handleClick = Product => {
    const { form } = this.state;

    Globals.confirm(
      `¿Desea remover del pedido el producto: ${Product.name}?`,
      () => {
        const filterMap = form.products.filter(Item => Item.id !== Product.id);

        this.setState(state => ({
          form: {
            ...state.form,
            products: filterMap,
            subtotal: FloatNum(reduceMap(filterMap, "subtotal")),
            taxable: FloatNum(taxableMap(filterMap)),
            exempt: FloatNum(exemptMap(filterMap)),
            vat: FloatNum(reduceMap(filterMap, "vat")),
            total: FloatNum(reduceMap(filterMap, "total"))
          }
        }));
      }
    );
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    // console.log(name);
    // console.log(this.state.search_client);

    switch (name) {
      case "search_code":
        this.setState(state => ({
          form: {
            ...state.form,
            order_code: ""
          },
          order_code_selected: "",
        }));
        this.setState({ [name]: value }, () => {
          this.AutoCompleteOrders(this.state);
        });
        break;
      default:
        this.setState(state => ({
          form: {
            ...state.form,
            type_payments: [],
            [name]: value
          }
        }));
        break;
    }
  };

  handleDiscount = (value, name) => {
    const { form } = this.state
    const { user } = this.props;
    const rawProducts = [...form.products];
    const discountRate = Globals.getRate(user);

    if (name === "discount_percentage" && !!discountRate) {
      if (parseInt(value) > discountRate.percentage) {
        Globals.showWarning(
          `¡El porcentaje de descuento no puede exceder el ${discountRate.percentage}%!`
        );
        return;
      }
    }

    const parseMap = form.type_payments.map((item) => {
      return {
        ...item,
        amount: "",
      }
    })

    const mapProducts = rawProducts.map((product, key) => {
      return {
        ...product,
        discount_percentage: value,
        vat: FloatNum(rawProducts[key].exempt ? 0 : rawProducts[key].subtotal * IVA),
        total: FloatNum(
          rawProducts[key].exempt
            ? rawProducts[key].subtotal
            : rawProducts[key].subtotal * IVA + rawProducts[key].subtotal
        )
      }
    })

    const discount = this.calculateDiscount(mapProducts);

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: parseMap,
        discount_percentage: value,
        subtotal: FloatNum(ReduceMap.reduceMap(mapProducts, "subtotal")),
        discount: FloatNum(discount),
        taxable: FloatNum(ReduceMap.taxableMap(mapProducts)),
        exempt: FloatNum(ReduceMap.exemptMap(mapProducts)),
        vat: FloatNum(ReduceMap.reduceMap(mapProducts, "vat")),
        total: FloatNum(ReduceMap.reduceMap(mapProducts, "total") - FloatNum(discount))
      }
    }));
  }

  changeType = (event) => {
    const target = event.target
    const value = target.value

    console.log(value)
    this.setState(state => ({
      form: {
        ...state.form,
        type: value
      }
    }));
  }

  isCash = () => {
    return (
      this.state.form.type_payments.map(i => parseInt(i.id)).indexOf(1) != -1
    );
  };

  handleNum = (emitter, key) => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    let newValue = value ? parseInt(value) : value;
    let rawProducts = form.products;
    let quantityValue;

    switch (name) {
      case "quantity":
        quantityValue =
          newValue > 0 && newValue !== ""
            ? newValue * rawProducts[key].price
            : 0;

        if (
          rawProducts[key].stock_min >=
          rawProducts[key].last_movement - newValue
        ) {
          Globals.showWarning(
            `¡El producto ${rawProducts[key].name} estara por debajo del stock mínimo!`
          );
        }

        rawProducts[key] = {
          ...rawProducts[key],
          quantity: newValue,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue
              : quantityValue * IVA + quantityValue
          ),
          stock: rawProducts[key].last_movement - newValue
        };
        break;
      case "rate":
        let rate =
          parseInt(value) === 0
            ? rawProducts[key].price_base
            : parseInt(value) === 1
              ? rawProducts[key].price_max
              : rawProducts[key].price_offer;

        quantityValue = rawProducts[key].quantity * rate;

        rawProducts[key] = {
          ...rawProducts[key],
          [name]: parseInt(value),
          price: rate,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue
              : quantityValue * IVA + quantityValue
          )
        };
        break;
      default:
        break;
    }

    this.setState(state => ({
      form: {
        ...state.form,
        products: rawProducts
      }
    }));

    this.setState(state => ({
      form: {
        ...state.form,
        subtotal: FloatNum(reduceMap(rawProducts, "subtotal")),
        taxable: FloatNum(taxableMap(rawProducts)),
        exempt: FloatNum(exemptMap(rawProducts)),
        vat: FloatNum(reduceMap(rawProducts, "vat")),
        total: FloatNum(reduceMap(rawProducts, "total"))
      }
    }));
  };

  takeOrder = Item => {
    const { user } = this.props;
    let order_selected = Item.code;

    let request = {
      'Id': Globals.getUserId(user),
      'user_id': user.id,
      'role': user.role,
      'code': Item.code,
      'warehouse_id': Item.warehouse_id
    }

    ReturnsDeliveryOrBill.getOrderByCode(request)
      .then(async response => {
        this.setState(state => ({
          search_client: "",
          form: {
            ...state.form,
            order_code: Item.code
          },
          suggestions_code: [],
          order_selected: order_selected,
        }));
        this.state.form.order_code = Item.code;
        this.setState({ OrderItem: response[0], showOrder: true });
      })
      .catch(() => Globals.showError());

    //this.saveDelivery();
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { submitted, create, OrderItem } = this.state;

    if (submitted) {
      return;
    }

    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });

    //const { Item } = this.props;
    const { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    let request = {
      'Id': enterpriseId,
      'user_id': user.id,
      'type': this.state.form.type,
      'role': user.role,
      'code': OrderItem.code,
      'date_emission': OrderItem.date_emission,
      'warehouse_id': OrderItem.warehouse_id,
      'observations': this.state.form.observations,
    }

    ReturnsDeliveryOrBill.createReturnFrom(request)
      .then(async response => {
        if(response.error){
          Globals.showError(response.error);
          this.props.onClose();
          return;
        }
        const { control_number } = response;
        Globals.showSuccess(`¡Devolución Nº ${control_number} creada exitosamente!`);
        await this.setState({
          observations: "",
        });
        this.props.onClose();
      })
      .catch(() => Globals.showError());

  };

  maybeLoadId = () => {
    const { Id } = this.props;

    ReturnsDeliveryOrBill.generateId({ Id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));
        // setTimeout(
        //   function () {
        //     this.maybeLoadId();
        //   }
        //     .bind(this),
        //   3000
        // );
      })
      .catch(() => Globals.showError());

    // if(this.state.form.code){
    // this.reloadMaybeLoadId();
    // }
  };

  reloadMaybeLoadId = () => {
    this.maybeLoadId();
  };

  removeAttribute = () => {
    this.setState({ showCustomer: false }, () => this.props.removeAttribute());
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };

  showSerials = item => {
    const { form } = this.state;
    let creator_id = this.props.user.id;
    let product_id = item.id;
    let request = {
      creator_id: creator_id,
      warehouse_id: form.warehouse_id,
      product_id: product_id,
    };

    //request.push({ creator_id: creator_id }, { warehouse_id: form.warehouse_id }, { product_id: product_id });

    axios
      .post("admin/deliver-notes/getserialsavailable", request)
      .then(res => {
        if (res.data.serialsAvailable.length > 0) {
          if (res.data.serialsCompromised > 0) {
            if (res.data.serialsCompromised == 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidad comprometida en un PEDIDO.'
              });
            }
            else if (res.data.serialsCompromised > 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidades comprometidas en un PEDIDO.'
              });
            }
          }
          this.setState({
            show_serials: true,
            item_serial: item,
            serials_available: res.data.serialsAvailable,
          });
        }
        else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      });


  }

  onCloseShowSerials = () => {
    this.setState({
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    });
  }

  onSuccess = async item => {
    let products = [...this.state.form.products];
    const index = products.findIndex(i => i.id == this.state.item_serial.id);
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      form: {
        ...this.state.form,
        products: products
      }
    });
    this.handleNum(
      {
        target: {
          value: item.length,
          name: "quantity"
        }
      },
      index
    );
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  closePayment = () => {
    this.setState({
      visiblePayment: false,
    });
  };

  render() {
    const { Id } = this.props;
    const {
      form,
      submitted,
      showCustomer,
      edit_serial,
      item_serial,
      suggestions_code,
      order_selected,
      OrderItem,
      showOrder
    } = this.state;
    const { view_serials, item_serials, serials } = this.state;
    const { user } = this.props;
    const _Item = OrderItem;

    return (
      <React.Fragment>
        {view_serials && (
          <ViewSerial
            onBack={() => {
                this.setState({
                    item_serials: null,
                    view_serials: false,
                    serials: null
                });
            }}
            product={item_serials}
            serials={serials}
          />
        )}
        {edit_serial ? (
          <React.Fragment>
            {/* <EditSerial
            onSuccess={ this.onSuccess }
            onCancel={ this.onCancel }
            product={ item_serial } /> */}

            <ModalScan
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
              serials={item_serial.serials}
            />
          </React.Fragment>
        ) : (
          <div id="createreturn">
            {showCustomer ? (
              <FormClient
                autocomplete="off"
                autocomplete="off"
                autocomplete="off"
                Id={Id}
                customer={Customer =>
                  this.setState({ showCustomer: false }, () => {
                    this.props.newCustomer(Customer);
                  })
                }
                removeAttribute={() => this.removeAttribute}
              />
            ) : (
              <div className="text-center container-create-edit-request">
                <div className="row">
                  <div className="col-md">
                    <Input
                      color="gray"
                      type="number"
                      name="search_code"
                      id="search_code"
                      style={{ display: this.state.form.code ? 'block' : 'none' }}
                      autocomplete="false"
                      placeholder="Buscar por código"
                      label="Buscar nota de entrega/factura"
                      value={
                        this.state.form.client_id !== '' && this.state.search_client === ''
                          ? this.state.form.client_id
                          : this.state.search_code
                      }
                      // value={
                      //   order_selected !== '' && this.state.search_code === ''
                      //     ?
                      //     order_selected
                      //     :
                      //     this.state.form.order_code !== '' ? this.state.form.order_code
                      //       : this.state.search_code
                      // }
                      onChange={this.handleChange}
                      error={`${this.hasErrorFor("client_id") ? "is-invalid" : ""
                        }`}
                      invalidfeedback={this.renderErrorFor("client_id")}
                    />
                    <div id="suggestion">
                      {suggestions_code.length > 0
                        ? suggestions_code.map((Item, key) => {
                          return (
                            <div
                              key={key}
                              onClick={() => {
                                this.takeOrder(Item);
                              }}
                            >
                              {Item.code}
                              {Item.type === 1 && (
                                <span> - NE</span>
                              ) || (
                                  <span> - Fact</span>
                                )}
                            </div>
                          );
                        })
                        : null}
                    </div>

                  </div>
                  <div className="col-md">
                    <Input
                      type="text"
                      color="gray"
                      value={this.state.form.code}
                      placeholder="Código alfanumérico"
                      name="code"
                      maxLength={10}
                      error={`${this.hasErrorFor("code") ? "is-invalid" : ""}`}
                      invalidfeedback={this.renderErrorFor("code")}
                      disabled
                      label="Número de Devolución"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="row">

                </div>

                <div className="row">

                </div>
              </div>
            )}

            {showOrder && (
              <React.Fragment>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <div className="table-responsive">
                        <table className="table table-bordered">
                          <thead>
                            <tr style={{ whiteSpace: "nowrap" }}>
                              <th>Nro.</th>
                              <td>{OrderItem.code ? OrderItem.code : ''}</td>
                              <th>Fecha:</th>
                              <td>{OrderItem.date_emission ? parseFormat(OrderItem.date_emission) : ''}</td>
                            </tr>
                            {(OrderItem || {}).request_order && (
                              <tr style={{ whiteSpace: "nowrap" }}>
                                <th>Nro. de Pedido:</th>
                                <td>{OrderItem.request_order.code ? OrderItem.request_order.code : ''}</td>
                                <th colSpan="2"></th>
                              </tr>
                            )}
                            <tr>
                              <th>Cliente:</th>
                              <td>
                                {OrderItem.client.person ? (OrderItem.client.person || {}).first_name ||
                                  (OrderItem.client || {}).name : ''}
                              </td>
                              <th>Hora:</th>
                              <td>{OrderItem.date_emission ? timeFormat(OrderItem.date_emission) : ''}</td>
                            </tr>
                            <tr>
                              <th>Rif Cliente:</th>
                              <td>
                                {(OrderItem.client.person || {}).fiscal_identification ||
                                  (OrderItem.client.person || {}).identity_document}
                              </td>
                              <th>Tipo:</th>
                              <td>
                                {OrderItem.type == 1 && (
                                  "Nota de Entrega"
                                ) || (
                                    "Factura de Venta"
                                  )}
                              </td>
                            </tr>
                            <tr>
                              <th>Vendedor:</th>
                              <td>
                                {(OrderItem.seller.person || {}).first_name ||
                                  (OrderItem.seller || {}).name}
                              </td>
                            </tr>
                          </thead>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md">
                    <div className="form-group">
                      <div className="table-responsive">
                        <table className="table table-bordered view-delivery">
                          <thead>
                            <tr>
                              <th colSpan="6" className="text-center">
                                {OrderItem.type == 1 ? 'PRODUCTOS DE LA NOTA DE ENTREGA' : 'PRODUCTOS DE LA FACTURA'}
                              </th>
                            </tr>
                            <tr style={{ whiteSpace: "nowrap" }}>
                              <th>Código</th>
                              <th>Nombre</th>
                              <th>Descripción</th>
                              <th>Precio</th>
                              <th>Cantidad</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            {_Item.details.map((Item, key) => {
                              const serials = _Item.operation.filter(
                                i => i.product_warehouse.product_id == Item.id
                              );

                              return (
                                <tr key={key}>
                                  <td>
                                    <p>{Item.code}</p>
                                    {serials.length > 0 && (
                                      <p
                                        className="view-serials"
                                        onClick={() => {
                                          this.setState({
                                            view_serials: true,
                                            item_serials: Item,
                                            serials: serials
                                          });
                                        }}
                                      >
                                        Ver Seriales
                                      </p>
                                    )}
                                  </td>
                                  <td>{Item.name}</td>
                                  <td>{Item.description ? Item.description : 'Sin descripción'}</td>
                                  <td>{Globals.formatMiles(Amount(Item))}</td>
                                  <td>{Item.pivot.quantity}</td>
                                  <td>
                                    {Globals.formatMiles(Item.pivot.subtotal)}
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan="3"></td>
                              <th>Subtotal/Cantidad</th>
                              <td>{getQuantity(OrderItem)}</td>
                              <td>{Globals.formatMiles(getAmount(OrderItem))}</td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <th>Descuento</th>
                              <td>
                                {Globals.formatMiles(OrderItem.discount_percentage)}
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <th>Exento</th>
                              <td>{Globals.formatMiles(OrderItem.exempt)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <th>Base Imponible</th>
                              <td>{Globals.formatMiles(OrderItem.taxable)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <th>IVA</th>
                              <td>{Globals.formatMiles(OrderItem.vat)}</td>
                            </tr>
                            <tr>
                              <td colSpan="4"></td>
                              <th>Total</th>
                              <td>{Globals.formatMiles(OrderItem.total)}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row text-center">
                  <div className="col-md">
                    <div className="form-group">
                      <Textarea
                        color="gray"
                        value={this.state.form.observations}
                        name="observations"
                        label="Observaciones"
                        onChange={this.handleChange}
                        error={`${this.hasErrorFor("observations") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("observations")}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    {submitted ? (
                      <div
                        className="spinner-border text-primary"
                        role="status"
                      >
                        <span className="sr-only">Loading...</span>
                      </div>
                    ) : (
                      <div id="button">
                        <Button
                          block="true"
                          type="button"
                          disabled={submitted || form.observations == ''}
                          onClick={emitter => {
                            let msg = '';
                            OrderItem.type == 1 ? msg = 'nota de entrega' : msg = 'factura';
                            Globals.confirm(
                              `¿Seguro de devolver la ${msg} número: ${OrderItem.code}?`,
                              () => {
                                this.handleSubmit(emitter);
                              }
                            );
                          }}
                        >
                          {this.state.textButton}
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </React.Fragment>
            )}

          </div>
        )}
      </React.Fragment>
    );
  }
}

function arrayTransform(Items, warehouse_id, user) {
  return Items.map((Item, key) => {
    let query = Item.product_warehouse.find(
      Item => Item.warehouse_id === warehouse_id
    );

    let prices = []
    if (user.role === Constants.ROLE_SELLER) {
      prices = [
        {
          value: 0,
          label: "Precio Min."
        }
      ]
      if (user.configuration_seller.price_max === 1)
        prices.push({ value: 1, label: "Precio Max." })
      if (user.configuration_seller.price_offer === 1)
        prices.push({ value: 2, label: "Precio Oferta" })

    } else {
      prices = [
        {
          value: 0,
          label: "Precio Min."
        },
        {
          value: 1,
          label: "Precio Max."
        },
        {
          value: 2,
          label: "Precio Oferta"
        }
      ]
    }

    return {
      id: Item.id,
      name: Item.name,
      code: Item.code,
      warehouse_id: query.warehouse_id,
      prices: prices,
      stock_min: query.stock_min,
      quantity: Item.pivot.quantity,
      rate: 1,
      price: Item.pivot.subtotal / Item.pivot.quantity,
      exempt: Item.exempt,
      subtotal: Item.pivot.subtotal,
      vat: Item.pivot.vat,
      total: Item.pivot.total,
      stock: 0
    };
  });
}

function taxableMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_ACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function exemptMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_INACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function reduceMap(Items, name) {
  return Items.reduce((value, product) => {
    return value + parseFloat(product[name]);
  }, 0);
}

function FloatNum(number) {
  return number.toFixed(2);
}

function getQuantity({ details: Products }) {
  return Products.reduce(function (Quantity, Item) {
    return parseInt(Item.pivot.quantity + Quantity);
  }, 0);
}

function getAmount({ details: Products }) {
  return Products.reduce(function (Amount, Item) {
    return parseFloat(Item.pivot.subtotal + Amount);
  }, 0);
}

function Amount({ pivot: Amount }) {
  return parseFloat(Amount.subtotal / Amount.quantity);
}

function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

function timeFormat(Time) {
  return moment(Time).format("HH:mm:ss");
}

CreateEditReturns.defaultState = {
  form: {
    warehouse_id: "",
    branch_id: "",
    customer_id: "",
    code: "",
    type: "",
    currency_id: "",
    date_emission: new Date(),
    observations: "",
    subtotal: 0,
    vat: 0,
    total: 0,
    creator_id: "",
    enterprise_id: "",
    products: [],
    type_payments: [],
  }
};

export default connect(state => {
  return {
    return: state.return
  }
})(CreateEditReturns);