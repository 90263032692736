import React from "react";
import { Button, Select, Input } from "../../components";
import File from "../../components/file";
import { axios, Globals } from "../../utils";

class CreateEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      textButton: "Crear"
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    if (this.props.edit) this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort()		
  }

  submit = async () => {
    let param = { ...this.state.form };
    if (!this.state.form.document_type) {
      param = {
        ...this.state.form,
        document_type: "V",
        document: "V-" + param.document.replace(/\./g, "")
      };
    } else if (!param.document_type.indexOf("-") || param.document_type) {
      param = {
        ...param,
        document: param.document_type + "-" + param.document.replace(/\./g, "")
      };
    }
    Globals.setLoading();
    axios
      .upload(
        this.props.edit ? "admin/seller/edit" : "admin/seller/create",
        param
      )
      .then(res => {
        if (res.data.result) {
          this.setState({
            form: {}
          });
          Globals.showSuccess(res.data.msg);
          this.props.onClose();
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    let documents = {};
    if (
      !this.props.edit.element.person.document.indexOf("-") ||
      parseInt(this.props.edit.element.person.document)
    ) {
      documents = {
        document_type: "V",
        document: this.props.edit.element.person.document
      };
    } else {
      let division = this.props.edit.element.person.document.split("-");
      documents = {
        document_type: division[0],
        document: division[1]
      };
    }
    await this.setState({
      form: {
        name: this.props.edit.element.person.name,
        lastname: this.props.edit.element.person.lastname,
        email: this.props.edit.element.email,
        phone: this.props.edit.element.person.phone,
        document: documents.document,
        document_type: documents.document_type,
        id: this.props.edit.element.id
      },
      textButton: "Editar"
    });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  render() {
    return (
      <div className="text-center container-create-edit-user">
        <Input
          color="gray"
          value={this.state.form.name}
          name="name"
          label="Nombre"
          onChange={this.change}
        />
        <Input
          color="gray"
          value={this.state.form.lastname}
          name="lastname"
          label="Apellido"
          onChange={this.change}
        />
        <Input
          color="gray"
          value={this.state.form.email}
          name="email"
          type="email"
          label="Email"
          onChange={this.change}
        />
        <div className="row">
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.phone}
              name="phone"
              type="number"
              label="Teléfono"
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <div className="container-cedula">
              <Select
                color="gray"
                label="Tipo"
                name="document_type"
                defaultname="document_type"
                onChange={this.change}
                value={
                  this.state.form.document_type
                    ? this.state.form.document_type
                    : "V"
                }
                options={[
                  {
                    label: "V",
                    value: "V"
                  },
                  {
                    label: "J",
                    value: "J"
                  },
                  {
                    label: "E",
                    value: "E"
                  }
                ]}
              />
              <Input
                color="gray"
                value={this.state.form.document}
                name="document"
                type="number"
                label="Cedula"
                onChange={this.change}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.password}
              name="password"
              type="password"
              autoComplete="off"
              label="Contraseña"
              onChange={this.change}
            />
          </div>
          <div className="col-md-6">
            <Input
              color="gray"
              value={this.state.form.password_confirmation}
              name="password_confirmation"
              type="password"
              autoComplete="off"
              label="Confirmar Contraseña"
              onChange={this.change}
            />
          </div>
        </div>
        <File
          placeholder={
            this.props.edit
              ? "Cambiar imagen de perfil (Opcional) "
              : "Agregar Imagen de perfil"
          }
          placeholdersuccess="Imagen de perfil Agregada"
          showcheck={true.toString()}
          onChange={this.change}
          name="image"
          value={this.state.form.image}
          inputstyle={{
            display: "contents"
          }}
          className="btn-product"
        />
        <div id="button">
          <Button block="true" type="button" onClick={() => this.submit()}>
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}
export default CreateEditUser;
