import React from "react";
import moment from "moment";

import { Textarea } from "../../../components";
import { Globals, Constants } from "../../../utils";

class View extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { Item } = this.props;
    console.log(Item)
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-md">
            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr style={{ whiteSpace: "nowrap" }}>
                        <React.Fragment>
                          <th>Nro. de Factura:</th>
                          <td>{Item.bill_number}</td>
                        </React.Fragment>
                        <React.Fragment>
                          <th>Nro. de Control:</th>
                          <td>{Item.control_number}</td>
                        </React.Fragment>
                        <React.Fragment>
                          <th>Fecha de Emisión:</th>
                          <td>{parseFormat(Item.date_emission || Item.date)}</td>
                        </React.Fragment>
                        <React.Fragment>
                          <th>Fecha de Recepción:</th>
                          <td>{parseFormat(Item.date_received || Item.date)}</td>
                        </React.Fragment>
                    </tr>
                    <tr>
                      <th>Proveedor:</th>
                      <td>{Item.provider.name}</td>
                      <React.Fragment>
                        <th>Fecha de Expiración:</th>
                        <td>{parseFormat(Item.date_expired || Item.date)}</td>
                      </React.Fragment>
                      <th>Tipo de Pago:</th>
                      <td>
                        {parseInt(Item.payment_type) === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH
                          ? "Contado"
                          : "Credito"}
                      </td>
                      <th>Estatus:</th>
                      <td>
                        {parseInt(Item.status) ? "PAGADA" : "SIN PAGAR"}
                      </td>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="row view-order">
          <div className="col-md">
            <div className="form-group">
              <div className="table-responsive">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <td colSpan="4" className="text-center">
                        PRODUCTOS DE LA ORDEN / FACTURA
                      </td>
                    </tr>
                    <tr style={{ whiteSpace: "nowrap" }}>
                      <th>Nombre/Descripción</th>
                      <th>Precio</th>
                      <th>Cantidad</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Item.details.map((Product, key) => {  

                      return (
                        <tr key={key}>
                          <td>{Product.description}</td>
                          <td>
                            {Globals.formatMiles(Product.price)}
                          </td>
                          <td>
                            {Product.amount}
                          </td>
                          <td>
                            {Globals.formatMiles(Product.subtotal)}
                          </td>
                        </tr>
                      );
                    })}
                    <tr>
                      <td colSpan="2"></td>
                      <th colSpan="1">Subtotal</th>
                      <td>
                        {Globals.formatMiles(Item.subtotal)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2"></td>
                      <th colSpan="1">IVA</th>
                      <td>
                        {Globals.formatMiles(Item.iva)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan="2"></td>
                      <th colSpan="1">Total</th>
                      <td>
                        {Globals.formatMiles(Item.total)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        { Item.expenses_ingress.length > 0 && (
          <div className="row view-order">
            <div className="col-md">
              <div className="form-group">
                <div className="table-responsive">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <td colSpan="3" className="text-center">
                          ABONOS
                        </td>
                      </tr>
                      <tr style={{ whiteSpace: "nowrap" }}>
                        <th>Fecha</th>
                        <th>Método de pago</th>
                        <th>Monto</th>
                      </tr>
                    </thead>
                    <tbody>
                      {Item.expenses_ingress.map((item, key) => {  
                        return (
                          <tr key={key}>
                            <td>{parseFormat(item.date)}</td>
                            <td>
                              {item.payment_type == 1 && ("Efectivo")}
                              {item.payment_type == 2 && ("Crédito")}
                              {item.payment_type == 3 && ("Débito")}
                              {item.payment_type == 4 && ("Transferencia")}
                            </td>
                            <td>{item.amount},00</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          ) || 
          ("Sin Abonos")
        }
        <div className="row text-center">
          <div className="col-md">
            <div className="form-group">
              <Textarea
                color="gray"
                value={Item.description}
                name="description"
                label="OBSERVACIONES"
                readOnly
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

export default View;
