import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  CheckBox,
  Icon,
  DatePicker,
  Modal,
  Input,
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand } from "../../services";
import PrinterIcon from '../../assets/icons/printer.png';
import AddIcon from '../../assets/icons/add.png';
import ThumbUpIcon from '../../assets/icons/thumb-up.png';
import ThumbDownIcon from '../../assets/icons/thumb-down.png';
import AcceptOrder from "./accept-order";
import ViewPayments from "./view-payments";
import EditOrder from "./edit-order";
import moment from "moment";

const ORDER_STATUS = {
  PENDING: 0,
  PROCESSED: 1,
  REJECTED: 2,
  CANCELLED: 5,
  DISPATCHED: 6,
  DELETED: 'deleted_at',
}

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      total: 0,
      header: [
        {
          value: "correlative_number",
          label: "ID",
          type: 1,
          section: "required",
          order: 0
        },
        {
          value: "created_at",
          label: "Fecha",
          type: 1,
          section: "required",
          order: 1
        },
        {
          value: "seller",
          label: "Vendedor",
          type: 1,
          section: "required",
          order: 2
        },
        {
          value: "client",
          label: "Cliente",
          type: 1,
          section: "required",
          order: 3
        },
        {
          value: "",
          label: "N. Crédito Aplicada",
          type: 1,
          section: "required",
          order: 4
        },
        // {
        //   value: "model",
        //   label: "Modelo",
        //   type: 1,
        //   section: "required",
        //   order: 4
        // },
        {
          value: "amount",
          label: "Monto",
          type: 1,
          section: "required",
          order: 5
        },
        // {
        //   value: "branch",
        //   label: "Sucursal",
        //   type: 1,
        //   section: "required",
        //   order: 6
        // },
        // {
        //   value: "warehouse",
        //   label: "Almacen",
        //   type: 1,
        //   section: "required",
        //   order: 7
        // },
        {
          value: "payment_method",
          label: "Método de pago",
          type: 1,
          section: "required",
          order: 8
        },
        {
          value: "status",
          label: "Estatus",
          type: 1,
          section: "required",
          order: 9
        },
        {
          value: "status_payment",
          label: "Estatus de pago",
          type: 1,
          section: "required",
          order: 9
        },
        {
          value: "",
          label: "Acciones",
          type: 1,
          section: "",
          order: 10
        },
      ],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
      page: 1,
      last_page: 1,
      data: [],
      branches: [],
      warehouses: [],
      order: null,
      editModal: false,
      viewPaymentsModal: false,
      acceptModal: false,
      providers: {},
      form: {
        status_payment: "",
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        order_id: "",
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        since: "",
        until: "",
        payment_method_id: "",
        client_id: "",
        seller_id: "",
        web: true,
        type_download: 'pdf',
        currency: Constants.CURRENCIES.DOLARES,
      },
      categories: [],
      subcategories: [],
      brands: [],
      models: [],
      payment_methods: [],
      clients: [],
      sellers: [],
      countPrice: 0,
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      showProductsFilters: true,
      dates: {
        minDate: "",
        maxDate: ""
      },
      status_filter: [
        {value: ORDER_STATUS.PENDING, label: 'Pendiente/En proceso'},
        {value: ORDER_STATUS.PROCESSED, label: 'Procesado'},
        {value: ORDER_STATUS.REJECTED, label: 'Rechazado'},
        {value: ORDER_STATUS.CANCELLED, label: 'Anulado'},
        {value: ORDER_STATUS.DELETED, label: 'Eliminado'},
        //{value: ORDER_STATUS.DISPATCHED, label: 'Despachado'},
      ],
      selectedItem: null,
      status_payment_filter: [
        {value: Constants.PAYMENT_TYPES.PAID, label: 'Pagado'},
        {value: Constants.PAYMENT_TYPES.TO_PAY, label: 'Por Pagar'},
      ],
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getThead();
    //this.getBranches();
    //this.getCategories();
    //this.getBrands();
  }
  // componentDidUpdate(_, prevState) {
  //   if (prevState.form.branch_id !== this.state.form.branch_id && this.state.form.branch_id) {
  //     this.getWarehouses();
  //     this.load();
  //   }
  // }
  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.getOrders();
    this.getFilters();
  };

  getFilters = () => {
    let { form } = this.state;

    form.user_id = this.props.user.id;

    axios
      .post("admin/reports/orders-filters", form)
      .then(res => {
        const paymentMethodsMap = res.data.payment_methods.map(({ id, name }) => ({
            value: id,
            label: name
          }));
        const clientsMap = res.data.clients.map(({ id, name }) => ({
            value: id,
            label: name
          }));
        const sellersMap = res.data.sellers.map(({ id, name }) => ({
            value: id,
            label: name
          }));
        this.setState({
          payment_methods: paymentMethodsMap,
          clients: clientsMap,
          sellers: sellersMap,
        });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getOrders = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = this.props.user.id;
    form.minimize = true;

    axios
      .post("admin/reports/orders?page=" + page, form)
      .then(res => {
        this.setState({
          last_page: res.data.orders.last_page,
          data: res.data.orders.data,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getWarehouses = id => {
    if (!id) {
      let warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }

    let { user } = this.props;

    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouses({
      role: user.role,
      user_id: enterpriseId,
      branch_id: id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = [];
        if (user.role === 4) {
          warehouses_filtered = response.warehouses.data.filter(el => {
            return user.assigned_warehouses.some(f => {
              return parseInt(f.warehouse_id) === parseInt(el.id);
            });
          });
        } else {
          warehouses_filtered = response.warehouses.data;
        }

        this.setState(state => ({
          warehouses: warehouses_filtered,
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  getCategories = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };

    const { form } = this.state;

    Globals.setLoading();
    axios
      .post("admin/categories/get", param)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            categories: res.data.categories,
            form: {
              ...form,
              subcategory_id: ""
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getSubcategories = categoryId => {
    let param = {
      category_id: categoryId,
      select: true
    };
    Category.getSubcategories(param).then(response => {
      if (response.result) {
        this.setState(state => ({
          ...state,
          subcategories: response.subcategories,
          form: {
            ...state.form,
            subcategory_id: ""
          }
        }));
      }
    });
  };

  getBrands = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };
    const { form } = this.state;
    Brand.getBrands(param).then(response => {
      if (response.result) {
        this.setState({
          brands: response.brands,
          form: {
            ...form,
            model_id: "",
            brand_id: ""
          }
        });
      }
    });
  };

  getModels = brandId => {
    let param = {
      brand_id: brandId,
      select: true,
      user_id: this.props.user.id
    };
    const { form } = this.state;
    Brand.getModels(param).then(response => {
      if (response.result) {
        this.setState({
          models: response.models,
          form: {
            ...form,
            model_id: ""
          }
        });
      }
    });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  openProductsDetails = e => {
    this.change(e);
    if (e.target.value === "")
      this.setState({
        showProductsFilters: false
      });
    else
      this.setState({
        showProductsFilters: true
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCheck = async e => {
    var { name, checked } = e.target;
    let { form, header, trows } = this.state;

    let sectionPrices = header
      .filter(Item => {
        return Item.section === "price";
      })
      .map(Item => {
        return Item.value;
      });

    let sectionStock = header
      .filter(Item => {
        return Item.section === "stock";
      })
      .map(Item => {
        return Item.value;
      });

    if (trows.length === 0) {
      trows = header.filter(Item => {
        return Item.type === 1;
      });
    } else {
      trows = trows.filter(Item => {
        return Item.type;
      });
    }

    let find = header.find(Item => Item.value === name);

    if (sectionPrices.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        trows.push(find);

        if (countSecPrice.length === 0) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = name;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        if (countSecPrice.length === 1) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = countSecPrice[0].value;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    if (sectionStock.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        trows.push(find);

        if (countSecStock.length === 0) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        if (countSecStock.length === 1) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    trows.sort((a, b) => parseInt(a.order) - parseInt(b.order));

    let thead = trows.map(Item => {
      return Item.label;
    });

    this.setState({
      form: {
        ...form,
        [name]: checked
      }
    });

    this.getThead(thead, trows);
    return;
  };

  getThead = async (arr = [], arr2 = []) => {
    const { header } = this.state;

    let newHeader = arr;

    if (newHeader.length === 0) {
      newHeader = header
        .filter(Item => {
          return Item.type === 1;
        })
        .map(Item => {
          return Item.label;
        });
    }

    await this.setState(state => {
      state.thead = newHeader;
      state.trows = arr2;
    });
  };

  getBranches = () => {
    let { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Branch.getBranchesByEnterprise({ Id: enterpriseId })
      .then(response => {
        let branches_filtered = response.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        this.setState({
          branches: branchesMap
        });
      })
      .catch(() => Globals.showError());
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        status_payment: "",
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        order_id: "",
        payment_method_id: "",
        since: "",
        until: "",
        client_id: "",
        seller_id: "",
        web: true,
        currency: Constants.CURRENCIES.DOLARES,
      },
      thead: [],
      trows: [],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
    }));
    await this.getThead();
    // await this.getBranches();
    // await this.getCategories();
    // await this.getBrands();
    await this.load();
  };

  print = (data) => {
    Globals.typeDownloadOptions(
      `Selecciona en que formato deseas que se exporte el reporte`,
      (type_download) => {
        this.setState(state => ({
          form: {
            ...state.form,
            type_download: type_download
          }
        }));
        axios
          .post("admin/reports/orders/download", this.state.form)
          .then(res => {
            if (res.data.url_storage) {
              //const url = res.data.url;
              const url = `${ENV.BasePublic}${res.data.url_storage}`;
              const win = window.open(url, "_blank");
              win.focus();
            }
            else {
              Globals.showError('Ha ocurrido un error');
            }
          })
          .catch(err => {
            Globals.showError();
          });
      }
    );
  };

  selectCurrency = (item) => {
    this.setState({ toPrint: { item, currency: this.props.currency } });
  }

  download = async (currency) => {
    try {
      const form = {
        currency,
        is_copy: true,
        order_id: this.state.toPrint.item.crypt,
        _user_id: this.props.user.id,
      };

      const res = await axios.post('admin/orders/download', form);
      if (!res.data?.url) throw new Error();

      window.open(res.data.url);
      this.setState({ toPrint: null });

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al generar el PDF. Intente nuevamente.');
    }
  }

  cancel = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de cancelación',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/cancel', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al cancelar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha cancelado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

  delete = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo para eliminar el pedido',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/delete', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al eliminar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha eliminado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

  accept = async (item) => {
    if (item.status_payment === Constants.PAYMENT_TYPES.TO_PAY) {
      return this.setState({ acceptModal: true, order: item });
    }

    Globals.setLoading();
    try {
      const form = {
        order_id: item.id,
        _user_id: this.props.user.id,
      };

      await axios.post('admin/orders/accept', form);
      Globals.showSuccess("Se ha aceptado el pedido correctamente");
      this.getOrders(1);

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al aceptar el pedido.');
    }
    Globals.quitLoading();
  }

  reject = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        try {
          if (!comments) {
            Globals.showError('Debe indicar el motivo');
            throw new Error('Debe indicar el motivo');
          }
    
          const form = {
            comments,
            order_id: item.id,
            user_id: this.props.user.id,
            _user_id: this.props.user.id,
          };
    
          return axios.post('admin/orders/reject', form)
            .then(response => {
              if (response.status !== 200) throw new Error(response.statusText);
              done = true;
              return true;
            })
            .catch(() => {
              Globals.showError('Ocurrió un error al rechazar el pedido');
              return false;
            });
        } catch (error) {
          Globals.showError(error.message);
          return false;
        }
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha rechazado el pedido correctamente');
        this.getOrders(1);
      }
    });
    
  }

	getColor = (status) => {
		switch(status) {
			case ORDER_STATUS.PENDING:
				return '#3A7FC2';

			case ORDER_STATUS.PROCESSED:
				return '#38CE6A';

			case ORDER_STATUS.REJECTED:
				return '#DD4B4B';

			case ORDER_STATUS.CANCELLED:
				return '#DD4B4B';

      case ORDER_STATUS.DISPATCHED:
        return 'black';

			default:
				return 'black';
		}
	}

  render() {
    const { trows } = this.state;
    const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');
    const minimalSize = { padding: 0, maxWidth: '12%', flex: '1 1 12%', marginRight: '0.5rem' };
    const user = this.props.user

    return (
      <Menu history={this.props.history}>
        {!!this.state.toPrint && (
          <Modal
            title="Seleccionar moneda"
            onClose={() => this.setState({ toPrint: null })}
            visible
          >
            <div id="currency" className="d-flex flex-row justify-content-around mb-3">
              <button
                onClick={() => this.download(Constants.CURRENCIES.DOLARES)}
                className="currency-item bg-blue"
              >
                <span className="currency-symbol text-white">$</span>
                <span className="currency-name text-white">Dólar</span>
              </button>

              <button
                onClick={() => this.download(Constants.CURRENCIES.BOLIVARES)}
                className="currency-item bg-blue"
              >
                <span className="currency-symbol text-white">{Globals.getLocalCurrencyName(user)}</span>
                <span className="currency-name text-white">{Globals.getLocalCurrencySymbol(user)}</span>
              </button>
            </div>
          </Modal>
        )}
        {this.state.acceptModal && (
          <Modal
            title="Aceptar pedido"
            onClose={() => this.setState({ acceptModal: false })}
            visible
          >
            <AcceptOrder
              order={ this.state.order }
              onClose={(reload = false) => {
                if (reload) this.getOrders(1);
                this.setState({ acceptModal: false });
              }}
            />
          </Modal>
        )}
        {this.state.editModal && (
          <Modal
            title="Editar pedido"
            onClose={() => this.setState({ editModal: false })}
            size="xl"
            visible
          >
            <EditOrder
              order={ this.state.order }
              clients={ this.state.clients }
              sellers={ this.state.sellers }
              methods={ this.state.payment_methods }
              onClose={(reload = false) => {
                this.setState({ editModal: false });
                if (reload) this.load();
              }}
            />
          </Modal>
        )}
        {this.state.viewPaymentsModal && (
          <Modal
            title="Pagos de la orden"
            onClose={() => this.setState({ viewPaymentsModal: false })}
            visible
          >
            <ViewPayments
              order={ this.state.order }
              methods={this.state.payment_methods}
              onClose={() => this.setState({ viewPaymentsModal: false })}
            />
          </Modal>
        )}
        <div id="products">
          <div className="row">
            <div className="col-3">
              <Input
                color=" "
                name="order_id"
                label="ID"
                className="filter"
                value={this.state.form.order_id}
                onChange={emitter => this.change(emitter)}
              />
            </div>
            <div className="col-3">
              <Select
                color="white"
                name="payment_method_id"
                label="Método de pago"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.payment_method_id}
                options={this.state.payment_methods}
              />
            </div>
            <div className="col-3">
              <Select
                color="white"
                name="client_id"
                label="Cliente"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.client_id}
                options={this.state.clients}
              />
            </div>
            <div className="col-3">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.seller_id}
                options={this.state.sellers}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-3">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-3">
              <Select
                color="white"
                name="status"
                label="Estatus"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.status}
                options={this.state.status_filter}
              />
            </div>
            <div className="col-3">
              <Select
                color="white"
                name="status_payment"
                label="Estatus de pago"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.status_payment}
                options={this.state.status_payment_filter}
              />
            </div>
            {/* <Button className="btn-align-bottom btn-filter" style={{ ...minimalSize, minWidth: 110, maxWidth: 110 }} 
              color=" " 
              onClick={async () => {
                await this.setState({ page: 1 });
                this.load();
              }}
            >
              <span>Filtrar</span>
            </Button>
            <Button className="btn-align-bottom btn-filter" style={{ ...minimalSize, minWidth: 110, maxWidth: 110, marginRight: 0 }} color=" " onClick={this.returnState}>
              <span>Limpiar</span>
            </Button> */}
          </div>
          <div className="row" id="row1">
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" style={{ ...minimalSize, minWidth: 110, maxWidth: 110 }} 
                color=" " 
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" style={{ ...minimalSize, minWidth: 110, maxWidth: 110, marginRight: 0 }} color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>
          {(!!this.state.optionals.length && this.state.optionals.some(x => !x.show)) && (
            <div className="row" id="row1">
              <div className="col col-md d-flex flex-wrap justify-content-between align-items-center check">
                {this.state.optionals.map(el => {
                  if (!el.show) {
                    return (
                      <div className="px-3 py-3 check1">
                        <CheckBox
                          label={el.label}
                          name={el.value}
                          checked={this.state.form[el.value] ? true : false}
                          value={this.state.form[el.value]}
                          onChange={this.handleCheck}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}
          <Table
            data={this.state.data.length}
            title="Pedidos"
            header={this.state.thead}
            right={
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print(this.state.data)}
                >
                  <img src={PrinterIcon} style={{ width: 24, marginTop: '-3px' }} alt="Imprimir" />
                </Button>
                <Link to="report-sale/create">
                  <img src={AddIcon} style={{ width: 24 }} alt="Nuevo Pedido" />
                </Link>
              </div>
            }
          >
            {this.state.data.map((i, index) => {

              const appliedCreditNote = (!!i.return?.credit_note?.number_format && !!i.return?.total_return)
                ? `N° ${i.return?.credit_note?.number_format}, $ ${i.return?.total_return}`
                : '-';

              return (
                <tr key={index}>
                  <td>{i.correlative_number}</td>
                  <td>{i.created_es}</td>
                  <td>
                    {i.seller?.name ?? ''}
                  </td>
                  <td>
                    {i.client?.name ?? ''}
                  </td>
                  <td>{ appliedCreditNote }</td>
                  <td>
                    {parseFloat(Number(i.total)).toFixed(2)}
                  </td>
                  <td>{
                    i.payment_methods_text ? i.payment_methods_text
                    :
                    i.method ? i.method?.name
                    :
                    ''
                  }
                  </td>
                  {/* <td>
                    <span style={{ color: i.deleted_at != null ? '#DD4B4B' : i.dispatch_date != null ? 'black' : this.getColor(i.status) }}>{ i.deleted_at != null ? 'Eliminado' : i.status_text }</span>
                  </td> */}
                  <td>
                    <span style={{ color: i.deleted_at != null ? '#DD4B4B' : this.getColor(i.status) }}>{ i.deleted_at != null ? 'Eliminado' : i.status_text }</span>
                  </td>
                  <td>
                    <span style={{ color: 'black'}}>
                      { 
                        i.status !== Constants.ORDER_STATUS.REJECTED && i.status !== Constants.ORDER_STATUS.CANCELED && i.deleted_at === null ? i.status_payment_text : '-' 
                      }
                    </span>
                  </td>
                  {/* {trows.map(row => {
                    if (
                      row.value === "committed" ||
                      row.value === "available"
                    ) {
                      return (
                        <td>
                          {row.value === "committed"
                            ? disponible(i)
                            : stock(i) - disponible(i) >= 0
                              ? stock(i) - disponible(i)
                              : 0}
                        </td>
                      );
                    } else if (
                      row.type === 2 ||
                      (row.type === 3 && row.value === "total")
                    ) {
                      return (
                        <td>
                          <NumberFormat
                            value={
                              row.value === "total"
                                ? parseFloat(
                                  (i[row.multiplier] * stock(i)).toFixed(2)
                                )
                                : parseFloat(i[row.value].toFixed(2))
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                          />
                        </td>
                      );
                    }
                  })} */}
                  {i.deleted_at != null ?
                    <td></td>
                    :
                    <td>
                      <Button
                        title="Ver"
                        small="true"
                        color="primary"
                        onClick={() => this.setState({ viewPaymentsModal: true, order: i, selectedItem: i })}
                      >
                        <Icon name="eye" />
                      </Button>
                      <Button
                        color="info"
                        title="Descargar copia PDF"
                        small="true"
                        onClick={() => this.selectCurrency(i)}
                      >
                        <Icon name="download" />
                      </Button>
                      {(user.level_id === Constants.ROLE_ADMIN && i.status !== Constants.ORDER_STATUS.CANCELED) && (
                        <Button
                            color="red"
                            title="Cancelar/Anular"
                            small="true"
                            onClick={() => this.cancel(i)}
                          >
                            <Icon name="close" />
                          </Button>
                      )}
                      {(i.status !== Constants.ORDER_STATUS.CANCELED && i.dispatch_date === null) && (
                          user.level_id === Constants.ROLE_MANAGER ?
                            user.permissions.length > 0 ?
                              user.permissions.some(i => i.code === Constants.PERMISSIONS.MANAGER.ORDERS_DELETE) ?
                                <Button
                                  color="red"
                                  title="Cancelar/Anular"
                                  small="true"
                                  onClick={() => this.cancel(i)}
                                >
                                  <Icon name="close" />
                                </Button>
                                : ''
                              : ''
                            :
                            user.level_id === Constants.ROLE_MODERATOR ?
                            user.permissions.length > 0 ?
                              user.permissions.some(i => i.code === Constants.PERMISSIONS.MANAGER.ORDERS_CANCEL) ?
                                <Button
                                  color="red"
                                  title="Cancelar/Anular"
                                  small="true"
                                  onClick={() => this.cancel(i)}
                                >
                                  <Icon name="close" />
                                </Button>
                                : ''
                              : ''
                            : ''
                      )}
                      {
                        (user.level_id === Constants.ROLE_ADMIN &&
                          i.status !== Constants.ORDER_STATUS.CANCELED &&
                          i.status !== Constants.ORDER_STATUS.REJECTED &&
                          i.fiscal_bill === Constants.ORDERS.FISCAL_BILL.NO) ?
                        <Button
                          title="Editar"
                          small="true"
                          color=" "
                          style={{ backgroundColor: '#0f54e0' }}
                          onClick={() => this.setState({ editModal: true, order: i })}
                        >
                          <Icon name="edit" />
                        </Button>
                        :
                        user.level_id === Constants.ROLE_MODERATOR && i.status === Constants.ORDER_STATUS.PENDING ?
                          user.permissions.length > 0 ?
                            user.permissions.some(i => i.code === Constants.PERMISSIONS.MODERATOR.ORDERS_EDIT) ?
                              <Button
                                title="Editar"
                                small="true"
                                color=" "
                                style={{ backgroundColor: '#0f54e0' }}
                                onClick={() => this.setState({ editModal: true, order: i })}
                              >
                                <Icon name="edit" />
                              </Button>
                              : ''
                            : ''
                          : ''
                      }
                      {(
                        i.status === Constants.ORDER_STATUS.PENDING &&
                        (user.level_id === Constants.ROLE_ADMIN || user.level_id === Constants.ROLE_MODERATOR)
                      ) && (
                          <Button
                            color="green"
                            title="Aceptar"
                            small="true"
                            onClick={() => this.accept(i)}
                          >
                            <img src={ThumbUpIcon} width={14} alt="Aceptar" />
                          </Button>
                        )}
                      {(
                        i.status === Constants.ORDER_STATUS.PENDING &&
                        (user.level_id === Constants.ROLE_ADMIN || user.level_id === Constants.ROLE_MODERATOR)
                      ) && (
                          <Button
                            color="red"
                            title="Rechazar"
                            small="true"
                            onClick={() => this.reject(i)}
                          >
                            <img src={ThumbDownIcon} width={14} alt="Rechazar" />
                          </Button>
                        )}
                      {/* {i.deleted_at == null && (
                        user.level_id === Constants.ROLE_ADMIN ?
                          <Button
                            color="red"
                            title="Eliminar"
                            small="true"
                            onClick={() => this.delete(i)}
                          >
                            <Icon name="trash" />
                          </Button>
                          :
                            ''
                      )} */}
                    </td>
                  }
                </tr>
              );
            })}
          </Table>

          {
            showTotal && (
              <p style={{
                fontSize: '16px',
                fontWeight: 'normal',
                fontFamily: 'Roboto Light',
                textAlign: 'right',
                marginTop: '10px',
                marginRight: '10px'
              }}>Costo Total: <NumberFormat
                  value={parseFloat(this.state.total.toFixed(2))}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            )
          }

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(Report);
