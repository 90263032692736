import axios from "../../utils/axios";

class Payment {
  static async getPaymentMethods(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/payment-methods", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomersWithDebts(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/payments/customers", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCustomers(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/payments/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createPayment(form) {
    return new Promise((resolve, reject) => {
      axios.upload("admin/payments/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async processingPayment(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/payments/processing`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async reportPayment(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/payments/report?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Payment;
