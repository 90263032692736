import React, { Component } from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, ArcElement, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar, Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';
import { connect } from 'react-redux';
import Menu from '../menu';
import CalendarIcon from '../../assets/icons/calendar.png';
import { Dashboard as DashboardService } from '../../services';
import { Globals } from '../../utils';

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const CHART_COLORS = {
  blue: '#309bf9',
  green: '#50d177',
  turquoise: '#0fe2db',
  red: '#cc566e',
  lightblue: '#2bbdf6',
  yellow: '#febd25',
  orange: '#ff9f40',
};

const SHOWN_ITEM_QTY = 4;

const formatRound = (amount) => parseFloat(parseFloat(amount).toFixed(2));
const formatAmount = (amount) => Globals.formatMiles((amount));
const getSecuentialColor = (index) => {
  const colors = Object.values(CHART_COLORS);
  return index >= colors.length
    ? colors[index % colors.length]
    : colors[index];
}

class Dashboard extends Component {

  state = {
    top_5_products: [],
    top_5_clients: [],
    cuentas_cobrar_pagar: {
      total_cobrar: 0,
      total_pagar: 0,
    },
    orders_months: {
      January: 0,
      February: 0,
      March: 0,
      April: 0,
      May: 0,
      June: 0,
      July: 0,
      August: 0,
      September: 0,
      October: 0,
      November: 0,
      December: 0,
    },
    currentMonthRange: {
      min: 0,
      max: SHOWN_ITEM_QTY,
    },
    ordersMonthsFiltered: [],
    orders_today: 0,
    orders_totals: 0,
    sellers_sales: [],
  }

  componentDidMount() {
    this.load();
  }

  load = async () => {
    try {
      const res = await DashboardService.getAdminDashboard({ user_id: this.props.user.id });

      const orders_months = [];
      for (const key in res.orders_months) {
        switch (key) {
          case 'January': orders_months.push({ monthName: 'Enero', amount: res.orders_months[key] }); break;
          case 'February': orders_months.push({ monthName: 'Febrero', amount: res.orders_months[key] }); break;
          case 'March': orders_months.push({ monthName: 'Marzo', amount: res.orders_months[key] }); break;
          case 'April': orders_months.push({ monthName: 'Abril', amount: res.orders_months[key] }); break;
          case 'May': orders_months.push({ monthName: 'Mayo', amount: res.orders_months[key] }); break;
          case 'June': orders_months.push({ monthName: 'Junio', amount: res.orders_months[key] }); break;
          case 'July': orders_months.push({ monthName: 'Julio', amount: res.orders_months[key] }); break;
          case 'August': orders_months.push({ monthName: 'Agosto', amount: res.orders_months[key] }); break;
          case 'September': orders_months.push({ monthName: 'Septiembre', amount: res.orders_months[key] }); break;
          case 'October': orders_months.push({ monthName: 'Octubre', amount: res.orders_months[key] }); break;
          case 'November': orders_months.push({ monthName: 'Noviembre', amount: res.orders_months[key] }); break;
          case 'December': orders_months.push({ monthName: 'Diciembre', amount: res.orders_months[key] }); break;
          default: break;
        }
      }

      await this.setState({
        cuentas_cobrar_pagar: res.cuentas_cobrar_pagar,
        orders_months,
        orders_today: res.orders_today,
        orders_totals: res.orders_totals,
        sellers_sales: res.sellers_sales.sort((a,b) => b.total - a.total).splice(0,5),
        top_5_products: res.top_5_products,
        top_5_clients: res.top_5_clients,
      });

      await this.formatMonths();

    } catch (error) {
      console.log(error)
    }
  }

  formatMonths = async (currentMonth = Number(moment().format('M'))) => {
    let monthFrom = 0;
    let monthUntil = SHOWN_ITEM_QTY;

    if (currentMonth > SHOWN_ITEM_QTY) {
      monthFrom = currentMonth - SHOWN_ITEM_QTY;
      monthUntil = currentMonth;
    }

    this.setState({
      currentMonthRange: {
        min: monthFrom,
        max: monthUntil,
      },
      ordersMonthsFiltered: this.state.orders_months.slice(monthFrom, monthUntil),
    });
  }

  render() {

    const { cuentas_cobrar_pagar, currentMonthRange, orders_today, orders_totals, ordersMonthsFiltered, sellers_sales, top_5_products, top_5_clients } = this.state;

    return (
			<Menu history={ this.props.history }>
        <div id="dashboard">
          <div className="widgets">
            <div id="wg--monthly" className="widget">
              <div className="widget-header">
                <p className="widget-title">Pedidos por mes</p>
                <div className="widget-actions">
                  <button
                    onClick={() => this.formatMonths(currentMonthRange.max - 1)}
                    disabled={currentMonthRange.min - 1 < 0}
                  >
                    <i className="fa fa-chevron-left" style={{ fontSize: 18 }} />
                  </button>
                  <button
                    onClick={() => this.formatMonths(currentMonthRange.max + 1)}
                    disabled={currentMonthRange.max + 1 > Number(moment().format('M'))}
                  >
                    <i className="fa fa-chevron-right" style={{ fontSize: 18 }} />
                  </button>
                </div>
              </div>
              <div className="widget-content chart-container">
                <Bar
                  options={{
                    maintainAspectRatio: false,
                    indexAxis: 'x',
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'start',
                        offset: 0,
                        color: 'black',
                        formatter: (value) => !value ? '' : `$${formatAmount(value)}`,
                        labels: {
                          title: {
                            font: {
                              weight: 'bold'
                            }
                          }
                        }
                      }
                    },
                    scales: {
                      y: {
                        ticks: {
                          display: false,
                        },
                      },
                    }
                  }}
                  data={{
                    labels: ordersMonthsFiltered.map(x => x.monthName),
                    datasets: [
                      {
                        label: 'Pedidos por mes',
                        data: ordersMonthsFiltered.map(x => formatRound(x.amount)),
                        backgroundColor: () => ordersMonthsFiltered.map((_,i) => getSecuentialColor(i)),
                      }
                    ],
                  }}
                />
              </div>
            </div>

            <div id="wg--to-pay" className="widget">
              <div className="widget-header">
                <p className="widget-title">Cuentas por cobrar y pagar</p>
              </div>
              <div className="widget-content full-height">
                <div className="calendar">
                  <img src={CalendarIcon} alt="Calendario" width={14} />
                  <div className="date-range">{ moment().locale('es').format('MMMM 1 - D') }</div>
                </div>

                <main
                  className="full-height content-center text-center"
                  style={{ gap: '1rem', margin: '0.5rem 0 1rem' }}
                >
                  <div>
                    <div className="big-text">${ formatAmount(cuentas_cobrar_pagar.total_cobrar) }</div>
                    <div className="legend">
                      <div className="legend-dot green" />
                      <div>Por cobrar</div>
                    </div>
                  </div>

                  <div>
                    <div className="big-text">${ formatAmount(cuentas_cobrar_pagar.total_pagar) }</div>
                    <div className="legend">
                      <div className="legend-dot blue" />
                      <div>Por pagar</div>
                    </div>
                  </div>
                </main>
              </div>
            </div>

            <div id="wg--top-products" className="widget">
              <div className="widget-header full-height">
                <p className="widget-title">Top de 5 productos más vendidos</p>
              </div>
              <div className="widget-content full-height">
                <div className="calendar">
                  <img src={CalendarIcon} alt="Calendario" width={14} />
                  <div className="date-range">{ moment().locale('es').subtract(1,'month').endOf('month').format('MMMM 1 - D') }</div>
                </div>

                <main className="chart-container full-height content-center" style={{ padding: '0 1rem' }}>
                  <Pie
                    options={{
                      maintainAspectRatio: true,
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          display: false,
                        }
                      },
                      
                      scales: {
                        x: {
                          display: false,
                        },
                        y: {
                          display: false,
                        }
                      },
                    }}
                    data={{
                      labels: top_5_products.map(x => x.name),
                      datasets: [
                        {
                          label: 'Cantidad vendida',
                          data: top_5_products.map(x => formatRound(x.quantity)),
                          backgroundColor: () => top_5_products.map((_,i) => getSecuentialColor(i)),
                          borderColor: () => top_5_products.map((_,i) => getSecuentialColor(i)),
                          borderWidth: 1,
                        }
                      ],
                    }}
                  />
                </main>

                <div className="chart-custom-grid-legend">
                  {top_5_products.map((x, i) => (
                    <div key={i} className="legend-item" title={x.name}>
                      <div className="dot" style={{ backgroundColor: getSecuentialColor(i) }} />
                      <div className="label">{ x.quantity } { x.name }</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div id="wg--annual-request" className="widget">
              <div className="widget-header">
                <p className="widget-title">Pedidos del día</p>
              </div>
              <div
                className="widget-content full-height text-center content-center"
                style={{ margin: '0.5rem 0 0' }}
              >
                <div className="big-text font-bold">${ formatAmount(orders_today) }</div>
              </div>

              <div className="widget-header">
                <p className="widget-title">Total anual de pedidos</p>
              </div>
              <div
                className="widget-content full-height text-center content-center"
                style={{ margin: '0.5rem 0' }}
              >
                <div className="big-text font-bold">${ formatAmount(orders_totals) }</div>
                <div className="calendar" style={{ marginLeft: 0 }}>
                  <img src={CalendarIcon} alt="Calendario" width={14} />
                  <div className="date-range">Ene. - { moment().locale('es').format('MMM') }</div>
                </div>
              </div>
            </div>

            <div id="wg--top-clients" className="widget">
              <div className="widget-header">
                <p className="widget-title">Top de 5 clientes con más compras</p>
              </div>
              <div className="widget-content">
                <div className="chart-container">
                  <Bar
                    options={{
                      maintainAspectRatio: false,
                      indexAxis: 'x',
                      responsive: true,
                      plugins: {
                        legend: {
                          display: false,
                        },
                        datalabels: {
                          color: 'black',
                          formatter: (value) => !value ? '' : `$${formatAmount(value)}`,
                          labels: {
                            title: {
                              font: {
                                weight: 'bold'
                              }
                            }
                          }
                        }
                      },
                      scales: {
                        x: {
                          display: false,
                        },
                        y: {
                          ticks: {
                            display: false,
                          },
                        },
                      }
                    }}
                    data={{
                      labels: top_5_clients.map(x => String(x.name).length > 14
                        ? String(x.name).substring(0,14) + '...'
                        : x.name),
                      datasets: [
                        {
                          label: 'Monto de compra',
                          data: top_5_clients.map(x => formatRound(x.total)),
                          backgroundColor: () => top_5_clients.map((_,i) => getSecuentialColor(i)),
                        }
                      ],
                    }}
                  />
                </div>
                <div className="chart-custom-label">
                  {top_5_clients.map((x, i) => (
                    <div key={i}>{ x.name }</div>
                  ))}
                </div>
              </div>
            </div>

            <div id="wg--sales-seller" className="widget">
              <div className="widget-header">
                <p className="widget-title">Ventas por vendedor</p>
              </div>
              <div className="widget-content chart-container" style={{ padding: '0 1rem 0 0.5rem' }}>
                <Bar
                  options={{
                    maintainAspectRatio: false,
                    indexAxis: 'y',
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      datalabels: {
                        anchor: 'end',
                        align: 'end',
                        color: 'black',
                        formatter: (value) => !value ? '' : `$${formatAmount(value)}`,
                        labels: {
                          title: {
                            font: {
                              weight: 'bold'
                            }
                          }
                        }
                      }
                    },
                    scales: {
                      x: {
                        display: false,
                      },
                      y: {
                        grid: {
                          display: false,
                        }
                      },
                    },
                    layout: {
                      padding: {
                        right: sellers_sales.every(x => x.total < 1000) ? 50 : 20,
                      }
                    }
                  }}
                  data={{
                    labels: sellers_sales.map(x => String(x.name).length > 20
                      ? String(x.name).substring(0,20) + '...'
                      : x.name),
                    datasets: [
                      {
                        label: 'Ventas de vendedores',
                        data: sellers_sales.map(x => formatRound(x.total)),
                        backgroundColor: () => sellers_sales.map((_,i) => getSecuentialColor(i)),
                      }
                    ],
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(Dashboard);