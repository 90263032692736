import React from 'react';

const Container = props => (
	<ul className="list-group list-group-flush">
		{ props.children }
	</ul>
)

const Item = props => (
	<li { ...props } className="list-group-item">
		{ props.avatar }
		{ props.icon && <img alt="icon" src={ props.icon } class="icon-list" /> }
		{ props.label && <span className="strong-item-list">{ props.label }: </span> }
		<span style={ props.valueStyle }>{ props.children }</span>
	</li>
)

export default {
	Container,
	Item
}