import React from "react";
import { List } from "../../components";
// import { Avatar, List } from '../../components';
// import { ENV } from '../../utils';

class ViewClient extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: props.user
    };
  }

  render() {
    return (
      <div className="text-center container-view-user">
        {/* <Avatar 
					source={ this.state.user.photo ? ENV.BasePublic + 'img/users/' + this.state.user.photo.file : User } 
					size="130px" /> */}
        <List.Container>
          <List.Item label="Nombre">{this.state.user.name}</List.Item>
          <List.Item label="Email">{this.state.user.email}</List.Item>
          <List.Item label="Cedula/RIF">
            {this.state.user.document ? this.state.user.document : ''}
          </List.Item>
          <List.Item label="Teléfono">{this.state.user.phone}</List.Item>
          <List.Item label="Zona">
            {this.state.user?.zone?.name}
          </List.Item>
          <List.Item label="Personalidad Jurídica">
            {this.state.user.person_type === 1 ? "Natural" : "Juridica"}
          </List.Item>
          {/* <List.Item label="Plazo de crédito">
            {this.state.user.configuration_client ? this.state.user.configuration_client.days_deadline : '-'}
          </List.Item> */}
          {/* <List.Item label="Tipo de Retencion">
            {this.state.user.user_retention_types
              ? this.state.user.user_retention_types.retention.percentage + "%"
              : "No posee tipo de retencion"}
          </List.Item> */}
          <List.Item label="Direccion">{this.state.user.address}</List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewClient;
