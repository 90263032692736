import React from "react";
import moment from "moment";

import { connect } from "react-redux";
import { Globals, Constants, Format, ENV } from "../../utils";

import { Pagination, Button, Modal, Table, Icon } from "../../components";
import {
  Delivery,
  Product,
  Currency,
  Request,
  Branch,
  Seller,
  Zones,
  ReturnsDeliveryOrBill
} from "../../services";

// Components
import Menu from "../menu";
import View from "./view";
import Filter from "./filterForm";
import CreateEditDeliveryNote from "./create_edit_delivery-notes";
import CreateEditReturns from "../returns/create_edit_returns";
import CreateReturnFrom from "../returns/create_return_from";
import AddIcon from '../../assets/icons/add.png';

const TABLE_HEADER = [
  "Nro. Nota",
  "Nro. Pedido",
  "Cliente",
  "Divisa",
  "Fecha Emision",
  "Total",
  "Acciones"
];

class DeliveryNotes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      form: {
        since: new Date(new Date().setHours(0,0,0,0)),
        until: new Date(new Date().setHours(24,0,0,0))
      },
      Item: "",
      current_page: 1,
      last_page: 1,
      visibleCreate: false,
      visibleRequest: false,
      showLg: true,
      currencies: [],
      customers: [],
      branches: [],
      zones: [],
      sellers: [],
      createReturns: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { user } = this.props;
    const { page, form } = this.state;

    const userId = Globals.getUserId(user);
    const zonesId = Globals.getZones(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);

    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    const getCustomers =
      Request.getCustomersByEnterprise({ Id: userId });

    Globals.setLoading();

    Promise.all([
      Product.getAllProductsByEnterprise({ user_id: enterpriseId }),
      Delivery.getDeliveryNotes(page, {
        Id: enterpriseId,
        role: user.role,
        user_id: userId,
        warehouse_id: warehouse_id,
        ...form
      }),
      getCustomers,
      Currency.getCurrenciesFromPurchase(),
      Seller.getSellersHaveDeliveryNotes({ user_id: enterpriseId }),
      Branch.getBranchesByEnterprise({ Id: enterpriseId }),
      Zones.getZones()
    ])
      .then(response => {
        const productsMap = response[0].map(Item => {
          let current_stock = 0;

          if (Item.last_movement) {
            current_stock =
              Item.last_movement.type === 1
                ? Item.last_movement.current_stock + Item.last_movement.amount
                : Item.last_movement.current_stock - Item.last_movement.amount;
          }

          let prices = [];
          if (user.role === Constants.ROLE_SELLER) {
            prices = [
              {
                value: 0,
                label: "Precio Min."
              }
            ]
            if (user.configuration_seller.price_max === 1)
              prices.push({ value: 1, label: "Precio Max." })
            if (user.configuration_seller.price_offer === 1)
              prices.push({ value: 2, label: "Precio Oferta" })

          } else {
            prices = [
              {
                value: 0,
                label: "Precio Min."
              },
              {
                value: 1,
                label: "Precio Max."
              },
              {
                value: 2,
                label: "Precio Oferta"
              }
            ]
          }

          return {
            id: Item.product.id,
            name: Item.product.name,
            code: Item.product.code,
            last_movement: current_stock,
            warehouse_id: Item.warehouse_id,
            prices: prices,
            stock_min: Item.stock_min,
            quantity: "",
            rate: 0,
            price: Item.price,
            price_base: Item.price,
            price_offer: Item.price_offer,
            price_max: Item.price_max,
            exempt: Item.product.exempt,
            subtotal: 0,
            vat: 0,
            total: 0,
            stock: 0,
            serialization: Item.product.serialization
          };
        });

        const { current_page, data, last_page } = response[1];

        const customersMap = response[2];

        const currenciesMap = response[3]
          .filter(Item => Item.status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        const sellersMap = Format.rawSeller(response[4]);

        let branches_filtered = response[5].filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        const rawZones = response[6].map(({ id, name }) => ({
          value: id,
          label: name
        }));

        this.setState({
          products: productsMap,
          customers: customersMap,
          currencies: currenciesMap,
          branches: branchesMap,
          sellers: sellersMap,
          zones: rawZones,
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  // componentDidUpdate(_, prevState) {
  //   if (
  //     prevState.visibleCreate !== this.state.visibleCreate &&
  //     this.state.visibleCreate
  //   ) {
  //     this.maybeLoad();
  //   }
  // }

  // maybeLoad() {
  //   const { user } = this.props;

  //   const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

  //   Branch.getBranchesByEnterprise({ Id: enterpriseId })
  //     .then(response => {
  //       let branches_filtered = response.filter(
  //         ({ deleted_at }) => !deleted_at
  //       );

  //       branches_filtered = Format.rawBranches(
  //         branches_filtered,
  //         user.assigned_warehouses,
  //         user.role
  //       );

  //       const branchesMap = branches_filtered
  //         .filter(({ status }) => status === Constants.STATUS_ACTIVE)
  //         .map(({ id, name }) => ({
  //           value: id,
  //           label: name
  //         }));

  //       this.setState({
  //         branches: branchesMap
  //       });
  //     })
  //     .catch(() => Globals.showError());
  // }

  loadMore = () => {
    const { user } = this.props;
    const { page, form } = this.state;

    const Id = user.id;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Delivery.getDeliveryNotes(page, {
      Id: enterpriseId,
      user_id: Id,
      role: user.role,
      warehouse_id: warehouse_id,
      ...form
    })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;
    const Id = user.id;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Globals.setLoading();

    Delivery.getDeliveryNotes(1, {
      Id: enterpriseId,
      user_id: Id,
      role: user.role,
      warehouse_id: warehouse_id,
      ...form
    })
      .then(response => {
        const { current_page, data, last_page } = response;

        this.setState({
          page: 1,
          form: { ...form },
          data: data,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  };

  rawCustomer = Customer => {
    this.setState(state => ({
      showLg: true,
      customers: [...state.customers, Customer]
    }));
  };

  deployRequest = Item => {
    this.setState({ Item: Item, visibleRequest: true });
  };

  close = () => {
    this.setState(
      {
        visibleCreate: false,
        visibleRequest: false
      },
      () => this.load()
    );
  };

  closeShow = () => {
    this.setState({
      visibleCreate: false,
      visibleRequest: false
    })
  }
  removeAttribute = () => {
    let showLg = !this.state.showLg;
    this.setState({ showLg: showLg });
  };

  printDeliveryNote = (itemId) => {
    const { user } = this.props;
    const enterprise_id = Globals.getEnterpriseId(user);
    const url = `${ENV.BasePublic}sales/print-sale/${enterprise_id}/${itemId}`;
    const win = window.open(url, "_blank");
    win.focus();
  };

  returnDelivery = (Item) => {
    const { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    let form = {
      'Id': enterpriseId,
      'user_id': user.id,
      'role': user.role,
      'code': Item.code,
      'warehouse_id': Item.warehouse_id,
    }

    // ReturnsDeliveryOrBill.getDeliveryNote(form)
    //   .then(response => {
    //     console.log(response);

    //   })
    //   .catch(() => Globals.showError());

    this.setState({
      Item: Item,
      createReturns: true,
    });





  };

  closeReturns = () => {
    this.setState(
      {
        createReturns: false,
      },
      () => this.load()
    );
  };

  render() {
    const {
      data,
      Item,
      showLg,
      branches,
      products,
      customers,
      currencies,
      sellers,
      zones,
      visibleCreate,
      visibleRequest,
      createReturns,
    } = this.state;

    const { user } = this.props;
    const Id = Globals.getUserId(user);

    return (
      <Menu history={this.props.history}>
        {visibleCreate && (
          <Modal
            className={showLg ? "modal-delivery" : ""}
            title="Crear Nota de Entrega"
            onClose={this.close}
            visible
          >
            <CreateEditDeliveryNote
              removeAttribute={this.removeAttribute}
              newCustomer={Customer => this.rawCustomer(Customer)}
              onClose={this.close}
              havePermission={
                this.props.sidebar.find(Item => Item.name === "Clientes")
                  ? true
                  : false
              }
              Id={Id}
              Item={null}
              products={products}
              currencies={currencies}
              customers={customers.filter(
                Item => Item.status === Constants.STATUS_ACTIVE
              )}
              branches={branches}
              user={user}
            />
          </Modal>
        )}
        {visibleRequest && (
          <Modal
            className="modal-delivery"
            title="Visualizar Nota de Entrega"
            onClose={this.closeShow}
            visible
          >
            <View Item={Item} />
          </Modal>
        )}
        {createReturns && (
          <Modal
            className="modal-delivery"
            title="Devolución de nota de entrega"
            onClose={this.closeReturns}
            visible
          >
            <CreateReturnFrom
              Item={Item}
              user={user}
              Id={Id}
              onClose={this.closeReturns}
            />
          </Modal>
        )}
        <div id="delivery-notes">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              Id={Id}
              user={user}
              since={new Date(new Date().setHours(0,0,0,0))}
              until={new Date(new Date().setHours(24,0,0,0))}
              sellers={sellers}
              zones={[{ value: 0, label: "Todos" }, ...zones]}
              branches={[...branches, { value: 0, label: "Todos" }].reverse()}
              customers={customers.map(Item => {
                return {
                  value: Item.id,
                  label:
                    Item.person.first_name
                };
              })}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={this.state.data.length}
                  title="Notas de entrega"
                  header={TABLE_HEADER}
                  // right={
                  //   <Button
                  //     title="Agregar N. Entrega"
                  //     small="true"
                  //     onClick={() => {
                  //       this.setState({
                  //         visibleCreate: true,
                  //         showLg: true
                  //       });
                  //     }}
                  //   >
                  //     <img src={AddIcon} alt="Agregar N. Entrega" />
                  //   </Button>
                  // }
                >
                  {data.map((Item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER
                            ? Item.code
                            : "-"}
                        </td>
                        <td>
                          {Item.request_order ? Item.request_order.code : "-"}
                        </td>
                        <td>
                          {(Item.client.person || {}).first_name ||
                            (Item.client || {}).name}
                        </td>
                        <td>{(Item.currency || {}).name || "-"}</td>
                        <td>
                          {moment(Item.date_emission).format("DD/MM/YYYY")}
                        </td>
                        <td>{Globals.formatMiles(Item.total)}</td>
                        <td>
                          <Button
                            color="primary"
                            title="Ver mas"
                            small="true"
                            onClick={() => this.deployRequest(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                          <Button
                            color="warning"
                            title="Imprimir"
                            small="true"
                            onClick={() => this.printDeliveryNote(Item.id)}
                          >
                            <Icon name="print" />
                          </Button>
                          {/* <Button
                            color="red"
                            title="Devolver"
                            small="true"
                            onClick={() => this.returnDelivery(Item)}
                          >
                            <Icon name="ban" />
                          </Button> */}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={this.state.last_page}
                  active={this.state.page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => this.loadMore()
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(DeliveryNotes);
