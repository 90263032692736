import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import es from "date-fns/locale/es";
import "react-datepicker/dist/react-datepicker.css";
import CalendarIcon from '../assets/icons/calendar.png';

registerLocale("es", es);
setDefaultLocale("es");

const CustomInput = React.forwardRef(
 ({ className, color, onClick, value, disabled }, ref) => (
    <div
      ref={ref}
      className={`container-datepicker ${className} ${color ? color : ""}`}
      onClick={onClick}
    >
      <span>{value}</span>
      <img src={CalendarIcon} alt="Calendario" style={{width: 24, marginLeft: '15px'}} />
    </div>
 )
);

const _DatePicker = props => {
 // Si defaultToday es true, establece la fecha actual como valor predeterminado
 const defaultDate = props.defaultToday ? new Date() : props.value;

 return (
    <div className={`form-group datepicker ${props?.className}`}>
      {props.label && <label className={`label-datepicker ${props?.labelClass}`}>{props.label}</label>}
      <DatePicker
        maxDate={props.maxDate}
        minDate={props.minDate}
        selected={defaultDate}
        onChange={props.onChange}
        disabled={props.disabled ? props.disabled : false}
        dateFormat="dd/MM/yyyy"
        customInput={<CustomInput {...props} />}
      />
    </div>
 );
};

export default _DatePicker;
