import React, { Component, createRef } from 'react';
import { Button, Input } from '../../components';
import { Globals } from '../../utils';
import { Auth } from '../../services';

import Logo from '../../assets/img/logo.png';
import BackButton from '../../assets/icons/left-arrow.png';

class Recover extends Component {

  state = {
    form: {
      code: '',
      email: '',
      password: '',
      password_confirmation: '',
    }
  }

  submitted = createRef(false);

  onChange = async (value, target) => {
    await this.setState(state => ({
      form: { ...state.form, [target]: value }
    }));
  }

  isValidForm = () => {
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    const { form } = this.state;
		const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    if (!form.email)
      return onError('Debe indicar el correo');

    if (!emailPattern.test(form.email))
      return onError('El correo debe tener un formato válido');

    return true;
  }

  onSubmit = async () => {
    if (!this.isValidForm()) return;

    Globals.setLoading();

    try {
      await Auth.recover(this.state.form);
      Globals.showSuccess('Se le ha enviado un correo electrónico para la recuperación de contraseña');
      this.props.history.push('login');

    } catch (error) {
      Globals.showError(error?.error);
      console.log(error?.error);
    }

    Globals.quitLoading();
  }

  render() {

    const { form } = this.state;

    return (
      <div id="recover">
        <div className="left">
          <header>
            <button
              className="go-back"
              type="button"
              onClick={() => this.props.history.goBack()}
            >
              <img src={BackButton} alt="Volver" />
            </button>
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>

            <h4>RESTABLECER CONTRASEÑA</h4>
            <p className="text-center">Te enviaremos instrucciones a tu correo para recuperar tus datos.</p>
            <p className="text-center">Utiliza esta opción si tu cuenta se encuentra bloqueada</p>
          </header>

          <form className="row">
            <div className="inputs">
              <Input
                placeholder="Correo electrónico"
                type="email"
                value={form.email}
                onChange={e => this.onChange(e.target.value, 'email')}
              />
            </div>
            <div className="col-12 text-center my-2">
              <Button
                type="button"
                color=" "
                className="primary"
                onClick={this.onSubmit}
              >
                <span>ENVIAR</span>
              </Button>
            </div>
          </form>
        </div>
      </div>
    )
  }
}

export default Recover;