/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { connect, useSelector } from "react-redux";
import moment from "moment";
import { Button, DatePicker, Input, Select, Modal, Icon } from "../../components";
import { Constants, Globals, axios } from "../../utils";
import CreateEditProduct from "../products/create_edit_product";

const INITIAL_STATE = {
	payment_type: Constants.PAYMENT_TYPES.PAID,
	comments: '',
	method_id: '',
	credit_days: '',
	provider_id: '',
	payment_date: '',
	amount_payment: '',
	initial_payment: '',
	discount: ''
}

class CreateEditPurchase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: INITIAL_STATE,
      client: null,
      modalAddProducts: false,
      modalCreateProduct: false,
      disabled_button: false,
      products: [],
      brands: [],
      categories: [],
      provider: null,
      dates: [],
      data: {
        conversion: {},
        methods: [],
        providers: [],
      },
      edit: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount = async () => {
    this.getProviders();
    this.getPaymentMethods();
    this.getBrands();
    this.getCategories();
    if (this.props.edit) this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  getProviders = async (page = 1) => {
    let param = {
      user_id: this.props.user.id,
      admin_id: this.props.user.admin_id || this.props.user.id,
    };

    axios
      .post("web/admin/purchases/providers?page=" + page, param)
      .then(async (res) => {
        if (res.data.providers) {
          await this.setState(state => ({
            data: {
              ...state.data,
              providers: [
                ...state.data.providers,
                ...res.data.providers.data
              ],
            },
          }));
          if (res.data.providers.data.length === 30) {
            await this.getProviders(page + 1);
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  }

  getPaymentMethods = async (page = 1) => {
    let param = {
      user_id: this.props.user.id,
      admin_id: this.props.user.admin_id || this.props.user.id,
    };

    axios
      .post("admin/purchases/methods?page=" + page, param)
      .then(async (res) => {
        if (res.data.methods) {
          await this.setState(state => ({
            data: {
              ...state.data,
              methods: [
                ...state.data.methods,
                ...res.data.methods,
              ],
              conversion: res.data.conversion,
            },
          }));
          if (res.data.methods.length === 30) {
            await this.getPaymentMethods(page + 1);
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  }

  getBrands = () => {
    let param = {
      user_id: this.props.user.id
    };
    axios.post('web/admin/brands?page=', param)
			.then(async res => {
				if (res.data) {
          await this.setState({
            brands: res.data.brands
          });
				}
			})
			.catch(err => {
				Globals.showError();
			})
  }

  getCategories = () => {
    const { user } = this.props;
    let param = {
      user_id: user.id
    };
    axios
    .post("web/admin/categories?page=", param)
    .then(async res => {
      if (res.data.result) {
        await this.setState({
          categories: res.data.categories.data
        });
      }
    })
    .catch(err => {
      Globals.showError();
    })
    .then(() => {
      Globals.quitLoading();
    });
  }

  edit = async () => {
    let documents = {};
    const { element } = this.props.edit;

    let products_element = []
    element.details.map((item,index )=> {
      let product = []
      if(item.product){
        product = item.product
        product.quantity = item.quantity
        product.selected_price = item.price

        products_element.push(product)
      }
    })

    await this.setState({
      form: {
        id: element.id,
        quote_id: element.id,
        client_id: element.client_id,
        comments: element.comments,
        expiration_date: moment(element.expiration_date).isValid() ? moment(element.expiration_date).toDate() : '',
      },
      products: products_element,
      edit: true,
    });
  }

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  }

	getTotal = () => {
		let total = this.state.products.map(product => {
			let total = parseFloat(product.purchase_price) * (parseInt(product.quantity) || 0);

			if (product.discount != null && product.discount <= 100) {
				total = total - ((total * product.discount) / 100);
			}

			return total;
		}).reduce((a,b) => a + b,0);

		if (this.state.form.discount != '' && this.state.form.discount <= 100) {
			total = total - ((total * this.state.form.discount) / 100);
		}

		return total;
	}

	getItemSubtotal = (product) => {
    let total = parseFloat(product.purchase_price) * (parseInt(product.quantity) || 0);

    if (product.discount != null && product.discount <= 100) {
      total = total - ((total * product.discount) / 100);
    }

    return total;
	}

  getAmountPayment = () => {
    return this.state.dates.length > 0
      ? ((this.getTotal() || 0) - (this.state.form.initial_payment || 0)) / this.state.dates.length
      : 0;
  }

	delete = (index) => {
		Globals.confirm('¿Desea eliminar el producto de la orden de compra?',() => {
			let products = [ ...this.state.products ];
			products.splice(index,1);
			this.setState({ products });
		});
	}

	changeProduct = (value,target,index,callback = () => {}) => {
		let products = Globals.copy(this.state.products);
		products[index][target] = value;
		this.setState({ products }, () => callback());
	}

	changeValue = async (value,target,index,callback = () => {}) => {
		let products = Globals.copy(this.state.products);
		products[index][target] = value;

		await this.setState({
			products
		},() => callback());
	}

	setPaymentType = (payment_type) => {
		this.setState({
			form: {
				...this.state.form,
				payment_type
			}
		});
	}

  toggleDate = (date) => {
    const exists = this.state.dates.includes(date);

    this.setState(state => ({
      dates: exists
        ? state.dates.filter(x => x !== date)
        : [ ...state.dates, date ],
    }));
  }

  choseProducts = (products = []) => {
    const { products: oldProducts } = this.state;

    const nonAdded = products
      .filter(np => {
        return !oldProducts.some(op => {
          return np.type === Constants.PRODUCTS_TYPE.SIZE_COLOR
            ? np.id === op.id && np.color_id === op.color_id && np.size_id === op.size_id
            : op.id === np.id
        })
      })
      .map(p => ({ ...p, quantity: 1, selected_price: p.sale_price }));

    this.setState({
      products: [ ...oldProducts, ...nonAdded ],
    });
  }

	enableSubmit = () => {
		return this.setState({
			disabled_button: false
		});
	}

	submit = async () => {
		if (this.state.products.some(product => {
			return product.type === Constants.PRODUCTS_TYPE.SIZE_COLOR && !product.size_id && !product.color_id;
		})) {
			Globals.showError("Por favor, ingrese el color/talla de los productos que lo requieran");
			return false;
		}

    for (const product of this.state.products) {
      if(product.purchase_price == 0){
        Globals.showError(`El precio de compra del producto ${ product?.name } debe ser mayor a $0.00`);
        return false;
      }

      if (product.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
        const existsMoreThanOne = this.state.products.filter(prod => prod.product_id === product.product_id && prod.color_id === product.color_id && prod.size_id === product.size_id).length > 1;
        if (existsMoreThanOne) {
          const color = product.size_colors.find(_i => _i.color_id === product.color_id)?.color;
          const size = product.size_colors.find(_i => _i.size_id === product.size_id)?.size;
          Globals.showError(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) se encuentra más de una vez en la compra`);
          return false;
        }
      }
    }

    for (const product of this.state.products) {
      if (product.type === Constants.PRODUCTS_TYPE.SIZE_COLOR) {
        const size_color = product?.size_colors?.find((i) => {
          if (product.size_id && product.color_id) {
            return i.size_id == product.size_id && i.color_id == product.color_id;
          }
          else if (product.size_id) {
            return i.size_id == product.size_id && i.color_id == null;
          }
          else if (product.color_id) {
            return i.color_id == product.color_id && i.size_id == null;
          }
        });

        if (!size_color) {
          const color = product.size_colors.find(_i => _i.color_id === product.color_id)?.color;
          const size = product.size_colors.find(_i => _i.size_id === product.size_id)?.size;
          Globals.showError(`Lo sentimos, el producto ${ product?.name } (${ [size?.name,color?.name].filter(i => i != null).join(',') }) no se encuentra en inventario`);
          return false;
        }
      }
    }

    if (this.state.form.payment_type == Constants.PAYMENT_TYPES.TO_PAY) {
      if (this.state.dates.length == 0) {
        Globals.showError("Por favor, seleccione las fechas de los pagos");
        return false;
      }

      if (this.state.form.initial_payment >= this.getTotal()) {
        Globals.showError("El pago inicial no puede ser igual o mayor al monto total. Este es un pedido por cobrar");
        return false;
      }

      if (this.state.dates.some(i => moment(i,'DD-MM-YYYY') > moment(this.state.form.payment_date,'DD-MM-YYYY'))) {
        Globals.showError("Lo sentimos, la fecha de pago final no puede ser menor a la fecha de los abonos");
        return false;
      }

      if (!this.state.form.payment_date) {
        Globals.showError("El campo Fecha de pago final es requerido");
        return false;
      }
    }

		if (this.state.form.discount != '' && this.state.form.discount > 100) {
			Globals.showError("El porcentaje de descuento general no puede ser mayor a 100%");
			return false;
		}

		let data = [...this.state.products];
		if (data.length == 0) {
			Globals.showError("Debe ingresar al menos un producto");
			return false;
		}
		if (data.some(i => isNaN(Number(i.purchase_price)) || Number(i.purchase_price) <= 0)) {
			Globals.showError("Debe ingresar el precio de compra de cada producto");
			return false;
		}
		if (data.some(i => i.quantity == null || i.quantity == '')) {
			Globals.showError("Debe ingresar la cantidad a agregar de cada producto");
			return false;
		}
		if (data.some(i => i.discount != null && i.discount > 100)) {
			Globals.showError("El porcentaje de descuento de los productos no puede ser mayor a 100%");
			return false;
		}

    if (!this.state.form.provider_id) {
      Globals.showError("El campo Proveedor es requerido");
      return false;
    }

    if (!this.state.form.method_id) {
      Globals.showError("El campo Método de Pago es requerido");
      return false;
    }

    Globals.setLoading();

    try {
      await axios.post(this.state.edit ? 'admin/purchases/edit' : 'admin/purchases/create', {
        ...this.state.form,
        details: JSON.stringify(this.state.products),
        user_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        admin_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        amount_payment: this.getAmountPayment(),
        payment_date: this.state.form.payment_date && moment(this.state.form.payment_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
        dates: JSON.stringify([...this.state.dates.map(i => moment(i,'DD-MM-YYYY').format('YYYY-MM-DD'))])
      });

      Globals.showSuccess(`Se ha ${this.state.edit ? 'editado':'creado'} la orden de compra correctamente`);
      this.props.onClose();

    } catch (error) {
      this.enableSubmit();

    } finally {
      Globals.quitLoading();
    }

	}

	close = async () => {
    document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
		await this.setState({ modalAddProducts: false, modalCreateProduct: false });
	}

	getProductName = (product) => {
    if (product.type === Constants.PRODUCTS_TYPE.NORMAL) return product.name;

    const size_color = product.size_colors.find(sc => sc.id === product.size_color_id);
    let altName = product.name;
    if (!!size_color?.color) altName += `, ${ size_color.color.name }`;
    if (!!size_color?.size) altName += `, ${ size_color.size.name }`;
    return altName;
	};

  render() {
    const { submitted, products, data, disabled_button } = this.state;

		const amount_payment = this.getAmountPayment();

    return (
      <form onSubmit={this.submit}>
        <div className="container-create-edit-purchase">
          <Modal
            className="modal-product"
            title="Nuevo Producto"
            onClose={this.close}
            visible={this.state.modalCreateProduct}
          >
            <CreateEditProduct
              brands={this.state.brands}
              categories={this.state.categories}
              onClose={this.close}
              user={this.props.user}
            />
          </Modal>

          <Modal
            title="Seleccionar producto"
            onClose={this.close}
            visible={this.state.modalAddProducts}
          >
            <SelectProduct
              onSelect={this.choseProducts}
              onClose={() => this.close()}
            />
          </Modal>

          {this.state.modalAddProducts && (
            <div
              className="backdrop"
              onClick={() => this.close()}
            />
          )}

          <div className="header-grid">
            <Select
              color="gray"
              name="provider_id"
              label="Seleccionar proveedor"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              disabledFirst={false}
              // options={data.providers.map(i => ({ value: i.id, label: i.name }))}
              options={data.providers.map(i => ({
                value: i.id,
                label: i.company !== null && i.company !== "" ? i.name + ' / ' + i.company : i.name
              }))}
              value={this.state.form.provider_id}
              onChange={e => this.handleChange({ target: { name: e.target.name, value: e.target.value } })}
            />

            <Select
              color="gray"
              name="method_id"
              label="Método de Pago"
              defaultname="Seleccione"
              className="material"
              labelClass="material"
              disabledFirst={false}
              value={this.state.form.method_id}
              options={data.methods.map(i => ({ value: i.id, label: i.name }))}
              onChange={e => this.handleChange({ target: { name: e.target.name, value: e.target.value } })}
            />

            <Input
              color=" "
              name="discount"
              label="Descuento General (%) opcional"
              className="material"
              labelClass="material"
              value={this.state.form.discount}
              onChange={this.handleChange}
            />

            <div className="tab-container">
              <div
                className={`tab ${this.state.form.payment_type === Constants.PAYMENT_TYPES.PAID ? 'selected':''}`}
                onClick={ () => this.setPaymentType(Constants.PAYMENT_TYPES.PAID) }
              >
                Pagada
              </div>
              <div
                className={`tab ${this.state.form.payment_type === Constants.PAYMENT_TYPES.TO_PAY ? 'selected':''}`}
                onClick={ () => this.setPaymentType(Constants.PAYMENT_TYPES.TO_PAY) }
              >
                Por Pagar
              </div>
            </div>

            <Input
              color=" "
              name="comments"
              label="Información adicional (Opcional)"
              className="material"
              labelClass="material"
              value={this.state.form.comments}
              onChange={this.handleChange}
            />

            {this.state.form.payment_type === Constants.PAYMENT_TYPES.TO_PAY && (
              <>
                <Input
                  color=" "
                  type="number"
                  name="initial_payment"
                  label="Pago inicial (Opcional)"
                  className="material"
                  labelClass="material"
                  value={this.state.form.initial_payment}
                  onChange={this.handleChange}
                />

                <div>
                  <DatePicker
                    color="white"
                    label="Fecha de pago"
                    className="material"
                    minDate={moment().add(1, 'day').toDate()}
                    value={''}
                    onChange={date => {
                      if (!date) return;
                      const formattedDate = moment(date).format('DD-MM-YYYY');
                      if (this.state.dates.includes(formattedDate)) return;
                      this.toggleDate(formattedDate);
                    }}
                  />

                  <div>
                    {!!this.state.dates.length && (
                      <ul className="dates">
                        {this.state.dates.map((date, index) => (
                          <li
                            key={index}
                            onClick={() => this.toggleDate(date)}
                            title="Click para remover"
                          >
                            { date }
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>

                {amount_payment > 0 && (
                  <Input
                    color=" "
                    name="amount_payment"
                    label="Abonos parciales"
                    className="material"
                    labelClass="material"
                    value={Globals.formatMiles(amount_payment)}
                    disabled={true}
                  />
                )}

                <DatePicker
                  color="white"
                  label="Fecha de pago final"
                  className="material"
                  minDate={new Date()}
                  value={this.state.form.payment_date}
                  onChange={v => this.handleChange({ target: { value: v, name: 'payment_date' }})}
                />
              </>
            )}
          </div>

          <div className="table-actions">
            <Button
              color=" "
              type="button"
              className="secondary"
              outline
              onClick={() => {
                document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
                this.setState({ modalCreateProduct: true })
              }}
            >
              <span>Crear producto</span>
            </Button>

            <Button
              color=" "
              type="button"
              className="secondary"
              outline
              onClick={() => {
                document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
                this.setState({ modalAddProducts: true })
              }}
            >
              <span>Agregar producto</span>
            </Button>
          </div>

          <table>
            <thead>
              <tr>
                <th>Cantidad Actual</th>
                <th>Agregar Cantidad</th>
                <th>Producto</th>
                <th>Precio Venta</th>
                <th>Precio Compra</th>
                <th>Descuento</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {products.map((product, index) => {
                const isSizeColor = product?.type == Constants.PRODUCTS_TYPE.SIZE_COLOR;
                let size_color = null;
                let size_color_name = null;
                if (isSizeColor) {
                  size_color = product?.size_colors?.find((i) => {
                    if (product.size_id && product.color_id) {
                      return i.size_id == product.size_id && i.color_id == product.color_id;
                    }
                    else if (product.size_id) {
                      return i.size_id == product.size_id && i.color_id == null;
                    }
                    else if (product.color_id) {
                      return i.color_id == product.color_id && i.size_id == null;
                    }
                  });
                  size_color_name = this.getProductName({ ...product, size_color_id: size_color?.id })
                }

                return (
                  <tr key={index}>
                    <td>
                      { isSizeColor ? (size_color?.stock || 0) : product.stock }
                    </td>
                    <td>
                      <Input
                        color=" "
                        name="quantity"
                        type="number"
                        value={product.quantity}
                        onChange={(e) => this.changeProduct(e.target.value, 'quantity', index)}
                      />
                    </td>
                    <td className="product-name">
                      <span className={isSizeColor ? 'is-size-color':''}>
                        Cod. {product?.code}. { size_color_name || product?.name }
                      </span>
                      {isSizeColor && (
                        <div className="size-color-pop-up">
                          <>
                            {/* {
                              [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].length > 0 && (
                                <div className="grid2">
                                  <div>Color:</div>
                                    <div className="colors">
                                      {
                                        [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].map((_item) => {
                                          return (
                                            <div
                                              className={`color ${product.color_id == _item ? 'selected' : ''}`}
                                              style={{ backgroundColor: product?.size_colors?.find(x => x.color_id === _item)?.color?.hex }}
                                              onClick={async () => {
                                                await this.changeValue(_item,'color_id',index);
                                                const size_color = product?.size_colors?.find((i) => {
                                                  if (this.state.products[index].size_id && this.state.products[index].color_id) {
                                                    return i.size_id == this.state.products[index].size_id && i.color_id == this.state.products[index].color_id;
                                                  }
                                                  else if (this.state.products[index].size_id) {
                                                    return i.size_id == this.state.products[index].size_id && i.color_id == null;
                                                  }
                                                  else if (this.state.products[index].color_id) {
                                                    return i.color_id == this.state.products[index].color_id && i.size_id == null;
                                                  }
                                                });
                                                if (size_color) {
                                                  await this.changeValue(size_color?.purchase_price,'purchase_price',index);
                                                  await this.changeValue(size_color?.sale_price,'sale_price',index);
                                                }
                                            } } />
                                          )
                                        })
                                      }
                                  </div>
                                </div>
                              )
                            } */}
                            {
                              [...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].length > 0 && (
                                <div className="grid2">
                                  <div>Color:</div>
                                  <Select
                                    disabledFirst={false}
                                    onChange={async e => {
                                      await this.changeValue(e.target.value, 'color_id', index);
                                      const size_color = product?.size_colors?.find((i) => {
                                        if (this.state.products[index].size_id && this.state.products[index].color_id) {
                                          return i.size_id == this.state.products[index].size_id && i.color_id == this.state.products[index].color_id;
                                        }
                                        else if (this.state.products[index].size_id) {
                                          return i.size_id == this.state.products[index].size_id && i.color_id == null;
                                        }
                                        else if (this.state.products[index].color_id) {
                                          return i.color_id == this.state.products[index].color_id && i.size_id == null;
                                        }
                                      });
                                      if (size_color) {
                                        await this.changeValue(size_color?.purchase_price, 'purchase_price', index);
                                        await this.changeValue(size_color?.sale_price, 'sale_price', index);
                                      }
                                    }}
                                    options={[...new Set(product?.size_colors?.filter((i) => i.color_id).map(i => i.color_id))].map(_item => {
                                      const color = product.size_colors?.find(i => i.color_id === _item);
                                      return {
                                        value: color?.color?.id, // Asume que cada color tiene un id único
                                        label: color?.color?.name, // Asume que cada color tiene un nombre
                                      }
                                    })}
                                    value={product.color_id}
                                    className="material"
                                    labelClass="material"
                                    placeholder="Todos"
                                  />
                                </div>
                              )
                            }
                            {
                              [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].length > 0 && (
                                <div className="grid2">
                                  <div>Talla:</div>
                                  <Select
                                    disabledFirst={false}
                                    onChange={async e => {
                                      await this.changeValue(e.target.value,'size_id',index);
                                      const size_color = product?.size_colors?.find((i) => {
                                        if (this.state.products[index].size_id && this.state.products[index].color_id) {
                                          return i.size_id == this.state.products[index].size_id && i.color_id == this.state.products[index].color_id;
                                        }
                                        else if (this.state.products[index].size_id) {
                                          return i.size_id == this.state.products[index].size_id && i.color_id == null;
                                        }
                                        else if (this.state.products[index].color_id) {
                                          return i.color_id == this.state.products[index].color_id && i.size_id == null;
                                        }
                                      });
                                      if (size_color) {
                                        await this.changeValue(size_color?.purchase_price,'purchase_price',index);
                                        await this.changeValue(size_color?.sale_price,'sale_price',index);
                                      }
                                    } }
                                    options={ [...new Set(product?.size_colors?.filter((i) => i.size_id).map(i => i.size_id))].map(_item => {
                                      const size = product.size_colors?.find(i => i.size_id === _item);
                                      return {
                                        value: size?.size?.id,
                                        label: size?.size?.name,
                                      }
                                    }) }
                                    value={ product.size_id }
                                    className="material"
                                    labelClass="material"
                                    placeholder="Todos"
                                  />
                                </div>
                              )
                            }
                          </>
                        </div>
                      )}
                    </td>
                    <td>$ { Globals.formatMiles(product?.sale_price) }</td>
                    <td>
                      <Input
                        color=" "
                        name="purchase_price"
                        type="number"
                        value={product.purchase_price}
                        onChange={(e) => this.changeProduct(e.target.value, 'purchase_price', index)}
                      />
                    </td>
                    <td>
                      <Input
                        color=" "
                        name="discount"
                        placeholder="(Opcional)"
                        type="number"
                        value={product.discount}
                        onChange={(e) => this.changeProduct(e.target.value, 'discount', index)}
                      />
                    </td>
                    <td>
                      <span>$ { Globals.formatMiles(this.getItemSubtotal(product)) }</span>
                      <div>
                        <i
                          className="fa fa-times remove-product"
                          title="Remover producto"
                          onClick={() => this.delete(index)}
                        />
                      </div>
                    </td>
                  </tr>
                )
              })}

              {products.length === 0 && (
                <tr>
                  <td colSpan={7} className="no-items">
                    <div className="no-items">No hay productos en la orden de compra</div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>

          <div className="total-price blue">
            <div>TOTAL</div>
            <div>$ { Globals.formatMiles(this.getTotal()) }</div>
          </div>

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button
                color="green"
                type="button"
                className="primary"
                disabled={disabled_button}
                onClick={() => this.submit()}
              >
                <span>Finalizar</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

const SelectProduct = ({ onSelect, onClose }) => {
  const user = useSelector(state => state.user);
  const adminId = user.level_id === Constants.ROLE_ADMIN ? user.id : user.admin_id;

  const { products, search, fetchMore, isLoading, pagination } = useFetch(adminId);
  const [selected, setSelected] = useState([]);

  const select = (item) => {
    if (selected.includes(item.id)) {
      const others = selected.filter(x => x !== item.id);
      setSelected(others);
    } else {
      setSelected([ ...selected, item.id ]);
    }
  }

  const submit = () => {
    onSelect(products.filter(x => selected.includes(x.id)));
    onClose();
  }

  return (
    <div id="container-create-edit-quote-select-product">
      <Input
        color=" "
        placeholder="Buscar por nombre o código"
        className="material"
        labelClass="material"
        onChange={e => search(e.target.value)}
      />

      {!!selected.length && (
        <div className="product-totals">
          <span>Seleccionados: { selected.length }</span>
          <button
            type="button"
            className="done"
            title="Agregar a la orden de compra"
            onClick={() => submit()}
          >
            <Icon name="check" />
          </button>
        </div>
      )}

      {products.map((item, index) => {
        const isSelected = selected.includes(item.id);
        return (
          <div
            key={`product-item-${index}`}
            className={`product-item ${isSelected ? 'selected':''}`}
            onClick={() => select(item)}
          >
            <div className="product-image">
              <img src={ Globals.fromPhotos(item?.photo?.file) } alt="Producto" />
            </div>
            <div className="product-details">
              <span className="product-name" title={item?.code} style={{ fontWeight: 'bold', fontSize: '12px' }}>Cod. {item?.code}</span>
              <span className="product-name" title={item?.name}>{ item?.name }</span>
            </div>
            <div>${ item?.sale_price }</div>
          </div>
        )
      })}

      <div className="d-flex justify-content-center mt-4">
        {isLoading && <span>Cargando...</span>}
        {(!isLoading && pagination.page < pagination.last_page) && (
          <Button onClick={() => fetchMore()}>
            <span>Cargar más</span>
          </Button>
        )}
      </div>
    </div>
  );
}

const useFetch = (admin_id) => {
  const initialForm = {
    web: true,
    admin_id,
    search: '',
    page: 1,
    per_page: 20,
    last_page: 1,
    item_count: 0,
  };

  const [data, setData] = useState([]);
  const [canFetch, setCanFetch] = useState(true);
  const [pagination, setPagination] = useState(initialForm);

  const debounceTime = 500;
  let debounce = null;

  const fetchData = async () => {
    if (!canFetch) return;

    try {
      const res = await axios.post('web/admin/quotes/products', pagination);

      if (!res?.data?.products)
        return Globals.showError('Ocurrió un error inesperado');

      setCanFetch(false);
      setPagination({
        ...pagination,
        item_count: res?.data?.products?.total,
        last_page: res.data?.products.last_page,
      });

      const newData = pagination.page > 1
        ? [ ...data, ...res.data?.products?.data ]
        : res.data?.products?.data;

      setData(newData);

    } catch (error) {
      Globals.showError('Ocurrió un error inesperado');
    }
  }

  const reload = (inSamePage = false) => {
    setCanFetch(true);
    if (!inSamePage) setPagination(initialForm);
    fetchData();
  }

  const fetchMore = (page = pagination.page + 1) => {
    if (page >= 1 && page <= pagination.last_page && page !== pagination.page) {
      setCanFetch(true);
      setPagination({ ...pagination, page });
    }
  }

  const search = (search) => {
    if (debounce) clearTimeout(debounce);
    debounce = setTimeout(() => {
      setCanFetch(true);
      setPagination({ ...pagination, page: 1, search });
    }, debounceTime);
  }

  useEffect(() => {
    fetchData();
  }, [pagination]);

  return {
    products: data,
    pagination,
    fetchMore,
    reload,
    isLoading: canFetch,
    search,
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditPurchase);
