import React from "react";
import moment from "moment";

import { Input, Textarea, ViewSerial } from "../../components";
import { Constants, Globals } from "../../utils";

import Banesco from '../../assets/icons/banesco.png';
import Banistmo from '../../assets/icons/banistmo.png';
import Binance from '../../assets/icons/binance.png';
import CashPayment from '../../assets/icons/cash-payment.png';
import TransferPayment from '../../assets/icons/transfer-payment.png';
import MobilePayment from '../../assets/icons/mobile-payment.png';
import Zelle from '../../assets/icons/zelle.png';
import Paypal from '../../assets/icons/paypal.png';
import DebitPayment from '../../assets/icons/debit-payment.png';

const { PAYMENT_METHODS } = Constants;

class View extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editable: false,
      view_serials: false,
      item_serials: null,
      serials: null,
      payment_method: [],
    };
  }

  componentDidMount() {
    this.nonEditable();
  }

  nonEditable = () => {
    const { Item } = this.props;
    let payment_method = [];

    if (Item?.method_payment?.length > 0) {
      payment_method = Item.method_payment.map(p => ({
        id: p?.type,
        amount: p?.amount || '0',
        processed: 0,
        reference_number: p?.reference_number,
      }));
    }

    this.setState({ editable: false, payment_method });
  }

  isSelectedPayment = (target) => {
    return this.state.payment_method.some(x => x.id === target);
  }

  getAmount = (target) => {
    return this.state.payment_method.find(x => x.id === target)?.amount || '';
  }

  render() {
    const { Item } = this.props;
    const { view_serials, item_serials, serials } = this.state;
    const _Item = Item;

    return (
      <React.Fragment>
        {view_serials && (
          <ViewSerial
            onBack={() => {
              this.setState({
                item_serials: null,
                view_serials: false,
                serials: null
              });
            }}
            product={item_serials}
            serials={serials}
          />
        )}

        {!view_serials && (
          <React.Fragment>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th>Nro. de Nota de Entrega:</th>
                          <td>{Item.code}</td>
                          <th>Fecha:</th>
                          <td>{parseFormat(Item.date_emission)}</td>
                        </tr>
                        {(Item || {}).request_order && (
                          <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Nro. de Pedido:</th>
                            <td>{Item.request_order.code}</td>
                            <th colSpan="2"></th>
                          </tr>
                        )}
                        <tr>
                          <th>Cliente:</th>
                          <td>
                            {(Item.client.person || {}).first_name ||
                              (Item.client || {}).name}
                          </td>
                          <th>Hora:</th>
                          <td>{timeFormat(Item.date_emission)}</td>
                        </tr>
                        <tr>
                          <th>Rif Cliente:</th>
                          <td>
                            {(Item.client.person || {}).fiscal_identification ||
                              (Item.client.person || {}).identity_document}
                          </td>
                        </tr>
                        <tr>
                          <th>Vendedor:</th>
                          <td>
                            {(Item.seller.person || {}).first_name ||
                              (Item.seller || {}).name}
                          </td>
                        </tr>
                        {(Item || {}).warehouse && (
                          <tr style={{ whiteSpace: "nowrap" }}>
                            <th>Sucursal:</th>
                            <td>{Item.warehouse.name}</td>
                          </tr>
                        )}
                      </thead>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md">
                <div className="form-group">
                  <div className="table-responsive">
                    <table className="table table-bordered view-delivery">
                      <thead>
                        <tr>
                          <th colSpan="6" className="text-center">
                            PRODUCTOS DE LA NOTA DE ENTREGA
                          </th>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th>Descripción</th>
                          <th>Precio</th>
                          <th>Cantidad</th>
                          <th>Total</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_Item.details.map((Item, key) => {
                          const serials = _Item.operation.filter(
                            i => i.product_warehouse.product_id == Item.id
                          );

                          return (
                            <tr key={key}>
                              <td>
                                <p>{Item.code}</p>
                                {serials.length > 0 && (
                                  <p
                                    className="view-serials"
                                    onClick={() => {
                                      this.setState({
                                        view_serials: true,
                                        item_serials: Item,
                                        serials: serials
                                      });
                                    }}
                                  >
                                    Ver Seriales
                                  </p>
                                )}
                              </td>
                              <td>{Item.name}</td>
                              <td>{Item.description ? Item.description : 'Sin descripción'}</td>
                              <td>{Globals.formatMiles(Amount(Item))}</td>
                              <td>{Item.pivot.quantity}</td>
                              <td>
                                {Globals.formatMiles(Item.pivot.subtotal)}
                              </td>
                            </tr>
                          );
                        })}
                        <tr>
                          <td colSpan="3"></td>
                          <th>Subtotal/Cantidad</th>
                          <td>{getQuantity(Item)}</td>
                          <td>{Globals.formatMiles(getAmount(Item))}</td>
                        </tr>
                        <tr>
                          <td colSpan="4"></td>
                          <th>Total</th>
                          <td>{Globals.formatMiles(Item.total)}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="view-request-modal">
              <div className="payment-methods">
                <div className="payment-method">
                  <div className="payment-method-left">
                    <input
                      id="payment-cash"
                      type="checkbox"
                      checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) || this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                    />
                    <div>
                      <img src={CashPayment} alt="CashPayment" />
                    </div>

                    <div>
                      <div>
                        <label htmlFor="payment-cash">Efectivo</label>
                      </div>
                      <div className="extra-options">
                        <div className="extra-option">
                          <input
                            id="payment-method-bs"
                            type="checkbox"
                            value={PAYMENT_METHODS.CASH_BS}
                            checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_BS)}
                            onClick={() => {
                              this.toggleInput(PAYMENT_METHODS.CASH_BS)
                            }}
                          />
                          <label htmlFor="payment-method-bs">Bs</label>
                        </div>
                        <div className="extra-option">
                          <input
                            id="payment-method-divisas"
                            type="checkbox"
                            value={PAYMENT_METHODS.CASH_FE}
                            checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                            onChange={() => {}}
                            />
                          <label htmlFor="payment-method-divisas">Divisas</label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="payment-method-right">
                    {(!this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && !this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)) && (
                      <Input
                        color=" "
                        type="number"
                        name="payment"
                        placeholder="Monto"
                        className="material"
                        labelClass="material"
                        disabled
                      />
                    )}
                    {this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && (
                      <Input
                        color=" "
                        type="number"
                        name={`payment-${PAYMENT_METHODS.CASH_BS}`}
                        placeholder={
                          (this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && this.isSelectedPayment(PAYMENT_METHODS.CASH_FE))
                            ? 'Monto Bs'
                            : 'Monto'
                        }
                        className="material"
                        labelClass="material"
                        disabled={!this.isSelectedPayment(PAYMENT_METHODS.CASH_BS)}
                        value={this.getAmount(PAYMENT_METHODS.CASH_BS)}
                        onChange={() => {}}
                      />
                    )}
                    {this.isSelectedPayment(PAYMENT_METHODS.CASH_FE) && (
                      <Input
                        color=" "
                        type="number"
                        name={`payment-${PAYMENT_METHODS.CASH_FE}`}
                        placeholder={
                          (this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && this.isSelectedPayment(PAYMENT_METHODS.CASH_FE))
                            ? 'Monto Divisas'
                            : 'Monto'
                        }
                        className="material"
                        labelClass="material"
                        disabled={!this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                        value={this.getAmount(PAYMENT_METHODS.CASH_FE)}
                        onChange={() => {}}
                      />
                    )}
                  </div>
                </div>

                <PaymentItem
                  target={PAYMENT_METHODS.BINANCE}
                  label="Binance"
                  icon={Binance}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.TRANSFER}
                  label="Transferencia Bs."
                  icon={TransferPayment}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.ZELLE}
                  label="Zelle"
                  icon={Zelle}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.MOBILE}
                  label="Pago Móvil"
                  icon={MobilePayment}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.BANESCO}
                  label="Banesco Panamá"
                  icon={Banesco}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.PAYPAL}
                  label="Paypal"
                  icon={Paypal}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />

                <PaymentItem
                  target={PAYMENT_METHODS.POS}
                  label="Tarjeta de débito"
                  icon={DebitPayment}
                  isSelectedPayment={this.isSelectedPayment}
                  getAmount={this.getAmount}
                />
              </div>
            </div>

            <div className="row text-center">
              <div className="col-md">
                <div className="form-group">
                  <Textarea
                    color="gray"
                    value={Item.observations}
                    name="observations"
                    label="OBSERVACIONES"
                    readOnly
                  />
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const PaymentItem = ({ target, icon, label, isSelectedPayment, getAmount }) => (
  <div className="payment-method">
    <div className="payment-method-left">
      <input
        id={`payment-${target}`}
        type="checkbox"
        value={target}
        checked={isSelectedPayment(target)}
        onClick={() => {}}
      />
      <div>
        <img src={icon} alt={label} />
      </div>
      <label htmlFor={`payment-${target}`}>{label}</label>
    </div>

    <div className="payment-method-right">
      <Input
        color=" "
        type="number"
        name={`payment-${target}`}
        placeholder="Monto"
        className="material"
        labelClass="material"
        disabled
        value={getAmount(target)}
        onChange={() => {}}
      />
    </div>
  </div>
)

function getQuantity({ details: Products }) {
  return Products.reduce(function(Quantity, Item) {
    return parseInt(Item.pivot.quantity + Quantity);
  }, 0);
}

function getAmount({ details: Products }) {
  return Products.reduce(function(Amount, Item) {
    return parseFloat(Item.pivot.subtotal + Amount);
  }, 0);
}

function Amount({ pivot: Amount }) {
  return parseFloat(Amount.subtotal / Amount.quantity);
}

function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

function timeFormat(Time) {
  return moment(Time).format("HH:mm:ss");
}

export default View;
