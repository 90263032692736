import React from "react";
import moment from "moment";

import {
  Button,
  Select,
  Input,
  DatePicker,
  Textarea,
  Icon,
  ModalScan,
  Modal,
  InputGroup,
} from "../../components";
import { Globals, Constants, Format, axios, ReduceMap } from "../../utils";
import { Request, Warehouse } from "../../services";
import { connect } from 'react-redux';

// Components
import FormClient from "./formClient";
import Payment from "../sales/payment";

const IVA = 0;

class CreateEditRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      create: props.Item ? false : true,
      search: "",
      search_client: "",
      showCustomer: false,
      form: {
        id: "",
        forthright: false,
        warehouse_id: "",
        branch_id: "",
        client_id: "",
        code: "",
        currency_id: "",
        date_emission: new Date(),
        observations: "",
        subtotal: 0.0,
        taxable: 0.0,
        exempt: 0.0,
        vat: 0.0,
        total: 0.0,
        creator_id: props.user.id,
        enterprise_id: props.Id,
        products: [],
        type_payments: [],
      },
      dates: {
        maxRequestDate: moment.now()
      },
      errors: [],
      products: [],
      suggestions: [],
      suggestions_client: [],
      warehouses: [],
      textButton: "Crear",
      edit_serial: false,
      item_serial: null,
      client_selected: '',
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      visiblePayment: false,
    };
  }

  async componentDidMount() {
    const { Id, user } = this.props;

    // Retoma el formulario anterior
    // if (!this.props.Item && this.props.request) {
    //   await this.setState({
    //     form: {
    //       ...this.props.request,
    //       date_emission: this.props.request.date_emission ? moment(this.props.request.date_emission).toDate() : new Date()
    //     }
    //   });
    // }

    this.setState(state => ({
      form: {
        ...state.form,
        enterprise_id: Id,
        creator_id: user.id
      }
    }));

    if (this.props.Item && !this.state.create) {
      const { Item } = this.props;

      this.maybeLoadData(Item, user);
      // this.maybeLoadGenerateId();
    } else {
      this.maybeLoadId(Id);
    }
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }

    if (
      prevState.form.forthright !== this.state.form.forthright &&
      this.state.form.forthright
    ) {
      this.maybeLoadGenerateId();
    } else if (
      prevState.form.forthright !== this.state.form.forthright &&
      !this.state.form.forthright
    ) {
      this.maybeLoadId();
    }
  }

  componentWillUnmount() {
    if (!this.props.Item)
      this.props.dispatch({
        type: 'SET_REQUEST',
        payload: {
          ...this.state.form
        }
      });
    else
      this.props.dispatch({
        type: 'SET_SERIALS_REQUEST',
        payload: [
          ...this.props.serials_request.filter(i => i.id != this.props.Item.id),
          this.state.form
        ]
      });
  }

  maybeLoadGenerateId = () => {
    const { Id } = this.props;

    Request.generateCodeRequest({ Id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));

      })
      .catch(() => Globals.showError());
  };

  maybeLoadData = (Item, user) => {
    const { details } = Item;

    const rawProducts = arrayTransform(details, Item.warehouse_id, user);

    this.setState(state => ({
      form: {
        ...state.form,
        id: Item.id,
        warehouse_id: Item.warehouse_id,
        branch_id: Item.warehouse?.branch_id,
        client_id: Item.client_id,
        client_selected: Item.client ? Item.client : '',
        code: Item.code,
        currency_id: Item.currency_id,
        date_emission: new Date(),
        observations: Item.observations,
        taxable: Item.taxable,
        exempt: Item.exempt,
        subtotal: Item.subtotal,
        vat: Item.vat,
        total: Item.total,
        // products: rawProducts.filter(i => {
        //   if (this.props.serials_request && this.props.Item) {
        //     const serials = this.props.serials_request.find(_i => _i.id == Item.id);
        //     if (serials && serials.products.findIndex(_i => _i.id == i.id) == -1) {
        //       return false;
        //     }
        //   }
        //   return true;
        // }).map(item => {
        //   if (this.props.serials_request && this.props.Item) {
        //     const serials = this.props.serials_request.find(i => i.id == Item.id);
        //     if (serials) {
        //       const product = serials.products.find(i => i.id == item.id);
        //       if (product) {
        //         item.serials = product.serials;
        //         if (product.serials) {
        //           item.quantity = item.serials.length;
        //         }
        //       }
        //     }
        //   }
        //   return item;
        // })
        products: rawProducts.map(item => {
          const hasSerials = this.props.Item?.operation?.filter(x => x.product_warehouse.product_id === item.id);
          if (item.serialization && !!hasSerials?.length) {
            item.serials = hasSerials.map(x => x.serial);
          }
          return item;
        }),
      },
      textButton: "Guardar"
    }));

    this.takeClient(Item.client);
  };

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: userId,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      const warehouse_id = this.props.request
        ? this.props.request.warehouse_id
        : (!this.state.create ? this.props.Item.warehouse_id : "")

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id,
        },
        warehouses: warehousesMap
      }), () => {
        this.props.dispatch({
          type: 'SET_REQUEST',
          payload: null
        });
      });
    });
  };

  AutoComplete = ({ search }) => {
    const { products } = this.props;
    const { form } = this.state;

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    let searchLowerCase = search.toLowerCase();

    const filterProducts = products.filter(Item => {
      let nameLowerCase = Item.name.toLowerCase();
      return (
        nameLowerCase.indexOf(searchLowerCase) !== -1 &&
        parseInt(form.warehouse_id) === Item.warehouse_id &&
        Item.last_movement > 0 &&
        form.products.every(query => {
          return parseInt(query.id) !== parseInt(Item.id);
        })
      );
    });

    this.setState({ suggestions: filterProducts });
  };

  AutoCompleteClients = ({ search_client }) => {
    const { customers } = this.props;
    const { form } = this.state;

    let isnum = /^[0-9]+$/;

    if (!search_client) {
      this.setState({ suggestions_client: [] });
      return;
    }

    if (!search_client.match(isnum)) { //Si la busqueda es por nombre
      let searchLowerCase = search_client.toLowerCase();
      
      const filterCustomers = customers.filter(Item => {
        let nameLowerCase = Item.person.first_name.toLowerCase();
        return (
          nameLowerCase.indexOf(searchLowerCase) !== -1 //&&
          // form.client_id.every(query => {
          //   return parseInt(query.id) !== parseInt(Item.id);
          // })
        );
      });

      this.setState({ suggestions_client: filterCustomers });
    }
    else if (search_client.match(isnum)) { //Si la busqueda es por cedula o rif
      let searchLowerCase = search_client;

      const filterCustomers = customers.filter(Item => {
        if (Item.person.identity_document != null) { //Si es por cedula
          let identityLowerCase = Item.person.identity_document;
          return (
            identityLowerCase.indexOf(searchLowerCase) !== -1
          );
        }
        else if (Item.person.fiscal_identification != null 
            && Item.person.identity_document != null) 
        { //Si es por rif
          let fiscalLowerCase = Item.person.identity_document;
          return (
            fiscalLowerCase.indexOf(searchLowerCase) !== -1
          );
        }

      });

      this.setState({ suggestions_client: filterCustomers });


      //this.setState({ suggestions_client: filterCustomers });
    }

  };

  handleClick = Product => {
    const { form } = this.state;

    Globals.confirm(
      `¿Desea remover del pedido el producto: ${Product.name}?`,
      () => {
        const filterMap = form.products.filter(Item => Item.id !== Product.id);

        this.setState(state => ({
          form: {
            ...state.form,
            products: filterMap,
            subtotal: FloatNum(reduceMap(filterMap, "subtotal")),
            taxable: FloatNum(taxableMap(filterMap)),
            exempt: FloatNum(exemptMap(filterMap)),
            vat: FloatNum(reduceMap(filterMap, "vat")),
            total: FloatNum(reduceMap(filterMap, "total"))
          }
        }));
      }
    );
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    switch (name) {
      case "cash":
      case "credit":
      case "debit":
      case "transfer":
      case "financed":
        const method = form.type_payments.find(Item => Item.id === value);
        const processed = parseInt(value) === Constants.SALE_PAYMENT_TYPES.CREDIT ?
          Constants.SALE_PAYMENT_PROCESSED_LIST.PROCESSED : Constants.SALE_PAYMENT_PROCESSED_LIST.NOT_PROCESSED

        let parseMap;
        if (!!method) {
          parseMap = form.type_payments.filter(Item => Item.id !== value);
        } else {
          // parseMap = [
          //   ...form.type_payments,
          //   { id: value, label: Payments[value].type, amount: "", reference_number: "", processed: processed }
          // ];
        }

        this.setState(state => ({
          form: {
            ...state.form,
            type_payments: parseMap
          }
        }));

        return;
      case "search":
        this.setState({ [name]: value }, () => this.AutoComplete(this.state));
        break;
      case "search_client":
        this.setState(state => ({
          form: {
            ...state.form,
            client_id: ""
          },
          client_selected: "",
        }));
        this.setState({ [name]: value }, () => {
          this.AutoCompleteClients(this.state);
        });
        break;
      case "warehouse_id":
      case "branch_id":
        if (form.products.length === 0) {
          this.setState(state => ({
            form: {
              ...state.form,
              type_payments: [],
              [name]: value
            }
          }));
          break;
        }

        Globals.sure(
          "¡Perderá su información de productos al cambiar esta opción!",
          ({ value: Status }) => {
            switch (Status) {
              case true:
                this.setState(state => ({
                  form: {
                    ...state.form,
                    [name]: value,
                    products: []
                  }
                }));
                break;
              default:
                break;
            }
          }
        );
        break;
      default:
        this.setState(state => ({
          form: {
            ...state.form,
            type_payments: [],
            [name]: value
          }
        }));
        break;
    }
  };

  handleDiscount = (value, name) => {
    const { form } = this.state
    const { user } = this.props;
    const rawProducts = [...form.products];
    const discountRate = Globals.getRate(user);

    if (name === "discount_percentage" && !!discountRate) {
      if (parseInt(value) > discountRate.percentage) {
        Globals.showWarning(
          `¡El porcentaje de descuento no puede exceder el ${discountRate.percentage}%!`
        );
        return;
      }
    }

    const parseMap = form.type_payments.map((item) => {
      return {
        ...item,
        amount: "",
        reference_number: "",
      }
    })

    const mapProducts = rawProducts.map((product, key) => {
      return {
        ...product,
        discount_percentage: value,
        vat: FloatNum(rawProducts[key].exempt ? 0 : rawProducts[key].subtotal * IVA),
        total: FloatNum(
          rawProducts[key].exempt
            ? rawProducts[key].subtotal
            : rawProducts[key].subtotal * IVA + rawProducts[key].subtotal
        )
      }
    })

    const discount = this.calculateDiscount(mapProducts);

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: parseMap,
        discount_percentage: value,
        subtotal: FloatNum(ReduceMap.reduceMap(mapProducts, "subtotal")),
        discount: FloatNum(discount),
        taxable: FloatNum(ReduceMap.taxableMap(mapProducts)),
        exempt: FloatNum(ReduceMap.exemptMap(mapProducts)),
        vat: FloatNum(ReduceMap.reduceMap(mapProducts, "vat")),
        total: FloatNum(ReduceMap.reduceMap(mapProducts, "total") - FloatNum(discount))
      }
    }));
  }

  isCash = () => {
    return (
      this.state.form.type_payments.map(i => parseInt(i.id)).indexOf(1) != -1
    );
  };

  handleNum = (emitter, key) => {
    const { name, value } = emitter.target;
    const { form } = this.state;

    let newValue = value ? parseInt(value) : value;
    let rawProducts = form.products;
    let quantityValue;
    let rate = 1;

    switch (name) {
      case "quantity":
        quantityValue =
          newValue > 0 && newValue !== ""
            ? newValue * rawProducts[key].price
            : 0;

        if (
          rawProducts[key].stock_min >=
          rawProducts[key].last_movement - newValue
        ) {
          Globals.showWarning(
            `¡El producto ${rawProducts[key].name} estara por debajo del stock mínimo!`
          );
        }

        rawProducts[key] = {
          ...rawProducts[key],
          quantity: newValue,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue
              : quantityValue * IVA + quantityValue
          ),
          stock: rawProducts[key].last_movement - newValue
        };
        break;
      case "rate":
        rate =
          parseInt(value) === 1
            ? rawProducts[key].price_base
            : parseInt(value) === 2
              ? rawProducts[key].price_max
              : rawProducts[key].price_offer;

        quantityValue = rawProducts[key].quantity * rate;

        rawProducts[key] = {
          ...rawProducts[key],
          [name]: parseInt(value),
          price: rate,
          subtotal: parseFloat(quantityValue),
          vat: FloatNum(rawProducts[key].exempt ? 0 : quantityValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? quantityValue
              : quantityValue * IVA + quantityValue
          )
        };
        break;
    }

    this.setState(state => ({
      form: {
        ...state.form,
        products: rawProducts
      }
    }));

    this.setState(state => ({
      form: {
        ...state.form,
        subtotal: FloatNum(reduceMap(rawProducts, "subtotal")),
        taxable: FloatNum(taxableMap(rawProducts)),
        exempt: FloatNum(exemptMap(rawProducts)),
        vat: FloatNum(reduceMap(rawProducts, "vat")),
        total: FloatNum(reduceMap(rawProducts, "total"))
      }
    }));
  };

  takeProduct = Product => {
    const exists = this.state.form.products.find(Item => {
      return Item.id === Product.id;
    });

    if (Product.quantity == "") {
      Product.quantity = 0;
    }

    if (!exists) {
      this.setState(state => ({
        search: "",
        form: {
          ...state.form,
          products: [...state.form.products, Product]
        },
        suggestions: []
      }));
    }
  };

  handleNumPayment = (values, key) => {
    const { floatValue } = values;
    const { form } = this.state;

    const paymentsMap = [...form.type_payments];

    paymentsMap[key] = {
      ...paymentsMap[key],
      amount: floatValue
    };

    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: paymentsMap
      }
    }));
  };

  handleReferenceNumber = (value, key) => {
    const { form } = this.state;

    const paymentsMap = [...form.type_payments];

    paymentsMap[key] = {
      ...paymentsMap[key],
      reference_number: value
    };
    
    this.setState(state => ({
      form: {
        ...state.form,
        type_payments: paymentsMap
      }
    }));
  }

  takeClient = Client => {

    let client_selected = '';

    if (Client.person.identity_document) {
      client_selected = Client.person.first_name + ' ' + Client.person.identity_document;
    }
    else if (Client.person.fiscal_identification !== null) {
      client_selected = Client.person.first_name + ' ' + Client.person.fiscal_identification;
    }

    this.setState(state => ({
      search_client: "",
      form: {
        ...state.form,
        client_id: Client.id
      },
      suggestions_client: [],
      client_selected: client_selected,
    }));
    this.state.form.client_id = Client.id;

    //this.saveDelivery();
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { form, submitted, create } = this.state;
    if (submitted) {
      return;
    }

    // if (this.isCash()) {
    //   const amountNoCash = FloatNum(
    //     ReduceMap.reduceMap(
    //       form.type_payments.filter(i => parseInt(i.id) != 1),
    //       "amount"
    //     )
    //   );

    //   if (amountNoCash > parseFloat(form.total)) {
    //     Globals.showError(
    //       "¡El monto a pagar sin efectivo es mayor al total de la venta!"
    //     );
    //     return;
    //   } else {
    //     form.type_payments[
    //       form.type_payments.findIndex(i => parseInt(i.id) == 1)
    //     ].amount = parseFloat(FloatNum(parseFloat(form.total) - amountNoCash));
    //   }
    // }

    // Almacenando el valor de por pago de la venta
    // const amountMap = FloatNum(
    //   ReduceMap.reduceMap(form.type_payments, "amount")
    // );

    // Filtrando productos para valores nulos
    const findValuesNull = form.products.filter(
      Item => Item.quantity <= 0 || Item.quantity === ""
    );

    // Filtrando productos con precios en 0
    const findNotValues = form.products.filter(Item => Item.total <= 0);

    if (form.products.length === 0) {
      Globals.showError("¡El pedido debe tener productos agregados!");
      return;
    } else if (findValuesNull.length > 0) {
      Globals.showError("¡Los productos deben tener un valor mayor a 0!");
      return;
    } else if (findNotValues.length > 0) {
      Globals.showError("¡Los productos deben tener un precio mayor a 0!");
      return;
    }
    // else if (parseFloat(amountMap) > parseFloat(form.total)) {
    //   Globals.showError("¡El monto a pagar es mayor al total de la venta!");
    //   return;
    // } else if (parseFloat(amountMap) < parseFloat(form.total)) {
    //   Globals.showError("¡El monto a pagar es menor al total de la venta!");
    //   return;
    // } else if (isNaN(parseFloat(amountMap))) {
    //   Globals.showError("¡Debe ingresar el monto en los métodos de pago!");
    //   return;
    // }
  

    // Validando si hay productos por serializar
    if (!create) {
      const cant = form.products.filter(
        i =>
          i.serialization == Constants.SERIALIZATION.ACTIVE && i.serials == null
      ).length;
      if (cant > 0) {
        Globals.showError("¡Hay productos que necesitan serializarse!");
        return;
      }

    }


    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });
    switch (create) {
      case true:
        Request.createRequest(form)
          .then(async response => {
            const { code } = response;
            this.setState(this.defaultState);
            Globals.showSuccess(`¡Pedido Nº ${code} creado exitosamente!`);
            await this.setState({
              visiblePayment: false,
              form: {
                id: "",
                forthright: false,
                warehouse_id: "",
                branch_id: "",
                client_id: "",
                code: "",
                currency_id: "",
                date_emission: new Date(),
                observations: "",
                subtotal: 0.0,
                taxable: 0.0,
                exempt: 0.0,
                vat: 0.0,
                total: 0.0,
                creator_id: this.props.user.id,
                enterprise_id: this.props.Id,
                products: [],
                type_payments: []
              }
            });
            this.props.onClose();
          })
          .catch(error => {
            if ((error || {}).message) {
              Globals.showError(error.message);
            }

            if (error.hasOwnProperty("msg")) {
              Globals.showError(error.msg);


            }

            this.componentDidMount();
            this.close();
            this.onCloseShowSerials();

            this.setState({ submitted: false, errors: error });
          });
        break;
      case false:
        Request.updateRequest(form)
          .then(async response => {
            const { code } = response;

            this.setState(this.defaultState);
            Globals.showSuccess(`¡Pedido Nº ${code} editado exitosamente!`);
            await this.setState({
              form: {
                id: "",
                forthright: false,
                warehouse_id: "",
                branch_id: "",
                client_id: "",
                code: "",
                currency_id: "",
                date_emission: new Date(),
                observations: "",
                subtotal: 0.0,
                taxable: 0.0,
                exempt: 0.0,
                vat: 0.0,
                total: 0.0,
                creator_id: this.props.user.id,
                enterprise_id: this.props.Id,
                products: []
              }
            });
            this.props.onClose();
          })
          .catch(error => {
            if ((error || {}).message) {
              Globals.showError(error.message);
            }

            if (error.hasOwnProperty("msg")) {
              Globals.showError(error.msg);
            }

            this.setState({ submitted: false, errors: error, visiblePayment: false });
          });
        break;
      default:
        break;
    }
  };

  close = () => {
    this.setState(
      {
        visibleCreate: false,
        visibleRequest: false,
        visibleProcessing: false
      }
    );
  };

  maybeLoadId = () => {
    const { Id } = this.props;

    Request.generateCodeRequest({ Id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));
        // setTimeout(
        //   function() {
        //       this.maybeLoadId();
        //   }
        //   .bind(this),
        //   3000
        // );
      })
      .catch(() => Globals.showError());
  };

  removeAttribute = () => {
    this.setState({ showCustomer: false }, () => this.props.removeAttribute());
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };

  onSuccess = async item => {
    let products = [...this.state.form.products];
    const index = products.findIndex(i => i.id == this.state.item_serial.id);
    products[index].serials = item;
    products[index].quantity = item.length;
    await this.setState({
      edit_serial: false,
      form: {
        ...this.state.form,
        products: products
      }
    });
    this.handleNum(
      {
        target: {
          value: item.length,
          name: "quantity"
        }
      },
      index
    );
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  closePayment = () => {
    this.setState({
      visiblePayment: false,
    });
  };

  showSerials = item => {
    const { form } = this.state;
    let creator_id = this.props.user.id;
    let product_id = item.id;
    let request = {
      creator_id: creator_id,
      warehouse_id: form.warehouse_id,
      product_id: product_id,
    };

    //request.push({ creator_id: creator_id }, { warehouse_id: form.warehouse_id }, { product_id: product_id });

    axios
      .post("admin/deliver-notes/getserialsavailable", request)
      .then(res => {
        if (res.data?.serialsAvailable?.length > 0) {
          if (res.data?.serialsCompromised > 0) {
            if (res.data.serialsCompromised == 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidad comprometida en un PEDIDO.'
              });
            }
            else if (res.data.serialsCompromised > 1) {
              this.setState({
                serialsCompromised: 'Tiene ' + res.data.serialsCompromised + ' unidades comprometidas en un PEDIDO.'
              });
            }
          }
          this.setState({
            show_serials: true,
            item_serial: item,
            serials_available: res.data.serialsAvailable,
          });
        }
        else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      });


  }

  onCloseShowSerials = () => {
    this.setState({
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    });
  }

  render() {
    const { currencies, customers, branches, Id, havePermission, user } = this.props;
    const {
      dates,
      form,
      search,
      submitted,
      showCustomer,
      suggestions,
      warehouses,
      create,
      edit_serial,
      item_serial,
      suggestions_client,
      client_selected,
      show_serials,
      serials_available,
      serialsCompromised,
      visiblePayment,
    } = this.state;
    
    const showDiscountSale = user.role === Constants.ROLE_SELLER &&
      user.configuration_seller.type_percentage === Constants.DISCOUNT_TYPES.bySale ? true : false;

    return (
      <React.Fragment>
        {edit_serial ? (
          <React.Fragment>
            <ModalScan
              onSuccess={this.onSuccess}
              onCancel={this.onCancel}
              serials={item_serial.serials}
            />
          </React.Fragment>
        ) : (
            <form onSubmit={this.handleSubmit}>
              {showCustomer ? (
                <FormClient
                  Id={Id}
                  back
                  customer={Customer =>
                    this.setState({ showCustomer: false }, () => {
                      this.props.newCustomer(Customer);
                    })
                  }
                  removeAttribute={() => this.removeAttribute}
                />
              ) : (
                  <div className="container-create-edit-request">
                    {/* {create && (
                  <div className="row text-left">
                    <div className="col-md-6">
                      <div className="form-group">
                        <div className="form-check">
                          <input
                            id="forthright"
                            type="checkbox"
                            name="forthright"
                            value={this.state.form.forthright}
                            onClick={() => {
                              let boolean = this.state.form.forthright;

                              this.setState(state => ({
                                form: {
                                  ...state.form,
                                  forthright: !boolean,
                                  date_emission: !boolean ? moment.now() : ""
                                }
                              }));
                            }}
                            className="form-check-input"
                          />
                          <label className="form-check-label" htmlFor="forthright">
                            Nota de entrega
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                )} */}
                    <div className="row">
                      <div className="col-md">
                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                          {/* <Select
                            color="gray"
                            label="Cliente"
                            name="client_id"
                            options={customers.map(Item => {
                              return {
                                value: Item.client_id,
                                label:
                                  (Item.person || {}).first_name ||
                                  (Item.client || {}).name
                              };
                            })}
                            disabled={!create}
                            value={form.client_id}
                            error={`${this.hasErrorFor("client_id") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("client_id")}
                            onChange={this.handleChange}
                            icon={
                              create && havePermission ? (
                                <Button
                                  color="blue"
                                  type="button"
                                  title="Agregar otro"
                                  small="true"
                                  onClick={() => {
                                    let inverseShow = !showCustomer;
                                    this.setState(
                                      { showCustomer: inverseShow },
                                      () => {
                                        this.props.removeAttribute();
                                      }
                                    );
                                  }}
                                >
                                  <Icon name={showCustomer ? "minus" : "plus"} />
                                </Button>
                              ) : null
                            }
                          /> */}
                          <div style={{ flex: 1 }}>
                            <Input
                              color=" "
                              disabled={client_selected !== ''}
                              name="search_client"
                              placeholder="Buscar por nombre, cédula o rif"
                              label="Buscar cliente"
                              className="material"
                              labelClass="material"
                              // value={
                              //   this.state.form.client_id !== '' && this.state.search_client === ''
                              //     ? this.state.form.client_id
                              //     : this.state.search_client
                              // }
                              value={
                                client_selected !== '' && this.state.search_client === ''
                                  ?
                                  client_selected
                                  :
                                  this.state.form.client_id !== '' ? this.state.form.client_id
                                    : this.state.search_client
                              }
                              onChange={this.handleChange}
                              error={`${this.hasErrorFor("client_id") ? "is-invalid" : ""
                                }`}
                              invalidfeedback={this.renderErrorFor("client_id")}
                              // icon={
                              //   create && havePermission ? (
                              //     <Button
                              //       color=" "
                              //       type="button"
                              //       title="Agregar otro"
                              //       small="true"
                              //       onClick={() => {
                              //         let showCustomer = !this.state.showCustomer;
                              //         this.setState(
                              //           { showCustomer: showCustomer },
                              //           () => {
                              //             this.props.removeAttribute();
                              //           }
                              //         );
                              //       }}
                              //     >
                              //       <Icon
                              //         name={this.state.showCustomer ? "minus" : "plus"}
                              //         style={{backgroundColor: '5c068c'}}
                              //       />
                              //     </Button>
                              //   ) : null
                              // }
                            />
                          </div>
                          {!!client_selected && (
                            <div>
                              <Button
                                color="primary"
                                type="button"
                                title="Seleccionar otro"
                                small="true"
                                onClick={() => {
                                  this.setState(s => ({
                                    client_selected: '',
                                    search_client: '',
                                    form: {
                                      ...s.form,
                                      client_id: '',
                                    }
                                  }));
                                }}
                              >
                                <Icon name="minus" />
                              </Button>
                            </div>
                          )}
                        </div>
                        <div id="suggestion">
                          {suggestions_client.length > 0
                            ? suggestions_client.map((Item, key) => {
                              return (
                                <div
                                  key={key}
                                  onClick={() => {
                                    this.takeClient(Item);
                                  }}
                                >
                                  { Item.person.identity_document
                                    ?
                                    Item.person.first_name + ' ' + Item.person.identity_document
                                    :
                                    Item.person.first_name + ' ' + Item.person.fiscal_identification}
                                </div>
                              );
                            })
                            : null}
                        </div>

                      </div>
                      <div className="col-md">
                        <Input
                          type="text"
                          color=" "
                          value={form.code}
                          placeholder="Código alfanumérico"
                          name="code"
                          maxLength="10"
                          error={`${this.hasErrorFor("code") ? "is-invalid" : ""}`}
                          invalidfeedback={this.renderErrorFor("code")}
                          disabled
                          label={
                            form.forthright
                              ? "Nro. de Nota de Entrega"
                              : "Nro. de Pedido"
                          }
                          className="material"
                          labelClass="material"
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md">
                        <Select
                          color="white"
                          label="Divisa"
                          name="currency_id"
                          className="material"
                          labelClass="material"
                          options={currencies}
                          disabled={!create}
                          value={form.currency_id}
                          error={`${this.hasErrorFor("currency_id") ? "is-invalid" : ""
                            }`}
                          invalidfeedback={this.renderErrorFor("currency_id")}
                          onChange={this.handleChange}
                        />
                      </div>
                      <div className="col-md">
                        <DatePicker
                          color="white"
                          className="material"
                          label={
                            form.forthright
                              ? "Fecha de emisión"
                              : "Fecha de registro"
                          }
                          maxDate={dates.maxRequestDate}
                          value={form.date_emission}
                          disabled={!create || form.forthright}
                          onChange={value => {
                            const name = "date_emission";
                            this.setState(state => ({
                              form: {
                                ...state.form,
                                [name]: value
                              }
                            }));
                          }}
                        />
                      </div>
                    </div>
                      <div className="row">
                        <div className="col col-md">
                          <Select
                            color="white"
                            name="branch_id"
                            label="Sucursal"
                            className="material"
                            labelClass="material"
                            value={form.branch_id}
                            options={branches}
                            error={`${this.hasErrorFor("branch_id") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("branch_id")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col col-md">
                          <Select
                            color="white"
                            name="warehouse_id"
                            label="Almacen"
                            className="material"
                            labelClass="material"
                            onChange={this.handleChange}
                            value={this.state.form.warehouse_id}
                            options={warehouses}
                            error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("warehouse_id")}
                          />
                        </div>
                      </div>
                    {form.warehouse_id && (
                      <div className="row">
                        <div className="col col-md">
                          <Input
                            color=" "
                            name="search"
                            placeholder="Introduce el nombre"
                            label="Buscar Productos"
                            className="material"
                            labelClass="material"
                            value={search}
                            onChange={this.handleChange}
                            error={`${this.hasErrorFor("products") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("products")}
                          />
                          <div id="suggestion">
                            {suggestions.length > 0
                              ? suggestions.map((Item, key) => {
                                return (
                                  <div
                                    key={key}
                                    onClick={() => {
                                      this.takeProduct(Item);
                                    }}
                                  >
                                    {Item.code + " - " + Item.name}
                                  </div>
                                );
                              })
                              : null}
                          </div>
                        </div>
                        <div className="col col-md"></div>
                      </div>
                    )}

                    {/* {visiblePayment && (
                      <Modal title="Método de pago" onClose={this.closePayment} visible>
                        <Payment
                          isCash={this.isCash}
                          onClose={this.closePayment}
                          submitted={submitted}
                          form={form}
                          user={user}
                          showDiscountSale={showDiscountSale}
                          handlePayment={(emitter, key) => {
                            this.handleNumPayment(emitter, key);
                          }}
                          handleChange={emitter => {
                            this.handleChange(emitter);
                          }}
                          handleDiscount={(value, name) => {
                            this.handleDiscount(value, name)
                          }}
                          handleReferenceNumber={(value, key) => {
                            this.handleReferenceNumber(value, key)
                          }}
                          onClick={emitter => {
                            this.handleSubmit(emitter);
                          }}
                        />
                      </Modal>
                    )} */}

                    {form.products.length > 0 && (
                      <div className="row">
                        <div className="col-md">
                          <div className="form-group">
                            <div className="table-responsive table-request">
                              <table className="table table-bordered table-products table-sm">
                                <thead>
                                  <tr>
                                    <td colSpan="7" align="center">PRODUCTOS DE LA ORDEN</td>
                                  </tr>
                                  <tr style={{ whiteSpace: "nowrap" }}>
                                    <th>Código</th>
                                    <th>Nombre</th>
                                    <th>Cantidad</th>
                                    <th>Precio</th>
                                    <th>Total</th>
                                    <th>Acción</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {form.products.map((Item, key) => {
                                    console.log(Item)
                                    return (
                                      <tr key={key}>
                                        <td>{Item.code}</td>
                                        <td>{Item.name}</td>
                                        <td>
                                          {
                                            <input
                                              type="text"
                                              name="quantity"
                                              className="form-control-sm material"
                                              labelClass="material"
                                              value={Item.quantity}
                                              disabled={
                                                Item.serialization ===
                                                Constants.SERIALIZATION.ACTIVE
                                              }
                                              maxLength="10"
                                              onKeyPress={emitter => {
                                                Globals.soloNumeros(emitter);
                                              }}
                                              onChange={async emitter => {
                                                let products = [
                                                  ...this.state.form.products
                                                ];
                                                products[key].serials = null;
                                                this.setState({
                                                  edit_serial: false,
                                                  form: {
                                                    ...this.state.form,
                                                    products: products
                                                  }
                                                });
                                                this.handleNum(emitter, key);
                                              }}
                                            />
                                          }
                                        </td>
                                        <td>{Globals.formatMiles(Item.price)}</td>
                                        <td>
                                          {Globals.formatMiles(Item.subtotal)}
                                        </td>
                                        <th>
                                          <div className="container-btn-actions">
                                            <Button
                                              color="red"
                                              small="true"
                                              title="Remover"
                                              type="button"
                                              onClick={() => {
                                                this.handleClick(Item);
                                              }}
                                            >
                                              <Icon name="trash" />
                                            </Button>
                                            {/*
                                              Boton de serialización, se muestra solo si se puede serializar el producto
                                            */}
                                            {Item.serialization ===
                                              Constants.SERIALIZATION.ACTIVE && (
                                                <Button
                                                  color={
                                                    !Item.serials
                                                      ? "yellow"
                                                      : "green"
                                                  }
                                                  small="true"
                                                  title={
                                                    !Item.serials
                                                      ? "Serializar"
                                                      : "Serializado"
                                                  }
                                                  type="button"
                                                  className="btn-actions-orders"
                                                  onClick={() => {
                                                    this.handleSerial(Item);
                                                  }}
                                                >
                                                  <Icon name="list" />
                                                </Button>
                                              )}

                                            {
                                              /*Boton de mostrar seriales disponibles, se muestra solo si se puede serializar el producto*/
                                            }
                                            {Item.serialization ==
                                              Constants.SERIALIZATION.ACTIVE && (
                                                <Button
                                                  color={"blue"}
                                                  small="true"
                                                  title={"Ver seriales disponibles"}
                                                  type="button"
                                                  className="btn-actions-orders"
                                                  onClick={() => {
                                                    this.showSerials(Item);
                                                  }}
                                                >
                                                  <Icon name="eye" />
                                                </Button>
                                              )}

                                          </div>
                                        </th>
                                      </tr>
                                    );
                                  })}

                                  <tr>
                                    <td colSpan="3"></td>
                                    <td><b>TOTAL</b></td>
                                    <td><b>{Globals.formatMiles(form.total)}</b></td>
                                    <td>&nbsp;</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {
                      (show_serials && serials_available) &&
                      <div className="row">
                        <div className="col-md-12">
                          <Modal
                            className={"modal-serials-inputs"}
                            title="Seriales disponibles"
                            //onSuccess={this.props.onSuccess}
                            onClose={this.onCloseShowSerials}
                            visible
                          >
                            {serialsCompromised != '' &&
                              <span className="text-danger">
                                <strong> <small> {serialsCompromised} </small>  </strong>
                              </span>
                            }
                            <div className="modal-serials-inputs">
                              {serials_available.map((item, index) => (
                                <div className="col-md-12" key={index}>
                                  <InputGroup
                                    label="Hola"
                                    color="gray"
                                    type="text"
                                    value={item.serial}
                                    disabled={true}
                                  />
                                </div>
                              ))}
                            </div>

                          </Modal>
                        </div>
                      </div>
                    }

                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          color=" "
                          value={form.observations}
                          name="observations"
                          label="Observaciones"
                          className="material"
                          labelClass="material"
                          onChange={this.handleChange}
                          error={`${this.hasErrorFor("observations") ? "is-invalid" : ""
                            }`}
                          invalidfeedback={this.renderErrorFor("observations")}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md d-flex justify-content-center">
                        {submitted ? (
                          <div
                            className="spinner-border text-primary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                        ) : (
                            <div id="button" className="d-flex justify-content-center">
                              <Button
                                type="submit"
                                color=" "
                                className="primary"
                                //onClick={() => this.submit()}
                                disabled={submitted}
                              >
                                <span>{this.state.textButton}</span>
                              </Button>
                            </div>
                            // <div id="button">
                            //   <Button
                            //     block="true"
                            //     type="button"
                            //     disabled={submitted || form.products.length === 0}
                            //     onClick={() => {
                            //       if (!form.client_id) {
                            //         Globals.showWarning(
                            //           `¡Debe seleccionar el cliente antes de procesar la venta!`
                            //         );
                            //         return;
                            //       }

                            //       this.setState({ visiblePayment: true });
                            //     }}
                            //   >
                            //     {this.state.textButton}
                            //   </Button>
                            // </div>
                          )}
                      </div>
                    </div>
                  </div>
                )}
            </form>
          )}
      </React.Fragment>
    );
  }
}

function arrayTransform(Items, warehouse_id, user) {
  return Items.map((Item, key) => {
    let query = Item.product_warehouse.find(
      Item => Item.warehouse_id === parseInt(warehouse_id)
    );

    const price_base =
      parseInt(Item.pivot.rate) === 1
        ? query.price
        : parseInt(Item.pivot.rate) === 2
          ? query.price_max
          : query.price_offer;

    let prices = []
    prices = [
      {
        value: 1,
        label: "Precio Min."
      }
    ]

    return {
      id: Item.id,
      name: Item.name,
      code: Item.code,
      warehouse_id: query.warehouse_id,
      prices: prices,
      stock_min: query.stock_min,
      quantity: Item.pivot.quantity,
      rate: Item.pivot.rate,
      price: price_base,
      price_base: query.price,
      price_max: query.price_max,
      price_offer: query.price_offer,
      exempt: Item.exempt,
      subtotal: Item.pivot.subtotal,
      vat: Item.pivot.vat,
      total: Item.pivot.total,
      stock: 0,
      serialization: Item.serialization
    };
  });
}

function taxableMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_ACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function exemptMap(Items) {
  return Items.filter(Item => Item.exempt === Constants.EXEMPT_INACTIVE).reduce(
    (value, product) => {
      return value + parseFloat(product.subtotal);
    },
    0
  );
}

function reduceMap(Items, name) {
  return Items.reduce((value, product) => {
    return value + parseFloat(product[name]);
  }, 0);
}

function FloatNum(number) {
  return number.toFixed(2);
}

CreateEditRequest.defaultState = {
  form: {
    warehouse_id: "",
    branch_id: "",
    customer_id: "",
    code: "",
    currency_id: "",
    date_emission: new Date(),
    observations: "",
    subtotal: 0,
    vat: 0,
    total: 0,
    creator_id: "",
    enterprise_id: "",
    products: [],
    type_payments: [],
  }
};

export default connect(state => {
  return {
    request: state.request,
    serials_request: state.serials_request
  }
})(CreateEditRequest);
