import React from "react";
import { connect } from "react-redux";
import moment from "moment";

import { Modal, Pagination, Table, Button, Icon } from "../../components";
import { Colors, Constants, Globals } from "../../utils";
import { Request, Payment } from "../../services";

// Components
import Menu from "../menu";
import FilterReport from "./filterFormReport";
import ViewReport from "./view_report";

const TABLE_HEADER = [
  "Nombre de Cliente",
  "Fecha de Pago",
  "Metodo de Pago",
  "Monto",
  "Estado",
  "Acciones"
];

class ReportPayment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      customers: [],
      payment: null,
      view: false,
      page: 1,
      current_page: 1,
      last_page: 1
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const zonesId = Globals.getZones(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);
    const getCustomers =
    user.role === Constants.ROLE_SELLER
      ? Request.getCustomersBySeller({
          zones_id: zonesId,
          enterprise_id: enterpriseId
        })
      : Request.getCustomersByEnterprise({ Id: enterpriseId });

    Promise.all([
      Payment.reportPayment(page, { Id: enterpriseId, user_id: user.id, role: user.role, warehouse_id: warehouse_id }),
      getCustomers
    ])
    .then(response => {
      const { current_page, data, last_page } = response[0];
      const customersMap = response[1];

      this.setState({
        customers: customersMap,
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);
    Payment.reportPayment(page, { ...form, Id: enterpriseId, user_id: user.id, role: user.role, warehouse_id: warehouse_id })
    .then(response => {
      const { current_page, data, last_page } = response;
      this.setState({
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  }

  loadMore = () => {
    const { user } = this.props;
    const { page, form } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);
    Payment.reportPayment(page, { ...form, Id: enterpriseId, user_id: user.id, role: user.role, warehouse_id: warehouse_id })
    .then(response => {
      const { current_page, data, last_page } = response;
      this.setState({
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  }

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Sin Usar",
          color: Colors.green
        };
        break;
      case 1:
        respuesta = {
          text: "Usado",
          color: Colors.red
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = () => {
    this.setState(
      {
        payment: null,
        view: false
      });
  };

  render() {
    const { data, last_page, page, customers } = this.state;
    const { user, history } = this.props;
    const Id = Globals.getUserId(user);

    return (
      <Menu history={history}>

        {this.state.view && (
          <Modal title="Ver Cobros" onClose={this.close} visible>
            <ViewReport payment={this.state.payment} />
          </Modal>
        )}

        <div id="payment">
          <div className="container-fluid">
            <FilterReport
              submitted={form => this.filterMap(form)}
              Id={Id}
              user={user}
              customers={customers.map((Item, key) => {
                return {
                  value: Item.client_id,
                  label:
                    (Item.client.person || {}).first_name ||
                    (Item.client || {}).name
                };
              })}
            />
            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Reporte de Cobros"
                  header={TABLE_HEADER}
                >

                  {data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {(Item.user.person || {}).first_name ||
                            (Item.user || {}).name} {Item.user.deleted_at ? " - Eliminado" : ""}
                        </td>
                        <td>
                          {moment(Item.created_at).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          { Globals.getPaymentsType((Item || {}).type)}
                        </td>
                        <td>
                          { Globals.formatMiles(Item.amount) }
                        </td>
                        <td style={{ color: this.getStatus(Item.status).color }}>
                          {this.getStatus(Item.status).text}
                        </td>
                        <td>
                        {Item.expenses.length > 0
                          ? <Button
                            title="Ver Cobros"
                            small="true"
                            onClick={() =>
                              this.setState({ view: true, payment: Item })
                            }
                          >
                            <Icon name="eye" />
                          </Button> :
                           null}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={last_page}
                  active={page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => {
                        this.loadMore();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ReportPayment);
