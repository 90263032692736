import { Constants } from '../utils';

const currency = (state = Constants.CURRENCIES.DOLARES, action) => {
	switch(action.type) {
		case 'SET_CURRENCY':
			return action.payload;
		default:
			return state;
	}
}

export default currency;