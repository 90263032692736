import React from "react";
import moment from "moment";
import { connect } from "react-redux";
import { Globals, Constants } from "../../../utils";
import PayDebt from './pay-debt';
import { Pagination, Button, Modal, Table, Icon } from "../../../components";
import {
  DebtsToPay as DebtsToPayService,
  Provider,
  Bank
} from "../../../services";


// Components
import Menu from "../../menu";
import View from "./view";
import Filter from "./filterForm";


const TABLE_HEADER = [
    "Nro. de Factura",
    "Nro. de Control",
    "Proveedor",
    "Fecha Emision",
    "Abonado",
    "Total",
    "Acciones"
  ];
  
  class DebtsToPay extends React.Component {
    constructor(props) {
      super(props);
  
      this.state = {
        data: [],
        page: 1,
        Item: "",
        current_page: 1,
        last_page: 1,
        visiblePurchase: false,
        visibleForm:false,
        visibleDepositForm:false,
        showLg: true,
        providers: [],
        banks: []
      };
    }
    componentDidMount() {
        this.load();
    }

    load = () => {
        
        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        Promise.all([
            Provider.getProviders({ Id: enterpriseId }),
            DebtsToPayService.getDebtsToPay(page, {
              enterprise_id: enterpriseId
            }),
            Bank.getBanks({enterprise_id: enterpriseId})
        ])
        .then(response => {
            const { current_page, data, last_page } = response[1];

            this.setState({
                providers: response[0],
                banks: response[2],
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    loadMore = () => {

        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

        DebtsToPayService.getDebtsToPay(page, {
            enterprise_id: enterpriseId,
        })
        .then(response => {
            const { current_page, data, last_page } = response;

            this.setState({
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    filterMap = form => {

        const { user } = this.props;
        const { page } = this.state;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    
        DebtsToPayService.getDebtsToPay(page, {
            enterprise_id: enterpriseId,
            ...form
        })
        .then(response => {
            const { current_page, data, last_page } = response;

            this.setState({
                data: data,
                current_page: current_page,
                last_page: last_page
            });
        })
        .catch(error => {
            Globals.showError();
        });
    };
    
    close = () => {
        this.setState({
            visiblePurchase: false,
            visibleForm:false,
            visibleDepositForm:false
        },() => {
            this.load();
        });
    };

    expensesIngressTotal = Item => {

      // if (Item.expenses_ingress.length > 0) {
      const total = parseFloat(Item.expenses_ingress.reduce((total, item) => total = total + item.amount,0)).toFixed(2);
      
      // if (total == 0) {
      //   total = "0,00"
      // }

      // }
      // var total = 0
      // if (Item.expenses_ingress.length > 0) {
      //   for (var i=0; i < Item.expenses_ingress.length; i++) {
      //     total=
      //   }
      // }else{
      //   total= "0,00"
      // }
      // const numbers = [1, 2, 3, 4, 5];

      return total;
    };

    deployPurchase = Item => {
        this.setState({ Item: Item, visiblePurchase: true });
    };

    deployForm = Item => {
      this.setState({ Item: Item, visibleForm: true });
    };
    
    deployDepositForm = Item => {
      this.setState({ Item: Item, visibleDepositForm: true });
    };

    rawProvider = Provider => {
        this.setState(state => ({
            showLg: true,
            providers: [...state.providers, Provider]
        }));
    };
    
    removeAttribute = () => {
        let showLg = !this.state.showLg;
        this.setState({ showLg: showLg });
    };

    render() {
        const {
            data,
            Item,
            providers,
            banks,
            visiblePurchase,
            visibleForm,
            visibleDepositForm,
        } = this.state;
        const { user, history } = this.props;
        const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
        const providersMapped = providers
        .filter(Item => Item.status === Constants.STATUS_ACTIVE)
        .map((Item, key) => {
          return {
            value: Item.id,
            label: Item.name,
            time_limit: Item.configuration_provider ? Item.configuration_provider.days_deadline : ''
          };
        })

        const banksMapped = banks
        .filter(Item => Item.status === Constants.STATUS_ACTIVE)
        .map((Item, key) => {
          return {
            value: Item.id,
            label: Item.name,
            type: Item.type
          };
        })
    
        return (
          <Menu history={history}>
   
            {visiblePurchase && (
              <Modal
                className="modal-order"
                title="Visualizar Factura"
                onClose={this.close}
                visible
              >
                <View Item={Item} />
              </Modal>
            )}
            {
              visibleForm && (
                <Modal
                  className='modal-order'
                  title='Pagar'
                  onClose={this.close}
                  visible>
                    <PayDebt 
                      enterpriseId={enterpriseId}
                      banks={banksMapped}
                      Item={Item}
                      details={Item.details}
                      user={user}
                      onClose={this.close}
                      expenses_ingress_class={Item.expenses_ingress_class}
                      />
                  </Modal>
              )

            }
            {
              visibleDepositForm && (
                <Modal
                  className='modal-order'
                  title='Abonar'
                  onClose={this.close}
                  visible>
                    <PayDebt
                      deposit={true}
                      enterpriseId={enterpriseId}
                      banks={banksMapped}
                      Item={Item}
                      details={Item.details}
                      user={user}
                      onClose={this.close}
                      expenses_ingress_class={Item.expenses_ingress_class}
                      />
                  </Modal>
              )

            }
            <div id="home">
              <div className="container-fluid">
                <Filter
                  submitted={form => this.filterMap(form)}
                  enterpriseId={enterpriseId}
                  providers={providersMapped}
                />
    
                <div className="row">
                  <div className="col-md">
                    <Table
                        data={data.length}
                        title="Cuentas por pagar"
                        header={TABLE_HEADER}
                    >
                      {data.map((Item, index) => {
                        
                        return (
                            <tr key={index}>
                                <td>
                                    {(Item || {}).bill_number || "-"}
                                </td>
                                <td>
                                    {(Item || {}).control_number || "-"}
                                </td>
                                <td>{Item.provider.name}</td>
                                <td>
                                    {moment(Item.date_emission || Item.created_at).format("DD/MM/YYYY")}
                                </td>
                                <td>
                                  {this.expensesIngressTotal(Item)}
                                </td>
                                <td>{Globals.formatMiles(Item.total)}</td>
                                <td>
                                    <Button
                                        color="primary"
                                        title="Ver mas"
                                        small="true"
                                        onClick={() => this.deployPurchase(Item)}
                                    >
                                        <Icon name="eye" />
                                    </Button>
                                    <Button
                                        color="info"
                                        title="Pagar"
                                        small="true"
                                        onClick={() => this.deployForm(Item)}
                                    >
                                        <Icon name="dollar" />
                                    </Button>
                                    <Button
                                        color="warning"
                                        title="Abonar"
                                        small="true"
                                        onClick={() => this.deployDepositForm(Item)}
                                    >
                                        <Icon name="dollar" />
                                    </Button>
                                </td>
                            </tr>
                        );
                      })}
                    </Table>
                  </div>
                </div>
    
                <div className="row my-3">
                  <div className="col-md">
                    <Pagination
                      pages={this.state.last_page}
                      active={this.state.page}
                      onChange={page => {
                        this.setState(
                          {
                            page: page
                          },
                          () => {
                            this.loadMore();
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Menu>
        );
    }
}

export default connect(state => {
    return {
      user: state.user,
      sidebar: state.sidebar
    };
  })(DebtsToPay);