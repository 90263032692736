import React from "react";

class Select extends React.Component {
 render() {
    return (
      <div className={`form-group ${this.props.size === "small" ? "m-0" : ""}`}>
        {this.props.label && (
          <label htmlFor={this.props.name} className={this.props?.labelClass}>
            {this.props.label} {this.props.icon}
          </label>
        )}
        <select
          {...this.props}
          className={`form-control${this.props.size === "small" ? "-sm" : ""} ${
            this.props.color ? this.props.color : ""
          } ${this.props.error ? this.props.error : ""}
          ${this.props.className ? this.props.className : ""}`}
        >
          <option
            value={this.props.defaultvalue !== undefined ? this.props.defaultvalue : ""}
            disabled={this.props.disabledFirst === undefined ? true : false}
          >
            {this.props.defaultname ? this.props.defaultname : "Seleccione"}
          </option>

          {this.props.options.map((i, index) => {
            if (i.optgroup) {
              return (
                <optgroup label={ i.label }>
                 {
                   i.options.map((i,index) => {
                     // Verifica si el campo 'status' existe y si es igual a 0
                      const isDisabled = i.status !== undefined && i.status === 0;
                      const isTruncated = this.props?.truncateLabel !== undefined && this.props?.truncateLabel > 0;
                      const truncatedLabel = isTruncated && String(i.label).length >= 30
                        ? String(i.label).substring(0,this.props.truncateLabel) + '...'
                        : i.label;

                      return (
                        <option
                          key={index}
                          value={i.value}
                          disabled={isDisabled} // Deshabilita la opción si 'status' es 0, de lo contrario, no se deshabilita
                        >
                          {(truncatedLabel || i.label) ?? i.name}
                        </option>
                      );
                   })
                 }
               </optgroup>
              )
            }
            else {
              // Verifica si el campo 'status' existe y si es igual a 0
              const isDisabled = i.status !== undefined && i.status === 0;
              const isTruncated = this.props?.truncateLabel !== undefined && this.props?.truncateLabel > 0;
              const truncatedLabel = isTruncated && String(i.label).length >= 30
                ? String(i.label).substring(0,this.props.truncateLabel) + '...'
                : i.label;

              return (
                <option
                  key={index}
                  value={i.value}
                  disabled={isDisabled} // Deshabilita la opción si 'status' es 0, de lo contrario, no se deshabilita
                >
                  {(truncatedLabel || i.label) ?? i.name}
                </option>
              );
            }            
          })}
        </select>
        {this.props.caret ? (
          this.props.caret
        ) : (
          <i
            className="fa fa-regular fa-chevron-down"
            style={{position: 'absolute', bottom: 10, right: 10, fontSize: 20, color: 'gray'}}
          />
        )}
        {this.props.invalidfeedback}
      </div>
    );
 }
}

export default Select;
