import React from "react";
import moment from "moment";
import NumberFormat from "react-number-format";
import {
  Button,
  Select,
  Input,
  InputFormat,
  DatePicker,
  Textarea,
  Icon
} from "../../components";
import { Globals, Constants } from "../../utils";
import { Bank, ExpensesIngress as ExpensesIngressService } from "../../services";
// Components
import FormProvider from "./formProvider";

const PAYMENT_TYPE_LIST = [
  { value: 1, label: "Contado" },
  { value: 2, label: "Credito" }
];

const METHOD_TYPE_LIST = [
  { value: 1, label: "Efectivo" },
  { value: 2, label: "Credito" },
  { value: 3, label: "Debito" },
  { value: 4, label: "Transferencia" }
];

const EXEMPT_LIST = [
  { value: 0, label: "NO" },
  { value: 1, label: "SI" }
];

var timer = null;
const IVA = 0.16;

class CreateEditExpensesIngress extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      create: props.Item ? false : true,
      showProvider: false,
      form: {
        id: "",
        provider_id: "",
        code: "",
        bill_number: "",
        control_number: "",
        payment_type: "",
        method_type: "",
        bank_id: "",
        bank_account_id: "",
        balance: 0,
        bill_date_emission: new Date(),
        bill_date_received: new Date(),
        description: "",
        subtotal: 0.0,
        vat: 0.0,
        total: 0.0,
        time_limit: "",
        creator_id: props.user.id,
        enterprise_id: props.enterpriseId,
        products: [],
        reference_number: ""
      },
      dates: {
        minEmissionDate: moment.now(),
        minReceptionDate: moment.now(),
        minExpiredDate: moment.now()
      },
      errors: [],
      banks: props.banks,
      bank_accounts: [],
      textButton: "Crear"
    };
  }

  componentDidMount() {
    const { enterpriseId, user } = this.props;

    this.setState(state => ({
      form: {
        ...state.form,
        enterprise_id: enterpriseId,
        creator_id: user.id
      }
    }));

    if (this.props.Item && !this.state.create) {
      const { Item } = this.props;
      this.maybeLoadData(Item);
    } else {
      this.maybeLoadId(enterpriseId);
    }

    //localStorage.removeItem('state');
    this.loadFormState();

  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.bank_id !== this.state.form.bank_id &&
      this.state.form.bank_id
    ) {
      this.maybeLoadBankAccounts();
    }
  }

  maybeLoadId = Id => {
    ExpensesIngressService.getId({ enterprise_id: Id })
      .then(response => {
        this.setState(state => ({
          form: {
            ...state.form,
            code: response
          }
        }));
      })
      .catch(error => {
        Globals.showError();
      });
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    let { form, submitted, create } = this.state;

    if (submitted) {
      return;
    }

    // Filtrando productos para valores nulos
    const findValuesNull = form.products.filter(
      Item => Item.amount <= 0 || Item.amount === ""
    );
    // Filtrando productos para costos nulos
    const findCostNull = form.products.filter(
      Item => parseInt(Item.total) <= 0 || parseInt(Item.total) === ""
    );

    if (findValuesNull.length > 0) {
      Globals.showError("¡Los productos/servicios deben tener una cantidad mayor a 0!");
      return;
    } else if (findCostNull.length > 0) {
      Globals.showError("¡Los productos/servicios deben tener un costo mayor a 0!");
      return;
    } else if (form.products.length === 0) {
      Globals.showError("¡Debe agregar productos/servicios a la factura!");
      return;
    } else if (form.total <= 0) {
      Globals.showError("¡La factura debe tener un valor total mayor a 0!");
      return;
    } else if (!form.date_emission) {
      Globals.showError("¡La factura debe tener una fecha de Emisión!");
      return;
    } else if (!form.date_received) {
      Globals.showError("¡La factura debe tener una fecha de Recepción!");
      return;
    } else if (!form.date_expired) {
      Globals.showError("¡La factura debe tener una fecha de Expiración!");
      return;
    }

    form.date_emission_format = moment(form.date_emission).format('YYYY-MM-DD');
    form.date_received_format = moment(form.date_received).format('YYYY-MM-DD');
    form.date_expired_format = moment(form.date_expired).format('YYYY-MM-DD');

    // Vaciando los campos de errores al enviar solicitud
    this.setState({ submitted: true, errors: [] });

    switch (create) {
      case true:
        // Registrar orden
        ExpensesIngressService.createExpensesIngress(form)
          .then(response => {
            Globals.showSuccess(
              `Factura creada exitosamente!`
            );
            localStorage.removeItem('state'); //dejo el state del storage vacío
            this.props.onClose();
          })
          .catch(error => {

            // if ("bill_number" in error) {
            //   Globals.showError(error.bill_number[0]);
            // }
            // if ("control_number" in error) {
            //   Globals.showError(error.control_number[0]);
            // }
            if ("msg" in error) {
              Globals.showError(error.msg)
            }
            this.setState({ submitted: false, errors: error });
          });
        break;
      default:
        break;
    }
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;
    const { banks, providers } = this.props;
    const { bank_accounts } = this.state;

    switch (name) {
      case "provider_id":
        const { time_limit } = providers.find(provider => provider.value === parseInt(value));
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            time_limit: time_limit
          }
        }));
        break;
      case "payment_type":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_id: "",
            bank_account_id: "",
            balance: 0
          },
          banks: banks,
          bank_accounts: []
        }));
        break;
      case "reference_number":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          },
        }))
        break;
      case "method_type":
        let banksMapped = []
        if (value && parseInt(value) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          banksMapped = banks.filter(Item => Item.type === Constants.TYPE_BANKS_LIST.CASH_ONLY)
        } else {
          banksMapped = banks.filter(Item => Item.type !== Constants.TYPE_BANKS_LIST.CASH_ONLY)
        }
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_id: "",
            bank_account_id: "",
            balance: 0
          },
          banks: banksMapped,
          bank_accounts: []
        }));
        break;
      case "bank_id":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_account_id: "",
            balance: 0
          },
        }), () => this.maybeLoadBankAccounts());
        break;
      case "bank_account_id":
        const bankAccount = bank_accounts.find(Item => Item.value === parseInt(value));
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            balance: parseFloat(bankAccount.balance)
          },
        }));
        break;
      default:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          }
        }));
        break;
    }
  };

  loadFormState = () => {
    let { form } = this.state;
    try {
      let serializedData = localStorage.getItem('state')
      if (serializedData === null) {
        return undefined // Si no existe el state en el local storage devolvemos undefined para que cargue el state inicial que hayamos definido
      }

      serializedData = JSON.parse(serializedData);
      console.log("storage.form.state: ", serializedData) // Si encontramos con exito nuestro storage lo devolvemos.
      // Convertimos las fechas de string a Date
      serializedData.bill_date_emission = Date.parse(serializedData.bill_date_emission);
      serializedData.bill_date_received = Date.parse(serializedData.bill_date_received);
      serializedData.date_emission = Date.parse(serializedData.date_emission);
      serializedData.date_expired = Date.parse(serializedData.date_expired);
      serializedData.date_received = Date.parse(serializedData.date_received);

      this.setState({ form: serializedData });

      form.provider_id = serializedData.provider_id;
      form.code = serializedData.code;
      form.date_emission = serializedData.date_emission;
      form.date_received = serializedData.date_received;
      form.date_expired = serializedData.date_expired;

      console.log("form.state: ", form);

    } catch (error) {
      return undefined // Si ocurre algun error, devuelvo undefined para cargar el state inicial.
    }
  }

  addProduct = () => {
    const { form } = this.state

    const newProduct = {
      description: "",
      price: 0.0,
      amount: 0,
      exempt: 0,
      subtotal: 0.0,
      vat: 0.0,
      total: 0.0
    }

    const rawProducts = [...form.products, newProduct]

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.calcAmounts(rawProducts)
    );

    this.saveExpensesIngress();

  }

  saveExpensesIngress() {
    const { form } = this.state;
    // this.props.dispatch({
    //   type: 'SET_EXPENSESINGRESS',
    //   payload: {
    //     ...this.state.form
    //   }
    // });
    try {

      console.log('saveExpressIngress >> state.form: ', form);
      const serializedData = {
        id: form.id,
        provider_id: form.provider_id,
        code: form.code,
        bill_number: form.bill_number,
        control_number: form.control_number,
        payment_type: form.payment_type,
        method_type: form.method_type,
        bank_id: form.bank_id,
        bank_account_id: form.bank_account_id,
        balance: form.balance,
        bill_date_emission: form.bill_date_emission,
        bill_date_received: form.bill_date_received,
        description: form.description,
        subtotal: form.subtotal,
        vat: form.vat,
        total: form.total,
        time_limit: form.time_limit,
        creator_id: form.creator_id,
        date_emission: form.date_emission,
        date_expired: form.date_expired,
        date_received: form.date_received,
        enterprise_id: form.enterprise_id,
        products: form.products,
        reference_number: form.reference_number
      };


      console.log('saveExpressIngress >> serializedData: ', serializedData);
      console.log('serializedData parse to json: ', JSON.stringify(serializedData));
      localStorage.setItem('state', JSON.stringify(serializedData));
    } catch (error) {
      console.log(error);
    }
  }

  handleNum = (emitter, key) => {

    // console.log(Globals.formatMiles(emitter.target.value));
    const { name, value } = emitter.target;
    const { form } = this.state;

    // value = value.replace(/,/g, "");
    // console.log(value.replace(/\./g, ""))

    let newValue = value ? parseInt(value.replace(/\./g, "")) : value;
    let valueWithDecimal = value.replace(/[^0-9,.]/g, '').replace(/,/g, '.');
    let rawProducts = [...form.products];

    switch (name) {
      case "description":
        rawProducts[key] = {
          ...rawProducts[key],
          description: value
        }
        break;
      case "exempt":
        let exemptValue = parseInt(value.replace(/\./g, ""))
        rawProducts[key] = {
          ...rawProducts[key],
          exempt: exemptValue,
          vat: FloatNum(exemptValue ? 0 : rawProducts[key].subtotal * IVA),
          total: FloatNum(
            exemptValue
              ? rawProducts[key].subtotal
              : rawProducts[key].subtotal * IVA + rawProducts[key].subtotal
          )
        }
        break;
      case "amount":
        let amountValue =
          newValue > 0 && newValue !== ""
            ? newValue * rawProducts[key].price
            : 0.0;

        rawProducts[key] = {
          ...rawProducts[key],
          amount: newValue,
          subtotal: amountValue,
          vat: FloatNum(rawProducts[key].exempt ? 0 : amountValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt
              ? amountValue
              : amountValue * IVA + amountValue
          )
        };
        break;
      case "price":
        console.log(valueWithDecimal)
        let unitValue =
          valueWithDecimal > 0 && valueWithDecimal !== ""
            ? rawProducts[key].amount * valueWithDecimal
            : 0.0;

        rawProducts[key] = {
          ...rawProducts[key],
          price: valueWithDecimal,
          subtotal: unitValue,
          vat: FloatNum(rawProducts[key].exempt ? 0 : unitValue * IVA),
          total: FloatNum(
            rawProducts[key].exempt ? unitValue : unitValue * IVA + unitValue
          )
        };
        break;
      default:
        return;
    }

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.calcAmounts(rawProducts)
    );
  };

  handlePrice = (floatValue, key, name) => {
    const { form } = this.state;
    const rawProducts = [...form.products];

    rawProducts[key] = {
      ...rawProducts[key],
      [name]: floatValue
    };

    this.setState(
      state => ({
        form: {
          ...state.form,
          products: rawProducts
        }
      }),
      () => this.calcAmounts(rawProducts)
    );
  };

  calcAmounts = rawProducts => {
    this.setState(state => ({
      form: {
        ...state.form,
        subtotal: FloatNum(reduceMap(rawProducts, "subtotal")),
        vat: FloatNum(reduceMap(rawProducts, "vat")),
        total: FloatNum(reduceMap(rawProducts, "total"))
      }
    }));
  };

  maybeLoadData = Item => {
    const { details } = Item;

    const rawProducts = arrayTransform(details);

    this.setState(state => ({
      form: {
        ...state.form,
        id: Item.id,
        enterprise_id: Item.enterprise_id,
        provider_id: Item.provider_id,
        bank_account_id: Item.bank_account_id,
        bill_number: Item.bill_number,
        control_number: Item.control_number,
        code: Item.code,
        date_emission: Item.date_emission,
        date_received: Item.date_received,
        date_expired: Item.date_expired,
        description: Item.description,
        subtotal: Item.subtotal,
        status: Item.status,
        vat: Item.iva,
        total: Item.total,
        products: rawProducts
      },
      textButton: "Procesar"
    }));
  };

  handleClick = (Product, key) => {
    const { form } = this.state;

    Globals.confirm(
      `¿Desea remover de la factura el producto: ${Product.description}?`,
      () => {
        const filterMap = form.products.filter((Item, index) => key !== index);

        this.setState(state => ({
          form: {
            ...state.form,
            products: filterMap,
            subtotal: FloatNum(reduceMap(filterMap, "subtotal")),
            vat: FloatNum(reduceMap(filterMap, "vat")),
            total: FloatNum(reduceMap(filterMap, "total"))
          }
        }));

        this.saveExpensesIngress(); //seteo el storage
      }
    );
  };

  removeAttribute = () => {
    this.setState({ showProvider: false }, () => this.props.removeAttribute());
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  maybeLoadBankAccounts = () => {
    const { form } = this.state

    Bank.getBankAccounts({ bank_id: form.bank_id })
      .then(response => {

        let bankAccountsMapped = [];

        if (form.method_type && parseInt(form.method_type) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .filter(Item => Item.type === Constants.TYPE_BANK_ACCOUNTS_LIST.CASH_ONLY)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? parseFloat(Item.last_balance.total) : 0
              };
            })
        } else {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? parseFloat(Item.last_balance.total) : 0
              };
            })
        }

        this.setState(state => ({
          form: {
            ...state.form,
            bank_account_id: "",
            balance: 0
          },
          bank_accounts: bankAccountsMapped
        }));

      })
      .catch(() => Globals.showError());
  }

  componentWillUnmount() {
    if (timer) {
      clearTimeout(timer);
    }
  }

  render() {
    const { providers, enterpriseId, havePermission, user } = this.props;
    const { banks, submitted, form, create, showProvider } = this.state;
    return (
      <React.Fragment>
        {
          <form onSubmit={this.handleSubmit}>
            {showProvider ? (
              <FormProvider
                enterpriseId={enterpriseId}
                user={user}
                provider={Provider =>
                  this.setState({ showProvider: false }, () => {
                    this.props.newProvider(Provider);
                  })
                }
                removeAttribute={() => this.removeAttribute}
              />
            ) : (
                <div className="text-center container-create-edit-order">
                  <div className="row">
                    <div className="col-md">
                      <Select
                        color="gray"
                        label="Proveedor"
                        name="provider_id"
                        options={providers}
                        disabled={!create}
                        value={this.state.form.provider_id}
                        error={`${this.hasErrorFor("provider_id") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("provider_id")}
                        onChange={this.handleChange}
                        icon={
                          create && havePermission ? (
                            <Button
                              color="blue"
                              type="button"
                              title="Agregar otro"
                              small="true"
                              onClick={() => {
                                let showProvider = !this.state.showProvider;
                                this.setState({ showProvider: showProvider }, () => {
                                  this.props.removeAttribute();
                                });
                              }}
                            >
                              <Icon
                                name={this.state.showProvider ? "minus" : "plus"}
                              />
                            </Button>
                          ) : null
                        }
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        type="text"
                        color="gray"
                        value={this.state.form.bill_number}
                        placeholder="Código alfanumérico"
                        name="bill_number"
                        maxLength="{10}"
                        error={`${this.hasErrorFor("bill_number") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("bill_number")}
                        label="Número de factura"
                        onChange={this.handleChange}
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        type="text"
                        color="gray"
                        value={this.state.form.control_number}
                        placeholder="Código alfanumérico"
                        name="control_number"
                        maxLength="{10}"
                        error={`${this.hasErrorFor("control_number") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("control_number")}
                        label="Número de control"
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <DatePicker
                        color="gray"
                        label="Fecha de Emisión"
                        value={this.state.form.date_emission}
                        onChange={value => {
                          console.log('value: ', value);
                          this.setState(state => ({
                            form: {
                              ...state.form,
                              date_emission: value,
                              date_received: "",
                              date_expired: ""
                            },
                            dates: {
                              ...state.dates,
                              minReceptionDate: value
                            }
                          }));
                        }}
                      />
                    </div>
                    <div className="col-md">
                      <DatePicker
                        color="gray"
                        label="Fecha de Recepción"
                        minDate={this.state.dates.minReceptionDate}
                        value={this.state.form.date_received}
                        onChange={value => {
                          const name = "date_received";
                          if (this.state.form.time_limit) {
                            const expired = new Date(value)
                            expired.setDate(expired.getDate() + parseInt(this.state.form.time_limit));
                            this.setState(state => ({
                              form: {
                                ...state.form,
                                [name]: value,
                                date_expired: expired
                              },
                              dates: {
                                ...state.dates,
                                minExpiredDate: value
                              }
                            }));
                          } else {
                            this.setState(state => ({
                              form: {
                                ...state.form,
                                [name]: value
                              },
                              dates: {
                                ...state.dates,
                                minExpiredDate: value
                              }
                            }));
                          }
                        }}
                      />
                    </div>
                    <div className="col-md">
                      <DatePicker
                        color="gray"
                        label="Fecha de Expiración"
                        disabled={this.state.form.time_limit ? true : false}
                        minDate={this.state.dates.minExpiredDate}
                        value={this.state.form.date_expired}
                        onChange={value => {
                          const name = 'date_expired'
                          this.setState(state => ({
                            form: {
                              ...state.form,
                              [name]: value
                            }
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4">
                      <Select
                        color="gray"
                        label="Tipo de Pago"
                        name="payment_type"
                        options={PAYMENT_TYPE_LIST}
                        value={this.state.form.payment_type}
                        error={`${this.hasErrorFor("payment_type") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("payment_type")}
                        onChange={this.handleChange}
                      />
                    </div>
                    {parseInt(this.state.form.payment_type) === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH && (
                      <div className="col-md-4">
                        <Select
                          color="gray"
                          label="Metodo de Pago"
                          name="method_type"
                          disabledFirst={false}
                          defaultname="Seleccione"
                          defaultValue=""
                          options={METHOD_TYPE_LIST}
                          value={this.state.form.method_type}
                          error={`${this.hasErrorFor("method_type") ? "is-invalid" : ""
                            }`}
                          invalidfeedback={this.renderErrorFor("method_type")}
                          onChange={this.handleChange}
                        />

                      </div>
                    )}
                    <div className="col-md-4">
                      <label>Agregar Productos/Servicios</label>
                      <Button
                        color="blue"
                        type="button"
                        title="Agregar"
                        small="true"
                        onClick={this.addProduct}
                      >
                        <Icon
                          name="plus"
                        />
                      </Button>
                    </div>
                  </div>
                  {
                    (parseInt(this.state.form.method_type)
                      ===
                      Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.TRANSFER)
                    && (
                      <div className="row">
                        <div className="col-md-4">
                          <Input
                            label="Numero de referencia"
                            name="reference_number"
                            value={this.state.form.reference_number}
                            color="gray"
                            onKeyPress={emitter =>
                              Globals.soloNumeros(emitter)
                            }
                            onChange={this.handleChange}
                          />
                        </div >
                      </div >

                    )
                  }
                  {
                    parseInt(this.state.form.payment_type) === Constants.EXPENSES_INGRESS_PAYMENT_TYPE_LIST.CASH &&
                    (
                      <div className="row">
                        <div className="col-md-4">
                          <Select
                            color="gray"
                            label="Banco"
                            name="bank_id"
                            disabledFirst={false}
                            defaultname="Seleccione"
                            defaultValue=""
                            options={banks}
                            value={this.state.form.bank_id}
                            error={`${this.hasErrorFor("bank_id") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("bank_id")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <Select
                            color="gray"
                            label="Cuenta Bancaria"
                            name="bank_account_id"
                            disabledFirst={false}
                            defaultname="Seleccione"
                            defaultValue=""
                            options={this.state.bank_accounts}
                            value={this.state.form.bank_account_id}
                            error={`${this.hasErrorFor("bank_account_id") ? "is-invalid" : ""
                              }`}
                            invalidfeedback={this.renderErrorFor("bank_account_id")}
                            onChange={this.handleChange}
                          />
                        </div>
                        <div className="col-md-4">
                          <InputFormat
                            color="gray"
                            disabled={true}
                            name="balance"
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale="2"
                            label="Saldo"
                            value={this.state.form.balance}
                            error={`${this.hasErrorFor("balance") ? "is-invalid" : ""}`}
                            invalidfeedback={this.renderErrorFor("balance")}
                          />

                        </div>
                      </div>
                    )
                  }
                  {form.products.length > 0 && (
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <div className="table-responsive table-orders">
                            <table className="table table-bordered table-products table-sm">
                              <thead>
                                <tr>
                                  <td
                                    colSpan={!create || form.forthright ? "11" : "10"}
                                  >
                                    PRODUCTOS/SERVICIOS DE LA FACTURA
                                  </td>
                                </tr>
                                <tr style={{ whiteSpace: "nowrap" }}>
                                  <th>Nombre/Descripción</th>
                                  <th>Cant.</th>
                                  <th>Cost x Ud.</th>
                                  <th>Exento</th>
                                  <th>Total</th>
                                  <th>Acción</th>
                                </tr>
                              </thead>
                              <tbody>
                                {form.products.map((Item, key) => {
                                  return (
                                    <tr key={key}>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          type="text"
                                          name="description"
                                          className="form-control-sm"
                                          value={Item.description}
                                          maxLength={100}
                                          onChange={async emitter => {
                                            let products = [...this.state.form.products];
                                            this.setState({
                                              form: {
                                                ...this.state.form,
                                                products: products
                                              }
                                            });
                                            this.handleNum(emitter, key)
                                          }}
                                        />
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        <input
                                          type="text"
                                          name="amount"
                                          className="form-control-sm"
                                          value={Item.amount}
                                          maxLength={8}
                                          onKeyPress={emitter =>
                                            Globals.soloNumeros(emitter)
                                          }
                                          onChange={async emitter => {
                                            let products = [...this.state.form.products];
                                            this.setState({
                                              form: {
                                                ...this.state.form,
                                                products: products
                                              }
                                            });
                                            this.handleNum(emitter, key)
                                          }}
                                        />
                                      </th>
                                      <th style={{ whiteSpace: "nowrap" }}>
                                        <NumberFormat
                                          className="form-control-sm"
                                          color="gray"
                                          disabled={false}
                                          name="price"
                                          thousandSeparator={true}
                                          allowNegative={false}
                                          // isNumericString={true}
                                          decimalSeparator={","}
                                          thousandSeparator={"."}
                                          decimalScale="2"
                                          maxLength={15}
                                          // onKeyPress={emitter =>
                                          //   Globals.soloNumeros(emitter)
                                          // }
                                          onChange={emitter =>
                                            this.handleNum(emitter, key)
                                          }
                                        /> 
                                      </th>
                                      <th>
                                        <Select
                                          color="gray"
                                          name="exempt"
                                          size="small"
                                          style={{ textTransform: "capitalize" }}
                                          defaultname="Seleccione"
                                          onChange={emitter => {
                                            this.handleNum(emitter, key);
                                          }}
                                          value={Item.exempt}
                                          options={EXEMPT_LIST}
                                        />
                                      </th>

                                      <th>{Globals.formatMiles(Item.subtotal)}</th>
                                      
                                      <th>
                                        <div className="container-btn-actions">
                                          <Button
                                            color="red"
                                            small="true"
                                            title="Remover"
                                            type="button"
                                            onClick={() => {
                                              this.handleClick(Item, key);
                                            }}
                                          >
                                            <Icon name="minus" />
                                          </Button>
                                        </div>
                                      </th>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md">
                      <Textarea
                        label="Observaciones"
                        name="description"
                        value={this.state.form.description}
                        error={`${this.hasErrorFor("description") ? "is-invalid" : ""
                          }`}
                        invalidfeedback={this.renderErrorFor("description")}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>

                  {form.products.length > 0 && (
                    <div className="row">
                      <div className="col-md">
                        <div className="form-group">
                          <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                              <thead>
                                <tr>
                                  <th style={{ width: "25%" }}>SUBTOTAL:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.subtotal)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>IVA:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.vat)}
                                  </td>
                                </tr>
                                <tr>
                                  <th style={{ width: "25%" }}>TOTAL:</th>
                                  <td className="text-left">
                                    {Globals.formatMiles(this.state.form.total)}
                                  </td>
                                </tr>
                              </thead>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="row">
                    <div className="col-md">
                      {submitted ? (
                        <div className="spinner-border text-primary" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      ) : (
                          <div id="button">
                            <Button block="true" type="submit" disabled={submitted}>
                              {this.state.textButton}
                            </Button>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
          </form>
        }
      </React.Fragment>
    );
  }
}

function arrayTransform(Items) {
  return Items.map((Item) => {

    return {
      id: Item.id,
      expenses_ingress_id: Item.expenses_ingress_id,
      description: Item.description,
      price: Item.price,
      amount: Item.amount,
      exempt: Item.exempt,
      subtotal: Item.subtotal,
      vat: Item.vat,
      total: Item.total
    };
  });
}

function reduceMap(Items, name) {
  return Items.reduce((value, product) => {
    return value + parseFloat(product[name]);
  }, 0);
}

function FloatNum(number) {
  return number.toFixed(2);
}

export default CreateEditExpensesIngress;
