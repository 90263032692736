import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import { Button, InputFormat } from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import $ from "jquery";

class Iva extends React.Component {
  state = {
    header: ["Monto", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    conversion: null,
    view: false,
    form: {
      user_id: this.props.user.id,
      percentage: 0,
      id: null,
    }
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  load = () => {
    Globals.setLoading();
    axios
      .post("admin/iva", this.state.form)
      .then(async res => {
        if (res.data) {
          await this.setState({
            data: res.data.iva,
            form: {
              ...this.state.form,
              id: res.data?.iva?.id,
              percentage: res.data?.iva?.percentage,
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  change = e => {
    // Limitar los números enteros a 8
    let nums = String(e.value).split('.', 2);
    let value = nums[0].substring(0, 8);
    value = `${value}${nums[1] ? `.${nums[1]}` : ''}`;

    this.setState({
      form: {
        ...this.state.form,
        percentage: value
      }
    });
  };

  submit = async () => {
    let param = { ...this.state.form }
    Globals.setLoading();
    axios.upload('admin/iva/edit', param)
      .then(res => {
        if(res.data.result) Globals.showSuccess('IVA registrado con exito')
        else Globals.showError(res.data.error)
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  }

  render() {
    return (
      <Menu history={this.props.history}>
        <section id="conversion">
          <div className="conversion-header">IVA %</div>
          <div className="conversion-form">
            <div className="label">Porcentaje:</div>
            <div>
              <InputFormat
                name="percentage"
                thousandSeparator={true}
                allowNegative={false}
                isNumericString={true}
                decimalScale="2"
                value={this.state.form.percentage}
                onValueChange={this.change}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              onClick={() => this.submit()}
            >
              Cargar
            </Button>
          </div>
        </section>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Iva);
