import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Input,
  DatePicker,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import { Payment } from "../../services";
import PrinterIcon from '../../assets/icons/printer.png';
import moment from "moment";

const QUANTITY_PER_PAGE = 30;

class Payments extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      header: [
        'Fecha de pago',
        'Pago a Factura',
        'Tipo','Vendedor',
        'Forma de Pago',
        'Monto'
      ],
      page: 1,
      last_page: 1,
      data: [],
      filteredData: [],
      form: {
        user_id: this.props.user.id,
        order_id: '',
        type: '',
        seller_id: "",
        payment_method_id: "",
        since: "",
        until: "",
      },
      sellers: [],
      paymentMethods: [],
      paymentTypes: [
        { value: 'Contado', label: 'Contado' },
        { value: 'Cuenta por cobrar / Abono', label: 'Cuenta por cobrar / Abono' },
        { value: 'Cuenta por cobrar / Pago inicial', label: 'Cuenta por cobrar / Pago inicial' },
      ],
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getSellers();
    this.getPaymentMethods();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    Globals.setLoading();
    let param = Globals.copy(this.state.form);
    param.user_id = this.props.user.id;

    axios
      .post("admin/cashreport", param)
      .then(async (res) => {
        if (res.data.payments) {
          await this.setState({ data: res.data.payments });
          this.setFiltered();
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

	getSellers = (page = 1) => {
		axios
      .post(`web/admin/sellers?page=${page}`, { user_id: this.props.user.id })
      .then(async ({ data }) => {
        await this.setState(state => ({
          sellers: [
            ...state.sellers,
            ...data.sellers.data,
          ]
        }));

        if (data.sellers.data.length === data.sellers.per_page) {
          this.getSellers(page + 1);
        }
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
	};

	getPaymentMethods = async () => {
    const { form } = this.state;
		try {
			const res = await Payment.getPaymentMethods({user_id: form.user_id, withoutPaginate: true});
			if (!res?.methods) throw new Error();
			this.setState({ paymentMethods: res.methods });

		} catch (error) {
			Globals.showError('Ocurrió un error al obtener los métodos de pago');
			console.log('getPaymentMethods -> catch: ', error)
		}
	};

  change = async (e) => {
    await this.setState({
      page: 1,
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });

    this.setFiltered();
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      page: 1,
      form: {
        user_id: this.props.user.admin_id || this.props.user.id,
        type: '',
        seller_id: "",
        payment_method_id: "",
        order_id: '',
        since: "",
        until: "",
      },
    }));
    this.load();
    //await this.setFiltered();
  };

  print = (data) => {
    let { form } = this.state;
    form.currency = Constants.CURRENCIES.DOLARES
    Globals.typeDownloadOptions(
      `Selecciona en que formato deseas que se exporte el reporte`,
      (type_download) => {
        this.setState(state => ({
          form: {
            ...state.form,
            type_download: type_download
          }
        }));
        axios
          .post("admin/reports/accounts-receivable/download", this.state.form)
          .then(res => {
            if (res.data.url_storage) {
              //const url = res.data.url;
              const url = `${ENV.BasePublic}${res.data.url_storage}`;
              const win = window.open(url, "_blank");
              win.focus();
            }
            else {
              Globals.showError('Ha ocurrido un error');
            }
          })
          .catch(err => {
            Globals.showError();
          });
      }
    );
  };

  close = (reload = false) => {
    this.setState({ modalPaymentDetails: false, modalAddPayment: false, selectedItem: null });
    if (reload) this.load();
  };

  setFiltered = () => {
    const { page, form, sellers, paymentMethods } = this.state;
    let data = Globals.copy(this.state.data);

    if (!!form.order_id) {
      data = data.filter(x => String(x.order_id).includes(form.order_id));
    }

    if (!!form.type) {
      data = data.filter(x => x.type === form.type);
    }

    if (!!form.seller_id) {
      const seller = sellers.find(x => x.id === parseInt(form.seller_id));
      data = data.filter(x => x.seller === seller.name);
    }

    if (!!form.payment_method_id) {
      const method = paymentMethods.find(x => x.id === parseInt(form.payment_method_id));
      data = data.filter(x => x.payment_method === method.name);
    }

    const filteredData = data.slice(((page - 1 ) * QUANTITY_PER_PAGE), QUANTITY_PER_PAGE * page);
    const last_page = data?.length > 0
      ? Math.ceil(data.length / QUANTITY_PER_PAGE)
      : 1;

    this.setState({ filteredData, last_page });
  };

  print = () => {
    let param = Globals.copy(this.state.form);
    param.user_id = this.props.user.id;

    axios
      .post('admin/cashreport/download', param)
      .then(res => {
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      })
      .catch(err => {
        console.log('Clientes -> download -> catch: ', err);
      });
  };

  render() {
    return (
      <Menu history={this.props.history}>
        <div id="products" className="new-reports">
          <div className="row">
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    page: 1,
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                  this.setFiltered()
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    page: 1,
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                  this.setFiltered()
                }}
              />
            </div>
            <div className="col-md-2">
              <Input
                color=" "
                name="order_id"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.form.order_id}
                placeholder="Buscar por factura"
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="type"
                label="Tipo"
                className="filter"
                style={{ textTransform: "capitalize" }}
                disabledFirst={false}
                onChange={this.change}
                value={this.state.form.type}
                options={this.state.paymentTypes}
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="seller_id"
                label="Vendedor"
                className="filter"
                style={{ textTransform: "capitalize" }}
                disabledFirst={false}
                onChange={this.change}
                value={this.state.form.seller_id}
                options={this.state.sellers.map(i => ({
                  value: i.id,
                  label: i.name
                }))}
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="payment_method_id"
                label="Forma de Pago"
                className="filter"
                style={{ textTransform: "capitalize" }}
                disabledFirst={false}
                onChange={this.change}
                value={this.state.form.payment_method_id}
                options={this.state.paymentMethods.map(i => ({
                  value: i.id,
                  label: i.name
                }))}
              />
            </div>
            <div className="col-md-2">
              <div className="container-buttons" style={{ marginBottom: '10px' }}>
                <Button className="btn-filter blue" color=" " onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}>
                  <span>Filtrar</span>
                </Button>
                <Button className="btn-filter" color=" " onClick={this.returnState}>
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>

          <Table
            data={this.state.filteredData.length}
            title="Reporte de Caja"
            header={this.state.header}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print()}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.filteredData.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{ i.created }</td>
                  <td>{ i.order_id }</td>
                  <td>{ i.type }</td>
                  <td>{ i.seller }</td>
                  <td>{ i.payment_method }</td>
                  <td>{ Globals.formatMiles(i.amount) }</td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async (page) => {
              await this.setState({ page });
              this.setFiltered();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Payments);
