import React from "react";
import { Avatar, List } from "../../components";
import { ENV } from "../../utils";

class ViewProduct extends React.Component {
  state = {
    data: this.props.data
  };

  componentDidMount() {
    if (this.state.data.description === 'null')
      this.setState({ data: { ...this.props.data, description: '' } });
  }

  render() {
    return (
      <div className="text-center container-view-user">
        {this.state.data.avatar ? (
          <Avatar
            source={ENV.BasePublic + this.state.data.avatar}
            size="130px"
          />
        ) : null}
        <List.Container>
          <List.Item label="Nombre">{this.state.data.name}</List.Item>
          <List.Item label="Código">{this.state.data.code}</List.Item>
          <List.Item label="Descripcion">
            {this.state.data.description ? this.state.data.description : "Sin descripción"}
          </List.Item>
          <List.Item label="Categoria">
            {this.state.data.category?.name ?? 'Sin categoría'}
          </List.Item>
          <List.Item label="Marca">
            {this.state.data.brand?.name ?? 'Sin marca'}
          </List.Item>
          <List.Item label="Modelo">
            {this.state.data.model?.name ?? 'Sin modelo'}
          </List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewProduct;
