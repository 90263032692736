import React from 'react';
import { connect } from 'react-redux';
import { Globals, Constants, axios } from '../../utils';
import { Input, Button, Select } from '../../components';
import { Client as ClientService } from '../../services';

const SECTIONS = {
	SELECT: 1,
	CREATE: 2
}

class SelectClient extends React.Component {

	constructor(props) {
		super(props);

		this.INITIAL_STATE = {
			user_id: this.props.user.id,
			search: '',
			admin_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
			web: true,
		}

		this.state = {
			page: 1,
			form: this.INITIAL_STATE,
			section: SECTIONS.SELECT,
			form_client: {
				name: this.props.item?.name || '',
				phone: this.props.item?.phone || '',
				comments: '',
				email: '',
				seller_id: '',
				document: this.props.item?.document || '',
				address: this.props.item?.address || '',
				zone_id: '',
				state_id: ''
			},
			data: [],
				height: 0,
				heightAddress: 0
		}
	}

	debounce = React.createRef(null)

	async componentDidMount() {
		await this.setState(state => ({
			isFirstSearch: true,
			form: { ...state.form, search: this.props.item?.document || '' }
		}));
		this.getClients();
	}

	getClients = (page = 1) => {
		const { form, data: oldData, isFirstSearch } = this.state;

		Globals.setLoading();
		axios
			.post("web/admin/clients?page=" + page, form)
			.then(async (res) => {
				if (!res.data.clients) return;

				const data = page > 1
					? [...oldData, ...res.data.clients.data]
					: res.data.clients.data;

				this.setState({
					form: {
						...form,
						search: isFirstSearch ? this.props.item?.name || '' : form.search,
					},
					isFirstSearch: false,
					data,
					page,
					last_page: res.data.clients.last_page,
				});

				if (!data.length) this.setTab(SECTIONS.CREATE);
			})
			.catch(() => {
				Globals.showError();
			});
		Globals.quitLoading();
	}

	reset = async () => {
		await this.setState({ page: 1, form: this.INITIAL_STATE });
		this.getClients();
	}

	change = (value,target) => {
		if (this.debounce.current) clearTimeout(this.debounce.current);
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
		this.debounce.current = setTimeout(() => this.getClients(), 600);
	}

	setTab = (section) => {
		this.setState({
			section
		});
	}

	changeClient = (value,target) => {
		this.setState({
			form_client: {
				...this.state.form_client,
				[target]: value
			}
		});
	}

  isValidForm = () => {
    const onError = (msg) => {
      Globals.showError(msg);
      return false;
    }

    const { form_client } = this.state;
		const emailPattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;

    if (!form_client.email)
      return onError('Debe indicar el correo');

    if (!emailPattern.test(form_client.email))
      return onError('El correo debe tener un formato válido');

    return true;
  }

	submit = async () => {
    if (!this.isValidForm()) return;

    Globals.setLoading();

		try {
			const res = await ClientService.create({
				...this.state.form_client,
				admin_id: this.props.user.level_id == Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
			});
			Globals.showSuccess("Se ha creado el cliente correctamente");
			this.props.onClose(res.client);

		} catch (error) {
			
		}

		Globals.quitLoading();
	}

	itClientMatch = (item) => {
		const { form_client } = this.state;
		const clientName = Globals.clean(form_client.name);
		const itemName = Globals.clean(item?.name);
		return clientName === itemName && form_client.phone === item?.phone;
	}

	render() {
		const { data, section } = this.state;

		return (
			<div className="whatsapp-select-client">
				<div className="tab-container">
					<button
						disabled={!data.length}
						className={`tab ${section === SECTIONS.SELECT ? 'active':''}`}
						onClick={ () => this.setTab(SECTIONS.SELECT) }
					>
						<span>Seleccionar</span>
					</button>
					<button
						disabled={!!data.length}
						className={`tab ${section === SECTIONS.CREATE ? 'active':''}`}
						onClick={ () => this.setTab(SECTIONS.CREATE) }
					>
						<span>Crear</span>
					</button>
				</div>

				{section === SECTIONS.CREATE && (
					<div>
						<div className="row">
							<div className="col-12">
								<Input
									label="Nombre"
									className="material"
									labelClass="material"
									value={ this.state.form_client.name }
									onChange={ e => this.changeClient(e.target.value,'name') }
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Input
									label="Correo Electrónico"
									className="material"
									labelClass="material"
									value={this.state.form_client.email}
									onChange={ e => this.changeClient(e.target.value,'email') }
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Input
									label="Teléfono"
									keyboardType="numeric"
									value={ this.state.form_client.phone }
									className="material"
									labelClass="material"
									onChange={ e => this.changeClient(e.target.value,'phone') }
								/>
								</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Input
									label="RIF"
									className="material"
									labelClass="material"
									value={ this.state.form_client.document }
									onChange={ e => this.changeClient(e.target.value,'document') }
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-6">
								<Select
									label="Zona"
									className="material"
									labelClass="material"
									onChange={ e => this.changeClient(e.target.value,'zone_id') }
									options={ this.props.zones.map(i => {
										return {
											value: i.id,
											label: i.name
										}
									}) }
									value={ this.state.form_client.zone_id }
								/>
							</div>
							<div className="col-6">
								<Select
									label="Estado"
									className="material"
									labelClass="material"
									onChange={ e => this.changeClient(e.target.value,'state_id') }
									options={ this.props.states.map(i => {
										return {
											value: i.id,
											label: i.name
										}
									}) }
									value={ this.state.form_client.state_id }
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Input
									label="Dirección"
									className="material"
									labelClass="material"
									multiline={ true }
									style={ { height: Math.max(60, this.state.heightAddress) } }
									value={ this.state.form_client.address }
									onChange={ e => this.changeClient(e.target.value,'address') }
									onContentSizeChange={(event) => {
										this.setState({ heightAddress: event.nativeEvent.contentSize.height })
									}}
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-12">
								<Input
									label="Información Adicional (Opcional)"
									style={ { height: Math.max(60, this.state.height) } }
									multiline={ true }
									className="material"
									labelClass="material"
									onChange={ e => this.changeClient(e.target.value,'comments') }
									onContentSizeChange={(event) => {
										this.setState({ height: event.nativeEvent.contentSize.height })
									}}
								/>
							</div>
						</div>

						<div>
							<Button color="primary" block onClick={ this.submit }>
								<span>Guardar</span>
							</Button>
						</div>
					</div>
				)}

				{section === SECTIONS.SELECT && (
					<React.Fragment>
						<div className="row">
							<div className="col-12">
								<Input
									label="Buscar por Nombre"
									className="material"
									labelClass="material"
									value={ this.state.form.search }
									onChange={ e => this.change(e.target.value,'search') }
								/>
							</div>
						</div>

						{this.state.form != this.INITIAL_STATE && (
							<div className="reset-filter">
								<button onClick={ this.reset }>
									<span>Limpiar filtros</span>
								</button>
							</div>
						)}

						<div className="client-list">
							{data.map((item,index) => (
								<div
									key={ index }
									title={ item?.name }
									className={`client-item ${this.itClientMatch(item) ? 'it-match':''}`}
									onClick={ () => this.props.onClose(item) }
								>
									<span>{ item?.name } - { item?.document }</span>
								</div>
							))}
						</div>

						{this.state.page < this.state.last_page && (
							<div className="fetch-more">
								<Button color="primary" onClick={() => this.getClients(this.state.page + 1)}>
									<span>Cargar más</span>
								</Button>
							</div>
						)}
					</React.Fragment>
				)}
			</div>
		)
	}
}
export default connect(state => {
	return {
		user: state.user,
	}
})(SelectClient);