import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseBlackIcon from '../../assets/icons/close-black.png';
import ENV from "../../utils/env";

// @ts-ignore
const google = window.google;

export class ModalRoute extends Component {

  state = {
    points: this.props.item?.details?.filter(item => item?.client?.latitude != null && item?.client?.longitude != null).map((item) => {
      return {
        latitude: parseFloat(item?.client?.latitude),
        longitude: parseFloat(item?.client?.longitude)
      }
    })
  }

  componentDidMount() {
    this.setMap();
  }

  setMap = async () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { 
        strokeColor: "#07a043",
        strokeWeight: 5
      }
    });

    if (this.state.points.length > 0) {
      const map = new google.maps.Map(document.getElementById('map-planner-view-route'), {
        zoom: 13,
        center: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false
      });
      
      var waypoints = [];

      for (let i = 0; i < this.state.points.length; i++) {
        if (i != 0 && i != (this.state.points.length - 1)) {
          waypoints.push({
            location:  new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
            stopover:true
          });
        }

        new google.maps.Marker({
          position: new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
          map,
          title: '',
          icon: ENV.BasePublic + 'img/marker.png',
          animation: google.maps.Animation.DROP,
          draggable: false
        });
      }

      if (this.state.points.length > 1) {
        const request = {
            origin: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
            destination: new google.maps.LatLng(this.state.points[this.state.points.length - 1].latitude,this.state.points[this.state.points.length - 1].longitude),
            waypoints,
            optimizeWaypoints: true,
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };

        directionsDisplay.setMap(map);
        directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            }
        });
      }      
    }    
  }

  render() {
    const item = this.props.item;

    return (
      <div className="modal-planer-view-route">
        <div className="text-right">
          <button className="btn-close" onClick={ () => this.props.onClose() }>
            <img src={ CloseBlackIcon } />
          </button>
        </div>
        <div id="map-planner-view-route"></div>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalRoute)