import React from 'react';
import {Button, Select, Input } from '../../components';
import { axios, Globals } from '../../utils';

class CreateEditCurrency extends React.Component{
    state={
        form:{},
        users: [],
        textButton: 'Crear'
    }
    componentDidMount(){
        if (this.props.edit) {
            this.edit()
        }
    }
    submit = async () => {
        let param = { ...this.state.form }
        Globals.setLoading();
        axios.upload(this.props.edit ? 'admin/currencies/edit' : 'admin/currencies/create', param)
            .then(res => {
                this.setState({
                    form:{}
                })
                Globals.showSuccess(res.data.msg)
                this.props.onClose()
            })
        .catch(err => {
            Globals.showError();
        })
        .then(() => {
            Globals.quitLoading();
        });
    }
    
    edit = async () => {
        await this.setState({
            form:{
                code: this.props.edit.element.code,
                name: this.props.edit.element.name,
                id: this.props.edit.element.id,
            },
            textButton: 'Editar'
        });
    }

    change = e => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
    }
    
    render(){
        return(
            <div className="text-center container-create-edit-user">
                <Input
                    color="gray"
                    value={this.state.form.name}
                    name="name" 
                    label="Nombre"
                    onChange={this.change} />
                <Input
                    color="gray"
                    value={this.state.form.code}
                    name="code"
                    label="Código"
                    onChange={this.change} />
                <div id="button">
                    <Button block="true" type="button" onClick={() => this.submit()} >
                        {this.state.textButton}
                    </Button>
                </div>
			</div>
        )
    }
}
export default CreateEditCurrency;