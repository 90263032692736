import React from "react";
import { Button, Select, Input } from "../../components";
import { axios, Globals, Constants } from "../../utils";

class CreateEditCategory extends React.Component {
  state = {
    form: {
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id
    },
    users: [],
    textButton: "Crear"
  };
  componentDidMount() {
    if (this.props.edit) {
      this.edit();
      //this.getEnterprises();
    } else {
      //this.getEnterprises();
    }
  }
  submit = async () => {
    let param = { ...this.state.form };
    Globals.setLoading();
    axios
      .upload(
        this.props.edit ? "web/admin/categories/edit" : "web/admin/categories/create",
        param
      )
      .then(res => {
        this.setState({
          form: {}
        });
        let msg = ''
        this.props.edit ? msg = "Categoría editada con exito" : msg = "Categoría creada con exito"
        Globals.showSuccess(msg);
        this.props.onClose();
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  edit = async () => {
    await this.setState({
      form: {
        name: this.props.edit.element.name,
        id: this.props.edit.element.id
      },
      textButton: "Editar"
    });
  };

  change = e => {
    if (e.target.name === "code") {
      e.target.value = e.target.value.toUpperCase();
    }
    let user_id = "";
    if (this.props.user.role === 1 || this.props.user.role === 2) {
      user_id = this.props.user.id;
    } else {
      user_id =
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id;
    }
    this.setState({
      form: {
        user_id: user_id,
        creator_id: this.props.user.id,
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  getEnterprises = async () => {
    Globals.setLoading();
    axios
      .get("admin/warehouses/enterprises")
      .then(res => {
        if (res.data.result) {
          this.setState({
            users: res.data.users
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  render() {
    const admin =
      this.props.user.role === 1 || this.props.user.role === 2 ? true : false;
    let selectEnterprise;
    if (admin) {
      selectEnterprise = (
        <Select
          color="white"
          name="user_id"
          label="Empresa"
          defaultname="Seleccione"
          className="material"
          labelClass="material"
          disabledFirst={false}
          onChange={this.change}
          value={this.state.form.user_id}
          options={this.state.users.map(i => {
            return {
              value: i.id,
              label: i.name
            };
          })}
        />
      );
    }
    return (
      <div className="container-create-edit-user">
        <Input
          color=" "
          value={this.state.form.name}
          name="name"
          label="Nombre"
          className="material"
          labelClass="material"
          onChange={this.change}
        />
        {/* {selectEnterprise} */}
        {/* <File
                    placeholder={this.props.edit ? 'Cambiar imagen de perfil (Opcional) ' : "Agregar Imagen de perfil"}
                    placeholdersuccess="Imagen de perfil Agregada"
                    showcheck={ true.toString() }
                    onChange={ this.change }
                    name="image"
                    value={ this.state.form.image }
                    inputstyle={{
                        display: 'contents'
                    }}
                    className="btn-product" /> */}
        <div id="button" className="d-flex justify-content-center">
          <Button
            type="button"
            color=" "
            className="primary"
            onClick={() => this.submit()}
          >
            <span>{this.state.textButton}</span>
          </Button>
        </div>
      </div>
    );
  }
}
export default CreateEditCategory;
