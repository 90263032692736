import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Modal
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import LocationPinIcon from '../../assets/icons/location-pin.png';
import AddIcon from '../../assets/icons/add.png';
import moment from 'moment';
import PrinterIcon from '../../assets/icons/printer.png';

const INITIAL_FORM = {
  since: "",
  until: "",
  search: "",
  status: "",
  type: ""
}

class ReportVisitPlanner extends React.Component {

  state = {
    header: [
        'Cliente',
        'Visita',
        'Vendedor',
        'Fecha',
        'Tipo visita',
        'Estatus',
        'Resultado',
        'Observaciones',
        'Prox. Visita',
    ],
    page: 1,
    last_page: 1,
    data: [],
    form: INITIAL_FORM,
    user_id: this.props.user.level_id === Constants.ROLE_ADMIN
      ? this.props.user.id
      : this.props.user.admin_id,
    visible: false,
    item: null,
    visible_route: false,
    planned: {
      pending: 0,
      finish: 0,
      unfinish: 0,
      total: 0
    },
    unplanned: {
      pending: 0,
      finish: 0,
      unfinish: 0
    }
  };

  componentDidMount() {
    this.fetchData();
  };

  fetchData = (page = 1) => {
    let { form } = this.state;

    form.user_id = this.props.user.id;    

    axios
      .post("admin/visit-planners/report?page=" + page, {
        ...form,
        since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
        until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      })
      .then(res => {
        this.setState({
          page,
          last_page: res.data.visit_planners.last_page,
          data: res.data.visit_planners.data,
          planned: res.data.planned || {
            pending: 0,
            finish: 0,
            unfinish: 0,
            total: 0
          },
          unplanned: res.data.unplanned || {
            pending: 0,
            finish: 0,
            unfinish: 0
          }
        });

        if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) == -1) {
          this.props.history.replace('/');
        }
      })
      .catch(() => {
        Globals.showError();
      });
  };

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  cleanFilters = async () => {
    await this.setState(state => ({
      ...state,
      form: INITIAL_FORM,
    }));
    await this.fetchData();
  }

  view = (item) => {
    this.setState({
      visible: true,
      item
    });
  }

  viewRoute = (item) => {
    this.setState({
      visible_route: true,
      item
    });
  }

  close = () => {
    this.setState({
      visible: false,
      item: null
    });
  }

  closeRoute = () => {
    this.setState({
      visible_route: false,
      item: null
    });
  }

  print = (data) => {
    axios
      .post("admin/visit-planners/report/download", this.state.form)
      .then(res => {
        if(res.data.url_storage){
          //const url = res.data.url;
          const url = `${ENV.BasePublic}${res.data.url_storage}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  render() {
    const { search, status, since, until, type } = this.state.form;
    const { visible, visible_route, planned, unplanned } = this.state;

    return (
      <Menu history={this.props.history}>

        <div id="visit-planner" className="new-reports">
          <div className="row">
            <div className="col-2">
              <DatePicker
                color="white"
                label="Desde"
                maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              />
            </div>
            <div className="col-2">
              <DatePicker
                color="white"
                label="Hasta"
                minDate={ this.state.form.since ? moment(this.state.form.since).toDate() : null }
                className="filter"
                value={this.state.form.until}
                onChange={value => this.change(value, 'until')}
              />
            </div>
            <div className="col-2">
              <Input
                color=" "
                label="Vendedor"
                placeholder="Escribir"
                className="filter"
                labelClass="filter"
                value={this.state.form.search }
                onChange={e => this.change(e.target.value, 'search')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Visita"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={[
                  { value: Constants.VISIT_PLANNER_TYPE.PLANNED, label: 'Planificada' },
                  { value: Constants.VISIT_PLANNER_TYPE.UNPLANNED, label: 'No planificada' },
                ]}
                value={this.state.form.type}
                onChange={e => this.change(e.target.value, 'type')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Estatus"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={[
                  { value: Constants.VISIT_PLANNER_STATUS.PENDING, label: 'Pendiente' },
                  { value: Constants.VISIT_PLANNER_STATUS.FINISH, label: 'Finalizada' },
                  { value: Constants.VISIT_PLANNER_STATUS.FAILED, label: 'No Finalizada' }
                ]}
                value={this.state.form.status}
                onChange={e => this.change(e.target.value, 'status')}
              />
            </div>

            <div className="col-4">
              <div className="container-buttons">
                <Button
                  className="btn-filter mr-3 blue"
                  color=" "
                  onClick={() => this.fetchData()}
                >
                  <span>Filtrar</span>
                </Button>

                {
                  (since != '' || until != '' || search != '' || status != '' || type != '') && (
                    <Button
                      className="btn-filter"
                      color=" "
                      onClick={this.cleanFilters}
                    >
                      <span>Limpiar</span>
                    </Button>
                  )
                }
              </div>
            </div>
          </div>

          <div className="container-totals" style={{ marginTop: '15px' }}>
            <div className="container-total">
              <p className="name">Visitas planificadas</p>
              <div className="container-values">
                <div className="item-value">
                  <p className="label">Visitas<br />planificadas</p>
                  <p className="value">{ planned.total }</p>
                </div>
                <div className="item-value">
                  <p className="label">Visitas<br />pendientes</p>
                  <p className="value">{ planned.pending }</p>
                </div>
                <div className="item-value">
                  <p className="label">Visitas<br />finalizadas</p>
                  <p className="value">{ planned.finish }</p>
                </div>
                <div className="item-value">
                  <p className="label">Visitas<br />no finalizadas</p>
                  <p className="value">{ planned.unfinish }</p>
                </div>
              </div>
            </div>
            <div className="container-total">
              <p className="name">Visitas no planificadas</p>
              <div className="container-values">
                <div className="item-value">
                  <p className="label">Visitas<br />pendientes</p>
                  <p className="value">{ unplanned.pending }</p>
                </div>
                <div className="item-value">
                  <p className="label">Visitas<br />finalizadas</p>
                  <p className="value">{ unplanned.finish }</p>
                </div>
                <div className="item-value">
                  <p className="label">Visitas<br />no finalizadas</p>
                  <p className="value">{ unplanned.unfinish }</p>
                </div>
              </div>
            </div>
          </div>

          <Table
            title="Reporte de Visitas"
            data={this.state.data.length}
            header={this.state.header}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print()}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{ i.client?.name }</td>
                  <td>{ i.visit_planner?.type_text }</td>
                  <td>{ i.visit_planner?.seller?.name }</td>
                  <td>{ moment(i.visit_planner?.date).format('DD-MM-YYYY') }</td>
                  <td>{ i.visit_type?.name }</td>
                  <td>{ i.status_text }</td>
                  <td>
                    { 
                      i.visit?.results?.map(i => i.name).join(', ')
                    }
                  </td>
                  <td>{ i?.visit?.comments }</td>
                  <td>{ i?.visit?.next_date ? moment(i?.visit?.next_date).format('DD-MM-YYYY') : '' }</td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => this.fetchData(page)}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ReportVisitPlanner);
