import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input,
  DatePicker,
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditIncomeExpensesExtra from "./create_edit_income_expense";
import ViewSeller from "./view_income_expense";
import AddIcon from '../../assets/icons/add.png';
import globals from "../../utils/globals";
// import socket from '../../utils/socket';

class IncomeExpensesExtra extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        "Fecha",
        "Categoría",
        "Descripción",
        "Tipo",
        "Estatus",
        "Total",
        "Acciones"
      ],
      page: 1,
      last_page: 1,
      data: [],
      income_categories: [],
      methods: [],
      types: [
        {
          id: 1,
          name: "Ingresos"
        },
        {
          id: 2,
          name: "Gastos"
        },
      ],
      user: null,
      view: false,
      list_status: [
        { value: "", label: "Todos" },
        { value: Constants.PAYMENT_TYPES.TO_PAY, label: "Por pagar" },
        { value: Constants.PAYMENT_TYPES.PAID, label: "Pagado" }
      ],
      list_categories: [],
      form: {
        user_id: this.props.user.id,
        role: this.props.user.role,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
        since: "",
        until: "",
        category_id: "",
        by_status: "",
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      total_gastos: 0,
      total_ingresos: 0,
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
  
  load = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.web = true
    param.user_id = this.props.user.id
    axios
      .post("admin/income?page=" + this.state.page, param)
      .then(res => {
        console.log(res)
        if (res.data.incomes) {
          const categoriesMap = res.data.categories.map(({ id, name }) => ({
            value: id,
            label: name
          }));
          this.setState({
            last_page: res.data.incomes.last_page,
            data: res.data.incomes.data,
            income_categories: res.data.categories,
            methods: res.data.methods,
            total_gastos: res.data.total_gastos,
            total_ingresos: res.data.total_ingresos,
            list_categories: categoriesMap
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Inactivo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = () => {
    this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });

    this.load();
  };

  inactiveUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea inactivar a: ${name}?`, () => {
      Users.setStatusInactive({  id: Item.id })
        .then(response => {
          // socket.emit('disable-user',{id: item.id});
          this.load();
          Globals.showSuccess("¡Vendedor inactivado exitosamente!");
        })
        .catch(error => {
          Globals.showError(error);
        });
    });
  };

  activateUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea activar a: ${name}?`, () => {
      Users.setStatusActive({ Id: Item.id })
        .then(response => {
          //socket.emit('activate-user', { id: item.id });
          this.load();
          Globals.showSuccess("¡Vendedor activado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  verifyUser = Item => {
    const { person } = Item;
    const name = person.first_name;

    Globals.confirm(`¿Desea verificar a: ${name}?`, () => {
      Users.setStatusVerify({ Id: Item.id })
        .then(response => {
          this.load();
          Globals.showSuccess("¡Vendedor verificado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar esta operación?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`admin/income/delete`, { income_id: item.id })
        .then(res => {
          Globals.showSuccess('Se ha eliminado la operación');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar al Vendedor multiempresa: " + item.person.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/users/get-user-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.user
            });
            Sidebar.getModules(res.data.user.role).then(response => {
              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: response
              });
              this.props.history.push("/enterprises");
            });
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  setPayment = (item) => {
    Globals.confirm(
      "¿Desea marcar como pagada esta operación?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/income/set-payment", { income_id: item.id })
          .then(res => {
            Globals.showSuccess("Se ha modificado la operación");
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
            this.load()
          });
      }
    );
	}

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      form: {
        status: null,
        search: null,
        user_id: this.props.user.id,
        role: this.props.user.role,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
        since: "",
        until: "",
        category_id: "",
        by_status: "",
      }
    }));

    await this.load();
  };

  render() {
    const {history} = this.props
    const { create, data, edit, header, list_status, search, status, user, view, methods, income_categories, total_gastos, total_ingresos } = this.state
    // const titleTable = this.props.user.role === 1 ? 'Multiempresas' : 'Vendedores';
    // const userTypeText = this.props.user.role === 1 ? 'Multiempresa' : 'Vendedor';
    const titleTable = 'Ingresos/Gastos Extras';
    const userTypeText = 'Vendedor';
    return (
      <Menu history={history}>
        {create && (
          <Modal
            title={edit ? `Editar operación` : `Nueva operación`}
            onClose={this.close}
            visible
          >
            <CreateEditIncomeExpensesExtra 
              edit={edit} 
              onClose={this.close} 
              categories={income_categories}
              methods={methods}
            />
          </Modal>
        )}

        <div id="home">
        <div className="row">
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="category_id"
                label="Categoría"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.category_id}
                options={this.state.list_categories}
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="by_status"
                label="Estatus"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.by_status}
                options={this.state.list_status}
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.load}>
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>

          <div className="row monthly-earnings mb-3">
            <div className="col-sm-4 ">
              <span className="">TOTAL INGRESOS EXTRAS</span>
              <div>{Globals.formatMiles(total_ingresos)} $</div>
            </div>
            <div className="col-sm-4 ">
              <span className="">TOTAL GASTOS EXTRAS</span>
              <div>{Globals.formatMiles(total_gastos)} $</div>
            </div>
          </div>

          <Table
            data={data.length}
            title={titleTable}
            header={header}
            right={
              <Button
                title="Nuevo"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Nuevo" />
              </Button>
            }
          >
            {data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>{Item.date}</td>
                  <td>{Item.category?.name ?? ''}</td>
                  <td>{Item.description}</td>
                  <td>{Item.type_text}</td>
                  <td>{Item.status_text}</td>
                  <td>
                    {parseFloat(Number(Item.total)).toFixed(2)} $
                  </td>
                  <td>
                    <Button
                      title="Editar"
                      small="true"
                      color=" "
                      style={{backgroundColor: '#0f54e0'}}
                      onClick={() =>
                        this.setState(
                          {
                            edit: {
                              element: Item,
                              index: index
                            }
                          },
                          () => {
                            this.setState({ create: true });
                          }
                        )
                      }
                    >
                      <Icon name="edit" />
                    </Button>

                    {
                      Item.status == Constants.PAYMENT_TYPES.TO_PAY && (
                        <Button
                          color=""
                          title="Marcar como pagada"
                          small="true"
                          onClick={() => this.setPayment(Item, index)}
                        >
                          <Icon name="check" />
                        </Button>
                      )
                    }

                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(Item, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.load()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(IncomeExpensesExtra);
