import { axios } from '../../utils';

class PublicStoreService {

	static async config(form) {
		return new Promise((resolve, reject) => {
			axios.post('web/sync/config', form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async orders(form) {
		return new Promise((resolve, reject) => {
			axios.post(`web/admin/public-store?page=${form?.page || 1}`, form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async convertToOrder(form) {
		return new Promise((resolve, reject) => {
			axios.upload("web/admin/public-store/convert-to-order", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async reject(form) {
		return new Promise((resolve, reject) => {
			axios.post("web/admin/public-store/reject", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

	static async delete(form) {
		return new Promise((resolve, reject) => {
			axios.post("web/admin/public-store/delete", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

}

export default PublicStoreService;