import React from "react";

import { Button } from "../../components";
import { Globals } from "../../utils";
import { Product } from "../../services";

class RemoveProductCompound extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      product: props.product,
      check: 0,
      warehouses: []
    };
  }

  handleArray = emitter => {
    const { value } = emitter.target;
    const { product } = this.state;

    switch (parseInt(value)) {
      case 1:
        this.setState({ check: 0, warehouses: [] });
        return;
      case 0:
        const warehouses = product.product_warehouse
          .filter(({ stock }) => stock === 0)
          .map(({ warehouse }) => warehouse.id);

        this.setState({ check: 1, warehouses: warehouses });
        return;
    }
  };

  handleChange = emitter => {
    const { value } = emitter.target;
    const { warehouses } = this.state;

    const isNotInArray = warehouses.includes(parseInt(value));

    switch (isNotInArray) {
      case true:
        this.setState(state => ({
          warehouses: state.warehouses.filter(id => id !== parseInt(value))
        }));
        return;
      case false:
        this.setState(state => ({
          warehouses: [...state.warehouses, parseInt(value)]
        }));
        return;
    }
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { submitted, product, warehouses } = this.state;

    if (submitted) {
      return;
    }

    if (warehouses.length <= 0) {
      Globals.showWarning("¡Debe seleccionar al menos 1 almacén!");
      return;
    }

    this.setState({ submitted: true });

    Product.destroyProductCompound({ warehouses: warehouses, product_id: product.id })
      .then(() => {
        Globals.showSuccess(
          "¡El producto ha sido eliminado en los almacenes seleccionados!"
        );
        this.props.onClose();
      })
      .catch(e => {
        Globals.showError(e.msg)
        this.props.onClose()
      });
  };

  render() {
    const { product, warehouses, submitted } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="table-responsive table-sales">
          <table className="table table-bordered table-products table-sm">
            <thead>
              <tr style={{ whiteSpace: "nowrap" }}>
                <th>Almacén</th>
                <th>Stock</th>
                <th className="text-center">
                  <input
                    type="checkbox"
                    title="Eliminar Todos"
                    name="check"
                    onClick={this.handleArray}
                    value={this.state.check}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {product.product_warehouse.length > 0 ? (
                product.product_warehouse.map((product, key) => {
                  return (
                    <tr key={key}>
                      <td>{product.warehouse.name}</td>
                      <td className="text-center">{product.stock}</td>
                      <td className="text-center">
                        <input
                          type="checkbox"
                          title={product.warehouse.name}
                          value={product.warehouse.id}
                          name="modules"
                          disabled={product.stock > 0}
                          checked={!!warehouses.includes(product.warehouse.id)}
                          onChange={this.handleChange}
                        />
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <th colSpan="3">No posee almacenes</th>
                </tr>
              )}

              {product.product_warehouse.length > 0 && (
                <tr>
                  <th colSpan="3" className="text-center">
                    <Button
                      submitted={submitted ? submitted : undefined}
                      block="true"
                      disabled={submitted}
                    >
                      Eliminar
                    </Button>
                  </th>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </form>
    );
  }
}

export default RemoveProductCompound;
