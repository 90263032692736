import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Input,
  Select,
  CheckBox,
  DatePicker,
  Modal,
  Icon,
} from "../../components";
import { axios, Constants, Globals, ENV } from "../../utils";
import { Warehouse, Category, Brand, Payment, AccountToPayService } from "../../services";
import NumberFormat from "react-number-format";
import PrinterIcon from '../../assets/icons/printer.png';
import FinishAccountIcon from '../../assets/icons/finish-account.png';
import moment from "moment";
import CreatePayment from "./create-payment";

const TABLE_HEADER = [
  "Proveedor",
  "Fecha compra",
  "ID",
  "Total de compra",
  "Total de Abonado",
  "Total por pagar",
  "Pago inicial",
  "Abonos parciales",
  "Fechas de pago programadas",
  "Fechas de pago final",
  "Forma de pago",
  "Acciones"
];

class AccountsToPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      total: 0,
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
      page: 1,
      last_page: 1,
      data: [],
      branches: [],
      warehouses: [],
      user: null,
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: this.props.user.id,
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        since: "", //moment().toDate(),
        until: "", //moment().toDate(),
        payment_method_id: "",
        client_id: "",
        provider_id: "",
        currency: Constants.CURRENCIES.DOLARES,
      },
      categories: [],
      subcategories: [],
      brands: [],
      models: [],
      payment_methods: [],
      clients: [],
      providers: [],
      paymentMethods: [],
      countPrice: 0,
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      showProductsFilters: true,
      dates: {
        minDate: "",
        maxDate: ""
      },
      total_compras: 0,
      total_abonado: 0,
      total_pagar: 0,
      modalPaymentDetails: false,
      modalAddPayment: false,
      modalFinish: false,
      selectedItem: null,
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.products();
    this.getPaymentMethods();
  };

  products = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = this.props.user.id;
    form.web = true

    axios
      .post("admin/reports/purchases-accountstopay?page=" + page, form)
      .then(res => {
        const providersMap = res.data.providers.map(({ id, name }) => ({
          value: id,
          label: name
        }));
        this.setState({
          last_page: res.data.purchases.last_page,
          data: res.data.purchases.data,
          total_compras: res.data.total,
          total_abonado: res.data.total_abonado,
          total_pagar: res.data.total_to_pay,
          providers: providersMap,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

	getPaymentMethods = async () => {
    const { form } = this.state;
		try {
			const res = await Payment.getPaymentMethods(form);
			if (!res?.methods?.data?.length) throw new Error();

			this.setState({ paymentMethods: res.methods.data });

		} catch (error) {
			Globals.showError('Ocurrió un error al obtener los métodos de pago');
			console.log('getPaymentMethods -> catch: ', error)
		}
	}

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getWarehouses = id => {
    if (!id) {
      let warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }

    let { user } = this.props;

    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouses({
      role: user.role,
      user_id: enterpriseId,
      branch_id: id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = [];
        if (user.role === 4) {
          warehouses_filtered = response.warehouses.data.filter(el => {
            return user.assigned_warehouses.some(f => {
              return parseInt(f.warehouse_id) === parseInt(el.id);
            });
          });
        } else {
          warehouses_filtered = response.warehouses.data;
        }

        this.setState(state => ({
          warehouses: warehouses_filtered,
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  getCategories = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };

    const { form } = this.state;

    Globals.setLoading();
    axios
      .post("admin/categories/get", param)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            categories: res.data.categories,
            form: {
              ...form,
              subcategory_id: ""
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getSubcategories = categoryId => {
    let param = {
      category_id: categoryId,
      select: true
    };
    Category.getSubcategories(param).then(response => {
      if (response.result) {
        this.setState(state => ({
          ...state,
          subcategories: response.subcategories,
          form: {
            ...state.form,
            subcategory_id: ""
          }
        }));
      }
    });
  };

  getBrands = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };
    const { form } = this.state;
    Brand.getBrands(param).then(response => {
      if (response.result) {
        this.setState({
          brands: response.brands,
          form: {
            ...form,
            model_id: "",
            brand_id: ""
          }
        });
      }
    });
  };

  getModels = brandId => {
    let param = {
      brand_id: brandId,
      select: true,
      user_id: this.props.user.id
    };
    const { form } = this.state;
    Brand.getModels(param).then(response => {
      if (response.result) {
        this.setState({
          models: response.models,
          form: {
            ...form,
            model_id: ""
          }
        });
      }
    });
  };

  openProductsDetails = e => {
    this.change(e);
    if (e.target.value === "")
      this.setState({
        showProductsFilters: false
      });
    else
      this.setState({
        showProductsFilters: true
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  handleCheck = async e => {
    var { name, checked } = e.target;
    let { form, header, trows } = this.state;

    let sectionPrices = header
      .filter(Item => {
        return Item.section === "price";
      })
      .map(Item => {
        return Item.value;
      });

    let sectionStock = header
      .filter(Item => {
        return Item.section === "stock";
      })
      .map(Item => {
        return Item.value;
      });

    if (trows.length === 0) {
      trows = header.filter(Item => {
        return Item.type === 1;
      });
    } else {
      trows = trows.filter(Item => {
        return Item.type;
      });
    }

    let find = header.find(Item => Item.value === name);

    if (sectionPrices.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        trows.push(find);

        if (countSecPrice.length === 0) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = name;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecPrice = trows.filter(Item => {
          return Item.section === "price";
        });

        if (countSecPrice.length === 1) {
          let totalRow = header.find(Item => Item.value === "total");
          totalRow.multiplier = countSecPrice[0].value;
          trows.push(totalRow);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "total");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    if (sectionStock.includes(name)) {
      if (!trows.find(Item => Item.value === find.value)) {
        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        trows.push(find);

        if (countSecStock.length === 0) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      } else {
        let index2 = trows.findIndex(Item => Item.value === name);
        trows.splice(index2, 1);

        let countSecStock = trows.filter(Item => {
          return Item.section === "stock";
        });

        if (countSecStock.length === 1) {
          let totalAvailable = header.find(Item => Item.value === "available");
          trows.push(totalAvailable);
        } else {
          let indexRow = trows.findIndex(Item => Item.value === "available");
          if (indexRow > -1) {
            trows.splice(indexRow, 1);
          }
        }
      }
    }

    trows.sort((a, b) => parseInt(a.order) - parseInt(b.order));

    let thead = trows.map(Item => {
      return Item.label;
    });

    this.setState({
      form: {
        ...form,
        [name]: checked
      }
    });

    return;
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      page: 1,
      search: '',
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        payment_method_id: "",
        since: "",
        until: "",
        client_id: "",
        provider_id: "",
      },
    }));
    await this.load();
  };

  print = (data) => {
      let { form } = this.state;
      form.currency = Constants.CURRENCIES.DOLARES
      Globals.typeDownloadOptions(
        `Selecciona en que formato deseas que se exporte el reporte`,
        (type_download) => {
          this.setState(state => ({
            form: {
              ...state.form,
              type_download: type_download
            }
          }));
          axios
            .post("admin/reports/purchases-accountstopay/download", this.state.form)
            .then(res => {
              if (res.data.url_storage) {
                //const url = res.data.url;
                const url = `${ENV.BasePublic}${res.data.url_storage}`;
                const win = window.open(url, "_blank");
                win.focus();
              }
              else {
                Globals.showError('Ha ocurrido un error');
              }
            })
            .catch(err => {
              Globals.showError();
            });
        }
      );
    
  };

  close = (reload = false) => {
    this.setState({ modalPaymentDetails: false, modalFinish: false, modalAddPayment: false, selectedItem: null });
    if (reload) this.load();
  }

  viewPayments = (item) => {
    this.setState({ modalPaymentDetails: true, selectedItem: item });
  }

  addPayment = (item) => {
    this.setState({ modalAddPayment: true, selectedItem: item });
  }

  onFinish = (item) => {
    this.setState({ modalFinish: true, selectedItem: item });
  }

  finish = async () => {
    try {
      await AccountToPayService.finish({
        purchase_id: this.state.selectedItem.id
      });
      Globals.showSuccess("Se ha marcado la compra como finalizada");
      this.close(true);
      
    } catch (error) {
      Globals.showSuccess("Ocurrió un error inesperado");
    }
  }

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  render() {
    const { trows } = this.state;
    const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');
    const {
      total_compras,
      total_abonado,
      total_pagar,
      modalPaymentDetails,
      modalAddPayment,
      modalFinish,
      selectedItem,
      paymentMethods,
    } = this.state;

    return (
      <Menu history={this.props.history}>

        {modalPaymentDetails && (
          <Modal title="Pagos Realizados" onClose={() => this.close()} visible>
            {
              selectedItem?.payments?.map((item) => (
                <div style={{ marginBottom: 20 }}>
                  <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
                    <span style={{ marginRight: 5 }}><b>Pago:</b> </span>
                    <span>{ Globals.formatMiles(item.amount,true) }</span>
                  </div>
                  {
                    item?.method && (
                      <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
                        <span style={{ marginRight: 5 }}><b>Método:</b> </span>
                        <span>{ item.method?.name }</span>
                      </div>
                    )
                  }
                  {
                    item?.comments && (
                      <div style={ { display: 'flex', flexDirection: 'row', alignItems: 'center' } }>
                        <span style={{ marginRight: 5 }}><b>Comentarios:</b> </span>
                        <span>{ item.comments }</span>
                      </div>
                    )
                  }
                  <span>{ moment(item.created_at).format('DD/MM/YYYY HH:mm') }</span>
                </div>
              ))
            }

            {
              !selectedItem?.payments?.length && (
                <p style={{ padding: 10, textAlign: 'center' }}>No se han registrado pagos</p>
              )
            }

            <Button block secondary onClick={() => this.close()}>
              <span>CERRAR</span>
            </Button>
          </Modal>
        )}

        {modalAddPayment && (
          <Modal title="Agregar Pago" onClose={() => this.close()} visible>
            <CreatePayment
              purchase={selectedItem}
              paymentMethods={paymentMethods}
              onClose={this.close}
            />
          </Modal>
        )}

        {modalFinish && (
          <Modal title="Finalizar compra" onClose={() => this.close()} visible>
            <h6 className="text-center mb-4">¿Desea marcar el pedido como finalizado?</h6>

            <div style={ { display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '1rem' } }>
              <div style={ { flex: 1 } }>
                <Button outline block onClick={ () => this.close() }>
                  <span style={{ color: 'gray' }}>Cerrar</span>
                </Button>
              </div>
              <div style={ { flex: 1 } }>
                <Button color="primary" block onClick={ this.finish }>
                  <span>Aceptar</span>
                </Button>
              </div>
            </div>
          </Modal>
        )}

        <div id="products" className="white-spaced-table">
          <div className="row">
            <div className="col-md-2">
              <Input
                color=" "
                name="search"
                label="Buscar ID"
                className="filter"
                onChange={this.handleChange}
                value={this.state.form.search}
                placeholder="Buscar ID"
              />
            </div>
            <div className="col-md-2">
              <Select
                color="white"
                name="provider_id"
                label="Proveedor"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.change(emitter)}
                value={this.state.form.provider_id}
                options={this.state.providers}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={async () => {
                await this.setState({ page: 1 });
                this.load();
              }}>
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                <span>Limpiar</span>
              </Button>
            </div>
          </div>

          {(!!this.state.optionals.length && this.state.optionals.some(x => !x.show)) && (
            <div className="row" id="row1">
              <div className="col col-md d-flex flex-wrap justify-content-between align-items-center check">
                {this.state.optionals.map(el => {
                  if (!el.show) {
                    return (
                      <div className="px-3 py-3 check1">
                        <CheckBox
                          label={el.label}
                          name={el.value}
                          checked={this.state.form[el.value] ? true : false}
                          value={this.state.form[el.value]}
                          onChange={this.handleCheck}
                        />
                      </div>
                    );
                  }
                })}
              </div>
            </div>
          )}

          <div className="row monthly-earnings mb-3">
            <div className="col-sm-4">
              <span className="">TOTAL COMPRAS</span>
              <div>{Globals.formatMiles(total_compras)} $</div>
            </div>
            <div className="col-sm-4">
              <span className="">TOTAL ABONADOS</span>
              <div>{Globals.formatMiles(total_abonado)} $</div>
            </div>
            <div className="col-sm-4">
              <span className="">TOTAL POR PAGAR</span>
              <div>{Globals.formatMiles(total_pagar)} $</div>
            </div>
          </div>

          <Table
            data={this.state.data.length}
            title="Cuentas por pagar"
            header={TABLE_HEADER}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print(this.state.data)}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              const paydays = i.paydays.map(purchase => moment(purchase.date).format('DD/MM/YYYY')).join('\n');
              return (
                <tr key={index}>
                  <td>{ i.provider?.name ?? '' }</td>
                  <td>{ moment(i.created_at).format('DD/MM/YYYY') }</td>
                  <td>{ i.number_format }</td>
                  <td>{ parseFloat(Number(i.total)).toFixed(2) }</td>
                  <td>{ parseFloat(Number(i.initial_payment + i.partial_payment)).toFixed(2) }</td>
                  <td>{ parseFloat(Number(i.to_pay)).toFixed(2) }</td>
                  <td>{ parseFloat(Number(i.initial_payment)).toFixed(2) }</td>
                  <td>{ parseFloat(Number(i.amount_payment)).toFixed(2) }</td>
                  <td style={{ whiteSpace: 'pre-line' }}>{ paydays }</td>
                  <td>{ moment(i.payment_date).format('DD/MM/YYYY') }</td>
                  <td>{ i.method?.name ?? '' }</td>
                  <td>
                    <Button
                      color="primary"
                      title="Ver pagos"
                      small="true"
                      onClick={() => this.viewPayments(i)}
                    >
                      <Icon name="eye" />
                    </Button>

                    <Button
                      color="green"
                      title="Agregar pago"
                      small="true"
                      onClick={() => this.addPayment(i)}
                    >
                      <Icon name="upload" />
                    </Button>

                    <Button
                      color=" "
                      title="Marcar finalizado"
                      small="true"
                      onClick={() => this.onFinish(i)}
                    >
                      <img src={FinishAccountIcon} alt="" width={16} style={{ filter: "brightness(0) invert(1)" }} />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          {
            showTotal && (
              <p style={{
                fontSize: '16px',
                fontWeight: 'normal',
                fontFamily: 'Roboto Light',
                textAlign: 'right',
                marginTop: '10px',
                marginRight: '10px'
              }}>Costo Total: <NumberFormat
                  value={parseFloat(this.state.total.toFixed(2))}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            )
          }

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(AccountsToPay);
