import React from "react";

import { Input, Button } from "../../components";
import { Globals } from "../../utils";
import { Category as Categories } from "../../services";

class Category extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      form: {
        code: "",
        name: "",
        user_id: props.Id
      },
      errors: []
    };
  }

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    const { submitted, form } = this.state;

    if (submitted) {
      return;
    }

    this.setState({ submitted: true, errors: [] });

    Categories.createCategory(form)
      .then(response => {
        Globals.showSuccess("¡La categoría fue creada exitosamente!");
        this.props.category(response);
      })
      .catch(error => {
        if ((error || {}).message) {
          Globals.showError(error.message);
        }

        this.setState({ submitted: false, errors: error });
      });
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-md-12">
          <Input
            color="gray"
            value={this.state.form.code}
            name="code"
            label="Código de Categoría"
            onChange={this.handleChange}
            error={`${this.hasErrorFor("code") ? "is-invalid" : ""}`}
            invalidfeedback={this.renderErrorFor("code")}
          />
        </div>
        <div className="col-md-12">
          <Input
            color="gray"
            value={this.state.form.name}
            name="name"
            label="Nombre de Categoría"
            onChange={this.handleChange}
            error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
            invalidfeedback={this.renderErrorFor("name")}
          />
        </div>
        <div className="col-md-12">
          <div className="form-group">
            <div id="button">
              <Button block="true" type="button" onClick={this.handleSubmit}>
                Crear
              </Button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Category;
