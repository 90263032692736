import axios from "../../utils/axios";

class User {
  static async getUsers(page, param) {
    return new Promise((resolve, reject) => {
      axios.post(`web/admin/users/get?page=${page}`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getAdministrators(param) {
    return new Promise((resolve, reject) => {
      axios.post(`web/admin/users/get-admins`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async setStatusActive(form) {
    return new Promise((resolve, reject) => {
      axios.post("web/admin/users/active", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async setStatusVerify(form) {
    return new Promise((resolve, reject) => {
      axios.post("web/admin/users/verify", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async removeUserById(form) {
    return new Promise((resolve, reject) => {
      axios.post("web/admin/users/delete", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async setStatusInactive(form) {
    return new Promise((resolve, reject) => {
      axios.post("web/admin/users/suspend", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createUserEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.upload("web/admin/users/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async editUserEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.upload("web/admin/users/edit", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async editAdminProfile(form) {
    return new Promise((resolve, reject) => {
      axios.upload("admin/profile/save", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getAdminProfile(form) {
    return new Promise((resolve, reject) => {
      axios.upload("admin/profile", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default User;
