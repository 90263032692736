import React from "react";

import { Button, Select } from "../../components";
import { axios, Globals, Format, Constants, ENV } from "../../utils";
import { Product } from "../../services";
import uploadExcelDesktop from '../../assets/icons/upload-excel-desktop.png';
import UploadFile from '../../assets/icons/upload.png';

class Habladores extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: false,
            categories: props.categories,
            selectedPrices: props.selectedPrices,
            onClose: props.onClose,
            form_habladores: {
                currency: Constants.CURRENCIES.DOLARES,
                category_id: null,
                select_price: 1,
                user_id: props.user.id
            }
        };
    }

    change = e => {
        console.log(e)
        this.setState({
          form_habladores: {
            ...this.state.form_habladores,
            [e.target.name]: e.target.value
          }
        });
    };

    createHabladores = async () => {
        this.setState({
            form_habladores: {
              ...this.state.form_habladores,
              select_price: parseInt(this.state.form_habladores.select_price)
            }
        });
        
        Globals.currencyOptions(
          `Selecciona en que moneda deseas que se genere el reporte`,
          (currency) => {
            this.setState(state => ({
              form_habladores: {
                ...state.form_habladores,
                currency: currency
              }
            }));
            axios
              .post("admin/habladores/create", this.state.form_habladores)
              .then(res => {
                if (res.data.url_storage) {
                  const url = `${ENV.BasePublic}${res.data.url_storage}`;
                  const win = window.open(url, "_blank");
                  win.focus();
                }
                else {
                  Globals.showError('Ha ocurrido un error');
                }
              })
              .catch(err => {
                console.log(err)
                Globals.showError();
              });
          }
        );
      }


    render() {
        const { categories, selectedPrices, onUpload, onClose } = this.state;

        return (
            <>
                <div className="banner">
                    <img src={uploadExcelDesktop} alt="Subir excel" />
                </div>

                <div className="content">
                    <p><b>Seleccione la categoría: (opcional)</b></p>
                    <Select
                        color="white"
                        name="category_id"
                        //label="Categoría"
                        className="filter"
                        style={{ textTransform: "capitalize" }}
                        onChange={emitter => this.change(emitter)}
                        value={this.state.form_habladores.category_id}
                        options={categories}
                        disabledFirst="false"
                    />

                    <p><b>Seleccione el precio:</b></p>
                    <Select
                        color="white"
                        name="select_price"
                        //label="Precio"
                        className="filter"
                        style={{ textTransform: "capitalize" }}
                        onChange={emitter => this.change(emitter)}
                        value={this.state.form_habladores.select_price}
                        options={selectedPrices}
                    />
                </div>

                <div className="actions">
                    <button className="btn p-2" onClick={onClose}>
                        <span>Cerrar</span>
                    </button>

                    <Button
                      className="btn p-2"
                      block="true"
                      onClick={() => this.createHabladores()}
                    >
                      Generar Habladores
                    </Button>
                </div>
            </>
        );
    }
}

export default Habladores;
