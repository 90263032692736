import React from "react";
import { Link } from "react-router-dom";
import { Card, Input, Button, Icon } from "../../components";

import { Globals, Constants, Format } from "../../utils";
import { Auth, Sidebar } from "../../services";
import Logo from '../../assets/img/logo.png';
import LogoWhite from '../../assets/img/logo-white.png';
import LoginBG from '../../assets/img/login-bg.png';
import Slider1 from '../../assets/img/slider-1.png';
import Slider2 from '../../assets/img/slider-2.png';
import Slider3 from '../../assets/img/slider-3.png';

import { connect } from "react-redux";

const banners = [Slider1, Slider2, Slider3];

class Login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: false,
      currentBanner: 0,
      showRedirect: false,
      showPass: false,
      form: {
        email: "",
        password: "",
        terms: false,
        web: true,
      }
    };
  }

  componentDidMount() {
    const { user, history } = this.props;
    this.interval = setInterval(() => this.autoHandleBanner(), 5000);

    if (!user) {
      return;
    }

    //history.push("/users");
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  /**
   * Event that captures form submission.
   * @param {object} event emitted when submitting the form.
   */

  submit = emitter => {
    emitter.preventDefault();

    const { form } = this.state;

    this.setState({ submitted: true });

    Auth.authenticate(form)
      .then(({ user, token, message }) => {
        const { level_id: roleId } = user;
        
        if(message !== null){
          Globals.showWarning(
            message
          );
        }

        this.props.dispatch({
          type: "SET_USER",
          payload: user
        });

        this.props.dispatch({
          type: "SET_ADMIN",
          payload: Globals.setTypeRole(user)
        });

        localStorage.setItem("token", JSON.stringify(token));

        return user;
      })
      .then(user => {
        if (!user) {
          return;
        }

        const { level_id: roleId } = user;

        // Sidebar.getModules(roleId)
        //   .then(modules => this.setModules(roleId, user, modules))
        //   .catch(error => console.log({ error: error }))
        //   .finally(() => this.setState({ submitted: false }));

        Sidebar.getModuleByUser(user.id)
          .then(modules => this.setModules(roleId, user, modules))
          .catch(error => console.log({ error: error }))
          .finally(() => this.setState({ submitted: false }));
      })
      .catch(error => {
        Globals.showError(error?.error);
      })
      .finally(() => this.setState({ submitted: false }));
  };

  recoverPass = () => {
    
  }

  terms = () => {
    
  }

  /**
   * Set the menu depending on the permissions.
   * @param {int} user role identifier.
   * @param {object} object containing user properties.
   * @param {object} object that contains the module properties.
   */

  setModules = (roleId, user, modules) => {
    const { id: userId } = user;

    switch (roleId) {
      case Constants.ROLE_ADMIN:
        this.props.dispatch({
          type: "SET_SIDEBAR",
          payload: modules
        });

        //this.props.history.push("/users");
        this.props.history.push('/dashboard');

        return;

      case Constants.ROLE_MODERATOR:
      case Constants.ROLE_MANAGER:
        if(modules.length > 0){
          this.props.dispatch({
            type: "SET_SIDEBAR",
            payload: modules
          });
  
          const defaultModule = modules[0]?.path
          this.props.history.push(defaultModule);
  
          return;
        }
        else{
          Globals.showError('No posee módulos asignados, comuníquese con el usuario Administrador');
        }
        

      default:
        // Sidebar.getModuleByUser(userId).then(response => {
        //   let Menus = [];

        //   const {
        //     modules_seller,
        //     enterprise_modules,
        //     ROLE_SELLER_subuser
        //   } = response;

        //   let array_modules =
        //     roleId === Constants.ROLE_SELLER
        //       ? modules_seller
        //       : enterprise_modules;

        //   if (roleId === Constants.ROLE_MANAGER) {
        //     array_modules = ROLE_SELLER_subuser.role_modules;
        //   }

        //   if (roleId === Constants.ROLE_MANAGER) {
        //     modules.forEach(module => {
        //       const { id } = module;
        //       array_modules.forEach(({ module_id: moduleId }) => {
        //         if (moduleId === id) {
        //           Menus.push(module);
        //         }
        //       });
        //     });
        //   } else {
        //     modules.forEach(module => {
        //       const { id: moduleId } = module;
        //       array_modules.forEach(({ id }) => {
        //         if (id === moduleId) {
        //           Menus.push(module);
        //         }
        //       });
        //     });
        //   }

        //   this.props.dispatch({
        //     type: "SET_SIDEBAR",
        //     payload: Menus
        //   });

        //   this.props.history.push(Menus[0].path);
        // });
    }
  };

  handleDisabled = () => {
    return this.state.form.email === "" || this.state.form.password === "";
  };

  handleChange = ({ target }) => {
    const { name, value } = target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  autoHandleBanner = () => {
    let current = this.state.currentBanner + 1;
    if (current >= banners.length) current = 0;
    this.setState({ currentBanner: current });
  };

  handleBanner = (index) => {
    if (this.interval) clearInterval(this.interval);
    this.setState({ currentBanner: index });
  };

  render() {
    const { currentBanner, submitted, showRedirect } = this.state;

    return (
      <div id="login">
        <img src={LoginBG} className="bg" alt="Login Background" />
        <main>
          <div className="left">
            <div className="logo">
              <img src={Logo} alt="Logo" />
            </div>
            {showRedirect ? (
              <div className="onboard">
                <Button
                  color="green"
                  block
                  className="primary"
                  onClick={() => this.setState({ showRedirect: false })}
                >
                  <span>Iniciar Sesión</span>
                </Button>

                <div className="row text-center link" style={{ margin: 'auto' }}>
                  <Link className="highlighted" to="/register">
                    <span className="highlighted">Registrarme</span>
                  </Link>
                </div>
              </div>
            ) : (
              <form onSubmit={this.submit}>
                <h5 style={{ fontWeight: '600', marginBottom: '1rem' }}>
                  Iniciar sesión en Sysge24
                </h5>

                <label>Correo electrónico</label>
                <div className="row">
                  <div className="col col-md">
                    <Input
                      value={this.state.email}
                      name="email"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>

                <div className="show-pass">
                  <label>Contraseña</label>
                  <div onClick={() => this.setState({ showPass: !this.state.showPass })}>
                    <Icon name="eye" />
                    <span>{ this.state.showPass ? 'Ocultar' : 'Mostrar' }</span>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md">
                    <Input
                      autoComplete="off"
                      value={this.state.password}
                      type={this.state.showPass ? 'text' : 'password'}
                      name="password"
                      onChange={this.handleChange}
                    />
                  </div>
                </div>
                <div className="my-3 text-center">
                  <Button
                    submitted={submitted ? submitted : undefined}
                    type="submit"
                    color=" "
                    className="primary"
                    block
                    // disabled={this.handleDisabled()}
                  >
                    <span style={{ fontWeight: 'bold', fontSize: 'medium' }}>Iniciar Sesión</span>
                  </Button>
                </div>
                <div className="link">
                  <div className="mt-2">
                    <Link className="highlighted" to="/register">
                      Registrarse
                    </Link>
                  </div>
                  <div className="mt-2">
                    <Link className="highlighted" to="/recover">
                      <span className="highlighted">¿Olvidó la contraseña?</span>
                    </Link>
                  </div>
                </div>
              </form>
            )}

            {/* <div className="terms">
              <input type="checkbox" value={form.terms} />
              <span>Acepto los</span>
              <span className="highlighted" onClick={() => this.terms()}>
                Términos y condiciones
              </span>
            </div> */}
          </div>
          <div className="right">
            <div className="slider">
              <div className="content">
                {banners.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    className={currentBanner === index ? 'active':''}
                    alt={`Slider ${index + 1}`}
                  />
                ))}
              </div>
              <nav>
                {banners.map((_, index) => (
                  <div
                    key={index}
                    className={`dot ${currentBanner === index ? 'active':''}`}
                    onClick={() => this.handleBanner(index)}
                  />
                ))}
              </nav>
            </div>
          </div>
        </main>
        {/* <footer className="copy">
          <p>© 2023 Designed by Limónbyte | All rights reserved</p>
        </footer> */}
      </div>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    admin: state.admin
  };
})(Login);
