import React from "react";
import { Colors, Globals, Constants } from "../../utils";

class ViewReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            payment: props.payment
        };
    }

    getStatus = status => {
        let respuesta = "";
        switch (status) {    
          case 1:
            respuesta = {
              text: "Completo",
              color: Colors.green
            };
            break;
          case 2:
            respuesta = {
              text: "Parcial",
              color: Colors.orange
            };
            break;
          default:
            break;
        }
        return respuesta;
    };

    render() {
        const { payment } = this.state;
    
        return (
            <div className="row">
                <div className="col-md">
                    <div className="form-group">
                        <div className="table-responsive">
                            <table className="table table-bordered table-sm">
                                <thead>
                                    <tr>
                                        <th>Tipo</th>
                                        <th>Fecha</th>
                                        <th>Código</th>
                                        <th>Monto Total del Cobro</th>
                                        <th>Tipo de Pago</th>
                                        <th>Monto Usado del Pago</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {payment.expenses.length > 0
                                    ? payment.expenses.map((expense, key) => {
                                        if(expense.processed_request){
                                            return (
                                            <tr key={key}>
                                                <td>{expense.processed_request.type === Constants.TYPE_DELIVERY ? "NE" : "FC"}</td>
                                                <td>{expense.processed_request.date_emission}</td>
                                                <td>{expense.processed_request.code}</td>
                                                <td>
                                                    {Globals.formatMiles(expense.processed_request.total)}
                                                </td>
                                                <td>{parseInt(expense.type) === 1 ? "ABONO" : "PAGO COMPLETO"}</td>
                                                <td>
                                                    {Globals.formatMiles(expense.amount)}
                                                </td>
                                            </tr>
                                            );
                                        }
                                    })
                                    : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ViewReport;