import moment from 'moment';
import React, { Component, createRef } from 'react'
import { Button, DatePicker, Icon, Input, Modal, Pagination } from '../../../components';
import { Globals, axios } from '../../../utils';
import { connect } from 'react-redux';
import ModalCreateReturn from './modal-create-return';

const INITIAL_FILTER = {
  date: null,
  search: '',
};

class ModalFindOrder extends Component {

  state = {
    selectedItem: null,
    filter: { ...INITIAL_FILTER },
    data: [],
    page: 1,
    last_page: 1,
  }

	debounce = createRef();

  componentDidMount() {
    this.load();
  }

  load = async (page = 1) => {
    Globals.setLoading();
    const { filter } = this.state;
    const date = filter.date && moment(filter.date).format('YYYY-MM-DD');

    const filters = {
      user_id: this.props.user.id,
      only_fiscal_bill: true,
      search: filter.search,
      until: date,
      since: date,
      page,
    };

    try {
      const res = await axios.post('admin/orders', filters);
      this.setState({ 
        data: res.data.orders.data,
        last_page: res.data.orders.last_page,
        page,
      });

    } catch (error) {
      Globals.showError(error?.response?.data?.error || 'Ocurrió un error inesperado');
    }

    Globals.quitLoading();
  }

  onSelectItem = async (item) => {
    document.getElementsByClassName('modal').item(0).style.zIndex = !!item ? 1040 : 1050;
    this.setState({ selectedItem: item });
  }

  changeFilter = async (value, target) => {
    await this.setState(s => ({ filter: { ...s.filter, [target]: value } }));

    if (target === 'search') {
      if (this.debounce.current) clearTimeout(this.debounce.current);
      this.debounce.current = setTimeout(async () => this.load(), 1000);
    } else {
      this.load();
    }
  }

  canResetFilter = () => {
    return JSON.stringify(this.state.filter) !== JSON.stringify(INITIAL_FILTER);
  }

  resetFilter = async () => {
    await this.setState({ filter: { ...INITIAL_FILTER } });
    this.load();
  }

  render() {

    const { data, filter, last_page, page, selectedItem } = this.state;

    return (
      <>
        <Modal
          className="modal-return-details"
          onClose={() => this.onSelectItem(null)}
          visible={!!selectedItem}
          size="xl"
        >
          <ModalCreateReturn
            returnedItem={selectedItem}
            onClose={() => this.onSelectItem(null)}
            onSuccess={() => {
              this.onSelectItem(null);
              this.props.onClose(true);
            }}
          />
        </Modal>

        <div className="row">
          <div className="col-2">
            <DatePicker
              label="Fecha de Factura"
              labelClass="material"
              className="material"
              value={filter.date}
              onChange={v => this.changeFilter(v,'date')}
            />
          </div>
          <div className="col-3">
            <Input
              color=" "
              label="Cédula o RIF del Cliente"
              labelClass="material"
              className="material"
              onChange={v => this.changeFilter(v.target.value,'search')}
              value={filter.search}
            />
          </div>
          <div className="col-md">
            <Button
              className="btn-align-bottom btn-filter"
              color="blue"
              onClick={async () => {
                await this.setState({ page: 1 });
                this.load();
              }}
            >
              <span className="text-white">Filtrar</span>
            </Button>
            {this.canResetFilter() && (
              <Button
                color="transparent"
                className="btn-align-bottom btn-filter"
                onClick={this.resetFilter}
              >
                <span>Limpiar</span>
              </Button>
            )}
          </div>
        </div>

        <table>
          <thead>
            <tr>
              <td>Fecha de emisión</td>
              <td>Clientes</td>
              <td>Método de pago</td>
              <td>Monto total</td>
              <td align="right">Acciones</td>
            </tr>
          </thead>
          <tbody>
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{ moment(item.created_at).format('DD/MM/YYYY') }</td>
                  <td>{ item?.client?.name }</td>
                  <td>{ item.payment_methods_text }</td>
                  <td>$ { Globals.formatMiles(item.total) }</td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() => this.onSelectItem(item)}
                    >
                      <Icon name="eye" />
                    </Button>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>

        {!data.length && (
          <div className="text-center p-3">No se encontraron resultados</div>
        )}

        <Pagination
          pages={last_page}
          active={page}
          onChange={page => this.load(page)}
        />
      </>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalFindOrder);