import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals, Colors } from "../../utils";
import CreaEditCategory from "./create_edit_category";
import ViewCategory from "./view_category";
import $ from "jquery";
import AddIcon from '../../assets/icons/add.png';

class Category extends React.Component {
  state = {
    header: ["Nombre", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    category: null,
    view: false,
    list_status: [
      { value: "", label: "Todos" },
      { value: 0, label: "Suspendidas" },
      { value: 1, label: "Activas" }
    ],
    form: {
      status: null,
      search: null,
      user_id: this.props.user.id,
      role: this.props.user.role
    }
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  load = () => {
    Globals.setLoading();
    axios
      .post("web/admin/categories?page=" + this.state.page, this.state.form)
      .then(async res => {
        if (res.data.result) {
          if (this.state.page > 1 && res.data.categories.data.length === 0) {
            await this.setState({
              page: 1
            });
            this.load();
          } else {
            await this.setState({
              last_page: res.data.categories.last_page,
              data: res.data.categories.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Suspendida",
          color: Colors.orange
        };
        break;
      case 1:
        respuesta = {
          text: "Activa",
          color: Colors.green
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      category: null
    });
    await this.load();
  };

  suspend = (item, i) => {
    let text = "¿Desea suspender la Categoría ";
    if (item.status !== 1) {
      text = "¿Desea activar la Categoría ";
    }
    Globals.confirm(text + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/categories/suspend", { id: item.id })
        .then(res => {
          if (res.data.result) {
            Globals.showSuccess(res.data.msg);
            this.load();
          } else {
            Globals.showError();
          }
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar la Categoría: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("web/admin/categories/delete", { category_id: item.id })
        .then(res => {
          Globals.showSuccess('Categoría eliminada con exito');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };
  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  render() {
    return (
      <Menu history={this.props.history}>
        <Modal
          title="Ver Categoría"
          onClose={this.close}
          visible={this.state.view}
        >
          <ViewCategory category={this.state.category} />
        </Modal>
        <Modal
          title={this.state.edit ? "Editar Categoría" : "Nueva Categoría"}
          onClose={this.close}
          visible={this.state.create}
        >
          <CreaEditCategory
            user={this.props.user}
            edit={this.state.edit}
            onClose={this.close}
          />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-md-5">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre"
              />
            </div>
            {/* <div className="col-md-5">
              <Select
                color="white"
                name="status"
                label="Status"
                defaultname="Seleccione"
                className="filter"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div> */}
            <div className="col-md-2">
              <Button color=" " className="btn-align-bottom btn-filter" onClick={this.load}>
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Categorías"
            header={this.state.header}
            right={
              <Button
                title="Agregar Categorias"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Agregar Marcas" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.name}</td>
                  <td style={{ maxWidth: 'none' }}>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      style={{backgroundColor: '#5c068c'}}
                      onClick={() => {
                        this.setState({
                          category: i,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      color=" "
                      small="true"
                      style={{backgroundColor: '#0f54e0'}}
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: i,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {/* {i.status === 0 && (
                      <Button
                        color="warning"
                        title="Activar"
                        small="true"
                        style={{backgroundColor: '#d39e00'}}
                        onClick={() => {
                          this.suspend(i, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {i.status === 1 && (
                      <Button
                        color="warning"
                        title="Suspender"
                        small="true"
                        style={{backgroundColor: '#d39e00'}}
                        onClick={() => this.suspend(i, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )} */}
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                    {/* <Link to={`subcategories/${i.id}`}>
                      <Button color="primary" title="Subcategorías" small="true" style={{backgroundColor: '#5c068c'}}>
                        <Icon name="list" />
                      </Button>
                    </Link> */}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => {
                  this.load();
                }
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Category);
