import React from 'react';
import { connect } from 'react-redux';
import Menu from '../menu';
import { Table, Pagination, Button, Icon, Modal, Select, Input } from '../../components';
import { axios, Globals, Colors } from '../../utils';
import CreateEditModel from './create_edit_model'; 
import ViewModel from './view_model';
import $ from 'jquery';
import AddIcon from '../../assets/icons/add.png';

class Model extends React.Component {
	state={
		header: [
			'Nombre',
			'Marca',
			'Acciones'
		],
		page: 1,
		last_page: 1,
		data: [],
		model: null,
		view: false,
		list_status: [
			{ value: '', label: 'Todos' },
			{ value: 0, label: 'Suspendidos' },
			{ value: 1, label: 'Activos' },
		],
		form: {
			status: '',
			search: '',
			brand_id: '',
			user_id: this.props.user.id,
			web: true
		}
	}
	componentDidMount() {
		this.setBrand(this.props.match.params.brand_id)
		setTimeout( async () =>{
            await $('.tooltip').tooltip('hide');
        },100)
	}
	componentDidUpdate(nextProps) {
		if (nextProps.location.pathname !== this.props.location.pathname) {
			let param  = this.props.location.pathname.split('/')
			param = param[param.length-1];
			this.setBrand(param)
		}
		setTimeout( async () =>{
            await $('.tooltip').tooltip('hide');
        },100)
	}
	setBrand = async (param) => {
		await this.setState({
			form: {
				...this.state.form,
				brand_id: param
			}
		})
		this.load()
	}
	load = () => {
		Globals.setLoading();
		axios.post('web/admin/models?page=' + this.state.page, this.state.form)
			.then(async res => {
				console.log(res)
				if (res.data.result) {
					if(this.state.page > 1 && res.data.models.data.length === 0){
						await this.setState({
							page: 1
						});
						this.load()
					}else{
						await this.setState({
							last_page: res.data.models.last_page,
							data: res.data.models.data
						});
					}
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

	getStatus = (status) => {
		let respuesta = "";
		switch(status) {
			case 0:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
				break;
			case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;
			default:
				break;
		
		}
		return respuesta;
	}

	close = async () => {
		await this.setState({
			create: false,
			view: false,
			edit: null,
			user: null,
			model: null,
			brands: null,
		});
		await this.load()
	}

	suspend = (item, i) =>{
		Globals.confirm('¿Desea suspender el modelo: ' + item.name + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/models/suspend',{ id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	activate = (item, i) => {
		Globals.confirm('¿Desea activar el modelo: ' + item.name + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('admin/models/active',{ id: item.id })
				.then(res => {
					if(res.data.result){
						Globals.showSuccess(res.data.msg);
						this.load();
					}else{
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	delete = (item, i) => {
		Globals.confirm('¿Desea eliminar el modelo: ' + item.name + '?',() => {
			Globals.setLoading("Guardando...");
			axios.post('web/admin/models/delete',{ model_id: item.id })
				.then(res => {
					Globals.showSuccess('Modelo eliminado con exito');
					this.load();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}
	change = e => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
        });
	}
	goBack = () => {
		this.props.history.goBack();
	}
	render() {
		return (
			<Menu history={ this.props.history }>
				<Modal
					title="Ver Modelo" 
					onClose={ this.close }
					visible={ this.state.view }>
					<ViewModel model={ this.state.model }/>
				</Modal>
				<Modal
					title={this.state.edit ? 'Editar Modelo' : "Nueva Modelo"}
					onClose={ this.close } 
					visible={ this.state.create }>
					<CreateEditModel
						brand_id = { this.state.form.brand_id }
						user={ this.props.user }
						edit={ this.state.edit }
						onClose={ this.close } />
				</Modal>
				<div id="home">
					<div className="row">
						<div className="col-md-2">
							<Button color=" " className="btn-align-bottom btn-filter" onClick={ this.goBack }>
								<span>Volver</span>
							</Button>
						</div>
						<div className="col-md-4">
							<Input
								color=" "
								name="search"
								label="Búsqueda"
								className="filter"
								onChange={ this.change } 
								value={ this.state.search }
								placeholder="Buscar por Nombre o Marca"
							/>
						</div>
						<div className="col-md-2">
							<Button color=" " className="btn-align-bottom btn-filter" onClick={ this.load }>
								<span>Filtrar</span>
							</Button>
						</div>
					</div>
					<Table data={ this.state.data.length } title="Modelos" header={ this.state.header } right={
								<Button small="true" onClick={ () => {
									this.setState({
										create: true
									});
								}}>
									<img src={AddIcon} alt="" />
								</Button>
							}>
							{ this.state.data.map((i,index) => {
								return (
									<tr key={ index }>
										<td>{ i.name }</td>
										<td>{ i.brand.name } </td>
										<td>
											<Button title="Ver" small="true" onClick={ () => {
												this.setState({
													model: i,
													view: true
												});
											} }>
												<Icon name="eye" />
											</Button>
											<Button
												title="Editar"
												small="true"
												color=" "
												style={{backgroundColor: '#0f54e0'}}
												onClick={() =>
													this.setState({
														create: true,
														edit: {
															element: i,
															index: index,
														}
													})
												}
											>
												<Icon name="edit" />
											</Button>
											{/* { i.status === 0 && <Button color="info" title="Activar" small="true" onClick={ () => {
												this.activate(i,index);
											} }>
												<Icon name="check" />
											</Button> }
											
											{ i.status === 1 && (
												<Button color="info" title="Suspender" small="true" onClick={ () => this.suspend(i,index) }>
													<Icon name="close" />
												</Button>
											) } */}
											<Button color="red" title="Eliminar" small="true" onClick={ () => this.delete(i,index) }>
												<Icon name="trash" />
											</Button>
										</td>
									</tr>
								)
							}) }
						</Table>

						<Pagination 
							pages={ this.state.last_page } 
							active={ this.state.page }
							onChange={ async page => {
								await this.setState({
									page: page
								});
								this.load();
						} } />
				</div>
			</Menu>
		)
	}
}

export default connect(state => {
    return {
		user: state.user
	}
})(Model);