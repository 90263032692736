import React from "react";

import { Input, InputFormat } from "../../components";
import { Globals } from "../../utils";

class Warehouses extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { warehouses } = this.props;

    return (
      <div className="row">
        <div className="col col-md">
          <div className="form-group">
            <div className="table-responsive table-warehouses">
              <table className="table table-bordered table-sm">
                <thead>
                  <tr>
                    <td colSpan="7">Productos por Almacen</td>
                  </tr>
                  <tr style={{ whiteSpace: "nowrap" }}>
                    <th>Almacen</th>
                    <th>Stock Min</th>
                    <th>Stock Max</th>
                    <th>% Utilidad Min.</th>
                    <th>% Utilidad Max.</th>
                    <th>% Utilidad Oferta</th>
                    <th>Ubicación Almacén</th>
                  </tr>
                </thead>
                <tbody>
                  {warehouses.map((Item, key) => {
                    return (
                      <tr key={key}>
                        <td style={{ whiteSpace: "nowrap" }}>{Item.name}</td>
                        <td>
                          <InputFormat
                            color="gray"
                            name="stock_min"
                            size="small"
                            onKeyPress={emitter => Globals.soloNumeros(emitter)}
                            onChange={emitter =>
                              this.props.onChange(emitter, key)
                            }
                            value={Item.stock_min}
                          />
                        </td>
                        <td>
                          <InputFormat
                            color="gray"
                            name="stock_max"
                            size="small"
                            maxLength={5}
                            onChange={emitter =>
                              this.props.onChange(emitter, key)
                            }
                            value={Item.stock_max}
                          />
                        </td>
                        {/* Porcentaje de utilidad 1 */}
                        <td>
                          <InputFormat
                            color="gray"
                            name="percentage_earning"
                            size="small"
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale="2"
                            maxLength={5}
                            onValueChange={values =>
                              this.props.onValueChange(
                                values,
                                "percentage_earning",
                                key
                              )
                            }
                            value={Item.percentage_earning}
                          />
                        </td>
                        {/* Porcentaje de utilidad 2 */}
                        <td>
                          <InputFormat
                            color="gray"
                            name="percentage_max"
                            size="small"
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale="2"
                            maxLength={5}
                            onValueChange={values =>
                              this.props.onValueChange(
                                values,
                                "percentage_max",
                                key
                              )
                            }
                            value={Item.percentage_max}
                          />
                        </td>
                        {/* Porcentaje de utilidad 3 */}
                        <td>
                          <InputFormat
                            color="gray"
                            name="percentage_offer"
                            size="small"
                            thousandSeparator={true}
                            allowNegative={false}
                            isNumericString={true}
                            decimalScale="2"
                            maxLength={5}
                            onValueChange={values =>
                              this.props.onValueChange(
                                values,
                                "percentage_offer",
                                key
                              )
                            }
                            value={Item.percentage_offer}
                          />
                        </td>
                        <td>
                          <Input
                            color="gray"
                            name="location"
                            size="small"
                            value={Item.location}
                            onChange={emitter =>
                              this.props.onChange(emitter, key)
                            }
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Warehouses;
