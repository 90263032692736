import React from "react";
import { connect } from "react-redux";

import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Input
} from "../../components";
import { axios, Globals, Format, Constants } from "../../utils";
import { Product, Branch, Warehouse } from "../../services";
import RemoveProductCompound from "./remove_product_compound";

// Components
import Menu from "../menu";
import CreateEditProduct from "./create_edit_product_compound";
import ViewProduct from "./view_product_compound";

class Products extends React.Component {
  state = {
    header: ["Nombre", "Código", "Categoría", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    warehouses: [],
    companies: [],
    branches: [],
    product: null,
    create: false,
    view: false,
    remove: false,
    providers: {},
    list_status: [
      { value: 0, label: "Suspendidos" },
      { value: 1, label: "Activos" }
    ],
    form: {
      status: "",
      search: ""
    }
  };

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(_, prevState) {
    if (prevState.create !== this.state.create && this.state.create) {
      this.maybeLoadUtils();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    const { user } = this.props;
    const enterprise_id = Globals.getEnterpriseId(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);
    this.products(enterprise_id, warehouse_id);
    this.warehouses(enterprise_id);
    this.companies();
  };

  products = (enterprise_id, warehouse_id) => {
    let param = this.state.form;
    param.user_id = enterprise_id;
    param.warehouse_id = warehouse_id;

    Globals.setLoading();
    axios
      .post("admin/products-compound/get?page=" + this.state.page, param)
      .then(res => {
        if (res.data.result) {
          const rawProducts = res.data.products.data.map(product => {
            const product_warehouse = product.product_warehouse.map(
              warehouse => {
                let lastMovement;
                if (warehouse.last_movement) {
                  lastMovement =
                    warehouse.last_movement.type === Constants.TYPE_ENTRY
                      ? warehouse.last_movement.current_stock +
                        warehouse.last_movement.amount
                      : warehouse.last_movement.current_stock -
                        warehouse.last_movement.amount;
                }
                else {
                  lastMovement = 0;
                }                

                return {
                  ...warehouse,
                  stock: lastMovement
                };
              }
            );

            return {
              ...product,
              product_warehouse: product_warehouse
            };
          });

          this.setState({
            last_page: res.data.products.last_page,
            data: rawProducts
          });
        }
      })
      .catch(err => {
        console.log(err);
      })
      .then(() => Globals.quitLoading());
  };

  maybeLoadUtils = () => {
    const { user } = this.props;

    const enterprise_id = Globals.getEnterpriseId(user);

    Promise.all([
      Branch.getBranchesByEnterprise({ Id: enterprise_id }),
      Warehouse.getWarehouse({ Id: enterprise_id })
    ])
      .then(response => {
        const branchesMap = Format.rawBranches(
          response[0],
          user.assigned_warehouses,
          user.role
        ).map(({ name, id }) => ({
          label: name,
          value: id
        }));

        const warehouseMap = Format.rawWarehouse(
          response[1],
          user.assigned_warehouses,
          user.role
        ).map(({ name, id, branch_id }) => ({
          label: name,
          value: id,
          branchId: branch_id
        }));

        this.setState({
          branches: [{ label: "Todos", value: 0 }, ...branchesMap],
          warehouses: [{ label: "Todos", value: 0 }, ...warehouseMap]
        });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  delete = ({ name, id }) => {
    const { user } = this.props;
    const enterpriseId = Globals.getUserId(user);

    Globals.confirm("¿Desea eliminar el producto: " + name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/products-compound/warehouses", { id: id, enterprise_id: enterpriseId })
        .then(() => {
          Globals.showSuccess("¡Producto eliminado exitosamente!");
          this.load();
        })
        .catch(({ response }) => Globals.showWarning(response.data.message))
        .then(() => Globals.quitLoading());
    });
  };

  companies = () => {
    const { user } = this.props;

    let param = {
      role: user.role,
      user_id: Globals.getEnterpriseId(user)
    };

    axios
      .post("admin/products-compound/companies", param)
      .then(res => {
        if (res.data.result) {
          let companies = [];
          res.data.companies.forEach((element, i) => {
            companies.push({
              label: element.name,
              value: element.id,
              models: element.models
            });
          });
          this.setState({
            companies: companies
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  // exportExcel = () => {
  //   const { user } = this.props;

  //   let param = {
  //     user_id: user.role === 3 ? user.id : user.enterprise_users.enterprise_id
  //   };

  //   axios({
  //     url: `admin/products/export/${param.user_id}`,
  //     method: "GET",
  //     responseType: "blob" // important,
  //     // params: { desde: data.desde, hasta:data.hasta, cli:data.cli }
  //   })
  //     .then(response => {
  //       const url = window.URL.createObjectURL(new Blob([response.data]));
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.setAttribute(
  //         "download",
  //         "Productos-" + this.props.user.name + ".xlsx"
  //       ); //or any other extension
  //       document.body.appendChild(link);
  //       link.click();
  //     })
  //     .catch(err => {
  //       Globals.showError();
  //     });
  // };

  warehouses = () => {
    const { user } = this.props;

    const Id = user.role === 3 ? user.id : user.enterprise_users.enterprise_id;

    axios
      .post("admin/products-compound/warehouses", {
        role: this.props.user.role,
        user_id: Id
      })
      .then(({ data }) => {
        if (data.result) {
          const warehouseMap = Format.rawWarehouse(
            data.warehouses,
            user.assigned_warehouses,
            user.role
          ).map(Item => {
            return {
              ...Item,
              id: Item.id,
              name: Item.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: "",
              percentage_earning: ""
            };
          });

          this.setState({
            warehouses: warehouseMap
          });
        } else {
          Globals.showError(data.msg);
        }
      })
      .catch(err => {
        console.log(err);
        Globals.showError();
      });
  };

  close = () => {
    this.setState(
      {
        create: false,
        view: false,
        remove: false,
        product: null,
        edit: null,
        user: null
      },
      () => this.load()
    );
  };

  destroyProduct = Item => {
    const { data } = this.state;
    const { user } = this.props;

    const Id = Globals.getEnterpriseId(user);

    Globals.confirm("¿Desea eliminar el producto: " + Item.name + "?", () => {
      Product.destroyProductCompound({ id: Item.id, creator_id: Id })
        .then(({ data: response }) => {
          const dataMap = data.filter(Product => Product.id !== Item.id);

          Globals.showSuccess("¡Producto eliminado exitosamente!");

          this.setState({
            data: dataMap
          });
        })
        .catch(() => Globals.showError());
    });
  };

  change = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  render() {
    const { view, create, branches, remove,product} = this.state;
    const { user } = this.props;
    const Id = Globals.getUserId(user);

    return (
      <Menu history={this.props.history}>
        {view && (
          <Modal title="Ver Producto Compuesto" onClose={this.close} visible>
            <ViewProduct data={this.state.product} />
          </Modal>
        )}

        {remove && (
          <Modal
            title={`Eliminar ${product.name}`}
            onClose={this.close}
            visible
          >
            <RemoveProductCompound product={product} onClose={this.close} />
          </Modal>
        )}

        {create && (
          <Modal
            className="modal-product"
            title={
              this.state.edit
                ? "Editar Producto Compuesto"
                : "Nuevo Producto Compuesto"
            }
            onClose={this.close}
            visible
          >
            <CreateEditProduct
              Id={Id}
              companies={this.state.companies}
              warehouses={this.state.warehouses}
              edit={this.state.edit}
              branches={branches}
              onClose={this.close}
            />
          </Modal>
        )}

        <div id="products">
          <div className="row">
            {/* <div className="col-md-2">
              <Button color="green" className="btn-align-bottom" onClick={ this.exportExcel }>
								Exportar
							</Button>
            </div> */}
            <div className="col-md-10">
              <Input
                color="white"
                name="search"
                label="Búsqueda"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre, Código, Categoría, Marca o Descripción"
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom" onClick={this.load}>
                Filtrar
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col col-md">
              <Table
                data={this.state.data.length}
                title="Productos Compuesto"
                header={this.state.header}
                right={
                  <Button
                    title="Agregar P. Compuesto"
                    outline="true"
                    small="true"
                    onClick={() => {
                      this.setState({
                        create: true
                      });
                    }}
                  >
                    <Icon name="plus" />
                  </Button>
                }
              >
                {this.state.data.map((Item, key) => {
                  return (
                    <tr key={Item.id}>
                      <td>{Item.name}</td>
                      <td>{Item.code}</td>
                      <td>
                        {Item.product_category
                          ? Item.product_category.category
                            ? Item.product_category.category.name
                            : "Sin categoría"
                          : "Sin categoría"}
                      </td>
                      <td>
                        <Button
                          title="Ver"
                          small="true"
                          onClick={() => {
                            this.setState({
                              product: Item,
                              view: true
                            });
                          }}
                        >
                          <Icon name="eye" />
                        </Button>
                        <Button
                          title="Editar"
                          color="primary"
                          small="true"
                          onClick={() =>
                            this.setState({
                              create: true,
                              edit: {
                                element: Item,
                                index: key
                              }
                            })
                          }
                        >
                          <Icon name="edit" />
                        </Button>
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() =>
                        this.setState({ remove: true, product: Item })
                      }
                    >
                      <Icon name="trash" />
                    </Button>
                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </div>

          <div className="row my-3">
            <div className="col col-md">
              <Pagination
                pages={this.state.last_page}
                active={this.state.page}
                onChange={page => {
                  this.setState(
                    {
                      page: page
                    },
                    () => {
                      this.load();
                    }
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Products);
