import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Input,
  Select,
  CheckBox,
  Icon,
  DatePicker,
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand } from "../../services";
import NumberFormat from "react-number-format";
import SearchIcon from '../../assets/icons/search.png';
import PrinterIcon from '../../assets/icons/printer.png';
import moment from "moment";

const HEADER_TABLE = [
  "Código\nProducto",
  "Nombre\nProducto",
  "Categoría\nProducto",
  "Marca\nProducto",
  "Modelo\nProducto",
  "Cantidad\nVendida",
  "Monto\nTotal ($)",
  "Cantidad\nPedidos",
];

class ReportProductsMostSold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trows: [],
      page: 1,
      last_page: 1,
      data: [],
      form: {
        user_id: this.props.user.id,
        since: "",
        until: "",
        search: "",
      },
      brands: [],
      user_id: this.props.user.id,
      dates: {
        minDate: "",
        maxDate: ""
      },
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.getProducts();
  };

  getProducts = (resetPage = null) => {
    let { page, form } = this.state;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    axios
      .post("admin/reports/mostSoldProducts?page=" + page, form)
      .then(res => {
        this.setState({
          last_page: res.data.products.last_page,
          data: res.data.products.data,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        search: "",
        since: "",
        until: "",
        user_id: this.props.user.id,
      },
      trows: [],
    }));
    await this.load();
  };

  print = () => {
    axios
      .post("admin/reports/mostSoldProducts/download", this.state.form)
      .then(res => {
        if(res.data.url_storage){
          const url = `${ENV.BasePublic}${res.data.url_storage}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  render() {
    const { trows } = this.state;

    return (
      <Menu history={this.props.history}>
        <div id="products" className="new-reports">
          <div className="row">
            <div className="col-md-4">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.form.search}
                placeholder="Buscar por nombre, código, modelo, categoría"
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            

            <div className="col-md-4">
              <div className="container-buttons">
                <Button 
                  className="btn-align-bottom btn-filter blue" 
                  color=" " 
                  onClick={async () => {
                    await this.setState({ page: 1 });
                    this.load();
                  }}
                >
                  <span>Filtrar</span>
                </Button>
                <Button className="btn-align-bottom btn-filter" color=" " onClick={this.returnState}>
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title={'Reporte de Productos más vendidos'}
            header={HEADER_TABLE}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print()}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td style={{ maxWidth: 'none' }}> {i.code} </td>
                  <td style={{ maxWidth: 'none' }}> {i.name} </td>
                  <td style={{ maxWidth: 'none' }}> {i.category?.name ?? '-'} </td>
                  <td style={{ maxWidth: 'none' }}> {i.brand?.name ?? '-'} </td>
                  <td style={{ maxWidth: 'none' }}> {i.model?.name ?? '-'} </td>
                  <td style={{ maxWidth: 'none' }}> {i.orders_quantity_sold} </td>
                  <td style={{ maxWidth: 'none' }}>
                    {Globals.formatMiles(Number(i.orders_total))}
                  </td>
                  <td style={{ maxWidth: 'none' }}> {i.orders_count} </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ReportProductsMostSold);
