import axios from "../../utils/axios";

class Warehouse {
  static async getWarehouse(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/warehouse/enterprise`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getWarehouses(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/warehouses/get", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getWarehouseByBranch(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/warehouses/branch", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Warehouse;
