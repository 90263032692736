import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  InputFormat,
} from "../../components";
import { Globals } from "../../utils";
import $ from "jquery";
import InputEdit from "./components/inputEdit";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { ExchangeServices } from "../../services";
import CreateEditExchange from './create_edit_exchange';
import AddIcon from '../../assets/icons/add.png';
import CalendarIcon from '../../assets/icons/calendar.png';

const TABLE_HEADER = [
  "Fecha",
  "Tasa de cambio",
  "Acción"
];
class Exchange extends React.Component {
  state = {
    page: 1,
    last_page: 1,
    data: [],
    brand: null,
    create: false,
    editing: false,
    view: false,
    date: new Date(),
    tasa: { date: new Date(), tasa: "8.30" },
    form: {
      conversion_rate: "",
      date_es: "",
      exchange_id: "",
      user_id:
        this.props.user.role == 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
    },
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }

  load = () => {
    Globals.setLoading();
    ExchangeServices.GetExchange()
      .then((res) => {
        this.setState({ data: res.exchange });
        Globals.quitLoading();
      })
      .catch((err) => {
        Globals.quitLoading();
      });
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      brand: null,
      editing: false,
    });
    await this.load();
  };

  change = (e, t) => {
    this.setState({
      form: {
        ...this.state.form,
        [t]: e,
      },
    });
  };

  edit = async (item) => {
    await this.setState({
      form: {
        exchange_id: item.id,
        date: item.updated_at,
        conversion_rate: item.conversion_rate,
      },
      editing: true,
    });
  };

  submit = async () => {
    Globals.setLoading();
    ExchangeServices.SetExchange(this.state.form)
      .then(res => {
        if (!res?.result) throw res;
        Globals.showSuccess('Tasa editada con éxito');
        this.close();
      })
      .catch(err => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  formatDate = (date) => {
    return moment(date).format("DD/MM/YYYY");
  };

  render() {
    const { create, form, editing } = this.state;
    return (
      <Menu history={this.props.history}>
        {create && (
          <Modal
            title={editing ? `Editar Tasa` : `Nueva Tasa`}
            onClose={this.close}
            visible
          >
            <CreateEditExchange onClose={this.close} />
          </Modal>
        )}

        <div id="home">
          <Table
            title="Tasa de cambio"
            data={[]}
            edit={editing}
            exchange={true}
            header={TABLE_HEADER}
            right={
              !this.state.data.length && (
                <Button
                  title="Agregar Tasa"
                  small="true"
                  onClick={() => this.setState({ create: true })}
                >
                  <img src={AddIcon} alt="Agregar Tasa" />
                </Button>
              )
            }
          >
            {this.state.data.map((item, key) => {
              return (
                <tr key={key}>
                  <td style={{ display: 'flex', alignItems: 'center', borderTop: 'none' }}>
                    <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>
                      {this.formatDate(editing ? new Date() : item.updated_at)}
                    </span>
                    {editing && (
                      <img src={CalendarIcon} alt="Calendario" style={{width: 24, marginLeft: '15px'}} />
                    )}
                  </td>
                  <td>
                    {editing ? (
                      <InputFormat
                        color="gray"
                        name="conversion_rate"
                        thousandSeparator={true}
                        allowNegative={false}
                        isNumericString={true}
                        decimalScale="2"
                        customClass="exchange"
                        value={form.conversion_rate}
                        onValueChange={v => this.change(v.value, "conversion_rate")}
                      />
                    ) : (
                      <span style={{ fontSize: '20pt', fontWeight: 'bold' }}>
                        {item.conversion_rate}
                      </span>
                    )}
                  </td>
                  <td style={{ maxWidth: 'none', borderTop: 'none' }}>
                    {editing ? (
                      <Button
                        small="true"
                        color=" "
                        style={{backgroundColor: '#0f54e0'}}
                        onClick={() => this.setState({ editing: false })}
                      >
                        <Icon name="close" />
                      </Button>
                    ) : (
                      <Button
                        small="true"
                        color="primary"
                        style={{backgroundColor: '#0f54e0'}}
                        onClick={() => this.edit(item)}
                      >
                        <Icon name="edit" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
            {editing && (
              <tr>
                <td></td>
                <td>
                  <div id="button" className="mt-4">
                    <Button
                      type="button"
                      color=" "
                      className="primary"
                      onClick={() => this.submit()}
                    >
                      <span>Guardar</span>
                    </Button>
                  </div>
                </td>
                <td className="border-0"></td>
              </tr>
            )}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async (page) => {
              await this.setState({
                page: page,
              });
              this.load();
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect((state) => {
  return {
    user: state.user,
  };
})(Exchange);
