import React from "react";
import { List } from "../../components";
import { Colors } from "../../utils";

class ViewModel extends React.Component {
  state = {
    model: this.props.model
  };

  getStatus = (status = this.state.model.status) => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  render() {
    return (
      <div className="text-center container-view-user">
        <List.Container>
          <List.Item label="Nombre">{this.state.model.name}</List.Item>
          <List.Item label="Código">{this.state.model.code}</List.Item>
          <List.Item label="Marca">{this.state.model.brand.name}</List.Item>
          <List.Item label="Status">
            <span
              style={{
                color: this.getStatus().color
              }}
            >
              {this.getStatus().text}
            </span>
          </List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewModel;
