import React from "react";
import { connect } from "react-redux";
import { on } from "jetemit";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  EditSerial,
  Modal,
  Select,
  Input,
  ModalScan,
  ViewSerial
} from "../../components";
import { Globals, Constants, Format } from "../../utils";
import CreateEditInventoryAdjustment from "./create_edit_inventory";
import { Product, Branch, Warehouse, Inventory } from "../../services";
import AddIcon from '../../assets/icons/add.png';
import CreateEditProvider from "./create_edit_provider";

const HEADER_TABLE = [
  "Nombre",
  "Código",
  "Tipo de Operación",
  // "Almacenes",
  "Sucursales",
  "Stock Actual",
  "Acciones"
];

const MOVEMENT_TYPE = {
  TRANSFER: 0,
  ENTRY: 1,
  EXIT: 2,
}

class InventoryAdjustment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      step: 1,
      last_page: 1,
      data: [],
      products: [],
      product:[],
      edit_serial: false,
      item_serial: null,
      warehouses: [],
      branches: [],
      showLg: true,
      visibleCreate: false,
      user: null,
      view: false,
      view_serials: false,
      unsubscriber: null,
      item_serials: null,
      showModalScan: false,
      itemSelected: null,
      serials: null,
      createProvider: false,
      providerCreated: false,
      form: {
        products: [],
        product:[],
        user_id:
          props.user.role === Constants.ROLE_MANAGER
            ? props.user.enterprise_users.enterprise_id
            : props.user.id,
        role: props.user.role,
        status: "",
        search: "",
        warehouse_id: "",
        branch_id: ""
      },
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();

    const unsubscriber = on(Constants.SCANNER, code => {
      const {
        showModalScan,
        step,
        showCustomer,
        visiblePayment,
        visible,
        uglyModal
      } = this.state;

      if (
        !visiblePayment &&
        !showCustomer &&
        !showModalScan &&
        !visible &&
        !uglyModal &&
        step === Constants.STEP_FORM
      ) {
        this.getOneProductByCode(code);
      }
      
    });

    this.setState({
      unsubscriber: unsubscriber
    });
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.visibleCreate !== this.state.visibleCreate &&
      this.state.visibleCreate
    ) {
      this.maybeLoadProducts();
    }

    if (
      prevState.form.branch_id !== this.state.form.branch_id
    ) {
      this.getWarehouses();
    }
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    let newForm = { ...form };

    if (user.assigned_warehouses.length > 0 && !newForm.branch_id) {
      newForm.warehouse_id = user.assigned_warehouses.map(
        ({ warehouse_id }) => warehouse_id
      );
    } else if (
      user.assigned_warehouses.length > 0 &&
      newForm.warehouse_id === ""
    ) {
      newForm.warehouse_id = user.assigned_warehouses.map(
        ({ warehouse_id }) => warehouse_id
      );
    }

    Product.getProductsInventory(page, newForm)
      .then(({ products_warehouse }) => {
        if (products_warehouse.data.last_page > 0) {
          if (products_warehouse.data.length > 0) {
            this.setState({
              last_page: products_warehouse.last_page,
              data: products_warehouse.data,
              page: page,
              products: products_warehouse.data,
            });
            
          
          } else {
            this.load(1);
          }
        } else {
          this.setState({
            last_page: products_warehouse.last_page,
            data: products_warehouse.data,
          });
        }
      })
      .catch(() => Globals.showError())
      .finally(() => this.getBranches());
      console.log(this.state.products)
  };

  close = () => {
    this.setState(
      {
        visibleCreate: false,
        showLg: true,
        view: false,
        edit: "",
        user: ""
      },
      () => this.load()
    );
  };

  
  getOneProductByCode = code => {
    const { user } = this.props;
    const { form } = this.state;

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Product.getOneProductByCode({
      user_id: userId,
      code: code,
      warehouse_id: form.warehouse_id
    })
      .then(response => {
        const configuration = Globals.getRate(user);
        const parseMap = Format.rawProducts(configuration, [response]);
        let product = parseMap[0];
        if (product.serialization == Constants.SERIALIZATION.ACTIVE) {
          product.quantity = 0;
          product.subtotal = 0;
          product.total = 0;
          product.vat = 0;
        }
        this.takeProduct(product);
      })
      .catch(err => {
        if (err.msg) Globals.showError(err.msg);
      });
  };

  change = emitter => {
    const { name, value } = emitter.target;
    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  onSuccess = async item => {
    console.log(item);
    let products = this.state.data;
    const {product, serials} = this.state;
    const products2 = products
    // products2.push({
    //   serials: item
    // })
    console.log(products2)
    // console.log(serials)
    const data2 = {
      products: products2,
      products_id: product.product_id,
      warehouse_id: product.warehouse_id,
      serials: item
    }

    // console.log(data2);
    // const product = [this.state.product];
    Inventory.check_serials(data2)
    .then(async response => {
      await this.setState({

      });
      this.load();
      Globals.showSuccess("¡Operación Exitosa!");
      this.props.onClose();
    })
    .catch(error => {
      if (error.hasOwnProperty("msg")) {
        Globals.showError(error.msg);
      }
      this.setState({
        submitted: false,
        errors: error
      });
    });
    const index = products.findIndex(i => i.id == this.state.itemSelected);
    
    products.serials = item;
    products.quantity = item.length;
    await this.setState({
      edit_serial: false,
      show_serials: false,
      serials_available: [],
      serialsCompromised: '',
      showModalScan: false,
      form: {
        ...this.state.form,
        products: products
      }
    });
    // this.handleNum(
    //   {
    //     target: {
    //       value: item.length,
    //       name: "quantity"
    //     }
    //   },
    //   index
    // );
  };

  onCancel = () => {
    this.setState({
      edit_serial: false
    });
  };

  handleSerial = item => {
    this.setState({
      edit_serial: true,
      item_serial: item
    });
  };

  maybeLoadProducts = () => {
    const { user } = this.props;
    const Id = user.role === 4 ? user.enterprise_users.enterprise_id : user.id;

    Product.getAllProductsByEnterprise({ user_id: Id })
      .then(response => {
        const rawProduct = response.map(Item => {
          let current_stock = 0;

          if (Item.last_movement) {
            current_stock =
              Item.last_movement.type === 1
                ? Item.last_movement.current_stock + Item.last_movement.amount
                : Item.last_movement.current_stock - Item.last_movement.amount;
          }

          return {
            id: Item.product.id,
            name: Item.product.name,
            code: Item.product.code,
            last_movement: current_stock,
            warehouse_id: Item.warehouse_id,
            cost: Item.cost,
            price: Item.price,
            quantity: "",
            change_price: false,
            cost_total: 0,
            type: Item.product.type,
            stock: current_stock,
            stock_min: Item.stock_min,
            stock_max: Item.stock_max,
            serialization: Item.product.serialization,
            product_details: Item.product.product_details,
            product_brand: Item.product.product_brand?.brand,
            product_model: Item.product.product_model?.model,
          };
        });

        this.setState({ products: rawProduct });
      })
      .catch(() => Globals.showError());
  };

  getBranches = () => {
    let { user } = this.props;
    user.enterprise_id = user.id;

    Branch.getBranches({
      role: user.role,
      enterprise_id: user.enterprise_id
    }).then(response => {
      if (response.result) {
        let branches_filtered = response.branches.data.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        branches_filtered = branches_filtered.map(({ id, name }) => ({
          value: id,
          label: name
        }));

        this.setState({
          branches: [...branches_filtered]
        });
      }
    });
  };

  getWarehouses = () => {
    console.log('aqui')
    const { user } = this.props;
    const { form } = this.state;

    user.enterprise_id =
      user.role === 4 ? user.enterprise_users.enterprise_id : user.id;

    if (!form.branch_id) {
      this.setState(state => ({
        warehouses: [],
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return
    }
    Warehouse.getWarehouses({
      role: user.role,
      user_id: user.enterprise_id,
      branch_id: form.branch_id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = response.warehouses.data.filter(
          ({ deleted_at }) => !deleted_at
        );

        warehouses_filtered = Format.rawWarehouse(
          warehouses_filtered,
          user.assigned_warehouses,
          user.role
        );

        warehouses_filtered = warehouses_filtered.map(({ id, name }) => ({
          value: id,
          label: name
        }));

        this.setState(state => ({
          warehouses: [...warehouses_filtered],
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  view = item => {
    this.setState({
      view_serials: true,
      item_serials: item.product,
      serials: item.serials
    });
  };

  onCloseSerials = () => {
    this.setState({
      view_serials: false,
      item_serials: null,
      serials: null
    });
  };

  createProvider = () => {
    this.setState({
      createProvider: true,
      providerCreated: false,
      visibleCreate: false,
    });
  }

  onCloseProvider = (isCreated) => {
    this.setState({
      createProvider: false,
      providerCreated: isCreated,
      visibleCreate: true,
    });
  }

  filtrarButton = () => {
    const { page } = this.state;

    if (page > 1) {
      this.load(1);
    }
    else if (page == 1) {
      this.load();
    }

  }

  getMovementType = (type) => {
    switch (type) {
      case MOVEMENT_TYPE.TRANSFER:
        return 'Traslado';

      case MOVEMENT_TYPE.ENTRY:
        return 'Ingreso';

      case MOVEMENT_TYPE.EXIT:
        return 'Egreso';
      
      default: return 'Sin movimientos';
    }
  }

  render() {
    const {
      visibleCreate,
      showLg,
      form,
      products,
      view_serials,
      item_serials,
      serials,
      showModalScan,
      itemSelected,
      warehouses,
      branches,
      createProvider,
      providerCreated,
    } = this.state;
    const { user } = this.props;

    const Id = user.role === 4 ? user.enterprise_users.enterprise_id : user.id;
    const __index = form.products.findIndex(i => i.id == itemSelected);

    return (
      <Menu history={this.props.history}>
        {visibleCreate && (
          <Modal
            className={showLg ? "modal-product" : ""}
            title={"Operaciones de Inventario"}
            onClose={this.close}
            visible
          >
            <CreateEditInventoryAdjustment
              Id={Id}
              products={products}
              edit={this.state.edit}
              branches={branches.filter(({ value }) => !!value)}
              createProvider={this.createProvider}
              providerCreated={providerCreated}
              onClose={this.close}
              user={user}
            />
          </Modal>
        )}

        {createProvider && (
          <Modal
            title="Nuevo Proveedor"
            onClose={this.onCloseProvider}
            visible
          >
            <CreateEditProvider onClose={this.onCloseProvider} />
          </Modal>
        )}

        {view_serials && (
          <Modal
            className="modal-product"
            title="Ver Seriales"
            visible
            onClose={() =>
              this.setState({
                item_serials: null,
                view_serials: false,
                serials: null
              })
            }
          >
            <ViewSerial
              onBack={() =>
                this.setState({
                  item_serials: null,
                  view_serials: false,
                  serials: null
                })
              }
              product={item_serials}
              serials={serials}
            />
          </Modal>
        )}

        {showModalScan && (
          <Modal
            className="modal-product"
            title="Ingresar seriales"
            onClose={() => {
              this.setState({
                showModalScan: false
              });
            }}
            visible
          >
            <ModalScan
              serials={this.serials}
              onSuccess={this.onSuccess}
              onCancel={() => {
                this.setState({
                  showModalScan: false
                });
              }}
            />
          </Modal>
        )}

        <div id="products">
          <div className="row">
            <div className="col-sm-12 col-md">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre o Código"
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="branch_id"
                label="Sucursales"
                className="filter"
                defaultname="Todos"
                disabledFirst="true"
                onChange={emitter => this.change(emitter)}
                value={this.state.form.branch_id}
                options={branches}
              />
            </div>
            {/* <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="warehouse_id"
                label="Almacenes"
                className="filter"
                defaultname="Todos"
                disabledFirst="true"
                onChange={this.change}
                value={this.state.form.warehouse_id}
                options={warehouses}
              />
            </div> */}
            <div className="col-md-2">
              <Button
                color=" "
                className="btn-align-bottom btn-filter"
                //onClick={this.load}
                onClick={
                  this.filtrarButton
                }
              >
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col col-md">
              <Table
                data={this.state.data.length}
                title="Operaciones de Inventario"
                header={HEADER_TABLE}
                right={
                  <Button
                    title="Agregar Op. Inventario"
                    small="true"
                    onClick={() => {
                      this.setState({
                        visibleCreate: true,
                        showLg: true
                      });
                    }}
                  >
                    <img src={AddIcon} alt="Agregar Op. Inventario" />
                  </Button>
                }
              >
                {this.state.data.map((Item, key) => {
                  Item.current_stock = 0;

                  if (Item.last_movement) {
                    Item.current_stock =
                      Item.last_movement.type === 1
                        ? Item.last_movement.current_stock +
                        Item.last_movement.amount
                        : Item.last_movement.current_stock -
                        Item.last_movement.amount;
                  }

                  return (
                    <tr key={key}>
                      <td>{Item.product.name}</td>
                      <td>{Item.product.code}</td>
                      <td>{this.getMovementType(Item?.last_movement?.type)}</td>
                      {/* <td>
                        {Item.warehouse?.name}{" "}
                        {Item.warehouse.deleted_at ? "(Eliminado)" : ""}
                      </td> */}
                      <td>
                        {Item.warehouse.branch.name}{" "}
                        {Item.warehouse.branch.deleted_at ? "(Eliminada)" : ""}
                      </td>
                      <td>{Item.current_stock}</td>
                      <td>
                        {Item.product.serialization == Constants.SERIALIZATION.ACTIVE && (
                          <Button
                            color=" "
                            title="Ver Seriales"
                            small="true"
                            style={{backgroundColor: '#5c068c'}}
                            onClick={() => this.view(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                        )}

                      </td>
                    </tr>
                  );
                })}
              </Table>
            </div>
          </div>
          <div className="row my-3">
            <div className="col col-md">
              <Pagination
                pages={this.state.last_page}
                active={this.state.page}
                onChange={page => {
                  this.setState(
                    {
                      page: page
                    },
                    () => this.load()
                  );
                }}
              />
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(InventoryAdjustment);
