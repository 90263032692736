import axios from "../../utils/axios";

class ExchangeServices {

    static async GetExchange(){
      return new Promise((resolve, reject) => {
        axios.post("admin/currencies/exchange").then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async SetExchange(form){
        return new Promise((resolve, reject) => {
          axios.post("admin/currencies/exchange-set",form).then(
            ({ data }) => {
              // http success
              resolve(data);
            },
            ({ response }) => {
              const { data } = response;
              // http failed
              reject(data);
            }
          );
        });
      }
  
}


export default ExchangeServices;
