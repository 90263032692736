import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Pagination, Table } from "../../../components";
import { Globals } from "../../../utils";
import { Provider, ExpensesIngress } from "../../../services";

// Components
import Menu from "../../menu";
import FilterReport from "./filterFormReport";

const TABLE_HEADER = [
  "Proveedor",
  "Fecha",
  "Hora",
  "Metodo de Pago",
  "Monto",
  "Realizado por"
];

class ExpensesReportPayments extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      providers: [],
      page: 1,
      current_page: 1,
      last_page: 1
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Promise.all([
      ExpensesIngress.reportExpensesPayment(page, { enterprise_id: enterpriseId, user_id: user.id, role: user.role}),
      Provider.getProviders({ Id: enterpriseId, getDeleted: true, withPerson: true }),
    ])
    .then(response => {
      const { current_page, data, last_page } = response[0];
      console.log(data, 'data')
      const providers = response[1];

      this.setState({
        providers: providers,
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;
    const { page } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    ExpensesIngress.reportExpensesPayment(page, { ...form, enterprise_id: enterpriseId, user_id: user.id, role: user.role})
    .then(response => {
      const { current_page, data, last_page } = response;
      console.log(data, 'data')
      this.setState({
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  }

  loadMore = () => {
    const { user } = this.props;
    const { page, form } = this.state;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    ExpensesIngress.reportExpensesPayment(page, { ...form, enterprise_id: enterpriseId, user_id: user.id, role: user.role})
    .then(response => {
      const { current_page, data, last_page } = response;
      console.log(data['10'], 'data')
      this.setState({
        data: data,
        current_page: current_page,
        last_page: last_page
      });
    })
    .catch(() => Globals.showError());
  }

  close = () => {
    this.setState(
      {
        payment: null,
        view: false
      });
  };

  render() {
    const { data, last_page, page, providers } = this.state;
    const { user, history } = this.props;

    return (
      <Menu history={history}>

        <div id="payment">
          <div className="container-fluid">
            <FilterReport
              submitted={form => this.filterMap(form)}
              user={user}
              providers={providers.map((Item, key) => {
                return {
                  value: Item.id,
                  label:
                    (Item.person || {}).first_name ||
                    (Item || {}).name
                };
              })}
            />
            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Reporte de Pagos"
                  header={TABLE_HEADER}
                >

                  {data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          {(Item.expenses_ingress.provider.person || {}).first_name ||
                            (Item.expenses_ingress.provider || {}).expenses_ingress.name} {Item.expenses_ingress.provider.deleted_at ? " - Eliminado" : ""}
                        </td>
                        <td>
                          {moment(Item.date).format("DD/MM/YYYY")}
                        </td>
                        <td>
                          {moment(Item.date).format("HH:mm:ss")}
                        </td>
                        <td>
                          { Globals.getPaymentsType((Item || {}).payment_type)}
                        </td>
                        <td>
                          { Globals.formatMiles(Item.amount) }
                        </td>
                        <td>
                          {
                            Item.creator != null && 
                              (Item.creator.person || {}).first_name ||
                                (Item.creator || {}).name
                            ||
                              "Eliminado"
                          }
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={last_page}
                  active={page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => {
                        this.loadMore();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ExpensesReportPayments);