import React from "react";

import { Button, DatePicker, Input, Select } from "../../components";
import { Globals } from "../../utils";
import moment from "moment";

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        user_id: Globals.getUserId(props.user),
        search: '',
        init_date: '',
        end_date: '',
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
    };
  }

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  onSubmit = (emitter) => {
    emitter.preventDefault();

    const { form } = this.state;

    const init_date = form.init_date ? moment(form.init_date).format('YYYY-MM-DD') : '';
    const end_date = form.end_date ? moment(form.end_date).format('YYYY-MM-DD') : '';

    this.props.submitted({ ...form, init_date, end_date });
  }

  render() {

    const { form, dates } = this.state;

    return (
      <form onSubmit={this.onSubmit}>
        <div className="row">
          <div className="col-md">
            <Input
              color=" "
              name="search"
              label="Búsqueda"
              className="filter"
              onChange={this.handleChange}
              value={form.search}
              placeholder="Buscar por Nombre, Email, Cédula/RIF o Código de caja"
            />
          </div>

          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              className="filter"
              minDate={dates.minDate}
              value={form.init_date}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    init_date: value
                  }
                }));
              }}
            />
          </div>

          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              className="filter"
              maxDate={dates.maxDate}
              value={form.end_date}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    end_date: value
                  }
                }));
              }}
            />
          </div>

          <div className="col-md">
            <Button color=" " className="btn-align-bottom btn-filter">
              <span>Filtrar</span>
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
