import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseBlackIcon from '../../assets/icons/close-black.png';
import moment from 'moment';
import LocationIcon from '../../assets/icons/location.png';
import ENV from "../../utils/env";
import { Constants, Globals } from '../../utils';

// @ts-ignore
const google = window.google;

export class ModalView extends Component {

  state = {
    points: this.props.item?.details?.filter(item => item?.client?.latitude != null && item?.client?.longitude != null).map((item) => {
      return {
        ...item,
        latitude: parseFloat(item?.client?.latitude),
        longitude: parseFloat(item?.client?.longitude),
      }
    }),
    item: null,
    autosale: this.props.autosale ?? false,
    itemAutoSaleDetail: null,
    showPhoto: false,
  }

  componentDidMount() {
    this.setMap();
  }

  setMap = async () => {
    const directionsService = new google.maps.DirectionsService();
    const directionsDisplay = new google.maps.DirectionsRenderer({
      suppressMarkers: true,
      polylineOptions: { 
        strokeColor: "#07a043",
        strokeWeight: 5
      }
    });

    if (this.state.points.length > 0) {
      const map = new google.maps.Map(document.getElementById('map-planner-view'), {
        zoom: 13,
        center: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
        draggable: true,
        zoomControl: true,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        scaleControl: false,
        rotateControl: false
      });

      var waypoints = [];

      for (let i = 0; i < this.state.points.length; i++) {
        if (i != 0 && i != (this.state.points.length - 1)) {
          waypoints.push({
            location:  new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
            stopover:true
          });
        }

        const marker = new google.maps.Marker({
          position: new google.maps.LatLng(this.state.points[i].latitude,this.state.points[i].longitude),
          map,
          title: '',
          icon: ENV.BasePublic + 'img/marker.png',
          animation: google.maps.Animation.DROP,
          draggable: false
        });

        google.maps.event.addListener(marker, 'click', () => {
          this.setState({
            item: this.state.points[i],
            itemAutoSaleDetail: this.state.autosale == true ? this.state.points[i] : null,
          });
        });
      }

      if (this.state.points.length > 1) {
        const request = {
            origin: new google.maps.LatLng(this.state.points[0].latitude,this.state.points[0].longitude),
            destination: new google.maps.LatLng(this.state.points[this.state.points.length - 1].latitude,this.state.points[this.state.points.length - 1].longitude),
            waypoints,
            optimizeWaypoints: true,
            travelMode: google.maps.DirectionsTravelMode.DRIVING
        };

        directionsDisplay.setMap(map);
        directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                directionsDisplay.setDirections(response);
            }
        });
      }

      if (!!this.state.points.length) {
        this.setState({ item: this.state.points[0] });
      }
    }
  }

  render() {
    const item = this.props.item;
    console.log(item)
    const { itemAutoSaleDetail, showPhoto } = this.state;
    console.log('itemAutoSaleDetail: ', itemAutoSaleDetail)

    const keys = ['id'];
    const zones = [...item?.seller?.zones,item?.seller?.zone].filter(i => i !== null).filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    );

    return (
      <div className="modal-planer-view">
        {showPhoto && (
          <div className="modal-planner-view-photo">
            <div className="close-photo" onClick={() => this.setState({ showPhoto: false })}>
              <img src={CloseBlackIcon} alt="Cerrar" />
            </div>
            <div className="photo-wrapper">
              <img src={Globals.fromPhotos(this.state.item?.visit?.photo)} alt="" />
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-6">
            <div id="map-planner-view"></div>
          </div>
          <div className="col-md-6">
            <div className="text-right">
              <button className="btn-close" onClick={ () => this.props.onClose() }>
                <img src={ CloseBlackIcon } />
              </button>
            </div>
            <p className="title">Vendedor</p>
            <h3 className="seller">{ item?.seller?.name }</h3>
            <p className="zones">{ zones.map(i => i.name).filter(i => i != null).join(', ') }</p>
            {
              this.state.item != null && (
                <React.Fragment>
                  <p className="gray">Cliente</p>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="name">{ this.state.item?.client?.name }</p>
                      <p className="location">
                        <img src={ LocationIcon } />
                        { this.state.item?.client?.address }
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p>{ this.state.item?.client?.phone }</p>
                      <p>{ this.state.item?.client?.email }</p>
                    </div>
                    {this.props.item.type === Constants.VISIT_PLANNER_TYPE.PLANNED && (
                      <div className="col-md-3">
                        <p className="gray">Tipo de visita</p>
                        <p>{ this.state.item?.visit_type?.name || '-' }</p>
                      </div>
                    )}
                  </div>
                  {
                    this.state.item.visit != null && (
                      <React.Fragment>
                        <p className="blue">Última visita</p>
                        <div className="row">
                          <div className="col-md-3">
                            <p className="gray">Fecha</p>
                            <p>{ this.state.item?.init_date ? moment(this.state.item?.init_date).format('DD-MM-YYYY') : '-' }</p>
                          </div>
                          <div className="col-md-3">
                            <p className="gray">Hora de Inicio</p>
                            <p>{ this.state.item?.init_hour ? moment(this.state.item?.init_hour,'HH:mm:ss').format('HH:mm a') : '-' }</p>
                          </div>
                          <div className="col-md-3">
                            <p className="gray">Hora de fin</p>
                            <p>{ this.state.item?.finish_hour ? moment(this.state.item?.finish_hour,'HH:mm:ss').format('HH:mm a') : '-' }</p>
                          </div>
                          <div className="col-md-3">
                            <p className="gray">Resultado de la visita</p>
                            {
                              this.state.item?.visit?.results?.map((item) => {
                                return (
                                  <p className="result-visit">{ item.name }</p>
                                )
                              })
                            }
                          </div>
                        </div>
                        <p className="gray">Observación</p>
                        <p className="comments">{ this.state.item?.visit?.comments || '-' }</p>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                          <div>
                            <p className="blue">Próxima visita</p>
                            <p className="date">{ moment(this.state.item?.visit?.next_date).format('DD-MM-YYYY') }</p>
                          </div>
                          {!!this.state.item?.visit?.photo && (
                            <div onClick={() => this.setState({ showPhoto: true })}>
                              <img src={Globals.fromPhotos(this.state.item?.visit?.photo)} style={{ width: 100, height: 100, objectFit: 'contain', cursor: 'pointer' }} alt="" />
                            </div>
                          )}
                          <div />
                        </div>
                        <p className="container-check">
                          <div className={ `check ${ this.state.item?.visit?.remember ? 'active' : '' }` }></div>
                          Recordatorio
                        </p>
                      </React.Fragment>
                    )
                  }

                  {/* Detalles resultado de la autoventa */}
                  {
                    (this.state.autosale == true && itemAutoSaleDetail) && (
                      <React.Fragment>
                        <p className="blue">Última visita</p>
                        {itemAutoSaleDetail.finish_date !== null && (
                          <div className="row">
                            <div className="col-md-3">
                              <p className="gray">Fecha</p>
                              <p>{itemAutoSaleDetail.init_date ? moment(itemAutoSaleDetail.init_date).format('DD-MM-YYYY') : '-'}</p>
                            </div>
                            <div className="col-md-3">
                              <p className="gray">Hora de Inicio</p>
                              <p>{itemAutoSaleDetail.init_hour ? moment(itemAutoSaleDetail.init_hour, 'HH:mm:ss').format('HH:mm a') : '-'}</p>
                            </div>
                            <div className="col-md-3">
                              <p className="gray">Hora de fin</p>
                              <p>{itemAutoSaleDetail.finish_hour ? moment(itemAutoSaleDetail.finish_hour, 'HH:mm:ss').format('HH:mm a') : '-'}</p>
                            </div>
                            <div className="col-md-3">
                              <p className="gray">Resultado de la visita</p>
                              {
                                itemAutoSaleDetail.results?.map((item) => {
                                  return (
                                    <p className="result-visit">{item.result?.name}</p>
                                  )
                                })
                              }
                            </div>
                            
                            {/* <p className="gray">Observación</p>
                            <p className="comments">{itemAutoSaleDetail.observations || '-'}</p>
                            {itemAutoSaleDetail.next_date && (
                              <>
                                <p className="blue">Próxima visita</p>
                                <p className="date">{moment(itemAutoSaleDetail.next_date).format('DD-MM-YYYY')}</p>
                              </>
                            )}
                            <p className="container-check">
                              <div className={ `check ${ itemAutoSaleDetail.remember ? 'active' : '' }` }></div>
                              Recordatorio
                            </p> */}
                          </div>
                        )}

                        <p className="gray">Observación</p>
                        <p className="comments">{itemAutoSaleDetail.observations || '-'}</p>
                        {itemAutoSaleDetail.next_date && (
                          <>
                            <p className="blue">Próxima visita</p>
                            <p className="date">{moment(itemAutoSaleDetail.next_date).format('DD-MM-YYYY')}</p>
                          </>
                        )}
                        <p className="container-check">
                          <div className={`check ${itemAutoSaleDetail.remember ? 'active' : ''}`}></div>
                          Recordatorio
                        </p>
                      </React.Fragment>
                    )
                  }
                </React.Fragment>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalView)