import React from "react";
import { Select, DatePicker, Button } from "../../components";

const TYPE_LIST = [
  { value: 0, label: "Todos" },
  { value: 1, label: "Orden de compra" },
  { value: 2, label: "Factura de compra" }
];

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        type: 0,
        year: "",
        month: "",
        since: "",
        until: "",
        provider: 0
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      months: [],
      years: []
    };
  }

  handleChange = (emitter, string) => {
    const { name, value } = emitter.target;
    // console.log(name, value);
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  render() {
    let { providers } = this.props;
    const { form } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              minDate={this.state.dates.minDate}
              value={this.state.form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              maxDate={this.state.dates.maxDate}
              value={this.state.form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...this.state.form,
                    until: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <Select
              color="white"
              name="provider"
              label="Proveedores"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={this.state.form.provider}
              options={[...providers, { value: 0, label: "Todos" }].reverse()}
            />
          </div>
          <div className="col-md">
            <Select
              color="white"
              name="type"
              label="Tipo"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={this.state.form.type}
              options={TYPE_LIST}
            />
          </div>
          <div className="col-md">
            <div className="form-group">
              <Button
                className="btn-block btn-align-bottom"
                onClick={this.load}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
