import React from "react";
import { Button, Select, Input } from "../../../components";

const STATUS = [
  {
    label: "Suspendidas",
    value: 2
  },
  {
    label: "Activas",
    value: 1
  }
];
class Filter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        status: "",
        search: "",
        bank_id: props.bankId
      }
    };
  }

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  componentWillReceiveProps(props) {
    const { bankId } = props
    this.setState( state => ({
      form: {
        ...state.form,
        bank_id:bankId
      }
    }))
  }
  
  render() {
    const { form } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md-2">
              <Button className="btn-align-bottom" onClick={this.props.back}>
                Volver
              </Button>
          </div>
          <div className="col-md-4">
            <Input
              color="white"
              name="search"
              label="Búsqueda"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.search}
              placeholder="Buscar por Cuenta Bancaria"
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="status"
              label="Estatus"
              style={{ textTransform: "capitalize" }}
              defaultname="Todos"
              defaultValue=""
              disabledFirst="false"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.status}
              options={STATUS}
            />
          </div>

          <div className="col-md-2">
            <div className="form-group">
              <Button
                className="btn-block btn-align-bottom"
                onClick={this.load}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
