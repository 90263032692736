import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
	Table,
	Pagination,
	Button,
	Icon,
	Modal,
	Select,
	Input
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditWarehouse from "./create_edit_warehouse";
import AddIcon from '../../assets/icons/add.png';

class Warehouse extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			header: [
				"Nombre",
				"Acciones"
			],
			page: 1,
			last_page: 1,
			data: [],
			user: null,
			view: false,
			form: {
				status: null,
				search: null,
				user_id: this.props.user.id,
				role: this.props.user.role,
				admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
			},
			products: [],
		};
	}

	abortController = new AbortController()

	componentDidMount() {
		this.load();
	}

	componentWillUnmount() {
		this.abortController.abort()
	}

	load = () => {
		Globals.setLoading();
		let param = this.state.form;
		param.role = this.props.user.role;
		if (this.props.user.role === 3) {
			param.user_id = this.props.user.id;
		}
		if (this.props.user.role === 4) {
			param.user_id = this.props.user.enterprise_users.enterprise_id;
		}
		axios
			.post("web/admin/warehouses?page=" + this.state.page, param)
			.then(res => {
				if (res.data.warehouses) {
					const formatWarehouses = res.data.warehouses.data;
					const mainIdx = formatWarehouses.findIndex(x => x.id === this.props.user.warehouse_principal);
					if (mainIdx >= 0) {
						const main = formatWarehouses[mainIdx];
						formatWarehouses.splice(mainIdx, 1);
						formatWarehouses.unshift(main);
					}

					this.setState({
						last_page: res.data.warehouses.last_page,
						data: formatWarehouses,
					});
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});

		// this.getProducts()

	};

	// getProducts = () => {
	// 	axios
	// 		.post("web/admin/warehouses/products", {user_id: this.props.user.id})
	// 		.then(res => {
	// 			if (res.data.products) {
	// 				this.setState({
	// 					products: res.data.products,
	// 				});
	// 			}
	// 		})
	// 		.catch(err => {
	// 			Globals.showError();
	// 		})
	// 		.then(() => {
	// 			Globals.quitLoading();
	// 		});
	// }

	close = () => {
		this.setState({
			create: false,
			view: false,
			edit: null,
			user: null
		});

		this.load();
	};

	delete = (item, i) => {
		// Verifica si hay productos y si la suma total de las cantidades es mayor a cero
		const hasProductsAndNonZeroQuantity = item.warehouse_products?.some(product => product.quantity > 0);
	
		if (hasProductsAndNonZeroQuantity)
			return Globals.showWarning('No se puede eliminar el almacén ya que tiene productos asociados con cantidad mayor a cero');
	
		Globals.confirm("¿Desea eliminar el almacén: " + item.name + "?", () => {
			Globals.setLoading("Guardando...");
			axios
			   .post(`web/admin/warehouses/delete`, { warehouse_id: item.id })
			   .then(res => {
					Globals.showSuccess('Almacén eliminado');
					this.load();
				})
			   .catch(err => {
					Globals.showError(err.response.data.msg);
				})
			   .finally(() => {
					Globals.quitLoading();
				});
		});
	};	

	handleChange = emitter => {
		const { name, value } = emitter.target;

		this.setState({
			form: {
				...this.state.form,
				[name]: value
			}
		});
	};

	render() {
		const { history, user } = this.props
		const { create, data, edit, header, search, products } = this.state
		const titleTable = 'Almacenes';
		return (
			<Menu history={history}>

				{create && (
					<Modal
						title={edit
							? edit?.element?.id == this.props.user.warehouse_principal ? 'Almacén' : 'Editar almacén'
							: 'Crear almacén'
						}
						onClose={this.close}
						visible
					>
						<CreateEditWarehouse 
							edit={edit} 
							onClose={this.close}
						/>
					</Modal>
				)}

				<div id="home">
					<div className="row">
						<div className="col-md">
							<Input
								color=" "
								name="search"
								label="Búsqueda"
								className="filter"
								onChange={this.handleChange}
								value={search}
								placeholder="Buscar por Nombre"
							/>
						</div>
						<div className="col-md">
							<Button
								className="btn-align-bottom btn-filter"
								color=" "
								onClick={async () => {
									await this.setState({ page: 1 });
									this.load();
								}}
							>
								<span>Filtrar</span>
							</Button>
						</div>
					</div>
					<Table
						data={data.length}
						title={titleTable}
						header={header}
						right={
							(user.level_id === Constants.ROLE_ADMIN || (user.level_id === Constants.ROLE_MODERATOR && user.warehouse_id == null)) && (
								<Button
									title="Agregar Almacén"
									small="true"
									onClick={() => {
										this.setState({
											create: true
										});
									}}
								>
									<img src={AddIcon} alt="Agregar Almacén" />
								</Button>
							)
						}
					>
						{data.map((Item, index) => {
							return (
								<tr key={index}>
									<td style={{ maxWidth: 'none' }}>
										{Item.type === Constants.WAREHOUSES.TYPE.MOVIL && !Item.name.includes('(Móvil)') ?
											`${Item.name} (Móvil)` :
											Item.name
										}
									</td>
									<td style={{ maxWidth: 'none' }} >
										<Button
											title="Editar"
											small="true"
											color=" "
											style={{ backgroundColor: '#0f54e0' }}
											onClick={() =>
												this.setState(
													{
														edit: {
															element: Item,
															index: index
														}
													},
													() => {
														this.setState({ create: true });
													}
												)
											}
										>
											<Icon name="edit" />
										</Button>

										{Item.id != this.props.user.warehouse_principal && (
											<Button
												color="red"
												title="Eliminar"
												small="true"
												onClick={() => this.delete(Item, index)}
											>
												<Icon name="trash" />
											</Button>
										)}
									</td>
								</tr>
							);
						})}
					</Table>

					<Pagination
						pages={this.state.last_page}
						active={this.state.page}
						onChange={page => {
							this.setState(
								{
									page: page
								},
								() => this.load()
							);
						}}
					/>
				</div>
			</Menu>
		);
	}
}

export default connect(state => {
	return {
		user: state.user
	};
})(Warehouse);
