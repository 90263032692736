import React from "react";
import { connect } from "react-redux";

import { Role, User } from "../../services";
import { Button, Input, File, Select } from "../../components";
import { Constants, Types, Globals } from "../../utils";

import { Branch, Warehouse } from "../../services";

class CreateEditUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      submitted: "",
      form: {
        id: "",
        name: "",
        phone: "",
        document_type: "V",
        document: "",
        level: this.props.user.level,
        role: "",
        email: "",
        password: "",
        password_confirmation: "",
        image: "",
        branch_id: 0,
        warehouse_id: "",
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id
      },
      errors: [],
      branches: [],
      warehouses: [],
      roles: [
        // {
        //   value: Constants.ROLE_ADMIN,
        //   label: 'Admin'
        // },
        {
          value: Constants.ROLE_MODERATOR,
          label: 'Moderador'
        },
        {
          value: Constants.ROLE_SELLER,
          label: 'Vendedor'
        },       
        {
          value: Constants.ROLE_MANAGER,
          label: 'Gerente'
        },       
      ],
      edit: false,
      textButton: "Crear"
    };

    this.handleChange = this.handleChange.bind(this);
  }

  abortController = new AbortController();

  componentDidMount() {
    const { edit } = this.props;

    this.load();

    if (!edit) {
      return;
    }

    this.edit();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    const { user } = this.props;
    const userId = user.id;

    // Role.getRolesByEnterprise({
    //   Id: userId
    // })
    //   .then(response => {
    //     let rolesId = response.map(({ id, display_name }) => ({
    //       value: id,
    //       label: display_name
    //     }));

    //     this.setState({
    //       roles: rolesId
    //     });
    //   })
    //   .then(() => {
    //     this.getBranches();
    //   })
    //   .catch(() => Globals.showError());
  };

  getBranches = () => {
    const { user } = this.props;

    const enterpriseId = user.id;

    Branch.getBranches({
      role: user.role,
      enterprise_id: enterpriseId
    }).then(response => {
      if (response.result) {
        const branches_filtered = response.branches.data.filter(
          ({ status, deleted_at }) =>
            status === Constants.STATUS_ACTIVE && !deleted_at
        );
        this.setState(state => ({
          ...state,
          branches: branches_filtered,
          form: {
            ...state.form,
            warehouse_id: state.form.warehouse_id ? state.form.warehouse_id : ""
          }
        }));
      }
    });
  };

  getWarehouses = id => {
    if (!id || id === "0") {
      let warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }
    let { user } = this.props;
    user.enterprise_id = user.id;

    Warehouse.getWarehouses({
      role: user.role,
      user_id: user.enterprise_id,
      branch_id: id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = response.warehouses.data.filter(function(
          warehouse
        ) {
          return !warehouse.deleted_at && warehouse.status === 1;
        });
        this.setState({
          warehouses: warehouses_filtered
        });
      }
    });
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    if (this.state.submitted) {
      return;
    }

    const { form, edit } = this.state;
    const { user } = this.props;

    this.setState({ submitted: true, errors: [] });

    form.Id = user.id;
    form.level = form.role;

    switch (edit) {
      case true:
        User.editUserEnterprise(form)
          .then(response => {
            const { result, msg } = response;

            if (!result) {
              this.setState({ submitted: false }, () => {
                Globals.showError(msg);
              });

              return;
            }

            this.setState(this.defaultState);
            Globals.showSuccess("¡Usuario editado exitosamente!");
            this.props.onClose();
          })
          .catch(error => {
            this.setState({ submitted: false, errors: error });
          });
        break;
      case false:
        User.createUserEnterprise(form)
          .then(response => {
            const { result, msg } = response;

            if (!result) {
              this.setState({ submitted: false }, () => {
                Globals.showError(msg);
              });

              return;
            }

            this.setState(this.defaultState);
            Globals.showSuccess("¡Usuario creado exitosamente!");
            this.props.onClose();
          })
          .catch(error => {
            this.setState({ submitted: false, errors: error });
          });
        break;
      default:
        break;
    }
  };

  edit = async () => {
    let documents = {};

    const { element } = this.props.edit;
    console.log(element)

    // if(element.person.document){
    //   if (
    //     !element.person.document.indexOf("-") ||
    //     parseInt(element.person.document)
    //   ) {
    //     documents = {
    //       document_type: "V",
    //       document: element.person.document
    //     };
    //   } else {
    //     let division = element.person.document.split("-");
    //     documents = {
    //       document_type: division[0],
    //       document: division[1]
    //     };
    //   }
    // }
    

    // if (element.name) {
    //   if (element.name.indexOf("-") <= -1) {
    //     element.name = element.name;
    //   } else {
    //     let division = element.name.split("-");
    //     element.name = division[1];
    //   }
    // }

    // let warehouse_id,
    //   branch_id = 0;

    // if (element.assigned_warehouses.length > 0) {
    //   if (element.assigned_warehouses.length === 1) {
    //     branch_id = element.assigned_warehouses[0].branch_id;
    //     warehouse_id = element.assigned_warehouses[0].warehouse_id;
    //   } else {
    //     let count = element.assigned_warehouses.reduce((obj, v) => {
    //       obj[v.branch_id] = (obj[v.branch_id] || 0) + 1;
    //       return obj;
    //     }, {});
    //     if (Object.keys(count).length === 1) {
    //       branch_id = element.assigned_warehouses[0].branch_id;
    //     }
    //   }
    // }

    // if (branch_id) {
    //   await this.getWarehouses(branch_id);
    // }

    await this.setState({
      form: {
        id: element.id,
        name: element.name,
        email: element.email,
        phone: element.phone,
        //document_type: documents.document_type,
        document: element.document,
        level: element.level_id,
        role: element.level_id,
        password: "",
        password_confirmation: "",
        admin_id: element.admin_id,
      },
      edit: true,
      textButton: "Editar"
    });

    // if (Types[user.role].value === Types[3].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else if (Types[user.role].value === Types[4].value) {
    //   this.setState({
    //     ...this.state.form,
    //     role: element.ROLE_SELLER_subuser
    //       ? element.ROLE_SELLER_subuser.ROLE_SELLER_id
    //       : ""
    //   });
    // } else {
    //   this.setState({
    //     ...this.state.form,
    //     level: element.roles[0].id
    //   });
    // }
  };

  showSelect = () => {
    const { user } = this.props;
    let select;

    // if (
    //   Types[user.role].value === Types[1].value ||
    //   Types[user.role].value === Types[2].value
    // ) {
    //   select = (
    //     <div className="col-md-6">
    //       <Select
    //         color=" "
    //         label="Tipo de usuario"
    //         name="level"
    //         labelClass="material"
    //         className="material"
    //         defaultname="Seleccione"
    //         value={this.state.form.level ? this.state.form.level : ""}
    //         options={[
    //           {
    //             label: "Administrador",
    //             value: 2
    //           }
    //         ]}
    //         error={`${this.hasErrorFor("level") ? "is-invalid" : ""}`}
    //         invalidfeedback={this.renderErrorFor("level")}
    //         onChange={this.handleChange}
    //       />
    //     </div>
    //   );
    // } else if (this.state.form.id != user.id) {
    //   select = (
    //     <div className="col-md-6">
    //       <Select
    //         color=" "
    //         label="Rol"
    //         name="role"
    //         labelClass="material"
    //         className="material"
    //         options={this.state.roles}
    //         value={this.state.form.role}
    //         error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
    //         invalidfeedback={this.renderErrorFor("role")}
    //         onChange={this.handleChange}
    //       />
    //     </div>
    //   );
    // }

    select = (
      <div className="col-md-6">
        <Select
          color=" "
          label="Rol"
          name="role"
          labelClass="material"
          className="material"
          options={this.state.roles}
          value={this.state.form.role}
          error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
          invalidfeedback={this.renderErrorFor("role")}
          onChange={this.handleChange}
        />
      </div>
    );

    return select;
  };

  showSelectWarehouse = () => {
    const { user } = this.props;
    let select;

    const isSeller = Number(this.state.form.role) === Constants.ROLE_SELLER;
    const isAdmin = Number(this.state.form.role) === Constants.ROLE_MODERATOR;

    // if (
    //   (Types[user.role].value === Types[3].value ||
    //   Types[user.role].value === Types[4].value) && this.state.form.id != user.id
    // ) {
    // }

    select = (
      <div className="row">
        <div className="col-md-6">
          <Select
            color=" "
            name="branch_id"
            label="Sucursal"
            labelClass="material"
            className="material"
            //defaultname={isSeller ? 'Seleccione' : 'Todos'}
            defaultname={isAdmin ? 'Todos' : 'Seleccione'}
            defaultvalue='0'
            disabledFirst={isAdmin ? 'false' : 'true'}
            onChange={async e => {
              await this.handleChange(e);
              await this.getWarehouses(this.state.form.branch_id);
            }}
            value={this.state.form.branch_id}
            options={this.state.branches.map(i => {
              return {
                value: i.id,
                label: i.name
              };
            })}
          />
        </div>
        <div className="col-md-6">
          <Select
            color=" "
            name="warehouse_id"
            label="Almacén"
            labelClass="material"
            className="material"
            // defaultname={isSeller ? 'Seleccione' : 'Todos'}
            // disabledFirst={isSeller ? 'true' : 'false'}
            defaultname={isAdmin ? 'Todos' : 'Seleccione'}
            disabledFirst={isAdmin ? 'false' : 'true'}
            onChange={this.handleChange}
            value={this.state.form.warehouse_id}
            options={this.state.warehouses.map(i => {
              return {
                value: i.id,
                label: i.name
              };
            })}
          />
        </div>
      </div>
    );

    return select;
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { submitted } = this.state;
    const textNameField = this.props.user.role === 1 ? "Nombre/Razón Social" : "Nombre y apellido";
    const sameUser = this.state.form.id === this.props.user.id

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="container-create-edit-user">
          <div className="row">
            {/* <div className="col-md-6">
              <Input
                type="text"
                color=" "
                value={this.state.form.name}
                name="name"
                label="Nombre de usuario"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                onChange={this.handleChange}
              />
            </div> */}
            <div className="col-md-12">
              <Input
                type="text"
                color=" "
                value={this.state.form.name}
                name="name"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                label={textNameField}
                className="material"
                labelClass="material"
                onChange={this.handleChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                color=" "
                value={this.state.form.phone}
                name="phone"
                type="text"
                maxLength={11}
                onKeyPress={e => {
                  Globals.soloNumeros(e);
                }}
                label="Teléfono"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("phone") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("phone")}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-6">
              <div style={{ display: "flex" }}>
                {/* <Select
                  color=" "
                  label="Tipo"
                  name="document_type"
                  className="material"
                  labelClass="material"
                  options={Constants.TYPE_DOCUMENTS}
                  value={this.state.form.document_type}
                  error={`${
                    this.hasErrorFor("document_type") ? "is-invalid" : ""
                  }`}
                  invalidfeedback={this.renderErrorFor("document_type")}
                  onChange={this.handleChange}
                /> */}
                <Input
                  color=" "
                  value={this.state.form.document}
                  type="text"
                  name="document"
                  label="Cédula"
                  className="material"
                  labelClass="material"
                  maxLength={this.state.form.document_type === "J" ? 9 : 8}
                  onKeyPress={e => {
                    Globals.soloNumeros(e);
                  }}
                  error={`${this.hasErrorFor("document") ? "is-invalid" : ""}`}
                  invalidfeedback={this.renderErrorFor("document")}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>

          <div className="row">
            {this.showSelect()}

            <div className={ sameUser ? 'col-md-12' : "col-md-6"}>
              <Input
                color=" "
                type="email"
                value={this.state.form.email}
                name="email"
                label="Email"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("email") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("email")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* {this.showSelectWarehouse()} */}

          <div className="row">
            <div className="col-md">
              <Input
                color=" "
                value={this.state.form.password}
                name="password"
                type="password"
                autoComplete="off"
                label="Contraseña"
                className="material"
                labelClass="material"
                error={`${this.hasErrorFor("password") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("password")}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md">
              <Input
                color=" "
                value={this.state.form.password_confirmation}
                name="password_confirmation"
                type="password"
                autoComplete="off"
                label="Confirmar Contraseña"
                className="material"
                labelClass="material"
                error={`${
                  this.hasErrorFor("password_confirmation") ? "is-invalid" : ""
                }`}
                invalidfeedback={this.renderErrorFor("password_confirmation")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {/* <File
            placeholder={
              this.state.edit
                ? "Cambiar imagen de Perfil (opcional)"
                : "Agregar Imagen de Perfil (opcional)"
            }
            placeholdersuccess={
              typeof this.state.form.image == "string"
                ? "Cambiar imagen (Opcional)"
                : "Imagen Agregada"
            }
            showcheck={true.toString()}
            onChange={this.handleChange}
            name="image"
            value={this.state.form.image}
            inputstyle={{
              display: "contents"
            }}
            className="btn-product"
          /> */}

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button" className="d-flex justify-content-center">
              <Button color=" " type="submit" className="primary" disabled={submitted}>
                <span>{this.state.textButton}</span>
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

CreateEditUser.defaultState = {
  form: {
    id: "",
    name: "",
    name: "",
    phone: "",
    document_type: "V",
    document: "",
    level: "",
    role: "",
    email: "",
    password: "",
    password_confirmation: "",
    image: ""
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditUser);
