import React from "react";

const InputGroup = props => (
  <div className={ props.itemRight != null || props.itemLeft != null ? 'input-group' : 'form-group' }>
    {
      props.itemLeft != null && (
        <div className="input-group-prepend">
          <span className={ `input-group-text ${
            props.color ? "input-" + props.color : "input-white"
          }`}>{ props.itemLeft }</span>
        </div>
      )
    }
    <input
      {...props}
      type={props.type ? props.type : "text"}
      className={`form-control${props.size === "small" ? "-sm" : ""} ${
        props.color ? "input-" + props.color : "input-white"
      } ${props.error ? props.error : ""}`}
      name={props.name}
    />
    {
      props.itemRight != null && (
        <div className="input-group-append">
          <span className={ `input-group-text ${
            props.color ? "input-" + props.color : "input-white"
          }`}>{ props.itemRight }</span>
        </div>
      )
    }
  </div>
);

export default InputGroup;
