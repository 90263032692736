import React from "react";
import { DatePicker, Button } from "../../components";

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        since: props.since ? props.since : "",
        until: props.until ? props.until : "",
      },
    };
  }

  handleChange = (value, target) => {
    this.setState(state => ({
      form: {
        ...state.form,
        [target]: value
      }
    }));
  };

  render() {
    const { form } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              className="filter"
              value={form.since}
              onChange={v => this.handleChange(v, 'since')}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              className="filter"
              value={form.until}
              onChange={v => this.handleChange(v, 'until')}
            />
          </div>

          <div className="col-md">
            <Button
              color=" "
              className="btn-align-bottom btn-filter">
              <span>Filtrar</span>
            </Button>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
