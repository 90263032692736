import React from "react"; 

import { Input } from "../../components";

import { Globals, Format } from "../../utils";
import { Product } from "../../services";

class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      search: "",
      suggestions: []
    };
  }

  AutoComplete = ({ search }) => {
    const { user, Id, form } = this.props;

    if (!search) {
      this.setState({ suggestions: [] });
      return;
    }

    const configuration = Globals.getRate(user);

    Product.getProductsByName({
      user_id: Id,
      search: search,
      warehouse_id: form.warehouse_id
    })
      .then(response => {
        const parseMap = Format.rawProducts(configuration, response);

        this.setState({ suggestions: parseMap });
      })
      .catch(() => Globals.showError());
  };

  firstElement = emitter => {
    emitter.preventDefault();
    const { suggestions: Products } = this.state;

    if (Products.length === 0 || (Products[0] || {}).last_movement === 0) {
      return;
    }

    this.props.onClick(Products[0]);
    this.props.onClose();
  };

  handleChange = (emitter, string) => {
    const { name, value } = emitter.target;

    switch (name) {
      case "search":
        this.setState({ [name]: value }, () => {
          this.AutoComplete(this.state);
        });
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <form onSubmit={this.firstElement}>
        <div className="row">
          <div className="col col-md">
            <Input
              color="gray"
              name="search"
              label="Buscar"
              placeholder="Ingrese el nombre del producto"
              value={this.state.search}
              onChange={this.handleChange}
            />
            <div id="suggestion">
              {this.state.suggestions.length > 0
                ? this.state.suggestions.map((Item, key) => {
                    return (
                      <div
                        key={key}
                        onClick={() => {
                          this.setState({ suggestions: [] }, () => {
                            this.props.onClick(Item);
                            this.props.onClose();
                          });
                        }}
                      >
                        {Item.code + " - " + Item.name}
                      </div>
                    );
                  })
                : null}
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
