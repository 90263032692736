import React from 'react';
import { Button, Input } from './';

class ViewSerials extends React.Component {

	state = {
		product: this.props.product
	}

	constructor(props) {
		super(props);

		let product = { ...this.state.product };
		product.serials = this.props.serials;

		this.state = {
			product: product
		}
	}
	
	render() {
		return (
			  <div className="modal-serial">
				<p className="product_name">{ this.state.product.name }</p>
					
				<div className="row">
					{
						this.state.product.serials.map((item,index) => (
							<div className="col-md-4" key={ index }>
								<Input
									color="gray"
				                    type="text"
				                    value={ item.serial }
				                    disabled={ true }
				                />
							</div>
						))
					}
				</div>

				<div className="row-flex row-flex-buttons">
					<div className="col-flex text-center">
						<Button className="btn-red" onClick={ this.props.onBack }>
	                      Volver
	                    </Button>					
					</div>			
				</div>
			</div>
		)
	}
}

export default ViewSerials;