import React from 'react';
import { List } from '../../components';
import { Colors } from '../../utils';
// import { Avatar, Icon, List } from '../../components';
// import { env, Colors } from '../../utils';
// import User from '../../assets/img/user.jpg';

class ViewCategory extends React.Component {

	state = {
		category: this.props.category
	}

	getStatus = (status = this.state.category.status) => {
		let respuesta = "";
		switch(status) {
			case 0:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
				break;
				
			case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;
			default:
				break;
		}
		return respuesta;
	}
		
	render() {
		return (
			<div className="text-center container-view-user">
				{/* <Avatar 
					source={ User } 
					size="130px" /> */}
				<List.Container>
					<List.Item label="Nombre">
						{ this.state.category.name }
					</List.Item>
				</List.Container>
			</div>
		)
	}
}

export default ViewCategory;