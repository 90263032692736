import React from 'react';
import { List } from '../../components';
import { Colors } from '../../utils';
// import { Avatar, Icon, List } from '../../components';
// import { env, Colors } from '../../utils';
// import User from '../../assets/img/user.jpg';

class ViewProductProvider extends React.Component {

	state = {
		provider: this.props.provider
	}
	
	render() {
		return (
			<div className="text-center container-view-user">
				{/* <Avatar 
					source={ User } 
					size="130px" /> */}
				<List.Container>
					<List.Item label="Nombre">
						{ this.state.provider.provide.name }
					</List.Item>
					<List.Item label="E-Mail">
						{ this.state.provider.provide.email }
					</List.Item>
                    <List.Item label="Código">
						{ this.state.provider.price }
					</List.Item>

				</List.Container>
			</div>
		)
	}
}

export default ViewProductProvider;