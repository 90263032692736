import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Input,
  DatePicker,
} from "../../components";
import { axios, Constants, Globals } from "../../utils";
import CreateEditPurchase from "./create_edit_purchase";
import AddIcon from '../../assets/icons/add.png';

class Purchases extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        "Número",
        "Fecha",
        "Método de Pago",
        "Descuento General",
        "Descuento de Productos",
        "Monto Total",
        "Estatus",
        "Acciones"
      ],
      page: 1,
      last_page: 1,
      data: [],
      user: null,
      form: {
        status: null,
        search: null,
        user_id: this.props.user.id,
        role: this.props.user.role,
        since: "",
        until: "",
        web: true,
        admin_id: this.props.user.admin_id != null ? this.props.user.admin_id : this.props.user.id
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      convertModal: false,
      item: [],
      client: [],
      methods: [],
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.getPurchases();
    this.getPaymentMethods();
  }

  componentWillUnmount() {
    this.abortController.abort()
  }

  getPurchases = () => {
    Globals.setLoading();
    let param = Globals.copy(this.state.form);
    param.user_id = this.props.user.id;
    param.admin_id = this.props.user.admin_id || this.props.user.id;
    param.since = !!param.since ? moment(param.since).format('YYYY-MM-DD') : undefined;
    param.until = !!param.until ? moment(param.until).format('YYYY-MM-DD') : undefined;

    axios
      .post("web/admin/purchases?page=" + this.state.page, param)
      .then(res => {
        if (res.data.purchases) {
          this.setState({
            last_page: res.data.purchases.last_page,
            data: res.data.purchases.data,
            methods: res.data?.payment_methods,
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };

  getPaymentMethods = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.user_id = this.props.user.id;
    axios
      .post("web/admin/purchases/methods?page=" + this.state.page, param)
      .then(res => {
        if (res.data.purchases) {
          this.setState({
            last_page: res.data.purchases.last_page,
            data: res.data.purchases.data,
            methods: res.data?.payment_methods,
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };

  close = () => {
    this.setState({
      create: false,
      edit: null,
      user: null,
    });

    this.getPurchases();
  };

  delete = (purchase_id) => {
    Globals.confirm("¿Seguro de eliminar la orden de compra?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`admin/purchases/delete`, { purchase_id, user_id: this.props.user.id })
        .then(res => {
          Globals.showSuccess('Orden de compra eliminada');
          this.getPurchases();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  print = (id) => {
    let param = Globals.copy(this.state.form);
    param.purchase_id = id;
    param.user_id = this.props.user.id;
    param.currency = Constants.CURRENCIES.DOLARES;

    axios
      .post("web/admin/purchases/download", param)
      .then(res => {
        if(res.data.url){
          const url = `${res.data.url}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      //search: '',
      form: {
        status: null,
        search: '',
        user_id: this.props.user.id,
        role: this.props.user.role,
        since: "",
        until: "",
        web: true,
        admin_id: this.props.user.admin_id != null ? this.props.user.admin_id : this.props.user.id
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
    }))
    await this.getPurchases();
  };

  render() {
    const {history} = this.props
    const { create, data, edit, header } = this.state
    return (
      <Menu history={history}>
        {create && (
          <Modal
            title={edit ? 'Editar compra' : 'Nueva compra'}
            onClose={this.close}
            visible
            size="xl"
          >
            <CreateEditPurchase 
              edit={edit}
              onClose={this.close} 
              user={this.props.user}
            />
          </Modal>
        )}

        <div id="home">
          <div className="row">
            <div className="col-md-3">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.handleChange}
                value={this.state.form.search}
                placeholder="Buscar por código"
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-3">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-3" style={{ display: 'flex', padding: 0, gap: '1rem' }}>
              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                style={{ minWidth: 110 }}
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.getPurchases();
                }}
              >
                <span>Filtrar</span>
              </Button>
              <Button
                className="btn-align-bottom btn-filter"
                color=" "
                style={{ minWidth: 110 }}
                onClick={this.returnState}
              >
                <span>Limpiar</span>
              </Button>
            </div>
          </div>
          <Table
            data={data.length}
            title="Compras"
            header={header}
            right={
              <Button
                title="Agregar compra"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="Agregar compra" />
              </Button>
            }
          >
            {data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{ item.number_format }</td>
                  <td>{ moment(item.created_at).format('DD-MM-YYYY HH:mm') }</td>
                  <td>{ item.method?.name ?? '-' }</td>
                  <td>
                    { Globals.formatMiles(item.discount) }
                  </td>
                  <td>
                    { Globals.formatMiles(item.product_discount) }
                  </td>
                  <td className="text-right">
                    { Globals.formatMiles(item.total) }
                  </td>
                  <td>{ item.status_text }</td>
                  <td>
                      <Button
                        color="info"
                        title="Descargar"
                        small="true"
                        onClick={() => this.print(item.crypt)}
                      >
                        <Icon name="download" />
                      </Button>
                      <Button
                        color="red"
                        title="Eliminar"
                        small="true"
                        onClick={() => this.delete(item.id)}
                      >
                        <Icon name="trash" />
                      </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.getPurchases()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Purchases);
