import axios from "../../utils/axios";

class Product {
  static async getProductsByEnterprise(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/search-products`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getAllProductsByEnterprise(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/enterprises`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProductsFromCompound(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products-compound/search-products`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProductsByCode(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/search-code`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getOneProductByCode(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/getbycode`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProductsByName(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/search-name`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProductsInventory(page, param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/inventory/get?page=${page}`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async destroyProduct(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/delete`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProducts(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
        )
      })
    }

  static async destroyProductCompound(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products-compound/delete`, param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getCompanies(param) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/products/companies`, param).then(
        ({data}) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      )
    })
  }
}


export default Product;
