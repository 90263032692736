import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Menu from '../menu';
import { Table, Pagination, Button, Icon, Modal, Select, Input } from '../../components';
import { axios, Globals, Colors } from '../../utils';
import CreateEditZone from './create_edit_zone';
import ViewColor from './view_zone';
import $ from 'jquery';
import AddIcon from '../../assets/icons/add.png';

class Zones extends React.Component {
	state = {
		header: [
			'Nombre',
			'Acciones'
		],
		page: 1,
		last_page: 1,
		data: [],
		brand: null,
		view: false,
		list_status: [
			{ value: '', label: 'Todos' },
			{ value: 0, label: 'Suspendidos' },
			{ value: 1, label: 'Activos' }
		],
		form: {
			status: null,
			search: null,
			user_id: this.props.user.role == 4 ? this.props.user.enterprise_users.enterprise_id : this.props.user.id,
			role: this.props.user.role
		}
	}
	componentDidMount() {
		this.load()
		setTimeout(async () => {
			await $('[data-toggle="tooltip"]').tooltip('hide');
		}, 300)
	}
	componentDidUpdate() {
		setTimeout(async () => {
			await $('[data-toggle="tooltip"]').tooltip('hide');
		}, 300)
	}
	load = () => {
		Globals.setLoading();
		axios.post('web/admin/zones?page=' + this.state.page, this.state.form)
			.then(async res => {
				if (res.data) {
					await this.setState({
						last_page: res.data.zones.last_page ?? 1,
						data: res.data.zones
					});
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	}

	getStatus = (status) => {
		let respuesta = "";
		switch (status) {
			case 0:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
				break;
			case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;
			default:
				break;
		}
		return respuesta;
	}

	close = async () => {
		await this.setState({
			create: false,
			view: false,
			edit: null,
			brand: null,
		});
		await this.load()
	}

	suspend = (item, i) => {
		Globals.confirm('¿Desea suspender la color: ' + item.name + '?', () => {
			Globals.setLoading("Guardando...");
			axios.post('admin/brands/suspend', { id: item.id })
				.then(res => {
					Globals.showSuccess(res.data.msg);
					this.load();
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	activate = (item, i) => {
		Globals.confirm('¿Desea activar la color: ' + item.name + '?', () => {
			Globals.setLoading("Guardando...");
			axios.post('admin/brands/active', { id: item.id })
				.then(res => {
					Globals.showSuccess(res.data.msg);
					this.load();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}

	delete = (item, i) => {
		Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
			Globals.setLoading("Guardando...");
			axios.post('web/admin/zones/delete', { zone_id: item.id })
				.then(res => {
					Globals.showSuccess('zona eliminada con exito');
					this.load();
				})
				.catch(err => {
					Globals.showError(err.response.data.msg);
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	}
	change = e => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	}
	render() {
		return (
			<Menu history={this.props.history}>
				<Modal
					title="Ver zona"
					onClose={this.close}
					visible={this.state.view}>
					<ViewColor brand={this.state.brand} />
				</Modal>
				<Modal
					title={this.state.edit ? 'Editar zona' : "Nueva zona"}
					onClose={this.close}
					visible={this.state.create}>
					<CreateEditZone
						user={this.props.user}
						edit={this.state.edit}
						onClose={this.close} />
				</Modal>
				<div id="home">
					<div className="row">
						<div className="col-md-5">
							<Input
								color=" "
								name="search"
								label="Búsqueda"
								className="filter"
								onChange={this.change}
								value={this.state.form.search}
								placeholder="Buscar por Nombre"
							/>
						</div>
						{/* <div className="col-md-5">
							<Select
								color="white"
								name="status"
								label="Estatus"
								defaultname="Seleccione"
								className="filter"
								onChange={ this.change }
								value={ this.state.status }
								options={ this.state.list_status.map(i => {
									return {
										value: i.value,
										label: i.label
									}
								}) }
							/>
						</div> */}
						<div className="col-md-2">
							<Button color=" " className="btn-align-bottom btn-filter" onClick={this.load}>
								<span>Filtrar</span>
							</Button>
						</div>
					</div>
					<Table
						data={this.state.data.length}
						title="Zonas"
						header={this.state.header}
						right={
							<Button
								title="Agregar Zona"
								small="true"
								onClick={() => {
									this.setState({
										create: true
									});
								}}
							>
								<img src={AddIcon} alt="Agregar zona" />
							</Button>
						}>
						{this.state.data.map((i, index) => {
							return (
								<tr key={index}>
									<td>{i.name}</td>
									{/* <td>{ i.code }</td> */}
									{/* <td>{ i.owner.person.first_name } { i.owner.deleted_at ? '(Eliminado)' : '' }</td> */}
									{/* <td style={ {color: this.getStatus(i.status).color }}>
											{ this.getStatus(i.status).text }
										</td> */}
									<td style={{ maxWidth: 'none' }}>
										<Button
											title="Ver"
											small="true"
											color="primary"
											style={{ backgroundColor: '#5c068c' }}
											onClick={() => {
												this.setState({
													brand: i,
													view: true
												});
											}}
										>
											<Icon name="eye" />
										</Button>
										<Button
											title="Editar"
											color=" "
											small="true"
											style={{ backgroundColor: '#0f54e0' }}
											onClick={() =>
												this.setState({
													create: true,
													edit: {
														element: i,
														index: index,
													}
												})}
										>
											<Icon name="edit" />
										</Button>
										{/* { i.status === 0 && <Button color="warning" title="Activar" small="true" style={{backgroundColor: '#d39e00'}} onClick={ () => {
												this.activate(i,index);
											} }>
												<Icon name="check" />
											</Button> }
											{ i.status === 1 && (
												<Button color="warning" title="Suspender" small="true" style={{backgroundColor: '#d39e00'}} onClick={ () => this.suspend(i,index) }>
													<Icon name="close" />
												</Button>
											) } */}
										<Button color="red" title="Eliminar" small="true" onClick={() => this.delete(i, index)}>
											<Icon name="trash" />
										</Button>
									</td>
								</tr>
							)
						})}
					</Table>

					<Pagination
						pages={this.state.last_page}
						active={this.state.page}
						onChange={async page => {
							await this.setState({
								page: page
							});
							this.load();
						}} />
				</div>
			</Menu>
		)
	}
}

export default connect(state => {
	return {
		user: state.user
	}
})(Zones);