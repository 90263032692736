import React, { Fragment } from "react";
import { connect } from "react-redux";
import moment from 'moment'
import { Globals, Constants } from "../../../utils";
import { Button, Select, DatePicker, InputFormat, Input } from "../../../components";
import { Bank, DebtsToPay as DebtsToPayService } from "../../../services";

const METHOD_TYPE_LIST = [
  { value: 1, label: "Efectivo" },
  { value: 3, label: "Debito" },
  { value: 4, label: "Transferencia" }
];

class PayDebt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      textButton: "Pagar",
      form: {
        method_type: false,
        amount: props.Item.total,
        purchase_order_id: props.Item.id,
        deposit: 0,
        rest:0,
        date: new Date(),
        bank_account_id: "",
        bank_id: "",
        balance: 0,
        expenses_ingress_id: props.Item.id,
        creator_id: props.user.id,
        expenses_ingress_class: props.expenses_ingress_class,
        reference_number: ""
      },
      banks: props.banks,
      deposit: props.deposit || false,
      dates: {
        minEmissionDate: moment.now(),
        minReceptionDate: moment.now(),
        minExpiredDate: moment.now()
      },
      bank_accounts: [],
      errors: []
    };
  }

  handleValueChange = (values, name) => {
    const { floatValue } = values;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: floatValue
      }
    }));
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.bank_id !== this.state.form.bank_id &&
      this.state.form.bank_id
    ) {
      this.maybeLoadBankAccounts();
    }
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  maybeLoadBankAccounts = () => {
    const { form } = this.state

    Bank.getBankAccounts({ bank_id: form.bank_id })
      .then(response => {
        let bankAccountsMapped = [];

        if (form.method_type && parseInt(form.method_type) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .filter(Item => Item.type === Constants.TYPE_BANK_ACCOUNTS_LIST.CASH_ONLY)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? Item.last_balance.total : 0
              };
            })
        } else {
          bankAccountsMapped = response
            .filter(Item => Item.status === Constants.STATUS_ACTIVE)
            .map((Item, key) => {
              return {
                value: Item.id,
                label: Item.account_number,
                balance: Item.last_balance ? Item.last_balance.total : 0
              };
            })
        }

        this.setState(state => ({
          form: {
            ...state.form,
            bank_account_id: ""
          },
          bank_accounts: bankAccountsMapped
        }));

      })
      .catch(() => Globals.showError());
  }

  handleChange = emitter => {
    const { name, value } = emitter.target;
    this.state.form.deposit = value
    const { banks } = this.props;
    const { bank_accounts, form: {amount, deposit} } = this.state;
    

    const total = parseFloat(amount - deposit);
    if (deposit >= amount) {
      Globals.showError('La cantidad a abonar no puede ser mayor o igual al Monto')

      this.setState(state => ({
          form: {
            ...state.form,
            deposit : 0,
            rest : amount,
          },
      }));
      return
    }else{
      this.setState(state => ({
        form: {
          ...state.form,
          rest : total
        },
      }));
    }

    switch (name) {
      case "method_type":
        let banksMapped = []
        if (value && parseInt(value) === Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.CASH) {
          banksMapped = banks.filter(Item => Item.type === Constants.TYPE_BANKS_LIST.CASH_ONLY)
        } else {
          banksMapped = banks.filter(Item => Item.type !== Constants.TYPE_BANKS_LIST.CASH_ONLY)
        }
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_id: "",
            bank_account_id: "",
            balance: 0
          },
          banks: banksMapped,
          bank_accounts: []
        }));
        break;
      case "bank_id":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            bank_account_id: "",
            balance: 0
          },
        }), () => this.maybeLoadBankAccounts());
        break; default:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          },
        }));
        break;
      case "reference_number":
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          },
        }))
        break;
      case "bank_account_id":
        const bankAccount = bank_accounts.find(Item => Item.value === parseInt(value));
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value,
            balance: parseFloat(bankAccount.balance)
          },
        }));
        break;
    }
  };

  handleSubmit = (e) => {
    e.preventDefault()
    const form = { ...this.state.form }

    if (this.state.deposit && form.deposit <= 0) {
      Globals.showError('La cantidad a abonar debe ser mayor a 0')
      return
    }

    if (this.state.deposit && form.deposit >= form.amount) {
      Globals.showError('La cantidad a abonar no puede ser mayor o igual al Monto')
      return
    }
    
    if (!form.amount) {
      Globals.showError('El monto debe ser mayor a 0')
      return
    }

    if (!form.method_type) {
      Globals.showError('Debe seleccionar un método de pago')
      return
    }

    if (!form.date) {
      Globals.showError('Fecha inválida')
      return
    }

    if (!form.bank_account_id) {

      Globals.showError('Debe elegir una cuenta bancaria')
      return
    }


    DebtsToPayService.payDebt(form)
      .then(response => {
        Globals.showSuccess(response.msg)
        this.props.onClose()
        this.props.loadMore();
      })
      .catch(error => {
        // Globals.showError(error.msg)
      })
  }

  render() {
    const { banks, deposit } = this.state;
    return (
      <form onSubmit={this.handleSubmit}>
        <div className="text-center container-create-edit-user">
          <div className="row">
            <div className="col-md-4">
              <Select
                color="gray"
                label="Metodo de Pago"
                name="method_type"
                disabledFirst={false}
                defaultname="Seleccione"
                defaultValue=""
                options={METHOD_TYPE_LIST}
                value={this.state.form.method_type}
                error={`${
                  this.hasErrorFor("method_type") ? "is-invalid" : ""
                  }`}
                invalidfeedback={this.renderErrorFor("method_type")}
                onChange={this.handleChange}
              />
            </div>
            <div className="col-md-4">
              <InputFormat
                color="gray"
                disabled={true}
                name="amount"
                thousandSeparator={true}
                allowNegative={false}
                isNumericString={true}
                decimalScale="2"
                label="Monto"
                value={this.state.form.amount}
                error={`${this.hasErrorFor("amount") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("amount")}
              />
            </div>
            <div className="col-md-4">
              <DatePicker
                color="gray"
                label="Fecha de Emisión"
                minDate={this.state.dates.minEmissionDate}
                value={this.state.form.date}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      date: value,
                    },
                  }));
                }}
              />
            </div>
          </div>
          { deposit &&
            <div className="row">
              <div className="col-md-4">
                <Input
                  type="number"
                  color="gray"
                  name="deposit"
                  min="0"
                  label="Cantidad a Abonar"
                  value={this.state.form.deposit}
                  onChange={this.handleChange}
                />
              </div>
              <div className="col-md-4">
                <InputFormat
                  color="gray"
                  disabled={true}
                  name="rest"
                  thousandSeparator={true}
                  allowNegative={false}
                  isNumericString={true}
                  decimalScale="2"
                  label="Resto de la Deuda"
                  value={this.state.form.rest}
                />
              </div>
            </div>
            }
          {
            (parseInt(this.state.form.method_type)
              ===
              Constants.EXPENSES_INGRESS_METHOD_TYPE_LIST.TRANSFER)
            && (
              <div className="row">
                <div className="col-md-4">
                  <Input
                    label="Numero de referencia"
                    name="reference_number"
                    value={this.state.form.reference_number}
                    color="gray"
                    onKeyPress={emitter =>
                      Globals.soloNumeros(emitter)
                    }
                    onChange={this.handleChange}
                    maxLength={20}
                  />
                </div >
              </div >

            )
          }
          <div>
            {
              this.state.form.method_type &&
              (
                <div className="row">
                  <div className="col-md-4">
                    <Select
                      color="gray"
                      label="Banco"
                      name="bank_id"
                      disabledFirst={false}
                      defaultname="Seleccione"
                      defaultValue=""
                      options={banks}
                      value={this.state.form.bank_id}
                      error={`${
                        this.hasErrorFor("bank_id") ? "is-invalid" : ""
                        }`}
                      invalidfeedback={this.renderErrorFor("bank_id")}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <Select
                      color="gray"
                      label="Cuenta Bancaria"
                      name="bank_account_id"
                      disabledFirst={false}
                      defaultname="Seleccione"
                      defaultValue=""
                      options={this.state.bank_accounts}
                      value={this.state.form.bank_account_id}
                      error={`${
                        this.hasErrorFor("bank_account_id") ? "is-invalid" : ""
                        }`}
                      invalidfeedback={this.renderErrorFor("bank_account_id")}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="col-md-4">
                    <InputFormat
                      color="gray"
                      disabled={true}
                      name="balance"
                      thousandSeparator={true}
                      allowNegative={false}
                      isNumericString={true}
                      decimalScale="2"
                      label="Saldo"
                      value={this.state.form.balance}
                      error={`${this.hasErrorFor("amount") ? "is-invalid" : ""}`}
                      invalidfeedback={this.renderErrorFor("amount")}
                    />

                  </div>

                </div>
              )
            }
          </div>


          <div id="button">
            <Button block="true" type="submit">
              {this.state.textButton}
            </Button>
          </div>
        </div>

      </form>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(PayDebt);
