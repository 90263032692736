import React from 'react';
import { List } from '../../components';
import { Colors } from '../../utils';
// import { Avatar, Icon, List } from '../../components';
// import { ENV, Colors } from '../../utils';
// import User from '../../assets/img/user.jpg';

class ViewSize extends React.Component {

	state = {
		brand: this.props.brand
	}

	getStatus = (status = this.state.brand.status) => {
		let respuesta = "";
		switch(status) {
            case 0:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
                break;
            case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;
			default:
				break;
		}
		return respuesta;
	}
		
	render() {
		return (
			<div className="text-center container-view-user">
				<List.Container>
					<List.Item label="Nombre">
						{ this.state.brand.name }
					</List.Item>
					{/* <List.Item label="Código">
						{ this.state.brand.code }
					</List.Item>
					<List.Item label="Estatus">
						<span style={ {
							color: this.getStatus().color
						} }>{ this.getStatus().text }</span>
					</List.Item> */}
				</List.Container>
			</div>
		)
	}
}

export default ViewSize;