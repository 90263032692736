import React from "react";
import { connect } from "react-redux";
import Menu from "../../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../../components";

import { axios, Globals, Colors } from "../../../utils";
import { Module, Enterprise } from "../../../services";

import CreateEditRoleEnterprise from "./create_edit_role_enterprises";
import ViewRoleEnterprise from "./view_role_enterprises";

const TABLE_HEADER = ["Nombre", "Estatus", "Acciones"];
class RoleEnterprises extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      last_page: 1,
      data: [],
      dataModules: [],
      user: null,
      role: null,
      view: false,
      list_status: [
        { value: "", label: "Todos" },
        { value: 0, label: "Suspendidos" },
        { value: 1, label: "Activos" }
      ],
      form: {
        status: "",
        search: ""
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    Globals.setLoading();

    const { user } = this.props;
    const { form } = this.state;

    let obj = {
      role: user.role,
      status: form.status,
      search: form.search,
      user_id: user.id
    };

    Module.getModulesEnterprise({ Id: user.id })
      .then(response => this.setState({ dataModules: response }))
      .catch(error => {
        Globals.showError();
      });

    axios
      .post("admin/roles_enterprises/get?page=" + this.state.page, obj)
      .then(async res => {
        if (res.data.result) {
          if (this.state.page > 1 && res.data.roles.data.length === 0) {
            this.setState({
              page: 1
            });
            this.load();
          } else {
            this.setState({
              last_page: res.data.roles.last_page,
              data: res.data.roles.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = async () => {
    this.setState(
      {
        create: false,
        view: false,
        edit: null,
        user: null,
        role: null
      },
      () => {
        this.load();
      }
    );
  };

  inactiveRol = Item => {
    Globals.confirm(`¿Desea suspender el Rol: ${Item.name}?`, () => {
      Enterprise.setStatusInactive({ Id: Item.id })
        .then(response => {
          Globals.showSuccess("¡Rol suspendido exitosamente!");
          this.load();
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  activateRol = Item => {
    Globals.confirm(`¿Desea activar el Rol: ${Item.name}?`, () => {
      Enterprise.setStatusActive({ Id: Item.id })
        .then(response => {
          Globals.showSuccess("¡Rol activado exitosamente!");
          this.load();
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  removeRol = Item => {
    Globals.confirm(`¿Desea eliminar el Rol: ${Item.name}?`, () => {
      Enterprise.removeRol({ Id: Item.id })
        .then(response => {
          Globals.showSuccess("¡Rol eliminado exitosamente!");
          this.load();
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  change = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  render() {
    const { data } = this.state;

    return (
      <Menu history={this.props.history}>
        <Modal title="Ver Permisos del Rol" onClose={this.close} visible={this.state.view}>
          <ViewRoleEnterprise role={this.state.role} />
        </Modal>
        <Modal
          title={this.state.edit ? "Editar Rol" : "Nuevo Rol"}
          onClose={this.close}
          visible={this.state.create}
        >
          <CreateEditRoleEnterprise
            modules={this.state.dataModules}
            edit={this.state.edit}
            enterprise_id={this.props.user.id}
            onClose={this.close}
          />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-md-5">
              <Input
                color="white"
                name="search"
                label="Búsqueda"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre de rol o Empresa"
              />
            </div>
            <div className="col-md-5">
              <Select
                color="white"
                name="status"
                label="Status"
                defaultname="Seleccione"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom" onClick={this.load}>
                Filtrar
              </Button>
            </div>
          </div>
          <Table
            data={data.length}
            title="Roles de Empresa"
            header={TABLE_HEADER}
            right={
              <Button
                title="Agregar Roles"
                outline="true"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <Icon name="plus" />
              </Button>
            }
          >
            {data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>{Item.name}</td>
                  <td style={{ color: this.getStatus(Item.status).color }}>
                    {this.getStatus(Item.status).text}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() => {
                        this.setState({
                          role: Item,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      color="primary"
                      small="true"
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: Item,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {Item.status === 0 && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.activateRol(Item);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}

                    {Item.status === 1 && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.inactiveRol(Item)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.removeRol(Item)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              this.setState(
                {
                  page: page
                },
                () => {
                  this.load();
                }
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(RoleEnterprises);
