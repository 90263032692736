import axios from "../../utils/axios";

class Branch {
  static async getBranches(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/branches/get", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getBranchesByEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/branches/enterprise", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Branch;
