import React from 'react';
import { connect } from 'react-redux';
import { Globals } from '../../utils';
import { Input, Select, Button } from '../../components';
import { AccountToPayService } from '../../services';

class CreatePayment extends React.Component {

	state = {
		form: {
			amount: '',
			// method_id: '',
			// comments: '',
			user_id: this.props.user.id,
		},
		paymentMethods: [],
		height: 0
	}

	change = (value,target) => {
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
	}

	submit = async () => {
		const amount = parseFloat(parseFloat(this.state.form.amount).toFixed(2));
		const total = parseFloat(parseFloat(this.props.purchase?.total).toFixed(2));
		let min_amount = parseFloat(this.props.purchase?.amount_payment);
		const pay = (this.props.purchase?.initial_payment || 0) + this.props.purchase?.payments.map(i => i.amount).reduce((a,b) => a + b,0);
		const to_pay = this.props.purchase?.total - pay;
		if (to_pay < min_amount) {
			min_amount = to_pay;
		}
		const amount_payment = parseFloat(parseFloat(min_amount).toFixed(2));

		if (amount < amount_payment) {
			Globals.showError("Lo sentimos, el monto mínimo del pago es " + Globals.formatMiles(min_amount,true));
			return false;
		}

		if (amount > total) {
			Globals.showError("Lo sentimos, el monto del pago no puede ser mayor al total de la compra (" + Globals.formatMiles(this.props.purchase?.total,true) + ")");
			return false;
		}

		if (amount > to_pay) {
			Globals.showError("Lo sentimos, el monto del pago no puede ser mayor al monto adeudado (" + Globals.formatMiles(to_pay,true) + ")");
			return false;
		}

		await AccountToPayService.setPayment({
			purchase_id: this.props.purchase?.id,
			...this.state.form
		});
		Globals.showSuccess("Se ha registrado el pago correctamente");
		this.props.onClose(true);
	}

	render() {
		return (
			<div>
				<div className="row">
					<div className="col-md-12">
						<Input
							color=" "
							type="number"
							label="Monto del Pago"
							className="material"
							labelClass="material"
							value={this.state.form.amount}
							onChange={e => {
								if (Globals.validateDouble(e.target.value) || e.target.value === '') {
									this.change(e.target.value,'amount');
								}
							}}
						/>
					</div>
					{/* <div className="col-md-12">
						<Select
							label="Método de Pago"
							className="material"
							labelClass="material"
							value={this.state.form.method_id}
							options={ this.props.paymentMethods.map(i => ({
								value: i.id,
								label: i.name
							}))}
							onChange={e => this.change(e.target.value, 'method_id')}
						/>
					</div>
					<div className="col-md-12">
						<Input
							color=" "
							label="Información Adicional (Opcional)"
							className="material"
							labelClass="material"
							value={this.state.form.comments}
							onChange={e => this.change(e.target.value,'comments')}
						/>
					</div> */}
				</div>
				<div style={ { display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '1rem' } }>
					<div style={ { flex: 1 } }>
						<Button outline block onClick={ () => this.props.onClose() }>
							<span style={{ color: 'gray' }}>Cerrar</span>
						</Button>
					</div>
					<div style={ { flex: 1 } }>
						<Button color="primary" block onClick={ this.submit }>
							<span>Aceptar</span>
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state) => {
	return {
		user: state.user
	}
})(CreatePayment);