import axios from "../../utils/axios";

class delivery {
  static async getDeliveryNotes(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/deliver-notes/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getId(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/deliver-notes/generateId", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async generateId(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/deliver-notes/getId", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createDelivery(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/deliver-notes/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default delivery;
