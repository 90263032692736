export default Object.freeze([
  {
    // Level types start at one. This is just a hole so we can index
    // them directly.
  },
  {
    type: "Efectivo",
    value: 1
  },
  {
    type: "Credito",
    value: 2
  },
  {
    type: "Debito",
    value: 3
  },
  {
    type: "Transferencia",
    value: 4
  },
  {
    type: "A Credito",
    value: 5
  },
  {
    type: "Pago Abonado",
    value: 6
  }
]);
