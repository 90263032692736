import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import { Button, InputFormat } from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
// import CreaEditConversion from "./create_edit_conversions";
// import ViewConversion from "./view_conversion";
import $ from "jquery";

class Conversions extends React.Component {
  state = {
    header: ["Monto", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    country: Globals.getCountry(this.props.user),
    currencies: [
      { id: Constants.CURRENCIES.DOLARES, symbol: '$', name: 'Dólar' },
      { id: Constants.CURRENCIES.BOLIVARES, symbol: 'Bs', name: 'Bolívar' },
    ],
    conversion: null,
    view: false,
    form: {
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      user_id: this.props.user.id,
      amount: 0,
      id: null,
    }
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  load = () => {
    Globals.setLoading();
    axios
      .post("admin/conversions?page=" + this.state.page, this.state.form)
      .then(async res => {
        if (res.data) {
          await this.setState({
            data: res.data.conversion,
            form: {
              ...this.state.form,
              id: res.data?.conversion?.id,
              amount: res.data?.conversion?.amount,
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nueva",
          color: Colors.red
        };
        break;
      case 1:
        respuesta = {
          text: "Activa",
          color: Colors.green
        };
        break;
      case 2:
        respuesta = {
          text: "Suspendida",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      category: null
    });
    await this.load();
  };

  suspend = (item, i) => {
    let text = "¿Desea suspender la Conversión ";
    if (item.status !== 1) {
      text = "¿Desea activar la Conversión ";
    }
    Globals.confirm(text + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/currencies/suspend", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar la Conversión: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/currencies/delete", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };
  change = e => {
    // Limitar los números enteros a 8
    let nums = String(e.value).split('.', 2);
    let value = nums[0].substring(0, 8);
    value = `${value}${nums[1] ? `.${nums[1]}` : ''}`;

    this.setState({
      form: {
        ...this.state.form,
        amount: value
      }
    });
  };
  submit = async () => {
      let param = { ...this.state.form }
      Globals.setLoading();
      axios.upload('admin/conversions/edit', param)
          .then(res => {
            Globals.showSuccess('Conversión registrada con exito')
          })
      .catch(err => {
          Globals.showError();
      })
      .then(() => {
          Globals.quitLoading();
      });
  }
  render() {
    return (
      <Menu history={this.props.history}>
        <section id="conversion">
          <div className="conversion-header">Tasa de Cambio $ / { this.state.country?.currency_symbol }</div>
          <div className="conversion-form">
            <div className="label">{ this.state.country?.currency_name_plural } por Dólar</div>
            <div>
              <InputFormat
                name="amount"
                thousandSeparator={true}
                allowNegative={false}
                isNumericString={true}
                decimalScale="2"
                value={this.state.form.amount}
                onValueChange={this.change}
              />
            </div>
          </div>
          <div className="actions">
            <Button
              onClick={() => this.submit()}
            >
              Cargar
            </Button>
          </div>
        </section>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    currency: state.currency,
  };
})(Conversions);
