import axios from "../../utils/axios";

class provider {
  static async getProviders(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/providers/enterprise", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async ProviderFromOrder(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/providers/from-order/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default provider;
