import React from "react";

import { Select, DatePicker, Button } from "../../components";
import { Globals, Constants, Format } from "../../utils";
import { Warehouse } from "../../services";
// const TYPE_LIST = [
//   { value: "", label: "Todos" },
//   { value: 1, label: "Nota de entrega" },
//   { value: 2, label: "Factura de compra" }
// ];

class FilterForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        year: "",
        month: "",
        since: props.since ? props.since :  "",
        until: props.until ? props.until :  "",
        client: 0,
        seller: 0,
        branch_id: [],
        warehouse_id: Globals.getAssignedWarehouses(this.props.user),
        zone_id: 0
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      months: [],
      years: [],
      warehouses: []
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }
  }

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;
    const warehouse_id = Globals.getAssignedWarehouses(user)

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: userId,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: warehouse_id
        },
        warehouses: warehousesMap
      }));
    });
  };

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    if(name === 'branch_id' || name === 'warehouse_id') {
      value = [parseInt(value)]
    }

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  render() {
    const { customers, sellers, branches, zones } = this.props;
    const { form, warehouses } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              className="filter"
              minDate={this.state.dates.minDate}
              value={this.state.form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              className="filter"
              maxDate={this.state.dates.maxDate}
              value={this.state.form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...this.state.form,
                    until: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <Select
              color="white"
              name="client"
              label="Clientes"
              className="filter"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={this.state.form.client}
              options={[...customers, { value: 0, label: "Todos" }].reverse()}
            />
          </div>
          {sellers && sellers.length > 0 && this.props.user.role !== Constants.ROLE_SELLER && (
            <div className="col-md">
              <Select
                color="white"
                name="seller"
                label="Vendedores"
                className="filter"
                style={{ textTransform: "capitalize" }}
                onChange={emitter => this.handleChange(emitter, null)}
                value={form.seller}
                options={[...sellers, { value: 0, label: "Todos" }].reverse()}
              />
            </div>
          )}
          {/* <div className="col-md">
            <Select
              color="white"
              name="type"
              label="Tipo"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={this.state.form.type}
              options={TYPE_LIST}
            />
          </div> */}
        </div>
        <div className="row">
          <div className="col-md">
            <Select
              color="white"
              name="branch_id"
              label="Sucursal"
              className="filter"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.branch_id}
              options={branches}
            />
          </div>
          {/* <div className="col-md">
            <Select
              color="white"
              name="warehouse_id"
              label="Almacenes"
              className="filter"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.warehouse_id}
              options={[...warehouses, { value: 0, label: "Todos" }].reverse()}
            />
          </div> */}
          <div className="col-md">
            <Select
              color="white"
              name="zone_id"
              label="Zona"
              className="filter"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.zone_id}
              options={zones}
            />
          </div>
          <div className="col-md">
            <div className="form-group">
              <Button
                color=" "
                className="btn-align-bottom btn-block btn-filter"
                onClick={this.load}
                >
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default FilterForm;
