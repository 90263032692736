import React from "react";
import { connect } from "react-redux";

import { Pagination, Button, Modal, Table, Icon } from "../../components";
import { Globals, Constants, ENV } from "../../utils";
import { Provider, Branch, Currency, Order, Bank } from "../../services";

// Components
import Menu from "../menu";
import View from "./view";
import Filter from "./filterForm";
import CreateEditOrder from "./create_edit_order";

const TABLE_HEADER = [
  "Nro. Orden",
  "Nro. Factura",
  "Nro. Control",
  "Proveedor",
  "Divisa",
  "Tipo",
  "Estatus",
  "Total",
  "Acciones"
];

class Orders extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      page: 1,
      Item: "",
      current_page: 1,
      last_page: 1,
      visibleCreate: false,
      visibleProcessing: false,
      visiblePurchase: false,
      showLg: true,
      providers: [],
      form:[],
      currencies: [],
      branches: [],
      banks: [],
      dataincache: false,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(_, prevState) {
    if (
      (prevState.visibleCreate !== this.state.visibleCreate &&
        this.state.visibleCreate) ||
      (prevState.visibleProcessing !== this.state.visibleProcessing &&
        this.state.visibleProcessing)
    ) {
      this.maybeLoad();
    }

    if (localStorage.getItem('OrderState') !== null) {
      this.state.dataincache = true;
    }
  }

  load = () => {
    const { user } = this.props;
    const { page } = this.state;

    const Id = Globals.getEnterpriseId(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);
    const form = this.state.form

    console.log(form)
    Promise.all([
      Provider.getProviders({ Id: Id }),
      Order.getOrders(page, {
        Id: Id,
        warehouse_id: warehouse_id,
        role: user.role,
        ...form
      }),
      Bank.getBanks({ enterprise_id: Id })
    ])
      .then(response => {
        const { current_page, data, last_page } = response[1];

        this.setState({
          providers: response[0],
          data: data,
          banks: response[2],
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(error => {
        Globals.showError();
      });
  };

  loadMore = () => {
    const { user } = this.props;
    const { page } = this.state;

    const Id = Globals.getEnterpriseId(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);

    this.load();
    // Order.getOrders(page, {
    //   Id: Id,
    //   warehouse_id: warehouse_id,
    //   role: user.role
    // })
    //   .then(response => {
    //     const { current_page, data, last_page } = response;

    //     this.setState({
    //       data: data,
    //       current_page: current_page,
    //       last_page: last_page
    //     });
    //   })
    //   .catch(error => {
    //     Globals.showError();
    //   });
  };

  filterMap = form => {
    const { user } = this.props;
    const { page } = this.state;

    const Id = Globals.getEnterpriseId(user);
    const warehouse_id = Globals.getAssignedWarehouses(user);

    this.setState({
      form: form
    });
    // if (this.state.form == null) {
    //   this.filterMap(form);
    // }
    // console.log(this.state.form);
    this.load()

    // Order.getOrders(page, {
    //   Id: Id,
    //   warehouse_id: warehouse_id,
    //   role: user.role,
    //   ...form
    // })
    //   .then(response => {
    //     const { current_page, data, last_page } = response;

    //     this.setState({
    //       data: data,
    //       current_page: current_page,
    //       last_page: last_page
    //     });
    //   })
    //   .catch(error => {
    //     Globals.showError();
    //   });
  };

  close = () => {
    this.setState(
      {
        visibleCreate: false,
        visiblePurchase: false,
        visibleProcessing: false
      },
      () => {
        this.load();
      }
    );
  };

  maybeLoad() {
    const { user } = this.props;
    const enterprise_id = Globals.getEnterpriseId(user);

    Promise.all([
      Currency.getCurrenciesFromPurchase(),
      Branch.getBranchesByEnterprise({ Id: enterprise_id })
    ])
      .then(response => {
        const currenciesMap = response[0]
          .filter(Item => Item.status === Constants.STATUS_ACTIVE)
          .map((Item, key) => {
            return {
              value: Item.id,
              label: Item.name
            };
          });

        const branches_filtered = Globals.filterWarehouseOrBranches(
          response[1],
          user,
          "branch"
        );
        const branchesMap = branches_filtered
          .filter(Item => Item.status === Constants.STATUS_ACTIVE)
          .map(Item => {
            return {
              value: Item.id,
              label: Item.name
            };
          });

        this.setState({
          currencies: currenciesMap,
          branches: branchesMap
        });
      })
      .catch(error => {
        Globals.showError();
      });
  }

  deployProcessing = (e, Item) => {

    e.preventDefault();
    if (localStorage.getItem('OrderState') !== null) {
      Globals.continueOrReturn(
        "Usted tiene una orden o factura almacenada en caché, si desea continuar la información en caché se borrará",
        ({
          value: Status
        }) => {
          switch (Status) {
            case true:
              localStorage.removeItem('OrderState'); //dejo el state del storage vacío
              this.setState({ Item: Item, visibleProcessing: true });
              break;
            default:
              break;
          }
        }
      );
    }
    else {
      this.setState({ Item: Item, visibleProcessing: true });
      return;
    }

    //this.setState({ Item: Item, visibleProcessing: true });
  };

  deployPurchase = Item => {
    this.setState({ Item: Item, visiblePurchase: true });
  };

  rawProvider = Provider => {
    this.setState(state => ({
      showLg: true,
      providers: [...state.providers, Provider]
    }));
  };

  removeAttribute = () => {
    let showLg = !this.state.showLg;
    this.setState({ showLg: showLg });
  };

  printOrder = (item) => {
    const url = `${ENV.BasePublic}orders/print-order/${item.id}`;
    const win = window.open(url, "_blank");
    win.focus();
  };

  render() {
    const {
      data,
      Item,
      banks,
      showLg,
      branches,
      providers,
      currencies,
      visibleCreate,
      visiblePurchase,
      visibleProcessing,
      dataincache
    } = this.state;

    const { user, history, sidebar } = this.props;
    const Id = Globals.getUserId(user);

    const banksMapped = banks
      .filter(Item => Item.status === Constants.STATUS_ACTIVE)
      .map((Item, key) => {
        return {
          value: Item.id,
          label: Item.name,
          type: Item.type
        };
      })

    return (
      <Menu history={history}>
        {visibleCreate && (
          <Modal
            className={showLg ? "modal-order" : ""}
            title="Crear Orden / Factura"
            onClose={this.close}
            visible
          >
            <CreateEditOrder
              banks={banksMapped}
              removeAttribute={this.removeAttribute}
              newProvider={Provider => this.rawProvider(Provider)}
              onClose={this.close}
              havePermission={
                sidebar.find(Item => Item.name === "Proveedores") ? true : false
              }
              havePermissionProducts={
                sidebar.find(Item => Item.name === "Productos") ? true : false
              }
              Id={Id}
              Item={null}
              providers={providers
                .filter(Item => Item.status === Constants.STATUS_ACTIVE)
                .map((Item, key) => {
                  return {
                    value: Item.id,
                    label: Item.name
                  };
                })}
              currencies={currencies}
              branches={branches}
              user={user}
            />
          </Modal>
        )}

        {visibleProcessing && (
          <Modal
            className="modal-order"
            title="Procesar Orden"
            onClose={this.close}
            visible
          >
            <CreateEditOrder
              banks={banksMapped}
              onClose={this.close}
              havePermission={false}
              Id={Id}
              Item={Item}
              providers={providers
                .filter(Item => Item.status === Constants.STATUS_ACTIVE)
                .map((Item, key) => {
                  return {
                    value: Item.id,
                    label: Item.name
                  };
                })}
              currencies={currencies}
              branches={branches}
              user={user}
            />
          </Modal>
        )}

        {visiblePurchase && (
          <Modal
            className="modal-order"
            title="Visualizar Orden/Factura"
            onClose={this.close}
            visible
          >
            <View Item={Item} />
          </Modal>
        )}

        <div id="home">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              Id={Id}
              providers={providers.map(Item => {
                return {
                  value: Item.id,
                  label: Item.name
                };
              })}
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={data.length}
                  title="Ordenes de compra / Factura de compra"
                  header={TABLE_HEADER}
                  right={
                    <Button
                      title="Agregar Ordenes"
                      outline="true"
                      small="true"
                      onClick={() => {
                        this.setState({
                          visibleCreate: true,
                          showLg: true
                        });
                      }}
                    >
                      <Icon name="plus" />
                    </Button>
                  }
                >
                  {data.map((Item, index) => {
                    return (
                      <tr key={index}>
                        <td className="text-center">
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER
                            ? Item.code
                            : "-"}
                        </td>
                        <td className="text-center">
                          {(Item || {}).bill_number || "-"}
                        </td>
                        <td className="text-center">
                          {(Item || {}).control_number || "-"}
                        </td>
                        <td>{Item.provider.name}</td>
                        <td>{Item.currency.name}</td>
                        <td>
                          {parseInt(Item.type) === Constants.PURCHASE_ORDER
                            ? "Orden de compra"
                            : "Factura de compra"}
                        </td>
                        <td
                          className={
                            Item.status ? "text-success" : "text-danger"
                          }
                        >
                          {Item.status ? "PROCESADA" : "PENDIENTE"}
                        </td>
                        <td>
                          {Globals.formatMiles(
                            Item.status ? Item.total_real : Item.total
                          )}
                        </td>
                        <td>
                          <Button
                            color="primary"
                            title="Ver mas"
                            small="true"
                            onClick={() => this.deployPurchase(Item)}
                          >
                            <Icon name="eye" />
                          </Button>
                          <Button
                            color="warning"
                            title="Imprimir"
                            small="true"
                            onClick={() => this.printOrder(Item)}
                          >
                            <Icon name="print" />
                          </Button>
                          {!Item.status && (
                            <Button
                              color="info"
                              title="Procesar"
                              small="true"
                              onClick={(e) =>
                                this.deployProcessing(e, Item)
                              }
                            >
                              <Icon name="cogs" />
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={this.state.last_page}
                  active={this.state.page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => {
                        this.load();
                      }
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user,
    sidebar: state.sidebar
  };
})(Orders);
