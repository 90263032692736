import React from "react";
import { Select, DatePicker, Button } from "../../../components";
import { Bank } from "../../../services";
import { Globals } from "../../../utils";

const MOVEMENT_TYPE = [
    { value: 1, label: "Ingreso" },
    { value: 2, label: "Egreso" }
];


class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                movement_type: 0,
                bank_id: 0,
                bank_account_id: 0,
                until: 0,
            },
            bank_accounts:[],
        };
    }

    maybeLoadBankAccounts = () => {
      const { form } = this.state
    
      Bank.getBankAccounts({ bank_id: form.bank_id })
        .then(response => {
          let bankAccountsMapped = [];
    
            bankAccountsMapped = response.map((Item, key) => {
                return {
                  value: Item.id,
                  label: Item.account_number,
                };
              })
    
          this.setState(state => ({
            form: {
              ...state.form,
              bank_account_id: 0
            },
            bank_accounts: bankAccountsMapped
          }));
    
        })
        .catch(() => Globals.showError());
    }

    handleChange = (emitter, string) => {
        const { name, value } = emitter.target;
        if(name === 'bank_id')
        {
            this.setState(state => ({
              form: {
                ...state.form,
                [name]: value,
                bank_account_id:0,
              },
            }), () => this.maybeLoadBankAccounts());
        }

        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          }
        }));
    };

    render() {
        const { banks } = this.props;
        const { form } = this.state;
        const {banksMapped} = this.props
        return (
          <form
            onSubmit={emitter => {
              emitter.preventDefault();
              this.props.submitted(form);
            }}
          >
            <div className="row">
              <div className="col-md">
                <DatePicker
                  color="white"
                  label="Desde"
                  value={this.state.form.since}
                  onChange={value => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        since: value
                      }
                    }));
                  }}
                />
              </div>
              <div className="col-md">
                <DatePicker
                  color="white"
                  label="Hasta"
                  value={this.state.form.until}
                  onChange={value => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        until: value
                      }
                    }));
                  }}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="bank_id"
                  defaultvalue={0}
                  label="Banco"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  options={banksMapped}
                  value={this.state.form.bank_id}
                />
              </div>
              <div className="col-md">
                <Select
                  defaultvalue={0}
                  color="white"
                  name="bank_account_id"
                  label="Cuenta"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={this.state.form.bank_account_id}
                  options={this.state.bank_accounts}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="movement_type"
                  label="Movimiento"
                  defaultvalue={0}
                  disabledFirst
                  defaultname="Todos"
                  style={{ textTransform: "capitalize" }}
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={this.state.form.movement_type}
                  options={MOVEMENT_TYPE}
                />
              </div>
              <div className="col-md">
                <div className="form-group">
                  <Button
                    disabled={!this.state.form.bank_id || !this.state.form.bank_account_id}
                    className="btn-block btn-align-bottom"
                    onClick={this.load}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      }
}

export default Filter;