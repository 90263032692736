import React, { useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import NumberFormat from "react-number-format";
import Swal from "sweetalert2";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  CheckBox,
  Icon,
  DatePicker,
  Modal,
  Input,
} from "../../components";
import { axios, Constants, Globals, Format, ENV } from "../../utils";
import { Branch, Warehouse, Category, Brand } from "../../services";
import PrinterIcon from '../../assets/icons/printer.png';
import AddIcon from '../../assets/icons/add.png';
import ThumbUpIcon from '../../assets/icons/thumb-up.png';
import ThumbDownIcon from '../../assets/icons/thumb-down.png';
import AcceptOrder from "./accept-order";
import ViewPayments from "./view-payments";
import EditOrder from "./edit-order";
import TrashIcon from '../../assets/icons/trash.png';
import moment from "moment";

const ORDER_STATUS = {
  PENDING: 0,
  PROCESSED: 1,
  REJECTED: 2,
  CANCELLED: 5,
  DISPATCHED: 6,
  DELETED: 'deleted_at',
}

class Dispatchs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      thead: [],
      trows: [],
      total: 0,
      header: [
        {
          value: "correlative_number",
          label: "N° de Pedido",
          type: 1,
          section: "required",
          order: 0
        },
        {
          value: "client",
          label: "Cliente",
          type: 1,
          section: "required",
          order: 3
        },
        {
          value: "amount",
          label: "Monto",
          type: 1,
          section: "required",
          order: 5
        },
        // {
        //   value: "payment_method",
        //   label: "Pago inicial",
        //   type: 1,
        //   section: "required",
        //   order: 8
        // },
        // {
        //   value: "credit_days",
        //   label: "Días de crédito",
        //   type: 1,
        //   section: "required",
        //   order: 9
        // },
        {
          value: "dispatch_date",
          label: "Fecha de despacho",
          type: 1,
          section: "required",
          order: 9
        },
        {
          value: "status",
          label: "Estatus",
          type: 1,
          section: "required",
          order: 9
        },
        {
          value: "dispatch_comment",
          label: "Observaciones",
          type: 1,
          section: "required",
          order: 10
        },
        {
          value: "",
          label: "Acciones",
          type: 1,
          section: "",
          order: 11
        },
      ],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
      page: 1,
      last_page: 1,
      data: [],
      branches: [],
      warehouses: [],
      order: null,
      editModal: false,
      viewPaymentsModal: false,
      acceptModal: false,
      providers: {},
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        code: true,
        name: true,
        current_stock: true,
        total: false,
        price: false,
        price_max: false,
        price_offer: false,
        cost: false,
        committed: false,
        available: false,
        since: "", //moment().toDate(),
        until: "", //moment().toDate(),
        payment_method_id: "",
        client_id: "",
        seller_id: "",
        web: true,
        type_download: 'pdf',
        currency: Constants.CURRENCIES.DOLARES,
        order_id: "",
      },
      categories: [],
      subcategories: [],
      brands: [],
      models: [],
      payment_methods: [],
      clients: [],
      countPrice: 0,
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role,
      showProductsFilters: true,
      dates: {
        minDate: "",
        maxDate: ""
      },
      status_filter: [
        {value: ORDER_STATUS.PROCESSED, label: 'Por entregar'},
        {value: ORDER_STATUS.DISPATCHED, label: 'Despachado'},
      ],
      visible_details: false,
      item: null,
    };
  }

  abortController = new AbortController();

  componentDidMount() {
    this.load();
    this.getThead();
    this.getClients();
  }

  componentWillUnmount() {
    this.abortController.abort();
  }

  load = () => {
    this.getOrders();
  };

  getOrders = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    form.user_id = this.props.user.id;

    axios
      .post("admin/orders/dispatch?page=" + page, form)
      .then(res => {
        console.log(res)
        this.setState({
          last_page: res.data.orders.last_page,
          data: res.data.orders.data,
        });
      })
      .catch(err => {
        Globals.showError();
      });
  };

  getClients = async () => {
    Globals.setLoading();

    try {
      const filter = {
        user_id: this.state.form.user_id,
        search: this.state.form.search,
        is_select: true
      };

      const res = await axios.post('web/admin/orders/clients', filter);
      if (!res.data?.clients) throw Error('No se encontraron los clientes');

      const clients = res.data.clients.map(c => ({
        ...c,
        value: c.id,
        label: !!c.document ? `${c.name} - Rif ${c.document}` : c.name,
      }));

      this.setState({ clients });

    } catch (error) {
      Globals.showError(error?.message);
      console.log('Cuentas por Pagar -> getClients -> catch:', error)
    }

    Globals.quitLoading();
  }

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        } else {
          Globals.showError(res.data.msg);
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getWarehouses = id => {
    if (!id) {
      let warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }

    let { user } = this.props;

    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouses({
      role: user.role,
      user_id: enterpriseId,
      branch_id: id
    }).then(response => {
      if (response.result) {
        let warehouses_filtered = [];
        if (user.role === 4) {
          warehouses_filtered = response.warehouses.data.filter(el => {
            return user.assigned_warehouses.some(f => {
              return parseInt(f.warehouse_id) === parseInt(el.id);
            });
          });
        } else {
          warehouses_filtered = response.warehouses.data;
        }

        this.setState(state => ({
          warehouses: warehouses_filtered,
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  getCategories = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };

    const { form } = this.state;

    Globals.setLoading();
    axios
      .post("admin/categories/get", param)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            categories: res.data.categories,
            form: {
              ...form,
              subcategory_id: ""
            }
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getSubcategories = categoryId => {
    let param = {
      category_id: categoryId,
      select: true
    };
    Category.getSubcategories(param).then(response => {
      if (response.result) {
        this.setState(state => ({
          ...state,
          subcategories: response.subcategories,
          form: {
            ...state.form,
            subcategory_id: ""
          }
        }));
      }
    });
  };

  getBrands = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id,
      select: true
    };
    const { form } = this.state;
    Brand.getBrands(param).then(response => {
      if (response.result) {
        this.setState({
          brands: response.brands,
          form: {
            ...form,
            model_id: "",
            brand_id: ""
          }
        });
      }
    });
  };

  getModels = brandId => {
    let param = {
      brand_id: brandId,
      select: true,
      user_id: this.props.user.id
    };
    const { form } = this.state;
    Brand.getModels(param).then(response => {
      if (response.result) {
        this.setState({
          models: response.models,
          form: {
            ...form,
            model_id: ""
          }
        });
      }
    });
  };

  warehouses = () => {
    let param = {
      role: this.props.user.role,
      user_id: this.props.user.id
    };
    axios
      .post("admin/products/warehouses", param)
      .then(res => {
        if (res.data.result) {
          let form = [];
          res.data.warehouses.forEach((el, i) => {
            form.push({
              id: el.id,
              name: el.name,
              stock_min: "",
              stock_max: "",
              price: "",
              location: ""
            });
          });
          this.setState({
            warehouses: form
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  openProductsDetails = e => {
    this.change(e);
    if (e.target.value === "")
      this.setState({
        showProductsFilters: false
      });
    else
      this.setState({
        showProductsFilters: true
      });
  };

  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };

  // getThead = async (arr = [], arr2 = []) => {
  //   const { header } = this.state;

  //   let newHeader = arr;

  //   if (newHeader.length === 0) {
  //     newHeader = header
  //       .filter(Item => {
  //         return Item.type === 1;
  //       })
  //       .map(Item => {
  //         return Item.label;
  //       });
  //   }

  //   await this.setState(state => {
  //     state.thead = newHeader;
  //     state.trows = arr2;
  //   });
  // };

  getThead = async (arr = [], arr2 = []) => {
    const { header } = this.state;
    const { user } = this.props;
  
    let newHeader = arr;
  
    if (newHeader.length === 0) {
      newHeader = header
        .filter(item => {
          return (
            item.label !== "Monto" ||
            (user.level_id === Constants.ROLE_ADMIN ||
              ((user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && user.show_order_total === Constants.STATUS_ACTIVE))
          );
        })
        .map(item => {
          return item.label;
        });
    }
  
    await this.setState(state => {
      state.thead = newHeader;
      state.trows = arr2;
    });
  };

  getBranches = () => {
    let { user } = this.props;
    const enterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    Branch.getBranchesByEnterprise({ Id: enterpriseId })
      .then(response => {
        let branches_filtered = response.filter(
          ({ deleted_at }) => !deleted_at
        );

        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const branchesMap = branches_filtered
          .filter(({ status }) => status === Constants.STATUS_ACTIVE)
          .map(({ id, name }) => ({
            value: id,
            label: name
          }));

        this.setState({
          branches: branchesMap
        });
      })
      .catch(() => Globals.showError());
  };

  viewDetails = (item) => {
    console.log('view_details item: ', item)
		this.setState({ visible_details: true, item });
	}

  onClose = async (move = false) => {
		await this.setState({
			visible_details: false,
      item: null
		});

		this.getOrders();
	}

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      search: '',
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        category_id: "",
        subcategory_id: "",
        brand_id: "",
        model_id: "",
        user_id: "",
        payment_method_id: "",
        since: "",
        until: "",
        client_id: "",
        seller_id: "",
        web: true,
        currency: Constants.CURRENCIES.DOLARES,
        order_id: "",
      },
      thead: [],
      trows: [],
      optionals: [
        { value: "code", label: "Código", show: true },
        { value: "name", label: "Nombre", show: true },
        { value: "branch", label: "Almacen", show: true },
        { value: "warehouse", label: "Almacen", show: true },
        { value: "current_stock", label: "Existencia", show: true },
        //{ value: "committed", label: "Comprometido", show: false },
        { value: "available", label: "Disponible", show: true },
        { value: "total", label: "Total", show: true },
        //{ value: "cost", label: "Costo", show: false },
        //{ value: "price", label: "Precio Min.", show: false },
        //{ value: "price_max", label: "Precio Max.", show: false },
        //{ value: "price_offer", label: "Precio Oferta", show: false }
      ],
    }));
    await this.getThead();
    // await this.getBranches();
    // await this.getCategories();
    // await this.getBrands();
    await this.load();
  };

  print = (data) => {
    Globals.setLoading();
    axios
    .post("admin/orders/dispatch/print", this.state.form)
    .then(res => {
      if (res.data.url_storage) {
        //const url = res.data.url;
        const url = `${ENV.BasePublic}${res.data.url_storage}`;
        const win = window.open(url, "_blank");
        win.focus();
      }
      else {
        Globals.showError('Ha ocurrido un error');
      }
    })
    .catch(err => {
      Globals.showError();
    });

    Globals.quitLoading();
  };

  download = async (item) => {
    try {
      const form = {
        dispatch_note: true,
        currency: Constants.CURRENCIES.DOLARES,
        order_id: item.crypt,
        user_id: this.props.user.id,
        _user_id: this.props.user.id,
      };

      const res = await axios.post('orders/download', form);
      if (!res.data?.url) throw new Error();

      window.open(res.data.url);

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al generar el PDF. Intente nuevamente.');
    }
  }

  cancel = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de cancelación',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/cancel', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al cancelar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha cancelado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

  accept = async (item) => {
    if (item.status_payment === Constants.PAYMENT_TYPES.TO_PAY) {
      return this.setState({ acceptModal: true, order: item });
    }

    Globals.setLoading();
    try {
      const form = {
        order_id: item.id,
        _user_id: this.props.user.id,
      };

      await axios.post('admin/orders/accept', form);
      Globals.showSuccess("Se ha aceptado el pedido correctamente");
      this.getOrders(1);

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al aceptar el pedido.');
    }
    Globals.quitLoading();
  }

  reject = async (item) => {
    let done = false;

    Swal.fire({
      title: 'Ingrese el motivo de rechazo',
      input: 'text',
      inputAttributes: {
        autocapitalize: 'off'
      },
      showCancelButton: true,
      confirmButtonText: 'Aceptar',
      cancelButtonText: "Cancelar",
      showLoaderOnConfirm: true,
      preConfirm: (comments) => {
        if (!comments)
          return Globals.showError('Debe indicar el motivo');

        const form = {
          comments,
          order_id: item.id,
          user_id: this.props.user.id,
          _user_id: this.props.user.id,
        };

        return axios.post('admin/orders/reject', form)
          .then(response => {
            if (response.status !== 200) throw new Error(response.statusText);
            done = true;
            return true;
          })
          .catch(() => {
            Globals.showError('Ocurrió un error al rechazar el pedido');
            return false;
          })
      },
      allowOutsideClick: () => !Swal.isLoading()
    }).then(() => {
      if (done) {
        Globals.showSuccess('Se ha rechazado el pedido correctamente');
        this.getOrders(1);
      }
    });
  }

	getColor = (status) => {
		switch(status) {
			case ORDER_STATUS.PENDING:
				return '#3A7FC2';

			case ORDER_STATUS.PROCESSED:
				return 'red';

			case ORDER_STATUS.REJECTED:
				return '#DD4B4B';

			case ORDER_STATUS.CANCELLED:
				return '#DD4B4B';

      case ORDER_STATUS.DISPATCHED:
        return 'black';

			default:
				return 'black';
		}
	}

  getStatusText = (item) => {
    if(item.deleted_at !== null){
      return 'Eliminado'
    }
    if(item.dispatch_date !== null){
      return 'Despachado'
    }
    if(item.status === Constants.ORDER_STATUS.PROCESSED && item.dispatch_date == null){
      return 'Por entregar'
    }
  }

  dispatchOrder = async (item) => {
    console.log(item)

    Globals.setLoading();
    try {
      const form = {
        dispatch_comment: item.dispatch_comment,
        order_id: item.id,
        _user_id: this.props.user.id,
      };

      await axios.post('admin/orders/dispatch-order', form);
      Globals.showSuccess("Pedido listo para ser despachado");
      this.getOrders();

    } catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al aceptar el pedido.');
    }
    Globals.quitLoading();
  }

  render() {
    const { trows, visible_details, item } = this.state;
    const showTotal = trows.find(i => i.value == 'total') && trows.find(i => i.value == 'cost');
    const minimalSize = { padding: 0, maxWidth: '12%', flex: '1 1 12%', marginRight: '0.5rem' };
    const user = this.props.user

    return (
      <Menu history={this.props.history}>
        {visible_details && (
					<Modal
						title="Detalles del pedido"
						onClose={ () => this.onClose() }
						visible
					>
						<OrderDetails
							order={ item }
							onClose={ this.onClose }
							onAccept={(form) => {
								this.onClose();
								this.dispatchOrder({ ...item, ...form });
							}}
              user={user}
						/>
					</Modal>
				)}

        
        <div id="products">
          <div className="row">
            <div className="col-sm-12 col-md">
              <Input
                color=" "
                name="order_id"
                label="N° de Pedido"
                className="filter"
                onChange={emitter => this.change(emitter)}
                value={this.state.form.order_id}
                placeholder="N° de Pedido"
                type="number"
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="client_id"
                label="Cliente"
                className="filter"
                defaultname="Seleccione"
                onChange={emitter => this.change(emitter)}
                value={this.state.form.client_id}
                options={this.state.clients}
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="status"
                label="Estatus"
                className="filter"
                defaultname="Seleccione"
                onChange={emitter => this.change(emitter)}
                value={this.state.form.status}
                options={this.state.status_filter}
              />
            </div>
            <div className="col-sm-12 col-md">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-sm-12 col-md">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-sm-12 col-md">
              <Button className="btn-align-bottom btn-filter" 
                color=" " 
                onClick={async () => {
                  await this.setState({ page: 1 });
                  this.load();
                }}
              >
                <span>Filtrar</span>
              </Button>
            </div>
            <div className="col-sm-12 col-md">
              <Button className="btn-align-bottom btn-filter" 
                color=" " 
                onClick={this.returnState}
              >
                <span>Limpiar</span>
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Despachos"
            header={this.state.thead}
            right={
              <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
                <Button
                  title="Imprimir"
                  small="true"
                  onClick={() => this.print(this.state.data)}
                >
                  <img src={PrinterIcon} style={{ width: 24, marginTop: '-3px' }} alt="Imprimir" />
                </Button>
              </div>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.correlative_number}</td>
                  <td>
                    {i.client?.name ?? ''}
                  </td>
                  {(user.level_id === Constants.ROLE_ADMIN ||
                    ((user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && user.show_order_total === Constants.STATUS_ACTIVE)) && (
                      <td> {parseFloat(Number(i.total)).toFixed(2)} </td>
                    )}
                  {/* <td>
                    {parseFloat(Number(i.initial_payment ?? 0)).toFixed(2)}
                  </td>
                  <td>
                    {i.credit_days ?? ''}
                  </td> */}
                  <td>
                    {i.dispatch_date ? moment(i.dispatch_date).format('DD/MM/YYYY') : ''}
                  </td>
                  <td>
                    <span style={{ color: i.deleted_at != null ? '#DD4B4B' : i.dispatch_date != null ? 'black' : this.getColor(i.status) }}> { this.getStatusText(i) }</span>
                  </td>
                  <td>{ i.dispatch_comment }</td>
                  {i.deleted_at != null ?
                    <td></td>
                    :
                    <td>
                      {(i.status === Constants.ORDER_STATUS.PROCESSED && i.dispatch_date == null) && (
                        <Button
                          color="green"
                          title="Despachar"
                          small="true"
                          onClick={() => this.viewDetails(i)}
                        >
                          <Icon name="truck" />
                        </Button>
                      )}

                      <Button
                        color=" "
                        title="Imprimir"
                        small="true"
                        onClick={() => this.download(i)}
                      >
                        <Icon name="download" />
                      </Button>
                    </td>
                  }
                </tr>
              );
            })}
          </Table>

          {
            showTotal && (
              <p style={{
                fontSize: '16px',
                fontWeight: 'normal',
                fontFamily: 'Roboto Light',
                textAlign: 'right',
                marginTop: '10px',
                marginRight: '10px'
              }}>Costo Total: <NumberFormat
                  value={parseFloat(this.state.total.toFixed(2))}
                  displayType={"text"}
                  thousandSeparator={true}
                />
              </p>
            )
          }

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />

        </div>
      </Menu>
    );
  }
}

const OrderDetails = ({ order, onClose, onAccept, user }) => {

  const [form, setForm] = useState({ dispatch_comment: '' });

	return (
		<div className="whatsapp-order-details">
      <div className="container-form">
        <div className="input-container">
          <div className="label">Cliente:</div>
          <span>{order?.client?.name}</span>
        </div>
        {/* <div className="input-container">
          <div className="label">Monto:</div>
          <span>{parseFloat(Number(order.total ?? 0)).toFixed(2)}</span>
        </div>
        {order.status_payment === Constants.PAYMENT_TYPES.TO_PAY && (
          <div className="input-container">
            <div className="label">Pago inicial:</div>
            <span>{parseFloat(Number(order.initial_payment ?? 0)).toFixed(2)}</span>
          </div>
        )}
        {order.status_payment === Constants.PAYMENT_TYPES.TO_PAY && (
          <div className="input-container">
            <div className="label">Días de crédito:</div>
            <span>{order?.credit_days ?? 0}</span>
          </div>
        )} */}

        {user.level_id === Constants.ROLE_ADMIN && (
          <div className="input-container">
            <div className="label">Monto:</div>
            <span>{parseFloat(Number(order.total ?? 0)).toFixed(2)}</span>
          </div>
        )}

        {(user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && user.show_order_total === Constants.STATUS_ACTIVE && (
          <div className="input-container">
            <div className="label">Monto:</div>
            <span>{parseFloat(Number(order.total ?? 0)).toFixed(2)}</span>
          </div>
        )}

        {user.level_id === Constants.ROLE_ADMIN && (
          <div className="input-container">
            <div className="label">Pago inicial:</div>
            <span>{parseFloat(Number(order.initial_payment ?? 0)).toFixed(2)}</span>
          </div>
        )}

        {(user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && user.show_order_total === Constants.STATUS_ACTIVE && (
          <div className="input-container">
            <div className="label">Pago inicial:</div>
            <span>{parseFloat(Number(order.initial_payment ?? 0)).toFixed(2)}</span>
          </div>
        )}

        {user.level_id === Constants.ROLE_ADMIN && (
          <div className="input-container">
            <div className="label">Días de crédito:</div>
            <span>{order?.credit_days ?? 0}</span>
          </div>
        )}

        {(user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && user.show_order_total === Constants.STATUS_ACTIVE && (
          <div className="input-container">
            <div className="label">Días de crédito:</div>
            <span>{order?.credit_days ?? 0}</span>
          </div>
        )}

      </div>
			{/* {order.details.map((product) => {
				if (product?.product) {
					return (
						<div className="product-item">
							<div className="product-info">
								<div>x{ product?.quantity || 0 } - { product?.product?.name || '-' }</div>
								<div>$ { Globals.formatMiles(product?.price * product?.quantity,true) }</div>
							</div>
							<div className="product-details">
								{product.color_id && (
									<div className="product-color" style={{ backgroundColor: product.color?.hex }} />
								)}
								{product.size_id && (
									<div className="product-size">{ product.size?.name }</div>
								)}
							</div>
						</div>
					)
				}
				else {
					return null;
				}
			})} */}
      {user.level_id === Constants.ROLE_ADMIN && order.details.map((product) => {
        if (product?.product) {
          return (
            <div className="product-item">
              <div className="product-info">
                <div>x{product?.quantity || 0} - {product?.product?.name || '-'}</div>
                <div>$ {Globals.formatMiles(product?.price * product?.quantity, true)}</div>
              </div>
              <div className="product-details">
                {product.color_id && (
                  <div className="product-color" style={{ backgroundColor: product.color?.hex }} />
                )}
                {product.size_id && (
                  <div className="product-size">{product.size?.name}</div>
                )}
              </div>
            </div>
          )
        } else {
          return null;
        }
      })}

      {(user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && (user.show_order_total === Constants.STATUS_ACTIVE) && order.details.map((product) => {
        if (product?.product) {
          return (
            <div className="product-item">
              <div className="product-info">
                <div>x{product?.quantity || 0} - {product?.product?.name || '-'}</div>
                <div>$ {Globals.formatMiles(product?.price * product?.quantity, true)}</div>
              </div>
              <div className="product-details">
                {product.color_id && (
                  <div className="product-color" style={{ backgroundColor: product.color?.hex }} />
                )}
                {product.size_id && (
                  <div className="product-size">{product.size?.name}</div>
                )}
              </div>
            </div>
          )
        } else {
          return null;
        }
      })}

      {(user.level_id === Constants.ROLE_MODERATOR || user.level_id === Constants.ROLE_MANAGER) && (user.show_order_total === Constants.STATUS_INACTIVE) && order.details.map((product) => {
        if (product?.product) {
          return (
            <div className="product-item">
              <div className="product-info">
                <div>{product?.product?.name || '-'}</div>
                <div>{product?.quantity || 0}</div>
              </div>
              <div className="product-details">
                {product.color_id && (
                  <div className="product-color" style={{ backgroundColor: product.color?.hex }} />
                )}
                {product.size_id && (
                  <div className="product-size">{product.size?.name}</div>
                )}
              </div>
            </div>
          )
        } else {
          return null;
        }
      })}

      <div style={{ marginTop: '1rem' }}>
        <Input
          color=" "
          name="dispatch_comment"
          label="Observaciones"
          labelClass="material"
          className="material"
          onChange={e => setForm({ dispatch_comment: e.target.value })}
        />
      </div>

      <div id="button" style={{ display: 'flex', justifyContent: 'center', marginTop: '1rem' }}>
        <Button
          onClick={() => onAccept(form)}
          type="button"
        >
          DESPACHAR
        </Button>
      </div>

		</div>
	)
}

const styles = {};

export default connect(state => {
  return {
    user: state.user
  };
})(Dispatchs);
