import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment, { weekdays } from 'moment';
import { Button, DatePicker, Input, Select, Modal, SelectSearch } from '../../components';
import Menu from '../menu';
import LocationPinIcon from '../../assets/icons/location-pin.png';
import ModalSellers from './modal-sellers';
import { axios, Constants, Globals } from "../../utils";
import CloseBlackIcon from '../../assets/icons/close-black.png';
import ArrowLeft from '../../assets/icons/arrow-left.png';
import ENV from "../../utils/env";
import Socket from "../../utils/socket";
import ModalViewClient from './modal-view-client';

const INTERVAL_TYPE = {
  DAILY: 1,
  WEEKLY: 2,
}

// @ts-ignore
const google = window.google;

export class VisitPlannerCreate extends Component {

  state = {
    intervalType: INTERVAL_TYPE.DAILY,
    form: {
      id: null,
      date: '',
      client_id: '',
      visit_type_id: '',
      seller_id: ''
    },
    weekdays: [],
    clients: [],
    filteredClients: [],
    sellers: [],
    data: [],
    visible_sellers: false,
    visit_types: [],
    visible_map: false,
    visible: false,
    map: null
  }

  async componentDidMount() {
    this.setWeek();
    this.load();
    this.getFromUrl();
  }

  getFromUrl = async () => {
    const parts = window.location.href.split('/');
    const id = parseInt(parts[parts.length - 1]);
    if (isNaN(id)) return;

    Globals.setLoading();
    axios
      .post(`admin/visit-planners/get-one`, { id, user_id: this.props.user.id })
      .then(res => {
        if (moment(res.data.visit_planner.date).isBefore(moment(moment().format('YYYY-MM-DD 00:00:00')))) {
          Globals.showError('La ruta finalizó');
          this.props.history.replace('/visit-planner');
        }
        if (res.data.visit_planner.status !== Constants.VISIT_PLANNER_STATUS.PENDING) {
          Globals.showError('No es posible modificar la ruta');
          this.props.history.replace('/visit-planner');
        }

        const data = res.data.visit_planner.details?.map(item => ({
          client_id: item.client_id,
          date: moment(res.data.visit_planner.date).toDate(),
          visit_type_id: item.visit_type_id,
        }));

        this.setState({
          form: {
            ...this.state.form,
            id,
            date: moment(res.data.visit_planner.date).toDate(),
            seller_id: res.data.visit_planner.seller_id,
          },
          data,
        });
      })
      .catch(() => Globals.showError())
      .finally(() => Globals.quitLoading());
  }

  setWeek = async (date = null) => {
    // if (date)
    //   date = moment(date).startOf('week').subtract(1,'day');
    // else
    //   date = moment();

    // if (moment() > date) {
    //   date = moment();
    // }

    // let weekdays = [];

    // for (let i = 0; i <= 6; i++) {
    //   weekdays.push({
    //     date: date.add(1,'day').format('YYYY-MM-DD'),
    //     selected: i == 0,
    //     data: [],
    //     form: {
    //       ...this.state.form,
    //       date: ''
    //     }
    //   });
    // }

    // await this.setState({
    //   weekdays
    // });

    if (date)
      date = moment(date).startOf('week');
    else
      date = moment().startOf('week');

    let weekdays = [];

    let today = moment().startOf('day'); // fecha actual a la medianoche
    let tempDate = moment(today);

    for (let i = 0; i <= 6; i++) {
      weekdays.push({
        date: tempDate.format('YYYY-MM-DD'),
        selected: i == 0,
        data: [],
        form: {
          ...this.state.form,
          date: ''
        }
      });

      if (tempDate.diff(today, 'days') >= 6) { // se detiene después de mostrar 6 días
        break;
      }

      tempDate.add(1, 'day');
    }

    await this.setState({
      weekdays
    });
  }

  load = async () => {
    let { form } = this.state;

    form.user_id = this.props.user.id;

    axios
      .post("admin/visit-planners/get-clients", form)
      .then(res => {
        this.setState({
          clients: res.data.clients,
          sellers: res.data.sellers,
          visit_types: res.data.visit_types
        });

        if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) == -1) {
          this.props.history.replace('/');
        }
      })
      .catch(() => {
        Globals.showError();
      });
  }

  onChangePlan = (value, target, index) => {
    const { data } = this.state;
    const newData = [ ...data ];

    newData[index][target] = value;
    this.setState({ data: newData });
  }

  onChangeForm = (value, target) => {
    this.setState(state => ({
      form: {
        ...state.form,
        [target]: value,
      }
    }));
  }

  canSubmit = () => {
    return (this.state.intervalType === INTERVAL_TYPE.DAILY && this.state.data.length > 0) ||
          this.state.weekdays.filter(i => i.data.length > 0).length > 0;
  }

  submit = () => {
    Globals.setLoading();

    let { form } = this.state;

    form.user_id = this.props.user.id;

    axios
      .post(`admin/visit-planners/${!!form.id ? 'edit':'save'}`, {
        ...form,
        date: this.state.form.date && moment(this.state.form.date).format('YYYY-MM-DD'),
        items: this.state.data,
        intervalType: this.state.intervalType,
        weekdays: this.state.weekdays
      })
      .then(res => {
        Globals.quitLoading();
        if (res.data.result) {
          this.props.history.replace('/visit-planner');
          Socket.emit(Constants.NEW_VISIT_PLANNER,{
            seller_id: this.state.form.seller_id
          });
        }
        else {
          Globals.showError(res.data.error);
        }
      })
      .catch(() => {
        Globals.showError();
        Globals.quitLoading();
      });
  }

  openSellers = () => {
    this.setState({
      visible_sellers: true
    });
  }

  closeSellers = () => {
    this.setState({
      visible_sellers: false
    });
  }

  add = () => {
    if (!this.state.form.client_id) {
      Globals.showError("Debe ingresar el cliente");
      return;
    }

    if (!this.state.form.visit_type_id) {
      Globals.showError("Debe ingresar el tipo de visita");
      return;
    }

    if (this.state.intervalType === INTERVAL_TYPE.DAILY) {
      let data = [...this.state.data];
      let check = false
      data.map(i => {
        if(i.client_id === this.state.form.client_id && (moment(i.date).format('YYYY-MM-DD') === moment(this.state.form.date).format('YYYY-MM-DD') )){
          check = true
        }
      })
      if(check){
        Globals.showError("Lo siento, pero no es posible programar una visita para el mismo cliente en el mismo día.");
        return;
      }
      data.push({
        date: this.state.form.date,
        client_id: this.state.form.client_id,
        visit_type_id: this.state.form.visit_type_id,
      });
      this.setState({
        data,
        form: {
          ...this.state.form,
          client_id: '',
          visit_type_id: '',
          date: ''
        }
      });

      console.log(this.state.data)
    }
    // else {
    //   let weekdays = [...this.state.weekdays];
    //   let index = weekdays.findIndex(i => i.selected);
    //   let check = false
    //   weekdays[index].data.map(i => {
    //     if (i.client_id === this.state.form.client_id) check = true
    //   })
    //   if(check){
    //     Globals.showError("Lo siento, pero no es posible programar una visita para el mismo cliente en el mismo día.");
    //     return;
    //   }
    //   weekdays[index].data.push({
    //     client_id: this.state.form.client_id,
    //     visit_type_id: this.state.form.visit_type_id,
    //   });
    //   this.setState({
    //     weekdays,
    //     form: {
    //       ...this.state.form,
    //       client_id: '',
    //       visit_type_id: ''
    //     }
    //   });
    // }    
  }

  remove = async (index) => {
    if (this.state.intervalType === INTERVAL_TYPE.DAILY) {
      let data = [...this.state.data];
      data.splice(index,1);
      this.setState({
        data
      });
    }
    else {
      let weekdays = [...this.state.weekdays];
      let _index = weekdays.findIndex(i => i.selected);
      weekdays[_index].data.splice(index,1);
      this.setState({
        weekdays
      });
    }    
  }

  next = () => {
    // if (!this.state.form.date && this.state.intervalType === INTERVAL_TYPE.DAILY) {
    //   Globals.showError("Debe ingresar la fecha");
    //   return;
    // }

    const allDatesFilled = this.state.data.every(item => item.date !== '');
    if (!allDatesFilled) {
      Globals.showError("Debe ingresar la fecha");
      return;
    }

    if (!this.state.form.seller_id) {
      Globals.showError("Debe ingresar el vendedor");
      return;
    }

    this.setState({
      visible_map: true
    },() => {
      setTimeout(async () => {
        const data = (this.state.intervalType === INTERVAL_TYPE.WEEKLY ? (this.state.weekdays.find(i => i.selected)?.data || []) : this.state.data).map((item) => {
          item.client = this.state.clients.find(i => i.id == item.client_id);
          return item;
        });

        const directionsService = new google.maps.DirectionsService();
        const directionsDisplay = new google.maps.DirectionsRenderer({
          suppressMarkers: true,
          polylineOptions: { 
            strokeColor: "#07a043",
            strokeWeight: 5
          }
        });

        if (this.state.map) {
          await this.setState({
            map: null
          });
        }

        if (data.length > 0) {
          const map = new google.maps.Map(document.getElementById('map-planner-create'), {
            zoom: 13,
            center: new google.maps.LatLng(data[0].client?.latitude,data[0].client?.longitude),
            draggable: true,
            zoomControl: true,
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            scaleControl: false,
            rotateControl: false
          });

          await this.setState({
            map
          });

          var waypoints = [];

          for (let i = 0; i < data.length; i++) {
            if (i != 0 && i != (data.length - 1)) {
              waypoints.push({
                location:  new google.maps.LatLng(data[i].client?.latitude,data[i].client?.longitude),
                stopover:true
              });
            }

            new google.maps.Marker({
              position: new google.maps.LatLng(data[i].client?.latitude,data[i].client?.longitude),
              map,
              title: '',
              icon: ENV.BasePublic + 'img/marker.png',
              animation: google.maps.Animation.DROP,
              draggable: false
            });
          }

          if (data.length > 1) {
            const request = {
                origin: new google.maps.LatLng(data[0].client?.latitude,data[0].client?.longitude),
                destination: new google.maps.LatLng(data[data.length - 1].client?.latitude,data[data.length - 1].client?.longitude),
                waypoints,
                optimizeWaypoints: true,
                travelMode: google.maps.DirectionsTravelMode.DRIVING
            };

            directionsDisplay.setMap(map);
            directionsService.route(request, (response, status) => {
                if (status == google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                }
            });
          }
        }
      },100);
    });
  }

  closeClient = () => {
    this.setState({
      visible: false,
      item: null
    });
  }

  sellerSelected = (seller_id) => {
    // Encuentra el vendedor seleccionado
    const selectedSeller = this.state.sellers.find(seller => seller.id === seller_id);

    if (!selectedSeller) {
      console.log('Vendedor no encontrado');
      return;
    }

    // Verifica si el vendedor tiene zonas asignadas
    const hasZones = selectedSeller.zones && selectedSeller.zones.length > 0;

    // Si el vendedor tiene zonas, obtén las zonas del vendedor seleccionado
    const sellerZones = hasZones ? selectedSeller.zones.map(zone => zone.id) : [];

    // Filtra los clientes que pertenecen a las zonas del vendedor seleccionado o que no tienen zona asignada
    const filteredClients = this.state.clients.filter(client => {
      // Si el vendedor tiene zonas, filtra por esas zonas
      if (hasZones) {
        return sellerZones.includes(client.zone_id);
      }
      // Si el vendedor no tiene zonas, incluye a los clientes que no tienen zona asignada
      else {
        return client.zone_id === null || client.zone_id === undefined;
      }
    });

    // Actualiza el estado con los clientes filtrados
    this.setState({
      filteredClients: filteredClients
    });
  }
   

  render() {
    const { intervalType, visible_sellers, visible } = this.state;
    const seller = this.state.sellers.find(i => i.id == this.state.form.seller_id);
    const client = this.state.clients.find(i => i.id == this.state.form.client_id);
    const data = (intervalType === INTERVAL_TYPE.WEEKLY ? (this.state.weekdays.find(i => i.selected)?.data || []) : this.state.data);
    const keys = ['id'];
    const zones = [...seller?.zones || [],seller?.zone || {}].filter(i => i !== null).filter((value, index, self) =>
      self.findIndex(v => keys.every(k => v[k] === value[k])) === index
    );
    const date = intervalType === INTERVAL_TYPE.DAILY ? this.state.form.date : this.state.weekdays.find(i => i.selected)?.date;

    return (
      <Menu history={this.props.history}>
        {
          visible_sellers && (
            <Modal
              className="modal-planner-sellers"
              title="Lista de Vendedores"
              onClose={this.closeSellers}
              visible
            >
              <ModalSellers
                onClose={this.closeSellers}
              />
            </Modal>
          )
        }

        {
          visible && (
            <Modal
              className="modal-planner-view-client"
              title=""
              onClose={this.closeClient}
              visible
            >
              <ModalViewClient
                item={ this.state.item }
                onClose={this.closeClient}
              />
            </Modal>
          )
        }

        <div id="visit-planner-create">
          <section>
            <div className="container-title">
              <img src={ ArrowLeft } onClick={ () => {
                this.props.history.replace('/visit-planner');
              } } />
              <span>Periodo del plan</span>
            </div>
            {
                // !this.state.visible_map && (
                //   <div className="actions">
                //     <div className="interval-type">
                //       <button
                //         className={`${ intervalType === INTERVAL_TYPE.DAILY ? 'selected':'' }`}
                //         onClick={() => this.setState({ intervalType: INTERVAL_TYPE.DAILY })}
                //       >
                //         DIARIO
                //       </button>

                //       <button
                //         className={`${ intervalType === INTERVAL_TYPE.WEEKLY ? 'selected':'' }`}
                //         onClick={() => this.setState({ intervalType: INTERVAL_TYPE.WEEKLY })}
                //       >
                //         SEMANAL
                //       </button>
                //     </div>
                //     <button className="alt-btn" onClick={ () => this.openSellers() }>Lista de vendedores</button>
                //   </div>
                // )
            }
          </section>

          {
            !this.state.visible_map && (
              <div className="table-wrapper">
                <div className="table-filters">
                  <div className="by-date">
                    <SelectSearch
                      label="Seleccionar vendedor"
                      className="filter"
                      labelClass="filter"
                      options={ this.state.sellers.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        }
                      })}
                      value={ seller ? {
                        value: seller.id,
                        label: seller.name
                      } : null }
                      onChange={ (data) => {
                        this.setState({
                          form: {
                            ...this.state.form,
                            seller_id: data.value
                          }
                        });
                        this.sellerSelected(data.value)
                      } }
                    />
                  </div>
                  <div className="col-5 note">
                    <p>Para mostrar todos tus clientes, debes actualizar la ubicación de su dirección en el mapa desde la App</p>
                  </div>
                </div>

                <div className="table-filters">
                  <div className="by-date">
                    <DatePicker
                      color="white"
                      label={intervalType === INTERVAL_TYPE.WEEKLY ? 'Fecha de inicio' : 'Seleccionar fecha'}
                      className="filter"
                      minDate={ moment().toDate() }
                      value={this.state.form.date}
                      onChange={value => {
                        if (intervalType === INTERVAL_TYPE.DAILY) {
                          this.onChangeForm(value, 'date');
                        }
                        else {
                          this.setWeek(value);
                        }
                      }}
                    />
                  </div>
                  <div className="by-date">
                    <SelectSearch
                      label="Seleccionar cliente"
                      className="filter"
                      labelClass="filter"
                      // options={ this.state.clients.map((item) => {
                      //   return {
                      //     value: item.id,
                      //     label: item.name
                      //   }
                      // })}
                      options={
                        this.state.filteredClients.length > 0
                          ? this.state.filteredClients.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                          : this.state.clients.map((item) => {
                            return {
                              value: item.id,
                              label: item.name,
                            };
                          })
                      }
                      value={ client ? {
                        value: client.id,
                        label: client.name
                      } : null }
                      onChange={ (data) => {
                        this.setState({
                          form: {
                            ...this.state.form,
                            client_id: data.value
                          }
                        });
                      } }
                    />
                  </div>
                  <div className="by-date">
                    <Select
                      label="Tipo de visita"
                      className="filter"
                      labelClass="filter"
                      options={ this.state.visit_types.map((item) => {
                        return {
                          value: item.id,
                          label: item.name
                        }
                      })}
                      value={ this.state.form.visit_type_id }
                      onChange={e => this.onChangeForm(e.target.value, 'visit_type_id')}
                    />
                  </div>
                  <div className="by-date">
                    <Button className="btn-save" onClick={ () => {
                      this.add();
                    } }>
                      <span>Cargar</span>
                    </Button>
                  </div>
                </div>

                <div className="interval-date">
                  {(intervalType === INTERVAL_TYPE.DAILY && !!this.state.form.date) && (
                    <div className="selected-date">
                      { moment(this.state.form.date).locale('es').format('dddd D') }
                      <span> de </span>
                      { moment(this.state.form.date).locale('es').format('MMMM YYYY') }
                    </div>
                  )}

                  {(intervalType === INTERVAL_TYPE.WEEKLY) && this.state.weekdays.map((day,index) => {
                    return (
                      <button className={ `week-day ${ day.selected ? 'selected' : '' }` } onClick={ () => {
                        let weekdays = [...this.state.weekdays.map(i => {
                          i.selected = false;
                          return i;
                        })];
                        weekdays[index].selected = !weekdays[index].selected;
                        this.setState({
                          weekdays
                        });
                      } }>
                        { moment(day.date).format('dddd DD') }
                      </button>
                    )
                  })}
                </div>

                <table className="table" width="100%">
                  {
                    data.length > 0 && (
                      <thead className="header">
                        <tr>
                          <th>Fecha</th>
                          <th>Nombre</th>
                          <th>Zona</th>
                          <th>Dirección</th>
                          <th>Ubicación</th>
                          <th>Estatus</th>
                          <th>Tipo de visita</th>
                          <th></th>
                        </tr>
                      </thead>
                    )
                  }

                  <tbody className="body">
                    {data.map((item, index) => {
                      const client = this.state.clients.find(i => i.id == item.client_id);
                      const visit_type = this.state.visit_types.find(i => i.id == item.visit_type_id);

                      return (
                        <tr key={index} className="tr">
                          <td>
                            { moment(item.date).format('DD-MM-YYYY') }
                          </td>
                          <td>
                            { client?.name }
                          </td>
                          <td>
                            { client?.zone?.name }
                          </td>
                          <td>
                            { client?.address }
                          </td>
                          <td>
                            <img
                              className="location-pin"
                              src={LocationPinIcon}
                              alt="Ubicación"
                              title="Ubicación"
                              onClick={() => this.setState({
                                visible: true,
                                item: client
                              })}
                            />
                          </td>
                          <td>
                            {
                              client?.last_visit?.results?.map((item) => {
                                return (
                                  <p className="result-visit">{ item.name }</p>
                                )
                              })
                            }
                            {
                              client?.last_visit == null && (
                                <p className="result-visit">Nuevo</p>
                              )
                            }
                          </td>
                          <td>
                            { visit_type?.name }
                          </td>
                          <td>
                            <button className="btn-remove" onClick={ () => this.remove(index) }>
                              <img src={ CloseBlackIcon } />
                            </button>
                          </td>
                        </tr>
                      )
                    })}

                    <tr>
                      <td colSpan={ 8 }>
                        <div className="footer">
                          <Button disabled={!this.canSubmit()} onClick={this.next} className="btn-next">
                            <span>Siguiente</span>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  </tbody>              
                </table>
              </div>
            )
          }

          {
            this.state.visible_map && (
              <div className="table-wrapper table-wrapper-route">
                <h3 className="seller seller-title">Ruta del vendedor</h3>

                {
                  (intervalType === INTERVAL_TYPE.WEEKLY) && (
                    <div className="interval-date">
                      { this.state.weekdays.map((day,index) => {
                        return (
                          <button className={ `week-day ${ day.selected ? 'selected' : '' }` } onClick={ () => {
                            let weekdays = [...this.state.weekdays.map(i => {
                              i.selected = false;
                              return i;
                            })];
                            weekdays[index].selected = !weekdays[index].selected;
                            this.setState({
                              weekdays
                            },() => this.next());
                          } }>
                            { moment(day.date).format('dddd DD') }
                          </button>
                        )
                      })}
                    </div>
                  )
                }

                <div className="row">
                  <div className="col-md-6">
                    <div id="map-planner-create" style={{
                      display: data.length > 0 ? 'block' : 'none'
                    }}></div>
                    {
                      !this.state.map && (
                        <p className="no-map">No hay rutas planificadas para este día</p>
                      )
                    }
                  </div>
                  <div className="col-md-6">
                    <div className="row">
                      <div className="col-md-8">
                        <p className="title">Vendedor</p>
                        <h3 className="seller">{ seller?.name }</h3>
                        <p className="zones">{ zones.map(i => i.name).filter(i => i != null).join(', ') }</p>
                      </div>
                      {/* <div className="col-md-4">
                        <p className="gray text-right">Fecha</p>
                        <p className="date text-right">{ moment(date).format('DD-MM-YYYY') }</p>
                      </div> */}
                    </div>                    
                    <table width="100%">
                      <tr>
                        <th width="25%">Fecha</th>
                        <th width="50%">Cliente</th>
                        <th width="25%" className="text-right">Tipo de visita</th>
                      </tr>
                      {
                        data.map((item, index) => {
                          const client = this.state.clients.find(i => i.id == item.client_id);
                          const visit_type = this.state.visit_types.find(i => i.id == item.visit_type_id);

                          return (
                            <tr>
                              <td width="25%" className="text-left">{ moment(item.date).format('DD-MM-YYYY') }</td>
                              <td width="50%">
                                <p className="name">{ client?.name }</p>
                                <p className="address">{ client?.address }</p>
                              </td>
                              <td width="25%" className="text-right">{ visit_type?.name }</td>
                            </tr>
                          )
                        })
                      }
                    </table>
                  </div>
                </div>

                <div className="container-buttons">
                  <Button onClick={ () => {
                    this.setState({
                      visible_map: false
                    });
                  } } className="btn-next btn-back">
                    <span>Regresar</span>
                  </Button>
                  <Button disabled={!this.canSubmit()} onClick={this.submit} className="btn-next">
                    <span>Guardar</span>
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      </Menu>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(VisitPlannerCreate)