import axios from "../../utils/axios";

class ReturnsDeliveryOrBill {

    static async getReturns(page, form) {
        return new Promise((resolve, reject) => {
            axios.post(`admin/returns/get?page=${page}`, form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async generateId(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/generateId", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async createReturnFrom(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/createreturnfrom", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async getReturnFromReport(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/getreturnfromreport", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async getReturnFromReportPDF(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/getreturnfromreportPDF", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async getOrders(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/getorders", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

    static async getOrderByCode(form) {
        return new Promise((resolve, reject) => {
            axios.post("admin/returns/getorderbycode", form).then(
                ({ data }) => {
                    // http success
                    resolve(data);
                },
                ({ response }) => {
                    const { data } = response;
                    // http failed
                    reject(data);
                }
            );
        });
    }

}

export default ReturnsDeliveryOrBill;