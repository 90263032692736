import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Input, Select, DatePicker, SelectActions, Modal } from "../../components";
import { Constants, Globals, axios, Socket, SocketEvents } from "../../utils";

import { Branch, Warehouse } from "../../services";
import CreateEditZone from "../zones/create_edit_zone";

const SHOW_PRICES = {
	YES: 1,
	NO: 0
}

class CreateEditModerator extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            submitted: "",
            modalZones: false,
            editZone: null,
            form: {
                id: "",
                name: "",
                phone: "",
                document_type: "V",
                document: "",
                level: this.props.user.level,
                email: "",
                password: "",
                password_confirmation: "",
                image: "",
                branch_id: 0,
                zone_id: "",
                warehouse_id: "",
                goal_amount: '',
                goal_date: '',
                admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
                role: Constants.ROLE_SELLER,
                permissions: [],
                modules: [],
                show_order_total: SHOW_PRICES.YES,
                commission: "",
                calculate_commission: Constants.STATUS_ACTIVE,
            },
            errors: [],
            zones: [],
            branches: [],
            warehouses: [],
            roles: [
                {
                    value: Constants.ROLE_ADMIN,
                    label: 'Admin'
                },
                {
                    value: Constants.ROLE_MODERATOR,
                    label: 'Moderador'
                },
                {
                    value: Constants.ROLE_SELLER,
                    label: 'Moderador'
                },
                {
                    value: Constants.ROLE_MANAGER,
                    label: 'Gerente'
                },
            ],
            edit: false,
            textButton: "Crear",
            permissions: this.props.permissions,
            modules: this.props.modules
        };

        this.handleChange = this.handleChange.bind(this);
    }

    abortController = new AbortController();

    componentDidMount() {
        const { edit } = this.props;

        this.load();

        if (!edit) {
            return;
        }

        this.edit();
    }

    componentWillUnmount() {
        this.abortController.abort();
    }

    load = () => {
        this.getWarehouses()
    };

    getWarehouses = async () => {
        Globals.setLoading();

        try {
            const data = {
                web: true,
                user_id: this.props.user.id,
                is_select: true,
            };

            const res = await axios.post('web/admin/warehouses', data);
            if(res.data?.warehouses){
                const formatWarehouses = res.data.warehouses.map(Item => ({
                    value: Item.id,
                    label: Item.name
                }));
    
                this.setState({
                    warehouses: formatWarehouses
                });
            }
        } catch (error) {
            Globals.showError();
            console.log('GetWarehouses -> load -> catch: ', error);
        }

        Globals.quitLoading();
    };

    getZones = () => {
        axios
            .post("web/admin/zones", { user_id: this.props.user.id })
            .then(({ data }) => {
                this.setState({ zones: data.zones });
            })
            .catch(() => Globals.showError())
            .then(() => Globals.quitLoading());
    };

    getBranches = () => {
        const { user } = this.props;

        const enterpriseId = user.id;

        Branch.getBranches({
            role: user.role,
            enterprise_id: enterpriseId
        }).then(response => {
            if (response.result) {
                const branches_filtered = response.branches.data.filter(
                    ({ status, deleted_at }) =>
                        status === Constants.STATUS_ACTIVE && !deleted_at
                );
                this.setState(state => ({
                    ...state,
                    branches: branches_filtered,
                    form: {
                        ...state.form,
                        warehouse_id: state.form.warehouse_id ? state.form.warehouse_id : ""
                    }
                }));
            }
        });
    };

    handleSubmit = emitter => {
        emitter.preventDefault();

        let param = { ...this.state.form };
        param.user_id = this.props.user.id;
        param.web = true;

        Globals.setLoading();
        axios
            .post(
                this.props.edit ? "admin/moderators/edit" : "admin/moderators/create",
                param
            )
            .then(res => {
                this.setState(this.defaultState);
                let msg = ''
                this.props.edit ? msg = "Moderador editado" : msg = "Moderador creado"
                Globals.showSuccess(msg);
                this.props.onClose();
                if (this.props.edit) {
                    Socket.emit(SocketEvents.USERS.UPDATE, { id: this.props.edit.element.id });
                }
            })
            .catch(err => {
                if (err.response.status === 422) {
                    Globals.showError(err.response.data.error);
                    return;
                }
                Globals.showError();
            })
            .then(() => {
                Globals.quitLoading();
            });
    };

    edit = async () => {
        const { element } = this.props.edit;

        await this.setState({
            form: {
                id: element.id,
                moderator_id: element.id,
                name: element.name,
                email: element.email,
                //document_type: documents.document_type,
                document: element.document,
                level: element.level_id,
                role: element.level_id,
                password: "",
                password_confirmation: "",
                permissions: element?.permissions?.map(x => x.id) || [],
                //modules: element?.modules?.map(x => x.id) || [],
                modules: element?.modules?.filter(module => module.status === 1).map(x => x.id) || [],
                show_order_total: element?.show_order_total,
                warehouse_id: element?.warehouse_id,
                commission: element?.commission,
                calculate_commission: element?.calculate_commission ?? Constants.STATUS_ACTIVE,
            },
            edit: true,
            textButton: "Editar"
        });
    };

    showSelect = () => {
        const { user } = this.props;
        let select;

        // if (
        //   Types[user.role].value === Types[1].value ||
        //   Types[user.role].value === Types[2].value
        // ) {
        //   select = (
        //     <div className="col-md-6">
        //       <Select
        //         color=" "
        //         label="Tipo de usuario"
        //         name="level"
        //         labelClass="material"
        //         className="material"
        //         defaultname="Seleccione"
        //         value={this.state.form.level ? this.state.form.level : ""}
        //         options={[
        //           {
        //             label: "Administrador",
        //             value: 2
        //           }
        //         ]}
        //         error={`${this.hasErrorFor("level") ? "is-invalid" : ""}`}
        //         invalidfeedback={this.renderErrorFor("level")}
        //         onChange={this.handleChange}
        //       />
        //     </div>
        //   );
        // } else if (this.state.form.id != user.id) {
        //   select = (
        //     <div className="col-md-6">
        //       <Select
        //         color=" "
        //         label="Rol"
        //         name="role"
        //         labelClass="material"
        //         className="material"
        //         options={this.state.roles}
        //         value={this.state.form.role}
        //         error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
        //         invalidfeedback={this.renderErrorFor("role")}
        //         onChange={this.handleChange}
        //       />
        //     </div>
        //   );
        // }

        select = (
            <div className="col-md-6">
                <Select
                    color=" "
                    label="Rol"
                    name="role"
                    labelClass="material"
                    className="material"
                    options={this.state.roles}
                    value={this.state.form.role}
                    error={`${this.hasErrorFor("role") ? "is-invalid" : ""}`}
                    invalidfeedback={this.renderErrorFor("role")}
                    onChange={this.handleChange}
                />
            </div>
        );

        return select;
    };

    showSelectWarehouse = () => {
        const { user } = this.props;
        let select;

        const isSeller = Number(this.state.form.role) === Constants.ROLE_SELLER;
        const isAdmin = Number(this.state.form.role) === Constants.ROLE_MODERATOR;

        // if (
        //   (Types[user.role].value === Types[3].value ||
        //   Types[user.role].value === Types[4].value) && this.state.form.id != user.id
        // ) {
        // }

        select = (
            <div className="row">
                <div className="col-md-6">
                    <Select
                        color=" "
                        name="branch_id"
                        label="Sucursal"
                        labelClass="material"
                        className="material"
                        //defaultname={isSeller ? 'Seleccione' : 'Todos'}
                        defaultname={isAdmin ? 'Todos' : 'Seleccione'}
                        defaultvalue='0'
                        disabledFirst={isAdmin ? 'false' : 'true'}
                        onChange={async e => {
                            await this.handleChange(e);
                            await this.getWarehouses(this.state.form.branch_id);
                        }}
                        value={this.state.form.branch_id}
                        options={this.state.branches.map(i => {
                            return {
                                value: i.id,
                                label: i.name
                            };
                        })}
                    />
                </div>
                <div className="col-md-6">
                    <Select
                        color=" "
                        name="warehouse_id"
                        label="Almacén"
                        labelClass="material"
                        className="material"
                        // defaultname={isSeller ? 'Seleccione' : 'Todos'}
                        // disabledFirst={isSeller ? 'true' : 'false'}
                        defaultname={isAdmin ? 'Todos' : 'Seleccione'}
                        disabledFirst={isAdmin ? 'false' : 'true'}
                        onChange={this.handleChange}
                        value={this.state.form.warehouse_id}
                        options={this.state.warehouses.map(i => {
                            return {
                                value: i.id,
                                label: i.name
                            };
                        })}
                    />
                </div>
            </div>
        );

        return select;
    };

    handleChange = emitter => {
        const { name, value, type, checked } = emitter.target;
        const isCheckbox = type === 'checkbox';
        const newValue = isCheckbox ? checked : value;
    
        this.setState(prevState => ({
            form: {
                ...prevState.form,
                [name]: newValue
            }
        }));
    };    

    togglePermission = (id) => {
        const { form } = this.state;
        const exists = form.permissions.includes(id);
        if (exists) {
            const others = form.permissions.filter(x => x !== id);
            this.setState({ form: { ...form, permissions: others } });
        } else {
            const permissions = [ ...form.permissions, id ];
            this.setState({ form: { ...form, permissions } });
        }
    };

    toggleModules = (id) => {
        const { form } = this.state;
        const exists = form.modules.includes(id);
        if (exists) {
            const others = form.modules.filter(x => x !== id);
            this.setState({ form: { ...form, modules: others } });
        } else {
            const modules = [ ...form.modules, id ];
            this.setState({ form: { ...form, modules } });
        }
    };

    hasErrorFor(field) {
        return !!this.state.errors[field];
    }

    renderErrorFor(field) {
        if (this.hasErrorFor(field)) {
            return (
                <span className="invalid-feedback my-2 text-left">
                    <strong>{this.state.errors[field][0]}</strong>
                </span>
            );
        }
    };

    createZone = () => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
        this.setState({ modalZones: true, editZone: null });
    };

    editZone = (item, index) => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1040;
        this.setState({
            modalZones: true,
            editZone: {
                element: item,
                index: index,
            }
        })
    };

    deleteZone = (item) => {
        Globals.confirm('¿Desea eliminar la zona: ' + item.name + '?', () => {
            Globals.setLoading("Eliminando...");
            axios.post('web/admin/zones/delete', { zone_id: item.id })
                .then(res => {
                    Globals.showSuccess('zona eliminada con exito');
                    this.getZones();
                })
                .catch(err => {
                    Globals.showError(err.response.data.msg);
                })
                .then(() => {
                    Globals.quitLoading();
                });
        });
    };

    close = async (reloading = false) => {
        document.getElementsByClassName('modal').item(0).style.zIndex = 1050;
        await this.setState({ modalZones: false, editZone: null });
        if (reloading) await this.getZones();
    }

    onChange = async (value, target) => {
		await this.setState(state => ({
			form: {
				...state.form,
				[target]: !!value?.target ? value?.target?.value : value,
			}
		}));
	}

    render() {
        const { submitted, permissions, modules } = this.state;
        const textNameField = this.props.user.role === 1 ? "Nombre/Razón Social" : "Nombre y apellido";

        return (
            <form onSubmit={this.handleSubmit}>
                <Modal
                    title={this.state.edit ? 'Editar zona' : "Nueva zona"}
                    onClose={() => this.close()}
                    visible={this.state.modalZones}
                >
                    <CreateEditZone
                        user={this.props.user}
                        edit={this.state.editZone}
                        onClose={() => this.close(true)}
                    />
                </Modal>

                {this.state.modalZones && (
                    <div
                        className="backdrop"
                        onClick={() => this.close()}
                    />
                )}
                <div className="container-create-edit-user">
                    <div className="row">
                        {/* <div className="col-md-6">
                            <Input
                                type="text"
                                color=" "
                                value={this.state.form.name}
                                name="name"
                                label="Nombre de usuario"
                                className="material"
                                labelClass="material"
                                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                                invalidfeedback={this.renderErrorFor("name")}
                                onChange={this.handleChange}
                            />
                        </div> */}
                        <div className="col-md-6">
                            <Input
                                type="text"
                                color=" "
                                value={this.state.form.name}
                                name="name"
                                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                                invalidfeedback={this.renderErrorFor("name")}
                                label={textNameField}
                                className="material"
                                labelClass="material"
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                color=" "
                                type="email"
                                value={this.state.form.email}
                                name="email"
                                label="Email"
                                className="material"
                                labelClass="material"
                                error={`${this.hasErrorFor("email") ? "is-invalid" : ""}`}
                                invalidfeedback={this.renderErrorFor("email")}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-6">
                            <Input
                                color=" "
                                type="number"
                                value={this.state.form.commission}
                                name="commission"
                                label="Comisión (Opcional)"
                                className="material"
                                labelClass="material"
                                error={`${this.hasErrorFor("commission") ? "is-invalid" : ""}`}
                                invalidfeedback={this.renderErrorFor("commission")}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md-6">
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="calculate_commission"
                                    id="calculate_commission"
                                    checked={this.state.form.calculate_commission == Constants.STATUS_ACTIVE ? true : false}
                                    onChange={this.handleChange}
                                />
                                <label className="form-check-label" htmlFor="calculate_commission">
                                    Calcular comisión en pedidos
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md">
                            <Input
                                color=" "
                                value={this.state.form.password}
                                name="password"
                                type="password"
                                autoComplete="off"
                                label="Contraseña"
                                className="material"
                                labelClass="material"
                                error={`${this.hasErrorFor("password") ? "is-invalid" : ""}`}
                                invalidfeedback={this.renderErrorFor("password")}
                                onChange={this.handleChange}
                            />
                        </div>
                        <div className="col-md">
                            <Input
                                color=" "
                                value={this.state.form.password_confirmation}
                                name="password_confirmation"
                                type="password"
                                autoComplete="off"
                                label="Confirmar Contraseña"
                                className="material"
                                labelClass="material"
                                error={`${this.hasErrorFor("password_confirmation") ? "is-invalid" : ""
                                    }`}
                                invalidfeedback={this.renderErrorFor("password_confirmation")}
                                onChange={this.handleChange}
                            />
                        </div>
                    </div>

                    <div><b>Módulos</b></div>
                    <ul style={{ paddingLeft: 0, display: 'grid', columnGap: '1rem', gridTemplateColumns: 'repeat(2,1fr)' }}>
                        {modules.map(x => (
                            <ModuleItem
                                label={x.name}
                                isSelected={this.state.form.modules.includes(x.id)}
                                onPress={() => this.toggleModules(x.id)}
                            />
                        ))}
                    </ul>

                    <div style={{ display: 'grid', columnGap: '1rem', gridTemplateColumns: 'repeat(2,1fr)', marginTop: '3rem', marginBottom: '1rem' }}>
                        <div>
                            <div><b>Permisos</b></div>
                            <div className="row">
                                <div className="col-md-12">
                                    <Select
                                        color=" "
                                        label="Facturar / Visualizar existencia de Almacén"
                                        defaultname="Todos"
                                        disabledFirst="false"
                                        defaultvalue="0"
                                        name="warehouse_id"
                                        labelClass="material"
                                        className="material"
                                        options={this.state.warehouses}
                                        value={this.state.form.warehouse_id}
                                        error={`${this.hasErrorFor("warehouse_id") ? "is-invalid" : ""}`}
                                        invalidfeedback={this.renderErrorFor("warehouse_id")}
                                        onChange={this.handleChange}
                                    />
                                </div>
                            </div>

                            <ul style={{ paddingLeft: 0 }}>
                                {permissions.map(x => (
                                    <PermissionItem
                                        label={x.name}
                                        isSelected={this.state.form.permissions.includes(x.id)}
                                        onPress={() => this.togglePermission(x.id)}
                                    />
                                ))}
                            </ul>
                        </div>

                        <div>
                            <div><b>Mostrar monto total en Despachos</b></div>
                            <div className="checkbox-items">
                                <div
                                    className="checkbox-item"
                                    onClick={() => {
                                        this.setState(state => ({
                                            form: {
                                                ...state.form,
                                                show_order_total: SHOW_PRICES.YES,
                                            }
                                        }));
                                    }}
                                >
                                    <input id="show-prices-yes"
                                        type="checkbox"
                                        checked={this.state.form.show_order_total === SHOW_PRICES.YES ? true : false}
                                        onChange={() => {
                                            this.setState(state => ({
                                                form: {
                                                    ...state.form,
                                                    show_order_total: SHOW_PRICES.YES,
                                                }
                                            }));
                                        }}
                                    />
                                    <label htmlFor="show-prices-yes">Sí</label>
                                </div>

                                <div
                                    className="checkbox-item"
                                    onClick={() => {
                                        this.setState(state => ({
                                            form: {
                                                ...state.form,
                                                show_order_total: SHOW_PRICES.NO,
                                            }
                                        }));
                                    }}
                                >
                                    <input id="show-prices-no"
                                        type="checkbox"
                                        checked={this.state.form.show_order_total === SHOW_PRICES.NO ? true : false}
                                        onChange={() => {
                                            this.setState(state => ({
                                                form: {
                                                    ...state.form,
                                                    show_order_total: SHOW_PRICES.NO,
                                                }
                                            }));
                                        }}
                                    />
                                    <label htmlFor="show-prices-no">No</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    {submitted ? (
                        <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    ) : (
                        <div id="button" className="d-flex justify-content-center">
                            <Button color=" " type="submit" className="primary" disabled={submitted}>
                                <span>{this.state.textButton}</span>
                            </Button>
                        </div>
                    )}
                </div>
            </form>
        );
    }
}

const PermissionItem = ({ label, isSelected, onPress }) => (
    <li onClick={() => onPress()} className={`permission-item ${isSelected ? 'selected' : ''}`}>
        { label }
        <div className="checkbox" />
    </li>
);

const ModuleItem = ({ label, isSelected, onPress }) => (
    <li onClick={() => onPress()} className={`permission-item ${isSelected ? 'selected' : ''}`}>
        { label }
        <div className="checkbox" />
    </li>
);

CreateEditModerator.defaultState = {
    form: {
        id: "",
        name: "",
        phone: "",
        email: "",
        document_type: "V",
        document: "",
        level: "",
        role: "",
        email: "",
        password: "",
        password_confirmation: "",
        image: "",
        commission: "",
        calculate_commission: Constants.STATUS_ACTIVE,
        goal_amount: "",
        goal_date: "",
    }
};

export default connect(state => {
    return {
        user: state.user
    };
})(CreateEditModerator);
