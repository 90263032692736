import React from "react";
import { Table, Button } from "../../components";
import { connect } from "react-redux";
import { Globals } from "../../utils";
import { Module } from "../../services";

const HeaderModules = ["Modulo", "Acceso"];

class CreateEditModule extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        enterprise_id: "",
        modules: []
      },
      submitted: false,
      errors: [],
      modules: [],
      enterprises: [],
      edit: false,
      textButton: "Crear"
    };

    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
  }

  componentDidMount() {
    if (this.props.edit) this.edit();
  }

  load = () => {
    Globals.setLoading();
  };

  edit = async () => {
    const { element } = this.props.edit;

    let modules = element.enterprise_modules.map((Item, key) => {
      return Item.id;
    });

    await this.setState({
      form: {
        name: element.name,
        id: element.id,
        enterprise_id: element.enterprise_id,
        modules: modules
      },
      edit: true,
      textButton: "Editar"
    });

    Globals.quitLoading();
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleArrayChange = emitter => {
    const { name, value, checked } = emitter.target;

    switch (checked) {
      case true:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: [...state.form[name], parseInt(value)]
          }
        }));
        break;
      case false:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: state.form[name].filter(Item => Item !== parseInt(value))
          }
        }));
        break;

      default:
        break;
    }
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    if (this.state.submitted) {
      return;
    }

    const { form, edit } = this.state;

    this.setState({ submitted: true });

    switch (edit) {
      case true:
        Module.updateEnterpriseModules(form)
          .then(response => {
            this.setState(this.defaultState);
            Globals.showSuccess("¡Modulos actualizados exitosamente!");
            this.props.onClose();
          })
          .catch(error => {
            this.setState({ submitted: false, errors: error });
          });
        break;
      default:
        break;
    }
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { modules } = this.props;
    const { submitted } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="text-center container-create-edit-user">
          <Table
            data={this.state.modules}
            title="Asignar módulos a la empresa"
            header={HeaderModules}
          >
            {modules.map((module, key) => {
              return (
                <tr key={key}>
                  <td className="left-align">{module.name}</td>
                  <td className="right-align">
                    <input
                      type="checkbox"
                      title={module.id}
                      value={module.id}
                      name="modules"
                      defaultChecked={
                        this.state.form.modules.includes(module.id)
                          ? "checked"
                          : null
                      }
                      onClick={this.handleArrayChange}
                    />
                  </td>
                </tr>
              );
            })}
          </Table>

          {this.hasErrorFor("modules") && (
            <div className="row">
              <div className="col-md">
                <div className="alert alert-warning" role="alert">
                  {"Debe contener al menos 1 modulo."}
                </div>
              </div>
            </div>
          )}

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button">
              <Button block="true" disabled={submitted}>
                {this.state.textButton}
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

CreateEditModule.defaultState = {
  form: {
    name: "",
    enterprise_id: "",
    modules: []
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditModule);
