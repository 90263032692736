import axios from "../../utils/axios";

class CashCount {
    static async getDetails(form, id) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/${id}`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async getNotProcessedRequests(form) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/get`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async getCajas(form) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/getCajas`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async getDataByCaja(form) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/getdatabycaja`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async createCashCount(form, type) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/create/${type}`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }

    static async processCashCount(form) {
      return new Promise((resolve, reject) => {
        axios.post(`admin/cash-count/process`, form).then(
          ({ data }) => {
            // http success
            resolve(data);
          },
          ({ response }) => {
            const { data } = response;
            // http failed
            reject(data);
          }
        );
      });
    }
}

export default CashCount;