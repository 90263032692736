import React from "react";
import moment from "moment";

import { Globals, Constants } from "../../../utils";
import { List } from "../../../components";
import NumberFormat from "react-number-format";

class View extends React.Component {
  constructor(props) {
    super(props);
    console.log(props)
  }

  render() {
    const { Item, bank } = this.props;
    // console.log(Item)
    return (
      <div className="text-center container-view-user">
        <List.Container>
          <List.Item label="Monto">
            <NumberFormat
              value={Item.amount}
              displayType={"text"}
              thousandSeparator={true}
            />
          </List.Item>
          <List.Item label="Fecha">
            {parseFormat(Item.created_at)}
          </List.Item>
          <List.Item label="Banco">
            {bank.name}
          </List.Item>
          <List.Item label="Accion">
            {Item.description}
          </List.Item>
          {(Item.action.payment_type || Item.action.type) && (
            <>
              <List.Item label="Tipo de pago">
                {Constants.CASH_COUNT_METHOD_PAYMENTS.find(x => x.value === (Item.action.payment_type || Item.action.type)).label}
              </List.Item>
              {
                !!Item.operation_number && (
                  <List.Item label="número de referencia">
                    {Item.operation_number}
                  </List.Item>
                )
              }

            </>
          )}

        </List.Container>
      </div>
    );
  }
}


function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

export default View;
