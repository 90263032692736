const SocketEvents = {
	USERS: {
		DELETE: 'users/delete',
		UPDATE: 'users/update',
	},
	CHAT: {
		MESSAGE: 'chat/message',
		SET_VIEWED_CHAT: 'chat/viewed',
		SET_VIEWED_MESSAGE: 'chat/viewed/message'
	},
	BADGE: {
		UPDATE: 'badge/update'
	},
	STOCK: {
		ZERO: 'stock/zero',
		ALERT: 'stock/alert',
		ACCOUNTS_RECEIVABLE_DAYS: 'accounts-receivable/days',
		ACCOUNTS_RECEIVABLE: 'accounts-receivable/day',
		ACCOUNTS_TO_PAY_DAYS: 'accounts-to-pay/days',
		ACCOUNTS_TO_PAY: 'accounts-to-pay/day',
		ACCOUNTS_RECEIVABLE_FINAL: 'accounts-receivable/final-day',
		ACCOUNTS_TO_PAY_FINAL: 'accounts-to-pay/final-day',
		REGISTER_PAYMENT: 'payments/register',
		INCOME_TO_PAY: 'income/to-pay',
		NEW_ORDER: 'orders/new'
	},
	CATALOGUE: {
		UPDATE: 'catalogue/update'
	},
	NEW_VISIT_PLANNER: 'new-visit-planner',
	UPDATE_SUPERADMIN: 'superadmin/update',
	SEND_GLOBAL_NOTIFICATION: 'superadmin/global-notification',
	PUBLIC_STORE: {
		PREVIEW: {
			BANNER: 'public-store/preview/banner',
			COLORS: 'public-store/preview/colors',
		}
	}
}

export default SocketEvents;