import React from 'react';
import { Avatar, List } from '../../components';
import { ENV, Colors } from '../../utils';
import User from '../../assets/img/user.jpg';

class ViewUser extends React.Component {

	state = {
		user: this.props.user
	}

	getStatus = (status = this.state.user.status) => {
		let respuesta = "";
		switch(status) {
			case 0:
				respuesta = {
					text: "Nuevo",
					color: Colors.red
				}
				break;
				
			case 1:
				respuesta = {
					text: "Activo",
					color: Colors.green
				}
				break;

			case 2:
				respuesta = {
					text: "Suspendido",
					color: Colors.orange
				}
				break;
			default:
				break;
		}
		return respuesta;
	}
		
	render() {
		return (
			<div className="text-center container-view-user">
				<Avatar 
					source={ this.state.user.person.image ? ENV.BasePublic  + this.state.user.person.image : User } 
					size="130px" />
				<List.Container>
					<List.Item label="Nombre">
						{ this.state.user.person.name + ' ' + this.state.user.person.lastname }
					</List.Item>
					<List.Item label="Email">
						{ this.state.user.email }
					</List.Item>
					<List.Item label="Cédula de identidad">
						{ this.state.user.person.document }
					</List.Item>
					<List.Item label="Teléfono">
						{ this.state.user.person.phone }
					</List.Item>
					<List.Item label="Estatus">
						<span style={ {
							color: this.getStatus().color
						} }>{ this.getStatus().text }</span>
					</List.Item>
				</List.Container>
			</div>
		)
	}
}

export default ViewUser;