import axios from "../../utils/axios";

class ExpensesIngress {
  static async getId(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/expenses-ingress/generate-id`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getExpensesIngress(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/expenses-ingress/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createExpensesIngress(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/expenses-ingress/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async reportExpensesPayment(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/expenses-ingress/payment-report?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default ExpensesIngress;