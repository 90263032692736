import React from "react";
import { Avatar, List } from '../../components';
import { ENV } from '../../utils';
import User from '../../assets/img/user.jpg';

class ViewClient extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user
    };
  }

  render() {
    const { user } = this.state;
    console.log(user)

    return (
      <div className="text-center container-view-user">
        <Avatar 
					source={ user.avatar ? ENV.BasePublic + user.avatar : User } 
					size="130px" />
        <List.Container>
          <List.Item label="Nombre de Usuario">
            {user.name}
          </List.Item>
          <List.Item label="Nombre">
            {user.first_name} {user.last_name}
          </List.Item>
          <List.Item label="Email">{user.email}</List.Item>
          <List.Item label="Cedula">
            {user.identity_document}
          </List.Item>
          <List.Item label="RIF">
            {user.fiscal_identification}
          </List.Item>
          <List.Item label="Dirección">{user.direction}</List.Item>
          <List.Item label="Teléfono">{user.phone}</List.Item>
          <List.Item label="Administrador">
            {user.enterprise_administrator.administrator.name}
          </List.Item>
          <List.Item label="Serialización">
            {user.serialization ? "Si" : "No"}
          </List.Item>
          <List.Item label="Permisos">
            {user.enterprise_modules.length > 0
              ? user.enterprise_modules.map((Item, key) => {
                  return (
                    <span key={key} className="badge badge-pill mx-2 my-2 badge-light">
                      {Item.name}
                    </span>
                  );
                })
              : "No posee módulos asignados."}
          </List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewClient;
