import React from "react";
import { connect } from "react-redux";

import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  Modal,
  Input
} from "../../components";

import { axios, Globals } from "../../utils";
import { Branch, Warehouse } from "../../services";

import CreateEditPrice from "./create_edit_price";
import ViewPrice from "./view_price";

import NumberFormat from "react-number-format";

const HEADER_TABLE = [
  "Nombre",
  "Precio Min.",
  "Precio Max.",
  "Precio Oferta",
  "Sucursal",
  "Almacen",
  // "Status",
  "Acciones"
];
class Prices extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      page: 1,
      last_page: 1,
      data: [],
      branches: [],
      warehouses: [],
      product: null,
      view: false,
      form: {
        status: "",
        search: "",
        branch_id: "",
        warehouse_id: "",
        user_id: Globals.getEnterpriseId(props.user),
        role: props.user.role
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  abortController = new AbortController();
  componentWillUnmount() {
    this.abortController.abort();
  }

  load = (resetPage = null) => {
    let { page, form } = this.state;
    const { user } = this.props;

    if (typeof resetPage === "number") {
      page = resetPage;
    }

    let newForm = { ...form };

    if (!newForm.branch_id) {
      newForm.warehouse_id = Globals.getAssignedWarehouses(user);
    } else if (newForm.warehouse_id === "") {
      newForm.warehouse_id = Globals.getAssignedWarehouses(user);
    }

    axios
      .post("admin/prices/get?page=" + page, newForm)
      .then(res => {
        if (res.data.result) {
          if (res.data.prices.last_page > 0) {
            if (res.data.prices.data.length > 0) {
              this.setState({
                last_page: res.data.prices.last_page,
                data: res.data.prices.data,
                page: page
              });
            } else {
              this.load(1);
            }
          } else {
            this.setState({
              last_page: res.data.prices.last_page,
              data: res.data.prices.data
            });
          }
        }
      })
      .catch(() => Globals.showError())
      .finally(() => this.getBranches());
  };

  close = () => {
    this.setState(
      {
        create: false,
        view: false,
        edit: null,
        user: null
      },
      () => this.load()
    );
  };

  change = e => {
    let value = e.target.value;
    const target = e.target.name;

    if (target === "warehouse_id") {
      value = value === "" ? "" : [value];
    }

    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  getBranches = () => {
    let { user } = this.props;
    user.enterprise_id = Globals.getEnterpriseId(user);

    Branch.getBranches({
      role: user.role,
      enterprise_id: user.enterprise_id
    }).then(response => {
      if (response.result) {
        const branches_filtered = Globals.filterWarehouseOrBranches(
          response.branches.data,
          user,
          "branch"
        );
        this.setState(state => ({
          ...state,
          branches: branches_filtered
        }));
      }
    });
  };

  getWarehouses = id => {
    if (!id) {
      const warehouses_filtered = [];
      this.setState(state => ({
        ...state,
        warehouses: warehouses_filtered,
        form: {
          ...state.form,
          warehouse_id: ""
        }
      }));
      return;
    }

    let { user } = this.props;
    user.enterprise_id = Globals.getEnterpriseId(user);

    Warehouse.getWarehouses({
      role: user.role,
      user_id: user.enterprise_id,
      branch_id: id
    }).then(response => {
      if (response.result) {
        const warehouses_filtered = Globals.filterWarehouseOrBranches(
          response.warehouses.data,
          user,
          "warehouse"
        );

        this.setState(state => ({
          ...state,
          warehouses: warehouses_filtered,
          form: {
            ...state.form,
            warehouse_id: ""
          }
        }));
      }
    });
  };

  render() {
    const { user, history } = this.props;
    const {
      branches,
      create,
      data,
      edit,
      form,
      last_page,
      page,
      product,
      search,
      warehouses,
      view
    } = this.state;

    return (
      <Menu history={history}>
        <Modal title="Ver Precio" onClose={this.close} visible={view}>
          <ViewPrice product={product} />
        </Modal>
        <Modal
          title={edit ? "Editar Precio" : "Nuevo Precio"}
          onClose={this.close}
          visible={create}
        >
          <CreateEditPrice user={user} edit={edit} onClose={this.close} />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-sm-12 col-md">
              <Input
                color="white"
                name="search"
                label="Búsqueda"
                onChange={this.change}
                value={search}
                placeholder="Buscar por Nombre"
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="branch_id"
                label="Sucursales"
                defaultname="Todos"
                disabledFirst="false"
                onChange={async e => {
                  await this.change(e);
                  await this.getWarehouses(this.state.form.branch_id);
                }}
                value={form.branch_id}
                options={branches.map(i => {
                  return {
                    value: i.id,
                    label: i.name
                  };
                })}
              />
            </div>
            <div className="col-sm-12 col-md">
              <Select
                color="white"
                name="warehouse_id"
                label="Almacen"
                defaultname="Todos"
                disabledFirst="false"
                onChange={this.change}
                value={form.warehouse_id}
                options={warehouses.map(i => {
                  return {
                    value: i.id,
                    label: i.name
                  };
                })}
              />
            </div>
            <div className="col-sm-12 col-md">
              <Button className="btn-align-bottom" onClick={this.load}>
                Filtrar
              </Button>
            </div>
          </div>
          <Table data={data.length} title="Precios" header={HEADER_TABLE}>
            {data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.product.name}</td>
                  <td>
                    <NumberFormat
                      value={i.price}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={i.price_max}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td>
                    <NumberFormat
                      value={i.price_offer}
                      displayType={"text"}
                      thousandSeparator={true}
                    />
                  </td>
                  <td>
                    {i.warehouse.branch.name}{" "}
                    {i.warehouse.branch.deleted_at ? "(Eliminada)" : ""}
                  </td>
                  <td>
                    {i.warehouse.name}{" "}
                    {i.warehouse.deleted_at ? "(Eliminado)" : ""}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() =>
                        this.setState({
                          product: i,
                          view: true
                        })
                      }
                    >
                      <Icon name="eye" />
                    </Button>
                    {!i.warehouse.deleted_at && (
                      <Button
                        title="Editar"
                        color="primary"
                        small="true"
                        onClick={() =>
                          this.setState({
                            create: true,
                            edit: {
                              element: i,
                              index: index
                            }
                          })
                        }
                      >
                        <Icon name="edit" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={last_page}
            active={page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.load()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Prices);
