import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Globals, Constants, axios } from '../../utils';
import { Button, DatePicker } from '../../components';
import TrashIcon from '../../assets/icons/trash.png';

class AcceptOrder extends React.Component {

	state = {
		dates: [],
		form: {
			date: '',
			amount_payment: '',
			payment_date: ''
		}
	}

	componentDidMount() {
		const payment_date = moment().add(this.props.order?.credit_days,'days').format('DD/MM/YYYY');

		this.setState({
			form: {
				...this.state.form,
				payment_date
			}
		});
	}

	change = async (value,target,callback = null) => {
		await this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		},callback);
	}

	updateAmount = () => {
		const order = this.props.order;
		let amount_payment = 0
		if(this.state.dates.length > 0){
			amount_payment = ((order.total - order.initial_payment) / this.state.dates.length);
		}
		else{
			amount_payment = ''
		}

		this.setState({
			form: {
				...this.state.form,
				amount_payment
			}
		});
	}

	add = () => {
		if (this.state.form.date) {
			let dates = [...this.state.dates];
			const newDate = moment(this.state.form.date).format('DD-MM-YYYY');
			if (!dates.includes(newDate)) {
				dates.push(newDate);
				this.setState({
					dates,
					form: {
						...this.state.form,
						date: ''
					}
				},() => this.updateAmount());
			}
			else {
				this.setState({
					form: {
						...this.state.form,
						date: ''
					}
				},() => this.updateAmount());
			}
		}
		else {
			Globals.sendToast("Por favor, seleccione una fecha");
		}
	}

	delete = index => {
		let dates = [...this.state.dates];
		dates.splice(index,1);
		this.setState({
			dates
		},() => this.updateAmount());
	}

	close = () => {
		this.props.onClose();
	}

	submit = async () => {
		if (!this.state.dates.length && this.state.form.payment_type == Constants.PAYMENT_TYPES.TO_PAY) {
			Globals.showError("Por favor, seleccione las fechas de los pagos");
			return false;
		}

		if (this.state.form.payment_type == Constants.PAYMENT_TYPES.TO_PAY && this.state.dates.filter(i => moment(i,'DD-MM-YYYY') > moment(this.state.form.payment_date,'DD-MM-YYYY')).length > 0) {
			Globals.showError("Lo sentimos, la fecha de pago de los abonos no pueden ser mayores a la fecha de pago final");
			return false;
		}

    Globals.setLoading();
		try {
			const form = {
				order_id: this.props.order?.id,
				_user_id: this.props.user.id,
				...this.state.form,
				payment_date: this.state.form.payment_date && moment(this.state.form.payment_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
				dates: JSON.stringify([...this.state.dates.map(i => moment(i,'DD-MM-YYYY').format('YYYY-MM-DD'))])
			};
	
			await axios.post('admin/orders/accept', form);
			Globals.showSuccess("Se ha aceptado el pedido correctamente");
			this.props.onClose(true);

		} catch (error) {
      console.log(error);
      Globals.showError('Ocurrió un error al aceptar el pedido.');
		}
		Globals.quitLoading();
	}

	render() {
		const { dates } = this.state;
		console.log('this.state.form.amount_payment: ', this.state.form.amount_payment)

		return (
			<div id="modal-accept-order">
				<div className="col-8">
					<DatePicker
						label="Seleccionar Fechas de Pago"
						labelClass="material"
						className="material"
						minDate={ moment().add(1,'day').toDate() }
						//maxDate={ moment(this.state.form.payment_date, 'DD/MM/YYYY').toDate() }
						value={ this.state.form.date || new Date() }
						onChange={ value => {
							this.change(value,'date',() => this.add());
						} }
					/>
				</div>
				<div className="container-list-date">
					{
						dates.map((item,index) => (
							<div key={ index } className="container-date">
								<span className="item-date">{ item }</span>
								<img
									src={ TrashIcon }
									className="trash-icon"
									alt="Eliminar"
									title="Eliminar"
									onClick={ () => this.delete(index) }
								/>
							</div>
						))
					}
				</div>

				{!!this.state.form.amount_payment && (
					<p>Abonos Parciales: { Globals.formatMiles(this.state.form.amount_payment) }</p>
				)}

				{/* <p>Fecha de pago final: { this.state.form.payment_date }</p> */}
				<p>Días de crédito: { this.props.order?.credit_days ? this.props.order?.credit_days > 1 ? this.props.order?.credit_days + ' días' : this.props.order?.credit_days + ' día' : '' }</p>
				<span><strong>*El periodo de crédito comienza a partir de la fecha de despacho</strong></span>
				<div className="actions">
					<Button
						color="gray"
						className="secondary"
						type="button"
						onClick={ () => this.close() }
					>
						<span>Cancelar</span>
					</Button>
					<Button
						color="primary"
						className="primary"
						type="button"
						onClick={ () => this.submit() }
					>
						<span>Aceptar</span>
					</Button>
				</div>
			</div>
		)
	}
}

export default connect(state => {
  return {
    user: state.user
  };
})(AcceptOrder);