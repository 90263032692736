import React from "react";
import moment from "moment";

import { Button, Input, Textarea, ViewSerial } from "../../components";
import { Constants, Globals, Payments } from "../../utils";

import Banesco from '../../assets/icons/banesco.png';
import Banistmo from '../../assets/icons/banistmo.png';
import Binance from '../../assets/icons/binance.png';
import CashPayment from '../../assets/icons/cash-payment.png';
import TransferPayment from '../../assets/icons/transfer-payment.png';
import MobilePayment from '../../assets/icons/mobile-payment.png';
import Zelle from '../../assets/icons/zelle.png';
import Paypal from '../../assets/icons/paypal.png';
import DebitPayment from '../../assets/icons/debit-payment.png';
import { Delivery, ExchangeServices } from "../../services";
import { connect } from "react-redux";

const { LOCAL_CURRENCIES, PAYMENT_METHODS } = Constants;

const TYPE_LIST = {
  PENDIENTE: 0,
  PROCESADO: 1,
  ANULADO: 2
};

class View extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view_serials: false,
      item_serials: null,
      serials: null,
      code: '',
      editable: true,
      payment_method: [],
      exchangeRate: 0,
    };
  }

  componentDidMount() {
    this.maybeLoadGenerateId();
    this.maybeLoadExchangeRate();
    this.mapSerials();
    if (this.props.Item.status === TYPE_LIST.PROCESADO) this.nonEditable();
  }

  mapSerials = () => {
    this.props.Item.details.forEach(prod => {
      if (this.props.Item?.operation?.length > 0) {
        prod.serials = this.props.Item.operation?.filter(i => i.product_warehouse.product_id == prod.id) || [];

      } else if (this.props.Item?.processed_order?.operation?.length > 0 && prod?.product_warehouse?.length > 0) {
        const prodWareIds = prod?.product_warehouse.map(x => x.id);
        prod.serials = this.props.Item.processed_order?.operation?.filter(i => prodWareIds.includes(i.product_warehouse_id)) || [];

      } else {
        prod.serials = [];
      }
    });
  }

  nonEditable = () => {
    const { Item } = this.props;
    let payment_method = [];

    if (Item?.processed_order?.method_payment?.length > 0) {
      payment_method = Item.processed_order.method_payment.map(p => ({
        id: p?.type,
        amount: p?.amount || '0',
        processed: 0,
        reference_number: p?.reference_number,
      }));
    }

    this.setState({ editable: false, payment_method });
  }

  maybeLoadGenerateId = () => {
    const Id = Globals.getUserId(this.props.user);

    Delivery.generateId({ Id })
      .then(response => {
        this.setState({ code: response });
      })
      .catch(() => Globals.showError());
  };

  maybeLoadExchangeRate = () => {
    ExchangeServices.GetExchange()
      .then(res => {
        if (!res?.result && !!res?.exchange?.length && res?.exchange[0]?.conversion_rate)
          return Globals.showError('No se pudo obtener la tasa de cambio');

        this.setState({ exchangeRate: res?.exchange[0]?.conversion_rate || 0 });
      })
      .catch(() => Globals.showError());
  };

  generateNote = async () => {
    const { Item, user } = this.props;
    const { code, exchangeRate } = this.state;
    const balance = parseFloat(this.getBalance().toFixed(2));
    const localBalance = parseFloat(parseFloat(this.getBalance() * exchangeRate).toFixed(2));

    if (balance !== 0 || localBalance !== 0)
      return Globals.showError('El saldo debe ser cero (0)');

    const Id = Globals.getUserId(user);
    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    const cant = Item.details.filter(i => i.serialization === Constants.SERIALIZATION.ACTIVE && !i?.serials?.length).length;
    if (cant > 0) {
      Globals.showError("Hay productos que necesitan serializarse, por favor edita el pedido");
      return;
    }

    const data = {
      userId,
      code,
      enterprise_id: Id,
      warehouse_id: Item.warehouse_id,
      client_id: Item.client_id,
      creator_id: Item.creator_id,
      currency_id: Item.currency_id,
      request_order_id:Item.id,
      observations: Item.observations || '',
      taxable: Item.taxable,
      exempt: Item.exempt,
      subtotal: Item.subtotal,
      vat: Item.vat || 0,
      total: Item.total,
      description: '',
      type_payments: this.state.payment_method,
      products: Item.details.map(x => ({
        ...x,
        quantity: x?.pivot?.quantity,
        subtotal: x?.pivot?.subtotal,
        vat: x?.pivot?.vat,
        total: x?.pivot?.total,
      })),
    };

    Delivery.createDelivery(data)
    .then(async response => {
      const { code } = response;
      Globals.showSuccess(`Nota de entrega Nº ${code} creada exitosamente!`);
      this.props.onClose();
    })
    .catch(error => {
      if ((error || {}).message) {
        Globals.showError(error.message);
      } else if (error.hasOwnProperty("msg")) {
        Globals.showError(error.msg);
      } else {
        Globals.showError();
      }
    });
  }

  toggleInput = (target) => {
    if (!this.state.editable) return;

    if (LOCAL_CURRENCIES.includes(target) && !this.state.exchangeRate) {
      this.maybeLoadExchangeRate();
      return Globals.showError('¡No hay tasa de cambio. Intente nuevamente!');
    }

    const exists = this.state.payment_method.find(x => x.id === target);

    if (exists) {
      const others = this.state.payment_method.filter(x => x.id !== target);
      this.setState({ payment_method: others });

    } else {
      this.setState(prev => ({
        payment_method: [
          ...prev.payment_method,
          {
            id: target,
            amount: '',
            processed: 0,
            reference_number: '',
          }
        ]
      }));
    }
  }

  onChangeAmount = (event, target) => {
    if (!this.state.editable) return;

    const payments = [ ...this.state.payment_method ];
    const index = payments.findIndex(x => x.id === target);
    if (index === -1) return;

    payments[index].amount = event.target.value;
    this.setState({ payment_method: payments });
  }

  isSelectedPayment = (target) => {
    return this.state.payment_method.some(x => x.id === target);
  }

  getAmount = (target) => {
    return this.state.payment_method.find(x => x.id === target)?.amount || '';
  }

  getBalance = () => {
    const { exchangeRate, payment_method } = this.state;
    let total = this.props.Item.total;

    payment_method.forEach(x => {
      let amount = x.amount;

      if (LOCAL_CURRENCIES.includes(x.id)) {
        amount = !!exchangeRate
          ? parseFloat(amount / exchangeRate)
          : 0;
      }

      total -= parseFloat(amount || 0);
    });

    // return parseFloat(parseFloat(total).toFixed(2));
    return parseFloat(total);
  }

  render() {
    const { Item, status } = this.props;
    const { view_serials, item_serials, serials, exchangeRate } = this.state;
    const _Item = Item;
    return (
      <React.Fragment>
        { view_serials && (
          <ViewSerial onBack={ () => {
            this.setState({
              item_serials: null,
              view_serials: false,
              serials: null
            });
          } } 
          product={ item_serials } 
          serials={ serials } />
        ) }
        
        { !view_serials && (
          <React.Fragment>
            <div className="row">
              <div className="col-md">
                <div className="form-group">

                  <div className="row">
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="client"
                        label="Cliente"
                        className="material"
                        labelClass="material"
                        value={(Item.client.person || {}).first_name || (Item.client || {}).name}
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="code"
                        label={status ? "Nro. de Pedido:" : "Nro. de Factura:"}
                        className="material"
                        labelClass="material"
                        value={Item.code}
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="date_emission"
                        label="Fecha de Entrega"
                        className="material"
                        labelClass="material"
                        value={parseFormat(Item.date_emission)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="search_client"
                        label="Sucursal"
                        className="material"
                        labelClass="material"
                        value={Item?.warehouse?.branch?.name}
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="search_client"
                        label="Almacén"
                        className="material"
                        labelClass="material"
                        value={Item?.warehouse?.name}
                      />
                    </div>
                    <div className="col-md">
                      <Input
                        color=" "
                        disabled
                        name="search_client"
                        label="Buscar productos"
                        className="material"
                        labelClass="material"
                        value={''}
                      />
                    </div>
                  </div>

                  {/* <div className="table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th>{status ? "Nro. de Pedido:" : "Nro. de Factura:"}</th>
                          <td>{Item.code}</td>
                          <th>Fecha de emisión:</th>
                          <td>{parseFormat(Item.date_emission)}</td>
                        </tr>
                        <tr>
                          <th>Cliente:</th>
                          <td>
                            {(Item.client.person || {}).first_name ||
                              (Item.client || {}).name}
                          </td>
                          <th>Hora:</th>
                          <td>{timeFormat(Item.date_emission)}</td>
                        </tr>
                        <tr>
                          <th>Rif Cliente:</th>
                          <td>
                            {(Item.client.person || {}).fiscal_identification ||
                              (Item.client.person || {}).identity_document}
                          </td>
                        </tr>
                        <tr>
                          <th>Vendedor:</th>
                          <td>
                            {(Item.seller.person || {}).first_name ||
                              (Item.seller || {}).name}
                          </td>
                        </tr>
                      </thead>
                    </table>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="row view-request-modal">
              <div className="col-md">
                <div className="form-group">
                  <div className="table-responsive">
                    <table className="table table-bordered view-requests">
                      <thead>
                        <tr>
                          <th colSpan="7" className="text-center">
                            PRODUCTOS DEL PEDIDO
                          </th>
                        </tr>
                        <tr style={{ whiteSpace: "nowrap" }}>
                          <th>Código</th>
                          <th>Nombre</th>
                          <th>Serial del Producto</th>
                          <th>Cantidad</th>
                          <th>Precio Unitario</th>
                          <th>Sub-Total</th>
                          <th>Acción</th>
                        </tr>
                      </thead>
                      <tbody>
                        {_Item.details.map((Item, key) => {
                          return (
                            <tr key={key}>
                              <td>
                                <p>{Item.code}</p>
                                { Item.serials?.length > 0 && <p className="view-serials" onClick={ () => {
                                  this.setState({
                                    view_serials: true,
                                    item_serials: Item,
                                    serials: Item.serials
                                  });
                                } }>
                                  Ver Seriales
                                </p> }
                              </td>
                              <td>{Item.name}</td>
                              <td>{Item.serials?.length > 0 && Item.serials?.map(x => x.serial).join(', ')}</td>
                              <td>{Item.pivot.quantity}</td>
                              <td>{Globals.formatMiles(Amount(Item))}</td>
                              <td>{Globals.formatMiles(Item.pivot.subtotal)}</td>
                              <td></td>
                            </tr>
                          );
                        })}

                        {/* <tr>
                          <td colSpan="3"></td>
                          <th>Subtotal/Cantidad</th>
                          <td>{getQuantity(Item)}</td>
                          <td>{Globals.formatMiles(getAmount(Item))}</td>
                        </tr>

                        <tr>
                          <td colSpan="4"></td>
                          <th>Exento</th>
                          <td>{Globals.formatMiles(Item.exempt)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4"></td>
                          <th>Base Imponible</th>
                          <td>{Globals.formatMiles(Item.taxable)}</td>
                        </tr>
                        <tr>
                          <td colSpan="4"></td>
                          <th>IVA</th>
                          <td>{Globals.formatMiles(Item.vat)}</td>
                        </tr> */}
                        <tr className="total-row">
                          <td style={{border: 'none'}} colSpan="3"></td>
                          <th style={{border: 'none', backgroundColor: '#f6fafd'}}>Total</th>
                          <td style={{border: 'none', backgroundColor: '#f6fafd'}}><b>{Globals.formatMiles(Item.total)}</b></td>
                          <td style={{border: 'none', backgroundColor: '#f6fafd'}}></td>
                        </tr>
                      </tbody>
                    </table>

                    <div className="payment-method-title">Formas de Pago</div>
                    <div className="payment-method-subtitle">Total nota de entrega</div>
                    <div className="payment-method-price">${Globals.formatMiles(Item.total)}</div>

                    <div className="payment-methods">
                      <div className="payment-method">
                        <div className="payment-method-left">
                          <input
                            id="payment-cash"
                            type="checkbox"
                            checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) || this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                          />
                          <div>
                            <img src={CashPayment} alt="CashPayment" />
                          </div>

                          <div>
                            <div>
                              <label htmlFor="payment-cash">Efectivo</label>
                            </div>
                            <div className="extra-options">
                              <div className="extra-option">
                                <input
                                  id="payment-method-bs"
                                  type="checkbox"
                                  value={PAYMENT_METHODS.CASH_BS}
                                  checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_BS)}
                                  onClick={() => {
                                    this.toggleInput(PAYMENT_METHODS.CASH_BS)
                                  }}
                                />
                                <label htmlFor="payment-method-bs">Bs</label>
                              </div>
                              <div className="extra-option">
                                <input
                                  id="payment-method-divisas"
                                  type="checkbox"
                                  value={PAYMENT_METHODS.CASH_FE}
                                  checked={this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                                  onClick={() => this.toggleInput(PAYMENT_METHODS.CASH_FE)}
                                />
                                <label htmlFor="payment-method-divisas">Divisas</label>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="payment-method-right">
                          {(!this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && !this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)) && (
                            <Input
                              color=" "
                              type="number"
                              name="payment"
                              placeholder="Monto"
                              className="material"
                              labelClass="material"
                              disabled
                            />
                          )}
                          {this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && (
                            <Input
                              color=" "
                              type="number"
                              name={`payment-${PAYMENT_METHODS.CASH_BS}`}
                              placeholder={
                                (this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && this.isSelectedPayment(PAYMENT_METHODS.CASH_FE))
                                  ? 'Monto Bs'
                                  : 'Monto'
                              }
                              className="material"
                              labelClass="material"
                              disabled={!this.isSelectedPayment(PAYMENT_METHODS.CASH_BS)}
                              value={this.getAmount(PAYMENT_METHODS.CASH_BS)}
                              onChange={v => this.onChangeAmount(v, PAYMENT_METHODS.CASH_BS)}
                            />
                          )}
                          {this.isSelectedPayment(PAYMENT_METHODS.CASH_FE) && (
                            <Input
                              color=" "
                              type="number"
                              name={`payment-${PAYMENT_METHODS.CASH_FE}`}
                              placeholder={
                                (this.isSelectedPayment(PAYMENT_METHODS.CASH_BS) && this.isSelectedPayment(PAYMENT_METHODS.CASH_FE))
                                  ? 'Monto Divisas'
                                  : 'Monto'
                              }
                              className="material"
                              labelClass="material"
                              disabled={!this.isSelectedPayment(PAYMENT_METHODS.CASH_FE)}
                              value={this.getAmount(PAYMENT_METHODS.CASH_FE)}
                              onChange={v => this.onChangeAmount(v, PAYMENT_METHODS.CASH_FE)}
                            />
                          )}
                        </div>
                      </div>

                      <PaymentItem
                        target={PAYMENT_METHODS.BINANCE}
                        label="Binance"
                        icon={Binance}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.TRANSFER}
                        label="Transferencia Bs."
                        icon={TransferPayment}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.ZELLE}
                        label="Zelle"
                        icon={Zelle}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.MOBILE}
                        label="Pago Móvil"
                        icon={MobilePayment}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.BANESCO}
                        label="Banesco Panamá"
                        icon={Banesco}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.PAYPAL}
                        label="Paypal"
                        icon={Paypal}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />

                      <PaymentItem
                        target={PAYMENT_METHODS.POS}
                        label="Tarjeta de débito"
                        icon={DebitPayment}
                        isSelectedPayment={this.isSelectedPayment}
                        getAmount={this.getAmount}
                        toggleInput={this.toggleInput}
                        onChangeAmount={this.onChangeAmount}
                      />
                    </div>

                    <div className="payment-method-subtitle">Saldo</div>
                    <div className="payment-method-price">
                      <span className="purple">
                        ${Globals.formatMiles(parseFloat(this.getBalance().toFixed(2)))}
                      </span>
                      <span className="purple" style={{ marginLeft: '4rem' }}>
                        Bs. {Globals.formatMiles(parseFloat((this.getBalance() * exchangeRate).toFixed(2)))}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {Item.observations && (
              <div className="row text-center">
                <div className="col-md">
                  <div className="form-group">
                    <Textarea
                      color="gray"
                      value={Item.observations}
                      name="observations"
                      label="OBSERVACIONES"
                      readOnly
                    />
                  </div>
                </div>
              </div>
            )}

          {Item.status === TYPE_LIST.PENDIENTE && (
            <div id="button" className="d-flex justify-content-center mt-3">
              <Button
                type="button"
                color=" "
                className="primary"
                onClick={() => this.generateNote()}
              >
                <span>Generar nota de entrega</span>
              </Button>
            </div>
          )}
        </React.Fragment> ) }
      </React.Fragment>
    );
  }
}

const PaymentItem = ({ target, icon, label, isSelectedPayment, getAmount, toggleInput, onChangeAmount }) => (
  <div className="payment-method">
    <div className="payment-method-left">
      <input
        id={`payment-${target}`}
        type="checkbox"
        value={target}
        checked={isSelectedPayment(target)}
        onClick={() => toggleInput(target)}
      />
      <div>
        <img src={icon} alt={label} />
      </div>
      <label htmlFor={`payment-${target}`}>{label}</label>
    </div>

    <div className="payment-method-right">
      <Input
        color=" "
        type="number"
        name={`payment-${target}`}
        placeholder="Monto"
        className="material"
        labelClass="material"
        disabled={!isSelectedPayment(target)}
        value={getAmount(target)}
        onChange={v => onChangeAmount(v, target)}
      />
    </div>
  </div>
)

function getQuantity({ details: Products }) {
  return Products.reduce(function(Quantity, Item) {
    return parseInt(Item.pivot.quantity + Quantity);
  }, 0);
}

function getAmount({ details: Products }) {
  return Products.reduce(function(Amount, Item) {
    return parseFloat(Item.pivot.subtotal + Amount);
  }, 0);
}

function Amount({ pivot: Amount }) {
  return parseFloat(Amount.subtotal / Amount.quantity);
}

function parseFormat(Date) {
  return moment(Date).format("DD/MM/YYYY");
}

function timeFormat(Time) {
  return moment(Time,'YYYY-MM-DD HH:mm:ss').format("HH:mm:ss");
}

export default connect(state => {
  return {
    user: state.user
  };
})(View);
