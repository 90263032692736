import React from "react";
import { connect } from "react-redux";

import { Pagination, Table } from "../../components";
import { Globals, Format, Constants } from "../../utils";
import { Movement, Branch } from "../../services";

// Components
import Menu from "../menu";
import Filter from "./filterForm";

const TABLE_HEADER = [
  "Sucursal",
  "Almacen",
  "Fecha y Hora",
  "Producto",
  "Tipo",
  "Movimiento",
  "Estatus",
  "Cantidad Existente",
  "Cantidad Procesada",
  "Stock Actual",
  "Usuario",
  "Observaciones"
];

class Movements extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      branches: [],
      page: 1,
      current_page: 1,
      last_page: 1,
      form: {}
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    let { user } = this.props;
    const { page } = this.state;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Promise.all([
      Movement.getMovements(page, {
        Id: EnterpriseId,
        user_id: user.id,
        warehouse_id: warehouse_id,
        role: user.role
      }),
      Branch.getBranches({ role: user.role, enterprise_id: EnterpriseId })
    ])
      .then(response => {
        const { current_page, data: Items, last_page } = response[0];
        const { branches } = response[1];
        let branches_filtered = branches.data.filter(function(branch) {
          return !branch.deleted_at;
        });
        branches_filtered = Format.rawBranches(
          branches_filtered,
          user.assigned_warehouses,
          user.role
        );

        const dataMap = Format.rawMovement(Items);

        this.setState({
          data: dataMap,
          branches: branches_filtered,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError());
  };

  loadMore = () => {
    const { user } = this.props;
    const { page, form } = this.state;

    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Movement.getMovements(page, {
      Id: EnterpriseId,
      user_id: user.id,
      warehouse_id: warehouse_id,
      role: user.role,
      ...form
    })
      .then(response => {
        const { current_page, data: Items, last_page } = response;

        const dataMap = Format.rawMovement(Items);

        this.setState({
          data: dataMap,
          current_page: current_page,
          last_page: last_page
        });
      })
      .catch(() => Globals.showError());
  };

  filterMap = form => {
    const { user } = this.props;

    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;
    const warehouse_id = Globals.getAssignedWarehouses(user);

    Movement.getMovements(1, {
      Id: EnterpriseId,
      user_id: user.id,
      warehouse_id: warehouse_id,
      role: user.role,
      ...form
    })
      .then(response => {
        const { current_page, data: Items, last_page } = response;

        const dataMap = Format.rawMovement(Items);

        this.setState({
          page: 1,
          data: dataMap,
          current_page: current_page,
          last_page: last_page,
          form: {
            ...form
          }
        });
      })
      .catch(() => Globals.showError());
  };

  actionMovement = Item => {

    const { description } = Item;
    const devolucion= '';
    const dev=0;

    if (description != null) {
      const devolucion = description.substring(description.length,description.length-10,2);
      if (devolucion == 'devolución') {
        const dev=1;
      }
    }
    if(dev > 0){
      return "Devolución";
    }else{
      return Item.action_class;
    }
  };

  render() {
    const { data, branches } = this.state;
    const { user } = this.props;
    const EnterpriseId = (user.enterprise_users || {}).enterprise_id || user.id;

    return (
      <Menu history={this.props.history}>
        <div id="home">
          <div className="container-fluid">
            <Filter
              submitted={form => this.filterMap(form)}
              user={user}
              Id={EnterpriseId}
              branches={
                branches
                  ? branches.map(Item => {
                      return {
                        value: Item.id,
                        label: Item.name
                      };
                    })
                  : []
              }
            />

            <div className="row">
              <div className="col-md">
                <Table
                  data={this.state.data.length}
                  title="Reporte de Movimientos"
                  header={TABLE_HEADER}
                >
                  {data.map((Item, key) => {
                    return (
                      <tr key={key}>
                        <td>
                          {Item.product_warehouse.warehouse.branch.name}{" "}
                          {Item.product_warehouse.warehouse.branch.deleted_at
                            ? "(Eliminado)"
                            : ""}
                        </td>
                        <td>
                          {Item.product_warehouse.warehouse.name}{" "}
                          {Item.product_warehouse.warehouse.deleted_at
                            ? "(Eliminado)"
                            : ""}
                        </td>
                        <td>{Item.created_at}</td>
                        <td>{Item.product.name}</td>
                        <td>
                          {Item.type === Constants.TYPE_ENTRY
                            ? "Entrada al inventario"
                            : "Salida del inventario"}
                        </td>
                        <td>{this.actionMovement(Item)}</td>
                        <td
                          className={
                            Item.status === Constants.STATUS_INV_COMMITTED
                              ? "text-danger"
                              : "text-success"
                          }
                        >
                          {Item.status === Constants.STATUS_INV_COMMITTED
                            ? "Comprometido"
                            : "Procesado"}
                        </td>
                        <td>
                          {Item.current_stock}
                        </td>
                        <td>
                          {Item.type === Constants.TYPE_ENTRY
                            ? Item.amount
                            : "-"+Item.amount
                          }</td>
                        <td>
                          {Item.stock}</td>
                        <td>
                          {Item.creator.person.first_name}{" "}
                          {Item.creator.deleted_at ? "(Eliminado)" : ""}
                        </td>
                        <td>
                          {Item.description
                            ? Item.description
                            : Item.action && Item.action.observations
                            ? Item.action.observations
                            : "Sin descripción"}
                        </td>
                      </tr>
                    );
                  })}
                </Table>
              </div>
            </div>

            <div className="row my-3">
              <div className="col-md">
                <Pagination
                  pages={this.state.last_page}
                  active={this.state.page}
                  onChange={page => {
                    this.setState(
                      {
                        page: page
                      },
                      () => this.loadMore()
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Movements);
