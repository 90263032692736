import moment from "moment";
import React from "react";
import { Button, Input } from "../../components";
import { ExchangeServices } from "../../services";
import { Globals } from "../../utils";

class CreateEditBrand extends React.Component {
  state = {
    form: {},
    editing: false,
    textButton: "Crear"
  };

  componentDidMount() {
    if (this.props.editing?.id) this.edit();
  }

  submit = async () => {
    Globals.setLoading();
    ExchangeServices.SetExchange(this.state.form)
      .then(res => {
        if (!res?.result) throw res;
        this.setState({ form: {} });
        Globals.showSuccess(`Tasa ${this.state.editing ? 'editada' : 'creada'} con éxito`);
        this.props.onClose();
      })
      .catch(err => Globals.showError())
      .then(() => Globals.quitLoading());
  };

  edit = async () => {
    await this.setState({
      form: {
        exchange_id: this.props.editing.id,
        date: this.props.editing.updated_at,
        conversion_rate: this.props.editing.conversion_rate,
      },
      editing: true,
      textButton: "Editar"
    });
  };

  onChange = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  };

  render() {
    return (
      <div className="container-create-edit-user">
        <div className="row">
          <div className="col-md-12">
            <Input
              color=" "
              value={this.state.form.conversion_rate}
              name="conversion_rate"
              label="Tasa"
              type="number"
              className="material"
              labelClass="material"
              onChange={v => this.onChange(v.target.value, 'conversion_rate')}
            />
          </div>
          <div className="col-md-12">
            <Input
              color=" "
              value={moment(this.state.form.date).format('DD/MM/YYYY')}
              name="date"
              label="Fecha"
              className="material"
              labelClass="material"
              disabled={true}
            />
          </div>
        </div>
        <div id="button" className="d-flex justify-content-center">
          <Button
            type="button"
            color=" "
            className="primary"
            onClick={() => this.submit()}
          >
            <span>{this.state.textButton}</span>
          </Button>
        </div>
      </div>
    );
  }
}

export default CreateEditBrand;
