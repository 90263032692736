import React from 'react';
import { connect } from 'react-redux';
import { Constants, Globals, axios } from '../../utils';
import { Input, Select, Button } from '../../components';
import { AccountReceivableService } from '../../services';

class CreatePayment extends React.Component {

	state = {
		form: {
			amount: '',
			method_id: '',
			comments: '',
			user_id: this.props.user.id,
		},
		paymentMethods: [],
		height: 0,
		client: this.props.client,
		type: this.props.type,
	}

	change = (value,target) => {
		this.setState({
			form: {
				...this.state.form,
				[target]: value
			}
		});
	}

  choosePhoto = (e) => {
		if (!e.target.files.length) return;
    this.setState({
      form: {
        ...this.state.form,
        photo: e.target.files[0],
      }
    });
  }

	submit = async () => {
		const pendingAmount = this.props.order?.payments
			?.filter(x => x.admin_confirmation === Constants.PAYMENT_ADMIN_CONFIRM.PENDING)
			?.map(x => x.amount)
			?.reduce((acc, curr) => acc + curr, 0) || 0;

		const amount = parseFloat(parseFloat(this.state.form.amount).toFixed(2));
		// Verificar si amount es NaN
		if (isNaN(amount)) {
			Globals.showError("Por favor, ingrese un monto válido.");
			return false;
		}
		
		const to_pay = parseFloat(parseFloat(this.props.order?.to_pay - pendingAmount).toFixed(2));
		const amount_payment = parseFloat(parseFloat(this.props.order?.amount_payment).toFixed(2));

		if (amount < amount_payment && to_pay > amount_payment) {
			Globals.showError("Lo sentimos, el monto mínimo del pago es " + Globals.formatMiles(this.props.order?.amount_payment,true));
			return false;
		}

		if (to_pay <= 0) {
			Globals.showError("Lo sentimos, hay abonos pendientes por aprobación");
			return false;
		}

		if (amount > to_pay) {
			Globals.showError("Lo sentimos, el monto del pago no puede ser mayor a " + Globals.formatMiles(to_pay,true));
			return false;
		}

		if(this.state.form.method_id == '' || this.state.form.method_id == null){
			Globals.showError("Lo sentimos, el método de pago es obligatorio");
			return false;
		}

		if(this.state.type === Constants.PAYMENT_INDIVIDUAL){
			//Checkeo si el ultm pago a la cuenta es igual
			const res = await axios.post('admin/accounts-receivable/getlastamount', {
				order_id: this.props.order?.id,
				...this.state.form
			});
			if(res.data.last_payment){
				const last_payment = parseFloat(res.data.last_payment.amount).toFixed(2);
				if(last_payment == amount){
					Globals.confirm(`Acabas de ingresar un monto igual al que intentas registrar, ¿estas seguro de continuar?`, () => {
						AccountReceivableService.setPayment({
							order_id: this.props.order?.id,
							...this.state.form
						});
						Globals.showSuccess("Se ha registrado el pago correctamente");
						this.props.onClose(true);
						return;
					});
				}
				else{
					AccountReceivableService.setPayment({
						order_id: this.props.order?.id,
						...this.state.form
					});
					Globals.showSuccess("Se ha registrado el pago correctamente");
					this.props.onClose(true);
				}
			}
			else{
				await AccountReceivableService.setPayment({
					order_id: this.props.order?.id,
					...this.state.form
				});
				Globals.showSuccess("Se ha registrado el pago correctamente");
				this.props.onClose(true);
			}
		}
		else if(this.state.type === Constants.PAYMENT_MULTIPLE){
			//Valido que el monto digitado, no sea mayor al monto total a pagar
			const total_to_pay = this.state.client?.orders?.map(x => x.to_pay).reduce((acc, curr) => acc + curr, 0);
			const amountRounded = Math.round(amount * 100) / 100; // Redondea a dos decimales
			const total_to_payRounded = Math.round(total_to_pay * 100) / 100; // Redondea a dos decimales

			if(amountRounded > total_to_payRounded){
				Globals.showError("Lo sentimos, el monto del pago no puede ser mayor a " + Globals.formatMiles(total_to_payRounded,true));
				return false;
			}

			await AccountReceivableService.setPaymentMultiple({
				client_id: this.props.client?.id,
				...this.state.form
			});
			Globals.showSuccess("Se ha registrado el pago correctamente");
			this.props.onClose(true);
		}
	}

	render() {
		const total = this.state.client?.orders?.map(x => x.to_pay).reduce((acc, curr) => acc + curr, 0);

		return (
			<div>
				<div className="row">
					{this.state.type === Constants.PAYMENT_MULTIPLE && (
						<div style={{ color: 'red', marginBottom: '5px', fontSize: '18px' }} className="col-md-12">
							<strong>Monto deuda: ${Globals.formatMiles(total, true)} </strong>
						</div>
					)}
					<div className="col-md-12">
						<Input
							color=" "
							type="number"
							label="Monto del Pago"
							className="material"
							labelClass="material"
							value={this.state.form.amount}
							onChange={e => {
								if (Globals.validateDouble(e.target.value) || e.target.value === '') {
									this.change(e.target.value,'amount');
								}
							}}
						/>
					</div>
					<div className="col-md-12">
						<Select
							label="Método de Pago"
							className="material"
							labelClass="material"
							value={this.state.form.method_id}
							options={ this.props.paymentMethods.map(i => ({
								value: i.value,
								label: i.label
							}))}
							onChange={e => this.change(e.target.value, 'method_id')}
						/>
					</div>
					<div className="col-md-12" onClick={() => document.getElementById('photo').click()}>
						<Input
							color=" "
							label="Comprobante de Pago (Opcional)"
							className="material"
							labelClass="material"
							readonly
							value={this.state.form.photo?.name}
						/>
						<input
							id="photo"
							type="file"
							name="photo"
							className="form-control material"
							hidden
							accept="image/*,.pdf"
							onChange={this.choosePhoto}
						/>
					</div>
					<div className="col-md-12">
						<Input
							color=" "
							label="Información Adicional (Opcional)"
							className="material"
							labelClass="material"
							value={this.state.form.comments}
							onChange={e => this.change(e.target.value,'comments')}
						/>
					</div>
				</div>
				<div style={ { display: 'flex', flexDirection: 'row', gap: '1rem', marginTop: '1rem' } }>
					<div style={ { flex: 1 } }>
						<Button outline block onClick={ () => this.props.onClose() }>
							<span style={{ color: 'gray' }}>Cerrar</span>
						</Button>
					</div>
					<div style={ { flex: 1 } }>
						<Button color="primary" block onClick={ this.submit }>
							<span>Aceptar</span>
						</Button>
					</div>
				</div>
			</div>
		)
	}
}

export default connect((state) => {
	return {
		user: state.user
	}
})(CreatePayment);