import axios from "../../utils/axios";

class Sale {
  static async getId(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sales/generateId", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createSale(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sales/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getSaleType(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/sales/get-sale-type", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Sale;
