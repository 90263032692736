import React from "react";
import { Avatar, List } from "../../components";
import { ENV, Colors, Constants, Globals } from "../../utils";
import User from "../../assets/img/user.jpg";

class ViewUser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      user: props.user
    };
  }

  getStatus = (status = this.state.user.status) => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nuevo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  render() {
    const { user } = this.state;
    console.log(user)
    let rol;
    // const enterprise_role = user.ROLE_SELLER_subuser ? true : false;
    // if (!enterprise_role) {
    //   rol = <List.Item label="Rol">{(user.roles[0]['name'] === 'sub_user') ? 'No posee rol asignado' : user.roles[0]['display_name']}</List.Item>;
    // } else {
    //   rol = (
    //     <List.Item label="Rol">
    //       {user.ROLE_SELLER_subuser.ROLE_SELLER.name}
    //     </List.Item>
    //   );
    // }

    rol = (
      <List.Item label="Rol">
        {Globals.getLevel(user.level_id)}
      </List.Item>
    );

    return (
      <div className="text-center container-view-user">
        {/* <Avatar
          source={
            user.person.avatar ? ENV.BasePublic + user.person.avatar : User
          }
          size="130px"
        /> */}
        <List.Container>
          <List.Item label="Nombre de usuario">{user.name}</List.Item>
          <List.Item label="Nombre/Razon Social">
            {user.name}
          </List.Item>
          {rol}
          {/* {user.roles[0]['display_name'] == 'Caja' ? 
            <List.Item label="Código de caja">{user.person.code}</List.Item>
            : ''
          } */}
          <List.Item label="Email">{user.email}</List.Item>
          <List.Item label="Cédula de identidad">
            {user.document}
          </List.Item>
          <List.Item label="Teléfono">{user.phone}</List.Item>
          <List.Item label="Estatus">
            <span
              style={{
                color: this.getStatus().color
              }}
            >
              {this.getStatus().text}
            </span>
          </List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewUser;
