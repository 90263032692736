import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input,
  DatePicker,
} from "../../components";
import { axios, Globals, Colors, Constants, ENV } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import CreateEditQuote from "./create_edit_change";
import ViewQuote from "./view_change";
import AddIcon from '../../assets/icons/add.png';
import globals from "../../utils/globals";
// import socket from '../../utils/socket';
import PrinterIcon from '../../assets/icons/printer.png';

const INITIAL_STATE = {
  since: '',
  until: '',
  product_id: '',
  category_id: '',
  brand_id: '',
  model_id: '',
  user_id: '',
  web: true,
  movement_type: '',
  showDeletedProducts: true,
  status: null,
  search: '',
  role: '',
  admin_id: '',
}

class InventoryChanges extends React.Component {
  constructor(props) {
    super(props);

    INITIAL_STATE.user_id = this.props.user.id;
    INITIAL_STATE.admin_id = this.props.user.admin_id || this.props.user.id;
    INITIAL_STATE.role = this.props.user.role;

    this.state = {
      header: [
        "Fecha",
        "Usuario",
        "Producto",
        "N° Documento Referencia",
        "Almacén",
        "Tipo de Movimiento",
        "Cantidad",
        "Existencia anterior",
        "Existencia actual",
      ],
      data: [],
      products: [],
      movement_types: [
        {id: 1.8, label: "Modificación de stock"},
        //{id: 8, label: "Modificación de Stock"},
        {id: 9.5, label: "Edición de pedido"},
        //{id: 5, label: "Devolución por edición de pedido"},
        {id: 2, label: "Venta"},
        {id: 3, label: "Pedido rechazado"},
        {id: 6, label: "Pedido Cancelado"},
        {id: 4, label: "Orden de compra"},
        {id: 7, label: "Devolución de Pedido"},
        {id: 11, label: "Transferencia de Almacén"},
      ],
      page: 1,
      last_page: 1,
      loading: false,
      visible: false,
      visible_products: false,
      product: null,
      form: { ...INITIAL_STATE },
      dates: {
        minDate: "",
        maxDate: ""
      },
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.load();
    // this.getProducts();

    const options_movements = this.state.movement_types.map(({id, label}) => ({
      value: id,
      label: label
    }));
    this.setState({
      movement_types: options_movements
    });
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
  
  load = () => {
    Globals.setLoading();
    let param = this.state.form;
    param.user_id = this.props.user.id;
    axios
      .post("admin/reports/movements?page=" + this.state.page + '&minimize=true', param)
      .then(res => {
        if (res.data.movements) {
          this.setState({
            last_page: res.data.movements.last_page,
            data: res.data.movements.data,
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };
  
  getProducts = (page = 1) => {
    const filter = {
      web: true,
      admin_id: this.props.user.admin_id || this.props.user.id,
    }
    axios
      .post(`web/admin/quotes/products?page=${page}`, filter)
      .then(res => {
        if (res.data.products.data) {
          this.setState(state => ({
            products: [
              ...state.products,
              ...res.data.products.data.map(x => ({ value: x.id, label: x.name })),
            ]
          }));
          if (res.data.products.data?.length === 30) this.getProducts(page + 1);
        }
      })
      .catch(err => {
        Globals.showError();
      });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Inactivo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = () => {
    this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });

    this.load();
  };

  inactiveUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea inactivar a: ${name}?`, () => {
      Users.setStatusInactive({  id: Item.id })
        .then(response => {
          // socket.emit('disable-user',{id: item.id});
          this.load();
          Globals.showSuccess("¡cotización inactivado exitosamente!");
        })
        .catch(error => {
          Globals.showError(error);
        });
    });
  };

  activateUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea activar a: ${name}?`, () => {
      Users.setStatusActive({ Id: Item.id })
        .then(response => {
          //socket.emit('activate-user', { id: item.id });
          this.load();
          Globals.showSuccess("¡cotización activado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  verifyUser = Item => {
    const { person } = Item;
    const name = person.first_name;

    Globals.confirm(`¿Desea verificar a: ${name}?`, () => {
      Users.setStatusVerify({ Id: Item.id })
        .then(response => {
          this.load();
          Globals.showSuccess("¡cotización verificado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar a: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`web/admin/sellers/delete/${item.id}`, { id: item.id })
        .then(res => {
          Globals.showSuccess('cotización eliminado');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar al cotización multiempresa: " + item.person.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/users/get-user-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.user
            });
            Sidebar.getModules(res.data.user.role).then(response => {
              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: response
              });
              this.props.history.push("/enterprises");
            });
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  print = (id) => {
    let param = this.state.form;
    param.quote_id = id
    param.web = true
    axios
      .post("admin/reports/movements/download", param)
      .then(res => {
        if(res.data.url){
          //const url = res.data.url;
          const url = `${res.data.url}`;
          const win = window.open(url, "_blank");
          win.focus();
        }
        else{
          Globals.showError('Ha ocurrido un error');
        }
      })
      .catch(err => {
        Globals.showError();
      });
    
  };

  returnState = async () => {
    await this.setState(state => ({
      ...state,
      form: {
        ...INITIAL_STATE
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
    }))
    await this.load();
  };

  render() {
    const {history} = this.props
    const { create, data, edit, header, search, status, user, view } = this.state
    const titleTable = 'Reporte de Movimientos de Inventario';
    const userTypeText = 'cotización';
    return (
      <Menu history={history}>
        {view && (
          <Modal title={`Ver ${userTypeText}`} onClose={this.close} visible>
            <ViewQuote user={user} />
          </Modal>
        )}

        {create && (
          <Modal
            title={edit ? `Editar ${userTypeText}` : `Nueva ${userTypeText}`}
            onClose={this.close}
            visible
          >
            <CreateEditQuote 
              edit={edit}
              onClose={this.close} 
              user={this.props.user}
            />
          </Modal>
        )}

        <div id="home" className="new-reports">
          <div className="row">
            {/* <div className="col-md-3">
              <Select
                color="white"
                label="Productos"
                className="filter"
                disabledFirst={false}
                name="product_id"
                options={this.state.products}
                value={this.state.form.product_id}
                onChange={async (e) => {
                  const id = e.target.value;
                  await this.setState(state => ({ form: { ...state.form, product_id: id } }));
                  this.load();
                }}
              />
            </div> */}
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Desde"
                className="filter"
                minDate={this.state.dates.minDate}
                value={this.state.form.since}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      since: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <DatePicker
                color="white"
                label="Hasta"
                className="filter"
                maxDate={this.state.dates.maxDate}
                value={this.state.form.until}
                onChange={value => {
                  this.setState(state => ({
                    form: {
                      ...state.form,
                      until: value
                    }
                  }));
                }}
              />
            </div>
            <div className="col-md-2">
              <Input
                color=" "
                name="product_id"
                label="Producto"
                className="filter"
                onChange={async (e) => {
                  const id = e.target.value;
                  await this.setState(state => ({ form: { ...state.form, search: id } }));
                  //this.load();
                }}
                value={this.state.form.search}
                placeholder="Nombre o Código"
                type="text"
              />
            </div>
            <div className="col-md-3">
              <Select
                color="white"
                label="Tipo de Movimiento"
                className="filter"
                disabledFirst={false}
                name="movement_type"
                options={this.state.movement_types}
                value={this.state.form.movement_type}
                onChange={async (e) => {
                  const id = e.target.value;
                  await this.setState(state => ({ form: { ...state.form, movement_type: id } }));
                }}
              />
            </div>
            <div className="col-md-3">
              <div className="container-buttons">
                <Button
                  className="btn-align-bottom btn-filter blue"
                  color=" "
                  style={{ minWidth: 110 }}
                  onClick={async () => {
                    await this.setState({ page: 1 });
                    this.load();
                  }}
                >
                  <span>Filtrar</span>
                </Button>
                <Button
                  className="btn-align-bottom btn-filter"
                  color=" "
                  style={{ minWidth: 110 }}
                  onClick={this.returnState}
                >
                  <span>Limpiar</span>
                </Button>
              </div>
            </div>
          </div>
          <Table
            data={data.length}
            title={titleTable}
            header={header}
            right={
              <Button
                title="Imprimir"
                small="true"
                onClick={() => this.print(this.state.data)}
              >
                <img src={PrinterIcon} style={{ width: 24 }} alt="Imprimir" />
              </Button>
            }
          >
            {data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>{ moment(Item.created_at).format('DD/MM/YYYY') }</td>
                  <td> { Item?.user?.name } </td>
                  <td> {Item.product_id && Item.product ? Item.product.code+' '+Item.product.name : (Item.size_color_id && Item.size_color && Item.size_color.product ? Item.size_color.product.code+' '+Item.size_color.product.name : '')} </td>
                  <td style={{ maxWidth: 'none' }}> { Item?.order ? 'Nota de entrega: '+Item?.order?.number_format : Item.purchase ? 'Orden de compra: '+Item.purchase?.number_format : '' } </td>
                  <td> { Item?.warehouse?.name } </td>
                  <td style={{ maxWidth: '300px', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', wordWrap: 'break-word' }}>
                    { Item?.type?.name }
                  </td>
                  <td> { Item?.movement_amount } </td>
                  <td> { Item?.current_amount } </td>
                  <td> { Item?.final_amount } </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => this.load()
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(InventoryChanges);
