import React from "react";
import { List } from "../../components";
import { Globals } from "../../utils";

class ViewBrand extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      branch: props.branch
    };
  }

  render() {
    const { branch } = this.state;

    return (
      <div className="text-center container-view-user">
        <List.Container>
          <List.Item label="Nombre">{branch.name}</List.Item>
          <List.Item label="Código">{branch.code}</List.Item>
          <List.Item label="Estatus">
            { !branch.deleted_at && (
              <span
                style={{
                  color: Globals.getStatus(branch.status).color
                }}
              >
                {Globals.getStatus(branch.status).text}
              </span>
            )}
            { branch.deleted_at && (
              <span
                style={{
                  color: Globals.getStatus(0).color
                }}
              >
                Eliminado
              </span>
            )}
          </List.Item>
        </List.Container>
      </div>
    );
  }
}

export default ViewBrand;
