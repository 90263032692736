import axios from "../../utils/axios";

class Client {
  static async create(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getZones() {
    return new Promise((resolve, reject) => {
      axios.get("admin/clients/zones").then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async ClientFromRequest(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/create/request", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async ClientSearchByName(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/clients/search-name", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Client;
