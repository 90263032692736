import React from "react";
import { Select, DatePicker, Button } from "../../components";

const EXPRESS_INGRESS_TYPE_LIST = [
    { value: 0, label: "Todos" },
    { value: 1, label: "Contado" },
    { value: 2, label: "Credito" }
];

const EXPRESS_INGRESS_STATUS_LIST = [
    { value: 0, label: "Sin pagar" },
    { value: 1, label: "Pagado" }
];

class Filter extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            form: {
                payment_type: 0,
                status: "",
                since: "",
                until: "",
                provider: 0
            },
            dates: {
                minDate: "",
                maxDate: ""
            }
        };
    }

    handleChange = (emitter, string) => {
        const { name, value } = emitter.target;
    
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: value
          }
        }));
    };

    render() {
        let { providers } = this.props;
        const { form } = this.state;
    
        return (
          <form
            onSubmit={emitter => {
              emitter.preventDefault();
              this.props.submitted(form);
            }}
          >
            <div className="row">
              <div className="col-md">
                <DatePicker
                  color="white"
                  label="Desde"
                  minDate={this.state.dates.minDate}
                  value={this.state.form.since}
                  onChange={value => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        since: value
                      }
                    }));
                  }}
                />
              </div>
              <div className="col-md">
                <DatePicker
                  color="white"
                  label="Hasta"
                  maxDate={this.state.dates.maxDate}
                  value={this.state.form.until}
                  onChange={value => {
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        until: value
                      }
                    }));
                  }}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="provider"
                  label="Proveedores"
                  style={{ textTransform: "capitalize" }}
                  defaultname="Seleccione"
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={this.state.form.provider}
                  options={[...providers, { value: 0, label: "Todos" }].reverse()}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="payment_type"
                  label="Tipo de Pago"
                  style={{ textTransform: "capitalize" }}
                  defaultname="Seleccione"
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={this.state.form.payment_type}
                  options={EXPRESS_INGRESS_TYPE_LIST}
                />
              </div>
              <div className="col-md">
                <Select
                  color="white"
                  name="status"
                  label="Estatus"
                  style={{ textTransform: "capitalize" }}
                  defaultname="Todos"
                  disabledFirst={"False"}
                  defaultValue=""
                  onChange={emitter => this.handleChange(emitter, null)}
                  value={this.state.form.status}
                  options={EXPRESS_INGRESS_STATUS_LIST}
                />
              </div>
              <div className="col-md">
                <div className="form-group">
                  <Button
                    className="btn-block btn-align-bottom"
                    onClick={this.load}
                  >
                    Filtrar
                  </Button>
                </div>
              </div>
            </div>
          </form>
        );
      }
}

export default Filter;