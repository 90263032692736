import React from 'react';
import { List } from '../../components';
import { Colors } from '../../utils';
// import { Avatar, Icon, List } from '../../../components';
// import { ENV, Colors } from '../../../utils';
// import User from '../../../assets/img/user.jpg';

class ViewModule extends React.Component {
	state = {
		modules: this.props.modules
	};

	render() {
		let renderModules
		if(this.state.modules.length > 0) {
			renderModules = 
			this.state.modules.map((value, index) => {
				return (
					<List.Item key={value.id} label="Nombre">
						{value.name}
					</List.Item>
				)
			})
		} else {
			renderModules = 
			<List.Item key="none">
				No posee ningun modulo asignado
			</List.Item>
		}
		return (
			<div className="text-center container-view-user">
				<List.Container>
					{renderModules}
				</List.Container>
			</div>
		);
	}
}

export default ViewModule;
