import React from "react";
import { Table, Button, Input } from "../../../components";
import { connect } from "react-redux";
import { Globals } from "../../../utils";
import { Role } from "../../../services";
import SelectCustom from "../../../components/select";

const HeaderModules = ["Modulo", "Acceso"];

class CreateEditRoleEnterprise extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        name: "",
        enterprise_id: this.props.enterprise_id,
        modules: []
      },
      submitted: false,
      errors: [],
      modules: [],
      enterprises: [],
      edit: false,
      textButton: "Crear"
    };

    this.hasErrorFor = this.hasErrorFor.bind(this);
    this.renderErrorFor = this.renderErrorFor.bind(this);
  }

  componentDidMount() {
    if (this.props.edit) this.edit();
    this.load();
  }

  load = () => {
    Globals.setLoading();

    Role.getRolesEnterprises()
      .then(response => {
        const { enterprises } = response;

        var newEnterprises = enterprises.map(enterprise => {
          return {
            value: enterprise.id,
            label: enterprise.name
          };
        });

        this.setState({
          enterprises: newEnterprises
        });
      })
      .catch(error => {
        const { data } = error;
        Globals.showError(data.msg);
      })
      .finally(() => {
        Globals.quitLoading();
      });
  };

  edit = () => {
    const { element } = this.props.edit;

    let modules = element.role_modules.map((Item, key) => {
      return Item.module_id;
    });

    this.setState({
      form: {
        name: element.name,
        id: element.id,
        enterprise_id: this.props.enterprise_id,
        modules: modules
      },
      edit: true,
      textButton: "Editar"
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  handleArrayChange = emitter => {
    const { name, value, checked } = emitter.target;

    switch (checked) {
      case true:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: [...state.form[name], parseInt(value)]
          }
        }));
        break;
      case false:
        this.setState(state => ({
          form: {
            ...state.form,
            [name]: state.form[name].filter(Item => Item !== parseInt(value))
          }
        }));
        break;

      default:
        break;
    }
  };

  handleSubmit = emitter => {
    emitter.preventDefault();

    if (this.state.submitted) {
      return;
    }

    const { form, edit } = this.state;

    this.setState({ submitted: true, errors: [] });

    switch (edit) {
      case true:
        Role.updateRoleEnterprises(form)
          .then(response => {
            this.setState(this.defaultState);
            Globals.showSuccess("¡Rol editado exitosamente!");
            this.props.onClose();
          })
          .catch(error => {
            this.setState({ submitted: false, errors: error });
          });
        break;
      case false:
        Role.createRoleEnterprises(form)
          .then(response => {
            this.setState(this.defaultState);
            Globals.showSuccess("¡Rol creado exitosamente!");
            this.props.onClose();
          })
          .catch(error => {
            this.setState({ submitted: false, errors: error });
          });
        break;
      default:
        break;
    }
  };

  hasErrorFor(field) {
    return !!this.state.errors[field];
  }

  renderErrorFor(field) {
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{this.state.errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { modules } = this.props;
    const { submitted } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <div className="text-center container-create-edit-user">
          <div className="row">
            <div className="col-md">
              <Input
                color="gray"
                value={this.state.form.name}
                name="name"
                label="Nombre del rol"
                error={`${this.hasErrorFor("name") ? "is-invalid" : ""}`}
                invalidfeedback={this.renderErrorFor("name")}
                onChange={this.handleChange}
              />
            </div>
          </div>

          {this.props.user.role !== 3 && (
            <SelectCustom
              color="gray"
              label="Empresa"
              name="enterprise_id"
              defaultname="enterprise_id"
              error={`${this.hasErrorFor("enterprise_id") ? "is-invalid" : ""}`}
              invalidfeedback={this.renderErrorFor("enterprise_id")}
              value={this.state.form.enterprise_id}
              options={this.state.enterprises}
              onChange={this.handleChange}
            />
          )}

          <Table
            data={this.state.modules}
            title="Asignar módulos de accesos"
            header={HeaderModules}
          >
            {modules.map((module, key) => {
              if(module.enterprise_only === 0){
                return (
                  <tr key={key}>
                    <td className="left-align">{module.name}</td>
                    <td className="right-align">
                      <input
                        type="checkbox"
                        title={module.id}
                        value={module.id}
                        name="modules"
                        defaultChecked={
                          this.state.form.modules.includes(module.id)
                            ? "checked"
                            : null
                        }
                        onClick={this.handleArrayChange}
                      />
                    </td>
                  </tr>
                );
              } else {
                return
              }
            })}
          </Table>

          {this.hasErrorFor("modules") && (
            <div className="row">
              <div className="col-md">
                <div className="alert alert-warning" role="alert">
                  {"Debe contener al menos 1 modulo."}
                </div>
              </div>
            </div>
          )}

          {submitted ? (
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          ) : (
            <div id="button">
              <Button block="true" disabled={submitted}>
                {this.state.textButton}
              </Button>
            </div>
          )}
        </div>
      </form>
    );
  }
}

CreateEditRoleEnterprise.defaultState = {
  form: {
    name: "",
    enterprise_id: "",
    modules: []
  }
};

export default connect(state => {
  return {
    user: state.user
  };
})(CreateEditRoleEnterprise);
