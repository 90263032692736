import axios from "../../utils/axios";

class order {
  static async getId(form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/orders/generateId`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getOrders(page, form) {
    return new Promise((resolve, reject) => {
      axios.post(`admin/orders/get?page=${page}`, form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createOrder(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/orders/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getDispatch(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/orders/dispatch", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async createReturn(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/returns/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async updateOrder(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/orders/update", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getProductsByProvider(param) {
    return new Promise((resolve, reject) => {
      axios.post("admin/orders/provider-products", param).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default order;
