import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Select,
  Icon,
  DatePicker,
  Input,
  Modal
} from "../../components";
import { axios, Constants, Globals } from "../../utils";
import LocationPinIcon from '../../assets/icons/location-pin.png';
import AddIcon from '../../assets/icons/add.png';
import moment from 'moment';
import ModalView from './modal-view';
import ModalViewRoute from './modal-route';
import ModalCreatePlanned from './modal-autosale-create-planned';

const INITIAL_FORM = {
  since: "",
  until: "",
  search: "",
  status: "",
}

class Autosale extends React.Component {

  state = {
    header: ['Vendedor','Fecha','Estatus vendedor','Zonas asignadas','Ruta','Estatus plan','Acción'],
    page: 1,
    last_page: 1,
    data: [],
    form: { ...INITIAL_FORM },
    visible: false,
    item: null,
    visible_create_planned: false,
    visible_route: false,
    clients: [],
    sellers: [],
    warehouses: [],
    zones: [],
  }

  componentDidMount() {
    this.fetchData();
    this.getSelects();
  }

  fetchData = (page = 1) => {
    let { form } = this.state;

    axios
      .post("admin/auto-sales/get?page=" + page, {
        ...form,
        user_id: this.props.user.id,
        type: Constants.AUTOSALE_TYPE.PLANNED,
        since: this.state.form.since && moment(this.state.form.since).format('YYYY-MM-DD'),
        until: this.state.form.until && moment(this.state.form.until).format('YYYY-MM-DD'),
      })
      .then(res => {
        this.setState({
          page,
          last_page: res.data.auto_sales.last_page,
          data: res.data.auto_sales.data
        });

        if (res.data.admin?.admin_functionalities?.map((i) => i.id).indexOf(Constants.ADMIN_FUNCTIONALITIES.VISIT_PLANNER) == -1) {
          this.props.history.replace('/');
        }
      })
      .catch((e) => {
        console.log(e)
        Globals.showError();
      });
  }

  getSelects = () => {
    axios
      .post("admin/auto-sales/getselects", { user_id: this.props.user.id })
      .then(({ data }) => {
        const clients = data.clients.map(x => ({ ...x, value: x.id, label: x.name }));
        const sellers = data.sellers.map(x => ({ ...x, value: x.id, label: x.name }));
        const visit_types = data.visit_types.map(x => ({ ...x, value: x.id, label: x.name }));
        const warehouses = data.warehouses.map(x => ({ ...x, value: x.id, label: x.name }));
        const zones = data.zones.map(x => ({ ...x, value: x.id, label: x.name }));
        this.setState({ clients, sellers, visit_types, warehouses, zones });
      })
      .catch(() => Globals.showError());
  }

  change = (value, target) => {
    this.setState({
      form: {
        ...this.state.form,
        [target]: value
      }
    });
  }

  cleanFilters = async () => {
    await this.setState({ form: INITIAL_FORM });
    this.fetchData();
  }

  canClearFilters = () => {
    return JSON.stringify(this.state.form) !== JSON.stringify(INITIAL_FORM);
  }

  view = (item) => {
    this.setState({ visible: true, item });
  }

  edit = (item) => {
    this.setState({ visible_create_planned: true, item });
  }

  delete = (item) => {
    Globals.confirm('¿Estás seguro de eliminar esta autoventa?', () => {
      Globals.setLoading();
      axios
        .post("admin/auto-sales/delete", { user_id: this.props.user.id, auto_sale_id: item.id })
        .then(({ data }) => {
          if (!data.result) throw new Error();
          Globals.showSuccess('Autoventa eliminada');
          this.fetchData();
        })
        .catch(() => Globals.showError())
        .finally(() => Globals.quitLoading());
    });
  }

  viewRoute = (item) => {
    this.setState({
      visible_route: true,
      item
    });
  }

  close = () => {
    this.setState({
      visible: false,
      item: null,
      visible_create_planned: false,
    });
  }

  closeRoute = () => {
    this.setState({ visible_route: false, item: null });
  }

  onCreate = () => {
    this.setState({ visible_create_planned: true });
  }

  render() {
    const { form, visible, visible_create_planned, visible_route } = this.state;

    return (
      <Menu history={this.props.history}>
        {
          visible_create_planned && (
            <Modal
              className="modal-autosale-create"
              title="Asignar Autoventa"
              onClose={this.close}
              visible
              size="lg"
            >
              <ModalCreatePlanned
                item={this.state.item}
                clients={this.state.clients}
                sellers={this.state.sellers}
                visit_types={this.state.visit_types}
                warehouses={this.state.warehouses}
                zones={this.state.zones}
                onClose={reload => {
                  this.close();
                  if (reload) this.fetchData();
                }}
              />
            </Modal>
          )
        }

        {
          visible && (
            <Modal
              className="modal-planner-view"
              title=""
              onClose={this.close}
              visible
            >
              <ModalView
                item={this.state.item}
                onClose={this.close}
                autosale={true}
              />
            </Modal>
          )
        }

        {
          visible_route && (
            <Modal
              className="modal-planner-view-route"
              title=""
              onClose={this.closeRoute}
              visible
            >
              <ModalViewRoute
                item={ this.state.item }
                onClose={this.closeRoute}
              />
            </Modal>
          )
        }

        <div id="visit-planner">
          <div className="row">
            <div className="col-2">
              <DatePicker
                color="white"
                label="Desde"
                maxDate={ this.state.form.until ? moment(this.state.form.until).toDate() : null }
                className="filter"
                labelClass="filter"
                value={this.state.form.since}
                onChange={value => this.change(value, 'since')}
              />
            </div>
            <div className="col-2">
              <DatePicker
                color="white"
                label="Hasta"
                minDate={form.since ? moment(form.since).toDate() : null}
                className="filter"
                value={form.until}
                onChange={value => this.change(value, 'until')}
              />
            </div>
            <div className="col-2">
              <Input
                color=" "
                label="Vendedor"
                placeholder="Escribir"
                className="filter"
                labelClass="filter"
                value={form.search }
                onChange={e => this.change(e.target.value, 'search')}
              />
            </div>
            <div className="col-2">
              <Select
                color="white"
                label="Estatus"
                placeholder="Seleccionar"
                className="filter"
                labelClass="filter"
                options={[
                  { value: Constants.AUTOSALE_STATUS.PENDING, label: 'Pendiente' },
                  { value: Constants.AUTOSALE_STATUS.FINISH, label: 'Finalizadas' },
                  { value: Constants.AUTOSALE_STATUS.FAILED, label: 'No Finalizadas' }
                ]}
                value={form.status}
                onChange={e => this.change(e.target.value, 'status')}
              />
            </div>

            <div className="col-4 row">
              <Button
                className="btn-align-bottom btn-filter mr-3"
                color=" "
                onClick={() => this.fetchData()}
              >
                <span>Filtrar</span>
              </Button>

              {this.canClearFilters() && (
                <Button
                  className="btn-align-bottom btn-filter"
                  color=" "
                  onClick={this.cleanFilters}
                >
                  <span>Limpiar</span>
                </Button>
              )}
            </div>
          </div>

          <Table
            title="Rutas planificadas"
            data={this.state.data.length}
            header={this.state.header}
            right={
              <button style={{ border: 'none' }} onClick={this.onCreate}>
                <img src={AddIcon} width={32} alt="Agregar" />
              </button>
            }
          >
            {this.state.data.map((i, index) => {
              const keys = ['id'];
              const zones = [...i.seller?.zones,i.seller?.zone].filter(i => i !== null).filter((value, index, self) =>
                self.findIndex(v => keys.every(k => v[k] === value[k])) === index
              );

              return (
                <tr key={index}>
                  <td>{ i.seller?.name }</td>
                  <td>{ moment(i.date).format('DD-MM-YYYY') } - { moment(i.date_until).format('DD-MM-YYYY') }</td>
                  <td>{ i.seller?.status_text }</td>
                  <td>{ zones.map(i => i.name).filter(i => i != null).join(', ') }</td>
                  <td>
                    <img
                      src={LocationPinIcon}
                      className="location-pin"
                      alt="Ubicación"
                      onClick={() => this.viewRoute(i)}
                    />
                  </td>
                  <td>{ i.status_text }</td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      color="primary"
                      onClick={() => this.view(i)}
                    >
                      <Icon name="eye" />
                    </Button>
                    {i.status_text == Constants.AUTOSALE_STATUS.TEXT.PENDING && (
                      <>
                        <Button
                          title="Editar"
                          small="true"
                          style={{backgroundColor: '#0f54e0'}}
                          color="primary"
                          onClick={() => this.edit(i)}
                        >
                          <Icon name="edit" />
                        </Button>
                        <Button
                          title="Eliminar"
                          small="true"
                          style={{backgroundColor: '#cf3f10'}}
                          color="red"
                          onClick={() => this.delete(i)}
                        >
                          <Icon name="trash" />
                        </Button>
                      </>
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => this.fetchData(page)}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => ({
  user: state.user
}))(Autosale);
