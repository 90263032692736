import React from 'react';
import { on } from 'jetemit';
import { Constants, Globals } from '../utils';
import { Input, Button, Icon, InputGroup } from '.';

class ModalScanCompound extends React.Component {

	constructor(props) {
		super(props);

		const unsubscriber = on(Constants.SCANNER,this.add);

		this.state = {
			item: props.item,
			manualSerial: '',
			unsubscriber: unsubscriber
		}	
	}

	componentWillUnmount() {
		if (this.state.unsubscriber) {
			this.state.unsubscriber();
		}
	}

	add = code => {
		let serials = [...this.state.serials];
		if (!code || code == '') {
			Globals.showError("Lo sentimos, el serial no puede estar vacio");
		}
		else if (serials.indexOf(code) === -1) {
			serials.push(code);
			this.setState({
				serials: serials,
				manualSerial: ''
			});
		}
		else {
			Globals.showError("Lo sentimos, el serial ya se encuentra agregado");
		}
	}

	render() {
		const { manualSerial, item } = this.state;
		return (
			<div className="modal-scan">
				{item.product_details.filter(detail => parseInt(detail.serialization) === 1).map(detail => {
					return (
						<div>
							<div className="row">
								<div className="col-md-6">
									<p className="quantity"><span>Producto:</span> { detail.product.name }</p>
									<p className="note-serialize">Use el lector de códigos de barra o ingrese manualmente</p>
								</div>
								<div className="col-md-6">
									<form className="container-serial-manual" onSubmit={ event => {
										event.preventDefault();
										this.add(manualSerial);
									} }>
										<div style={ {
											flex: .4
										} }>
											<Input
												color="gray"
												type="text"
												value={ manualSerial }
												maxLength={ 25 }
												onChange={ emitter => {
													const { value } = emitter.target;
													this.setState({
														manualSerial: value
													});
												} }
											/>
										</div>
										<div style={ {
											flex: .3
										} }>
											<Button className="btn-green">
											Agregar
											</Button>
										</div>
									</form>				
								</div>
							</div>				
							{/* <div className="row">
								{
									detail.serials.map((item,index) => (
										<div className="col-md-4" key={ index }>
											<InputGroup
												label="Hola"
												color="gray"
												type="text"
												value={ item }
												disabled={ true }
												itemRight={
													<Button onClick={ () => {
														this.setState({
															serials: detail.serials.filter(i => i != item)
														});
													} }>
														<Icon name="remove" />
													</Button>
												}
											/>
										</div>
									))
								}
							</div> */}
						</div>
					)
				})}
				<div className="row-flex flex-right">
					<div className="row-flex row-flex-buttons">
						<div className="col-flex text-center">
							<Button className="btn-red" onClick={ this.props.onCancel }>
		                      Cancelar
		                    </Button>					
						</div>
						{/* <div className="col-flex text-center">
							<Button
							   disabled={ serials.length == 0 } 
							   className="btn-green"
							   onClick={ () => this.props.onSuccess(serials) }>
		                      Guardar
		                    </Button>
						</div>					 */}
					</div>
				</div>
			</div>
		)
	}
}

export default ModalScanCompound;