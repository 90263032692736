import React from "react";
import { Button, Input, Select } from "../../components";
import { Globals, Constants } from "../../utils";
import { Bank } from "../../services";

class CreateEditBank extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            form: {
                id: "",
                name: "",
                type: ""
            },
            submitted: false,
            textButton: "Crear",
            errors: [],
        };
    }
  componentDidMount() {
    const { bank } = this.props
    if(bank) {
        this.loadData(bank)
    }
  }

  change = emitter => {
    const { name, value } = emitter.target;
    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  loadData = async (bank) => {
    await this.setState({
        form: {
          ...bank
        },
        textButton: "Editar"
      });
  }

  submit = () => {

    this.setState({ submitted: true, errors: [] });

    const { bank, EnterpriseId, user } = this.props;
    const { form } = this.state;
    const bankFunction = bank ? Bank.updateBank(form.id, {...form, enterprise_id: EnterpriseId, user_id: user.id}) : 
    Bank.newBank({...form, enterprise_id: EnterpriseId, user_id: user.id});
    bankFunction
    .then( response => {
        const { name } = response
        const messageSuccess = bank ? `¡Banco ${name} modificado exitosamente!` : `¡Banco ${name} creado exitosamente!`
        Globals.showSuccess(messageSuccess);
        this.props.onClose();
    })
    .catch(error => {
        if ((error || {}).message) {
            Globals.showError(error.message);
        }

        if (error.hasOwnProperty("msg")) {
            Globals.showError(error.msg);
        }

        this.setState({ submitted: false, errors: error });
    });
  }

  hasErrorFor(field) {
    const { errors } = this.state
    return !!errors[field];
  }

  renderErrorFor(field) {
    const { errors } = this.state
    if (this.hasErrorFor(field)) {
      return (
        <span className="invalid-feedback my-2 text-left">
          <strong>{errors[field][0]}</strong>
        </span>
      );
    }
  }

  render() {
    const { form, submitted } = this.state;
    return (
      <div className="text-center container-create-edit-user">
        <div className="row">
          <div className="col-md-12">
            <Input
                color="gray"
                value={form.name}
                name="name"
                label="Nombre"
                onChange={this.change}
                error={`${
                        this.hasErrorFor("name") ? "is-invalid" : ""
                    }`}
                invalidfeedback={this.renderErrorFor("name")}
            />
          </div>
          <div className="col-md-12">
            <Select
              color="gray"
              name="type"
              label="Tipo de Entidad"
              defaultname="Seleccione"
              disabledFirst="false"
              onChange={this.change}
              value={form.type}
              options={Constants.TYPE_BANKS}
              error={`${
                      this.hasErrorFor("type") ? "is-invalid" : ""
                  }`}
              invalidfeedback={this.renderErrorFor("type")}
            />
          </div>
        </div>
        <div id="button">
          <Button 
            submitted={
              submitted ? submitted : undefined
            }
            block="true" 
            type="button" 
            onClick={() => this.submit()}
          >
            {this.state.textButton}
          </Button>
        </div>
      </div>
    );
  }
}
export default CreateEditBank;