import axios from "../../utils/axios";

class Role {
  static async createRoleEnterprises(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/create", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async updateRoleEnterprises(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/edit", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getRolesEnterprises() {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises/enterprises", {}).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getRoles(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/users/get-roles-enterprises", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }

  static async getRolesByEnterprise(form) {
    return new Promise((resolve, reject) => {
      axios.post("admin/roles_enterprises", form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Role;
