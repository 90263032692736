import React from "react";
import { Select, DatePicker, Button } from "../../../components";
import { Globals } from "../../../utils";

const METHOD_TYPE_LIST = [
  { value: 1, label: "Efectivo" },
  { value: 2, label: "Credito" },
  { value: 3, label: "Debito"},
  { value: 4, label: "Transferencia"}
];

class FilterReport extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        provider: 0,
        payment_type: 0,
        status: '',
        user_id: Globals.getUserId(props.user)
      },
      dates: {
        minDate: "",
        maxDate: ""
      }
    };
  }

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };

  render() {
    
    const { form, dates } = this.state;
    const { providers } = this.props;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md">
            <DatePicker
              color="white"
              label="Desde"
              minDate={dates.minDate}
              value={form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md">
            <DatePicker
              color="white"
              label="Hasta"
              maxDate={dates.maxDate}
              value={form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    until: value
                  }
                }));
              }}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md">
            <Select
              color="white"
              name="provider"
              label="Proveedores"
              disabledFirst={false}
              defaultname="Todos"
              defaultValue={0}
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.provider}
              options={[...providers]}
            />
          </div>
          <div className="col-md">
            <Select
              color="white"
              name="payment_type"
              label="Tipo de pago"
              disabledFirst={false}
              defaultname="Todos"
              defaultValue={0}
              style={{ textTransform: "capitalize" }}
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.payment_type}
              options={[...METHOD_TYPE_LIST]}
            />
          </div>
          <div className="col-md">
            <div className="form-group">
              <Button
                className="btn-block btn-align-bottom"
                onClick={this.load}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default FilterReport;
