let data = {};
let mode = 3;
let Base = '';
switch (mode) {
    case 1: Base = 'http://127.0.0.1'; break;
    case 2: Base = 'https://php7.teamlimonbyte.com/Sysge24/api/public'; break;
    case 3: Base = 'https://api.sysge24.com'; break;
    default: break;
}
switch(mode) {
    case 1: // dev local
        data = {
            BaseURI: `${Base}:8000/api/`,
            BasePublic: `${Base}:8000/`,
            BaseStorage: `${Base}:8000/storage/`,
            BaseStore: 'https://app.sysge24.com/',
            Socket: `${Base}:11020`,
            mode: mode,
        }
        break;
    case 2: //Tuqueque
        data = {
            BaseURI: `${Base}/api/`,
            BasePublic: `${Base}/`,
            BaseStorage: `${Base}/storage/`,
            BaseStore: 'http://php7.teamlimonbyte.com/Sysge24/public/',
            Socket: `http://php7.teamlimonbyte.com:11020`,
            mode: mode,
        }
        break;
    case 3: //SYSGE PROD
        data = {
            BaseURI: `${Base}/api/`,
            BasePublic: `${Base}/`,
            BaseStorage: `${Base}/storage/`,
            BaseStore: 'https://app.sysge24.com/',
            Socket: `https://web.sysge24.com:11021`,
            mode: mode,
        }
        break;
    default:
        break;
}
export default data;