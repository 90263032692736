import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import AddIcon from '../../assets/icons/add.png';
import globals from "../../utils/globals";
// import socket from '../../utils/socket';

class Access extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: [
        "Nombre",
        "Fecha de creación",
        "Acciones"
      ],
      page: 1,
      last_page: 1,
      data: [],
      user: null,
      view: false,
      list_status: [
        { value: "", label: "Todos" },
        // { value: 0, label: "Nuevos" },
        { value: 1, label: "Activos" },
        { value: 0, label: "Inactivos" }
      ],
      form: {
        status: null,
        search: null,
        user_id: this.props.user.id,
        role: this.props.user.role,
        admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        web: true,
      }
    };
  }

  abortController = new AbortController()

  componentDidMount() {
    this.load();
  }

  componentWillUnmount() {
    this.abortController.abort()
  }
  
  load = () => {
    Globals.setLoading();

    axios
      .post("admin/usersaccess/get", {user_id: this.props.user.id})
      .then(res => {
        if (res.data.result) {
          this.setState({
            data: res.data.token,
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
    
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Inactivo",
          color: Colors.red
        };
        break;

      case 1:
        respuesta = {
          text: "Activo",
          color: Colors.green
        };
        break;

      case 2:
        respuesta = {
          text: "Suspendido",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = () => {
    this.setState({
      create: false,
      view: false,
      edit: null,
      user: null
    });

    this.load();
  };

  inactiveUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea inactivar a: ${name}?`, () => {
      Users.setStatusInactive({  id: Item.id })
        .then(response => {
          // socket.emit('disable-user',{id: item.id});
          this.load();
          Globals.showSuccess("¡Vendedor inactivado exitosamente!");
        })
        .catch(error => {
          Globals.showError(error);
        });
    });
  };

  activateUser = Item => {
    const name = Item.name;

    Globals.confirm(`¿Desea activar a: ${name}?`, () => {
      Users.setStatusActive({ Id: Item.id })
        .then(response => {
          //socket.emit('activate-user', { id: item.id });
          this.load();
          Globals.showSuccess("¡Vendedor activado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  verifyUser = Item => {
    const { person } = Item;
    const name = person.first_name;

    Globals.confirm(`¿Desea verificar a: ${name}?`, () => {
      Users.setStatusVerify({ Id: Item.id })
        .then(response => {
          this.load();
          Globals.showSuccess("¡Vendedor verificado exitosamente!");
        })
        .catch(error => {
          Globals.showError();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Seguro de eliminar su token?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post(`admin/usersaccess/delete`, { id: item.id })
        .then(res => {
          Globals.showSuccess('Token eliminado');
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  handleChange = emitter => {
    const { name, value } = emitter.target;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value
      }
    });
  };

  set = (item, i) => {
    Globals.confirm(
      "¿Desea seleccionar al Vendedor multiempresa: " + item.person.first_name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/users/get-user-data", { id: item.id })
          .then(res => {
            this.props.dispatch({
              type: "SET_USER",
              payload: res.data.user
            });
            Sidebar.getModules(res.data.user.role).then(response => {
              this.props.dispatch({
                type: "SET_SIDEBAR",
                payload: response
              });
              this.props.history.push("/enterprises");
            });
          })
          .catch(err => {
            Globals.showError(err.response.data.msg);
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };

  generateToken = () => {
    Globals.setLoading();

    axios
      .post("admin/usersaccess/create", {user_id: this.props.user.id})
      .then(res => {
        if (res.data.result) {
          this.load();
          Globals.showSuccess("¡Token registrado con exito! revisa tu cuenta de email");
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  }

  render() {
    const {history} = this.props
    const { create, data, edit, header, list_status, search, status, user, view } = this.state
    // const titleTable = this.props.user.role === 1 ? 'Multiempresas' : 'Vendedores';
    // const userTypeText = this.props.user.role === 1 ? 'Multiempresa' : 'Vendedor';
    const titleTable = 'API Tokens';
    const userTypeText = 'Vendedor';
    return (
      <Menu history={history}>


        <div id="home">
          <div className="row">
            
          </div>
          <Table
            data={data.length}
            title={titleTable}
            header={header}
            message_api_token={true}
            right={
              data.length == 0 ?
              <Button
                title="Nuevo API TOKEN"
                small="true"
                onClick={() => {
                  this.generateToken()
                }}
              >
                <img src={AddIcon} alt="Nuevo Token" />
              </Button>
              : ''
            }
          >
            {data.map((Item, index) => {
              return (
                <tr key={index}>
                  <td>Token de acceso a API</td>
                  <td>{Item.date_es}</td>
                  <td style={{ maxWidth: 'unset' }}>
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(Item, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Access);
