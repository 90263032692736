import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals } from "../../utils";
import CreateEditBranch from "./create_edit_branch";
import ViewBranch from "./view_branch";
import AddIcon from '../../assets/icons/add.png';

class Branch extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      header: ["Nombre", "Código", "Estatus", "Acciones"],
      page: 1,
      last_page: 1,
      data: [],
      enterprises: [],
      branch: null,
      view: false,
      list_status: [
        { value: "", label: "Todos" },
        { value: 1, label: "Activos" },
        { value: 2, label: "Suspendidos" },
        { value: 3, label: "Eliminados"}
      ],
      form: {
        status: "",
        search: "",
        enterprise_id: Globals.getEnterpriseId(props.user),
        role: props.user.role
      }
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { page, form } = this.state;
    const { user } = this.props;

    form.branch_id = user.assigned_warehouses.map(Item => {
      return Item.branch_id;
    });

    form.branch_id = user.role === 3 ? "" : form.branch_id;

    axios
      .post("admin/branches/get?page=" + page, form)
      .then(response => {
        if (response.data.result) {
          if (this.state.page > 1 && response.data.branches.data.length === 0) {
            this.setState(
              {
                page: 1
              },
              () => {
                this.load();
              }
            );
          } else {
            this.setState({
              last_page: response.data.branches.last_page,
              data: response.data.branches.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      });
  };

  close = () => {
    this.setState(
      {
        create: false,
        view: false,
        edit: null,
        branch: null
      },
      () => {
        this.load();
      }
    );
  };

  suspend = (item, i) => {
    Globals.confirm("¿Desea suspender la Sucursal: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/branches/suspend", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  activate = (item, i) => {
    Globals.confirm("¿Desea activar la Sucursal: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/branches/active", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("Se evaluara si los almacenes de la sucursal no tienen existencia de productos para ser eliminado, ¿Desea cotinuar?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/branches/delete", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError(err.response.data.msg);
        })
        .then(() => {
          Globals.quitLoading();
        });
    }, "¿Desea eliminar la Sucursal: " + item.name + "?");
  };
  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  getEnterprises = async () => {
    Globals.setLoading();
    axios
      .get("admin/warehouses/enterprises")
      .then(res => {
        if (res.data.result) {
          this.setState({
            enterprises: res.data.users
          });
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  render() {
    const { create } = this.state;

    return (
      <Menu history={this.props.history}>
        {this.state.view && (
          <Modal title="Ver Sucursal" onClose={this.close} visible>
            <ViewBranch branch={this.state.branch} />
          </Modal>
        )}

        {this.state.create && (
          <Modal
            title={this.state.edit ? "Editar Sucursal" : "Nueva Sucursal"}
            onClose={this.close}
            visible
          >
            <CreateEditBranch
              user={this.props.user}
              edit={this.state.edit}
              enterprises={this.state.enterprises}
              onClose={this.close}
            />
          </Modal>
        )}
        <div id="home">
          <div className="row">
            <div className="col-md-5">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre o Código"
              />
            </div>
            <div className="col-md-5">
              <Select
                color="white"
                name="status"
                label="Estatus"
                className="filter"
                defaultname="Seleccione"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div>
            <div className="col-md-2">
              <Button
                color=" "
                className="btn-align-bottom btn-filter"
                onClick={this.load}
              >
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Sucursales"
            header={this.state.header}
            right={
              <Button
                title="Agregar Sucursales"
                small="true"
                onClick={() => this.setState({ create: true })}
              >
                <img src={AddIcon} alt="Agregar Sucursales" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.name}</td>
                  <td>{i.code}</td>
                  { !i.deleted_at && (
                    <td style={{ color: Globals.getStatus(parseInt(i.status)).color }}>
                      {Globals.getStatus(parseInt(i.status)).text}
                    </td>
                  )}
                  { i.deleted_at && (
                    <td style={{ color: Globals.getStatus(3).color }}>
                      {Globals.getStatus(3).text}
                    </td>
                  )}
                  <td>
                    <Button
                      title="Ver"
                      color=" "
                      small="true"
                      style={{backgroundColor: '#5c068c'}}
                      onClick={() => {
                        this.setState({
                          branch: i,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    { !i.deleted_at && (
                      <Button
                        title="Editar"
                        color=" "
                        small="true"
                        style={{backgroundColor: '#0f54e0'}}
                        onClick={() =>
                          this.setState({
                            create: true,
                            edit: {
                              element: i,
                              index: index
                            }
                          })
                        }
                      >
                        <Icon name="edit" />
                      </Button>
                    )}
                    {(i.status === 2 && !i.deleted_at) && (
                      <Button
                        color=" "
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.activate(i, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {(i.status === 1 && !i.deleted_at) && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.suspend(i, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}
                    {(this.props.user.role === 3 && !i.deleted_at) && (
                      <Button
                        color="red"
                        title="Eliminar"
                        small="true"
                        style={{backgroundColor: '#cf3f10'}}
                        onClick={() => this.delete(i, index)}
                      >
                        <Icon name="trash" />
                      </Button>
                    )}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Branch);
