import React from "react";

import { DatePicker, Button, Select, Input } from "../../components";
import { Format, Globals, Constants } from "../../utils";
import { Warehouse } from "../../services";

const STATUS = [
  {
    label: "Todos",
    value: -1
  },
  {
    label: "Comprometido",
    value: 0
  },
  {
    label: "Procesado",
    value: 1
  }
];

const TYPES = [
  {
    label: "Todos",
    value: 0
  },
  {
    label: "Entrada al inventario",
    value: 1
  },
  {
    label: "Salida del inventario",
    value: 2
  }
];

const ACTION_CLASS = [
  {
    label: "Todos",
    value: 0
  },
  {
    label: "Ajuste de Inventario",
    value: 1
  },
  {
    label: "Orden de Compra",
    value: 2
  },
  {
    label: "Notas de Entrega",
    value: 3
  },
  {
    label: "Traslado entre Almacenes",
    value: 4
  },
  {
    label: "Devolución",
    value: 8
  }
];
class Filter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {
        status: -1,
        type: 0,
        action_class: 0,
        name: "",
        year: "",
        month: "",
        code: "",
        since: "",
        until: "",
        branch_id: [],
        warehouse_id: Globals.getAssignedWarehouses(props.user),
      },
      dates: {
        minDate: "",
        maxDate: ""
      },
      warehouses: [],
      months: [],
      years: []
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.form.branch_id !== this.state.form.branch_id &&
      this.state.form.branch_id
    ) {
      this.maybeLoadWarehouses();
    }
  }

  maybeLoadWarehouses = () => {
    const { form } = this.state;
    const { user } = this.props;
    const warehouse_id = Globals.getAssignedWarehouses(user)

    const userId = (user.enterprise_users || {}).enterprise_id || user.id;

    Warehouse.getWarehouseByBranch({
      user_id: userId,
      branch_id: form.branch_id
    }).then(response => {
      let warehouses_filtered = response.filter(
        ({ deleted_at }) => !deleted_at
      );

      const warehousesMap = Format.rawWarehouse(
        warehouses_filtered,
        user.assigned_warehouses,
        user.role
      )
        .filter(({ status }) => status === Constants.STATUS_ACTIVE)
        .map(({ id, name }) => ({
          value: id,
          label: name
        }));

      this.setState(state => ({
        form: {
          ...state.form,
          warehouse_id: warehouse_id
        },
        warehouses: warehousesMap
      }));
    });
  };

  handleChange = (emitter, string) => {
    let { name, value } = emitter.target;

    if(name === 'branch_id' || name === 'warehouse_id') {
      value = [parseInt(value)]
    }

    this.setState(state => ({
      form: {
        ...state.form,
        [name]: value
      }
    }));
  };
  
  render() {
    const { branches } = this.props;
    const { form, dates, warehouses } = this.state;

    return (
      <form
        onSubmit={emitter => {
          emitter.preventDefault();
          this.props.submitted(form);
        }}
      >
        <div className="row">
          <div className="col-md-4">
            <Input
              color="white"
              name="name"
              label="Búsqueda"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.name}
              placeholder="Buscar por Producto"
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="branch_id"
              label="Sucursales"
              defaultname="Todos"
              disabledFirst="false"
              style={{ textTransform: "capitalize" }}
              onChange={emitter => { this.handleChange(emitter, null) }}
              value={form.branch_id}
              options={branches}
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="warehouse_id"
              label="Almacenes"
              defaultname="Todos"
              defaultvalue={0}
              disabledFirst="false"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.warehouse_id}
              options={warehouses}
            />
          </div>
          <div className="col-md-4">
            <DatePicker
              color="white"
              label="Desde"
              minDate={dates.minDate}
              value={form.since}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...state.form,
                    since: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md-4">
            <DatePicker
              color="white"
              label="Hasta"
              maxDate={dates.maxDate}
              value={form.until}
              onChange={value => {
                this.setState(state => ({
                  form: {
                    ...form,
                    until: value
                  }
                }));
              }}
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="type"
              label="Tipo"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.type}
              options={TYPES}
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="action_class"
              label="Movimientos"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.action_class}
              options={ACTION_CLASS}
            />
          </div>
          <div className="col-md-4">
            <Select
              color="white"
              name="status"
              label="Estatus"
              style={{ textTransform: "capitalize" }}
              defaultname="Seleccione"
              onChange={emitter => this.handleChange(emitter, null)}
              value={form.status}
              options={STATUS}
            />
          </div>

          <div className="col-md-4">
            <div className="form-group">
              <Button
                className="btn-block btn-align-bottom"
                onClick={this.load}
              >
                Filtrar
              </Button>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default Filter;
