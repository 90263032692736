import React from 'react';
import { List } from '../../components';
import NumberFormat from 'react-number-format';

class ViewClient extends React.Component {

	state = {
		product: this.props.product
    }
		
	render() {
		return (
			<div className="text-center container-view-user">
				<List.Container>
					<List.Item label="Costo">
						<NumberFormat value={ this.state.product.cost } displayType={'text'} thousandSeparator={true} />
					</List.Item>
					<List.Item label="Precio Min.">
						<NumberFormat value={ this.state.product.price } displayType={'text'} thousandSeparator={true} />
					</List.Item>
					<List.Item label="Porcentaje de Utilidad Precio Min.">
						{ this.state.product.percentage_earning }%
					</List.Item>
					<List.Item label="Precio Max.">
						<NumberFormat value={ this.state.product.price_max } displayType={'text'} thousandSeparator={true} />
					</List.Item>
					<List.Item label="Porcentaje de Utilidad Precio Max.">
						{ this.state.product.percentage_max }%
					</List.Item>
					<List.Item label="Precio Oferta">
						<NumberFormat value={ this.state.product.price_offer } displayType={'text'} thousandSeparator={true} />
					</List.Item>
					<List.Item label="Porcentaje de Utilidad Precio Oferta">
						{ this.state.product.percentage_offer }%
					</List.Item>
				</List.Container>
			</div>
		)
	}
}

export default ViewClient;