import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
	Table,
	Pagination,
	Button,
	Icon,
	Modal,
	Select,
	Input
} from "../../components";
import { axios, Globals, Colors, Constants } from "../../utils";
import { User as Users, Sidebar } from "../../services";
import AddIcon from '../../assets/icons/add.png';
import globals from "../../utils/globals";
import APIGuide from "./api_guide";
// import socket from '../../utils/socket';

class Docu extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			header: [
				"Nombre/Razón social",
				"Correo electrónico",
				"Estatus",
				"Acciones"
			],
			page: 1,
			last_page: 1,
			data: [],
			user: null,
			view: false,
			list_status: [
				{ value: "", label: "Todos" },
				// { value: 0, label: "Nuevos" },
				{ value: 1, label: "Activos" },
				{ value: 0, label: "Inactivos" }
			],
			form: {
				status: null,
				search: null,
				user_id: this.props.user.id,
				role: this.props.user.role,
				admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
        		web: true,
			},
			modules: [],
			sellers: [],
			permissions: [],
		};
	}

	abortController = new AbortController()

	componentDidMount() {
		
	}

	componentWillUnmount() {
		this.abortController.abort()
	}

	

	render() {
		const { history } = this.props
		const titleTable = 'Documentación';
		return (
			<Menu history={history}>
				

				<div id="home">
					<APIGuide></APIGuide>
				</div>
			</Menu>
		);
	}
}

export default connect(state => {
	return {
		user: state.user
	};
})(Docu);
