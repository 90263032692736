import React from "react";

import { Avatar, List } from "../../components";
import { ENV } from "../../utils";

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: this.props.data,
      products: []
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    const { data } = this.state;

    const productsMap = data.product_details.map(Item => {
      return {
        ...Item,
        code: (Item.product || {}).code,
        name: (Item.product || {}).name,
        description: (Item.product || {}).description
      };
    });

    this.setState({ products: productsMap });
  };

  render() {
    const { products } = this.state;

    return (
      <div className="text-center container-view-user">
        {this.state.data.avatar ? (
          <Avatar
            source={ENV.BasePublic + this.state.data.avatar}
            size="130px"
          />
        ) : null}
        <List.Container>
          <List.Item label="Nombre">{this.state.data.name}</List.Item>
          <List.Item label="Código">{this.state.data.code}</List.Item>
          <List.Item label="Descripción">
            {this.state.data.description ? this.state.data.description : 'Sin descripción'}
          </List.Item>
          <List.Item label="Porcentaje de comision">
            {this.state.data.percentage_commission}
          </List.Item>
          <List.Item label="Exento">
            {this.state.data.exempt === 0 ? "NO" : "SI"}
          </List.Item>
          <List.Item label="Categoría">
            {this.state.data.product_category
              ? this.state.data.product_category.category.name
              : "Sin Categoría"}
          </List.Item>
          <List.Item label="Subcategoría">
            {this.state.data.product_subcategory
              ? this.state.data.product_subcategory.subcategory.name
              : "Sin Subcategoría"}
          </List.Item>
          <List.Item label="Marca">
            {this.state.data.product_brand
              ? this.state.data.product_brand.brand.name
              : "Sin Marca"}
          </List.Item>
          <List.Item label="Modelo">
            {this.state.data.product_model
              ? this.state.data.product_model.model.name
              : "Sin Modelo"}
          </List.Item>
          <div className="row text-center my-3">
            <div className="col col-md">
              <strong>Productos</strong>
            </div>
          </div>
          {products.length > 0
            ? products.map((Item, key) => {
                return (
                  <List.Item key={key} label={Item.name}>
                    {Item.amount}
                  </List.Item>
                );
              })
            : null}
        </List.Container>
      </div>
    );
  }
}

export default ViewProduct;
