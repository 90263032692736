import React from 'react';
import { connect } from 'react-redux';
import Menu from '../menu';
import {
	Table,
	Pagination,
	Button,
	Icon,
	Modal,
	Input
} from '../../components';
import { axios, Globals, Colors } from '../../utils';
import { Module } from '../../services';
import CreateEditModule from './create_edit_modules';
import ViewModule from './view_modules';

class Modules extends React.Component {
	state = {
		header: ['Empresa', 'Acciones'],
		page: 1,
		last_page: 1,
		data: [],
		dataModules: [],
		user: null,
		modules: [],
		view: false,
		list_status: [
			{ value: '', label: 'Todos' },
			{ value: 0, label: 'Suspendidos' },
			{ value: 1, label: 'Activos' }
		],
		form: {
			status: '',
			search: ''
		}
	};
	componentDidMount() {
		this.load();
	}
	load = () => {
		Globals.setLoading();
		let obj = {
			role: this.props.user.role,
			status: this.state.form.status,
			search: this.state.form.search,
			user_id: this.props.user.id
		};

		Module.getModules()
			.then(response => this.setState({ dataModules: response.modules }))
			.catch(error => {});

		axios
			.post('admin/modules/get-enterprises?page=' + this.state.page, obj)
			.then(async res => {
				if (res.data.result) {
					if (this.state.page > 1 && res.data.enterprises.data.length === 0) {
						this.setState({
							page: 1
						});
						this.load();
					} else {
						this.setState({
							last_page: res.data.enterprises.last_page,
							data: res.data.enterprises.data
						});
					}
				}
			})
			.catch(err => {
				Globals.showError();
			})
			.then(() => {
				Globals.quitLoading();
			});
	};

	getStatus = status => {
		let respuesta = '';
		switch (status) {
			case 0:
				respuesta = {
					text: 'Suspendido',
					color: Colors.orange
				};
				break;

			case 1:
				respuesta = {
					text: 'Activo',
					color: Colors.green
				};
				break;
			default:
				break;
		}
		return respuesta;
	};

	close = async () => {
		await this.setState({
			create: false,
			view: false,
			edit: null,
			user: null,
			modules: []
		});
		await this.load();
	};

	suspend = (item, i) => {
		Globals.confirm('¿Desea suspender el rol ' + item.name + '?', () => {
			Globals.setLoading('Guardando...');
			axios
				.post('admin/modules/suspend', { id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					} else {
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	};

	activate = (item, i) => {
		Globals.confirm('¿Desea activar el rol: ' + item.name + '?', () => {
			Globals.setLoading('Guardando...');
			axios
				.post('admin/modules/active', { id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					} else {
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	};

	verify = (item, i) => {
		Globals.confirm(
			'¿Desea verificar a: ' +
				item.person.first_name +
				' ' +
				item.person.last_name +
				'?',
			() => {
				Globals.setLoading('Guardando...');
				axios
					.post('admin/modules/verify', { id: item.id })
					.then(res => {
						if (res.data.result) {
							Globals.showSuccess(res.data.msg);
							this.load();
						} else {
							Globals.showError();
						}
					})
					.catch(err => {
						Globals.showError();
					})
					.then(() => {
						Globals.quitLoading();
					});
			}
		);
	};

	delete = (item, i) => {
		Globals.confirm('¿Desea eliminar el rol: ' + item.name + '?', () => {
			Globals.setLoading('Guardando...');
			axios
				.post('admin/modules/delete', { id: item.id })
				.then(res => {
					if (res.data.result) {
						Globals.showSuccess(res.data.msg);
						this.load();
					} else {
						Globals.showError();
					}
				})
				.catch(err => {
					Globals.showError();
				})
				.then(() => {
					Globals.quitLoading();
				});
		});
	};
	change = e => {
		this.setState({
			form: {
				...this.state.form,
				[e.target.name]: e.target.value
			}
		});
	};
	render() {
		return (
			<Menu history={this.props.history}>
				<Modal title="Ver Módulos" onClose={this.close} visible={this.state.view}>
					<ViewModule modules={this.state.modules} />
				</Modal>
				<Modal
					title={this.state.edit ? 'Editar Módulos' : 'Nuevo Módulos'}
					onClose={this.close}
					visible={this.state.create}
				>
					<CreateEditModule
						modules={this.state.dataModules}
						edit={this.state.edit}
						onClose={this.close}
					/>
				</Modal>
				<div id="home">
					<div className="row">
						<div className="col-md-10">
							<Input
								color="white"
								name="search"
								label="Búsqueda"
								onChange={this.change}
								value={this.state.search}
								placeholder="Buscar por Empresa"
							/>
						</div>
						<div className="col-md-2">
							<Button className="btn-align-bottom" onClick={this.load}>
								Filtrar
							</Button>
						</div>
					</div>
					<Table
						data={this.state.data.length}
						title="Módulos de Empresas"
						header={this.state.header}
					>
						{this.state.data.map((i, index) => {
							return (
								<tr key={index}>
									<td>{i.first_name}</td>
									<td>
										<Button
											title="Ver"
											small="true"
											onClick={() => {
												this.setState({
													modules: i.enterprise_modules,
													view: true
												});
											}}
										>
											<Icon name="eye" />
										</Button>
										<Button
											title="Editar"
											color="primary"
											small="true"
											onClick={() =>
												this.setState({
													create: true,
													edit: {
														element: i,
														index: index
													}
												})
											}
										>
											<Icon name="edit" />
										</Button>
									</td>
								</tr>
							);
						})}
					</Table>

					<Pagination
						pages={this.state.last_page}
						active={this.state.page}
						onChange={async page => {
							await this.setState({
								page: page
							});
							this.load();
						}}
					/>
				</div>
			</Menu>
		);
	}
}

export default connect(state => {
	return {
		user: state.user
	};
})(Modules);
