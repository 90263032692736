import React, { createRef } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, DatePicker, Icon, Input, Modal, Pagination, Table } from '../../../components';
import AddIcon from '../../../assets/icons/add.png';
import ModalCreateReturn from './modal-create-return';
import ModalFindReturn from './modal-find-order';
import { Globals, axios } from '../../../utils';

const INITIAL_FILTER = {
  date: null,
  search: '',
};

class Return extends React.Component {

  state = {
    showCreate: false,
    selectedItem: null,
    form: { ...INITIAL_FILTER },
    data: [],
    page: 1,
    last_page: 1,
  }

	debounce = createRef();

  componentDidMount() {
    this.load();
  }

  load = async (page = 1) => {
    Globals.setLoading();
    const { form } = this.state;
    const date = form.date && moment(form.date,'DD-MM-YYYY').format('YYYY-MM-DD');

    const filter = {
      user_id: this.props.user.id,
      search: form.search,
      until: date,
      since: date,
      page,
    };

    try {
      const res = await axios.post('admin/orders/fiscal-bill/creditnote/get', filter);
      this.setState({
        data: res.data.orders.data,
        last_page: res.data.orders.last_page,
        page,
      });

    } catch (error) {
      Globals.showError(error?.response?.data?.error || 'Ocurrió un error inesperado');
    }

    Globals.quitLoading();
  }

  change = async (value, target) => {
    await this.setState(s => ({ form: { ...s.form, [target]: value } }));

    if (target === 'search') {
      if (this.debounce.current) clearTimeout(this.debounce.current);
      this.debounce.current = setTimeout(async () => this.load(), 1000);
    } else {
      this.load();
    }
  }

  onSelectItem = async (item) => {
    this.setState({ selectedItem: item });
  }

  canResetFilter = () => {
    return JSON.stringify(this.state.form) !== JSON.stringify(INITIAL_FILTER);
  }

  resetFilter = async () => {
    await this.setState({ form: { ...INITIAL_FILTER } });
    this.load();
  }

  // download = async (item) => {
  //   try {
  //     const form = {
  //       currency: Constants.CURRENCIES.DOLARES,
  //       order_id: item.id,
  //       user_id: this.props.user.id,
  //       _user_id: this.props.user.id,
  //     };

  //     const res = await axios.post('admin/orders/fiscal-bill/creditnote/download', form);
  //     if (!res.data?.url) throw new Error();

  //     window.open(res.data.url);

  //   } catch (error) {
  //     Globals.showError('Ocurrió un error al generar el PDF. Intente nuevamente.');
  //   }
  // }

  render() {

    const { data, form, last_page, page, selectedItem, showCreate } = this.state;

    return (
      <div>
        <Modal
          className="modal-return-create"
          title="Nueva nota de crédito"
          visible={showCreate}
          size="xl"
          onClose={(reload) => {
            this.setState({ showCreate: false });
            if (reload) this.load();
          }}
        >
          <ModalFindReturn
            onClose={() => {
              this.setState({ showCreate: false });
              this.load();
            }}
          />
        </Modal>

        <Modal
          className="modal-return-details"
          title="Detalles de la nota de crédito"
          visible={!!selectedItem}
          size="xl"
          onClose={() => this.onSelectItem(null)}
        >
          <ModalCreateReturn
            returnedItem={selectedItem}
            onClose={() => this.onSelectItem(null)}
            isReadOnly
          />
        </Modal>

        <div className="row">
          <div className="col-2">
            <DatePicker
              color="white"
              label="Fecha nota de crédito"
              className="filter"
              value={form.date}
              onChange={v => this.change(v,'date')}
            />
          </div>
          <div className="col-2">
            <Input
              color=" "
              label="Cliente"
              className="filter"
              onChange={e => this.change(e.target.value,'search')}
              value={form.search}
            />
          </div>
          <div className="col-md ">
            <Button
              className="btn-align-bottom btn-filter"
              color=" "
              onClick={async () => {
                await this.setState({ page: 1 });
                this.load();
              }}
            >
              <span>Filtrar</span>
            </Button>
            {this.canResetFilter() && (
              <Button
                color="transparent"
                className="btn-align-bottom btn-filter ml-2"
                onClick={this.resetFilter}
              >
                <span>Limpiar</span>
              </Button>
            )}
          </div>
        </div>

        <Table
          title="Registro de Notas de Crédito"
          header={['N°','Fecha','Cliente','Monto','Acciones']}
          emptyMessage="No hay notas de crédito registradas"
          data={!!data.length ? data : null}
          right={
            <Button
              title="Nueva Nota de Crédito"
              small="true"
              onClick={() => this.setState({ showCreate: true })}
            >
              <img src={AddIcon} alt="Nueva Nota de Crédito" />
            </Button>
          }
        >
          {data.map((item, index) => {
            return (
              <tr key={index}>
                <td>{ item.number_format }</td>
                <td>{ moment(item.order_bill?.created_at).format('DD/MM/YYYY') }</td>
                <td>{ item.client?.name }</td>
                <td>{ Globals.formatMiles(item.total) }</td>
                <td>
                  {/* <Button title="Imprimir" small="true" onClick={() => this.download(item)}>
                    <Icon name="print" />
                  </Button> */}
                  <Button title="Ver" small="true" onClick={() => this.onSelectItem(item)}>
                    <Icon name="eye" />
                  </Button>
                </td>
              </tr>
            );
          })}
        </Table>

        <Pagination
          pages={last_page}
          active={page}
          onChange={page => this.load(page)}
        />
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(Return);