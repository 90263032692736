import { axios } from '../../utils';

class QuoteService {

	static async convertToOrder(form) {
		return new Promise((resolve, reject) => {
			axios.upload("admin/quotes/convert", form).then(
				({ data }) => {
					// http success
					resolve(data);
				},
				({ response }) => {
					const { data } = response;
					// http failed
					reject(data);
				}
			);
		});
	}

}

export default QuoteService;