import React, { Component } from 'react';
import { connect } from 'react-redux';
import CloseBlackIcon from '../../assets/icons/close-black.png';

// @ts-ignore
const google = window.google;

export class ModalViewClient extends Component {

  state = {
    
  }

  componentDidMount() {
    this.setMap();
  }

  setMap = async () => {
    const map = new google.maps.Map(document.getElementById('map-planner-view-client'), {
      zoom: 13,
      center: new google.maps.LatLng(parseFloat(this.props.item?.latitude),parseFloat(this.props.item?.longitude)),
      draggable: true,
      zoomControl: true,
      mapTypeControl: false,
      streetViewControl: false,
      fullscreenControl: false,
      scaleControl: false,
      rotateControl: false
    });

    const marker = new google.maps.Marker({
      position: new google.maps.LatLng(parseFloat(this.props.item?.latitude),parseFloat(this.props.item?.longitude)),
      map,
      title: '',
      animation: google.maps.Animation.DROP,
      draggable: false
    });
  }

  render() {
    const item = this.props.item;

    return (
      <div className="modal-planner-view-client">
        <div className="text-right">
          <button className="btn-close" onClick={ () => this.props.onClose() }>
            <img src={ CloseBlackIcon } />
          </button>
        </div>
        <div id="map-planner-view-client"></div>
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalViewClient)