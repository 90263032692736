import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Select,
  Input
} from "../../components";
import { axios, Globals, Colors } from "../../utils";
import CreaEditCurrency from "./create_edit_currency";
import ViewCurrency from "./view_currency";
import $ from "jquery";

class Currency extends React.Component {
  state = {
    header: ["Nombre", "Código", "Estatus", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    currency: null,
    view: false,
    list_status: [
      { value: "", label: "Todos" },
      { value: 0, label: "Nuevas" },
      { value: 1, label: "Activas" },
      { value: 2, label: "Suspendidas" }
    ],
    form: {
      status: "",
      search: "",
      role: this.props.user.role
    }
  };
  componentDidMount() {
    this.load();
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  componentDidUpdate() {
    setTimeout(async () => {
      await $('[data-toggle="tooltip"]').tooltip("hide");
    }, 300);
  }
  load = () => {
    Globals.setLoading();
    axios
      .post("admin/currencies/get?page=" + this.state.page, this.state.form)
      .then(async res => {
        if (res.data.result) {
          if (this.state.page > 1 && res.data.currencies.data.length === 0) {
            await this.setState({
              page: 1
            });
            this.load();
          } else {
            await this.setState({
              last_page: res.data.currencies.last_page,
              data: res.data.currencies.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };

  getStatus = status => {
    let respuesta = "";
    switch (status) {
      case 0:
        respuesta = {
          text: "Nueva",
          color: Colors.red
        };
        break;
      case 1:
        respuesta = {
          text: "Activa",
          color: Colors.green
        };
        break;
      case 2:
        respuesta = {
          text: "Suspendida",
          color: Colors.orange
        };
        break;
      default:
        break;
    }
    return respuesta;
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      category: null
    });
    await this.load();
  };

  suspend = (item, i) => {
    let text = "¿Desea suspender la divisa ";
    if (item.status !== 1) {
      text = "¿Desea activar la divisa ";
    }
    Globals.confirm(text + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/currencies/suspend", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };

  delete = (item, i) => {
    Globals.confirm("¿Desea eliminar la divisa: " + item.name + "?", () => {
      Globals.setLoading("Guardando...");
      axios
        .post("admin/currencies/delete", { id: item.id })
        .then(res => {
          Globals.showSuccess(res.data.msg);
          this.load();
        })
        .catch(err => {
          Globals.showError();
        })
        .then(() => {
          Globals.quitLoading();
        });
    });
  };
  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  render() {
    return (
      <Menu history={this.props.history}>
        <Modal
          title="Ver Divisa"
          onClose={this.close}
          visible={this.state.view}
        >
          <ViewCurrency currency={this.state.currency} />
        </Modal>
        <Modal
          title={this.state.edit ? "Editar Divisa" : "Nueva Divisa"}
          onClose={this.close}
          visible={this.state.create}
        >
          <CreaEditCurrency
            user={this.props.user}
            edit={this.state.edit}
            onClose={this.close}
          />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-md-5">
              <Input
                color="white"
                name="search"
                label="Búsqueda"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre o Código"
              />
            </div>
            <div className="col-md-5">
              <Select
                color="white"
                name="status"
                label="Estatus"
                defaultname="Seleccione"
                onChange={this.change}
                value={this.state.status}
                options={this.state.list_status.map(i => {
                  return {
                    value: i.value,
                    label: i.label
                  };
                })}
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom" onClick={this.load}>
                Filtrar
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Divisas"
            header={this.state.header}
            right={
              <Button
                outline="true"
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <Icon name="plus" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.name}</td>
                  <td>{i.code}</td>
                  <td style={{ color: this.getStatus(i.status).color }}>
                    {this.getStatus(i.status).text}
                  </td>
                  <td>
                    <Button
                      title="Ver"
                      small="true"
                      onClick={() => {
                        this.setState({
                          currency: i,
                          view: true
                        });
                      }}
                    >
                      <Icon name="eye" />
                    </Button>
                    <Button
                      title="Editar"
                      color="primary"
                      small="true"
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: i,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    {i.status === 2 && (
                      <Button
                        color="info"
                        title="Activar"
                        small="true"
                        onClick={() => {
                          this.suspend(i, index);
                        }}
                      >
                        <Icon name="check" />
                      </Button>
                    )}
                    {i.status === 1 && (
                      <Button
                        color="info"
                        title="Suspender"
                        small="true"
                        onClick={() => this.suspend(i, index)}
                      >
                        <Icon name="close" />
                      </Button>
                    )}
                    {/* <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i, index)}
                    >
                      <Icon name="trash" />
                    </Button> */}
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={page => {
              this.setState(
                {
                  page: page
                },
                () => {
                  this.load();
                }
              );
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(Currency);
