import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, DatePicker, Input, Select } from '../../components';
import { axios, Constants, Globals } from '../../utils';
import TrashIcon from '../../assets/icons/trash.png';
import LeftArrowIcon from '../../assets/icons/left-arrow.png';
import CheckIcon from '../../assets/icons/check.png';
import CommissionCircleIcon from '../../assets/icons/commission-circle.png';

const TAB = {
  SELECTED: 1,
  UNPAID: 2,
}

const STEPS = {
  ORDERS: 1,
  PAYMENT_METHODS: 2
}

const MANAGER_PAYMENT = { 
  YES: 1,
  NO: 0
}

class ModalCreatePayment extends React.Component {

  state = {
    tab: TAB.SELECTED,
    sellers: this.props.sellers,
    managers: this.props.managers,
    form: {
      web: true,
      seller_id: '',
      payment_date: '',
      search: '',
      since: '',
      until: '',
      order_id: '',
      orders: [],
      registered_by: this.props.user.id,
      admin_id: this.props.user.level_id === Constants.ROLE_ADMIN ? this.props.user.id : this.props.user.admin_id,
      totalAmount: 0,
      amount_seller: 0,
      amount_manager: 0,
      to_pay: 0,
      comments: '',
      comments_manager: '',
      payment_method_id: '',
      payment_method_id_manager: '',
      voucher: '',
      voucher_manager: ''
    },
    unpaidOrdersOriginal: [],
    unpaidOrders: [],
    pagination: {
      page: 1,
      isLastPage: true,
    },
    totalAmount: 0,
    step: STEPS.ORDERS,
    manager_payment: MANAGER_PAYMENT.NO
  }

  getUnpaidOrders = async (page = 1) => {
    Globals.setLoading();
    await this.setState({ unpaidOrders: [] });

    try {
      const { form } = this.state;

      const data = {
        web: true,
        user_id: this.props.user.id,
        seller_id: form.seller_id,
        order_id: form.order_id,
        until: form.until && moment(form.until,'DD-MM-YYYY').format('YYYY-MM-DD'),
        since: form.since && moment(form.since,'DD-MM-YYYY').format('YYYY-MM-DD'),
        currency: Constants.CURRENCIES.DOLARES,
        status: [
          Constants.ORDER_STATUS.PENDING,
          Constants.ORDER_STATUS.PROCESSED,
        ],
        page,
        createPaymentComission: true,
        only_processed: true,
      };

      const res = await axios.post('admin/reports/orders', data);

      this.setState({
        unpaidOrders: res.data.orders.data,
        unpaidOrdersOriginal: res.data.orders.data,
        pagination: {
          page,
          isLastPage: page >= res.data.orders.last_page,
        },
        payment_methods: res.data.payment_methods || []
      });

    } catch (error) {
      Globals.showError();
      console.log('Commissions -> load -> catch: ', error);
    }

    Globals.quitLoading();
  }

  filterOrders = async () => {
    const { unpaidOrdersOriginal, form } = this.state;
    let filterOrders = unpaidOrdersOriginal;
  
    switch (true) {
      case form.order_id !== '':
        const orderId = parseInt(form.order_id, 10);
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          return item.correlative_number.toString().startsWith(orderId.toString());
        });
        break;
      case form.since !== '':
        const sinceDate = moment(form.since).format('YYYY-MM-DD');
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          const createdAtDate = moment(item.created_at).format('YYYY-MM-DD');
          return createdAtDate >= sinceDate;
        });
        break;
      case form.until !== '':
        const untilDate = moment(form.until).format('YYYY-MM-DD');
        filterOrders = unpaidOrdersOriginal.filter(function(item) {
          const createdAtDate = moment(item.created_at).format('YYYY-MM-DD');
          return createdAtDate <= untilDate;
        });
        break;
      default:
        break;
    }
  
    if(form.order_id !== ''){
      if (filterOrders.length <= 0) {
        this.setState({
          unpaidOrders: unpaidOrdersOriginal,
        });
      } else {
        this.setState({
          unpaidOrders: filterOrders,
        });
      }
    }
    else{
      this.setState({
        unpaidOrders: filterOrders,
      });
    }
    
  }

  change = async (value, target) => {
    console.log(value,target)
    await this.setState(state => ({
      form: {
        ...state.form,
        [target]: value,
        orders: target === 'seller_id'
          ? []
          : state.form.orders,
      },
    }));
    
  }

  onSelectOrders = () => {
    if (!this.state.form.seller_id)
      return Globals.showError('Primero seleccione un vendedor');

    this.setState({ tab: TAB.UNPAID });
  }

  toggleItem = async (item) => {
    const { form } = this.state;
    const index = form.orders.findIndex(x => x.id === item.id);

    if (index == -1) {
      form.orders.push(item);
    }
    else {
      form.orders.splice(index,1);
    }

    this.calculate();
  }

  removeItem = (index) => {
    const orders = this.state.form.orders.filter((_,i) => i !== index);
    this.setState(state => ({
      form: { ...state.form, orders }
    }));
  }

  calculate = () => {
    const { sellers, form, managers } = this.state;
    const seller = [...sellers,...managers].find(i => i.id == form.seller_id);
    const manager = seller?.managers?.length > 0 ? seller?.managers[0] : null;
    const pay_to_manager = (this.state.manager_payment == MANAGER_PAYMENT.YES && manager != null) || seller?.level_id == Constants.ROLE_MANAGER;
    const is_manager = seller?.level_id == Constants.ROLE_MANAGER;

    this.setState(state => {
      const total = state.form.orders.map(curr => {
        const amount_seller = curr.seller_id == seller?.id ? curr.total_commission_to_pay.seller : 0;
        const amount_manager = (!is_manager || curr.seller_id != seller?.id) ? (pay_to_manager ? curr.total_commission_to_pay.manager : 0) : 0;
        return amount_seller + amount_manager;
      }).reduce((a,b) => a + b, 0);
      const total_seller = state.form.orders.map(curr => {
        return curr.seller_id == seller?.id ? curr.total_commission_to_pay.seller : 0;
      }).reduce((a, b) => a + b, 0);
      let total_manager = 0;
      if (pay_to_manager) {
        total_manager = state.form.orders.map(curr => {
          return (!is_manager || curr.seller_id != seller?.id) ? curr.total_commission_to_pay.manager : 0;
        }).reduce((a,b) => a + b, 0);
      }
      // const total_topay = state.form.orders.map(curr => curr.total_commission_to_pay.total).reduce((a, b) => a + b, 0);
      const total_topay = total;
      return {
        totalAmount: parseFloat(total).toFixed(2),
        amount_seller: parseFloat(total_seller).toFixed(2),
        amount_manager: parseFloat(total_manager).toFixed(2),
        to_pay: parseFloat(total_topay).toFixed(2),
      };
    });
  }

  submit = async () => {
    const { form, totalAmount, amount_seller, amount_manager, to_pay, sellers, managers  } = this.state;
    
    if(!form.payment_date){
      Globals.showError('La fecha de pago es obligatoria');
      return;
    }

    if (!form.payment_method_id && amount_seller > 0) {
      Globals.showError('El método de pago de la comisión del vendedor es obligatorio');
      return;
    }

    const seller = [...sellers,...managers].find(i => i.id == form.seller_id);
    const manager = seller?.managers?.length > 0 ? seller?.managers[0] : null;
    const pay_to_manager = (this.state.manager_payment == MANAGER_PAYMENT.YES && manager != null) || seller?.level_id == Constants.ROLE_MANAGER;

    if (pay_to_manager && !form.payment_method_id_manager && amount_manager > 0) {
      Globals.showError('El método de pago de la comisión del gerente es obligatorio');
      return;
    }

    Globals.setLoading();
    try {
      let order_ids = form.orders.map(i => i.id);

      const data = {
        web: true,
        user_id: form.seller_id,
        seller_id: form.seller_id,
        registered_by_id: form.registered_by,
        admin_id: form.admin_id,
        date: form.payment_date && moment(form.payment_date,'DD-MM-YYYY').format('YYYY-MM-DD'),
        orders: JSON.stringify(order_ids),
        totalAmount: totalAmount,
        amount_seller: amount_seller,
        amount_manager: amount_manager,
        to_pay: to_pay,
        comments: form.comments,
        comments_manager: form.comments_manager,
        payment_method_id: form.payment_method_id,
        payment_method_id_manager: form.payment_method_id_manager,
        voucher: form.voucher?.value,
        voucher_manager: form.voucher_manager?.value,
        separate: true,
        manager_payment: pay_to_manager ? 1 : 0
      };

      await axios.uploadAlternative('web/admin/commission-payments/create', data);
      Globals.showSuccess('Se ha registrado el pago correctamente')
      this.props.onClose()

    } catch (error) {
      Globals.showError('Ha ocurrido un error');
      console.log('Comisiones registrar pago - catch: ', error);
    }

    Globals.quitLoading();
  }

  goToPayment = () => {
    const { form, amount_seller, amount_manager } = this.state;

    if(!form.payment_date){
      Globals.showError('La fecha de pago es obligatoria');
      return;
    }

    if (form.orders.length == 0) {
      Globals.showError('Debe seleccionar por lo menos 1 orden a pagar');
      return;
    }

    if (amount_seller <= 0 && amount_manager <= 0) {
      Globals.showError('El monto a pagar debe ser mayor a 0');
      return;
    }

    this.setState({
      step: STEPS.PAYMENT_METHODS
    });
  }

  addVoucher = (e,target) => {
    if (e?.target?.files[0]) {
      const files = { ...e?.target?.files };
      e.target.value = null;
      const format = files[0].name.split('.');
      const permitidos = ['jpg','png','jpeg'];
      if (permitidos.indexOf(format[format.length - 1].toLowerCase()) == -1) {
        Globals.showError('El formato del archivo no es válido');
        return false;
      }
      var reader = new FileReader();
      reader.readAsDataURL(files[0]);

      let tipo = format[format.length - 1].toLowerCase();
      switch(tipo) {
        case 'jpg':
          tipo = 'image/jpeg';
          break;

        case 'png':
          tipo = 'image/png';
          break;

        case 'jpeg':
          tipo = 'image/jpeg';
          break;        

        default: 
          tipo = 'image/jpeg';
          break;
      }

      reader.onload = file => {
        var tempImg = new Image();
        tempImg.src = reader.result;
    
        const self = this;
        tempImg.onload = function() {
          var tempW = tempImg.width;
          var tempH = tempImg.height;

          var resizedCanvas = document.createElement('canvas');
          resizedCanvas.width = tempW;
          resizedCanvas.height = tempH;
          var ctx = resizedCanvas.getContext("2d");
          ctx.drawImage(this, 0, 0, tempW, tempH);
          const dataURL = resizedCanvas.toDataURL(tipo);
          self.setState({
            form: {
              ...self.state.form,
              [target]: {
                value: self.dataURItoBlob(dataURL),
                dataURL
              }
            }
          });
        }
      }
    }
  }

  dataURItoBlob = (dataURI) => {
      var byteString;
      if (dataURI.split(',')[0].indexOf('base64') >= 0)
          byteString = atob(dataURI.split(',')[1]);
      else
          byteString = unescape(dataURI.split(',')[1]);
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
      var ia = new Uint8Array(byteString.length);
      for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
      }
      return new Blob([ia], {type:mimeString});
  }

  render() {
    const { step, tab, form, sellers, unpaidOrders, pagination, totalAmount, amount_seller, amount_manager, managers } = this.state;
    const seller = [...sellers,...managers].find(i => i.id == form.seller_id);
    const manager = seller?.managers?.length > 0 ? seller?.managers[0] : null;
    const pay_to_manager = (this.state.manager_payment == MANAGER_PAYMENT.YES && manager != null) || seller?.level_id == Constants.ROLE_MANAGER;
    const is_manager = seller?.level_id == Constants.ROLE_MANAGER;

    return (
      <div id="commissions-payments-modal">
        {tab === TAB.SELECTED && (
          <>
            {
              step == STEPS.ORDERS && (
                <>
                  <div id="tab-selected">
                    <div>
                      <div>
                        <Select
                          color="white"
                          label="Vendedor o Gerente"
                          className="material"
                          onChange={async (e) => {
                            await this.change(e.target.value, 'seller_id');
                            this.getUnpaidOrders();
                            this.calculate();
                          }}
                          value={form.seller_id}
                          options={
                            [{
                              label: 'Vendedores',
                              optgroup: true,
                              options: this.props.sellers.map((Item) => {
                                return {
                                  value: Item.id,
                                  label: Item.name
                                }
                              })
                            },
                            {
                              label: 'Gerentes',
                              optgroup: true,
                              options: this.props.managers.map((Item) => {
                                return {
                                  value: Item.id,
                                  label: Item.name
                                }
                              })
                            }]
                          }
                        />
                      </div>
                      <div className="row">
                        <div className="col-6">
                          <DatePicker
                            color="white"
                            label="Fecha de pago"
                            className="material"
                            value={form.payment_date}
                            onChange={v => this.change(v, 'payment_date')}
                          />
                        </div>
                        <div className="col-6 d-flex justify-content-end align-items-center">
                          <div>
                            <Button className="btn-select-orders" onClick={this.onSelectOrders}>
                              Seleccionar Pedidos
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="container-scroll">
                      <div className="content">
                          {
                            form.orders.length > 0 && (
                              <>
                                <p className="header">ID</p>
                                <p className="header">Fecha</p>
                                <p className="header">Total Vendedor</p>
                                <p className="header">Total Gerente</p>
                              </>
                            )
                          }
                        
                          {form.orders.map((order, index) => (
                            <>
                              <p className="item white">
                                <div className="actions">
                                  <img
                                    src={TrashIcon}
                                    title="Eliminar"
                                    alt="Eliminar"
                                    onClick={() => this.toggleItem(order)}
                                  />
                                </div>
                                { order.number_format }
                              </p>
                              <p className="item white">{ moment(order.created_at).format('DD/MM/YYYY HH:mm') }</p>
                              {/* <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission) : order.commission, true)}</p>
                              <p><span>Total Gerente:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission_manager) : order.commission_manager, true) }</p> */}
                              {/*<p className="item white">${ Globals.formatMiles(order.total_commission_to_pay.seller, true) }</p>
                              <p className="item white">{ pay_to_manager ? '$' + Globals.formatMiles(order.total_commission_to_pay.manager, true) : '-' }</p>*/}
                              <p className="item white">
                                {
                                  order.seller_id == seller?.id ? (
                                    <>
                                      ${ Globals.formatMiles(order.total_commission_to_pay.seller, true)}
                                    </>
                                  ) : (
                                    <>-</>
                                  )
                                }
                              </p>
                              <p className="item white">
                                {
                                  pay_to_manager && (!is_manager || order.seller_id != seller?.id) ? (
                                    <>${ Globals.formatMiles(order.total_commission_to_pay.manager, true) }</>
                                  ) : (
                                    <>-</>
                                  )
                                }
                              </p>
                          </>
                        ))}

                        {!form.orders.length && (
                          <p className="empty-message">No se han seleccionado pedidos</p>
                        )}
                      </div>
                    </div>

                    <div className={ `totals ${ form.orders.length > 0 ? 'active' : '' }` }>
                      <div style={{ width: '50%' }}>Total:</div>
                      <div style={{ width: '25%' }}>${ Globals.formatMiles(amount_seller) }</div>
                      <div style={{ width: '25%' }}>{ pay_to_manager ? '$' + Globals.formatMiles(amount_manager) : '-' }</div>
                    </div>

                    <footer style={{ marginBottom: '15px' }}>
                      <Button className="btn-send" onClick={this.goToPayment}>
                        Aceptar
                      </Button>
                    </footer>
                  </div>
                </>
              )
            }

            {
              step == STEPS.PAYMENT_METHODS && (
                <div style={{ width: '100%' }}>
                  <div className="container-title-methods">
                    <div style={{ flex: .1 }}>
                      <div className="back-button">
                        <img
                          src={LeftArrowIcon}
                          alt="Regresar"
                          onClick={() => 
                            this.setState({
                              step: STEPS.ORDERS
                            })
                          }
                        />
                      </div>
                    </div>
                    <div style={{ flex: .8 }}>
                      <h3>Registre métodos de pago</h3>
                    </div>
                    <div style={{ flex: .1 }}></div>
                  </div>

                  {
                    amount_seller > 0 && (
                      <>
                         <div className="container-cyan">
                          <table width="100%">
                            <tr>
                              <td width="50%"><p>Pagar a</p></td>
                              <td width="50%">
                                <p className="text-right">{ seller?.name } (Vendedor)</p>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <p className="blue">Total comisión a pagar</p>
                              </td>
                              <td width="50%">
                                <p className="text-right blue">${ Globals.formatMiles(amount_seller) }</p>
                                <p className="text-right blue">Bs. { Globals.formatMiles(amount_seller * this.props.conversion) }</p>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div className="container-fields">
                          <Select
                            color="white"
                            label="Método de Pago"
                            name="payment_method_id"
                            onChange={ (e) => this.change(e.target.value,e.target.name) }
                            value={form.payment_method_id}
                            options={ this.state.payment_methods.map((item) => {
                              return {
                                value: item.id,
                                label: item.name
                              }
                            }) }
                          />

                          <div className="container-file">
                            <label htmlFor="voucher1">{ form.voucher != '' ? 'Comprobante Cargado' : 'Comprobante de pago (Opcional)' }</label>
                            <input
                              id="voucher1"
                              type="file"
                              name="voucher"
                              style={{ display: 'none' }}
                              onChange={ (e) => {
                                this.addVoucher(e,'voucher');
                              } }
                            />
                          </div>

                          <Input
                            placeholder="Información adicional (Opcional)"
                            name="comments"
                            value={ form.comments }
                            onChange={ (e) => this.change(e.target.value,e.target.name) }
                          />
                        </div>
                      </>
                    )
                  }

                  {
                    pay_to_manager && amount_manager > 0 && (
                      <>
                        <div className="container-cyan">
                          <table width="100%">
                            <tr>
                              <td width="50%"><p>Pagar a</p></td>
                              <td width="50%">
                                <p className="text-right">{ is_manager ? seller?.name : manager?.name } (Gerente)</p>
                              </td>
                            </tr>
                            <tr>
                              <td width="50%">
                                <p>Total comisión a pagar</p>
                              </td>
                              <td width="50%">
                                <p className="text-right">${ Globals.formatMiles(amount_manager) }</p>
                                <p className="text-right">Bs. { Globals.formatMiles(amount_manager * this.props.conversion) }</p>
                              </td>
                            </tr>
                          </table>
                        </div>

                        <div className="container-fields">
                          <Select
                            color="white"
                            label="Método de Pago"
                            name="payment_method_id_manager"
                            onChange={ (e) => this.change(e.target.value,e.target.name) }
                            value={form.payment_method_id_manager}
                            options={ this.state.payment_methods.map((item) => {
                              return {
                                value: item.id,
                                label: item.name
                              }
                            }) }
                          />

                          <div className="container-file">
                            <label htmlFor="voucher2">{ form.voucher_manager != '' ? 'Comprobante Cargado' : 'Comprobante de pago (Opcional)' }</label>
                            <input
                              id="voucher2"
                              type="file"
                              name="voucher_manager"
                              style={{ display: 'none' }}
                              onChange={ (e) => {
                                this.addVoucher(e,'voucher_manager');
                              } }
                            />
                          </div>

                          <Input
                            placeholder="Información adicional (Opcional)"
                            name="comments_manager"
                            value={form.comments_manager}
                            onChange={ (e) => this.change(e.target.value,e.target.name) }
                          />
                        </div>
                      </>
                    )
                  }

                  <div className="container-btn-send">
                    <Button className="btn-send" onClick={this.submit}>
                      Aceptar
                    </Button>
                  </div>
                </div>
              )
            }
          </>
        )}

        {tab === TAB.UNPAID && (
          <div id="tab-unpaid">
            <nav style={{ marginBottom: '15px' }}>
              <div className="back-button">
                <img
                  src={LeftArrowIcon}
                  alt="Regresar"
                  onClick={
                    () => 
                    this.setState(state => ({
                      form: {
                        ...state.form,
                        since: "",
                        until: "",
                        order_id: "",
                      },
                      tab: TAB.SELECTED,
                      unpaidOrders: state.unpaidOrdersOriginal,
                    }))
                  }
                />
              </div>
            </nav>

            <div>
              <div className="row">
                <div className="col-12">
                  <Input
                    color=" "
                    label="Buscar"
                    placeholder="Buscar por ID de pedido"
                    className="filter"
                    value={form.order_id}
                    onKeyPress={Globals.soloNumeros}
                    onChange={async (e) => {
                      await this.change(e.target.value, 'order_id');
                      clearTimeout(this.timer);
                      this.timer = setTimeout(() => {
                        this.getUnpaidOrders();
                        this.calculate();
                      },1000);
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <DatePicker
                    color="white"
                    label="Desde"
                    className="material"
                    labelClass="material"
                    value={form.since}
                    onChange={async (v) => {
                      await this.change(v, 'since');
                      this.getUnpaidOrders();
                      this.calculate();
                    }}
                  />
                </div>
                <div className="col-md-6">
                  <DatePicker
                    color="white"
                    label="Hasta"
                    className="material"
                    labelClass="material"
                    value={form.until}
                    onChange={async (v) => {
                      await this.change(v, 'until');
                      this.getUnpaidOrders();
                      this.calculate();
                    }}
                  />
                </div>
              </div>
            </div>

            {
              (form.since || form.until || form.order_id) && (
                <p onClick={ () => {
                  this.setState({
                    form: {
                      ...this.state.form,
                      since: '',
                      until: '',
                      order_id: ''
                    }
                  },() => {
                    this.getUnpaidOrders();
                    this.calculate();
                  });
                } } className="reset-filters">Limpiar filtros</p>
              )
            }
            

            <div className="container-totals">
              <div style={{ flex: .5 }}>
                <div className="container-total blue">
                  <p className="name">Total Comisión Gerente</p>
                  <p className="total">{ pay_to_manager ? '$' + Globals.formatMiles(amount_manager) : '-' }</p>
                </div>
              </div>
              <div style={{ flex: .5 }}>
                <div className="container-total cyan">
                  <p className="name">Total Comisión Vendedor</p>
                  <p className="total">${ Globals.formatMiles(amount_seller)}</p>
                </div>
              </div>
            </div>

            {
              (manager != null && seller?.level_id == Constants.ROLE_SELLER) && (
                <div className="container-totals">
                  <div style={{ flex: 1 }}>
                    <button className={ this.state.manager_payment == MANAGER_PAYMENT.YES ? 'active' : '' } onClick={ () => {
                      this.setState({
                        manager_payment: this.state.manager_payment == MANAGER_PAYMENT.YES ? MANAGER_PAYMENT.NO : MANAGER_PAYMENT.YES
                      },() => this.calculate());
                    } }>
                      <img src={ CommissionCircleIcon } />
                      <p>Incluir pago del gerente</p>
                    </button>
                  </div>
                </div>
              )
            }

            <div className="text-right">
              <Button
                color="green"
                onClick={() => this.setState({ tab: TAB.SELECTED })}
              >
                Guardar
              </Button>
            </div>

            <div className="container-scroll" style={{ marginTop: '15px' }}>
              <div className="content">
                <p className="header">ID</p>
                <p className="header">Fecha</p>
                <p className="header">Total Vendedor</p>
                <p className="header">Total Gerente</p>
                {unpaidOrders.map((order, index) => {
                  const isSelected = form.orders.some(x => x.id === order.id);

                  return (
                    <>
                        <p className="item">
                          <div className={ `check ${ isSelected ? 'active' : '' }` } onClick={() => this.toggleItem(order)}>
                            {
                              isSelected && (
                                <img src={ CheckIcon } />
                              )
                            }
                          </div>
                          <strong>{ order.number_format }</strong>
                        </p>
                        <p className="item">{ moment(order.created_at).format('DD/MM/YYYY HH:mm') }</p>
                        {/* <p><span>Total Vendedor:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission) : order.commission, true)}</p>
                        <p><span>Total Gerente:</span> $ {  Globals.formatMiles(order.status_payment == Constants.PAYMENT_TYPES.TO_PAY ? (((order.total - order.to_pay) / order.total) * order.commission_manager) : order.commission_manager, true) }</p> */}
                        <p className="item">
                          {
                            order.seller_id == seller?.id ? (
                              <>
                                ${ Globals.formatMiles(order.total_commission_to_pay.seller, true)}
                              </>
                            ) : (
                              <>-</>
                            )
                          }
                        </p>
                        <p className="item">
                          {
                            (!is_manager || order.seller_id != seller?.id) ? (
                              <>${ Globals.formatMiles(order.total_commission_to_pay.manager, true) }</>
                            ) : (
                              <>-</>
                            )
                          }
                        </p>
                    </>
                  )
                })}

                {!unpaidOrders.length && (
                  <p className="empty-message">No hay pedidos de este vendedor</p>
                )}
              </div>
            </div>

            <footer style={{ marginTop: '10px', marginBottom: '10px', display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
              {pagination.page > 1 && (
                <Button onClick={() => this.getUnpaidOrders(pagination.page - 1)}>
                  Anterior
                </Button>
              )}
              {!pagination.isLastPage && (
                <Button onClick={() => this.getUnpaidOrders(pagination.page + 1)}>
                  Siguiente
                </Button>
              )}
            </footer>
          </div>
        )}
      </div>
    )
  }
}

export default connect(state => ({
  user: state.user,
}))(ModalCreatePayment)