import React from "react";

const Textarea = props => (
  <div className="form-group">
    {props.label && <label htmlFor={props.name}>{props.label}</label>}
    <textarea
      {...props}
      rows={props.rows ? props.rows : 4}
      type={props.type ? props.type : "text"}
      className={
        `form-control ${props.error ? props.error : ""} 
        ${props.className ? props.className : ""}`
      }
      maxLength={props.maxlength ? props.maxlength : "255"}
      name={props.name}
      autoComplete="address-level4"
    ></textarea>
    {props.invalidfeedback}
  </div>
);

export default Textarea;
