import Constants from "./constants";

class ReduceMap {
  taxableMap(Items) {
    return Items.filter(Item => Item.exempt === Constants.EXEMPT_ACTIVE).reduce(
      (value, product) => {
        return (value + parseFloat(product.subtotal) - (parseFloat(product.subtotal) * (product.discount_percentage/100)));
      },
      0
    );
  }

  exemptMap(Items) {
    return Items.filter(
      Item => Item.exempt === Constants.EXEMPT_INACTIVE
    ).reduce((value, product) => {
      return (value + parseFloat(product.subtotal) - (parseFloat(product.subtotal) * (product.discount_percentage/100)));
    }, 0);
  }

  reduceMap(Items, name) {
    return Items.reduce((value, product) => {
      return value + parseFloat(product[name]);
    }, 0);
  }

  amountMap(Items, name) {
    return Items.reduce((value, payments) => {
      return value + parseFloat(payments[name]);
    }, 0);
  }
}

export default new ReduceMap();
