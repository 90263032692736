import axios from "../../utils/axios";

class Dashboard {
  static async getAdminDashboard(form) {
    return new Promise((resolve, reject) => {
      axios.post('web/admin/home', form).then(
        ({ data }) => {
          // http success
          resolve(data);
        },
        ({ response }) => {
          const { data } = response;
          // http failed
          reject(data);
        }
      );
    });
  }
}

export default Dashboard;