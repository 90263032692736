import { emit } from 'jetemit';
import Constants from './constants';
import ScannerDetector from 'js-scanner-detection';

class Scanner {

	init = () => {
		const onComplete = barcode => emit(Constants.SCANNER,barcode);

		const options = {
		  onComplete: onComplete
		}

		new ScannerDetector(options);
	}
}

export default new Scanner();