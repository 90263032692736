import React from "react";
import { connect } from "react-redux";
import Menu from "../menu";
import {
  Table,
  Pagination,
  Button,
  Icon,
  Modal,
  Input
} from "../../components";
import { axios, Globals } from "../../utils";
import CreateEditProductProvider from "./create_edit_product_provider";
import ViewProductProvider from "./view_product_provider";
import $ from "jquery";
import AddIcon from '../../assets/icons/add.png';

class ProductProviders extends React.Component {
  state = {
    header: ["Nombre", "Correo", "Costo", "Acciones"],
    page: 1,
    last_page: 1,
    data: [],
    providers: null,
    provider: null,
    view: false,
    list_status: [
      { value: "", label: "Todos" },
      { value: 0, label: "Suspendidas" },
      { value: 1, label: "Activas" }
    ],
    form: {
      status: "",
      search: "",
      product_id: "",
      user_id:
        this.props.user.role === 4
          ? this.props.user.enterprise_users.enterprise_id
          : this.props.user.id,
      role: this.props.user.role
    }
  };
  componentDidMount() {
    this.setProvider(this.props.match.params.product_id);
    setTimeout(async () => {
      await $(".tooltip").tooltip("hide");
    }, 100);
  }
  componentDidUpdate(nextProps) {
    if (nextProps.location.pathname !== this.props.location.pathname) {
      let param = this.props.location.pathname.split("/");
      param = param[param.length - 1];
      this.setProvider(param);
    }
    setTimeout(async () => {
      await $(".tooltip").tooltip("hide");
    }, 100);
  }
  setProvider = async param => {
    await this.setState({
      form: {
        ...this.state.form,
        product_id: param,
        user_id: this.props.user.id
      }
    });
    this.load();
  };
  load = () => {
    Globals.setLoading();
    axios
      .post("admin/products/providers?page=" + this.state.page, this.state.form)
      .then(async res => {
        if (res.data.result) {
          this.setState({
            providers: res.data.providers
          });

          if (
            this.state.page > 1 &&
            res.data.product_providers.data.length === 0
          ) {
            await this.setState({
              page: 1
            });
            this.load();
          } else {
            await this.setState({
              last_page: res.data.product_providers.last_page,
              data: res.data.product_providers.data
            });
          }
        }
      })
      .catch(err => {
        Globals.showError();
      })
      .then(() => {
        Globals.quitLoading();
      });
  };
  goBack = () => {
    this.props.history.goBack();
  };

  close = async () => {
    await this.setState({
      create: false,
      view: false,
      edit: null,
      provider: null
    });
    await this.load();
  };

  delete = (item, i) => {
    Globals.confirm(
      "¿Desea quitar a este producto el proveedor: " + item.provide.name + "?",
      () => {
        Globals.setLoading("Guardando...");
        axios
          .post("admin/products/providers/delete", { id: item.id })
          .then(res => {
            if (res.data.result) {
              Globals.showSuccess(res.data.msg);
              this.load();
            } else {
              Globals.showError();
            }
          })
          .catch(err => {
            Globals.showError();
          })
          .then(() => {
            Globals.quitLoading();
          });
      }
    );
  };
  change = e => {
    this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value
      }
    });
  };
  render() {
    return (
      <Menu history={this.props.history}>
        <Modal
          title="Ver Proveedor"
          onClose={this.close}
          visible={this.state.view}
        >
          <ViewProductProvider provider={this.state.provider} />
        </Modal>
        <Modal
          title={this.state.edit ? "Cambiar Proveedor" : "Asignar Proveedor"}
          onClose={this.close}
          visible={this.state.create}
        >
          <CreateEditProductProvider
            providers={this.state.providers}
            product_id={this.state.form.product_id}
            edit={this.state.edit}
            onClose={this.close}
          />
        </Modal>
        <div id="home">
          <div className="row">
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.goBack}>
                <span>Volver</span>
              </Button>
            </div>
            <div className="col-md-8">
              <Input
                color=" "
                name="search"
                label="Búsqueda"
                className="filter"
                onChange={this.change}
                value={this.state.search}
                placeholder="Buscar por Nombre, Correo o Costo"
              />
            </div>
            <div className="col-md-2">
              <Button className="btn-align-bottom btn-filter" color=" " onClick={this.load}>
                <span>Filtrar</span>
              </Button>
            </div>
          </div>
          <Table
            data={this.state.data.length}
            title="Proveedores de producto"
            header={this.state.header}
            right={
              <Button
                small="true"
                onClick={() => {
                  this.setState({
                    create: true
                  });
                }}
              >
                <img src={AddIcon} alt="" />
              </Button>
            }
          >
            {this.state.data.map((i, index) => {
              return (
                <tr key={index}>
                  <td>{i.provide ? i.provide.name ? i.provide.name : '' : ''}</td>
                  <td>{i.provide ? i.provide.email ? i.provide.email : '' : ''}</td>
                  <td>{i.price ? i.price : ''}</td>
                  <td>
                    <Button
                      title="Editar"
                      color="primary"
                      small="true"
                      onClick={() =>
                        this.setState({
                          create: true,
                          edit: {
                            element: i,
                            index: index
                          }
                        })
                      }
                    >
                      <Icon name="edit" />
                    </Button>
                    <Button
                      color="red"
                      title="Eliminar"
                      small="true"
                      onClick={() => this.delete(i, index)}
                    >
                      <Icon name="trash" />
                    </Button>
                  </td>
                </tr>
              );
            })}
          </Table>

          <Pagination
            pages={this.state.last_page}
            active={this.state.page}
            onChange={async page => {
              await this.setState({
                page: page
              });
              this.load();
            }}
          />
        </div>
      </Menu>
    );
  }
}

export default connect(state => {
  return {
    user: state.user
  };
})(ProductProviders);
